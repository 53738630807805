<div class="modal-header" data-automation-id="modal-header">
  <h2 class="modal-title title text-capitalize" tabindex="-1" cdkFocusInitial
      id="{{titleId}}" [attr.data-automation-id]="titleId">
    {{ title }}
  </h2>
  <button type="button"
          class="close-button m-n1"
          data-dismiss="modal"
          (click)="onClose()"
          [class.button-disabled]="closeDisabled"
          [attr.aria-disabled]="closeDisabled"
          [attr.aria-label]="'closeDialog' | translate"
          [attr.data-automation-id]="closeId">
    <fa-icon class="close-icon" [icon]="closeIcon"></fa-icon>
  </button>
</div>
<h3 class="modal-subtitle" *ngIf="subtitle"
    data-automation-id="modal-subtitle">
  {{ subtitle }}
</h3>
