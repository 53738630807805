import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { resetListUpdatedState, updateList } from '../../actions/list.actions';
import { RegularListWithItemsCount } from '../../models/list';
import { getUpdateState, ListState } from '../../reducers/list.reducer';

@Component({
  selector: 'app-edit-search-name-modal',
  templateUrl: './edit-list-name-modal.component.html',
  styleUrls: ['./edit-list-name-modal.component.scss'],
})
export class EditListNameModalComponent implements OnInit, OnDestroy {
  @Input() public list?: RegularListWithItemsCount;
  public editListNameForm: UntypedFormGroup;
  public isUpdating: boolean;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly store: Store<ListState>,
    private readonly formBuilder: UntypedFormBuilder,
  ) {
    this.store.dispatch(resetListUpdatedState());
    this.editListNameForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
    });
  }

  public get name(): AbstractControl {
    return this.editListNameForm.get('name');
  }

  public ngOnInit(): void {
    this.name.setValue(this.list.name);
    this.subscriptions.add(
      this.store.select(getUpdateState).subscribe((updateState) => {
        if (updateState?.error) {
          switch (updateState.error.status) {
            case 409: {
              this.name.setErrors({duplicate: 'List name duplicate'});
              break;
            }
            case 404: {
              this.activeModal.close();
              break;
            }
            default: {
              this.editListNameForm.setErrors({
                unknown: {message: 'General server error', originalMessage: updateState.error.message},
              });
            }
          }
        }

        if (updateState?.updated) {
          this.activeModal.close();
        }

        this.isUpdating = updateState?.loading || false;
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onClose(): void {
    this.activeModal.dismiss();
  }

  public onSubmit(): void {
    this.name.markAsTouched();
    if (!this.editListNameForm.valid || this.isUpdating) {
      return;
    }

    this.store.dispatch(updateList({
      request: {
        id: this.list.id,
        name: this.name.value,
      },
    }));
  }
}
