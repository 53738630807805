import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, concatLatestFrom, createEffect, ofType, } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { secondModalOptions } from 'common/configs/modal-options';
import { EMPTY, from, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom, } from 'rxjs/operators';
import {
  bulkHoldSelectedItems,
  finalizeVolumeFlowInBulkHoldFlow,
  handleNeededAction,
  neededActionProcessing,
  neededActionProcessingFailure,
  neededActionProcessingSuccess,
  openBulkHoldModal,
  openErrorDetailsModal,
  openIneligibleForBulkHoldModal,
  reloadHoldsAfterBulkRequest,
  submitBulkHolds,
  submitIndividualHoldRequest,
  submitIndividualHoldRequestFailure,
  submitIndividualHoldRequestProcessing,
  submitIndividualHoldRequestStopped,
  submitIndividualHoldRequestSuccess,
  tabHoldItemAsVolumeInBulkHoldFlow,
  tabHoldVolumeInBulkHoldFlow,
} from 'user/actions/bulk-hold.actions';
import { holdRequested, loadHolds } from 'user/actions/user-profile.actions';
import { BulkHoldModalComponent } from 'user/components/bulk-hold/bulk-hold-modal/bulk-hold-modal.component';
import {
  IneligibleForBulkHoldModalComponent
} from 'user/components/bulk-hold/ineligible-for-bulk-hold-modal/ineligible-for-bulk-hold-modal.component';
import { GetItErrorDialogComponent } from 'user/components/get-it-error-dialog/get-it-error-dialog.component';
import { ItemVolumeListComponent } from 'user/components/item-volume-list/item-volume-list.component';
import { VolumeListComponent } from 'user/components/volume-list/volume-list.component';
import { HOLD_EXPIRATION_DEFAULT } from 'user/const/hold-expiration-default';
import { ItemVolume, Volume } from 'user/models/get-it';
import { getBulkHoldSubmitState, PlaceHoldError } from 'user/reducers/bulk-hold.reducer';
import { getProfileConfiguration, getUserHomeLibraryCode, getUserId, getUserPreferredPickupLocationCode } from 'user/reducers/user.reducer';
import { GetItService } from 'user/services/get-it.service';
import { PickupLocationService } from 'user/services/pickup-location.service';
import { isFormatGroup } from '../../entity/models/entity';
import { AvailabilityService } from '../../entity/services/availability.service';
import { ModalQueueService } from '../../list/services/modal-queue.service';


@Injectable()
export class BulkHoldEffects {
  public bulkHoldSelectedItems$ = createEffect(() => this.actions$.pipe(
    ofType(bulkHoldSelectedItems),
    map(({bulkFeatureKey, items}) => {
      const formatGroups = items.filter(isFormatGroup);
      const {available, unavailable} = this.availabilityService.filterFormatGroupsByPhysicalTabsAvailability(formatGroups);
      return openBulkHoldModal({bulkFeatureKey, availableFormatGroups: available, unavailableFormatGroups: unavailable});
    }),
  ));

  public openBulkHoldModal$ = createEffect(() => this.actions$.pipe(
    ofType(openBulkHoldModal),
    withLatestFrom(
      this.store.select(getUserHomeLibraryCode),
      this.store.select(getUserPreferredPickupLocationCode),
      this.store.select(getProfileConfiguration),
      this.store.select(getUserId),
    ),
    switchMap(([_, homeLibraryCode, preferredPickupLocationCode, profileConfiguration, userId]) => {
      return this.pickupLocationService.getUserPickupLocations(profileConfiguration.holdSetting, homeLibraryCode).pipe(
        map((pickupLocations) => {
          const modalRef = this.modal.open(BulkHoldModalComponent, {
            windowClass: 'bulk-hold-modal',
            ariaLabelledBy: 'bulk-hold-modal-title',
            backdrop: 'static',
          });
          const selectedLocation = this.pickupLocationService.getUserPickupLocation(pickupLocations, homeLibraryCode, preferredPickupLocationCode);
          modalRef.componentInstance.pickupLocations = pickupLocations;
          modalRef.componentInstance.selectedLocationCode = selectedLocation.code;
          modalRef.componentInstance.holdExpirationDefault = profileConfiguration?.holdExpirationDefault || HOLD_EXPIRATION_DEFAULT;
          modalRef.componentInstance.holdActivationDateSetting = profileConfiguration?.holdActivationDateSetting;
          modalRef.componentInstance.userId = userId;
          modalRef.result.finally(() => {
            this.modalQueueService.startQueue();
            this.store.dispatch(reloadHoldsAfterBulkRequest());
          });
        }),
        catchError(() => EMPTY),
      );
    }),
  ), {dispatch: false});

  public submitBulkHolds$ = createEffect(() => this.actions$.pipe(
    ofType(submitBulkHolds),
    switchMap(({holdRequests}) => {
      return holdRequests.map((holdRequest, holdIndex) => submitIndividualHoldRequest({holdRequest, holdIndex}));
    }),
  ));

  public processHoldRequest$ = createEffect(() => this.actions$.pipe(
    ofType(submitIndividualHoldRequest),
    concatMap((action) => of(action).pipe(
      concatLatestFrom(() => this.store.select(getBulkHoldSubmitState)),
      concatMap(([{holdRequest, holdIndex}, bulkHoldSubmitState]) => {
        if (bulkHoldSubmitState.stopping) {
          return of(submitIndividualHoldRequestStopped({holdRequest, holdIndex}));
        }
        this.store.dispatch(submitIndividualHoldRequestProcessing({holdRequest, holdIndex}));
        return this.getItService.placeTabHold(holdRequest).pipe(
          switchMap(({recordId}) => {
            const actions: Action[] = [submitIndividualHoldRequestSuccess({holdRequest, holdIndex, recordId})];
            if (recordId) {
              actions.push(holdRequested({holdId: recordId, isSierra: true, skipLoadHolds: true}));
            }
            return actions;
          }),
          catchError((err) => {
            const error: PlaceHoldError = {
              status: err.status,
              message: err.error?.message,
              needsAction: err.status === 409,
              volumes: err.error?.data?.volumes,
              itemsAsVolumes: err.error?.data?.itemsAsVolumes,
            };
            return of(submitIndividualHoldRequestFailure({holdRequest, holdIndex, error}));
          }));
      }),
    )),
  ));

  public reloadHoldsAfterBulkRequest$ = createEffect(() => this.actions$.pipe(
    ofType(reloadHoldsAfterBulkRequest),
    withLatestFrom(this.store.select(getBulkHoldSubmitState)),
    switchMap(([_, bulkHoldSubmitState]) => {
      return bulkHoldSubmitState.succeededCount > 0 ? [loadHolds()] : EMPTY;
    }),
  ));

  public openErrorDetailsModal$ = createEffect(() => this.actions$.pipe(
    ofType(openErrorDetailsModal),
    tap(({message}) => {
      const modalRef = this.modal.open(GetItErrorDialogComponent, secondModalOptions);
      modalRef.componentInstance.message = message;
    }),
  ), {dispatch: false});

  public handleNeededAction$ = createEffect(() => this.actions$.pipe(
    ofType(handleNeededAction),
    switchMap(({holdRequest: data, error}) => {
      const actions: Action[] = [];
      if (error.itemsAsVolumes?.length) {
        actions.push(tabHoldItemAsVolumeInBulkHoldFlow({data, itemsAsVolumes: error.itemsAsVolumes}));
      } else if (error.volumes?.length) {
        actions.push(tabHoldVolumeInBulkHoldFlow({data, volumes: error.volumes}));
      }
      return actions;
    }),
  ));

  public tabHoldVolumeInBulkHoldFlow$ = createEffect(() => this.actions$.pipe(
    ofType(tabHoldVolumeInBulkHoldFlow),
    switchMap(({data: tabHoldData, volumes}) => {
      const modalRef = this.modal.open(VolumeListComponent, secondModalOptions);
      modalRef.componentInstance.volumes = volumes;
      return from(modalRef.result).pipe(
        map((volume: Volume) => {
          const data = {...tabHoldData, recordNumber: volume.id, recordType: 'j'};
          return finalizeVolumeFlowInBulkHoldFlow({data, fgId: data.formatGroupId});
        }),
      );
    }),
  ));

  public tabHoldItemAsVolumeInBulkHoldFlow$ = createEffect(() => this.actions$.pipe(
    ofType(tabHoldItemAsVolumeInBulkHoldFlow),
    switchMap(({data: tabHoldData, itemsAsVolumes}) => {
      const modalRef = this.modal.open(ItemVolumeListComponent, secondModalOptions);
      modalRef.componentInstance.itemsAsVolumes = itemsAsVolumes;
      return from(modalRef.result).pipe(
        map((itemVolume: ItemVolume) => {
          const data = {...tabHoldData, recordNumber: itemVolume.id, recordType: 'i'};
          return finalizeVolumeFlowInBulkHoldFlow({data, fgId: data.formatGroupId});
        }),
      );
    }),
  ));

  public finalizeVolumeFlowInBulkHoldFlow$ = createEffect(() => this.actions$.pipe(
    ofType(finalizeVolumeFlowInBulkHoldFlow),
    concatMap(({data, fgId}) => {
      this.store.dispatch(neededActionProcessing({fgId}));
      return this.getItService.placeHold(data).pipe(
        map(() => neededActionProcessingSuccess({fgId})),
        catchError((err) => of(neededActionProcessingFailure({fgId, message: err?.error?.message}))));
    }),
  ));

  public openIneligibleForBulkHoldModal$ = createEffect(() => this.actions$.pipe(
    ofType(openIneligibleForBulkHoldModal),
    tap(({ineligibleFormatGroups}) => {
      const modalRef = this.modal.open(IneligibleForBulkHoldModalComponent, secondModalOptions);
      modalRef.componentInstance.ineligibleFormatGroups = ineligibleFormatGroups;
    }),
  ), {dispatch: false});

  constructor(
    private readonly actions$: Actions,
    private readonly modal: NgbModal,
    private readonly store: Store,
    private readonly availabilityService: AvailabilityService,
    private readonly pickupLocationService: PickupLocationService,
    private readonly getItService: GetItService,
    private readonly modalQueueService: ModalQueueService,
  ) {
  }
}
