import { Injectable, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { FeatureToggleService } from './feature-toggle.service';
import { WindowRefService } from './window-ref.service';

export enum SyndeticsWidget {
  lookinside = 'lookinside',
  series = 'series',
  patronreviews = 'patronreviews',
  similar = 'similar',
  reviews = 'reviews',
  bookprofile = 'bookprofile',
  readinglevel = 'readinglevel',
  awards = 'awards',
  author = 'author',
}

export type InsertWidgetsStatus = { [key in SyndeticsWidget]?: boolean };

@Injectable()
export class SyndeticsUnboundService {
  private readonly scriptId = 'syndetics-unbound-script';
  private scriptInjected: HTMLScriptElement = null;
  private widgetsInUse: SyndeticsWidget[] = [];
  private promiseQueueWaitForFirstInjection: Promise<InsertWidgetsStatus> = null;
  private featureSyndeticLinks = false;
  private featureSyndeticIntegrations = false;

  constructor(private readonly windowRefService: WindowRefService,
              private router: Router,
              private readonly featureToggleService: FeatureToggleService,
  ) {
    this.featureSyndeticLinks = this.featureToggleService.getToggles()['DIS-32351_2024-07-31_Syndetics-Links'];
    this.featureSyndeticIntegrations = this.featureToggleService.getToggles()['DIS-28885_2024-06-31_syndetics_integration'];
  }

  public insertWidgets(isbn: string, widgets: SyndeticsWidget[],
                       aId: string,
                       renderer: Renderer2,
                       title?: string,
                       branchFilters?: string[]): Promise<InsertWidgetsStatus> {
    const window = this.windowRefService.nativeWindow();
    const unboundInitFn = this.runUnbound.bind(this, isbn, title, branchFilters);

    // if it is first usage on page, we just initialize Syndetics Unbound as the docs suggest…
    if (!this.scriptInjected) {
      const promise = this.setCallbacksAndBuildPromise(widgets);
      // syndetics calls this global unboundInit function when it sets all the internal object once
      // after script is injected to the page, reporting it is ready to be used
      window.unboundInit = unboundInitFn;
      this.injectScript(aId, renderer);
      this.promiseQueueWaitForFirstInjection = promise;
      this.widgetsInUse.push(...widgets);
      return promise;
    }

    // …otherwise, when script is already on the page,
    // we explicitly call internal (but public) method to initiate widgets loading and inject.
    // However we don't want to interrupt into widgets loading process,
    // so we're waiting for first widgets injection before starting the next one
    return this.promiseQueueWaitForFirstInjection.then(() => {
      const promise = this.setCallbacksAndBuildPromise(widgets);
      unboundInitFn();
      this.widgetsInUse.push(...widgets);
      return promise;
    });
  }

  public removeWidgets(widgets: SyndeticsWidget[], renderer: Renderer2): void {
    const window = this.windowRefService.nativeWindow();
    widgets.forEach((widget) => this.removeWidgetCallbacks(widget));

    this.widgetsInUse = this.widgetsInUse.filter((widget) => {
      return !widgets.includes(widget);
    });

    // if there are no widgets left in use on the page, do a cleanup after syndetics unbound scripts
    if (!this.widgetsInUse.length && this.scriptInjected) {
      renderer.removeChild(window.document.body, this.scriptInjected);

      // remove CSS styles added by Syndetics Unbound
      const widgetStyles: NodeList = window.document.querySelectorAll('link[href*="/syndeticsunbound/"]');
      widgetStyles.forEach((widgetStyle) => {
        renderer.removeChild(window.document, widgetStyle);
      });

      // remove modal wrapper added by Syndetics Unbound
      const modalWrappers: NodeList = window.document.querySelectorAll('[id="LT_LB_wrapper"]');
      modalWrappers.forEach((widgetStyle) => {
        renderer.removeChild(window.document, widgetStyle);
      });

      // remove callback set for unbound
      delete window.unboundInit;
      // using internal public function destroy() to let Syndetics JS script remove itself
      // LibraryThingConnector may be undefined if Syndetics script is not yet loaded
      window.LibraryThingConnector?.destroy?.();
      this.setStubsForCallbackInProgress();
      // cleaning up internal service state to be able to re-initiate it on next FRC
      this.scriptInjected = null;
      this.promiseQueueWaitForFirstInjection = null;
    }
  }

  private injectScript(aId: string, renderer: Renderer2): void {
    const window = this.windowRefService.nativeWindow();
    this.scriptInjected = renderer.createElement('script');
    this.scriptInjected.id = this.scriptId;
    this.scriptInjected.type = 'text/javascript';
    this.scriptInjected.src = `https://unbound.syndetics.com/syndeticsunbound/connector/initiator.php?a_id=${aId}`;

    renderer.appendChild(window.document.body, this.scriptInjected);
  }

  private redirectOnWidgetClick(link: string) {
    const router = this.router;

    const url = new URL(link);
    const urlParams = new URLSearchParams(url.search);
    if (urlParams.has('isbn')) {
      const isbn = urlParams.get('isbn');
      // use angular router
      router.navigate(['/search', 'card'], {
        queryParams: {'isbn': isbn}
      });
    } else {
      // fall back to default behavior
      window.history.pushState({}, link);
      window.top.location.href = link;
    }
  }

  private runUnbound(isbn: string, title?: string, branchFilters?: Array<string>): void {
    const metadata: { isbn: string, title?: string, branchFilters?: Array<string>, unbound_run_link_function?: Function } = {isbn};

    if (title) {
      metadata.title = title;
    }

    if (this.featureSyndeticLinks) {
      metadata.unbound_run_link_function = this.redirectOnWidgetClick.bind(this);
    }

    if (branchFilters?.length && this.featureSyndeticIntegrations) {
      metadata.branchFilters = branchFilters;
    }

    // internal public legal method to run widgets loading
    this.windowRefService.nativeWindow().LibraryThingConnector.runUnboundWithMetadata(metadata);
  }

  private setCallbacksAndBuildPromise = (widgets: SyndeticsWidget[]) => {
    const widgetPromises: Promise<[SyndeticsWidget, boolean]>[] = widgets.map((widget) => new Promise(((resolve) => {
      this.setWidgetCallbacks(
        widget,
        () => resolve([widget, true]),
        () => resolve([widget, false]),
      );
    })));

    return Promise.all(widgetPromises).then((results) => {
      const insertWidgetsStatus: InsertWidgetsStatus = {};
      results.forEach((result) => {
        insertWidgetsStatus[result[0]] = result[1];
      });

      return insertWidgetsStatus;
    });
  };

  // when syndetics finishes handling the "Widgets Load" procedure, it calls functions on window (if set)
  // this way we can know when to hide loading, and when to hide blocks for widgets if they are not loaded
  private setWidgetCallbacks(widget: SyndeticsWidget, success: () => void, failure: () => void): void {
    this.windowRefService.nativeWindow()[`unbound_${widget}_success`] = success;
    this.windowRefService.nativeWindow()[`unbound_${widget}_failure`] = failure;
  }

  private removeWidgetCallbacks(widget: SyndeticsWidget): void {
    delete this.windowRefService.nativeWindow()[`unbound_${widget}_success`];
    delete this.windowRefService.nativeWindow()[`unbound_${widget}_failure`];
  }

  // This function is required to be called on "unregistering" for the following case.
  // When Syndetics Unbound is still loading widgets, but user is leaving the page,
  // the errors are occurring because there are no objects handling the response available.
  // Besides, some events may occur from the page (set using jQuery).
  // So we set this stubs to avoid "cannot call function on undefined" type of errors.
  // We don't need to remove this stubs later, because syndetics unbound initiator script
  // rewrites these objects with real implementations.
  // Actually, we shouldn't remove them because otherwise similar errors occur
  // when visiting FRC next time, because stubs are removed, but actual implementation is not yet loaded.
  // If more errors of such type occur, the only thing required should be adding a new function stub below.
  private setStubsForCallbackInProgress() {
    const window = this.windowRefService.nativeWindow();
    const noop = () => {
    };
    window.LibraryThingConnector = {
      timing: {hover: {api: {pq: {}}}},
      utils: {
        jQuery: () => ({
          html: noop,
          focus: noop,
          hasClass: noop,
        }),
        base64decode: noop,
      },
      log: noop,
      debug: noop,
      info: noop,
      insertAuthorImage: noop,
      sub_in_translationstringsA: noop,
      logToDebugPanel: noop,
      syndeticsNoData: noop,
      updateContainerQueries: noop,
      dispatchLTFLWidgetsLoaded: noop,
      recordPageStats: noop,
      addContent: noop,
      dispatchSyndeticsWidgetsLoaded: noop,
      runRecordPageStatsLoadedHooks: noop,
      handleSyndeticsHoverAPI: noop,
      handleSyndeticsHoverAPIFailure: noop,
      addHoverData: noop,
      attachAccessibilityItems: noop,
      attachAccessibilityItemsV2: noop,
      lazyload_data_images: noop,
      unbound_check_seemores: noop,
      recordStats: noop,
      updateMoreByButton: noop,
      unbound_imgload: noop,
    };
    window.$syndetics = {
      callback: noop,
    };
  }
}
