import { createAction, props } from '@ngrx/store';
import { Entity, EntityPayload, FormatGroupPayload, MaterialTab } from '../models/entity';
import { SearchResult } from 'search/models/search-results';
import { RelatedItem, RelatedItemPayload } from '../models/related-items-result';

export const loadEntity = createAction(
  '[FullCard] Entity Load',
  props<{ entity: EntityPayload }>(),
);

export const loadEntityComplete = createAction(
  '[FullCard] Entity Load Complete',
  props<{ entity: Entity }>(),
);

export const loadEntityFailure = createAction(
  '[FullCard] Entity Load Failure',
  props<{ error: any }>(),
);

export const loadFormatGroup = createAction(
  '[FullCard] Load Format Group',
  props<{ entity: EntityPayload }>(),
);

export const loadFormatGroupComplete = createAction(
  '[FullCard] Load Format Group Complete',
  props<{ entity: FormatGroupPayload }>(),
);

export const loadFormatGroupFailure = createAction(
  '[FullCard] Load Format Group Failure',
  props<{ error: any }>(),
);

export const loadFormatGroupByRecordIdFailure = createAction(
  '[FullCard] FormatGroup By RecordId Load Failure',
  props<{ error: any }>(),
);

export const loadFormatGroupByIdentifierFailure = createAction(
  '[FullCard] FormatGroup By identifier Load Failure',
  props<{ error: any }>(),
);

export const loadFormatGroupAboutInfo = createAction(
  '[FullCard] FormatGroup Information About Selected Instance Load',
  props<{ id: string }>(),
);

export const loadFormatGroupAboutInfoComplete = createAction(
  '[FullCard] FormatGroup Information About Selected Instance Load Complete',
  props<{ results: SearchResult[] }>(),
);

export const loadRelatedItems = createAction(
  '[Card body] Related Items Load',
  props<{ item: RelatedItemPayload }>(),
);

export const loadRelatedItemsComplete = createAction(
  '[Card body] Related Items Load complete',
  props<{ relatedItems: RelatedItem[] }>(),
);

export const loadRelatedItemsFailure = createAction(
  '[Card body] Related Items Load failure',
  props<{ error: any }>(),
);

export const loadRelatedItemsOfMaterialTabEditions = createAction(
  '[Card body] Editions availability load',
  props<{ materialTabIndex: number, editionIndex?: number, }>(),
);

export const loadRelatedItemsOfEditionComplete = createAction(
  '[Card body] Editions availability load complete',
  props<{ materialTabIndex: number, editionIndex: number, relatedItems: RelatedItem[] }>(),
);

export const loadRelatedItemsOfEditionFailure = createAction(
  '[Card body] Editions availability load failure',
  props<{ materialTabIndex: number, editionIndex: number, error: any }>(),
);

export const updateEditionAvailabilityState = createAction(
  '[Card body] Editions availability state update',
  props<{ materialTabIndex: number, editionIndex: number, expanded?: boolean, loading?: boolean }>(),
);

export const queryEditions = createAction(
  '[Entity::Editions::Query] Editions information query',
  props<{ query: string }>(),
);
