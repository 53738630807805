export interface IMatPaginatorIntlCustomTranslations {
  [key: string]: {
    perPage: string;
    nextPage: string;
    prevPage: string;
    of: string;
  };
}

export const dictionary: IMatPaginatorIntlCustomTranslations = {
  en: {
    perPage: 'Items per page',
    nextPage: 'Next Page',
    prevPage: 'Previous Page',
    of: ' of ',
  },
  ar: {
    perPage: 'مواد لكل صفحة',
    nextPage: 'الصفحة التالية',
    prevPage: 'الصفحة السابقة',
    of: ' من ',
  },
  zh: {
    perPage: '每頁項目',
    nextPage: '下一頁',
    prevPage: '上一頁',
    of: ' 的 ',
  },
  fr: {
    perPage: 'Objets par page',
    nextPage: 'Page Suivante',
    prevPage: 'Page Précédente',
    of: ' de ',
  },
};
