import { Component, Input, OnInit } from '@angular/core';
import { LibraryHasConfiguration } from 'shared/models/library-has-configuration';
import { configLibraryHas } from 'shared/utils/config-library-has';

@Component({
  selector: 'app-library-has',
  templateUrl: './library-has.component.html',
  styleUrls: ['./library-has.component.scss'],
})
export class LibraryHasComponent implements OnInit {
  @Input() public issuesStrings: string[];
  @Input() public supplementsStrings: string[];
  @Input() public indexesStrings: string[];
  @Input() public isFullScreen = false;

  public expanded: boolean;
  public showConfig: LibraryHasConfiguration;

  public ngOnInit(): void {
    this.showConfig = configLibraryHas(this.expanded, this.issuesStrings, this.indexesStrings, this.supplementsStrings);
  }

  public toggleExpanded() {
    this.expanded = !this.expanded;
    this.showConfig = configLibraryHas(this.expanded, this.issuesStrings, this.indexesStrings, this.supplementsStrings);
  }
}
