import { createAction, props } from '@ngrx/store';
import { EmailSavedSearchError, ShareItSavedSearchResource, ShareItShareBody, ShareItShowcaseResource } from '../models/share-it';

export const emailEntity = createAction(
  '[ShareIt] Email entity',
  props<{ body: ShareItShareBody }>(),
);

export const emailEntityComplete = createAction(
  '[ShareIt] Email entity complete',
);

export const emailEntityFailure = createAction(
  '[ShareIt] Email entity failure',
  props<{ error: EmailSavedSearchError }>(),
);

export const resetEntityEmailState = createAction(
  '[ShareIt] Reset entity email state',
);

export const openEmailEntityModal = createAction(
  '[ShareIt] Open email entity modal state',
  props<{ defaultSubject: string, resource: ShareItSavedSearchResource | ShareItShowcaseResource }>(),
);

export const notifyEmailEntityModalClosed = createAction(
  '[ShareIt] Notify email entity modal closed',
  props<{ resource: ShareItSavedSearchResource | ShareItShowcaseResource }>(),
);
