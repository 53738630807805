import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LibraryList } from '../../library-list/reducers/library-list.reducer';
import { ListSortByString } from '../../list/models/list.dto';
import {
  CustomShowcaseCreatedFromType,
  CustomShowcaseCreationForm,
  CustomShowcasePublishDate,
  CustomShowcaseReference,
  CustomShowcaseUpdatedFields,
} from '../models/custom-showcase';
import {
  CustomShowcaseDto,
  CustomShowcaseItemsDto,
  CustomShowcasePaginationParams,
  CustomShowcasesDto,
  CustomShowcaseSingleDto
} from '../models/custom-showcase.dto';

@Injectable()
export class CustomShowcaseService {
  private static readonly customShowcaseUrl = 'api/search-result/personalization/showcases';

  constructor(private readonly http: HttpClient) {
  }

  public loadMyCustomShowcases(previewSize: number, searchText?: string): Observable<CustomShowcaseDto[]> {
    const params: Record<string, string> = {
      previewSize: previewSize.toString(),
    };
    if (searchText) {
      params.searchText = searchText;
    }
    return this.http
    .get<CustomShowcaseDto[]>(`${CustomShowcaseService.customShowcaseUrl}/my`, {params});
  }

  public loadAllCustomShowcases(previewSize: number, paginationParams: CustomShowcasePaginationParams,
                                searchText?: string): Observable<CustomShowcasesDto> {
    const params: Record<string, string> = {
      previewSize: previewSize.toString(),
      pageNum: paginationParams.pageNum.toString(),
      pageSize: paginationParams.pageSize.toString(),
    };
    if (searchText) {
      params.searchText = searchText;
    }
    return this.http
    .get<CustomShowcasesDto>(`${CustomShowcaseService.customShowcaseUrl}/all`, {params});
  }

  public getCustomShowcaseById(id: string): Observable<CustomShowcaseSingleDto> {
    return this.http
    .get<CustomShowcaseSingleDto>(`${CustomShowcaseService.customShowcaseUrl}/${id}`);
  }

  public createCustomShowcase(creationForm: CustomShowcaseCreationForm): Observable<CustomShowcaseReference> {
    return this.http
      .post<CustomShowcaseReference>(CustomShowcaseService.customShowcaseUrl, creationForm, { headers: { 'api-version': '2' } });
  }

  public updateCustomShowcase(id: string, updateForm: CustomShowcaseUpdatedFields):Observable<void> {
    return this.http
    .patch<void>(`${CustomShowcaseService.customShowcaseUrl}/${id}`, updateForm, { headers: { 'api-version': '2' } });
  }

  public publishOrUnpublishCustomShowcase(id: string, updateForm: CustomShowcaseUpdatedFields): Observable<CustomShowcasePublishDate> {
    return this.http
    .patch<{publishDate?: string}>(`${CustomShowcaseService.customShowcaseUrl}/${id}/published`, updateForm, { headers: { 'api-version': '2' } })
    .pipe(map((response) => ({publishDate: response.publishDate || null})));
  }

  public removeCustomShowcase(id: string): Observable<string> {
    return this.http
      .delete<void>(`${CustomShowcaseService.customShowcaseUrl}/${id}`)
      .pipe(map(() => id));
  }

  public getPublishedShowcaseById(id: string): Observable<LibraryList> {
    return this.http
    .get<LibraryList>(`${CustomShowcaseService.customShowcaseUrl}/published/${id}`);
  }

  public getCustomShowcaseItems(
    id: string,
    paginationParams: CustomShowcasePaginationParams,
  ): Observable<CustomShowcaseItemsDto> {
    return this.http.get<CustomShowcaseItemsDto>(`${CustomShowcaseService.customShowcaseUrl}/${id}/items`, {
      params: {
        pageNum: paginationParams.pageNum.toString(),
        pageSize: paginationParams.pageSize.toString(),
      },
    });
  }
}
