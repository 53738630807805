<fieldset class="form-group mb-4 p-0 m-0" [formGroup]="aboutShowcase">
  <legend class="metadata-label" data-automation-id="metadata-about-showcase-label">
    <fa-icon [icon]="bookReaderIcon" [classes]="['icon']" [fixedWidth]="true"></fa-icon>
    {{ 'customShowcaseMetadataAboutThisShowcase' | translate }}
  </legend>
  <p class="metadata-label-description mb-10"
     data-automation-id="metadata-about-showcase-label-description">
    {{ 'customShowcaseMetadataAboutThisShowcaseDescription' | translate }}
  </p>

  <div class="row">
    <!-- TYPE -->
    <div class="col-12 col-md-4 mb-4">
      <fieldset class="form-group p-0 m-0" [formGroup]="types" data-automation-id="metadata-about-showcase-type">
        <legend class="metadata-label-description" data-automation-id="metadata-about-showcase-type-label">{{'customShowcaseMetadataType' | translate}}</legend>
        <div *ngFor="let type of types.controls | keyvalue:originalOrder; index as i"
             data-automation-id="metadata-about-showcase-type-checkbox">
          <app-custom-checkbox
            [formControlName]="type.key"
            [containerClass]="['ml-2 mr-4 my-4']"
            [attr.data-automation-id]="'metadata-type-checkbox-' + i">
            {{ typeTranslations[type.key] | translate }}
          </app-custom-checkbox>
        </div>
      </fieldset>
    </div>

    <!-- AGE -->
    <div class="col-12 col-md-4 mb-4">
      <fieldset class="form-group p-0 m-0" [formGroup]="ages" data-automation-id="metadata-about-showcase-age">
        <legend class="metadata-label-description" data-automation-id="metadata-about-showcase-age-label">{{'customShowcaseMetadataAge' | translate}}</legend>
        <div *ngFor="let age of ages.controls | keyvalue:originalOrder; index as i"
             data-automation-id="metadata-about-showcase-age-checkbox">
          <app-custom-checkbox
            [formControlName]="age.key"
            [containerClass]="['ml-2 mr-4 my-4']"
            [attr.data-automation-id]="'metadata-age-checkbox-' + i">
            {{ ageTranslations[age.key] | translate }}
          </app-custom-checkbox>
        </div>
      </fieldset>
    </div>

    <!-- FORMAT -->
    <div class="col-12 col-md-4 mb-4">
      <fieldset class="form-group p-0 m-0" [formGroup]="formats" data-automation-id="metadata-about-showcase-format">
        <legend class="metadata-label-description" data-automation-id="metadata-about-showcase-format-label">{{'customShowcaseMetadataFormat' | translate}}</legend>
        <div *ngFor="let format of formats.controls | keyvalue:originalOrder; index as i"
             data-automation-id="metadata-about-showcase-format-checkbox">
          <app-custom-checkbox
            [formControlName]="format.key"
            [containerClass]="['ml-2 mr-4 my-4']"
            [attr.data-automation-id]="'metadata-format-checkbox-' + i">
            {{ formatTranslations[format.key] | translate }}
          </app-custom-checkbox>
        </div>
      </fieldset>
    </div>
  </div>
</fieldset>
