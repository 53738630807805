import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { License } from '../../models/license';
import { LicenseService } from '../../services/license.service';

@Injectable()
export class SiteCodeGuard implements CanActivate {
  constructor(
    private readonly licenseService: LicenseService,
    private readonly router: Router,
  ) {
  }

  public canActivate(): boolean {
    if (this.licenseService.errorStatus) {
      if ([403, 404].includes(this.licenseService.errorStatus)) {
        this.router.navigate([`/${this.licenseService.errorStatus}`]);
      }

      return false;
    } else if (!this.licenseService.licenses?.includes(License.DISCOVERY)) {
      this.router.navigate(['/404']);
      return false;
    }

    return true;
  }
}
