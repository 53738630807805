import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TippyModule } from '@ngneat/helipopper';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'common/shared.module';
import { EmailEntityModalComponent } from './components/email-entity-modal/email-entity-modal.component';
import { EmailPopupComponent } from './components/email-popup/email-popup.component';
import { ShareItEffects } from './effects/share-it.effects';
import * as shareIt from './reducers/share-it.reducer';
import { ShareItService } from './services/share-it.service';

const COMPONENTS = [
  EmailPopupComponent,
  EmailEntityModalComponent,
];
const MATERIAL = [
  OverlayModule,
];
@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    A11yModule,
    SharedModule,
    StoreModule.forFeature(shareIt.shareItFeatureKey, shareIt.reducer),
    EffectsModule.forFeature([
      ShareItEffects,
    ]),
    TippyModule,
    NgbModalModule,
    ...MATERIAL,
  ],
  exports: [
    ...COMPONENTS,
    A11yModule,
  ],
  providers: [
    ShareItService,
  ],
})
export class ShareItModule { }
