import { Component, Input } from '@angular/core';
import { faUser } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-showcase-creator',
  templateUrl: './showcase-creator.component.html',
  styleUrls: ['./showcase-creator.component.scss'],
})
export class ShowcaseCreatorComponent {
  @Input() isCreator: boolean;
  @Input() creator: string;
  public readonly creatorIcon = faUser;
}
