<div class="maintenance-mode-page">
  <div class="maintenance-mode-page-content">
    <div class="maintenance-mode-page-description">
      <h2 class="maintenance-mode-page-description__header" data-automation-id="maintenance-mode-page-title">
        Site Unavailable
      </h2>
      <p class="maintenance-mode-page-description__text" data-automation-id="maintenance-mode-page-message">
        <span>The library catalog is temporarily unavailable for maintenance.</span>
        <br>
        <span>Check back later.</span>
      </p>
    </div>
    <div class="maintenance-mode-page-content__image-container">
      <img class="maintenance-mode-page-content__image" src="../../../../assets/maintenance-mode-image.svg"
           alt="Page not found">
    </div>
  </div>
</div>
