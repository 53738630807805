import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-remove-button',
  templateUrl: './remove-button.component.html',
  styleUrls: ['./remove-button.component.scss'],
})
export class RemoveButtonComponent {
  @Input() public controlsId: string;
  @Input() public inputValue: string;
  @Output() public readonly onClick = new EventEmitter<undefined>();
  public emptyField: string = 'empty';

  public readonly timesIcon = faTimes;
}
