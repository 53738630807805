import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { resetBulkSelection, bulkSelectAllItems, toggleSelectItem } from '../actions/bulk-select.actions';
import { BulkFeatureKey } from '../enums/bulk-feature-key';
import { BulkSelectItem } from '../models/bulk-select-item';

export interface BulkSelectState {
  selectedItemsState: { [key in BulkFeatureKey]: Map<string, BulkSelectItem> };
}

export const initialState: BulkSelectState = {
  selectedItemsState: {
    [BulkFeatureKey.SRP]: new Map(),
    [BulkFeatureKey.BOOKMARKS]: new Map(),
    [BulkFeatureKey.LIBRARY_LIST]: new Map(),
  },
};

const _reducer = createReducer(
  initialState,
  on(toggleSelectItem, (state, {bulkFeatureKey, item}) => {
    const newSelectedItemsMap = new Map(state.selectedItemsState[bulkFeatureKey]);
    newSelectedItemsMap.has(item.id)
      ? newSelectedItemsMap.delete(item.id)
      : newSelectedItemsMap.set(item.id, item);
    return {
      ...state,
      selectedItemsState: {
        ...state.selectedItemsState,
        [bulkFeatureKey]: newSelectedItemsMap,
      },
    };
  }),
  on(bulkSelectAllItems, (state, {bulkFeatureKey, items}) => {
    const newSelectedItemsMap = new Map();
    for (const item of items) {
      newSelectedItemsMap.set(item.id, item);
    }
    return {
      ...state,
      selectedItemsState: {
        ...state.selectedItemsState,
        [bulkFeatureKey]: newSelectedItemsMap,
      },
    };
  }),
  on(resetBulkSelection, (state, {bulkFeatureKey}) => {
    return {
      ...state,
      selectedItemsState: {
        ...state.selectedItemsState,
        [bulkFeatureKey]: new Map(),
      },
    };
  }),
);

export function reducer(state: BulkSelectState | undefined, action: Action) {
  return _reducer(state, action);
}

export const featureKey = 'bulkSelect';

export const getBulkSelectState = createFeatureSelector<BulkSelectState>(featureKey);

export const getSelectedItemsState = createSelector(getBulkSelectState, (state: BulkSelectState): { [key: string]: Map<string, any> } => {
  return state.selectedItemsState;
});

export const getSelectedItems = (bulkFeatureKey: BulkFeatureKey) => createSelector(
  getSelectedItemsState,
  (selectedItemsState): BulkSelectItem[] => {
    return [...selectedItemsState[bulkFeatureKey].values()];
  }
);

export const isItemSelected = (bulkFeatureKey: BulkFeatureKey, itemId: string) => createSelector(
  getSelectedItemsState,
  (selectedItemsState): boolean => {
    return selectedItemsState[bulkFeatureKey].has(itemId);
  }
);

