import { Component, Input } from '@angular/core';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { ExpirationMessageInfo } from 'user/models/user';

@Component({
  selector: 'app-profile-expiration-message',
  templateUrl: './profile-expiration-message.component.html',
  styleUrls: ['./profile-expiration-message.component.scss'],
})
export class ProfileExpirationMessageComponent {
  @Input() public expirationMessage: ExpirationMessageInfo;
  @Input() public expirationDate: string;
  @Input() public libraryContactLink: string;

  public readonly exclamationCircleIcon = faExclamationCircle;
}
