import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { asapScheduler, forkJoin, merge, of, scheduled, zip, } from 'rxjs';
import { filter, first, map, pluck, switchMap, tap, } from 'rxjs/operators';
import { beginHold, beginTabHold, loadCheckoutsSuccess, loadHoldsSuccess } from 'user/actions/user-profile.actions';
import { loggedIn } from '../../keycloak/actions/keycloak.actions';
import { ModalQueueService } from '../../list/services/modal-queue.service';
import { openSaveSearchModal } from '../../saved-search/actions/saved-search.actions';
import {
  rollupTabSelected,
  setEntities,
  setFocusedResourceId,
  tryToGetIt,
  tryToGetItRollup,
  tryToGetItTab,
  tryToPlaceHold
} from '../actions/entity.actions';
import { loadFormatGroupComplete } from '../actions/full-entity.actions';
import { PREPARE_EXTERNAL_GET_IT, PREPARE_EXTERNAL_HOLD_REQUEST } from '../actions/vendors.actions';
import { AvailabilityStatus, Edition, EntityTypes, GetItRequest, GetItTabRequest, VendorType } from '../models/entity';
import { VendorIssuesService } from '../services/vendor-issues.service';

@Injectable()
export class GetItAfterLoginEffects {
  /* eslint-disable @typescript-eslint/member-ordering */
  private incompleteFrcRequest$ = forkJoin([
    this.router.events.pipe(
      first((event) => event instanceof NavigationEnd),
      map(() => this.router.parseUrl(this.router.url)),
      pluck('queryParams'),
    ),
    merge(
      this.actions$.pipe(
        ofType(loadFormatGroupComplete),
        map(({entity}) => ({
          fgId: entity.fgEntity.id,
          editions: entity.fgEntity.materialTabs?.flatMap((tab) => tab.editions).filter((v) => !!v) || [],
          materialTabs: entity.fgEntity.materialTabs || [],
        })),
      ),
    ).pipe(first()),
  ]);

  public clearIncompleteRequest$ = createEffect(() => merge(
    this.actions$.pipe(ofType(beginHold.type)),
    this.actions$.pipe(ofType(beginTabHold.type)),
    this.actions$.pipe(ofType(PREPARE_EXTERNAL_GET_IT)),
    this.actions$.pipe(ofType(PREPARE_EXTERNAL_HOLD_REQUEST)),
    this.actions$.pipe(ofType(openSaveSearchModal)),
  )
  .pipe(
    filter(() => {
      const {queryParams} = this.router.parseUrl(this.router.url);
      const {getResourceId, getRecordId, getReserveId, getTabFgId, getTabName, holdResourceId, holdReserveId, openSaveSearchModal} = queryParams;
      return getResourceId || getRecordId || getReserveId || getTabFgId || getTabName || holdResourceId || holdReserveId || openSaveSearchModal;
    }),
    tap(() => {
      this.router.navigate([], {
        queryParams: {
          getResourceId: null,
          getResourceVendor: null,
          getReserveId: null,
          getRecordId: null,
          getTabFgId: null,
          getTabName: null,
          holdResourceId: null,
          holdResourceVendor: null,
          holdReserveId: null,
          openSaveSearchModal: null,
        },
        queryParamsHandling: 'merge',
      });
    }),
  ), {dispatch: false});

  public returnAuthenticatedAndPlaceHoldEntity$ = createEffect(() =>
    zip(
      this.router.events.pipe(
        first((event) => event instanceof NavigationEnd),
        map(() => this.router.parseUrl(this.router.url)),
        pluck('queryParams'),
        filter(({holdReserveId}) => !!holdReserveId),
      ),
      this.actions$.pipe(ofType(setEntities)),
      this.actions$.pipe(ofType(loadCheckoutsSuccess), first()),
      this.actions$.pipe(ofType(loadHoldsSuccess), first()),
    ).pipe(
      map(([{holdResourceVendor, holdReserveId}, {entities}]) => {
        let tabIndex = -1;
        const foundFg = entities.find((fg) => {
          tabIndex = fg.materialTabs.findIndex((tab) => tab.availability?.vendors?.overdrive?.reserveId[0] === holdReserveId);
          return tabIndex >= 0;
        });

        return {
          item: null,
          vendor: holdResourceVendor,
          reserveId: holdReserveId,
          tabIndex,
          fgId: foundFg?.id,
        };
      }),
      switchMap(({item, vendor, reserveId, fgId, tabIndex}) => {
        const actions: Action[] = [];
        if (fgId) {
          actions.push(
            setFocusedResourceId({resourceId: fgId}),
            rollupTabSelected({resourceId: fgId, tab: tabIndex, skipFirstAutomaticSelection: true}),
          );
        }
        return scheduled(of(
          ...actions,
          tryToPlaceHold({item, vendor, reserveId}),
        ), asapScheduler);
      }),
    ));

  // SRP Get It
  public returnAuthenticatedAndGetItEntity$ = createEffect(() =>
    zip(
      this.router.events.pipe(
        first((event) => event instanceof NavigationEnd),
        map(() => this.router.parseUrl(this.router.url)),
        pluck('queryParams'),
        filter(({getResourceId, getReserveId, getRecordId}) => !!(getResourceId || getReserveId || getRecordId)),
      ),
      this.actions$.pipe(ofType(setEntities)),
      this.actions$.pipe(ofType(loadCheckoutsSuccess), first()),
      this.actions$.pipe(ofType(loadHoldsSuccess), first()),
    ).pipe(
      map(([{getResourceId, getResourceVendor, getReserveId, getRecordId}, {entities}]) => {
        let tabIndex = -1;
        const fg = entities.find((fg) => {
          tabIndex = fg.materialTabs.findIndex((tab) => (
            tab.editions?.some((edition) => edition.id === getResourceId) || (getReserveId && getResourceVendor
              && tab.availability?.vendors?.[getResourceVendor as VendorType]?.reserveId.find((x) => x === getReserveId))
          ));

          return tabIndex >= 0;
        });
        const tab = fg?.materialTabs[tabIndex];
        const hasEcontentIssues = this.vendorIssuesService.hasVendorIssuesForTab(tab);
        return {
          id: fg?.id,
          vendor: getResourceVendor,
          recordId: tab?.recordIds?.[0] || getRecordId,
          entityType: EntityTypes.FORMAT_GROUP,
          formatGroupId: fg?.id,
          reserveId: getReserveId,
          tabIndex,
          hasEcontentIssues: hasEcontentIssues,
          usePublicApi: tab?.editions?.[0]?.availabilityStatus === AvailabilityStatus.ON_ORDER,
        } as GetItRequest;
      }),
      switchMap((item) => {
        const actions: Action[] = [];
        if (item.id) {
          actions.push(
            setFocusedResourceId({resourceId: item.id}),
            rollupTabSelected({resourceId: item.id, tab: item.tabIndex, skipFirstAutomaticSelection: true}),
          );
        }

        return scheduled(of(
          ...actions,
          tryToGetItRollup({item}),
        ), asapScheduler);
      }),
    ));

  // SRP Get It Tab
  public returnAuthenticatedAndGetItTabEntity$ = createEffect(() =>
    zip(
      this.router.events.pipe(
        first((event) => event instanceof NavigationEnd),
        map(() => this.router.parseUrl(this.router.url)),
        pluck('queryParams'),
        filter(({getTabFgId, getTabName}) => !!(getTabFgId || getTabName)),
      ),
      this.actions$.pipe(ofType(setEntities)),
      this.actions$.pipe(ofType(loadCheckoutsSuccess), first()),
      this.actions$.pipe(ofType(loadHoldsSuccess), first()),
    ).pipe(
      map(([{getTabFgId, getTabName}, {entities}]) => {
        const fg = entities.find((fg) => fg.id === getTabFgId);
        const tabIndex = fg.materialTabs.findIndex((tab) => tab.name === getTabName);
        const cardInfo = {id: getTabFgId, tabName: getTabName};
        return {cardInfo, tabIndex} as GetItTabRequest;
      }),
      switchMap(({cardInfo, tabIndex}) => {
        const actions: Action[] = [];
        if (tabIndex >= 0) {
          actions.push(
            setFocusedResourceId({resourceId: cardInfo.id}),
            rollupTabSelected({resourceId: cardInfo.id, tab: tabIndex, skipFirstAutomaticSelection: true}),
          );
        }

        return scheduled(of(
          ...actions,
          tryToGetItTab({cardInfo}),
        ), asapScheduler);
      }),
    ));

  // FRC Get It
  public returnAuthenticatedAndGetIt$ = createEffect(() =>
    zip(
      this.incompleteFrcRequest$.pipe(filter(([{getResourceId, getReserveId}]) => !!(getResourceId || getReserveId))),
      this.actions$.pipe(ofType(loadCheckoutsSuccess), first()),
      this.actions$.pipe(ofType(loadHoldsSuccess), first()),
    ).pipe(
      map(([[{getResourceId, getResourceVendor, getReserveId}, {fgId, materialTabs}]]) => {
        let item: Edition = null;
        const tabIndex = materialTabs.findIndex((tab) => {
          item = tab.editions?.find((edition) => (edition.id === getResourceId || (getReserveId && getResourceVendor
            && edition.vendors?.[getResourceVendor as VendorType]?.reserveId.find((x) => x === getReserveId))));
          return !!item;
        });
        return {
          item,
          reserveId: getReserveId,
          vendor: getResourceVendor,
          tabIndex,
          fgId,
        };
      }),
      switchMap(({item, vendor, reserveId, tabIndex, fgId}) => {
        const actions: Action[] = [];
        if (tabIndex >= 0) {
          actions.push(rollupTabSelected({resourceId: fgId, tab: tabIndex, skipFirstAutomaticSelection: true}));
        }
        return scheduled(of(
          tryToGetIt({item, vendor, reserveId} as any),
          ...actions,
        ), asapScheduler);
      }),
    ));

  // FRC Get It Tab
  public returnAuthenticatedAndGetItTab$ = createEffect(() =>
    zip(
      this.incompleteFrcRequest$.pipe(filter(([{getTabFgId, getTabName}]) => !!(getTabFgId || getTabName))),
      this.actions$.pipe(ofType(loadCheckoutsSuccess), first()),
      this.actions$.pipe(ofType(loadHoldsSuccess), first()),
    ).pipe(
      map(([[{getTabFgId, getTabName}, {materialTabs}]]) => {
        const tabIndex = materialTabs.findIndex((tab) => tab.name === getTabName);
        const cardInfo = {id: getTabFgId, tabName: getTabName};
        return {cardInfo, tabIndex} as GetItTabRequest;
      }),
      switchMap(({cardInfo, tabIndex}) => {
        const actions: Action[] = [];
        if (tabIndex >= 0) {
          actions.push(rollupTabSelected({resourceId: cardInfo.id, tab: tabIndex, skipFirstAutomaticSelection: true}));
        }
        return scheduled(of(
          tryToGetItTab({cardInfo}),
          ...actions,
        ), asapScheduler);
      }),
    ));

  public returnAuthenticatedAndPlaceHold$ = createEffect(() => this.incompleteFrcRequest$.pipe(
    filter(([{holdResourceId, holdReserveId}]) => !!(holdReserveId || holdResourceId)),
    map(([{holdResourceId, holdResourceVendor, holdReserveId}, {editions, fgId, materialTabs}]) => {
      const tabIndex = materialTabs.findIndex((tab) => (
        tab.editions?.some((edition) => edition.id === holdResourceId)
        || (holdReserveId && tab.availability?.vendors?.overdrive?.reserveId[0] === holdReserveId)
      ));

      return {
        item: editions.find((searchResult) => searchResult.id === holdResourceId),
        vendor: holdResourceVendor,
        reserveId: holdReserveId,
        tabIndex,
        fgId,
      };
    }),
    switchMap(({item, vendor, reserveId, fgId, tabIndex}) => {
      const actions: Action[] = [];
      if (tabIndex >= 0) {
        actions.push(rollupTabSelected({resourceId: fgId, tab: tabIndex, skipFirstAutomaticSelection: true}));
      }

      return scheduled(of(
        ...actions,
        tryToPlaceHold({item, vendor, reserveId}),
      ), asapScheduler);
    }),
  ));

  public decideAfterLoginModalFlow$ = createEffect(() =>
    zip(
      this.router.events.pipe(
        first((event) => event instanceof NavigationEnd),
        map(() => this.router.parseUrl(this.router.url)),
        pluck('queryParams'),
      ),
      this.actions$.pipe(ofType(loggedIn)),
    ).pipe(
      tap(([{getResourceId, getReserveId, holdResourceId, holdReserveId, getTabFgId}]) => {
        this.modalQueueService.noWaiting = !(getResourceId || getReserveId || holdResourceId || holdReserveId || getTabFgId);
      }),
    ), {dispatch: false});

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly modalQueueService: ModalQueueService,
    private readonly vendorIssuesService: VendorIssuesService
  ) {
  }
}
