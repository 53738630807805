import {
  FacetSchemaProps,
  defaultFacetSchema,
} from 'search/facets/models/facet-schema';
import { FacetResponseData } from 'search/facets/models/facet-data';
import {
  RecordOf,
  Record,
} from 'immutable';

export interface FacetSchemaWithDataProps<T extends FacetResponseData> {
  data: T;
  schema: FacetSchemaProps;
}

export const defaultFacetSchemaWithData: FacetSchemaWithDataProps<FacetResponseData> = {
  schema: defaultFacetSchema,
  data: null,
};

export const makeFacetSchemaWithData = Record(defaultFacetSchemaWithData, 'facetSchemaWithData');

export type FacetSchemaWithData<T = FacetResponseData> = RecordOf<FacetSchemaWithDataProps<T>>;
