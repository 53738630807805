<div aria-atomic="true" aria-live="assertive" role="alert">
  <div class="px-3 mb-2 text-center" *ngIf="unavailableItems.length">
    <span class="warning-message " data-automation-id="select-list-unavailable-items-warning-message">
      {{ 'selectListSomeTitlesIneligibleForAction' | translate }}
    </span>
    <button
      (click)="onToggleCollapsibleList()"
      class="toggle-button mb-1"
      data-automation-id="select-list-unavailable-items-toggle-button">
      <span>{{ 'selectListSeeIneligibleTitles' | translate }}</span>
      <fa-icon [icon]="isCollapsed ? caretDownIcon : caretUpIcon" class="ml-2"></fa-icon>
    </button>
    <ul class="items-list ml-2 mb-2" [(ngbCollapse)]="isCollapsed">
      <li *ngFor="let unavailableEntity of unavailableItems" class="mb-1"
          data-automation-id="select-list-unavailable-item">
        {{ unavailableEntity.title }}
      </li>
    </ul>
  </div>
</div>
