import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { faBars, faSearch } from '@fortawesome/pro-regular-svg-icons';

import { CustomizableLink } from 'app/models/customization';
import { Breakpoints } from '../../../models/viewport';
import { ViewportService } from '../../../services/viewport.service';
import { openGlobalSidebar } from 'core/actions/global-sidebar.actions';
import { SearchBarComponent } from 'common/components/search-bar/search-bar.component';

@Component({
  selector: 'app-global-nav',
  templateUrl: './global-nav.component.html',
  styleUrls: ['./global-nav.component.scss'],
})
export class GlobalNavComponent implements OnInit, OnDestroy {
  @ViewChild(SearchBarComponent) searchBarComponent: SearchBarComponent;
  @Input() public links?: CustomizableLink[];
  public readonly allLinksIcon = faBars;
  public readonly searchIcon = faSearch;
  public isWiderThanXs: boolean;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly viewportService: ViewportService
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.viewportService.isWiderThan(Breakpoints.RESOLUTION_MAX_XS).subscribe((isWiderThanXs) => {
        this.isWiderThanXs = isWiderThanXs;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onSearchDropdownToggle(isOpen: boolean): void {
    if (!isOpen) return;

    setTimeout(() => {
      this.searchBarComponent.setFocusOnInputField();
    });
  }

  public openGlobalSidebar(): void {
    this.store.dispatch(openGlobalSidebar());
  }
}
