import { createAction, props } from '@ngrx/store';
import { FormatGroup } from '../../../../entity/models/entity';
import { NlbBookmark } from '../models/nlb-bookmark';

export const loadNlbBookmarks = createAction(
  '[NlbBookmark] Load NLB bookmarks',
);

export const loadNlbBookmarksSuccess = createAction(
  '[NlbBookmark] Load NLB bookmarks success',
  props<{ nlbBookmarks: NlbBookmark[] }>(),
);

export const loadNlbBookmarksFailure = createAction(
  '[NlbBookmark] Load NLB bookmarks failure',
);

export const tryToCreateNlbBookmark = createAction(
  '[NlbBookmark] Try to create NLB bookmark',
  props<{ tabName: string, formatGroup: FormatGroup }>(),
);

export const createNlbBookmark = createAction(
  '[NlbBookmark] Create NLB bookmark',
  props<{ tabName: string, formatGroup: FormatGroup }>(),
);

export const createNlbBookmarkSuccess = createAction(
  '[NlbBookmark] Create NLB bookmark success',
  props<{ nlbBookmark: NlbBookmark, formatGroupId: string }>(),
);

export const createNlbBookmarkFailure = createAction(
  '[NlbBookmark] Create NLB bookmark failure',
  props<{ formatGroupId: string }>()
);

export const deleteNlbBookmark = createAction(
  '[NlbBookmark] Delete NLB bookmark',
  props<{ nlbBookmark: NlbBookmark, formatGroup: FormatGroup }>(),
);

export const deleteNlbBookmarkSuccess = createAction(
  '[NlbBookmark] Delete NLB bookmark success',
  props<{ nlbBookmark: NlbBookmark, formatGroupId: string }>(),
);

export const deleteNlbBookmarkFailure = createAction(
  '[NlbBookmark] Delete NLB bookmark failure',
  props<{ formatGroupId: string }>()
);

export const resumeCreateNlbBookmarkAfterLogin = createAction(
  '[NlbBookmark] Resume create NLB bookmark',
  props<{ formatGroup: FormatGroup, tabName: string, focusResource: boolean, nlbBookmarks: NlbBookmark[] }>()
);
