import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { setAllItemsSelection, toggleItemSelection } from 'app/list/actions/list.actions';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { getPatronOrStaffUserId } from 'user/reducers/user.reducer';
import * as ListReducer from '../../list/reducers/list.reducer';
import { ListItemsLoadingState } from '../../list/reducers/list.reducer';
import { setAllOpenedCuratedShowcaseItemsSelection, toggleOpenedCuratedShowcaseItemSelection } from '../actions/custom-showcase.actions';
import {
  CustomShowcaseCreatedFromType,
  CustomShowcaseSingle,
  isCuratedShowcase,
  OpenedShowcaseWithDetails
} from '../models/custom-showcase';
import * as CustomShowcaseReducer from '../reducers/custom-showcase.reducer';
import { ShowcaseSearchData } from '../reducers/custom-showcase.reducer';

@Injectable()
export class CustomShowcaseStoreAdapterService {
  private readonly userId$: Observable<string>;

  constructor(private readonly store: Store) {
    this.userId$ = this.store.select(getPatronOrStaffUserId);
  }

  public getOpenedShowcase(): Observable<[CustomShowcaseSingle | null, string]> {
    return this.userId$.pipe(
      switchMap((userId) => combineLatest([
        this.store.select(CustomShowcaseReducer.getOpenedShowcase),
        of(userId),
      ])),
    );
  }

  public getOpenedShowcaseWithDetails(): Observable<null | OpenedShowcaseWithDetails> {
    return this.getOpenedShowcase().pipe(
      switchMap(([openedShowcase, userId]): Observable<null | OpenedShowcaseWithDetails> => {
        if (openedShowcase) {
          const isShowcaseCreator = userId === openedShowcase.creator.id;

          switch (openedShowcase.createdFrom.type) {
            case CustomShowcaseCreatedFromType.list: {
              return combineLatest([
                isShowcaseCreator
                  ? this.store.select(ListReducer.getListItems, {id: openedShowcase.createdFrom.id})
                  : this.store.select(CustomShowcaseReducer.getOpenedShowcaseItems),
                isShowcaseCreator
                  ? this.store.select(ListReducer.getListPagination, {id: openedShowcase.createdFrom.id})
                  : this.store.select(CustomShowcaseReducer.getOpenedShowcaseItemsPagination),
              ])
              .pipe(
                map(([items, pagination]) => ({
                  showcase: openedShowcase,
                  items,
                  pagination,
                  type: CustomShowcaseCreatedFromType.list,
                })),
              );
            }
            case CustomShowcaseCreatedFromType.savedSearch: {
              return this.store.select(CustomShowcaseReducer.getShowcaseSearchData)
              .pipe(
                map((result: ShowcaseSearchData) => {
                  return {
                    showcase: openedShowcase,
                    type: CustomShowcaseCreatedFromType.savedSearch,
                    ...result,
                  };
                }),
              );
            }
          }
        } else {
          return of(null);
        }
      }),
    );
  }

  public getOpenedShowcaseLoadings(): Observable<[
    CustomShowcaseReducer.ShowcaseLoadingState,
    (ListReducer.ListItemsLoadingState | null),
  ]> {
    return this.getOpenedShowcase().pipe(
      switchMap(([openedShowcase, userId]) => {
        let itemsLoadingState: Observable<ListItemsLoadingState> = of(null);
        if (isCuratedShowcase(openedShowcase)) {
          itemsLoadingState = (userId === openedShowcase.creator.id)
            ? this.store.select(ListReducer.getListItemsLoadingState, {id: openedShowcase.createdFrom.id})
            : this.store.select(CustomShowcaseReducer.getOpenedShowcaseItemsLoadingState);
        }

        return combineLatest([
          this.store.select(CustomShowcaseReducer.getOpenedShowcaseLoadingState),
          itemsLoadingState,
        ]);
      }),
    );
  }

  public setAllItemsSelection(openedShowcase: CustomShowcaseSingle, selected: boolean) {
    this.dispatchIfOwned(
      openedShowcase.creator.id,
      setAllItemsSelection({listId: openedShowcase.createdFrom.id, selected}),
      setAllOpenedCuratedShowcaseItemsSelection({selected}),
    );
  }

  public toggleItemSelection(openedShowcase: CustomShowcaseSingle, itemId: string) {
    this.dispatchIfOwned(
      openedShowcase.creator.id,
      toggleItemSelection({listId: openedShowcase.createdFrom.id, itemId}),
      toggleOpenedCuratedShowcaseItemSelection({itemId}),
    );
  }

  private dispatchIfOwned(creatorId: string, action: Action, elseAction: Action): void {
    this.userId$.pipe(
      take(1),
      tap((userId) => {
        this.store.dispatch((userId === creatorId) ? action : elseAction);
      }),
    ).subscribe();
  }
}
