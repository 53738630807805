import { animate, state, style, transition, trigger, } from '@angular/animations';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Store, } from '@ngrx/store';
import { isAuthorizedInKeycloak, isStaffAuthorizedInKeycloak, KeycloakState, } from 'app/keycloak/reducers/keycloak.reducer';
import { KeycloakService } from 'app/keycloak/services/keycloak.service';
import { Observable, Subscription, } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ConfigurationLoader } from 'shared/configuration-loader';
import { LoginType } from 'shared/models/configuration';
import { focusUserPanelButton, toggleOpenedUserPanel } from 'user/actions/user-profile.actions';
import { StaffUser, User } from 'user/models/user';
import {
  getCheckoutsTotal,
  getHoldsTotal,
  getMoneyOwed,
  getStaffUser,
  getStaffUserName,
  getUser,
  getUserNicknameOrName
} from 'user/reducers/user.reducer';
import { CustomerIntegrationLinks, } from '../../../customer-integration/customer-integration';
import { CustomerIntegrationService } from '../../../customer-integration/services/customer-integration.service';
import { WindowRefService } from '../../../services/window-ref.service';

@Component({
  selector: 'app-inspire-user-panel',
  templateUrl: './inspire-user-panel.component.html',
  styleUrls: ['./inspire-user-panel.component.scss'],
  animations: [
    trigger('present', [
      state('void', style({opacity: 0})),
      transition('void <=> *', animate('300ms ease-in-out')),
    ]),
  ],
})
export class InspireUserPanelComponent implements OnInit, OnDestroy {
  public libraryName?: string;
  public loginType?: LoginType;
  public checkoutsTotal$ = this.store.select(getCheckoutsTotal);
  public holdsTotal$ = this.store.select(getHoldsTotal);
  public moneyOwed$ = this.store.select(getMoneyOwed);

  public user$: Observable<User>;
  public staffUser$: Observable<StaffUser>;
  public userNicknameOrName$: Observable<string>;
  public staffUserName$: Observable<string>;
  public isPatronAuthorized: boolean;
  public isStaffAuthorized: boolean;
  public guestLibraryEmail: string;
  public customLinks: CustomerIntegrationLinks;

  public fixedHeader = false;
  public top = '80px';
  public right = '0';
  public left = 'unset';

  private leftAlignment = false;
  private subscription = new Subscription();

  constructor(
    private configLoader: ConfigurationLoader,
    private keycloakService: KeycloakService,
    private store: Store<KeycloakState>,
    private elementRef: ElementRef,
    private router: Router,
    private windowRef: WindowRefService,
    private customerIntegrationService: CustomerIntegrationService
  ) {
  }

  @HostListener('body:click', ['$event.target'])
  public onClick(targetElement: any) {
    if (!this.elementRef.nativeElement.contains(targetElement)) {
      this.closeUserPanel();
    }
  }

  @HostListener('keydown.escape')
  public escapePressed() {
    this.closeUserPanel();
  }

  public ngOnInit() {
    this.libraryName = this.configLoader.headerConfig.title;
    this.loginType = this.configLoader.loginType;
    this.fixedHeader = this.configLoader.headerConfig?.fixedHeader;
    this.guestLibraryEmail = this.configLoader.libraryInfoConfiguration?.defaultLocationInfo?.email;
    this.customLinks = this.customerIntegrationService.CustomerIntegrationLinksMap[this.configLoader.customerIntegrationType];

    this.user$ = this.store.select(getUser);
    this.userNicknameOrName$ = this.store.select(getUserNicknameOrName);
    this.staffUser$ = this.store.select(getStaffUser);
    this.staffUserName$ = this.store.select(getStaffUserName);

    this.subscription.add(this.store.select(isAuthorizedInKeycloak).subscribe((isAuthorized) => {
      this.isPatronAuthorized = isAuthorized;
    }));

    this.subscription.add(this.store.select(isStaffAuthorizedInKeycloak).subscribe((isStaffAuthorized) => {
      this.isStaffAuthorized = isStaffAuthorized;
    }));

    this.subscription.add(this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.closeUserPanel();
      }));

    this.calculatePlacement();
  }

  public logOut() {
    this.keycloakService.logout();
  }

  public logIn() {
    this.keycloakService.login();
  }

  public closeUserPanel() {
    this.store.dispatch(toggleOpenedUserPanel({isOpened: false}));
    this.store.dispatch(focusUserPanelButton());
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private calculatePlacement() {
    const {innerWidth: windowWidth, pageYOffset} = this.windowRef.nativeWindow();
    const loginButtonRect = this.windowRef.nativeWindow().document.getElementById('user-login-button')?.getBoundingClientRect();

    if (loginButtonRect) {

      const top = loginButtonRect.top + loginButtonRect.height + this.fixedHeader ? 0 : pageYOffset;

      this.leftAlignment = loginButtonRect.left < windowWidth / 2;
      this.top = `${top}px`;
      this.left = this.leftAlignment ? `${loginButtonRect.left}px` : 'unset';
      this.right = this.leftAlignment ? 'unset' : `${windowWidth - loginButtonRect.right}px`;
    }
  }
}
