<ng-container>
  <ng-container *ngIf="!isElectronicResource; else getItElectronic">
    <ng-container *ngIf="!isNlbCustomer; else nlbHoldAction">
      <button class="ins-buttons button-{{type}} flint w-100"
              data-automation-id="place-hold-btn" translate
              (click)="getIt($event)"
              [disabled]="mode === modes.MultipleMode"
              [attr.aria-describedby]="ariaDescribedBy"
              *ngIf="getOneEdition; else rollUpGetIt">
        placeHold
      </button>
      <ng-template #rollUpGetIt>
        <app-get-it-button [tabName]="tabName"
                           [resourceId]="resourceId"
                           [ariaDescribedBy]="ariaDescribedBy"></app-get-it-button>
      </ng-template>
    </ng-container>
    <ng-template #nlbHoldAction>
      <button *ngIf="getOneEdition; else tabPlaceHold"
              (click)="getIt($event)"
              class="ins-buttons button-{{type}} flint w-100"
              data-automation-id="edition-place-hold-link" translate>
        placeHold
      </button>
      <ng-template #tabPlaceHold>
        <app-get-it-button [tabName]="tabName"
                           [resourceId]="resourceId"
                           [ariaDescribedBy]="ariaDescribedBy"></app-get-it-button>
      </ng-template>
    </ng-template>
  </ng-container>

  <ng-template #getItElectronic>
    <ng-container
      *ngIf="availability[vendorType.GENERAL] === availabilityStatus.GET_MATERIAL">
      <button class="ins-buttons button-{{type}} flint w-100"
              (click)="openGetItPicker($event)"
              [attr.aria-describedby]="ariaDescribedBy"
              data-automation-id="get-material-btn"
              translate>getMaterial
      </button>
    </ng-container>
    <ng-container
      *ngIf="availability[vendorType.GENERAL] === availabilityStatus.CHECKED_OUT">
      <button class="ins-buttons button-{{type}} flint w-100" (click)="placeHold($event)"
              translate
              [attr.aria-describedby]="ariaDescribedBy"
              data-automation-id="place-hold-btn">placeHold
      </button>
    </ng-container>

    <ng-container
      *ngIf="availability[vendorType.GENERAL] === availabilityStatus.AVAILABLE && !hasEcontentIssues"
    >
      <button class="ins-buttons button-{{type}} flint w-100" (click)="getIt($event)"
              translate
              [attr.aria-describedby]="ariaDescribedBy"
              data-automation-id="get-it-btn">getIt
      </button>
    </ng-container>
    <ng-container *ngIf="availability[vendorType.GENERAL] === availabilityStatus.GET_ISSUES">
      <button class="ins-buttons button-{{type}} flint w-100" (click)="getIt($event)"
              translate
              [attr.aria-describedby]="ariaDescribedBy"
              data-automation-id="get-issue-btn">getIssue
      </button>
    </ng-container>

    <ng-container *ngIf="availability[vendorType.GENERAL] === availabilityStatus.CHECK_AVAILABILITY
          && !eVendorAvailabilityPending && !hasEcontentIssues">
      <button class="ins-buttons button-{{type}} flint w-100"
              data-automation-id="check-availability-btn"
              [attr.aria-describedby]="ariaDescribedBy"
              (click)="openGetItPicker($event)" translate>checkAvailabilityButton
      </button>
    </ng-container>

    <ng-container
      *ngIf="eVendorAvailabilityPending">
      <button class="ins-buttons button-primary flint loading w-100"
              data-automation-id="await-availability-btn"
              [attr.aria-label]="'awaitingRetrievingAvailability' | translate"
              [attr.aria-describedby]="ariaDescribedBy">
        <div class="spinner-border"
             role="status"
             aria-live="polite">
                    <span class="sr-only" translate>
                        Loading...
                    </span>
        </div>
      </button>
    </ng-container>
  </ng-template>
</ng-container>
