<div class="d-flex flex-column align-items-center align-items-sm-end">
  <div ngbDropdown
       #dropdown="ngbDropdown"
       placement="bottom-right bottom top-right auto"
       (openChange)="setDropdownOpened($event)"
       class="d-flex mb-2">
    <button *ngIf="defaultAction"
            class="ins-buttons button-primary flint action-button__current-action"
            [class.no-dropdown]="!cardActions?.length"
            (click)="onTriggerAction(defaultAction)"
            [attr.aria-disabled]="disabled"
            [class.button-disabled]="disabled"
            aria-haspopup="menu"
            data-automation-id="checkout-card-action-button-default-action"
            [attr.aria-describedby]="ariaDescribedBy">
      <ng-template [ngTemplateOutlet]="defaultAction?.template"></ng-template>
    </button>
    <button *ngIf="cardActions?.length"
            ngbDropdownAnchor
            class="action-button__caret hide-default-caret ins-buttons button-primary flint"
            (click)="$event.stopPropagation(); toggleDropdown(dropdown)"
            [attr.aria-expanded]="actionDropdownOpened"
            [attr.aria-disabled]="disabled"
            [class.button-disabled]="disabled"
            aria-haspopup="menu"
            data-automation-id="checkout-card-action-button-toggle"
            [attr.aria-describedby]="ariaDescribedBy">
      <span class="icon-angle-down-thin"></span>
    </button>
    <div ngbDropdownMenu role="menu" data-automation-id="checkout-card-action-button-dropdown">
      <button *ngFor="let cardAction of cardActions"
              (click)="onTriggerAction(cardAction)"
              [attr.aria-describedby]="ariaDescribedBy"
              class="d-block py-2 px-5 w-100 h-auto action-button__dropdown-option"
              ngbDropdownItem
              aria-haspopup="dialog"
              role="menuitem"
              data-automation-id="checkout-card-action-button-dropdown-option">
        <ng-template [ngTemplateOutlet]="cardAction.template"></ng-template>
      </button>
    </div>
  </div>

  <div *ngIf="action" class="action-status__current-action">
    <div class="action-status__current-action--pending d-flex align-items-center"
         *ngIf="action.status === actionStatus.Pending">
      <div class="action-status__current-action__icon">
        <div class="spinner-border text-primary" data-automation-id="checkout-card-action-spinner">
          <span class="sr-only" translate>Loading...</span>
        </div>
      </div>
      <div class="action-status__current-action__label" [ngSwitch]="action.type"
           data-automation-id="checkout-card-action-pending">
        <ng-container *ngSwitchCase="actionType.RenewCheckout">
          {{ 'Renewing' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="actionType.CheckIn">
          {{ 'checkInPending' | translate }}
        </ng-container>
      </div>
    </div>

    <div class="action-status__current-action--success d-flex align-items-center"
         *ngIf="action.status === actionStatus.Success"
         aria-atomic="true"
         aria-live="assertive"
         role="alert"
         data-automation-id="checkout-card-action-success">
      <span class="fa fa-check" aria-hidden="true"></span>
      <ng-container [ngSwitch]="action.type">
        <ng-container *ngSwitchCase="actionType.RenewCheckout">
          {{ 'checkoutRenewSuccess' | translate }}
        </ng-container>
      </ng-container>
    </div>

    <div class="action-status__current-action--failed action-status__current-action__label text-center text-sm-right"
         *ngIf="action.status === actionStatus.Failed"
         aria-atomic="true"
         aria-live="assertive"
         role="alert"
         data-automation-id="checkout-card-action-failure">
      <span class="fas fa-exclamation-circle" aria-hidden="true"></span>
      <ng-container *ngIf="action.error">
        {{action.error}}
      </ng-container>

      <ng-container *ngIf="!action.error" [ngSwitch]="action.type">
        <ng-container *ngSwitchCase="actionType.RenewCheckout">
          {{ 'checkoutRenewFailed' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="actionType.CheckIn">
          {{ 'checkInFailed' | translate }}
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
