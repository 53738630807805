<ng-container *ngIf="loadState">
  <p *ngIf="loadState.error; else loadingOrContent"
     role="alert"
     aria-live="assertive"
     aria-atomic="true"
     class="m-4 text-white"
     translate
     data-automation-id="saved-searches-error-message">
    generalServerError
  </p>

  <ng-template #loadingOrContent>
    <div *ngIf="loadState.loading; else savedSearchesOrNoSearches" class="d-flex justify-content-center pt-5">
      <div class="spinner-border text-primary"
           style="width: 7rem; height: 7rem;"
           role="status"
           aria-live="polite"
           data-automation-id="saved-searches-spinner">
        <span class="sr-only" translate>Loading...</span>
      </div>
    </div>
  </ng-template>

  <ng-template #savedSearchesOrNoSearches>
    <ol *ngIf="savedSearches?.length; else noSearches"
        class="d-flex flex-wrap justify-content-center justify-content-md-start mb-0"
        attr.aria-label="{{ 'savedSearchesListTitle' | translate }}">
      <li *ngFor="let savedSearch of savedSearches; trackBy: trackById"
          class="d-block m-2 m-md-4" data-automation-id="bookshelf-saved-search-card">
        <app-saved-search-card [savedSearch]="savedSearch" (onRunSavedSearch)="runSavedSearch($event)"></app-saved-search-card>
      </li>
    </ol>
  </ng-template>

  <ng-template #noSearches>
    <div #zeroSearches
         *ngIf="loadState.loaded"
         class="m-4 text-white"
         data-automation-id="saved-searches-status-message"
         tabindex="-1">
      <p translate>savedSearchesYouHaveZeroResults</p>
      <p translate>savedSearchesHowToASaveASearch</p>
    </div>
  </ng-template>
</ng-container>
