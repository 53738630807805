import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BookshelfItem } from 'common/models/bookshelf-card';

@Component({
  selector: 'app-bookshelf-items-list',
  templateUrl: './bookshelf-items-list.component.html',
  styleUrls: ['./bookshelf-items-list.component.scss'],
})
export class BookshelfItemsListComponent {
  @Input() public bookshelfItems: BookshelfItem[];
  @Input() public listId?: string;
  @Input() public isExtendedCard: boolean;
  // todo: remove; it's a temporary solution for hiding checkbox in RH
  @Input() public hideCheckbox: boolean;
  @Input() public ariaLabel = '';
  @Output() public onToggleItem = new EventEmitter<BookshelfItem>();

  public toggleItemSelection(bookshelfItem: BookshelfItem): void {
    this.onToggleItem.emit(bookshelfItem);
  }

  public trackById(index: number, bookshelfItem: BookshelfItem): string {
    return bookshelfItem.id;
  }
}
