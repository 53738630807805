import {
  Pipe,
  PipeTransform,
} from '@angular/core';
import { Collection, fromJS } from 'immutable';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe<T> implements PipeTransform {

  public transform(array: T[], fields: string[], isReverseOrder?: boolean): T[] {
    const uniqueFields = fields.filter((element, index) => fields.indexOf(element) === index);

    return array.slice().sort((first: T, second: T) => {
      for (const field of uniqueFields) {
        const firstProperty = this.getProperty(first, field);
        const secondProperty = this.getProperty(second, field);

        if (firstProperty !== secondProperty) {
          if (firstProperty === null) {
            return 1;
          } else if (secondProperty === null) {
            return -1;
          } else if (isReverseOrder) {
            return firstProperty < secondProperty ? 1 : -1;
          } else {
            return firstProperty < secondProperty ? -1 : 1;
          }
        }
      }

      return 0;
    });
  }

  private getProperty(object: T, path: string): any {
    const immutableObject = fromJS(object) as Collection<string, any>;
    return immutableObject.getIn(path.split('.'));
  }
}
