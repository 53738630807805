import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-embeddable-showcase-error-dialog',
  templateUrl: './embeddable-showcase-error-dialog.component.html',
  styleUrls: ['./embeddable-showcase-error-dialog.component.scss']
})
export class EmbeddableShowcaseErrorDialogComponent {
  public faExclamationCircle = faExclamationCircle;

  constructor(
    public activeModal: NgbActiveModal,
  ) {}

}
