<div class="p-4">
  <div class="modal-header">
    <h2 class="modal-title mb-0" data-automation-id="opt-out-modal-title" translate>
      confirmationActionDialogTitle
    </h2>
    <button
      (click)="onClose()"
      [attr.aria-label]="'closeDialog' | translate"
      class="close-btn p-0 m-n2"
      data-automation-id="close-opt-out-icon"
      type="button">
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
  </div>
  <div class="modal-body">
    <span
      data-automation-id="opt-out-modal-text"
      translate>
      ReadingHistoryOptOutWarning
    </span>
  </div>
  <div class="modal-footer justify-content-start">
    <button
      (click)="onConfirm()"
      class="ins-buttons button-primary flint"
      data-automation-id="confirm-opt-out-modal-btn"
      translate
      type="button">
      confirm
    </button>
    <button
      (click)="onClose()"
      class="ins-buttons button-secondary flint ml-3"
      data-automation-id="cancel-opt-out-modal-btn"
      translate>
      cancel
    </button>
  </div>
</div>
