<div class="custom-dropdown">
  <button
    [ngClass]="{'custom-dropdown__toggle-fixed-width': fixedWidth}"
    class="custom-dropdown__toggle h-auto"
    data-automation-id="dropdownToggle"
    cdkOverlayOrigin
    #toggleDropdownButton
    #trigger="cdkOverlayOrigin"
    [attr.aria-expanded]="isExpanded"
    [attr.aria-label]="ariaLabel ? ariaLabel + ' ' + labelSelected : null"
    aria-haspopup="listbox"
    (click)="toggle()">
    <div>
      <span class="custom-dropdown__label"
            [ngClass]="{'text-truncate': fixedWidth}">
        <ng-content select="[custom-dropdown-label]"></ng-content></span>
      <span data-automation-id="dropdownSelectedOption"
            class="custom-dropdown__dropdown-label"
            [ngClass]="{'text-truncate': fixedWidth}">
        <span *ngIf="!valueSelected; else selectedItem">
          {{ ariaLabel || 'Select option' | translate }}
        </span>
        <ng-template #selectedItem>
           <img *ngIf="imageSelected" [src]="imageSelected" data-automation-id="dropdownSelectedOptionWithImage"
                [ngClass]="labelSelected ?
                          'custom-dropdown__dropdown-label__image' : 'custom-dropdown__dropdown-label__only__image'"
                alt=""
                role="presentation">
           <span *ngIf="labelSelected" data-automation-id="dropdownSelectedOptionLabel">  {{ labelSelected  }}</span>
        </ng-template>

      </span>
    </div>
    <fa-icon class="custom-dropdown__toggle__triangle" [icon]="arrowIconDown"></fa-icon>
  </button>
</div>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayBackdropClass="transparent-backdrop"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isExpanded"
  [cdkConnectedOverlayPositions]="isTopOriented ? topPositionConfig : defaultPositionConfig"
  (backdropClick)="close()"
  (detach)="close()">
  <div [ngClass]="isTopOriented ? 'options options-top' : 'options'" role="listbox" tabindex="0"
       [attr.aria-label]="ariaLabel"
       cdkTrapFocus
       cdkTrapFocusAutoCapture
       [attr.aria-activedescendant]="keyManager.activeItem?.value"
       (keydown)="keyHandler($event)"
       (mouseover)="onMouseover()"
       (mouseout)="onMouseout()">
    <ng-content></ng-content>
  </div>
</ng-template>
