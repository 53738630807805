import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as ShareItActions from '../actions/share-it.actions';
import { EmailEntityModalComponent } from '../components/email-entity-modal/email-entity-modal.component';
import { ShareItService } from '../services/share-it.service';
import { Store } from '@ngrx/store';

@Injectable()
export class ShareItEffects {
  public emailEntity$ = createEffect(() => this.actions$.pipe(
    ofType(ShareItActions.emailEntity),
    switchMap(({ body }) => {
      return this.shareItService.shareItShare(body)
      .pipe(
        map(() => ShareItActions.emailEntityComplete()),
        catchError((err: HttpErrorResponse) => {
          const notFound = (err.status === 404);
          return of(ShareItActions.emailEntityFailure({ error: { notFound } }));
        }),
      );
    }),
  ));

  public openEmailSavedSearchModal$ = createEffect(() => this.actions$.pipe(
    ofType(ShareItActions.openEmailEntityModal),
    map(({ defaultSubject, resource }) => {
      const modalRef = this.modal.open(EmailEntityModalComponent, {
        windowClass: 'inspire-custom-modal',
        ariaLabelledBy: 'email-entity-modal-title',
      });

      modalRef.componentInstance.defaultSubject = defaultSubject;
      modalRef.componentInstance.resource = resource;
      modalRef.result.finally(() => {
        this.store.dispatch(ShareItActions.notifyEmailEntityModalClosed({ resource }));
      });
    }),
  ), { dispatch: false });

  constructor(
    private readonly actions$: Actions,
    private readonly shareItService: ShareItService,
    private readonly modal: NgbModal,
    private readonly store: Store,
  ) {
  }
}
