import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  bulkRenew,
  checkoutCardActionFailure,
  checkoutCardActionSuccess,
} from 'user/actions/user-profile.actions';
import { CheckoutCardRecord, CheckoutCardRecordFactory } from 'user/reducers/user.reducer';
import * as Immutable from 'immutable';
import { UserReceiptActionStatus } from 'user/models/user-receipt-card';
import { CheckoutCard } from 'user/models/checkouts';

export const bulkRenewFeatureKey = 'bulkRenew';

export interface BulkRenewState {
  cards: Immutable.List<Immutable.Record<CheckoutCard>>;
  nextItemToRenewId: number;
}

export const initialState: BulkRenewState = {
  cards: Immutable.List(),
  nextItemToRenewId: null
};

const updateCheckoutCardIfFound = (
  state: BulkRenewState,
  checkoutCardPredicate: (checkoutCard: CheckoutCardRecord) => boolean,
  callback: (checkoutCard: CheckoutCardRecord) => CheckoutCardRecord,
): BulkRenewState => {
  let newState = state;
  let someCheckoutCardMutated = false;

  const checkoutCardsMutated = state.cards.withMutations((checkoutCards) => {
    const checkoutCardIndex = checkoutCards.findIndex(checkoutCardPredicate);

    if (checkoutCardIndex >= 0) {
      someCheckoutCardMutated = true;
      checkoutCards.updateIn([checkoutCardIndex], callback);
    }
  });

  if (someCheckoutCardMutated) {
    newState = {
      ...newState,
      cards: checkoutCardsMutated,
    };
  }

  return newState;
};

const incrementCardIndex = (state: BulkRenewState) => {
  return state.nextItemToRenewId < state.cards.size ? state.nextItemToRenewId + 1 : state.nextItemToRenewId;
};

export const reducer = createReducer(
  initialState,
  on(bulkRenew, (state: BulkRenewState, {cards}) => ({
      ...state,
      cards: Immutable.List(cards.map((card) => CheckoutCardRecordFactory({receipt: card.receipt}))),
      nextItemToRenewId: 0,
    }
  )),
  on(checkoutCardActionSuccess, (state: BulkRenewState, {checkoutId, actionType}) => ({
    ...updateCheckoutCardIfFound(
      state,
      (checkoutCard) => checkoutCard.get('receipt').id === checkoutId,
      (checkoutCard) => (
        checkoutCard.set('action', {
          type: actionType,
          status: UserReceiptActionStatus.Success,
          error: null,
        })
      ),
    ),
    nextItemToRenewId: incrementCardIndex(state),
  })),
  on(checkoutCardActionFailure, (state: BulkRenewState, {checkoutId, actionType, error}) => ({
    ...updateCheckoutCardIfFound(
      state,
      (checkoutCard) => checkoutCard.get('receipt').id === checkoutId,
      (checkoutCard) => (
        checkoutCard.set('action', {
          type: actionType,
          status: UserReceiptActionStatus.Failed,
          error,
        })
      ),
    ),
    nextItemToRenewId: incrementCardIndex(state),
  })),
);

export const getBulkRenewState = createFeatureSelector<BulkRenewState>(bulkRenewFeatureKey);

// get next item to renew; next item is updated only when the previous renewal is completed
export const checkoutToRenew = createSelector(getBulkRenewState, (state: BulkRenewState): string => {
  const cardId = (state.cards.toJS() as CheckoutCard[])[state.nextItemToRenewId]?.receipt?.id;
  return cardId || null;
});

export const noRenewalsInProgress = createSelector(checkoutToRenew, (checkoutId): boolean => checkoutId === null);

export const processedItemsNumber = createSelector(getBulkRenewState, (state: BulkRenewState): number => state.nextItemToRenewId);

export const renewedCheckouts = createSelector(
  getBulkRenewState,
  noRenewalsInProgress, (state, noRenewals): CheckoutCard[] => {
    if (noRenewals) {
      return (state.cards.toJS() as CheckoutCard[]).filter((card) => card.action.status === UserReceiptActionStatus.Success);
    }
    return [];
  });

export const failedToRenewCheckouts = createSelector(
  getBulkRenewState,
  noRenewalsInProgress, (state, noRenewals): CheckoutCard[] => {
    if (noRenewals) {
      return (state.cards.toJS() as CheckoutCard[]).filter((card) => card.action.status === UserReceiptActionStatus.Failed);
    }
    return [];
  });
