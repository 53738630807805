import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  @Input() public currentPage = 0;
  @Input() public totalPagesCount = 1;
  @Input() public navigationUrl: string;
  @Input() public query: string;
  @Input() public numPageRange = 3;
  @Output() public onNavigate = new EventEmitter<number>();
  public numbers: number[];
  public isFirst: boolean;
  public isLast: boolean;
  public isLeftDotsVisible: boolean;
  public isRightDotsVisible: boolean;
  public isOnlyPage: boolean;
  public readonly arrowIconRight = faAngleRight;
  public readonly arrowIconLeft = faAngleLeft;

  public goToPage(pageNum: number) {
    if (pageNum !== this.currentPage) {
      this.currentPage = pageNum;
      this.onNavigate.emit(pageNum);
      this.update();
    }
  }

  public ngOnInit() {
    this.update();
  }

  public validate() {
    if (this.currentPage < 0) {
      this.currentPage = 0;
    }

    if (this.currentPage >= this.totalPagesCount) {
      this.currentPage = this.totalPagesCount - 1;
    }
  }

  public calculatePageRange(totalPagesCount0: number, currentPage: number): number[] {
    const pageRange = this.numPageRange * 2;
    let startPage = currentPage - this.numPageRange;
    let endPage = currentPage + this.numPageRange;
    let numPagesToShow: number;

    if (totalPagesCount0 <= pageRange) {
      startPage = 1;
      numPagesToShow = totalPagesCount0 - 1;

      this.isRightDotsVisible = false;
      this.isLeftDotsVisible = false;

    } else {
      if (startPage <= 0) {
        startPage = 1;
      }
      if (endPage >= totalPagesCount0) {
        endPage = totalPagesCount0 - 1;
      }

      numPagesToShow = endPage - startPage + 1;
      if (numPagesToShow > pageRange - 1) {
        if (currentPage <= this.numPageRange) {
          endPage -= 1;
        } else {
          startPage += 1;
          if (numPagesToShow > pageRange) {
            endPage -= 1;
          }
        }
        numPagesToShow = pageRange - 1;
      }

      this.isLeftDotsVisible = (startPage - 1) > 0;
      this.isRightDotsVisible = (totalPagesCount0 - endPage) > 1;
    }

    return Array.from(new Array(numPagesToShow), (val, index) => index + startPage);
  }

  private update() {
    this.validate();

    const totalPagesCount0 = this.totalPagesCount - 1;
    this.isFirst = this.currentPage === 0;
    this.isLast = this.currentPage === totalPagesCount0;
    this.isOnlyPage = this.isFirst && this.isLast;

    if (!this.isOnlyPage) {
      this.numbers = this.calculatePageRange(totalPagesCount0, this.currentPage);
    }
  }
}
