import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GetItContext, } from 'search/models/modal-window-data';

@Component({
  selector: 'app-get-it-modal-content',
  templateUrl: './get-it-modal-content.component.html',
  styleUrls: ['./get-it-modal-content.component.scss'],
})
export class GetItModalContentComponent {
  @Input() public context: GetItContext;

  constructor(
    private readonly ngbActiveModal: NgbActiveModal,
  ) {}

  public onClose() {
    this.ngbActiveModal.dismiss();
  }
}
