<div class="volume-list-modal-container p-4">
  <div data-automation-id="volume-list-header">
    <app-modal-header
      title="{{'selectVolumeTitle' | translate}}"
      titleId="volume-list-title"
      closeId="close-volume-list-dialog-btn"
      (close)="onClose()">
    </app-modal-header>
  </div>
  <div class="modal-body">
    <div class="volumes-search-container d-flex flex-row align-items-center justify-content-start pb-5"
         data-automation-id="volumes-search-bar"
         (click)="$event.stopPropagation();">
      <div class="d-flex flex-row align-items-center">
        <fa-icon [icon]="searchIcon" class="position-absolute mx-1"></fa-icon>
        <input class="w-100 px-5 py-1 m-0"
               #volumesSearchInput
               [attr.aria-label]="'searchVolumesLabel' | translate">
      </div>
    </div>
    <perfect-scrollbar [config]="{ suppressScrollX: false }">
      <table class="table table-bordered table-hover" data-automation-id="volume-list-table">
        <thead class="thead-light">
        <tr>
          <th class="volume-table-data w-5"></th>
          <th class="volume-table-data text-left align-middle" colspan="2" translate>volume</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let volume of queriedVolumes; trackBy: trackById; index as i">
          <td class="volume-table-data w-5"></td>
          <td class="volume-table-data w-75 text-left align-middle"
              data-automation-id="volume-list-volume-row">{{volume.volume}}</td>
          <td class="volume-table-data w-20">
            <button translate class="ins-buttons button-secondary flint my-1 mr-2"
                    [attr.data-automation-id]="'volume-place-hold-btn-' + i"
                    (click)="placeHold(volume)">placeHold
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </perfect-scrollbar>
  </div>
</div>
