import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Directive({
  selector: '[year]',
})
export class YearDirective implements OnInit {
  @Input() public year: string;
  @Output() public yearChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private el: ElementRef,
  ) {}

  @HostListener('input', ['$event.target.value'])
  public onInput(value: any) {
    this.format(value);
  }

  public ngOnInit() {
    this.el.nativeElement.value = this.year || '';
  }

  public format(value: any) {
    const digits = value ? value.replace(/[^\d]/g, '') : '';
    const yearNumber = digits ? digits.slice(0, digits.length > 4 ? 4 : digits.length) : '';
    this.el.nativeElement.value = yearNumber ? yearNumber.toString() : '';
    this.yearChange.next(yearNumber);
  }
}
