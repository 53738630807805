import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, merge, of } from 'rxjs';
import {catchError, debounceTime, filter, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import { getPatronOrStaffUserId } from 'user/reducers/user.reducer';
import * as ListActions from '../../list/actions/list.actions';
import { ListError, LoadListItemsPayload } from '../../list/models/list';
import { ListPaginationParams } from '../../list/models/list.dto';
import { getListSort } from '../../list/reducers/list.reducer';
import { ListTransformerService } from '../../list/services/list-transformer.service';
import { ListService } from '../../list/services/list.service';
import * as SavedSearchActions from '../../saved-search/actions/saved-search.actions';
import { SavedSearchService } from '../../saved-search/services/saved-search.service';
import { WindowRefService } from '../../services/window-ref.service';
import * as ShareItActions from '../../share-it/actions/share-it.actions';
import { ShareItShareEntityType } from '../../share-it/models/share-it';
import { copyUrl, publish, writeUrl } from '../actions/custom-showcase.actions';
import * as CustomShowcaseActions from '../actions/custom-showcase.actions';
import {
  CustomShowcase,
  CustomShowcaseCreatedFromType,
  isCuratedShowcase,
  isCuratedShowcaseType,
  isDynamicShowcase,
  isDynamicShowcaseType,
} from '../models/custom-showcase';
import { CustomShowcaseDto, CustomShowcasePaginationParams } from '../models/custom-showcase.dto';
import * as CustomShowcaseReducer from '../reducers/custom-showcase.reducer';
import { sharedShowcase } from '../reducers/custom-showcase.reducer';
import { CustomShowcaseService } from '../services/custom-showcase.service';
import { EmbeddableShowcaseService } from '../services/embeddable-showcase.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShowcaseGenerationPopupComponent } from '../components/showcase-generation-popup/showcase-generation-popup.component';
import { EmbeddableShowcaseErrorDialogComponent } from '../components/embeddable-showcase-error-dialog/embeddable-showcase-error-dialog.component';

@Injectable()
export class CustomShowcaseEffects {
  public static showcaseItemsPrefetched = 10;
  private static readonly pageSize = 50;

  // <editor-fold desc="All showcases effects">

  public update$ = createEffect(() => this.actions$.pipe(
    ofType(CustomShowcaseActions.update),
    switchMap(({id, updatedFields, isSaveAndPublish, currentPublishStatus}) => {
      return this.customShowcaseService.updateCustomShowcase(id, updatedFields)
      .pipe(
        switchMap(() => {
          const actionsToDispatch: Action[] = [
            CustomShowcaseActions.updateSuccess({ id }),
            CustomShowcaseActions.applyUpdate({ id, updatedFields }),
            CustomShowcaseActions.applyFormDataUpdate({ id, updatedFields }),
          ];
          if (isSaveAndPublish) {
            actionsToDispatch.push(publish({id, updatedFields: { published: !currentPublishStatus }}));
          }
         return actionsToDispatch;
        }),
        catchError((err: HttpErrorResponse) =>
          of(CustomShowcaseActions.updateFailure({errorCode: err.status, id}))),
      );
    }),
  ));

  public publish$ = createEffect(() => this.actions$.pipe(
    ofType(CustomShowcaseActions.publish),
    switchMap(({id, updatedFields}) => {
      return this.customShowcaseService.publishOrUnpublishCustomShowcase(id, updatedFields)
      .pipe(
        switchMap((publishDateUpdate) => {
          return [
            CustomShowcaseActions.updateSuccess({id}),
            CustomShowcaseActions.applyUpdate({id, updatedFields, publishDateUpdate}),
            CustomShowcaseActions.applyFormDataUpdate({id, updatedFields, publishDateUpdate}),
          ];
        }),
        catchError((err: HttpErrorResponse) =>
          of(CustomShowcaseActions.publishFailure({errorCode: err.status, id}))),
      );
    }),
  ));

  public reloadAllShowcases$ = createEffect(() => (
    merge(
      this.actions$.pipe(ofType(CustomShowcaseActions.updateFailure)).pipe(filter(({errorCode}) => errorCode === 404)),
      this.actions$.pipe(ofType(CustomShowcaseActions.removeSuccess)),
      this.actions$.pipe(ofType(CustomShowcaseActions.removeFailure)).pipe(filter(({errorCode}) => errorCode === 404)),
    ).pipe(
      map(() => CustomShowcaseActions.loadAllFirstPage()),
    )
  ));

  public notifyLoaded$ = createEffect(() => this.actions$.pipe(
    ofType(CustomShowcaseActions.notifyLoaded),
    map(({customShowcaseDtos = []}) => CustomShowcaseActions.applyMyShowcasesLoaded({
      customShowcases: customShowcaseDtos.map(this.transformDtoToCustomShowcase),
    })),
  ));

  public loadMyOrAllShowcases$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomShowcaseActions.loadMyShowcases, CustomShowcaseActions.loadAllShowcases),
      concatLatestFrom(() => this.store.select(CustomShowcaseReducer.getSearchText)),
      switchMap(([action, searchText]) => {
        if (action.type === CustomShowcaseActions.loadMyShowcases.type) {
          return this.customShowcaseService.loadMyCustomShowcases(CustomShowcaseEffects.showcaseItemsPrefetched, searchText)
            .pipe(
              switchMap((response) => {
                const actions: Action[] = [CustomShowcaseActions.loadSuccess()];
                const customShowcases = response.map(this.transformDtoToCustomShowcase);
                if (searchText) {
                  actions.push(CustomShowcaseActions.applySearchMyShowcasesLoaded({customShowcases}));
                } else {
                  actions.push(CustomShowcaseActions.applyMyShowcasesLoaded({customShowcases}));
                }
                return actions;
              }),
              catchError((err: HttpErrorResponse) =>
                of(CustomShowcaseActions.loadFailure({errorCode: err.status}))),
            );
        } else {
          return this.loadAndProcessAllShowcases(CustomShowcaseEffects.showcaseItemsPrefetched, action.paginationParams, searchText);
        }
      }),
    ));

  public preloadAllShowcasesCount$ = createEffect(() => this.actions$.pipe(
    ofType(CustomShowcaseActions.preloadAllShowcasesCount),
    switchMap(() => this.loadAndProcessAllShowcases(1, {pageSize: 1, pageNum: 0})),
  ));

  public loadFirstPageAll$ = createEffect(() => this.actions$.pipe(
    ofType(CustomShowcaseActions.loadAllFirstPage),
    switchMap(() => [
      CustomShowcaseActions.clearAllShowcases(),
      CustomShowcaseActions.loadAllShowcases({
        paginationParams: {pageNum: 0, pageSize: CustomShowcaseEffects.pageSize},
      }),
    ]),
  ));

  public loadAllMore$ = createEffect(() => this.actions$.pipe(
    ofType(CustomShowcaseActions.loadAllMore),
    concatLatestFrom(() => this.store.select(CustomShowcaseReducer.getAllCustomShowcases)),
    filter(([, {pagination}]) => pagination && !CustomShowcaseReducer.isLastPageLoaded(pagination)),
    map(([, {pagination}]) => CustomShowcaseActions.loadAllShowcases({
      paginationParams: {pageNum: pagination.page + 1, pageSize: CustomShowcaseEffects.pageSize},
    })),
  ));

  public shareShowcase$ = createEffect(() => this.actions$.pipe(
    ofType(CustomShowcaseActions.openEmailModal),
    map(({sharedShowcase}) => this.makeShowcaseViewUrl(sharedShowcase.id)),
    withLatestFrom(this.store.select(sharedShowcase)),
    map(([url, sharedShowcase]) => {
      return ShareItActions.openEmailEntityModal({
        defaultSubject: sharedShowcase.name,
        resource: {
          type: ShareItShareEntityType.SHOWCASE,
          attributes: {url, resourceName: sharedShowcase.name},
        },
      });
    }),
  ));

  public openGenerateShowcaseModal$ = createEffect(() => this.actions$.pipe(
    ofType(CustomShowcaseActions.openGenerateShowcaseModal),
    switchMap((showcase) => {
      return this.embeddableShowcaseService.getEmbeddableShowcases()
        .pipe(
          map((response) => {
            const modalRef = this.modal.open(ShowcaseGenerationPopupComponent, {
              windowClass: '',
              ariaLabelledBy: 'showcase-generation-popup',
            });
            modalRef.componentInstance.preselectedShowcase = {id: showcase.publishedShowcase.id, name: showcase.publishedShowcase.name};
            modalRef.componentInstance.publishedShowcases = response.publishedShowcases;
            modalRef.componentInstance.pathToScript = response.widgetSource;
          }),
       );
    }),
    catchError((error: HttpErrorResponse) => {
     this.modal.open(EmbeddableShowcaseErrorDialogComponent, { size: 'sm' });
      return of(null);
    }),
  ),{ dispatch: false });

  // </editor-fold>

  // <editor-fold desc="Effects with curated/dynamic showcases switches">

  public create$ = createEffect(() => this.actions$.pipe(
    ofType(CustomShowcaseActions.create),
    switchMap(({form, isSaveAndPublish, currentPublishStatus}) => {
      return this.customShowcaseService.createCustomShowcase(form)
      .pipe(
        switchMap(({id}) => {
          const actions: Action[] = [CustomShowcaseActions.createSuccess({id})];
          if (isSaveAndPublish) {
            actions.push(publish({id, updatedFields: { published: !currentPublishStatus }}));
          }
          if (isCuratedShowcase(form)) {
            actions.push(ListActions.setListShowcaseRef({listId: form.createdFrom.id, showcaseRef: id}));
          } else if (isDynamicShowcase(form)) {
            actions.push(SavedSearchActions.setSavedSearchShowcaseRef({savedSearchId: form.createdFrom.id, showcaseRef: id}));
          }
          return actions;
        }),
        catchError((err: HttpErrorResponse) =>
          of(CustomShowcaseActions.createFailure({errorCode: err.status}))),
      );
    }),
  ));

  public remove$ = createEffect(() => this.actions$.pipe(
    ofType(CustomShowcaseActions.remove),
    switchMap(({id, createdFrom, actionOnSuccess, actionOnFailure}) => {
      return this.customShowcaseService.removeCustomShowcase(id)
      .pipe(
        switchMap((id) => {
          const actions: Action[] = [CustomShowcaseActions.removeSuccess({id})];
          if (isCuratedShowcaseType(createdFrom.type)) {
            actions.push(ListActions.unsetListShowcaseRef({id: createdFrom.id}));
          } else if (isDynamicShowcaseType(createdFrom.type)) {
            actions.push(SavedSearchActions.unsetSavedSearchShowcaseRef({savedSearchId: createdFrom.id}));
          }
          if (actionOnSuccess) {
            actions.push(actionOnSuccess);
          }
          return actions;
        }),
        catchError((err: HttpErrorResponse) => {
          const actions: Action[] = [CustomShowcaseActions.removeFailure({id, errorCode: err.status})];

          if (err.status === 404 && isCuratedShowcaseType(createdFrom.type)) {
            actions.push(ListActions.unsetListShowcaseRef({id: createdFrom.id}));
            if (actionOnSuccess) {
              actions.push(actionOnSuccess);
            }
          } else if (err.status === 404 && isDynamicShowcaseType(createdFrom.type)) {
            actions.push(SavedSearchActions.unsetSavedSearchShowcaseRef({savedSearchId: createdFrom.id}));
            if (actionOnSuccess) {
              actions.push(actionOnSuccess);
            }
          } else if (actionOnFailure) {
            actions.push(actionOnFailure);
          }

          return actions;
        }),
      );
    }),
  ));

  public loadShowcaseWithDetails$ = createEffect(() => this.actions$.pipe(
    ofType(CustomShowcaseActions.openShowcase),
    concatLatestFrom(() => this.store.select(getPatronOrStaffUserId)),
    switchMap(([{id}, userId]) => {
      return this.customShowcaseService.getCustomShowcaseById(id)
      .pipe(
        switchMap((customShowcase) => {
          const showcase = {...customShowcase, publishDate: customShowcase.publishDate || null};

          switch (customShowcase.createdFrom.type) {
            case CustomShowcaseCreatedFromType.list: {
              return of(customShowcase)
              .pipe(
                concatLatestFrom((customShowcase) => this.store.select(getListSort, {id: customShowcase.createdFrom.id})),
                switchMap(([customShowcase, listSortToRestoreOnClose]) => {
                  const actions: Action[] = [];
                  actions.push(CustomShowcaseActions.loadShowcaseSuccess({showcase, listSortToRestoreOnClose}));

                  const showcaseSort = this.listTransformerService.transformListSortByStringToSort(customShowcase.metadata.sortOrder);
                  const loadItemsPayload: LoadListItemsPayload = {
                    id: customShowcase.createdFrom.id,
                    sort: showcaseSort,
                    paginationParams: this.makePaginationParams(0, CustomShowcaseEffects.pageSize),
                    clearBeforeAdd: true,
                  };

                  if (userId === customShowcase.creator.id) {
                    actions.push(
                      ListActions.clear({id: showcase.createdFrom.id}),
                      ListActions.setListSort({
                        listId: showcase.createdFrom.id,
                        sort: showcaseSort,
                      }),
                      ListActions.loadListItems(loadItemsPayload),
                    );
                  } else {
                    actions.push(CustomShowcaseActions.loadCuratedShowcaseItems(loadItemsPayload));
                  }

                  return actions;
                }),
              );
            }
            case CustomShowcaseCreatedFromType.savedSearch: {
              return [
                CustomShowcaseActions.loadShowcaseSuccess({showcase}),
                CustomShowcaseActions.loadSavedSearch({id: showcase.createdFrom.id}),
              ];
            }
          }
        }),
        catchError((err: HttpErrorResponse) =>
          of(CustomShowcaseActions.loadShowcaseFailure({errorCode: err.status}))),
      );
    }),
  ));

  public cleanUpOnShowcaseClosed$ = createEffect(() => this.actions$.pipe(
    ofType(CustomShowcaseActions.showcaseClosed),
    concatLatestFrom(() => this.store.select(getPatronOrStaffUserId)),
    concatLatestFrom(() => this.store.select(CustomShowcaseReducer.getOpenedShowcaseListSortToRestoreOnClose)),
    switchMap(([[{showcase}, userId], listSortToRestoreOnClose]) => {
      const actions: Action[] = [];
      if (
        isCuratedShowcase(showcase)
        && listSortToRestoreOnClose
        && userId === showcase.creator.id
      ) {
        actions.push(
          ListActions.setListSort({
            listId: showcase.createdFrom.id,
            sort: listSortToRestoreOnClose,
          }),
          ListActions.loadListItems({
            id: showcase.createdFrom.id,
            sort: listSortToRestoreOnClose,
            paginationParams: this.makePaginationParams(0, CustomShowcaseEffects.pageSize),
            clearBeforeAdd: true,
          }),
        );
      }
      actions.push(CustomShowcaseActions.clearOpenedState());
      return actions;
    }),
  ));

  // </editor-fold>

  // <editor-fold desc="Curated showcase effects">

  public loadCuratedShowcaseItems$ = createEffect(() => this.actions$.pipe(
    ofType(CustomShowcaseActions.loadCuratedShowcaseItems),
    mergeMap(({id, sort, paginationParams, clearBeforeAdd}) => {
      return this.listService.getItems(id, this.listTransformerService.transformSortToListSortByString(sort), paginationParams)
      .pipe(
        switchMap((listItemsDto) => {
          const {data, ...pagination} = listItemsDto;
          const entities = data.map((item) => this.listTransformerService.transformFgOrEntityToListItemEntity(item));
          const actions: Action[] = [];
          actions.push(CustomShowcaseActions.loadCuratedShowcaseItemsSuccess({pagination}));
          if (clearBeforeAdd) {
            actions.push(CustomShowcaseActions.clearCuratedShowcaseItems());
          }
          actions.push(CustomShowcaseActions.addLoadedCuratedShowcaseItems({entities}));

          return actions;
        }),
        catchError((err: HttpErrorResponse) => {
          const error = CustomShowcaseEffects.extractErrorOrUnknown(err);
          return of(CustomShowcaseActions.loadCuratedShowcaseItemsFailure({error}));
        }),
      );
    }),
  ));

  public loadMoreOpenedCuratedShowcaseItems$ = createEffect(() => this.actions$.pipe(
    ofType(CustomShowcaseActions.loadMoreOpenedCuratedShowcaseItems),
    concatLatestFrom(() => this.store.select(CustomShowcaseReducer.getOpenedShowcase)),
    concatLatestFrom(() => this.store.select(getPatronOrStaffUserId)),
    map(([[, openedShowcase], userId]) => openedShowcase?.creator.id === userId
      ? ListActions.loadMoreListItems({id: openedShowcase.createdFrom.id})
      : CustomShowcaseActions.loadMoreCuratedShowcaseItems({listId: openedShowcase.createdFrom.id}),
    ),
  ));

  public loadMoreCuratedShowcaseItems$ = createEffect(() => this.actions$.pipe(
    ofType(CustomShowcaseActions.loadMoreCuratedShowcaseItems),
    concatLatestFrom(() => this.store.select(CustomShowcaseReducer.getOpenedShowcaseItemsPagination)),
    concatLatestFrom(() => this.store.select(CustomShowcaseReducer.getOpenedShowcase)),
    filter(([[, pagination]]) => !(pagination.page >= pagination.totalPages - 1)),
    map(([[{listId}, pagination], openedShowcase]) => CustomShowcaseActions.loadCuratedShowcaseItems({
      id: listId,
      sort: this.listTransformerService.transformListSortByStringToSort(openedShowcase.metadata.sortOrder),
      paginationParams: this.makePaginationParams(pagination.page + 1, CustomShowcaseEffects.pageSize),
    })),
  ));

  public reloadCuratedShowcaseCoversAndCountOnItemsUpdate$ = createEffect(() => this.actions$.pipe(
    ofType(CustomShowcaseActions.notifyCuratedShowcasesItemsUpdated),
    concatLatestFrom((action) => this.store.select(CustomShowcaseReducer.getVisibleShowcase, {id: action.showcaseId})),
    filter(([, visibleShowcase]) => !!visibleShowcase && isCuratedShowcase(visibleShowcase)),
    mergeMap(([{loadedByList, listId}, showcase]) => {
      if (loadedByList.sortByString === showcase.metadata.sortOrder) {
        return of(CustomShowcaseActions.applyUpdate({
          id: showcase.id,
          coversAndItemsCount: {
            items: loadedByList.entities,
            itemsCount: loadedByList.totalResults,
          },
        }));
      }

      return this.listService.getItems(listId, showcase.metadata.sortOrder, {pageNum: 0, pageSize: 5})
      .pipe(
        switchMap(({data, totalResults}) => {
          const coverConfigs = data.map((item) => this.listTransformerService.transformFgOrEntityToListItemEntity(item));

          return of(CustomShowcaseActions.applyUpdate({
            id: showcase.id,
            coversAndItemsCount: {items: coverConfigs, itemsCount: totalResults},
          }));
        }),
        catchError(() => EMPTY),
      );
    }),
  ));

  public copyCuratedShowcaseUrl$ = createEffect(() => this.actions$.pipe(
    ofType(copyUrl),
    debounceTime(0),
    map(({id}) => {
      const url = this.makeShowcaseViewUrl(id);
      return writeUrl({id, url});
    }),
  ));

  // </editor-fold>

  // <editor-fold desc="Dynamic showcase effects">
  public loadDynamicShowcaseSearchObject$ = createEffect(() => this.actions$.pipe(
    ofType(CustomShowcaseActions.loadSavedSearch),
    switchMap(({id}) => {
      return this.savedSearchService.getSavedSearchData(id)
      .pipe(
        map((showcaseSearchData) => CustomShowcaseActions.loadSavedSearchSuccess(showcaseSearchData)),
        catchError((error) => of(CustomShowcaseActions.loadSavedSearchFailure({error}))),
      );
    }),
  ));

  public updateOpenedDynamicShowcaseOnRelevantSavedSearchUpdate$ = createEffect(() => this.actions$.pipe(
    ofType(SavedSearchActions.updateSavedSearchComplete),
    concatLatestFrom(() => this.store.select(CustomShowcaseReducer.getOpenedShowcase)),
    filter(([{savedSearchUpdate}, openedShowcase]) => (
      openedShowcase
      && isDynamicShowcase(openedShowcase)
      && openedShowcase.createdFrom.id === savedSearchUpdate.id
    )),
    switchMap(([{savedSearchUpdate}]) => {
      return this.savedSearchService.getShowcaseSearchDataBySearchRequestBody(savedSearchUpdate.searchRequest)
      .pipe(
        map((showcaseSearchData) => CustomShowcaseActions.loadSavedSearchSuccess(showcaseSearchData)),
        catchError((error) => of(CustomShowcaseActions.loadSavedSearchFailure({error}))),
      );
    }),
  ));

  // </editor-fold>

  constructor(
    private actions$: Actions,
    private readonly customShowcaseService: CustomShowcaseService,
    private readonly store: Store,
    private readonly listService: ListService,
    private readonly listTransformerService: ListTransformerService,
    private readonly savedSearchService: SavedSearchService,
    private readonly embeddableShowcaseService: EmbeddableShowcaseService,
    private readonly router: Router,
    private readonly windowRef: WindowRefService,
    private readonly modal: NgbModal,
  ) {
  }

  private static extractErrorOrUnknown(err: HttpErrorResponse): ListError {
    return (err.error?.status)
      ? err.error
      : {
        status: err.status,
        message: 'Unknown error',
      };
  }

  private loadAndProcessAllShowcases(prefetch: number, paginationParams: CustomShowcasePaginationParams, searchText = '') {
    return this.customShowcaseService.loadAllCustomShowcases(prefetch, paginationParams, searchText)
    .pipe(
      switchMap(({data, ...pagination}) => {
        const actions: Action[] = [CustomShowcaseActions.loadSuccess()];
        actions.push(CustomShowcaseActions.addAllShowcasesLoaded({
          customShowcases: data.map(this.transformDtoToCustomShowcase),
          pagination,
        }));
        if (!searchText) {
          actions.push(CustomShowcaseActions.updateAllTotalCount({totalCount: pagination.totalResults}));
        }
        return actions;
      }),
      catchError((err: HttpErrorResponse) =>
        of(CustomShowcaseActions.loadFailure({errorCode: err.status}))),
    );
  }

  private transformDtoToCustomShowcase = (customShowcaseDto: CustomShowcaseDto): CustomShowcase => {
    const {items, itemsCovers, ...customShowcaseWithoutItems} = customShowcaseDto;
    return {
      ...customShowcaseWithoutItems,
      publishDate: customShowcaseDto.publishDate || null,
      items: items ?
        items.data.map((item) => this.listTransformerService.transformFgOrEntityToListItemEntity(item)) :
        customShowcaseDto.itemsCovers.map(item => ({
          coverConfig: {
            coverUrl: item,
          }
        })),
      itemsCount: customShowcaseDto.items?.totalResults || null,
    };
  }

  private makePaginationParams = (pageNum = 0, pageSize = 999): ListPaginationParams => {
    return {pageNum, pageSize};
  }

  private makeShowcaseViewUrl(id: string): string {
    const tree = this.router.createUrlTree(['/library-list', 'showcase', id]).toString();
    return `${this.windowRef.origin()}${tree}`;
  }
}
