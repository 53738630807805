import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CookieNotificationService } from 'core/services/cookie.service';
import { ConfigurationLoader } from 'shared/configuration-loader';

@Component({
  selector: 'app-cookie-notification',
  templateUrl: './cookie-notification.component.html',
  styleUrls: [ './cookie-notification.component.scss' ],
})
export class CookieNotificationComponent implements OnInit {
  public privacyPolicyLink: { title: string, href: string };
  constructor(public readonly cookieService: CookieNotificationService, private configLoader: ConfigurationLoader) {}

  public ngOnInit(): void {
    this.privacyPolicyLink = this.configLoader.headerConfig.privacyPolicyLink;
  }
}
