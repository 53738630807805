<div class="card">
  <div class="card-block">
    <h4 class="card-title" translate>ForbiddenError</h4>
    <p class="card-text" translate>ForbiddenMessage</p>
    <a [routerLink]="['/']"
      class="btn btn-primary"
      translate>
      ForbiddenLinkToHome
    </a>
  </div>
</div>
