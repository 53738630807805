import { createAction, props } from '@ngrx/store';

export const updateCurrentNavigationId = createAction(
  '[History] Update current navigation id',
  props<{navId: number}>(),
);

export const setFirstNavigationId = createAction(
  '[History] Set first navigation id',
  props<{navId: number}>(),
);
