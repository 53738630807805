import {
  Pipe,
  PipeTransform,
} from '@angular/core';

@Pipe({
  name: 'join',
  pure: false,
})
export class JoinPipe implements PipeTransform {
  public transform(input: any[], separator = ''): any {
    return input.join(separator);
  }
}
