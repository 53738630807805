import { FacetSchemaWithData, makeFacetSchemaWithData } from 'search/facets/models/facet-schema-with-data';
import { FacetState, FacetURLFilters, makeFacetState } from 'search/facets/models/facet-state';
import { CatalogDateFacet, Facet, FacetsLocked } from 'search/facets/models/resource-facet';
import { FacetsActionsProcessor } from 'search/facets/processors/actions/facets-actions-processor';
import { ResourceFacetBlock } from 'search/models/filter-panel';
import { defaultCatalogDateFacets } from 'search/models/search-object';
import { CatalogDate } from 'search/models/search-results';
import { copyObject } from 'search/reducers/utils';

export class CatalogDateFacetsActionsProcessor extends FacetsActionsProcessor {
  public processFacetsLoaded(oldState: FacetState, appliedFilters: FacetURLFilters,
                             schemaWithData: FacetSchemaWithData, facetsLocked: FacetsLocked): FacetState {
    const newSelectedAndApplied = appliedFilters.catalogDate
      ? this.extractFromApplied(appliedFilters, schemaWithData, facetsLocked)
      : copyObject(oldState?.selected || defaultCatalogDateFacets);

    // @ts-ignore
    return makeFacetState({
      schemaWithData,
      selected: copyObject(newSelectedAndApplied),
      applied: appliedFilters.catalogDate ? copyObject(newSelectedAndApplied) : null,
      expanded: oldState ? oldState.expanded : false,
      isLoading: false,
    });
  }

  public processBubblesReset(oldState: FacetState<CatalogDateFacet>, resetAll: boolean): FacetState {
    const stateWithoutApplied = oldState.update('applied', (facet) => {
      return !resetAll && facet?.isResetLocked ? facet : null;
    });
    return stateWithoutApplied.set('selected', stateWithoutApplied.applied || copyObject(defaultCatalogDateFacets));
  }

  public processFacetLocked(oldState: FacetState<CatalogDateFacet>): FacetState {
    const updated = this.updateResetLocked('selected', oldState);
    return this.updateResetLocked('applied', updated);
  }

  public processFacetSelected(oldState: FacetState<CatalogDateFacet>, selectedFacet: CatalogDateFacet): FacetState {
    return oldState.set('selected', selectedFacet);
  }

  public processExpandFacets(oldState: FacetState<CatalogDateFacet>, expanded: true, checkSelected: boolean): FacetState {
    return oldState.set('expanded', !checkSelected ? expanded : oldState.selected.selected);
  }

  public processReplaceSelectedFacets(oldState: FacetState, selectedFacets: Facet[]): FacetState {
    throw new Error('Unsupported operation');
  }

  public processApplyFacets(oldState: FacetState<CatalogDateFacet>): FacetState {
    const selected = oldState.selected;
    return oldState.set('applied', selected.selected && selected.catalogDate ? selected : null);
  }

  public processFacetBlockSelected(oldState: FacetState<Facet[]>): FacetState {
    return oldState.set('selected', copyObject(defaultCatalogDateFacets));
  }

  public processBubbleRemoved(oldState: FacetState): FacetState {
    const resetSelectedState = oldState.set('selected', copyObject(defaultCatalogDateFacets));
    return resetSelectedState.set('applied', null);
  }

  public processCancelEditingFacets(oldState: FacetState): FacetState {
    return oldState.set('selected', copyObject(oldState.applied || defaultCatalogDateFacets));
  }

  public processResetFacetState(oldState: FacetState): FacetState {
    // @ts-ignore
    return makeFacetState({
      schemaWithData: makeFacetSchemaWithData({
        schema: oldState.schemaWithData.schema,
        data: null,
      }),
      selected: {
        catalogDate: '',
        facetKey: ResourceFacetBlock.CATALOG_DATE,
        isResetLocked: false,
        selected: false,
        label: '',
        id: '',
      },
      applied: null,
      expanded: false,
      isLoading: false,
    });
  }

  public getAppliedFiltersForLock(schemaWithData: FacetSchemaWithData, filters: FacetURLFilters): string[] {
    const key = 'catalogDate';
    return filters[key] ? [filters[key] as string] : null;
  }

  private updateResetLocked(field: 'selected' | 'applied', oldState: FacetState<CatalogDateFacet>) {
    return oldState.update(field, (facet) => {
      if (facet) {
        facet.isResetLocked = !facet.isResetLocked;
      }
      return facet;
    });
  }

  private extractFromApplied(appliedFilters: FacetURLFilters, schemaWithData: FacetSchemaWithData, facetsLocked: FacetsLocked) {
    const dateFacet = {} as CatalogDateFacet;
    const facetKey = schemaWithData.schema.key;
    const facetLockedByKey = facetsLocked.hasOwnProperty(facetKey) ? facetsLocked[facetKey] : [];
    dateFacet.catalogDate = (appliedFilters.catalogDate || '') as CatalogDate;
    dateFacet.selected = true;
    dateFacet.facetKey = facetKey;
    dateFacet.label = `${dateFacet.catalogDate ? dateFacet.catalogDate : ''}`;
    dateFacet.id = dateFacet.label;
    dateFacet.isResetLocked = facetLockedByKey.includes(dateFacet.id);
    return dateFacet;
  }
}
