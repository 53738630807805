import { ShowcasesTypes } from 'search/full-card/models/showcases';
import { EContentVendor } from 'search/models/e-content';
import {
  BookCover,
  CoverUrl,
  LocationInfo,
  SearchResult,
} from 'search/models/search-results';
import { AboutTabDescription } from '../components/about-popup/components/description/description.component';
import { AboutTabDetails } from '../components/about-popup/components/details/details.component';
import { FormatGroupFullRecord } from '../../jsonld/models/full-record';
import { AvailabilityState } from '../reducers/full-entity.reducer';

export enum EntityTypes {
  AGENT = 'Agent',
  CONCEPT = 'Concept',
  INSTANCE = 'Instance',
  FORMAT_GROUP = 'FormatGroup',
}

export enum EditionType {
  PHYSICAL = 'physical',
  ELECTRONIC = 'electronic',
}

export enum AgentTypes {
  PERSON = 'person',
}

export enum AvailabilityVendorType {
  GENERAL = 'general',
  ELECTRONIC_LOCATOR = 'electronicLocator',
  ELECTRONIC_VENDOR = 'electronicVendor',
}

export enum AvailabilityStatus {
  AVAILABLE = 'Available',
  CHECKED_OUT = 'CheckedOut',
  CHECK_AVAILABILITY = 'CheckAvailability',
  ON_ORDER = 'OnOrder',
  UNAVAILABLE = 'Unavailable',
  GET_MATERIAL = 'Get Material',
  GET_ISSUES = 'Get Issues'
}

export enum IssuanceTypes {
  SERIAL = 'serial',
  // Add other types if needed
};

export const AvailableStatuses = [
  AvailabilityStatus.AVAILABLE,
  AvailabilityStatus.ON_ORDER,
  AvailabilityStatus.CHECK_AVAILABILITY,
  AvailabilityStatus.GET_MATERIAL,
  AvailabilityStatus.GET_ISSUES,
];

export enum VendorType {
  CLOUD_LIBRARY = 'cloudlibrary',
  AXIS360 = 'axis360',
  OVERDRIVE = 'overdrive',
  HOOPLA = 'hoopla',
  BORROW_BOX = 'borrowbox',
  OAI_PMH = 'oaipmh'
}


export interface IdentifiedBy {
  type: string;
  value: string;
  note?: string;
  qualifier?: string;
  source?: string;
}

export interface EntityPayload {
  id: string;
  hasSameId?: boolean;
  entityType?: EntityTypes;
  showcasesType?: ShowcasesTypes;
  showcasesSubrouting?: string;
  pageNum?: number;
  recordId?: string;
  isbn?: string;
  upc?: string;
  issn?: string;
  controlNumber?: string;
}

export interface MaterialTypeFullResourceCardView {
  id: string;
  date: string;
  dimensions?: string;
  edition?: string;
  pagesCount?: string;
  publisher?: string;
  recordId: string[];
  isRequestable: boolean;
  availabilityStatus: string;
}

export interface MaterialTypeCardInfo {
  id: string;
  tabName: string;
}

export interface HoldRequest {
  id: string;
  reserveId: string;
  vendor?: EContentVendor | string;
}

export interface GetItRequest {
  id: string;
  reserveId?: string;
  vendor?: EContentVendor | string;
  recordId?: string;
  entityType?: string;
  formatGroupId?: string;
  instanceId?: string;
  eventType?: string;
  readyHold?: boolean;
  tabIndex?: number;
  usePublicApi?: boolean;
  hasEcontentIssues?: boolean
}

export interface GetItTabRequest {
  cardInfo: MaterialTypeCardInfo;
  tabIndex: number;
}

export interface EContent {
  vendor: string;
  titleId: string;
}

export interface NonBibframe {
  eContent?: EContent[];
  preferredCallNumber?: PreferredCallNumber[];
}

export interface PreferredCallNumber {
  tag?: string;
  callNumber?: string;
}

export interface VendorInfo {
  reserveId: string[];
}

export interface Reserve {
  vendorName: VendorType;
  id: string;
}

export interface AvailabilityStatusMap {
  [AvailabilityVendorType.GENERAL]?: AvailabilityStatus;
  [AvailabilityVendorType.ELECTRONIC_LOCATOR]?: AvailabilityStatus;
  [AvailabilityVendorType.ELECTRONIC_VENDOR]?: AvailabilityStatus;
}

export interface AvailabilityUpdateEditionInfo {
  status: AvailabilityStatusMap;
  reserve?: Reserve;
  subscriptionId? : string[];
  eVendorAvailabilityPending?: boolean;
}

export interface AvailabilityUpdateInfo extends AvailabilityUpdateEditionInfo {
  holds?: number;
  copies?: number;
}

export interface Availability {
  isGetItSuppressed: boolean;
  urls?: string[];
  status: AvailabilityStatusMap;
  vendors?: Partial<Record<VendorType, VendorInfo>>;
}

export interface Edition {
  id: string;
  availabilityStatus: AvailabilityStatus;
  isGetItSuppressed: boolean;
  issuance?: string[];
  hasItems: boolean;
  recordId: string;
  callNumber?: string;
  edition?: string;
  eResourceUrls?: string[];
  publicationDate?: string;
  publicNote?: string;
  publisher?: string;
  physicalDescription?: string;
  subscriptionId?: string[];
  vendors?: Partial<Record<VendorType, VendorInfo>>;
  type: EditionType;
  availability?: AvailabilityState & {
    expanded: false;
  };
}

export interface MaterialTab {
  name: string;
  type: EditionType;
  callNumber?: string;
  itemEditionRecordId?: string;
  itemCollectionLocation?: string;
  itemLocation?: string;
  itemLibrary?: string;
  itemLocationCode?: string;
  itemLibraryCode?: string;
  itemShelfLocation?: string;
  holdingsDisplayStrings?: string[];
  supplementsDisplayStrings?: string[];
  indexesDisplayStrings?: string[];
  holdingsLatestReceived?: string;
  availability: Availability;
  recordIds?: string[];
  contentId?: string;
  materialTypes?: string[];
  locations?: LocationInfo[];
  // should use hasMoreLocations to determine if tab has more locations
  locationsTotalResults?: number;
  hasMoreLocations?: boolean;
  editions?: Edition[];
  issuance?: string[];
  checkAvailabilityFromOtherSources?: boolean;
  multimediaLinks?: MultimediaLink[];
  active?: boolean;
  opacMessage?: string;
  publicNote?: string;
  translationKey?: string;
}

export interface MultimediaLink {
  url: string;
  label?: string;
}

export interface WithLists {
  // set if logged in and personal lists license is turned on
  lists?: string[];
  personalLists?: PersonalList[]
}

export interface PersonalList {
  id?: string;
  recordId?: string;
  listIds: string[]
}

export interface FormatGroup extends WithLists {
  id: string;
  entityType: EntityTypes.FORMAT_GROUP;
  recordId?: string[];
  description?: string;
  customerId?: string;
  identifiers?: Record<string, string>;
  title?: string;
  publicationDate?: string;
  primaryAgent?: TabEntity;
  hasMetadataLinks?: boolean;
  materialTabs?: MaterialTab[];
  isUnresolved?: boolean;
  missingWords?: string[];
  coverUrl?: CoverUrl;
  language?: string;
  seriesTitle? : string;
  seriesTitleSearch? : string;
  fullRecord?: FormatGroupFullRecord;
  selectedTabRecordId?: string;
}

export interface MaterialType {
  allowRequestsWithNoItems: boolean;
  code: string;
  description: string;
  workType: string;
  formatGroup: string;
  alwaysAvailable: boolean;
  icon?: string;
}

export interface FormatGroupPayload {
  fgEntity: FormatGroup;
}

export interface Entity extends WithLists {
  id: string;
  entityType: EntityTypes;
  recordId?: string[];
  identityStatus?: string;
  description?: string[];
  label?: string;
  source?: string;
  type?: string[] | string;
  identifiedBy?: IdentifiedBy[];
  customerId?: string;
  relationshipTypes?: string[];
  resourceLinksCount?: number;
  linksCount?: number;
  $title?: string[];
  coverUrl?: CoverUrl;
  bookIsbn?: string;
  marcKey?: string;
}

export interface SearchResultResponse {
  searchResult?: SearchResult;
  loading: boolean;
}

export interface Concept extends Entity {
  identifiedBy?: {
    type: string;
    value: string;
    qualifier: string;
    source: string;
    note: string
  }[];
}

export interface Topic extends Concept {
  resources?: {
    data: [],
    totalResults: number;
  };
}

export interface AboutTab {
  description?: AboutTabDescription;
  details?: AboutTabDetails;
}

export interface TabEntity {
  id: string;
  label: string;
  role?: string;
  alternateAgent?: {
    label?: string;
  };
}

export interface ConceptDescription {
  label?: string;
  source?: string;
  url?: string;
}

export interface ArticleData {
  label: string;
  volumeCount: string;
  issueCount: string;
}

export interface AgentOrConceptData {
  id: string;
  type?: string | string[];
  format?: string | string[];
  title?: string;
  hasAssociations?: boolean;
  entityType: EntityTypes.INSTANCE | EntityTypes.FORMAT_GROUP | EntityTypes.AGENT | EntityTypes.CONCEPT;
  coverConfig?: BookCover;
  materialTypeLabel?: string;
  description?: ConceptDescription;
  bookIsbn?: string;
}

export function isFormatGroup(entity: Entity | FormatGroup): entity is FormatGroup {
  return entity.entityType === EntityTypes.FORMAT_GROUP;
}
