import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NlbBookmark } from '../models/nlb-bookmark';

@Injectable()
export class NlbBookmarkService {
  private static readonly nlbBookmarksUrl = 'api/search-result/nlb/bookmarks';

  constructor(
    private readonly http: HttpClient,
  ) {
  }

  public loadBookmarks(): Observable<NlbBookmark[]> {
    return this.http.get<NlbBookmark[]>(NlbBookmarkService.nlbBookmarksUrl);
  }

  public createBookmark(formatGroupId: string, materialTabName: string): Observable<NlbBookmark> {
    return this.http.post<NlbBookmark>(NlbBookmarkService.nlbBookmarksUrl, {formatGroupId, materialTabName});
  }

  public deleteBookmark(bookmarkId: string): Observable<{}> {
    return this.http.delete<{}>(`${NlbBookmarkService.nlbBookmarksUrl}/${bookmarkId}`);
  }
}
