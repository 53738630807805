import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { openBookmarksTab } from 'user/actions/user-profile.actions';
import { UserPermission } from 'user/models/user';
import { doesUserHavePermission } from 'user/reducers/user.reducer';
import { openList, removeSelectedFromList } from '../../../list/actions/list.actions';
import { ListItem, ListItemEntity } from '../../../list/models/list';
import { getLists, ListItemsLoadingState } from '../../../list/reducers/list.reducer';
import { applyUpdate, hideForm, loadMoreOpenedCuratedShowcaseItems, showForm } from '../../actions/custom-showcase.actions';
import { CustomShowcaseSingle, OpenedCuratedShowcaseWithDetails } from '../../models/custom-showcase';
import { isLastPageLoaded, ShowcaseLoadingState } from '../../reducers/custom-showcase.reducer';
import { CustomShowcaseStoreAdapterService } from '../../services/custom-showcase-store-adapter.service';
import { FeatureToggleService } from 'app/services/feature-toggle.service';
import { ShowcasePermissions } from 'app/permissions/models/permissions';

@Component({
  selector: 'app-curated-showcase-view',
  templateUrl: './curated-showcase-view.component.html',
  styleUrls: ['./curated-showcase-view.component.scss'],
})
export class CuratedShowcaseViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public openedShowcaseWithDetails: OpenedCuratedShowcaseWithDetails;
  @Input() public isShowcaseCreator: boolean;
  @Input() public isInvisible: boolean;
  @Output() public readonly closeShowcase = new EventEmitter<undefined>();
  public openedShowcase: CustomShowcaseSingle;
  public openedShowcaseItems: ListItem[];
  public openedShowcaseItemsCount: number;
  public openedShowcaseLoadingState: ShowcaseLoadingState;
  public openedShowcaseDetailsLoadingState: ListItemsLoadingState | null;
  public allOpenedShowcaseItemsLoaded: boolean;
  public itemsEntitySelected: ListItemEntity[];
  public itemsSelectedCount: number;
  public isAllSelected: boolean;
  public showcaseId: string;
  public isAuthPatronFlagEnabled: boolean;
  public showcasePermissions = ShowcasePermissions;
  public readonly arrowIconLeft = faAngleLeft;

  public readonly lists$ = this.store.select(getLists);

  public hasUpdatePersonalShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_PERSONAL_UPDATE));
  public hasUpdateNonPersonalShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_NON_PERSONAL_UPDATE));

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly customShowcaseStoreAdapterService: CustomShowcaseStoreAdapterService,
    private readonly cdr: ChangeDetectorRef,
    private readonly featureToggleService: FeatureToggleService,
  ) {
  }

  public ngOnChanges(): void {
    this.openedShowcase = this.openedShowcaseWithDetails.showcase;
    this.showcaseId = this.openedShowcaseWithDetails?.showcase?.id;
    this.openedShowcaseItems = this.openedShowcaseWithDetails.items;
    this.openedShowcaseItemsCount = this.openedShowcaseWithDetails.pagination.totalResults;
    this.itemsEntitySelected = this.openedShowcaseItems.filter((item) => item.selected).map((item) => item.entity);
    this.itemsSelectedCount = this.itemsEntitySelected.length;
    this.isAllSelected = !!(this.itemsSelectedCount && this.itemsSelectedCount === this.openedShowcaseItems.length);
    this.allOpenedShowcaseItemsLoaded = isLastPageLoaded(this.openedShowcaseWithDetails.pagination);
  }

  public ngOnInit(): void {
    this.isAuthPatronFlagEnabled = this.featureToggleService.getToggles()['DIS-30793_2024-04-27_auth_patron'];

    this.subscriptions.add(
      this.customShowcaseStoreAdapterService.getOpenedShowcaseLoadings()
      .subscribe((data) => {
        ([this.openedShowcaseLoadingState, this.openedShowcaseDetailsLoadingState] = data);
        this.cdr.markForCheck();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public loadMoreShowcaseItems() {
    this.store.dispatch(loadMoreOpenedCuratedShowcaseItems());
  }

  public onCloseShowcase(): void {
    this.closeShowcase.emit();
  }

  public onCloseShowcaseAndDeselectItems(): void {
    this.setAllItemsSelection(false);
    this.dispatchShowcaseUpdate();
    this.closeShowcase.emit();
  }

  public openShowcaseSettings(): void {
    this.store.dispatch(showForm({showcase: this.openedShowcase}));
  }

  public setAllItemsSelection(selected: boolean): void {
    this.customShowcaseStoreAdapterService.setAllItemsSelection(this.openedShowcase, selected);
  }

  public onToggleItem(showcaseId: string, listItem: ListItem): void {
    this.customShowcaseStoreAdapterService.toggleItemSelection(this.openedShowcase, listItem.id);
  }

  public removeSelectedFromRelatedList(): void {
    this.store.dispatch(removeSelectedFromList({listId: this.openedShowcase.createdFrom.id}));
  }

  public openList(): void {
    this.store.dispatch(hideForm());
    this.store.dispatch(openBookmarksTab());
    this.store.dispatch(openList({listId: this.openedShowcase.createdFrom.id}));
  }

  private dispatchShowcaseUpdate() {
    this.store.dispatch(applyUpdate({
      id: this.openedShowcase.id,
      updatedFields: {
        name: this.openedShowcase.name,
        published: this.openedShowcase.published,
        metadata: this.openedShowcase.metadata,
      },
      publishDateUpdate: {
        publishDate: this.openedShowcase.publishDate,
      },
      coversAndItemsCount: {
        itemsCount: this.openedShowcaseItemsCount,
        items: this.openedShowcaseItems.map(item => item.entity),
      },
    }));
  }
}
