<table class="bulk-hold-table table" data-automation-id="bulk-hold-table">
  <caption class="sr-only" data-automation-id="bulk-hold-table-caption">{{ 'bulkPlaceHoldItems' | translate}}</caption>
  <thead class="thead">
    <tr class="d-flex">
      <th class="bulk-table-data table-header col-7 pl-2 pl-sm-8">{{ 'bulkHoldItemOverview' | translate}}</th>
      <th class="bulk-table-data table-header col-3">{{ 'bulkHoldFormat' | translate }}</th>
      <th class="bulk-table-data table-header col-2" data-automation-id="bulk-hold-table-action-header">
        <app-bulk-hold-counters
          [bulkHoldSubmitState]="bulkHoldSubmitState"
          (neededActionClick)="navigateToFirstNeededAction()">
        </app-bulk-hold-counters>
      </th>
    </tr>
  </thead>
  <tbody infiniteScroll infiniteScrollDisabled="true">
    <tr #tableRow class="bulk-hold-table-row d-flex" *ngFor="let formatGroup of formatGroups; trackBy: trackById; index as i">
      <td class="col-7 bulk-table-data pl-2 pl-sm-8 py-3">
        <app-bulk-hold-item-overview [formatGroup]="formatGroup"></app-bulk-hold-item-overview>
      </td>
      <td class="col-3 bulk-table-data align-middle align-content-center">
        <label class="sr-only" for="bulk-hold-select-format-{{i}}">{{'bulkHoldFormatLabel' | translate: {title: formatGroup.title} }}</label>
        <select
          class="bulk-hold-select-format select-custom-arrow m-0"
          id="bulk-hold-select-format-{{i}}"
          data-automation-id="bulk-hold-select-format"
          [disabled]="bulkHoldSubmitState.submitted"
          (change)="changeFormat(formatGroup, $event.target.value)">
          <option *ngFor="let format of formatGroup.materialTabs"
                  [value]="format.name">
            {{format.name}}
          </option>
        </select>
      </td>
      <td class="col-2 bulk-table-data d-flex justify-content-center align-items-center">
          <ng-container *ngIf="!bulkHoldSubmitState.submitted; else holdSubmitState">
            <button (click)="removeItem(formatGroup)"
                    class="app-button bulk-hold-remove-button"
                    [attr.aria-label]="('removeButton' | translate)+ ' ' + formatGroup.title"
                    aria-controls="title"
                    data-automation-id="bulk-hold-remove-btn">
              <fa-icon [icon]="timesIcon"></fa-icon>
            </button>
          </ng-container>
          <ng-template #holdSubmitState>
            <app-hold-submit-state [fgId]="formatGroup.id" [title]="formatGroup.title"></app-hold-submit-state>
          </ng-template>
      </td>
    </tr>
    <ng-container *ngIf="formatGroups.length === 0">
      <div class="mx-auto bulk-hold-no-items-message d-flex justify-content-center align-items-center flex-column"
           data-automation-id="bulk-hold-no-items-message"
           aria-atomic="true"
           role="alert"
           aria-live="assertive">
        <p class="m-0">{{'bulkHoldNoItemsSelectedMessage' | translate}}</p>
        <p class="m-0">{{'returnToCatalogMessage' | translate}}</p>
      </div>
    </ng-container>
  </tbody>
</table>
