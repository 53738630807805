import { Component, Injector, Input } from '@angular/core';
import { UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectValue } from 'user/components/account/profile/profile-form-field-input-select/profile-form-field-input-select.component';
import { ControlValueAccessorConnector } from 'user/components/bookshelf/helpers/control-value-accessor-connector';
import { NotificationPreferenceData } from 'user/models/user';

export interface SelectValueExtended extends SelectValue {
  description?: string;
  outputValue: NotificationPreferenceData;
}

@Component({
  selector: 'profile-form-field-input-select-extended',
  templateUrl: './profile-form-field-input-select-extended.component.html',
  styleUrls: ['./profile-form-field-input-select-extended.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ProfileFormFieldInputSelectExtendedComponent,
    multi: true,
  }],
})
export class ProfileFormFieldInputSelectExtendedComponent extends ControlValueAccessorConnector {
  @Input() public values: SelectValueExtended[];

  public select = this.formBuilder.control('none');

  constructor(injector: Injector, private readonly formBuilder: UntypedFormBuilder) {
    super(injector);
  }

  public trackById(index: number, value: SelectValueExtended) {
    return value.id;
  }

  public setValue(id: string): void {
    const option = this.values.find((option: any) => id === option.id);
    this.controlContainer.control.setValue(option.outputValue);
  }
}

