import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'common/shared.module';
import { NlbSiteLinkComponent } from './components/nlb-site-link.component';

@NgModule({
  declarations: [
    NlbSiteLinkComponent
  ],
  exports: [
    NlbSiteLinkComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
})
export class NlbSiteLinkModule { }
