import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { SavedSearchState } from '../../../../saved-search/reducers/saved-search.reducer';
import { OpenedDynamicShowcaseWithDetails } from '../../../models/custom-showcase';
import { savedSearchMetadataSortOrderTranslations } from '../../../models/custom-showcase.translations';
import { runSavedSearch } from 'app/saved-search/actions/saved-search.actions';

@Component({
  selector: 'app-dynamic-showcase-search-data',
  templateUrl: './dynamic-showcase-search-data.component.html',
  styleUrls: ['./dynamic-showcase-search-data.component.scss'],
})
export class DynamicShowcaseSearchDataComponent {

  @Input() openedShowcase: OpenedDynamicShowcaseWithDetails;

  public readonly metadataSortOrderTranslation = savedSearchMetadataSortOrderTranslations;

  constructor(private readonly store: Store<SavedSearchState>) {
  }

  public runSavedSearch(): void {
    this.store.dispatch(runSavedSearch({savedSearchId: this.openedShowcase.showcase.createdFrom.id}));
  }
}
