import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Drawer, DrawerElement } from 'search/models/search-models';
import { SearchResultService } from 'search/services/search-results.service';
import {
  drawerInformationLoad,
  drawerInformationLoadComplete,
  loadLocations,
  locationsLoadComplete,
  queryDrawerContent,
  queryDrawerContentComplete
} from '../actions/entity.actions';
import { select, Store } from '@ngrx/store';
import { EntityState, getDrawer, getQueriedDrawer } from '../reducers/entity.reducer';

@Injectable()
export class DrawerEffects {
  private FILTER_FIELDS = ['callNumber', 'publicationDate', 'locationName', 'physicalDescription', 'publisherInformation'];

  public loadDrawerInfo$ = createEffect(() => this.actions$.pipe(
    ofType(drawerInformationLoad),
    switchMap(({formatGroupId, tabName, selectedLocationCodes}) =>
      this.searchResultService.getDrawer(formatGroupId, tabName, selectedLocationCodes)),
    map((response: Drawer) => drawerInformationLoadComplete(response)),
  ));

  public loadLocations$ = createEffect(() => this.actions$.pipe(
    ofType(loadLocations),
    switchMap(({formatGroupId, tabName}) => this.searchResultService.getLocations(formatGroupId, tabName)
      .pipe(
        map((locations) => locationsLoadComplete({locations})),
      ),
    ),
  ));

  public queryDrawerInfo$ = createEffect(() => this.actions$.pipe(
      ofType(queryDrawerContent, drawerInformationLoadComplete),
      withLatestFrom(this.store.pipe(select(getDrawer)), this.store.pipe(select(getQueriedDrawer))),
      map(([, {content}, {query}]) => {
        const filteredItems = this.filterDrawerItemsByQuery(query, content?.items ?? []);
        return queryDrawerContentComplete({...content, items: filteredItems});
      })
    ),
  );

  private filterDrawerItemsByQuery(query: string, drawerItems: DrawerElement[]) {
    if (!query) {
      return drawerItems;
    }
    return drawerItems.reduce((target: DrawerElement[], item) => {
        for (const filter of this.FILTER_FIELDS) {
          if ((item as any)[filter]?.toLowerCase()?.trim()?.includes(query?.toLowerCase()?.trim())) {
            target.push(item);
            break;
          }
        }
        return target;
    }, []);
  }

  constructor(
    private readonly actions$: Actions,
    private readonly searchResultService: SearchResultService,
    private readonly store: Store<EntityState>,
  ) {
  }
}
