import { Directive, ElementRef, AfterViewChecked } from '@angular/core';

@Directive({
  selector: '[externalLink]',
})
export class ExternalLinkDirective implements AfterViewChecked {

  constructor(private el: ElementRef) { }

  public ngAfterViewChecked() {
    Array.from(this.el.nativeElement.querySelectorAll('a'))
    .forEach((el: any) => {
      el.setAttribute('target', '_blank');
    });
  }
}
