<div class="p-5" data-automation-id="delete-confirmation-dropdown-content">
  <span data-automation-id="delete-confirmation-question">
    {{ 'removeItemsQuestion' | translate: {itemsCount: itemsCount} }}
  </span>
  <div class="d-flex mt-5">
    <button class="ins-buttons button-primary flint"
            ngbDropdownItem
            translate
            data-automation-id="delete-confirmation-confirm-button"
            (click)="confirmDelete()">
      removeButton
    </button>
    <button class="ins-buttons button-secondary flint ml-3"
            ngbDropdownItem
            translate
            data-automation-id="delete-confirmation-cancel-button"
            (click)="cancel()">
      cancelButton
    </button>
  </div>
</div>
