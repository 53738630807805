import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormatGroup } from '../../../../entity/models/entity';

@Component({
  selector: 'app-ineligible-for-bulk-hold-modal',
  templateUrl: './ineligible-for-bulk-hold-modal.component.html',
  styleUrls: ['./ineligible-for-bulk-hold-modal.component.scss']
})
export class IneligibleForBulkHoldModalComponent {
  @Input() ineligibleFormatGroups: FormatGroup[];

  constructor(
    private readonly activeModal: NgbActiveModal,
  ) {
  }

  public onClose(): void {
    this.activeModal.dismiss();
  }
}
