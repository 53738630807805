import { Injectable } from '@angular/core';
import ITEM_LANGUAGE_CODE_MAP from '../customer-integration/nlb/nlb-item-locator/models/item-language-code-map';
import { NlbItemLanguageCodeMap } from 'app/customer-integration/nlb/nlb-item-locator/models/nlb-item-locator';
@Injectable({
  providedIn: 'root'
})
export class ItemLanguageMapperService {
  public static getLanguageByCode(code: string) {
    return ITEM_LANGUAGE_CODE_MAP.find((item: NlbItemLanguageCodeMap) => item.id == code);
  }
}
