<div class="showcase-settings">
  <div class="showcase-settings__title w-100 px-4 pb-2 d-inline-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-between">
    <div class="d-inline-flex flex-column flex-sm-row">
      <div class="d-inline-flex align-items-center mt-3 mt-sm-0" data-automation-id="showcase-settings-header-showcase-label">
        <fa-icon [icon]="showcaseIcon" [classes]="['showcase-icon p-3 mr-2']"></fa-icon>
        <span class="showcase-settings__title__label mr-3" translate>customShowcase</span>
      </div>

      <div class="d-flex flex-column mt-3 mt-sm-6">
        <span class="showcase-settings__title__name"
              data-automation-id="showcase-settings-header-name">
          {{showcase.name}}
        </span>
        <app-showcase-creator
          [creator]="showcase.creator.nickname"
          [isCreator]="isShowcaseCreator">
        </app-showcase-creator>
      </div>
    </div>

    <div class="d-inline-flex align-items-baseline mt-2">
      <a class="showcase-settings__title__type d-inline-flex align-items-center mr-3" href=""
         (click)="$event.preventDefault(); onShowcaseTypeClick.emit()"
           data-automation-id="showcase-settings-header-type">
        <ng-container [ngSwitch]="showcase?.createdFrom.type">
          <ng-container *ngSwitchCase="showcaseTypeEnum.list">
            <fa-icon [icon]="bookmarksIcon" class="icon mr-2"></fa-icon>
            <span class="type mr-2">{{ 'list' | translate }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="showcaseTypeEnum.savedSearch">
            <fa-icon [icon]="searchIcon" class="icon mr-2"></fa-icon>
            <span class="type mr-2">{{ 'bookshelfCardStatusSavedSearch' | translate }}</span>
          </ng-container>
        </ng-container>
      </a>
      <app-custom-showcase-form-publish-status
        [publishDate]="showcase.publishDate"
        [published]="showcase.published">
      </app-custom-showcase-form-publish-status>
    </div>
  </div>

  <div class="showcase-settings__body px-4 pt-4 w-100 d-inline-flex flex-column flex-sm-row"
       *ngIf="showcase?.createdFrom.type === showcaseTypeEnum.list || displayOnTranslations.length || aboutShowcaseTranslations.length">
    <div *ngIf="showcase?.createdFrom.type === showcaseTypeEnum.list" class="showcase-settings__body__metadata mr-10 mb-4" data-automation-id="showcase-settings-header-sort">
      <div class="showcase-settings__body__metadata__title" translate>customShowcaseSort</div>
      <div class="showcase-settings__body__metadata__value text-nowrap">
        {{metadataSortOrderTranslation[showcase.metadata.sortOrder] | translate}}
      </div>
    </div>

    <div class="showcase-settings__body__metadata mr-10 mb-4" *ngIf="displayOnTranslations.length" data-automation-id="showcase-settings-header-display-on">
      <div class="showcase-settings__body__metadata__title text-nowrap" translate>customShowcaseDisplayedOn</div>
      <div class="showcase-settings__body__metadata__value text-nowrap">
        <div *ngFor="let displayOn of displayOnTranslations">{{displayOn}}</div>
      </div>
    </div>

    <div class="showcase-settings__body__metadata mb-4" *ngIf="aboutShowcaseTranslations.length" data-automation-id="showcase-settings-header-about-showcase">
      <div class="showcase-settings__body__metadata__title" translate>customShowcaseAboutThisShowcase</div>
      <div class="showcase-settings__body__metadata__value">
        <app-cut-text-with-certain-height
          [text]="aboutShowcaseTranslations.join(', ')"
          height="40"
          moreLessColor="#0096ad"
          minLengthToCut="60">
        </app-cut-text-with-certain-height>
      </div>
    </div>
  </div>
</div>
