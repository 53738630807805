<img *ngIf="(item?.logoUrl || item?.params?.imageUrl) && !item.params.hideSystemName"
     [src]="(item?.logoUrl || item?.params?.imageUrl)"
     class="custom-passthrough-image"
     data-automation-id="custom-passthrough-image"
     alt="{{item.params.name || item.params.systemName}}"/>
  <span 
      class="custom-passthrough-system-name text-truncate"
      tippy="{{item.params.name || item.params.systemName}}"
      data-automation-id="custom-passthrough-system-name">
    {{item.params.name || item.params.systemName}}
  </span>
