import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Inject,
  OnInit,
  HostListener,
  ElementRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LOCALE_ID_WRAPPER } from '../../../app.module';
import { ViewportService } from '../../../services/viewport.service';
import { WindowRefService } from '../../../services/window-ref.service';
import { OneTrustService } from '../../../services/one-trust.service';
import { FeatureToggleService } from 'app/services/feature-toggle.service';

@Component({
  selector: 'bc-app',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class AppComponent implements OnInit {
  public featureOneTrustBanner = false;

  constructor(
    private readonly oneTrustService: OneTrustService,
    @Inject(LOCALE_ID_WRAPPER) private readonly locale: string,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly windowRef: WindowRefService,
    private readonly viewportService: ViewportService,
    private readonly elementRef: ElementRef,
    private readonly featureToggleService: FeatureToggleService
  ) {
  }

  public ngOnInit() {
    this.document.documentElement.lang = this.locale;
    this.featureOneTrustBanner = this.featureToggleService.getToggles()['VE-3951_2024-04-30_discover-one-trust-banner'];

    // remove Angular version attribute for security reasons
    this.elementRef.nativeElement.removeAttribute('ng-version');

    this.setViewportSize();
    if (this.featureOneTrustBanner) {
      this.oneTrustService.initialize();
    }
  }

  @HostListener('window:resize')
  public setViewportSize() {
    const width = this.windowRef.nativeWindow().innerWidth;
    const height = this.windowRef.nativeWindow().innerHeight;
    this.viewportService.setViewport({height, width});
  }

  public preventClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }
}
