import { Injectable } from '@angular/core';
import {
  Subject,
  Observable,
} from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import {
  CitationResponseRIS,
  MendeleyAuthUrlResponse,
  MendeleyData,
  AuthTokenData,
} from '../models/citation';

@Injectable({
  providedIn: 'root',
})
export class CitationService {
  private readonly CITATION_URL = 'api/citation';

  constructor(
    private readonly http: HttpClient,
  ) {
  }

  public getRIS(instanceId: string): Subject<CitationResponseRIS> {
    const url = `${this.CITATION_URL}/ris/${instanceId}`;
    const res = new Subject<CitationResponseRIS>();
    this.http.get<Blob>(url, {responseType: 'blob' as 'json', observe: 'response'}).subscribe((data) => {
      let fileName;
      const nameHeader = data.headers.get('content-disposition');
      if (nameHeader) {
        const matchData = nameHeader.match(/filename="(.+)"/);
        fileName = matchData && matchData.length > 1 && matchData[1];
      }
      const body = data.body;
      res.next({body, fileName});
    });
    return res;
  }

  public getDataForRefWorks(instanceId: string): Observable<Blob> {
    const url = `${this.CITATION_URL}/ris/content/${instanceId}?tool=RefWorks`;
    return this.http.get<Blob>(url, {responseType: 'blob' as 'json'});
  }

  public getDataForEasyBib(instanceId: string): Observable<Blob> {
    const url = `${this.CITATION_URL}/ris/content/${instanceId}?tool=EasyBib`;
    return this.http.get<Blob>(url, {responseType: 'blob' as 'json'});
  }

  public getMendeleyAuthUrl(): Observable<MendeleyAuthUrlResponse> {
    const url = `${this.CITATION_URL}/mendeley/auth/url`;
    return this.http.get<MendeleyAuthUrlResponse>(url);
  }

  public getMendeleyAuthToken(code: string): Observable<AuthTokenData> {
    const url = `${this.CITATION_URL}/mendeley/auth/token?code=${code}`;
    return this.http.get<AuthTokenData>(url);
  }

  public refreshMendeleyAuthToken(refreshToken: string): Observable<AuthTokenData> {
    const url = `${this.CITATION_URL}/mendeley/auth/token?refresh_token=${refreshToken}`;
    return this.http.get<AuthTokenData>(url);
  }

  public getMendeleyData(instanceId: string): Observable<MendeleyData> {
    const url = `${this.CITATION_URL}/mendeley/documents/${instanceId}`;
    return this.http.get<MendeleyData>(url);
  }

  public sendDataToMendeley(data: MendeleyData, token: string): Observable<MendeleyData> {
    const url = 'https://api.mendeley.com/documents';
    const options = {
      headers: new HttpHeaders({
        'Accept': 'application/vnd.mendeley-document.1+json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/vnd.mendeley-document.1+json',
      }),
    };
    return this.http.post<MendeleyData>(url, data, options);
  }

  public getCoinsData(instanceId: string): Observable<string> {
    const url = `api/citation/coins/content/${instanceId}`;
    return this.http.get<string>(url, {responseType: 'text' as 'json'});
  }
}
