export const loadPendo = function () {
  const apiKey = '85872289-85e6-434e-7f1b-a803c02ed2be';
  (function (p, e, n, d) {
    var v: any, w, x, y: any, z: any;
    // @ts-ignore
    const o: any = p[d] = p[d] || {};
    o._q = [];
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
    for (w = 0, x = v.length; w < x; ++w) {
      (function (m) {
        o[m] = o[m] || function () {
          o._q[m === v[0] ? 'unshift' : 'push'](
            [m].concat([].slice.call(arguments, 0)));
        };
      })(v[w]);
    }

    const pendoScript = document.getElementById('pendo-script');

    if (!pendoScript) {
      y = e.createElement(n);y.async=!0;
      y.src = 'https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
      y.id = 'pendo-script';
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y,z);
    }
  })(window, document, 'script', 'pendo');
};
