import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { I18NService } from 'app/services/i18-n.service';

export interface AboutTabDetails {
  frequency?: string;
  formerTitle?: string;
  abbreviatedTitle?: string;
  genre?: string;
  language?: string;
  intendedAudience?: string;
  supplementaryContent?: string;
  credits?: string;
  series?: string;
  awards?: string[];
  tableOfContents?: string;
  identifiers?: Map<string, string[]>;
  bibId?: string;
}

@Component({
  selector: 'app-about-tab-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
  @Input() public details: AboutTabDetails;
  @Input() public visible: boolean;
  @Input() public fixMargin: boolean;
  public language: string;
  public identifierKeys: string[];

  public boxHeight = '115';
  public moreLessColor = '#4a4b4c';

  constructor(private i18nService: I18NService) {}

  public ngOnInit(): void {
    this.language = this.i18nService.getLangFacetTitle(this.details.language);
    this.identifierKeys = this.details.identifiers ? Array.from(this.details.identifiers.keys()) : [];
  }
}
