import { CustomerFeature } from '../../customer-integration';

export const NLB_CUSTOMER_HIDDEN_FEATURES: CustomerFeature[] = [
  CustomerFeature.BookshelfAccountTab,
  CustomerFeature.BookshelfReadingHistoryTab,
  CustomerFeature.BulkBookmarks,
  CustomerFeature.BulkHolds,
  CustomerFeature.UserPopupInfoSection,
];

export const SG_PROD_ENV = 'sg-prod';
