import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { stopOpenBookshelfAccountTab } from 'user/actions/user-profile.actions';
import { AccountTabs } from 'user/models/bookshelf';
import { FinesPaymentStatus } from 'user/models/fine';
import { ExpirationMessageInfo } from 'user/models/user';
import {
  getCheckoutsTotal,
  getHoldsTotal,
  getMoneyOwed,
  getOpenBookshelfAccountTabPending,
  getProfileConfiguration
} from 'user/reducers/user.reducer';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit, OnDestroy {
  public activeTabId = AccountTabs.CHECKOUTS;
  public paymentStatus: FinesPaymentStatus;
  public moneyOwed?: number;
  public checkoutsTotal?: number;
  public holdsTotal?: number;
  public expirationMessage?: ExpirationMessageInfo;
  public readonly accountTabs = AccountTabs;

  private readonly subscription: Subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly cdRef: ChangeDetectorRef,
  ) {
  }

  public ngOnInit() {
    this.subscription.add(
      this.store.pipe(select(getOpenBookshelfAccountTabPending))
      .subscribe((openBookshelfAccountTabPending) => {
        if (openBookshelfAccountTabPending !== null) {
          this.store.dispatch(stopOpenBookshelfAccountTab());
          this.openTab(openBookshelfAccountTabPending);
          this.cdRef.markForCheck();
        }
      }),
    );
    this.subscription.add(
      this.store.pipe(select(getMoneyOwed))
      .subscribe((moneyOwed) => this.moneyOwed = moneyOwed),
    );
    this.subscription.add(
      this.store.pipe(select(getCheckoutsTotal))
      .subscribe((checkoutsTotal) => this.checkoutsTotal = checkoutsTotal),
    );
    this.subscription.add(
      this.store.pipe(select(getHoldsTotal))
      .subscribe((holdsTotal) => this.holdsTotal = holdsTotal),
    );
    this.subscription.add(
      this.store.pipe(select(getProfileConfiguration))
      .subscribe((profileConfiguration) => this.expirationMessage = profileConfiguration?.expirationMessage),
    );
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public openTab(tab: AccountTabs) {
    this.activeTabId = tab;
  }
}
