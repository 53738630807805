<div class="p-4">
  <div class="modal-header">
    <h2 ngbAutofocus class="modal-title" data-automation-id="overdrive-get-it-success-title" translate>confirmationActionDialogTitle</h2>
    <button data-automation-id="close-get-it-dialog-btn" type="button" class="close-btn p-0"
      [attr.aria-label]="'closeDialog' | translate" (click)="activeModal.dismiss()">
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
  </div>
  <div class="modal-body">
    <section class="status-section" data-automation-id="overdrive-get-it-success-status-message">
      <fa-icon class="success-icon" aria-hidden="true" [icon]="faCheckCircle" size="4x"></fa-icon>
      <ng-container [ngSwitch]="data.formatType" *ngIf="data?.formatType">
        <div class="status-message" *ngSwitchCase="formats.KINDLE">
          <p translate>kindleRequestSuccessful</p>
          <p translate *ngIf="!downloadLink">
              kindleDownloadFailed
          </p>
        </div>
        <div class="status-message" *ngSwitchCase="formats.OVERDRIVE_READ">
          <p translate>overDriveRequestSuccessful</p>
          <p translate *ngIf="downloadLink">
            overDriveUsageComment
          </p>
          <p translate *ngIf="!downloadLink">overDriveDownloadFailed</p>
        </div>
        <div class="status-message" *ngSwitchCase="formats.EPUB">
          <p translate>
            epubRequestSuccessful
          </p>
          <p translate *ngIf="downloadLink">epubUsageComment</p>
          <p translate *ngIf="!downloadLink">
            overDriveEpubDownloadFailed
          </p>
        </div>
        <div class="status-message" *ngSwitchDefault>
          <p translate>overDriveRequestSuccessful</p>
          <p translate *ngIf="!downloadLink">overDriveDownloadFailed</p>
        </div>
      </ng-container>
      <div class="status-message" *ngIf="data?.isHoldRequest">
        <p translate>holdRequestSuccessful</p>
        <p translate>
          holdRequestComment
        </p>
      </div>
      <div class="status-message" *ngIf="data?.isLockInRequest">
        <p translate>lockInSuccessful</p>
      </div>
    </section>
  </div>
  <div class="modal-footer">
    <section class="actions-section" *ngIf="!data?.isHoldRequest && data?.formatType">
      <ng-container [ngSwitch]="data.formatType" *ngIf="downloadLink">
        <button *ngSwitchCase="formats.KINDLE" class="app-button button-raised" translate
          (click)="navigateToDownloadLink()" data-automation-id="overDriveGetFromAmazon">
          getFromAmazon
        </button>
        <button *ngSwitchCase="formats.OVERDRIVE_READ" class="app-button button-raised" translate
          (click)="navigateToDownloadLink()" data-automation-id="overDriveReadNow">
          readNow
        </button>
        <button *ngSwitchCase="formats.OVERDRIVE_LISTEN" class="app-button button-raised" translate
                (click)="navigateToDownloadLink()" data-automation-id="overDriveListen">
          overDriveListen
        </button>
        <button *ngSwitchCase="formats.OVERDRIVE_MP3" class="app-button button-raised" translate
                (click)="navigateToDownloadLink()" data-automation-id="overDriveDownloadMP3">
          downloadMP3
        </button>
        <button *ngSwitchDefault class="app-button button-raised" translate
                (click)="navigateToDownloadLink()" data-automation-id="overDriveDownload">
          download
        </button>
      </ng-container>
      <button *ngIf="[formats.OVERDRIVE_READ, formats.EPUB].includes(data.formatType)" translate
        class="app-button button-text read-later" data-automation-id="overDriveReadLater" (click)="activeModal.close()">
        readLater
      </button>
    </section>
    <section class="actions-section" *ngIf="data?.isHoldRequest || data?.isLockInRequest">
      <button class="app-button button-raised" (click)="activeModal.close()" translate
        data-automation-id="overDriveHoldRequestOkButton">
        ok
      </button>
    </section>
  </div>
</div>
