import { Injectable } from '@angular/core';
import { FacetURLFilters } from 'search/facets/models/facet-state';
import { defaultSearchObjectConfig, SearchObject, SearchQuery, SearchQueryParams, SearchRequestFacets } from 'search/models/search-object';
import { SearchType } from 'search/models/search-type';
import { WindowRefService } from '../../services/window-ref.service';

@Injectable()
export class QueryHelper {

  public localStorage: any;

  constructor(
    private readonly windowRef: WindowRefService,
  ) {
    this.localStorage = this.windowRef.nativeWindow().localStorage;
  }

  public prepareQueryParams(query: string, facets: FacetURLFilters = {}, searchType?: string, mode?: string): SearchQueryParams {
    let searchQuery = {
      ...facets,
      query: !query?.trim?.() ? '*' : query,
      searchType: searchType || SearchType.EVERYTHING,
      pageSize: this.getPageSizeFromLocalStorageOrDefault(),
    } as SearchQuery;

    if (mode !== undefined) {
      searchQuery = {
          ...searchQuery,
          mode
        };
    }

    return {
      queryParams: {
        ...searchQuery,
      },
    };
  }

  public prepareQueryParamsFromSearchObject(searchObject: SearchObject): SearchQueryParams {
    const facets = this.prepareFacetsForQueryParams(searchObject.filters.facets);

    const query: SearchQuery = {
      pageSize: searchObject.pagination.pageSize || this.getPageSizeFromLocalStorageOrDefault(),
      pageNum: searchObject.pagination.pageNum,
      sorting: searchObject.sortingParams.sorting,
      sortOrder: searchObject.sortingParams.sortOrder,
      searchType: searchObject.searchType || SearchType.EVERYTHING,
    };

    const isACRequest = ['Agent', 'Concept'].indexOf(searchObject.resourceType) !== -1;

    if (isACRequest) {
      query.boolQuery = searchObject.searchParams.type.metadataBoolQuery;
    }

    if (searchObject.searchParams.type.searchText) {
      query.query = searchObject.searchParams.type.searchText;
    }
    if (searchObject.searchParams.type.metadataBoolQuery) {
      query.metadata = searchObject.searchParams.type.metadataBoolQuery;
    }

    return {
      queryParams: {
        ...query,
        ...facets,
      },
    };
  }

  public prepareFacetsForQueryParams(searchFacets: SearchRequestFacets = {}): FacetURLFilters {
    const facets = { ...searchFacets };
    return Object.keys(facets)
    .reduce((acc, key) => {
      if ((facets)[key]) {
        (acc as any)[key] = (facets)[key].toString();
      }
      return acc;
    }, {});
  }

  private getPageSizeFromLocalStorageOrDefault(): string {
    return this.localStorage.getItem('pageSize') || defaultSearchObjectConfig.pagination.pageSize;
  }
}
