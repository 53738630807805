import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-holds-and-copies-message',
  templateUrl: './holds-and-copies-message.component.html',
  styleUrls: ['./holds-and-copies-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoldsAndCopiesMessageComponent {
  @Input() holds?: number;
  @Input() copies?: number;
}
