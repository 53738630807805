import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType, } from '@ngrx/effects';
import { select, Store, } from '@ngrx/store';
import { CustomerIntegrationType } from 'app/customer-integration/customer-integration';
import { InformationModalComponent } from 'common/components/information-modal/information-modal.component';
import { SearchResultHelper } from 'core/utils/search-result-helper';
import { EMPTY, from, of } from 'rxjs';
import { catchError, delay, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ConfigurationLoader } from 'shared/configuration-loader';
import {
  beginChangeHoldLocation,
  beginHold,
  beginTabHold,
  changeHoldLocation,
  changeHoldLocationSuccess,
  finalizeVolumeFlow,
  holdRequested,
  loadHolds,
  loadTabHold,
  loadTabHoldComplete,
  pickHoldItemVolume,
  pickHoldLocation,
  pickHoldVolume,
  tabHoldItemAsVolume,
  tabHoldVolume,
  updateShouldCloseModal,
} from 'user/actions/user-profile.actions';
import { ChangeHoldLocationDialogComponent } from 'user/components/change-hold-location-dialog/change-hold-location-dialog.component';
import { GetItDialogComponent } from 'user/components/get-it-dialog/get-it-dialog.component';
import { GetItErrorDialogComponent } from 'user/components/get-it-error-dialog/get-it-error-dialog.component';
import { GetItSuccessDialogComponent } from 'user/components/get-it-success-dialog/get-it-success-dialog.component';
import { ItemVolumeListComponent } from 'user/components/item-volume-list/item-volume-list.component';
import { VolumeListComponent } from 'user/components/volume-list/volume-list.component';
import { HOLD_EXPIRATION_DEFAULT } from 'user/const/hold-expiration-default';
import { GetItPlaceHoldData, GetItPlaceTabHold, ItemVolume, NlbPayload, Volume } from 'user/models/get-it';
import {
  getProfileConfiguration,
  getUserFirstAddress,
  getUserHomeLibraryCode,
  getUserId,
  getUserPreferredPickupLocationCode,
  getUserRequestedIds,
  UserState,
} from 'user/reducers/user.reducer';
import { PickupLocationService } from 'user/services/pickup-location.service';
import { nlbEditionHold } from '../../customer-integration/nlb/nlb-hold/actions/nlb-hold.actions';
import { AvailabilityStatus } from '../../entity/models/entity';
import { ModalQueueService } from '../../list/services/modal-queue.service';
import { GetItService } from '../services/get-it.service';

@Injectable()
export class GetItEffects {

  public beginPlaceHold$ = createEffect(() => this.actions$.pipe(
    ofType(beginHold),
    map((action) => {
      // If a list of record id is received, extract recordIds and dates
      const payload: any = action.payload || {};
      if (payload instanceof Array) {
        return payload.map((item) => ({
          recordId: item.recordId[0],
          date: item.date,
          usePublicApi: item.usePublicApi ?
            item.usePublicApi :
            item.availabilityStatus ? item.availabilityStatus === AvailabilityStatus.ON_ORDER : false,
        }));
      }
      return [{
        recordId: payload.recordId instanceof Array
          ? payload.recordId[0]
          : payload.recordId,
        usePublicApi: payload.usePublicApi ?
          payload.usePublicApi :
          payload.availabilityStatus ? payload.availabilityStatus === AvailabilityStatus.ON_ORDER : false,
      }];
    }),
    withLatestFrom(this.store.pipe(select(getUserRequestedIds))),
    filter(([recordIdList, requestedIds]) => {
      const notRequested = recordIdList[0] && recordIdList[0].recordId && !requestedIds.includes(recordIdList[0].recordId);
      if (!notRequested) {
        this.modalQueueService.startQueue();
        const modalRef = this.modalService.open(InformationModalComponent);
        modalRef.componentInstance.titleTranslateKey = 'itemRequest';
        modalRef.componentInstance.bodyTranslateKey = 'youHaveAlreadyPlacedARequestOnThisTitle';
      }
      return notRequested;
    }),
    withLatestFrom(this.store.pipe(select(getUserId))),
    switchMap(([[recordIdList], userId]) => {
        return this.getItService.getHoldForm({
          patronId: userId?.toString(),
          recordNumber: recordIdList,
          recordType: 'b',
        }).pipe(
          map((response) => {
            if (this.configLoader.customerIntegrationType === CustomerIntegrationType.NLB) {
              const nlbPayload = {
                editionId: response.recordNumber,
                volumes: response.volumes,
                itemsAsVolumes: response.itemsAsVolumes,
              } as NlbPayload;
              return nlbEditionHold({nlbPayload: nlbPayload});
            } else {
              if (response.volumes?.length) {
                return pickHoldVolume({
                  volumes: response.volumes,
                  pickupLocations: response.pickupLocations,
                });
              } else if (response.itemsAsVolumes?.length) {
                return pickHoldItemVolume({
                  itemsAsVolumes: response.itemsAsVolumes,
                  pickupLocations: response.pickupLocations,
                });
              } else {
                return pickHoldLocation({
                  pickupLocations: response.pickupLocations,
                  recordNumber: response.recordNumber,
                  recordType: 'b',
                  usePublicApi: recordIdList[0]?.usePublicApi ?? false,
                });
              }
            }
          }),
          catchError(this.errorHandler()),
        );
    }),
  ));

  public beginTabHold$ = createEffect(() => this.actions$.pipe(
    ofType(beginTabHold),
    withLatestFrom(
      this.store.pipe(select(getUserHomeLibraryCode)),
      this.store.pipe(select(getUserPreferredPickupLocationCode)),
      this.store.pipe(select(getProfileConfiguration)),
      this.store.pipe(select(getUserFirstAddress)),
    ),
    switchMap(([payload, homeLibraryCode, preferredPickupLocationCode, profileConfiguration, userAddress]) => {
      this.store.dispatch(updateShouldCloseModal({ shouldCloseModal: false}));
      return this.pickupLocationService.getUserPickupLocations(profileConfiguration.holdSetting, homeLibraryCode).pipe(
        map((pickupLocations) => {
          const modalRef = this.modalService.open(GetItDialogComponent, {
            centered: true,
            windowClass: 'inspire-custom-modal',
          });
          const selectedLocation = this.pickupLocationService.getUserPickupLocation(pickupLocations, homeLibraryCode, preferredPickupLocationCode);
          modalRef.componentInstance.pickupLocations = pickupLocations;
          modalRef.componentInstance.selectedLocationCode = selectedLocation.code;
          modalRef.componentInstance.borrowByMailConfiguration = profileConfiguration?.borrowByMailConfiguration;
          modalRef.componentInstance.holdExpirationDefault = profileConfiguration?.holdExpirationDefault || HOLD_EXPIRATION_DEFAULT;
          modalRef.componentInstance.holdActivationDateSetting = profileConfiguration?.holdActivationDateSetting;
          modalRef.componentInstance.userAddress = userAddress;
          return modalRef;
        }),
        switchMap((modalRef) =>
          from(modalRef.componentInstance.formDataSubject).pipe(
            map((data: any) => {

              return ({ ...data, payload });
            }),
            catchError((_) => {
              this.modalQueueService.startQueue();
              return EMPTY;
            })
          )
        )
      );
    }),
    filter((data) => !!data),
    withLatestFrom(this.store.pipe(select(getUserId))),
    switchMap(([{
      pickupLocation,
      holdNeededDate,
      borrowByMail,
      payload,
    }, userId]) => {
      const {neededBy, neededFrom, hasExpirationDate} = holdNeededDate;
      const data: GetItPlaceTabHold = {
        patronId: userId?.toString(),
        pickupLocation,
        formatGroupId: payload.payload.id,
        materialTypeName: payload.payload.tabName,
        borrowByMail,
      };
      if (neededBy) {
        data.neededBy = neededBy;
      }

      if (neededFrom && hasExpirationDate) {
        data.neededFrom = neededFrom;
      }
      return this.getItService.placeTabHold(data).pipe(
        map((response) => {
          return ({ recordId: response.recordId });
        }),
        catchError((error) => {
          if (error.status === 409) {
            this.store.dispatch(loadTabHoldComplete());
            if (error.error.data?.itemsAsVolumes?.length) {
              this.store.dispatch(tabHoldItemAsVolume({ data: data, itemsAsVolumes: error.error.data?.itemsAsVolumes }));
            } else if (error.error.data.volumes?.length) {
              this.store.dispatch(tabHoldVolume({ data: data, volumes: (error.error.data.volumes) }));

            }
            else {
              this.store.dispatch(updateShouldCloseModal({ shouldCloseModal: false, errorMessage: error.error.message }));
            }
          } else {
            this.modalService.dismissAll();
            this.errorHandler()(error);
          }
          return EMPTY;
      }),
    );
    }),
    tap(({recordId}) => {
      this.store.dispatch(loadTabHoldComplete());
      this.store.dispatch(updateShouldCloseModal({ shouldCloseModal: true }));
      const modalRef = this.modalService.open(GetItSuccessDialogComponent, {
        centered: true,
        windowClass: 'inspire-custom-modal',
      });
      modalRef.result.finally(() => {
        this.modalQueueService.startQueue();
      });
      modalRef.componentInstance.recordId = recordId;
    }),
    switchMap(({recordId}) => {
      if (recordId) {
        return of(holdRequested({holdId: recordId, isSierra: true}));
      } else {
        return EMPTY;
      }
    }),
  ));

  public tabHoldVolume$ = createEffect(() => this.actions$.pipe(
    ofType(tabHoldVolume),
    switchMap(({data, volumes}) => {
      const modalRef = this.modalService.open(VolumeListComponent, {centered: true, windowClass: 'inspire-custom-modal'});
      modalRef.componentInstance.volumes = volumes;
      return from(modalRef.result).pipe(
        map((volume: Volume) => finalizeVolumeFlow({
          data: {
            ...data,
            recordNumber: volume.id,
            recordType: 'j',
          }
        })),
        catchError((_) => {
          this.modalQueueService.startQueue();
          return EMPTY;
        }),
      );
    }),
    delay(1),
  ));

  public tabHoldItemAsVolume$ = createEffect(() => this.actions$.pipe(
    ofType(tabHoldItemAsVolume),
    switchMap(({data, itemsAsVolumes}) => {
      const modalRef = this.modalService.open(ItemVolumeListComponent, {centered: true, windowClass: 'inspire-custom-modal'});
      modalRef.componentInstance.itemsAsVolumes = itemsAsVolumes;
      return from(modalRef.result).pipe(
        map((itemVolume: ItemVolume) => finalizeVolumeFlow({
          data: {
            ...data,
            recordNumber: itemVolume.id,
            recordType: 'i',
          }
        })),
        catchError((_) => {
          this.modalQueueService.startQueue();
          return EMPTY;
        }),
      );
    }),
    delay(1),
  ));

  public finalizeVolumeFlow$ = createEffect(() => this.actions$.pipe(
    ofType(finalizeVolumeFlow),
    tap(() => this.store.dispatch(loadTabHold())),
    switchMap(({data}) => {
      return this.getItService.placeHold(data).pipe(
        // this is to be able to resubmit the request.
        // Otherwise, after the first failed request, we will not be able to make a new call.
        catchError(this.errorHandler()),
      );
    }),
    tap(() => {
       this.store.dispatch(updateShouldCloseModal({shouldCloseModal: true}));
       this.store.dispatch(loadTabHoldComplete());
       this.store.dispatch(loadHolds());
        const modalRef = this.modalService.open(GetItSuccessDialogComponent, {
        centered: true,
        windowClass: 'inspire-custom-modal',
      });
      modalRef.result.finally(() => {
        this.modalQueueService.startQueue();
      });

    }),
  ), {dispatch: false})


  public pickHoldVolume$ = createEffect(() => this.actions$.pipe(
    ofType(pickHoldVolume),
    switchMap((action) => {
      const modalRef = this.modalService.open(VolumeListComponent, { centered: true, windowClass: 'inspire-custom-modal' });
      modalRef.componentInstance.volumes = action.volumes;
      return from(modalRef.result).pipe(
        map((volume: Volume) => pickHoldLocation({
          pickupLocations: action.pickupLocations,
          recordType: 'j',
          recordNumber: volume.id,
          volume: volume.volume,
        })),
        catchError((_) => {
          this.modalQueueService.startQueue();
          return EMPTY;
        }),
      );
    }),
    delay(1),
  ));

  public pickHoldItemVolume$ = createEffect(() => this.actions$.pipe(
    ofType(pickHoldItemVolume),
    switchMap((action) => {
      const modalRef = this.modalService.open(ItemVolumeListComponent,
        {
          centered: true,
          windowClass: 'inspire-custom-modal',
        },
      );
      modalRef.componentInstance.itemsAsVolumes = action.itemsAsVolumes;
      return from(modalRef.result).pipe(
        map((itemVolume: ItemVolume) => pickHoldLocation({
          itemVolume: [itemVolume.callNumber, itemVolume.location].filter((val) => !!val).join(' - '),
          pickupLocations: action.pickupLocations,
          recordNumber: itemVolume.id,
          recordType: 'i',
        })),
        catchError((_) => {
          this.modalQueueService.startQueue();
          return EMPTY;
        }),
      );
    }),
    delay(1),
  ));

  public finishHold$ = createEffect(() => this.actions$.pipe(
    ofType(pickHoldLocation),
    withLatestFrom(
      this.store.pipe(select(getUserHomeLibraryCode)),
      this.store.pipe(select(getUserPreferredPickupLocationCode)),
      this.store.pipe(select(getProfileConfiguration)),
      this.store.pipe(select(getUserFirstAddress)),
    ),
    switchMap(([payload, homeLibraryCode, preferredPickupLocationCode, profileConfiguration, userAddress]) => {
      this.store.dispatch(updateShouldCloseModal({shouldCloseModal: false}));
      const modalRef = this.modalService.open(GetItDialogComponent,
        {
          centered: true,
          windowClass: 'inspire-custom-modal',
        });
      const pickupLocations = payload.pickupLocations;
      const selectedLocation = this.pickupLocationService.getUserPickupLocation(pickupLocations, homeLibraryCode, preferredPickupLocationCode);
      modalRef.componentInstance.selectedLocationCode = selectedLocation.code;
      modalRef.componentInstance.pickupLocations = pickupLocations;
      modalRef.componentInstance.volume = payload.volume;
      modalRef.componentInstance.itemVolume = payload.itemVolume;
      modalRef.componentInstance.borrowByMailConfiguration = profileConfiguration?.borrowByMailConfiguration;
      modalRef.componentInstance.holdExpirationDefault = profileConfiguration?.holdExpirationDefault || HOLD_EXPIRATION_DEFAULT;
      modalRef.componentInstance.holdActivationDateSetting = profileConfiguration?.holdActivationDateSetting;
      modalRef.componentInstance.userAddress = userAddress;
      return from(modalRef.componentInstance.formDataSubject).pipe(
        map((data: any) => ({ ...data, recordId: payload.recordNumber, recordType: payload.recordType, usePublicApi: payload.usePublicApi })),
        catchError((_) => {
          this.modalQueueService.startQueue();
          return EMPTY;
        }),
      );
    }),
    filter((data) => !!data),
    withLatestFrom(this.store.pipe(select(getUserId))),
    switchMap(([{ recordId, recordType, pickupLocation, holdNeededDate, usePublicApi, borrowByMail }, userId]) => {
      const {neededBy, neededFrom, hasExpirationDate} = holdNeededDate;
      const data: GetItPlaceHoldData = {
        patronId: userId?.toString(),
        recordNumber: recordId,
        pickupLocation,
        recordType,
        publicApi: usePublicApi,
        borrowByMail
      };
      if (neededBy) {
        data.neededBy = neededBy;
      }

      if (neededFrom && hasExpirationDate) {
        data.neededFrom = neededFrom;
      }
      return this.getItService.placeHold(data).pipe(
        map(() => ({ recordId, recordType })),
        catchError(this.errorHandler()),
      );
    }),
    tap(() => {
      this.store.dispatch(loadTabHoldComplete());
      this.store.dispatch(updateShouldCloseModal({ shouldCloseModal: true }));
      const modalRef = this.modalService.open(GetItSuccessDialogComponent, {
        centered: true,
        windowClass: 'inspire-custom-modal',
      });
      modalRef.result.finally(() => {
        this.modalQueueService.startQueue();
      });
    }),
    switchMap(({ recordId, recordType }) =>
      recordType === 'b' ? of(holdRequested({holdId: recordId, isSierra: true})) : EMPTY),
  ));

  public beginChangeHoldLocation$ = createEffect(() => this.actions$.pipe(
    ofType(beginChangeHoldLocation),
    withLatestFrom(this.store.pipe(select(getUserId))),
    switchMap(([{hold}, userId]) =>
      this.getItService.getHoldForm({
        patronId: userId?.toString(),
        recordNumber: [{recordId: hold.recordId}],
        recordType: 'b',
      }).pipe(
        map(({pickupLocations}) => changeHoldLocation({hold, pickupLocations})),
        catchError(this.changeLocationErrorHandler()),
      ),
    ),
  ));

  public changeHoldLocation$ = createEffect(() => this.actions$.pipe(
    ofType(changeHoldLocation),
    switchMap(({hold, pickupLocations}) => {
      const modalRef = this.modalService.open(ChangeHoldLocationDialogComponent, {centered: true, windowClass: 'inspire-custom-modal'});
      modalRef.componentInstance.pickupLocations = pickupLocations;
      modalRef.componentInstance.hold = hold;
      return from(modalRef.result).pipe(
        map((data) => ({...data, hold: hold})),
        catchError((_) => of(null)),
      );
    }),
    filter((data) => data && (data.pickupLocation.name !== data.hold.location)),
    switchMap(({pickupLocation, hold}) => {
      const locationFound = this.searchResultHelper.extractPhysicalLocationByCode(pickupLocation.code);
      pickupLocation.name = locationFound ? `location.${pickupLocation.code}` : pickupLocation.name;
      return this.getItService.changeHoldLocation(hold.id, pickupLocation.code).pipe(
        map(() => changeHoldLocationSuccess({holdId: hold.id, pickupLocationName: pickupLocation.name, locationCode: pickupLocation.code})),
        catchError(this.changeLocationErrorHandler()),
      );
    }),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly modalService: NgbModal,
    private readonly getItService: GetItService,
    private readonly store: Store<UserState>,
    private readonly modalQueueService: ModalQueueService,
    private readonly pickupLocationService: PickupLocationService,
    private readonly configLoader: ConfigurationLoader,
    private readonly searchResultHelper: SearchResultHelper,
  ) { }

  private errorHandler() {
    return (err: any) => {
      this.store.dispatch(loadTabHoldComplete());
      this.store.dispatch(updateShouldCloseModal({ shouldCloseModal: true }));
      const modalRef = this.modalService.open(GetItErrorDialogComponent,
        {
          centered: true,
          windowClass: 'inspire-custom-modal',
        });
      modalRef.componentInstance.message = err?.error?.message;
      modalRef.result.finally(() => {
        this.modalQueueService.startQueue();
      });
      return EMPTY;
    };
  }

  private changeLocationErrorHandler() {
    return () => {
      const modalRef = this.modalService.open(InformationModalComponent,
        {
          centered: true,
          windowClass: 'inspire-custom-modal',
        });
      modalRef.componentInstance.titleTranslateKey = 'requestFailedTitle';
      modalRef.componentInstance.bodyTranslateKey = 'changeHoldLocationError';
      return EMPTY;
    };
  }
}
