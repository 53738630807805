import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';

import { NotificationsComponent } from './notifications.component';
import { IconTextNotificationComponent } from './icon-text-notification/icon-text-notification.component';
import { NotificationsService } from './services/notifications.service';

@NgModule({
  imports: [
    CommonModule,
    PortalModule,
  ],
  declarations: [
    IconTextNotificationComponent,
    NotificationsComponent,
  ],
  exports: [
    IconTextNotificationComponent,
    NotificationsComponent,
  ],
  providers: [
    NotificationsService,
  ],
})
export class NotificationsModule { }
