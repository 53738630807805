import { Injectable } from '@angular/core';
import * as Immutable from 'immutable';
import { SearchResultHelper } from 'core/utils/search-result-helper';
import { SearchTerm } from '../models/search-term';
import { SearchResult } from '../models/search-results';
import * as boolParser from 'boolean-parser';
import {
  TermsAndExcludes,
  BoolQueryItem,
} from 'search/models/wheel';

@Injectable()
export class BoolQueryHelper {

  constructor(
    public readonly searchResultHelper: SearchResultHelper,
  ) {}

  public extractQuery$(queryParams: any, initialResources: SearchResult[]): TermsAndExcludes {
    if (!initialResources || !initialResources.length) {
      throw new Error('Query result error: entities with current query not found') as any;
    }
    const normalizedQuery: string = this.normalizeQuery(queryParams.query);
    if (this.isContainsBracketsInsideBrackets(normalizedQuery)) {
      throw new Error('Query string error: Brackets inside brackets are not supported') as any;
    }
    if (!/^(\s?(AND\s|OR\s|AND\sNOT)?\(?\w*:[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}\)?)+$/g
      .test(normalizedQuery)) {
        throw new Error('Query string error: Query string is not correct') as any;
    }
    const typesAndIds = normalizedQuery.match(/\w*:[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/g);
    const ids = Immutable.Set(typesAndIds.map((v) => v.split(':')[1]));
    if (initialResources.length < ids.size) {
      throw new Error('Query result error: entities with current query not found') as any;
     }
    const cache = initialResources.reduce((acc, item) => {
      return acc.set(item.id, item);
    }, Immutable.Map<string, SearchResult>());
    return {
      terms: this.decomposeQuery(normalizedQuery, cache),
      excludes: this.getExclusionsFromQuery(normalizedQuery, cache),
    };
  }

  public extractQuery(queryParams: any): BoolQueryItem[] {
    return queryParams.query.replace(/(\(|\))/g, '').split(' ').reduce((acc: any, item: any) => {
      if (item.indexOf(':') > 0) {
        const [entityType, id] = item.split(':');
        return acc.concat([{ id, entityType }]);
      } else {
        return acc.concat([{ entityType: 'operator', value: item }]);
      }
    }, []);
  }

  private normalizeQuery(query: string) {
    return boolParser.removeOuterBrackets(boolParser.removeDoubleWhiteSpace(query));
  }

  private isContainsBracketsInsideBrackets(transformedQuery: string): boolean {
    return transformedQuery.search(/\([^()]*\(.*\)[^()]*\)/g) > -1;
  }

  private getExclusionsFromQuery(query: string, cache: Immutable.Map<string, SearchResult>): SearchResult[] {
    return (query.match(/NOT\(.+?\)/ig) || [])
      .map((str) => cache.get( str.split(':')[1].slice(0, -1) ));
  }

  private decomposeQuery(query: string, cache: Immutable.Map<string, SearchResult>, level = 0): SearchTerm[][] {
    query = level > 0 ? query : 'AND ' + query;
    const elementsArray = query
      .match(/(AND\s|OR\s)\(.*?\)|((AND\s|OR\s)\w*:[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/g);
    const result: any[] = [];
    elementsArray.forEach((v) => {

      if (boolParser.containsBrackets(v)) {
        result.push(this.decomposeQuery(v.replace(/\(|\)/g, ''), cache, 1));
      } else {
        const id: string = v.replace(/(AND\s|OR\s)/g, '').split(':')[1];
        const fullItem = cache.get(id);
        const searchTerm = {
          id: fullItem.id,
          key: `${fullItem.id}_${this.newGuid()}`,
          entityType: fullItem.entityType,
          value: this.extractTitle(fullItem),
          operator: v.replace(/(AND\s|OR\s)(.*)/g, '$1').trim(),
        };
        result.push(level > 0 ? searchTerm : [searchTerm]);
      }
    });
    return result;
  }

  private extractTitle(resource: any) {
    if (['Agent', 'Concept'].indexOf(resource.entityType) > -1) {
      return resource.label;
    } else {
      return this.searchResultHelper.extractTitle(resource);
    }
  }

  private newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
