import { Action, createAction, props } from '@ngrx/store';
import { FacetSchemaWithData } from 'search/facets/models/facet-schema-with-data';
import {
  DateFacet,
  Facet,
  FacetResult,
  FacetsLocked,
  ResourceFacet,
  ResourceFacetLoadCompletePayload,
} from 'search/facets/models/resource-facet';
import { FacetBlockState } from 'search/models/filter-panel';
import {
  SearchObject,
  MappedSearchObject,
} from 'search/models/search-object';

export const RESOURCE_FACETS_LOAD_COMPLETE = '[Resource Facets] Load complete';
export const EXPAND_RESOURCE_FACETS = '[Resource Facets] Expand resource facets';
export const EXPAND_RESOURCE_FACETS_BLOCK = '[Resource Facets] Expand specific resource facets block';
export const LOAD_LAZY_FACET = '[Resource Facets] Load lazy facet';
export const COLLAPSE_RESOURCE_FACETS = '[Resource Facets] Collapse resource facets';
export const RESOURCE_FACET_SELECTED = '[Resource Facets] Facet selected';
export const UPDATE_SELECTED_FACETS = '[Resource Facets] Selected facets updated';
export const RESOURCE_FACET_BLOCK_SELECTED = '[Resource Facets] Facet block selected';
export const APPLY_RESOURCE_FACETS = '[Resource Facets] Apply selected resource facets';
export const RESET_RESOURCE_FACETS = '[Resource Facets] Reset resource facets';
export const CLEAR_RESOURCE_FACETS = '[Resource Facets] Clear resource facets';
export const LOAD_RESOURCE_FACETS = '[Resource Facets] Load resource facets';
export const CANCEL_EDITING_RESOURCE_FACETS = '[Resource Facets] Cancel editing resource facets';
export const REMOVE_FACET_BUBBLE = '[Resource Facets] Remove facet bubble';
export const TOGGLE_FACET_BUBBLE_LOCK = '[Resource Facets] Toggle facet bubble lock';
export const HANDLE_FACETS_LOCK_IN_STORAGE = '[Resource Facets] Handle facet lock in the localStorage';
export const RESET_FACET_BUBBLES = '[Resource Facets] Reset facet bubbles';
export const UPDATE_SEARCH_OBJECT = '[Resource Facets] Update searchObject';
export const CLEAR_FACETS_LOCK_IN_STORAGE = '[Resource Facets] Clear FACETS_LOCKED localStorage';

export const loadSingleLazyFacet = createAction(
  '[Resource Facets] Load single lazy facet',
  props<{ facetType: string, searchObject: MappedSearchObject, changedQuery: boolean }>(),
);

export const singleLazyFacetLoadCompleteAction = createAction(
  '[Resource Facets] Load single complete',
  props<{
    facet: FacetResult<Facet>;
    facetKey: string;
    schemaWithData: FacetSchemaWithData;
    facetsLocked: FacetsLocked;
  }>(),
);

export class ResourceFacetsLoadCompleteAction implements Action {
  public readonly type = RESOURCE_FACETS_LOAD_COMPLETE;

  constructor(public payload: ResourceFacetLoadCompletePayload) {
  }
}

export class UpdateSelectedFacetsAction implements Action {
  public readonly type = UPDATE_SELECTED_FACETS;

  constructor(public payload: { selected: Facet[], facetKey: string }) {
  }
}

export class UpdateSearchObjectAction implements Action {
  public readonly type = UPDATE_SEARCH_OBJECT;
  constructor(public payload: Partial<SearchObject>) {
  }
}

export class ExpandResourceFacetsAction implements Action {
  public readonly type = EXPAND_RESOURCE_FACETS;

  /**
   * Expands facets by their order
   * # [0,1] means to expand first and second facet
   * expands all applied facets if payload not set
   * @param payload - order of facets to expand
   */
  constructor(public payload: number[] | null) {
  }
}

export class ExpandResourceFacetsBlockAction implements Action {
  public readonly type = EXPAND_RESOURCE_FACETS_BLOCK;

  constructor(public payload: FacetBlockState) {
  }
}

export class LazyFacetLoadAction implements Action {
  public readonly type = LOAD_LAZY_FACET;

  constructor(public payload: { searchObject: MappedSearchObject, changedQuery: boolean }) {
  }
}

export class CollapseResourceFacetsAction implements Action {
  public readonly type = COLLAPSE_RESOURCE_FACETS;
}

export class ResourceFacetBlockSelectedAction implements Action {
  public readonly type = RESOURCE_FACET_BLOCK_SELECTED;

  constructor(public payload: { facetKey: string }) {
  }
}

export class ResourceFacetSelectedAction implements Action {
  public readonly type = RESOURCE_FACET_SELECTED;

  constructor(public payload: Partial<ResourceFacet> | DateFacet) {
  }
}

export class ApplyResourceFacetsAction implements Action {
  public readonly type = APPLY_RESOURCE_FACETS;
}

export class ClearResourceFacetsAction implements Action {
  public readonly type = CLEAR_RESOURCE_FACETS;
}

export class ResetResourceFacetsAction implements Action {
  public readonly type = RESET_RESOURCE_FACETS;
}

export class LoadResourceFacetsAction implements Action {
  public readonly type = LOAD_RESOURCE_FACETS;

  constructor(public payload: { searchObject: MappedSearchObject, changedQuery: boolean }) {
  }
}

export class CancelEditingResourceFacetsAction implements Action {
  public readonly type = CANCEL_EDITING_RESOURCE_FACETS;
}

export class RemoveFacetBubbleAction implements Action {
  public readonly type = REMOVE_FACET_BUBBLE;

  constructor(public payload: { facet: ResourceFacet }) {
  }
}

export class ToggleFacetBubbleLockAction implements Action {
  public readonly type = TOGGLE_FACET_BUBBLE_LOCK;

  constructor(public payload: { facet: Partial<ResourceFacet> }) {
  }
}

export class HandleFacetsLockInStorage implements Action {
  public readonly type = HANDLE_FACETS_LOCK_IN_STORAGE;

  constructor(public payload: { facet: Partial<ResourceFacet> }) {
  }
}

export class ResetFacetBubblesAction implements Action {
  public readonly type = RESET_FACET_BUBBLES;

  constructor(public payload: { resetAll?: boolean }) {
  }
}

export class ClearFacetsLockInStorage implements Action {
  public readonly type = CLEAR_FACETS_LOCK_IN_STORAGE;
}

export type FacetActions =
  | ExpandResourceFacetsAction
  | ExpandResourceFacetsBlockAction
  | CollapseResourceFacetsAction
  | ResourceFacetsLoadCompleteAction
  | ResourceFacetSelectedAction
  | ResourceFacetBlockSelectedAction
  | ApplyResourceFacetsAction
  | ResetResourceFacetsAction
  | ClearResourceFacetsAction
  | LoadResourceFacetsAction
  | CancelEditingResourceFacetsAction
  | RemoveFacetBubbleAction
  | HandleFacetsLockInStorage
  | ToggleFacetBubbleLockAction
  | ResetFacetBubblesAction
  | UpdateSelectedFacetsAction
  | UpdateSearchObjectAction
  | LazyFacetLoadAction
