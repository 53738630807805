import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { error } from 'core/utils/error-decorator';
import { Observable } from 'rxjs';
import { ResourceItemsResponse } from 'user/models/resource-item-data';
import { RollupResultsV2, SearchRequestBody, SearchResult } from '../models/search-results';

@Injectable({ providedIn: 'root' })
export class SearchService {
  private static readonly searchUrl = 'api/search';
  private static readonly searchResultUrl = 'api/search-result/search';

  constructor(
    private readonly http: HttpClient,
  ) {}

  public getSearchResults(searchRequestBody: SearchRequestBody): Observable<RollupResultsV2> {
    const searchUrl = SearchService.getSearchUrl();
    return this.http.post<RollupResultsV2>(searchUrl, searchRequestBody, {headers: {'api-version': '2'}});
  }

  @error([])
  public getResource(resourceId: string | string[], resourceType = 'all'): Observable<SearchResult[]> {
    const entityIds = resourceId instanceof Array ? resourceId : Array.of(resourceId);
    return this.getEntities(entityIds, resourceType);
  }

  @error([])
  public getMetadata(metadataId: string | string[], metadataType = 'all_meta'): Observable<SearchResult[]> {
    const entityIds = metadataId instanceof Array ? metadataId : Array.of(metadataId);
    return this.getEntities(entityIds, metadataType);
  }

  @error([])
  public getSearchItemById(id: string): Observable<SearchResult> {
    const url = `${SearchService.searchUrl}/resources/instances/${id}`;
    return this.http.get<SearchResult>(url);
  }

  @error([])
  public getMetadataIds(ids: string[]): Observable<ResourceItemsResponse> {
    return this.http.get<ResourceItemsResponse>(`${SearchService.searchUrl}/resources/all_meta`, {
      params: {
        id: ids.join(','),
      },
    });
  }

  public searchList(id: string, searchRequestBody: SearchRequestBody): Observable<RollupResultsV2> {
    const params = new HttpParams()
      .set('searchType', 'title')
      .set('searchText', searchRequestBody.searchText);
    const options = {
      headers: {'api-version': '1'},
      params
    };
    return this.http.post<RollupResultsV2>(`${SearchService.searchResultUrl}/lists/${id}`, searchRequestBody, options);
  }

  private static getSearchUrl() {
    return `${SearchService.searchResultUrl}/format-groups`;
  }

  private getEntities(entityIds: string[], entityType: string): Observable<SearchResult[]> {
    const url = `${SearchService.searchUrl}/resources/${entityType}?id=${entityIds}`;
    return this.http.get<SearchResult[]>(url);
  }
}
