<div class="card d-flex justify-content-center align-items-center p-12">
  <div class="card-block">
    <h2 class="card-title" data-automation-id="not-found-page-title" translate>
      notFoundError
    </h2>
    <p class="card-text" data-automation-id="not-found-page-message" translate>
      notFoundMessage
    </p>
    <a [routerLink]="['/']"
       class="ins-buttons button-primary flint"
       data-automation-id="not-found-page-home-link"
       translate>
      notFoundLinkTakeHome
    </a>
  </div>

  <div class="mt-5" *ngIf="isNlbCustomer">
    <app-countdown-redirect
      [seconds]="5"
      [redirectTo]="['/']">
    </app-countdown-redirect>
  </div>
</div>
