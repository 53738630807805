import { Injectable } from '@angular/core';
import { WindowRefService } from '../../services/window-ref.service';

@Injectable()
export class StyleLoaderService {

  constructor(
    private readonly windowRef: WindowRefService,
  ) {
  }

  public loadStyle(href: string): Promise<void> {
    return new Promise((resolve) => {
      const styleElement: HTMLLinkElement = this.windowRef.nativeWindow().document.createElement('link');
      if (href) {
        styleElement.href = href;
      }
      styleElement.rel = 'stylesheet';
      styleElement.media = 'all';
      styleElement.type = 'text/css';

      this.windowRef.nativeWindow().document.head.appendChild(styleElement);
      resolve();
    });
  }
}
