import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getPatronOrStaffUserId } from 'user/reducers/user.reducer';
import { focusListComplete, openShowcase } from '../../actions/custom-showcase.actions';
import { CustomShowcase } from '../../models/custom-showcase';
import { CustomShowcaseState, getDoFocusList, getLoadState, LoadState } from '../../reducers/custom-showcase.reducer';
import { CustomShowcaseCardComponent } from '../custom-showcase-card/custom-showcase-card.component';
import { FeatureToggleService } from 'app/services/feature-toggle.service';
import { getPermissionsFromToken } from 'app/keycloak/actions/keycloak.actions';

@Component({
  selector: 'app-custom-showcases-list',
  templateUrl: './custom-showcases-list.component.html',
  styleUrls: ['./custom-showcases-list.component.scss'],
})
export class CustomShowcasesListComponent implements OnInit, OnDestroy {
  @Input() public showcases: CustomShowcase[];
  @Input() public resultsCount: number;
  @Input() public searchText: string;
  @Input() public hasMoreItems: boolean;
  @Input() public scrollContainer: HTMLElement;
  @Output() public moreItemsRequested = new EventEmitter<undefined>();

  public loadState: LoadState;
  public isAuthPatronFlagEnabled: boolean;
  public userId: string;

  @ViewChildren(CustomShowcaseCardComponent) private cards: QueryList<CustomShowcaseCardComponent>;
  @ViewChild('zeroCustomShowcases') private zeroCustomShowcases: ElementRef;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly store: Store<CustomShowcaseState>,
    private readonly featureToggleService: FeatureToggleService,
  ) {
  }

  public ngOnInit(): void {
    this.isAuthPatronFlagEnabled = this.featureToggleService.getToggles()['DIS-30793_2024-04-27_auth_patron'];
    this.subscriptions.add(
      this.store.select(getDoFocusList).subscribe((doFocusList) => {
        if (doFocusList) {
          this.store.dispatch(focusListComplete());
          setTimeout(() => {
            const focusRef = this.cards?.length ? this.cards.first : this.zeroCustomShowcases.nativeElement;
            focusRef.focus();
          });
        }
      }),
    );

    this.subscriptions.add(
      this.store.select(getLoadState).subscribe((loadState) => {
        this.loadState = loadState;
      }),
    );

    this.subscriptions.add(
      this.store.select(getPatronOrStaffUserId).subscribe((userId) => {
        this.userId = userId;
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public openShowcase(showcase: CustomShowcase): void {
    if (showcase.id && this.isAuthPatronFlagEnabled) {
      const showcaseString = `showcase-${showcase.id}`;
      this.store.dispatch(getPermissionsFromToken({showcaseResource: showcaseString}));
    }
    this.store.dispatch(openShowcase(showcase));
  }

  public trackById(index: number, showcase: CustomShowcase): string {
    return showcase.id;
  }
}
