import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { CustomerFeatureToggleKey } from '../models/feature-toggle';
import { License } from '../models/license';

@Injectable({
  providedIn: 'root',
})
export class LicenseService {
  get errorStatus(): number | undefined {
    return this._errorStatus;
  }

  get licenses(): License[] {
    return this._licenses;
  }

  private _licenses?: License[];
  private _errorStatus?: number;

  constructor(private readonly http: HttpClient) {
  }

  public loadLicenses(): Observable<License[]> {
    return this.http.get<{ activeLicenses: License[] }>('status')
    .pipe(
      map((data) => {
        return data.activeLicenses || [];
      }),
      tap((licenses) => this._licenses = licenses),
      catchError((err) => {
        this._errorStatus = err.status;
        return of(undefined);
      }),
    );
  }

  public loadFeatureFlags(): Observable<CustomerFeatureToggleKey[]> {
    return this.http.get<CustomerFeatureToggleKey[]>('api/search-result/feature-flags')
    .pipe(
      map((data) => data || []),
      catchError(() => {
        return of([]);
      }),
    );
  }

  public loadToggles(): Promise<{licenses: License[], customerFeatures: CustomerFeatureToggleKey[]}> {
    return forkJoin([
      this.loadLicenses(),
      this.loadFeatureFlags(),
    ]).pipe(
      map(([licenses, customerFeatures]) => ({licenses, customerFeatures})),
    ).toPromise();
  }
}
