import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomCode, STOP_CUSTOM_PARAM } from 'common/models/custom-code';
import { Subscription } from 'rxjs';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { CustomizableLink } from '../../../models/customization';
@Component({
  selector: 'app-universal-footer',
  templateUrl: './universal-footer.component.html',
  styleUrls: ['./universal-footer.component.scss'],
})
export class UniversalFooterComponent implements OnInit, OnDestroy {
  @Input() noBorderTop = false;
  public privacyPolicyLink: CustomizableLink;
  public customFooter?: CustomCode;
  public stopCustom = false;

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly configLoader: ConfigurationLoader,
    private readonly route: ActivatedRoute,
  ) {
  }

  public ngOnInit(): void {
    this.privacyPolicyLink = this.configLoader.headerConfig.privacyPolicyLink;
    this.customFooter = this.configLoader.headerConfig.customFooter;
    this.subscriptions.add(this.route.queryParamMap.subscribe((params) => {
      this.stopCustom = params.get(STOP_CUSTOM_PARAM) === 'true';
    }));
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
