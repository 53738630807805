<div class="row">
  <div class="col px-0">
    <main [ngClass]="{'py-4 px-6': isWiderThanSm}" tabindex="-1">
      <app-search-bar *ngIf="isWiderThanSm" class="search-bar"></app-search-bar>
      <div class="mt-8 position-relative">
        <app-home-page-preview-v2></app-home-page-preview-v2>
        <app-monster-help-v2 class="monster-help-large-bottom-right d-none d-lg-block"></app-monster-help-v2>
      </div>
    </main>
    <app-universal-footer [noBorderTop]="!isWiderThanSm"></app-universal-footer>
  </div>
</div>
