import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { Inject, Injectable } from '@angular/core';
import { LOCALE_ID_WRAPPER } from '../../app.module';

import { dictionary } from './mat-paginator-intl-custom.translations';

@Injectable()
export class MatPaginatorIntlCustom extends MatPaginatorIntl {
  public locale: string;
  private localeLabels: any;

  constructor(@Inject(LOCALE_ID_WRAPPER) locale: string) {
    super();
    this.locale = locale;
    this.setPaginatorTranslation();
  }

  public setPaginatorTranslation() {
    /* istanbul ignore next */
    this.localeLabels = dictionary[this.locale] || dictionary['en'];
    this.itemsPerPageLabel = this.localeLabels.perPage;
    this.nextPageLabel = this.localeLabels.nextPage;
    this.previousPageLabel = this.localeLabels.prevPage;

    this.getRangeLabel = (page: number, pageSize: number, length: number) => {
      return `${(page * pageSize) + 1} - ${(page * pageSize) + pageSize}${this.localeLabels.of}${length}`;
    };
  }
}
