export function areEqualDeeply(object1: any, object2: any): boolean {
  if (typeof object1 === 'object' && typeof object2 === 'object' && object1 && object2) {
    const keys1 = Object.keys(object1).filter((c) => object1[c] !== null);
    const keys2 = Object.keys(object2).filter((c) => object2[c] !== null);

    return keys1.length === keys2.length &&
      keys1.every((key) => areEqualDeeply(object1[key], object2[key]));
  } else {
    return object1 === object2;
  }
}
