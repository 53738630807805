import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Actions, createEffect, ofType, } from '@ngrx/effects';
import {
  ROUTER_NAVIGATION,
  RouterNavigatedAction,
  routerNavigatedAction,
  RouterNavigationAction,
  SerializedRouterStateSnapshot,
} from '@ngrx/router-store';
import { Action, select, Store } from '@ngrx/store';
import { checkEntityVendorAvailability } from 'app/entity/actions/entity.actions';
import { AvailabilityService } from 'app/entity/services/availability.service';
import { notifyListsLoaded } from 'app/list/actions/list.actions';
import { ModalQueueService } from 'app/list/services/modal-queue.service';
import { notifyReadingHistorySettingLoaded } from 'app/reading-history/actions/reading-history.actions';
import { WindowRefService } from 'app/services/window-ref.service';
import {
  OverDriveAuthErrorDialogComponent
} from 'core/over-drive/components/over-drive-auth-error-dialog/over-drive-auth-error-dialog.component.';
import {
  OverdriveDownloadRedirectDialogComponent
} from 'core/over-drive/components/overdrive-checkout-dialog/overdrive-download-redirect-dialog.component';
import { ErrorCause } from 'core/over-drive/models/over-drive';
import { OverDriveService } from 'core/over-drive/services/over-drive.service';
import { SearchResultHelper } from 'core/utils/search-result-helper';
import { EMPTY, NEVER, Observable, of, zip, } from 'rxjs';
import { catchError, delay, filter, first, map, mergeMap, pluck, switchMap, tap, withLatestFrom, } from 'rxjs/operators';
import { SearchResultsLoadAction } from 'search/actions/search.actions';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { limitTimeoutDelay } from 'shared/utils/limit-timeout-delay';
import { toMessageObjectsArray } from 'shared/utils/unify-error';
import {
  checkoutCardActionFailure,
  checkoutCardActionRequest,
  checkoutCardActionSuccess,
  checkoutRequested,
  clearCheckoutCardActionRequest,
  holdCancelSuccess,
  holdCardActionFailure,
  holdCardActionRequest,
  holdCardActionSuccess,
  holdFreezeSuccess,
  holdRequested,
  holdUnfreezeSuccess,
  loadCheckouts,
  loadCheckoutsFailure,
  loadCheckoutsSuccess,
  loadFines,
  loadFinesFailure,
  loadFinesSuccess,
  loadHolds,
  loadHoldsFailure,
  loadHoldsSuccess,
  loadStaffUser,
  loadUser,
  openBookshelfAccountTab,
  removeCheckout,
  setFinesPaymentStatus,
  setStaffUser,
  setUser,
  stillActive,
  toggleOpenedUserPanel,
  updateCheckout,
  updatePatronPasscode,
  updatePatronPasscodeFailure,
  updatePatronPasscodeSuccess,
  updateProfile,
  updateProfileFailure,
  updateProfilePartialSuccess,
  updateProfileSuccess,
} from 'user/actions/user-profile.actions';
import { AccountTabs } from 'user/models/bookshelf';
import { CheckoutCardActionType, } from 'user/models/checkouts';
import { FineError, FinesPaymentStatuses } from 'user/models/fine';
import { HoldCardActionType, } from 'user/models/holds';
import { newPasscodeIncorrectMessages } from 'user/models/passcode';
import { ProfileUpdate, ProfileUpdated, ProfileUpdateError, UserLocationsToUpdate } from 'user/models/user';
import { ECONTENT_VENDORS, Vendor } from 'user/models/vendor';
import { getInProgressSierraHoldIds, UserState, } from 'user/reducers/user.reducer';
import { UserProfileService } from 'user/services/user-profile.service';
import { loadMyShowcases, notifyLoaded } from '../../custom-showcase/actions/custom-showcase.actions';
import { loadEntity, loadFormatGroup } from '../../entity/actions/full-entity.actions';
import { EntityTypes, VendorType } from '../../entity/models/entity';
import {
  getPermissionsFromToken,
  loggedIn,
  login,
  logout,
  staffLoggedIn,
  updateToken,
  willLogoutSoon } from '../../keycloak/actions/keycloak.actions';
import { KeycloakRole, KeycloakTokenParsedWithClaims } from '../../keycloak/models/keycloak';
import { isAuthorizedInKeycloak, isStaffAuthorizedInKeycloak } from '../../keycloak/reducers/keycloak.reducer';
import { KeycloakService } from '../../keycloak/services/keycloak.service';
import { LOCAL_STORAGE } from '../../models/local-storage';
import { PendoService } from '../../services/pendo.service';
import { FeatureToggleService } from 'app/services/feature-toggle.service';
import { ALWAYS_AVAILABLE_VENDORS } from 'shared/vendor-config';

@Injectable()
export class UserProfileEffects {
  public static readonly queryParamPaymentStatus = 'paymentStatus';
  public static readonly queryParamOpenAccount = 'openAccount';
  public static readonly queryParamOpenLogin = 'openLogin';

  public openAccountTabOrLogin$ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    first(),
    map((action: RouterNavigatedAction) => {
      const { queryParams } = action.payload.routerState.root;
      return queryParams[UserProfileEffects.queryParamOpenAccount];
    }),
    filter((accountTabFromQueryParams: string | undefined) => {
      if (!accountTabFromQueryParams) return false;
      return Object.values(AccountTabs).includes(accountTabFromQueryParams as AccountTabs);
    }),
    withLatestFrom(
      this.store.select(isAuthorizedInKeycloak),
      this.store.select(isStaffAuthorizedInKeycloak),
    ),
    map(([accountTab, isAuthorized, isStaffAuthorized]: [AccountTabs, boolean, boolean]) => {
      if (isStaffAuthorized) return toggleOpenedUserPanel({ isOpened: true });
      if (!isAuthorized) return login();
      this.router.navigate([], {
        queryParams: { [UserProfileEffects.queryParamOpenAccount]: null },
        queryParamsHandling: 'merge',
      });
      return openBookshelfAccountTab({ accountTab });
    }),
  ));

  public openLogin$ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    first(),
    map((action: RouterNavigatedAction) => {
      const { queryParams } = action.payload.routerState.root;
      return queryParams[UserProfileEffects.queryParamOpenLogin];
    }),
    withLatestFrom(
      this.store.select(isAuthorizedInKeycloak),
      this.store.select(isStaffAuthorizedInKeycloak),
    ),
    filter(([openLoginFromQueryParams, isAuthorized, isStaffAuthorized]: [string | undefined, boolean, boolean]) => {
      if (isAuthorized) return isStaffAuthorized;
      return openLoginFromQueryParams === 'true';
    }),
    map(() => {
      this.router.navigate([], {
        queryParams: { [UserProfileEffects.queryParamOpenLogin]: null },
        queryParamsHandling: 'merge',
      });
      return toggleOpenedUserPanel({ isOpened: true });
    }),
  ));

  public startKeycloakLogin$ = createEffect(() => this.actions$.pipe(
    ofType(login),
    tap(() => this.keycloakService.startLogin()),
  ), { dispatch: false });

  public triggerPageLoadingAfterNavigationAndLoggingIn$: Observable<any> = createEffect(() => zip(
    this.actions$.pipe(ofType(setUser)),
    this.actions$.pipe(ofType(ROUTER_NAVIGATION)),
  ).pipe(
    switchMap(([_, payload]: [any, RouterNavigationAction]) => {
      const routerState: Partial<SerializedRouterStateSnapshot> = payload.payload.routerState;
      const { id, entityType, recordId, isbn, upc, issn, controlNumber } = routerState.root.queryParams;

      return this.selectPageForLoadingResult(entityType, id, recordId, isbn, upc, issn, controlNumber);
    }),
  ));

  public loginWithPatronId$ = createEffect(() => this.actions$.pipe(
    ofType(loggedIn),
    switchMap(() => [loadUser(), loadHolds(), loadCheckouts()]),
  ));

  public loginWithStaffId$ = createEffect(() => this.actions$.pipe(
    ofType(staffLoggedIn),
    switchMap(() => [loadStaffUser()]),
  ));

  public loadCheckoutsAndHoldsAfterCheckoutRequested$ = createEffect(() => this.actions$.pipe(
    ofType(checkoutRequested),
    switchMap(() => [loadHolds(), loadCheckouts()]),
  ));

  public loadHoldsAfterHoldRequested$ = createEffect(() => this.actions$.pipe(
    ofType(holdRequested),
    switchMap(({skipLoadHolds}) => !skipLoadHolds ? [loadHolds()] : EMPTY),
  ));

  public oneMinuteBeforeGuestSessionExpiration$ = createEffect(() => this.actions$.pipe(
    ofType(stillActive),
    withLatestFrom(
      this.store.pipe(select(isAuthorizedInKeycloak)),
      this.store.pipe(select(isStaffAuthorizedInKeycloak)),
    ),
    filter(() => !!this.configLoaderService.timeoutSeconds),
    switchMap(([, isPatronAuthorized, isStaffAuthorized]) => (isPatronAuthorized || isStaffAuthorized) ? NEVER : of(undefined).pipe(
      delay(limitTimeoutDelay(this.configLoaderService.timeoutSeconds * 1000 - 60 * 1000) || this.minimalDelay)
    )),
    tap(() => this.userService.openSessionExpiredDialog(false)),
  ), {dispatch: false});

  public openSessionExpiredDialog = createEffect(() => this.actions$.pipe(
    ofType(willLogoutSoon),
    tap(() => this.userService.openSessionExpiredDialog(true)),
  ), {dispatch: false});

  public updateToken = createEffect(() => this.actions$.pipe(
    ofType(updateToken),
    tap(() => this.keycloakService.updateToken()),
  ), {dispatch: false});

  public stillActiveAfterNavigation = createEffect(() => this.actions$.pipe(
    ofType(ROUTER_NAVIGATION),
    map(() => stillActive()),
  ));

  public loadUser$ = createEffect(() => this.actions$.pipe(
    ofType(loadUser),
    switchMap(() => {
      return this.userService.getUser()
      .pipe(
        switchMap(({user, lists, showcases}) => {
          const roles = (
            this.keycloakService.keycloak.tokenParsed as KeycloakTokenParsedWithClaims
          )?.resource_access?.convergence?.roles as KeycloakRole[] || [];
          this.pendoService.startPatronSession({...user, roles });
          const actions = [
            setUser({ user }),
            notifyListsLoaded({ listDtos: lists }),
            notifyLoaded({ customShowcaseDtos: showcases }),
            notifyReadingHistorySettingLoaded({ setting: user.profileConfiguration.fieldsConfiguration.enableReadingHistory })
          ];

          if (this.isAuthPatronFlagEnabled()) {
            const hasPromotedPatronRole = roles.includes(KeycloakRole.PROMOTED_PATRON);

            if (hasPromotedPatronRole) {
              return of(
                ...actions,
                getPermissionsFromToken({ actionsToDispatch: [loadMyShowcases()] })
              );
            } else {
              return of(...actions);
            }

          } else {
            return of(
              ...actions,
              loadMyShowcases()
            );
          }
        }),
        catchError(() => of(setUser({user: null}))),
      );
    }),
  ));

  public loadStaffUser$ = createEffect(() => this.actions$.pipe(
      ofType(loadStaffUser),
      switchMap(() => {
        return this.userService.getStaffUser().pipe(
          switchMap(({ user, lists }) => {
            const roles = this.keycloakService.keycloakRoles;
            const staffUser = { ...user, roles };
            this.pendoService.startStaffSession(staffUser);
            if (this.isAuthPatronFlagEnabled()) {
              return [
                setStaffUser({ staffUser }),
                notifyListsLoaded({ listDtos: lists }),
                getPermissionsFromToken({ actionsToDispatch: [loadMyShowcases()] })
              ];
            }
            else {
              return [
                setStaffUser({ staffUser }),
                notifyListsLoaded({ listDtos: lists }),
                loadMyShowcases()
              ];
            }
          }),
          catchError(error => {
            return of(setStaffUser({ staffUser: null }));
          })
        );
      })
    )
  );

  public loadCheckouts$ = createEffect(() => this.actions$.pipe(
    ofType(loadCheckouts),
    switchMap(() => {
      return this.userService.getUserCheckouts()
      .pipe(
        map((checkouts) => loadCheckoutsSuccess({ checkouts })),
        catchError((err: HttpErrorResponse) => {
          const error = UserProfileEffects.extractErrorOrUnknown(err);
          return of(loadCheckoutsFailure({ error }));
        }),
      );
    }),
  ));

  public loadHolds$ = createEffect(() => this.actions$.pipe(
    ofType(loadHolds),
    withLatestFrom(this.store.pipe(select(getInProgressSierraHoldIds))),
    switchMap(([, requestedHoldIds]) => {
      return this.userService.getUserHolds(requestedHoldIds)
      .pipe(
        map((holds) => loadHoldsSuccess({ holds })),
        catchError((err: HttpErrorResponse) => {
          const error = UserProfileEffects.extractErrorOrUnknown(err);
          return of(loadHoldsFailure({ error }));
        }),
      );
    }),
  ));

  public loadFines$ = createEffect(() => this.actions$.pipe(
    ofType(loadFines),
    switchMap(() => {
      return this.userService.getUserFines()
      .pipe(
        map((finesData) => loadFinesSuccess({ finesData })),
        catchError((err: HttpErrorResponse) => {
          const error = UserProfileEffects.extractErrorOrUnknown(err);
          return of(loadFinesFailure({ error }));
        }),
      );
    }),
  ));

  public updateProfile$ = createEffect(() => this.actions$.pipe(
    ofType(updateProfile),
    switchMap(({ profileUpdate }) => {
      const libraryLocations: UserLocationsToUpdate = this.userService.extractLibraryLocationsFromCodes({
        homeLibrary: profileUpdate.homeLibraryCode,
        preferredPickupLocation: profileUpdate.preferredPickupLocationCode
      });

      return this.userService.updateUserProfile(profileUpdate)
      .pipe(
        map(() => {
          const profileUpdated: ProfileUpdated = {...profileUpdate, ...libraryLocations};
          return updateProfileSuccess({profileUpdated});
        }),
        catchError((err: HttpErrorResponse) => {
          const error = UserProfileEffects.extractProfileUpdateError(err);
          return of(
            ...this.getPartialUpdateActions(error, profileUpdate),
            updateProfileFailure({error}),
          );
        }),
      );
    }),
  ));

  public shouldShowFinesPaymentStatus$ = createEffect(() => (
    zip(
      this.actions$.pipe(ofType(routerNavigatedAction), first()),
      this.actions$.pipe(ofType(loggedIn)),
    ).pipe(
      pluck('0', 'payload', 'routerState', 'root', 'queryParams', UserProfileEffects.queryParamPaymentStatus),
      tap((entityIdTriggeredLogin) => {
        if (entityIdTriggeredLogin) {
          this.router.navigate([], {
            queryParams: { [UserProfileEffects.queryParamPaymentStatus]: null },
            queryParamsHandling: 'merge',
          });
        }
      }),
      switchMap((finePaymentStatus) => {
          const paymentStatus = finePaymentStatus?.toLowerCase();
          return FinesPaymentStatuses.includes(paymentStatus) ?
            of(
              openBookshelfAccountTab({ accountTab: AccountTabs.FINES }),
              setFinesPaymentStatus({ paymentStatus }),
            ) : NEVER;
        },
      ),
    )));

  public checkoutsCardActionRequest$ = createEffect(() => this.actions$.pipe(
    ofType(checkoutCardActionRequest),
    mergeMap(({ checkoutId, actionType, payload, resourceId }) => {
      return this.processCheckoutCardRequest(checkoutId, actionType, payload)
      .pipe(
        switchMap((actions) => {
          if (actionType === CheckoutCardActionType.CheckIn && !this.isVendorAllwaysAvailable(payload) && ECONTENT_VENDORS.includes(payload)) {
            return [checkoutCardActionSuccess({ checkoutId, actionType }),
              checkEntityVendorAvailability({vendor: payload, content: {
                  resourceId: resourceId,
                  reserveId: [checkoutId]
                }}),

              ...actions];
          } else {
            return [checkoutCardActionSuccess({ checkoutId, actionType }),
              ...actions];
          }
        }),
        catchError((error) => {
          if (error.error?.cause === ErrorCause.PASSWORD_NOT_FOUND && payload === VendorType.OVERDRIVE) {
            this.windowRefService.nativeWindow().localStorage.setItem(LOCAL_STORAGE.OVERDRIVE_AUTH_ERROR, 'true');
            const modalRef = this.modalService.open(OverDriveAuthErrorDialogComponent, {size: 'sm'});
            modalRef.componentInstance.errorsResponse = error.error?.message;
          }
          return of(checkoutCardActionFailure({
            checkoutId,
            actionType,
            error: UserProfileEffects.extractCheckoutHoldError(error)
          }));
        }),
        tap(() => this.modalQueueService.startQueue()),
      );
    }),
  ));

  private isVendorAllwaysAvailable (vendor: Vendor) {
    return ALWAYS_AVAILABLE_VENDORS.includes(vendor as any);
  }

  public holdCardActionRequest$ = createEffect(() => this.actions$.pipe(
    ofType(holdCardActionRequest),
    mergeMap(({ holdId, holdRecordId, actionType, vendor, resourceId }) => {
      return this.processHoldCardRequest(holdId, holdRecordId, actionType, vendor)
      .pipe(
        switchMap((actions) => {
          if (actionType === HoldCardActionType.CancelHold && ECONTENT_VENDORS.includes(vendor)) {
            return [holdCardActionSuccess({ holdId, actionType }),
              checkEntityVendorAvailability({vendor: vendor, content: {
                  resourceId: resourceId,
                  reserveId: [holdId]
                }}),
              ...actions];
          } else {
            return [holdCardActionSuccess({ holdId, actionType }),
              ...actions];
          }
        }),
        catchError((error) => {
          if (error.error?.cause === ErrorCause.PASSWORD_NOT_FOUND) {
            this.windowRefService.nativeWindow().localStorage.setItem(LOCAL_STORAGE.OVERDRIVE_AUTH_ERROR, 'true');
            const modalRef = this.modalService.open(OverDriveAuthErrorDialogComponent, { size: 'sm' });
            modalRef.componentInstance.errorsResponse = error.error?.message;
          }
          return of(holdCardActionFailure({
            holdId,
            actionType,
            error: UserProfileEffects.extractCheckoutHoldError(error),
          }));
        }),
      );
    }),
  ));

  public updatePatronPasscode$ = createEffect(() => this.actions$.pipe(
    ofType(updatePatronPasscode),
    mergeMap(({patronPasscodePayload}) => {
      return this.userService.updatePatronPasscode(patronPasscodePayload)
      .pipe(
        switchMap(() => [updatePatronPasscodeSuccess()]),
        catchError((error) => {
          const errorMessage = this.getPasscodeErrorMessage(error);
          return of(updatePatronPasscodeFailure({error: errorMessage}));
        }),
      );
    }),
  ));

  public clearOverdriveAuthErrorOnLogout$ = createEffect(() => this.actions$.pipe(
    ofType(logout),
    tap(() => this.windowRefService.nativeWindow().localStorage.removeItem(LOCAL_STORAGE.OVERDRIVE_AUTH_ERROR)),
  ), {dispatch: false});

  private readonly minimalDelay = 5 * 1000;

  constructor(
    private actions$: Actions,
    private store: Store<UserState>,
    private configLoaderService: ConfigurationLoader,
    private userService: UserProfileService,
    private keycloakService: KeycloakService,
    private overDriveService: OverDriveService,
    private windowRefService: WindowRefService,
    private modalService: NgbModal,
    private readonly router: Router,
    private readonly searchResultHelper: SearchResultHelper,
    private readonly pendoService: PendoService,
    private readonly modalQueueService: ModalQueueService,
    private readonly featureToggleService: FeatureToggleService,
  ) {
  }

  public selectPageForLoadingResult(entityType: EntityTypes, id: string, recordId: string, isbn?: string,
                                    upc?: string, issn?: string, controlNumber?: string) {
    return entityType || recordId || isbn || upc || issn || controlNumber ?
      this.loadFRCResult(entityType, id, recordId, isbn, upc, issn, controlNumber) :
      this.loadSRPResult();
  }

  public loadSRPResult() {
    return [new SearchResultsLoadAction()];
  }

  public loadFRCResult(entityType: EntityTypes, id: string, recordId: string, isbn?: string,
                       upc?: string, issn?: string, controlNumber?: string) {
    if (entityType === EntityTypes.FORMAT_GROUP || recordId) {
      return [loadFormatGroup({entity: {id, entityType, recordId}})];
    } else if (isbn) {
      return [loadFormatGroup({entity: {id, entityType, isbn}})];
    } else if (upc) {
      return [loadFormatGroup({entity: {id, entityType, upc}})];
    } else if (issn) {
      return [loadFormatGroup({entity: {id, entityType, issn}})];
    } else if (controlNumber) {
      return [loadFormatGroup({entity: {id, entityType, controlNumber}})];
    }
    return [loadEntity({entity: {id, entityType}})];
  }

  private isAuthPatronFlagEnabled(): boolean {
    return this.featureToggleService.getToggles()['DIS-30793_2024-04-27_auth_patron'];
  };

  private static extractErrorOrUnknown(err: HttpErrorResponse): FineError {
    return (err.error?.status)
      ? err.error
      : {
        status: err.status,
        message: 'Unknown error',
      };
  }

  private getPartialUpdateActions(error: ProfileUpdateError, profileUpdate: ProfileUpdate): Action[] {
    const actions: Action[] = [];
    if (error.status === 500 && error.fields) {
      error.fields.forEach((field) => {
        if (profileUpdate.hasOwnProperty(field)) {
          delete profileUpdate[field];
        }
      });

      const libraryLocations: UserLocationsToUpdate = this.userService.extractLibraryLocationsFromCodes({
        homeLibrary: profileUpdate.homeLibraryCode,
        preferredPickupLocation: profileUpdate.preferredPickupLocationCode
      });
      const profileUpdated: ProfileUpdated = {...profileUpdate, ...libraryLocations};

      actions.push(updateProfilePartialSuccess({ profileUpdated }));
    }
    return actions;
  }

  private static extractProfileUpdateError(err: HttpErrorResponse): ProfileUpdateError {
    const fields = Array.isArray(err.error?.error) ? err.error.error.map((errorField: any) => errorField?.target) : undefined;
    return {
      status: err.status,
      fields,
    };
  }

  private static extractCheckoutHoldError(error: HttpErrorResponse): string | null {
    const messages = toMessageObjectsArray(error).map((error: any) => error?.message).filter(Boolean);
    return messages.length
      ? messages
      .map((message: string) => message.replace(/^WebPAC Error : /g, '').replace(/\s\s+/g, ' ').replace(/\.$/, ''))
      .join('. ')
      : null;
  }

  private processCheckoutCardRequest = (checkoutId: string, actionType: CheckoutCardActionType, payload?: any): Observable<Action[]> => {
    switch (actionType) {
      case CheckoutCardActionType.RenewCheckout: {
        return this.userService.renewCheckout(checkoutId).pipe(map((checkout) => [updateCheckout({ checkout })]));
      }
      case CheckoutCardActionType.CheckIn: {
        return this.checkInCard(checkoutId, payload)
        .pipe(
          map(() => [removeCheckout({ checkoutId })])
        );
      }
      case CheckoutCardActionType.GetItem: {
        this.windowRefService.nativeWindow().open(payload, '_blank');
        return of([clearCheckoutCardActionRequest({ checkoutId })]);
      }
      case CheckoutCardActionType.GetDownloadRedirect: {
        const modalRef = this.modalService.open(OverdriveDownloadRedirectDialogComponent, {size: 'md'});
        modalRef.componentInstance.reserveId = checkoutId;
        return of([clearCheckoutCardActionRequest({checkoutId})]);
      }
    }
  }

  private checkInCard(checkoutId: string, vendor: VendorType): Observable<void> {
    switch (vendor) {
      case VendorType.CLOUD_LIBRARY:
      case VendorType.BORROW_BOX:
        return this.overDriveService.checkInEContentItem(vendor, checkoutId);
      default:
        return this.overDriveService.checkInTheTitle(checkoutId, vendor);
    }
  }

  private processHoldCardRequest(
    holdId: string,
    holdRecordId: string,
    actionType: HoldCardActionType,
    vendor: Vendor,
  ): Observable<Action[]> {
    switch (actionType) {
      case HoldCardActionType.CancelHold: {
        return this.processHoldCancel(holdId, holdRecordId, vendor);
      }
      case HoldCardActionType.FreezeHold: {
        return this.processHoldFreeze(holdId);
      }
      case HoldCardActionType.UnfreezeHold: {
        return this.processHoldUnfreeze(holdId);
      }
    }
  }

  private processHoldCancel(holdId: string, holdRecordId: string, vendor: Vendor): Observable<Action[]> {
    return this.cancelHold(vendor, holdId)
    .pipe(map(() => [holdCancelSuccess({ holdId, holdRecordId })]));
  }

  private cancelHold(vendor: Vendor, holdId: string): Observable<void> {
    switch (vendor) {
      case Vendor.OVERDRIVE:
        return this.overDriveService.cancelHoldOverdriveItem(holdId);
      case Vendor.CLOUD_LIBRARY:
      case Vendor.AXIS360:
      case Vendor.BORROW_BOX:
        return this.overDriveService.cancelHoldEContentItem(vendor, holdId);
      default:
        return this.userService.cancelHold(holdId);
    }
  }

  private processHoldFreeze(holdId: string): Observable<Action[]> {
    return this.userService.freezeHold(holdId).pipe(map(() => [holdFreezeSuccess({ holdId })]));
  }

  private processHoldUnfreeze(holdId: string): Observable<Action[]> {
    return this.userService.unfreezeHold(holdId).pipe(map(() => [holdUnfreezeSuccess({ holdId })]));
  }

  private getPasscodeErrorMessage(error: HttpErrorResponse): string {
    if (error.status === 401) {
      return 'currentPasscodeIsIncorrect';
    } else if (error.status === 400) {
      const errorCode = error.error.errorCode;
      return newPasscodeIncorrectMessages[errorCode];
    }
    return 'passcodeUpdateFailed';
  }
}
