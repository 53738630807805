import { FacetsActionsProcessor } from 'search/facets/processors/actions/facets-actions-processor';
import {
  FacetState,
  FacetURLFilters,
  makeFacetState,
} from 'search/facets/models/facet-state';
import {
  DateFacet,
  defaultResourceFacet,
  Facet,
  FacetsLocked,
} from 'search/facets/models/resource-facet';
import { FacetSchemaWithData } from 'search/facets/models/facet-schema-with-data';
import { copyObject } from 'search/reducers/utils';

export class SingleFacetActionsProcessor extends FacetsActionsProcessor {
  public processFacetsLoaded(oldState: FacetState<Partial<Facet>>, appliedFilters: FacetURLFilters,
                             schemaWithData: FacetSchemaWithData<Partial<Facet>>, facetsLocked: FacetsLocked): FacetState {
    const isFacetApplied = !!appliedFilters[schemaWithData.schema.mapField];
    const singleFacet = this.combineSingleFacets(schemaWithData, isFacetApplied, oldState, facetsLocked);

    // @ts-ignore
    return makeFacetState({
      schemaWithData,
      selected: copyObject(singleFacet),
      applied: isFacetApplied ? copyObject(singleFacet) : null,
      expanded: oldState ? oldState.expanded : false,
      isLoading: false,
    });
  }

  public processBubblesReset(oldState: FacetState<Partial<Facet>>, resetAll: boolean): FacetState {
    const stateWithoutApplied = oldState.update('applied', (facet) => {
      return !resetAll && facet?.isResetLocked ? facet : this.combineSingleFacets(oldState.schemaWithData as FacetSchemaWithData<Partial<Facet>>);
    });
    return stateWithoutApplied.set('selected', stateWithoutApplied.applied);
  }

  public processFacetLocked(oldState: FacetState<Facet>): FacetState {
    const updated = this.updateResetLocked('selected', oldState);
    return this.updateResetLocked('applied', updated);
  }

  public processFacetSelected(oldState: FacetState<Partial<Facet>>, selectedFacet: Partial<Facet>): FacetState {
    return oldState.set('selected', selectedFacet);
  }

  public processExpandFacets(oldState: FacetState<Facet>, expanded: true, checkSelected: boolean): FacetState {
    return oldState.set('expanded', !checkSelected ? expanded : oldState.selected.selected);
  }

  public processReplaceSelectedFacets(oldState: FacetState, selectedFacets: Facet[]): FacetState {
    throw new Error('Unsupported operation');
  }

  public processFacetBlockSelected(oldState: FacetState<Facet>): FacetState {
    throw new Error('Unsupported operation');
  }

  public processApplyFacets(oldState: FacetState<DateFacet>): FacetState {
    return oldState.set('applied', oldState.selected.selected ? copyObject(oldState.selected) : null);
  }

  public processBubbleRemoved(oldState: FacetState): FacetState {
    const resetSelectedState = oldState.set('selected', this.combineSingleFacets(oldState.schemaWithData as FacetSchemaWithData<Partial<Facet>>));
    return resetSelectedState.set('applied', null);
  }

  public processCancelEditingFacets(oldState: FacetState): FacetState {
    return oldState.set('selected', oldState.applied ? copyObject(oldState.applied)
      : this.combineSingleFacets(oldState.schemaWithData as FacetSchemaWithData<Partial<Facet>>));
  }

  public processResetFacetState(oldState: FacetState<Facet>): FacetState {
    this.updateResetLocked('selected', oldState);
    this.updateResetLocked('applied', oldState);
    return oldState;
  }

  public getAppliedFiltersForLock(schemaWithData: FacetSchemaWithData, filters: FacetURLFilters): string[] {
    return filters[schemaWithData.schema.mapField] ? [schemaWithData.schema.blockName] : null;
  }

  private updateResetLocked(field: 'selected' | 'applied', oldState: FacetState<Facet>) {
    return oldState.update(field, (facet) => {
      if (facet) {
        facet.isResetLocked = !facet.isResetLocked;
      }
      return facet;
    });
  }

  private combineSingleFacets(newSchemaWithData: FacetSchemaWithData<Partial<Facet>>,
                              isApplied = false, oldState?: FacetState<Partial<Facet>>, facetsLocked?: FacetsLocked): Partial<Facet> {
    const facetKey = newSchemaWithData.schema.key;
    let isResetLocked = false;

    if (facetsLocked) {
      const facetLockedByKey = facetsLocked.hasOwnProperty(facetKey) ? facetsLocked[facetKey] : [];
      isResetLocked = facetLockedByKey.includes(newSchemaWithData.schema.blockName);
    }
    if (!oldState) {
      return {
        ...defaultResourceFacet,
        count: newSchemaWithData.data ? newSchemaWithData.data.count : 0,
        selected: isApplied,
        label: newSchemaWithData.schema.blockName,
        id: newSchemaWithData.schema.blockName,
        facetKey,
        isResetLocked,
      };
    }

    return {
      ...oldState.selected,
      count: newSchemaWithData.data ? newSchemaWithData.data.count : 0,
    };
  }
}
