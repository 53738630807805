import { Component } from '@angular/core';
import { HelpEndPoint, HelpLinks } from '../../../models/in-app-help';

@Component({
  selector: 'app-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss']
})
export class HelpButtonComponent {
  public helpUrl = HelpEndPoint + HelpLinks.HOME_PATRON;
}
