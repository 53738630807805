import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NlbBorrowNDeliverComponent } from './components/nlb-borrow-n-deliver/nlb-borrow-n-deliver.component';
import { SharedModule } from 'common/shared.module';

@NgModule({
  declarations: [
    NlbBorrowNDeliverComponent
  ],
  exports: [
    NlbBorrowNDeliverComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class NlbBorrowNDeliverModule { }
