import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType, } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { InformationModalComponent } from 'common/components/information-modal/information-modal.component';
import {
  PrepareEContentGetItAction,
  PrepareEContentHoldRequestAction,
  PrepareHooplaGetItAction,
  PrepareOverDriveGetItAction,
  PrepareOverDriveHoldRequestAction,
  PrepareVendorGetItAction,
} from 'core/over-drive/actions/over-drive.actions';
import { filter, map, withLatestFrom, } from 'rxjs/operators';
import { checkoutRequested, holdRequested, } from 'user/actions/user-profile.actions';
import { getUserRequestedIds, UserState } from 'user/reducers/user.reducer';
import { ModalQueueService } from '../../list/services/modal-queue.service';
import {
  EXTERNAL_GET_IT_SUCCESSFUL,
  EXTERNAL_HOLD_REQUEST_SUCCESSFUL,
  ExternalGetItSuccessfulAction,
  PREPARE_EXTERNAL_GET_IT,
  PREPARE_EXTERNAL_HOLD_REQUEST,
  PrepareExternalGetItAction,
  PrepareExternalHoldRequestAction,
} from '../actions/vendors.actions';
import { VendorType } from '../models/entity';

@Injectable()
export class VendorsEffects {
  public prepareGetIt$: Actions<PrepareVendorGetItAction | undefined> = createEffect(() => this.actions$.pipe(
    ofType(PREPARE_EXTERNAL_GET_IT),
    map((action: PrepareExternalGetItAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getUserRequestedIds))),
    filter(([item, requestedIds]) => {
      const isReserved = !(item.reserveId && (!requestedIds.includes(item.reserveId)) || item.readyHold);
      if (!isReserved) {
        return true;
      }
      if (item.hasEcontentIssues) {
        return true;
      }

      this.modalQueueService.startQueue();
      const modalRef = this.modalService.open(InformationModalComponent);
      modalRef.componentInstance.titleTranslateKey = 'itemCheckout';
      modalRef.componentInstance.bodyTranslateKey = 'youHaveThisTitleCheckedOut';
      return false;
    }),
    map(([item]) => {
      delete item['readyHold'];
      switch (item.vendor) {
        case VendorType.OVERDRIVE: {
          return new PrepareOverDriveGetItAction(item);
        }
        case VendorType.HOOPLA: {
          return new PrepareHooplaGetItAction(item);
        }
        case VendorType.AXIS360:
        case VendorType.BORROW_BOX:
        case VendorType.CLOUD_LIBRARY: {
          return new PrepareEContentGetItAction(item);
        }
      }
    }),
  ));

  public getItSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType(EXTERNAL_GET_IT_SUCCESSFUL),
    map((action: ExternalGetItSuccessfulAction) => action.payload),
    map(({reserveId}) => checkoutRequested({reserveId})),
  ));

  public prepareHoldRequest$: Actions<
    PrepareOverDriveHoldRequestAction
    | PrepareEContentHoldRequestAction
    | undefined
  > = createEffect(() => this.actions$.pipe(
    ofType(PREPARE_EXTERNAL_HOLD_REQUEST),
    map((action: PrepareExternalHoldRequestAction) => action.payload),
    map(({vendor, itemId, reserveId}) => {
      switch (vendor) {
        case VendorType.OVERDRIVE:
          return new PrepareOverDriveHoldRequestAction({itemId, reserveId});
        case VendorType.AXIS360:
        case VendorType.BORROW_BOX:
        case VendorType.CLOUD_LIBRARY:
          return new PrepareEContentHoldRequestAction({contentId: reserveId, vendor});
      }
    }),
  ));

  public holdRequestSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType(EXTERNAL_HOLD_REQUEST_SUCCESSFUL),
    map((action: ExternalGetItSuccessfulAction) => action.payload),
    map(({reserveId}) => holdRequested({holdId: reserveId, isSierra: false})),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<UserState>,
    private readonly modalQueueService: ModalQueueService,
    private readonly modalService: NgbModal,
  ) {
  }
}
