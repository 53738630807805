<div data-automation-id="cookie-notification" class="cookie-notification">
  <span data-automation-id="cookie-notification-message">
    {{ 'gdprMessage' | translate }}
    <span *ngIf="privacyPolicyLink">
      {{ 'gdprToLearnMore' | translate }}
      <a class="link"
        [href]="privacyPolicyLink.href"
        target="_blank"
        data-automation-id="cookieNotificationLibraryPrivacyPolicyLink">{{ privacyPolicyLink.title }}.</a>
    </span>
  </span>
  <button (click)="cookieService.accept()" translate data-automation-id="accept-cookies"
          class="ins-buttons button-secondary flint accept-button">accept
  </button>
</div>
