import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  createNlbBookmark,
  createNlbBookmarkFailure,
  createNlbBookmarkSuccess,
  deleteNlbBookmark,
  deleteNlbBookmarkFailure,
  deleteNlbBookmarkSuccess,
  loadNlbBookmarksSuccess
} from '../actions/nlb-bookmark.actions';
import { NlbBookmark } from '../models/nlb-bookmark';

export interface ToggleState {
  loading: boolean;
  error: boolean;
}

export interface NlbBookmarkState {
  nlbBookmarks: NlbBookmark[];
  toggleState: {[key: string]: ToggleState}
}

export const initialState: NlbBookmarkState = {
  nlbBookmarks: [],
  toggleState: {},
};

const _reducer = createReducer(
  initialState,
  on(loadNlbBookmarksSuccess, (state, {nlbBookmarks}) => ({
    ...state,
    nlbBookmarks,
  })),
  on(createNlbBookmarkSuccess, (state, {nlbBookmark}) => ({
    ...state,
    nlbBookmarks: [...state.nlbBookmarks, nlbBookmark],
  })),
  on(deleteNlbBookmarkSuccess, (state, {nlbBookmark}) => ({
    ...state,
    nlbBookmarks: state.nlbBookmarks.filter(bookmark => bookmark.id !== nlbBookmark.id),
  })),
  on(createNlbBookmark,
    deleteNlbBookmark, (state, {formatGroup}) => ({
      ...state,
      toggleState: {
        ...state.toggleState,
        [formatGroup.id]: {loading: true, error: false}
      }
    })),
  on(createNlbBookmarkSuccess,
    deleteNlbBookmarkSuccess, (state, {formatGroupId}) => ({
      ...state,
      toggleState: {
        ...state.toggleState,
        [formatGroupId]: {loading: false, error: false}
      }
    })),
  on(createNlbBookmarkFailure,
    deleteNlbBookmarkFailure, (state, {formatGroupId}) => ({
      ...state,
      toggleState: {
        ...state.toggleState,
        [formatGroupId]: {loading: false, error: true}
      }
    })),
);

export function reducer(state: NlbBookmarkState | undefined, action: Action) {
  return _reducer(state, action);
}

export const featureKey = 'nlbBookmark';

export const getNlbBookmarkState = createFeatureSelector<NlbBookmarkState>(featureKey);

export const getNlbBookmarks = createSelector(getNlbBookmarkState, (state: NlbBookmarkState): NlbBookmark[] => {
  return state.nlbBookmarks;
});

export const getToggleState = createSelector(
  getNlbBookmarkState,
  (state: NlbBookmarkState, props: {id: string}): ToggleState => {
    return state?.toggleState[props.id];
  }
);
