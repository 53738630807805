import { Component, Input, OnInit, } from '@angular/core';
import { ShareItResource, ShareItShareEntityType } from 'app/share-it/models/share-it';
import { AgentOrConceptData, Entity } from '../../models/entity';

@Component({
  selector: 'app-toolbar-line',
  templateUrl: './toolbar-line.component.html',
  styleUrls: ['./toolbar-line.component.scss'],
})
export class ToolbarLineComponent implements OnInit {
  @Input() public data: AgentOrConceptData;
  @Input() public entity: Entity;
  public resourceData: ShareItResource;

  public ngOnInit() {
    this.resourceData = {
      type: ShareItShareEntityType.RESOURCE,
      attributes: {
        id: this.data.id,
        url: `/search/card?id=${this.data.id}&entityType=${this.data.entityType}`,
        title: this.data.title || '',
        description: this.data.description?.label || '',
        resourceType: this.data.entityType,
      }
    };
  }
}
