import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DictionaryItem, DictionaryTypes } from 'app/models/dictionaries';
import { LocationWithName, LocationWithNameOverride, PhysicalLocation } from 'app/models/locations';
import { error } from 'core/utils/error-decorator';
import * as Immutable from 'immutable';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { OAI_MATERIAL_TYPE_REGEX } from 'search/models/search-results';
import { LocationInfo, VisibleLocation } from 'shared/models/library-info';

type DictionaryCollection = Immutable.Map<DictionaryTypes, Immutable.Map<string, DictionaryItem | PhysicalLocation>>;

@Injectable()
export class DictionariesService {
  private customizationUrl = 'api/customization';
  private readonly customerUrl = 'api/search-result/customer';
  private readonly configurationsUrl = 'api/search-result/customization/configurations';
  private _dictionary: DictionaryCollection = Immutable.Map([
    [DictionaryTypes.MATERIAL_TYPES, Immutable.Map({})],
    [DictionaryTypes.ITEM_STATUSES, Immutable.Map({})],
    [DictionaryTypes.PHYSICAL_LOCATIONS, Immutable.OrderedMap({})],
    [DictionaryTypes.PICKUP_LOCATIONS, Immutable.OrderedMap({})],
  ]);
  private loadingPromise: Promise<void>;

  constructor(
    private http: HttpClient,
  ) {
  }

  @error([])
  public loadMaterialTypeDictionary(): Observable<DictionaryItem[]> {
    return this.http
      .get<DictionaryItem[]>(`${this.customizationUrl}/material-types`);
  }

  @error([])
  public loadStatusDictionary(): Observable<DictionaryItem[]> {
    return this.http
    .get<DictionaryItem[]>(`${this.customizationUrl}/item-statuses`);
  }

  @error([])
  public loadLocationsDictionary(): Observable<PhysicalLocation[]> {
    return this.http.get<PhysicalLocation[]>(`${this.customerUrl}/locations`);
  }

  public loadVisibleLocations(id: string): Observable<VisibleLocation[]> {
    return this.http.get<VisibleLocation[]>(`${this.configurationsUrl}/${id}/location-info/visible-locations`);
  }

  public loadLocationInfo(configId: string, id: string): Observable<LocationInfo> {
    return this.http.get<LocationInfo>(`${this.configurationsUrl}/${configId}/location-info/${id}`);
  }

  public loadAllDictionaries(): Promise<void> {
    if (!this.loadingPromise) {
      this.loadingPromise = forkJoin([
        this.loadMaterialTypeDictionary().pipe(
          tap((materialTypes: DictionaryItem[]) => this._dictionary = this._dictionary
            .set(DictionaryTypes.MATERIAL_TYPES, Immutable.Map(this.setDictionary(materialTypes))),
          ),
        ),
        this.loadStatusDictionary().pipe(
          tap((itemStatuses: DictionaryItem[]) => this._dictionary = this._dictionary
            .set(DictionaryTypes.ITEM_STATUSES, Immutable.Map(this.setDictionary(itemStatuses))),
          ),
        ),
        this.loadLocationsDictionary().pipe(
          tap((locations: PhysicalLocation[]) => {
            this._dictionary = this._dictionary
            .set(DictionaryTypes.PHYSICAL_LOCATIONS, Immutable.OrderedMap(DictionariesService.makeLocationsMap(locations)));
          }),
        ),
      ]).pipe(
        map(() => {
        }),
      ).toPromise().catch(() => Promise.resolve());
    }

    return this.loadingPromise;
  }

  @error([])
  public loadPickupLocations(): Observable<string[]> {
    return this.http.get<string[]>('api/search-result/gates/pickup-locations');
  }

  public getPickupLocations(): Observable<PhysicalLocation[]> {
    const pickupLocations = this._dictionary.get(DictionaryTypes.PICKUP_LOCATIONS);
    if (pickupLocations?.size > 0) {
      // pickupLocations exist in dictionary
      return of([...pickupLocations.values()] as PhysicalLocation[]);
    } else {
      return this.loadPickupLocations().pipe(
        switchMap((locationCodes: string[]) => {
          const locations = locationCodes.map(code => this._dictionary.get(DictionaryTypes.PHYSICAL_LOCATIONS).get(code))
            .filter(location => !!location)
            .sort(this.sortByNameLocation);
          return of(locations);
        }),
        tap((locations: PhysicalLocation[]) => {
          this._dictionary = this._dictionary
              .set(DictionaryTypes.PICKUP_LOCATIONS, Immutable.OrderedMap(DictionariesService.makeLocationsMap(locations)));
        }),
      );
    }
  }

  public getDictionaryItemByCode(dictionary: DictionaryTypes, code: string): DictionaryItem | PhysicalLocation {
    return this._dictionary.getIn([dictionary, code]) as DictionaryItem | PhysicalLocation;
  }

  public getDictionaryItem(dictionary: DictionaryTypes): Immutable.Map<string, DictionaryItem | PhysicalLocation> {
    return this._dictionary.getIn([dictionary]) as Immutable.Map<string, DictionaryItem | PhysicalLocation>;
  }

  public getItemByName(dictionary: DictionaryTypes, itemName: string): string {
    const name = itemName.trim();
    const items = this._dictionary.getIn([dictionary]) as DictionaryItem[];
    return [...items.values()].find((item) => item.name?.trim() === name || item.description?.trim() === name)?.code;
  }

  public getVisibleLocationsWithName(locations: VisibleLocation[]): LocationWithName[] {
    return locations
    .map((location) => {
      const locationMapped: LocationWithNameOverride = {
        code: location.locationCode,
        name: location.nameOverride || this.getDictionaryItemByCode(DictionaryTypes.PHYSICAL_LOCATIONS, location.locationCode)?.name || '',
      };
      if (location.nameOverride) {
        locationMapped.nameOverride = location.nameOverride;
      }
      return locationMapped;
    })
    .sort(this.sortByNameLocation);
  }

  public findFormatByDesc(desc = ''): DictionaryItem[] {
    const materialTypes: Immutable.Map<string, DictionaryItem> = this._dictionary.get(DictionaryTypes.MATERIAL_TYPES);
    return materialTypes.filter((item) => item.description && item.description.toLowerCase().includes(desc.toLowerCase())).toList().toArray();
  }

  public getFormatDescriptionByCode(code?: string): string {
    if (!code) {
      return '';
    }
    const [,,match] = code?.match(OAI_MATERIAL_TYPE_REGEX) ?? [];
    if (match) {
      return match;
    }
    const formatByCode: DictionaryItem = this.getDictionaryItemByCode(DictionaryTypes.MATERIAL_TYPES, code);
    return formatByCode ? (formatByCode.description || 'UNKNOWN') : `UNKNOWN (${code})`;
  }

  public getGetItSuppressedByCode(code: string): boolean {
    const dictionaryItem: Partial<DictionaryItem> = this._dictionary.getIn([DictionaryTypes.ITEM_STATUSES, code]);
    return dictionaryItem && dictionaryItem.getItSuppressed;
  }

  private static makeLocationsMap(locations: PhysicalLocation[]): [string, DictionaryItem][] {
    return locations.map((location) => [location.code, location]);
  }

  private setDictionary(items: DictionaryItem[]): [string, PhysicalLocation | DictionaryItem][] {
    return items.reduce((acc, item) => acc.concat([[item.code, item]]), []);
  }

  private sortByNameLocation(a: LocationWithName, b: LocationWithName) {
    return a.name.localeCompare(b.name);
  }
}
