<div class="p-4">
  <div class="modal-header">
    <h2 ngbAutofocus class="modal-title" data-automation-id="overdrive-get-it-failed-title" translate>getItErrorDialogTitle</h2>
    <button data-automation-id="close-get-it-dialog-btn" type="button" class="close-btn p-0"
      [attr.aria-label]="'closeDialog' | translate" (click)="activeModal.dismiss()">
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="status-section" data-automation-id="overdrive-get-it-failed-status-message">
      <fa-icon class="text-danger mr-1" [icon]="faExclamationCircle" size="4x"></fa-icon>
      <span class="status-message">
        {{'overDriveTitleRequestUnsuccessful' | translate: {vendor: vendorName} }}
      </span>
    </div>
    <p externalLink
       class="message"
       data-automation-id="overdrive-get-it-failed-error-message"
       *ngFor="let error of errors"
       [innerHTML]="error?.message"></p>
  </div>
  <div class="modal-footer">
    <button
      data-automation-id="overdrive-get-it-failed-ok-button"
      class="ins-buttons button-primary flint"
      (click)="activeModal.close()"
      translate>ok</button>
  </div>
</div>
