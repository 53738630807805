import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { updateProfile } from 'user/actions/user-profile.actions';
import { MaxHeightPerfectScrollbar } from 'user/models/bookshelf';
import { Carrier, ProfileUpdate, User } from 'user/models/user';
import { getIsUserLoading, getProfileUpdateState, getUser, ProfileUpdateState, UserState } from 'user/reducers/user.reducer';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  public user: User;
  public isUserLoading: boolean;
  public isEditing = false;
  public readonly maxHeightPerfectScrollbar = MaxHeightPerfectScrollbar.BASE_ACCOUNT_TAB;
  public touchForm$: Observable<void>;
  public profileUpdateState$ = this.store.select(getProfileUpdateState);
  private profileUpdateState: ProfileUpdateState;
  private readonly touchFormSubject = new Subject<void>();
  private profileFormChanges: ProfileUpdate | null = null;
  private readonly subscriptions = new Subscription();

  constructor(
    private readonly store: Store<UserState>,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    this.touchForm$ = this.touchFormSubject.asObservable();
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.store.select(getUser).subscribe((user) => {
        this.user = user;
        this.changeDetectorRef.detectChanges();
      }),
    );
    this.subscriptions.add(
      this.store.select(getIsUserLoading).subscribe((isUserLoading) => {
        this.isUserLoading = isUserLoading;
        this.changeDetectorRef.detectChanges();
      }),
    );

    this.subscriptions.add(
      this.profileUpdateState$.subscribe((profileUpdateState) => {
        this.profileUpdateState = profileUpdateState;
        if (this.profileUpdateState.updated) {
          this.isEditing = false;
        }

        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.touchFormSubject.complete();
  }

  public edit(): void {
    this.isEditing = true;
  }

  public save(): void {
    if (!this.profileUpdateState.loading) {
      if (this.profileFormChanges) {
        this.store.dispatch(updateProfile({profileUpdate: JSON.parse(JSON.stringify(this.profileFormChanges))}));
      } else {
        this.touchFormSubject.next();
      }
    }
  }

  public cancel() {
    this.isEditing = false;
  }

  public onFormValueChange(profileFormChanges: ProfileUpdate | null) {
    this.profileFormChanges = profileFormChanges;
  }
}
