<div class="modal-header">
  <div *ngIf="description">
    <h1 class="modal-title m-0" tabindex="-1" data-automation-id="main-title-label">
      {{description.title}}
    </h1>
    <h2 data-automation-id="main-edition-label" *ngIf="description.edition">
      {{description.edition}}
    </h2>
  </div>
  <button type="button"
          class="close-btn p-0"
          (click)="closePopup()"
          aria-label="Close dialog"
          data-automation-id="close-popup-details-button">
    <span aria-hidden="true" class="icon icon-close"></span>
  </button>
</div>

<div class="modal-body">
  <div class="d-flex justify-content-around" *ngIf="loading" >
        <div class="spinner-border text-primary loading-spinner"
            role="status"
            aria-live="polite">
            <span class="sr-only" translate>
                Loading...
            </span>
        </div>
    </div>
  <ng-container *ngIf="!loading">
    <ng-container *ngIf="dataIsNotEmpty; else nodata">
      <perfect-scrollbar style="max-height: 350px;">

        <app-about-tab-description
          [tabLabel]="tabLabel"
          [description]="description"
          [visible]="isNotEmpty(description)">
        </app-about-tab-description>

        <app-about-tab-details
          [details]="details"
          [visible]="isNotEmpty(details)"
          [fixMargin]="isNotEmpty(description)">
        </app-about-tab-details>

      </perfect-scrollbar>

    </ng-container>

    <ng-template #nodata>
      <app-no-found-data tabType="about" [title]="description?.title"></app-no-found-data>
    </ng-template>

  </ng-container>
</div>
