import { ChangeDetectorRef, Component, ContentChild, ContentChildren, ElementRef, HostListener, QueryList } from '@angular/core';
import { BookshelfTemplateActionDirective } from 'common/directives/bookshelf-template-action.directive';
import { BookshelfTemplateBodyDirective } from 'common/directives/bookshelf-template-body.directive';
import { BookshelfTemplateSortActionDirective } from 'common/directives/bookshelf-template-sort-action.directive';
import { BookshelfTemplateSubheaderActionsDirective } from 'common/directives/bookshelf-template-subheader-actions.directive';
import { BookshelfTemplateSubheaderDirective } from 'common/directives/bookshelf-template-subheader.directive';
import { BookshelfTemplateTitleDirective } from 'common/directives/bookshelf-template-title.directive';

@Component({
  selector: 'app-bookshelf-template',
  templateUrl: './bookshelf-template.component.html',
  styleUrls: ['./bookshelf-template.component.scss'],
})
export class BookshelfTemplateComponent {
  @ContentChild(BookshelfTemplateTitleDirective) public bookshelfTemplateTitle!: BookshelfTemplateTitleDirective;
  @ContentChild(BookshelfTemplateSubheaderDirective) public bookshelfTemplateSubheader!: BookshelfTemplateSubheaderDirective;
  @ContentChild(BookshelfTemplateSortActionDirective) public bookshelfTemplateSortAction!: BookshelfTemplateSortActionDirective;
  @ContentChildren(BookshelfTemplateActionDirective) public bookshelfTemplateActions!: QueryList<BookshelfTemplateActionDirective>;
  @ContentChild(BookshelfTemplateBodyDirective) public bookshelfTemplateBody!: BookshelfTemplateBodyDirective;
  @ContentChild(BookshelfTemplateSubheaderActionsDirective) public bookshelfTemplateSubheaderActions!: BookshelfTemplateSubheaderActionsDirective;
  public psScroll?: HTMLElement;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  public psAfterViewInit(ps: ElementRef): void {
    this.setPsScroll(ps.nativeElement.querySelector('.ps'));
  }

  public psDestroy(): void {
    this.setPsScroll(null);
  }

  private setPsScroll(psScrollableElement: HTMLDivElement | null): void {
    this.psScroll = psScrollableElement;
    this.changeDetectorRef.detectChanges();
  }
}
