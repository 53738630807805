import { createAction, props } from '@ngrx/store';
import { ISearchData } from '../../../../models/customization';

export const redirectCustomPassThroughSearch = createAction(
  '[CustomPassThrough] Redirect Custom Pass Through Search',
  props<{ keyword: string | {}, url: string }>(),
);

export const redirectCustomPassThroughSearchSuccess = createAction(
  '[CustomPassThrough] Redirect Custom Pass Through Search Success',
);

export const redirectCustomPassThroughWork = createAction(
  '[CustomPassThrough] Redirect Custom Pass Through Work',
  props<{ data: ISearchData, url: string }>(),
);

export const redirectCustomPassThroughWorkSuccess = createAction(
  '[CustomPassThrough] Redirect Custom Pass Through Work Success',
);
