import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Breakpoints, Viewport } from '../models/viewport';

@Injectable()
export class ViewportService {
  private viewport$ = new BehaviorSubject<Viewport>({
    width: 0,
    height: 0,
  });

  public getViewport(): Observable<Viewport> {
    return this.viewport$;
  }

  public setViewport(viewport: Viewport): void {
    this.viewport$.next(viewport);
  }

  public isWiderThan(breakpoint: Breakpoints): Observable<boolean> {
    return this.viewport$.pipe(
      map(viewport => viewport.width > breakpoint),
      distinctUntilChanged(),
    );
  }

  public isHigherThan(breakpoint: Breakpoints): Observable<boolean> {
    return this.viewport$.pipe(
      map(viewport => viewport.height > breakpoint),
      distinctUntilChanged(),
    );
  }
}
