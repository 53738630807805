import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { collapseBookshelf } from 'user/actions/user-profile.actions';
import { Fine } from 'user/models/fine';
import { getUserId } from 'user/reducers/user.reducer';

@Component({
  selector: 'app-fine-card',
  templateUrl: './fine-card.component.html',
  styleUrls: ['./fine-card.component.scss'],
})
export class FineCardComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public fine: Fine;
  @Input() public isSelected: boolean;
  @Input() public showCheckbox: boolean;
  @Output() public onSelect = new EventEmitter<void>();
  public ariaTitleId: string;
  public frcLink: boolean;
  public hasResourceTitle: boolean;
  public ill: boolean;
  private readonly subscriptions = new Subscription();
  private userId: string;

  constructor(
    public store: Store,
  ) {
  }

  public ngOnInit() {
    this.ariaTitleId = `ariaLabel-${this.fine.id}`;
    this.subscriptions.add(
      this.store.select(getUserId).subscribe((userId) => {
        this.userId = userId;
      }),
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public onNavigation(): void {
    this.store.dispatch(collapseBookshelf());
  }

  public ngOnChanges(): void {
    this.frcLink = this.fine.resource?.type?.length > 0 && this.fine.resource?.id?.length > 0;
    this.hasResourceTitle = this.fine.resource?.title?.length > 0;
    this.ill = this.fine.resource?.ill;
  }
}
