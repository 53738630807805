import { Component, Input, OnInit } from '@angular/core';
import { CoverColor } from 'shared/models/cover';
import { CoverColorService } from 'shared/service/cover-color.service';

@Component({
  selector: 'app-default-cover',
  templateUrl: './default-cover.component.html',
  styleUrls: ['./default-cover.component.scss']
})
export class DefaultCoverComponent implements OnInit {
  @Input() public author: string;
  @Input() public title: string;
  @Input() public materialType: string;

  public color: CoverColor;

  constructor(private coverColorService: CoverColorService) { }

  ngOnInit(): void {
    this.color = this.coverColorService.pickColorBasedOnHash(this.title);
  }
}
