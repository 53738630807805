import * as Immutable from 'immutable';

export interface PatronPasscodePayload {
  currentPasscode: string;
  newPasscode: string;
}

export interface PasscodeUpdateStatus {
  loading: boolean;
  success: boolean;
  error: string;
}

export const PasscodeUpdatedStateRecord = Immutable.Record<PasscodeUpdateStatus>({
  loading: false,
  success: false,
  error: null
});

export const newPasscodeIncorrectMessages: { [key: string]: string } = {
  'WRONG_FORMAT': 'invalidPasscodeFormat',
  'WRONG_LENGTH': 'invalidPasscodeLength',
  'PASSCODE_CHANGE_NOT_ALLOWED': 'passcodeChangeForbidden',
  'LONG_PASSCODE': 'passcodeIsTooLong',
  'SHORT_PASSCODE': 'passcodeIsTooShort',
  'TRIVIAL_PASSCODE': 'passcodeIsTrivial',
};
