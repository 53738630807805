<div *ngIf="data && !loading" class="content">
  <div *ngIf="data.todayHours && !data.overrideHours.override"
       class="content__today-hours border"
       data-automation-id="library-widget-today-hours">
    <span class="fas fa-clock big-icon" aria-hidden="true"></span>
    <span class="title" translate>todaysHours</span>
    <span class="hours">
      {{data.todayHours}}
    </span>
  </div>

  <div class="content__info border">
    <div class="location align-items-baseline" *ngIf="data.location">
      <span class="fas fa-map-marker-alt small-icon" aria-hidden="true"></span>
      <span class="cdk-visually-hidden" data-automation-id="library-widget-location-label" translate>location</span>
      <span data-automation-id="library-widget-today-info">{{data.location}}</span>
    </div>

    <div class="phones align-items-baseline" *ngIf="data.phones?.length">
      <div *ngFor="let phone of data.phones; index as i" class="phone">
        <span class="fas fa-phone small-icon" aria-hidden="true"></span>
        <span class="cdk-visually-hidden" [attr.data-automation-id]="'library-widget-phone-label-' + i" translate>phone</span>
        <a [href]="'tel:' + phone"
           [attr.data-automation-id]="'library-widget-phone-' + i">
          {{ phone }}
        </a>
      </div>
    </div>

    <div class="email align-items-baseline" *ngIf="data.email">
      <span class="fas fa-envelope small-icon" aria-hidden="true"></span>
      <span class="cdk-visually-hidden" data-automation-id="library-widget-email-label" translate>email</span>
      <a [href]="'mailto:' + data.email"
         data-automation-id="library-widget-email">
        {{data.email}}
      </a>
    </div>

    <div class="web-site align-items-baseline" *ngIf="data.webSite">
      <span class="fas fa-globe-americas small-icon" aria-hidden="true"></span>
      <span class="cdk-visually-hidden" data-automation-id="library-widget-website-label" translate>website</span>
      <a [href]="data.webSite"
         data-automation-id="library-widget-website"
         target="_blank"
         rel="noopener noreferrer">
        {{data.webSite}}
      </a>
    </div>
  </div>

  <ng-container *ngIf="data.overrideHours?.override; else hours">
    <div class="content__schedule border align-items-baseline" data-automation-id="library-widget-override-hours">
      <span class="fas fa-clock small-icon" aria-hidden="true"></span>
      <div class="override-hours mb-8">
        <h3 class="title" translate data-automation-id="library-widget-override-hours-title">hours</h3>
        <a [href]="data.overrideHours.link.url"
           class="d-block"
           data-automation-id="library-widget-override-hours-link"
           target="_blank">
          {{ data.overrideHours.link.text }}
        </a>
      </div>
    </div>
  </ng-container>

  <ng-template #hours>
    <div class="content__schedule border align-items-baseline" *ngIf="data.regular?.length || data.exceptions?.length">
      <span class="fas fa-clock small-icon" aria-hidden="true"></span>
      <div>
        <div class="regular-hours mb-8" data-automation-id="library-widget-regular-hours" *ngIf="data.regular?.length">
          <h3 class="title" translate>hours</h3>
          <app-library-info-schedule [data]="data.regular"></app-library-info-schedule>
        </div>

        <div class="exceptions mb-8" data-automation-id="library-widget-exceptions" *ngIf="data.exceptions?.length">
          <h3 class="title" translate>exceptions</h3>
          <app-library-info-schedule [data]="data.exceptions"></app-library-info-schedule>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<div *ngIf="loading" class="d-flex justify-content-center text-white" role="status" aria-live="polite">
  <div
    class="mt-5 spinner-border library-info-spinner"
    data-automation-id="library-info-spinner">
    <span class="sr-only" translate>Loading...</span>
  </div>
</div>
