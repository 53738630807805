<div class="profile-card-info">
  <app-profile-form-field-label (onRemove)="onRemove.emit()"
                                [fieldName]="fieldName"
                                [icon]="icon"
                                [inputLabel]="inputLabel"
                                [libraryContactLink]="libraryContactLink"
                                [isHelpDisplayed]="!hideHelp && isEditing && control.disabled"
                                [serverError]="isEditing ? serverError : undefined"
                                [isRemoveButtonDisplayed]="displayRemoveFieldButton && isEditing && control.enabled">
  </app-profile-form-field-label>

  <div [ngClass]="{'profile-card-info-value-less-margin-mobile': isEditing}"
       class="profile-card-info-value"
       data-automation-id="profile-card-info-value">
    <div [hidden]="control.disabled || !isEditing" data-automation-id="profile-card-info-value-input-container">
      <ng-container *ngIf="inputDisplayDirective; else defaultInputDisplay">
        <ng-container *ngTemplateOutlet="inputDisplayDirective.template">
        </ng-container>
      </ng-container>
      <ng-template #defaultInputDisplay>
        <app-profile-form-field-input [formControl]="control" [formControlLabel]="inputLabel" [autocompleteValue]="autocompleteValue"></app-profile-form-field-input>
      </ng-template>
    </div>
    <div *ngIf="control.disabled || !isEditing" data-automation-id="profile-card-info-value-display">
      <ng-container
        *ngTemplateOutlet="isArrayControl ? multipleValues : singleValue; context: {value: control.value}"></ng-container>

      <ng-template #multipleValues>
        <ng-container *ngFor="let value of control.value">
          <ng-container *ngTemplateOutlet="singleValue; context: {value: value}"></ng-container>
        </ng-container>
      </ng-template>

      <ng-template #singleValue let-value="value">
        <ng-container *ngIf="valueDisplayDirective; else defaultValueDisplayTemplate">
          <ng-container *ngTemplateOutlet="valueDisplayDirective.template; context: {value: value}"></ng-container>
        </ng-container>

        <ng-template #defaultValueDisplayTemplate>
          <div>{{ value }}</div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
