<div class="remove-custom-showcase-modal-container p-4" data-automation-id="remove-custom-showcase-modal-container">
  <div class="modal-header">
    <h2 class="modal-title title text-capitalize"
        data-automation-id="remove-custom-showcase-modal-title"
        id="remove-custom-showcase-modal-title"
        translate>
      removeCustomShowcaseConfirmation
    </h2>
    <button (click)="onClose()"
            [attr.aria-label]="'closeDialog' | translate"
            class="close-btn p-0 m-n2"
            data-automation-id="close-remove-custom-showcase-dialog-btn"
            type="button">
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
  </div>
  <div class="modal-body">
    <p data-automation-id="remove-custom-showcase-caution"
       id="remove-custom-showcase-caution"
       translate>
      removeCustomShowcaseCaution
    </p>
    <div aria-live="assertive" role="alert">
      <p *ngIf="displayError"
           class="error"
           data-automation-id="remove-custom-showcase-error"
           translate>
        removeCustomShowcaseError
      </p>
    </div>
    <div aria-live="polite" role="status">
      <div *ngIf="pendingRemove"
           class="spinner-border text-primary"
           data-automation-id="remove-custom-showcase-spinner">
        <span class="sr-only" translate>Loading...</span>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-start">
    <button (click)="onConfirm()"
            class="ins-buttons button-primary flint"
            data-automation-id="remove-custom-showcase-modal-confirm-btn"
            translate
            type="button">
      removeCustomShowcaseConfirmationButton
    </button>
    <button (click)="onClose()"
            class="ins-buttons button-secondary flint ml-3 text-uppercase"
            data-automation-id="remove-custom-showcase-modal-cancel-btn"
            translate>
      removeCustomShowcaseKeepButton
    </button>
  </div>
</div>
