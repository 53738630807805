<ol class="d-flex flex-wrap justify-content-center justify-content-md-start mb-0"
    data-automation-id="bookmarks-list-items-list"
    [attr.aria-label]="ariaLabel">
  <li *ngFor="let bookshelfItem of bookshelfItems; trackBy: trackById"
      class="d-block m-2 m-md-4"
      data-automation-id="bookmarks-list-item-card">
    <app-bookshelf-item-card
      [item]="bookshelfItem"
      [listId]="listId"
      [hideCheckbox]="hideCheckbox"
      [isExtendedCard]="isExtendedCard"
      (onToggle)="toggleItemSelection(bookshelfItem)"
    ></app-bookshelf-item-card>
  </li>
</ol>
