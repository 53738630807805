<ng-container *ngIf="authorized">
  <app-select-list-button [buttonClass]="'bulk-add-to-list-button'"
                          [lists]="lists"
                          [listItemEntities]="selectedItemsAsListItems"
                          [listIdsSelected]="[]"
                          [disabled]="!selectedItems.length"
                          [multiselect]="true"
                          [canCreateNew]="true"
                          [confirmDelete]="true"
                          data-automation-id="bulk-panel-add-to-list">
    <span class="text">{{ 'bulkAddToLists' | translate }}</span>
  </app-select-list-button>
</ng-container>
