import {
  Directive,
  HostBinding,
  ElementRef,
  AfterViewInit,
  Input,
} from '@angular/core';

@Directive({
  selector: '[focusOnInit]',
})
export class FocusOnInitDirective implements AfterViewInit {
  @HostBinding('attr.tabindex')
  public tabindex = -1;

  @Input() public focusOnInit: boolean;

  constructor(
    private element: ElementRef,
  ) { }

  public ngAfterViewInit(): void {
    if (this.focusOnInit) {
      this.element.nativeElement.focus();
    }
  }
}
