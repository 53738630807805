<app-checkout-card-action-button
  [action]="action"
  [ariaDescribedBy]="ariaDescribedBy"
  [disabled]="disabled"
  (triggerAction)="triggerAction.emit($event)">
  <ng-container *ngIf="vendor === vendorEnum.GATES">
    <span *cardAction="actionTypeEnum.RenewCheckout"
          translate
          data-automation-id="checkout-card-action-label-renew">
      renew
    </span>
  </ng-container>

  <ng-container *ngIf="vendor === vendorEnum.HOOPLA">
    <span *cardAction="actionTypeEnum.CheckIn; payload:vendor"
          translate
          data-automation-id="checkout-card-action-label-checkin">
      checkIn
    </span>
    <span *cardAction="actionTypeEnum.GetItem; payload:url"
          translate
          data-automation-id="checkout-card-action-label-get-item">
      getItem
    </span>
  </ng-container>

  <ng-container *ngIf="vendor === vendorEnum.OVERDRIVE">
    <span *cardAction="actionTypeEnum.CheckIn; payload:vendor"
          translate
          data-automation-id="checkout-card-action-label-checkin">
      checkIn
    </span>

    <span *cardAction="actionTypeEnum.GetDownloadRedirect"
          translate
          data-automation-id="checkout-card-action-label-get-download-redirect">
      getItem
    </span>
  </ng-container>

  <ng-container *ngIf="econtentVendors.includes(vendor)">
    <ng-container *ngIf="!vendorsWithoutCheckIn.includes(vendor)">
        <span *cardAction="actionTypeEnum.CheckIn; payload:vendor"
              translate
              data-automation-id="checkout-card-action-label-checkin">
          checkIn
        </span>
    </ng-container>
    <span *cardAction="actionTypeEnum.GetItem; payload:url"
          translate
          data-automation-id="checkout-card-action-label-get-item">
        getItem
      </span>
  </ng-container>

</app-checkout-card-action-button>
