<div class="d-flex flex-column p-3 results-block" [ngClass]="isSuccess ? 'success' : 'failure'">
  <p data-automation-id="bulk-renew-results-title" class="mb-1">{{title | translate}}:</p>
  <ol class="mb-0">
    <li *ngFor="let card of checkouts" data-automation-id="bulk-renew-items-status">
      <strong *ngIf="card.receipt?.resource?.title; else noResourceTitle">{{card.receipt.resource?.title}}</strong>
      <ng-template #noResourceTitle>
        <strong>{{ 'resourceForCheckoutNotAvailable' | translate: {id: card.receipt.id} }}</strong>
      </ng-template>
      <span *ngIf="card.action.error">: {{card.action.error}}</span>
    </li>
  </ol>
</div>


