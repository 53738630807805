import { AfterViewInit, Component, ElementRef, Input, OnChanges, Renderer2, ViewChild } from '@angular/core';
import { WindowRefService } from '../../../../../services/window-ref.service';

@Component({
  selector: 'app-nlb-qr-code',
  templateUrl: './nlb-qr-code.component.html',
})
export class NlbQrCodeComponent implements AfterViewInit, OnChanges {
  @Input() public recordId: string;
  @Input() public reserveId?: string;
  @Input() public size: string = '5'; // QR code size, 1-10
  @Input() public isElectronic: boolean;
  public url: string; // Fallback URL if title with uuid not found
  public uuid: string; // Bib Record ID / Overdrive UUID
  public type: string; // 'CATALOGUE'/'EBOOKS.OVERDRIVE'
  private qrCodeElement: ElementRef;

  @ViewChild('qrCodeContainer') qrCodeContainer: ElementRef;

  constructor(
    private readonly renderer: Renderer2,
    private readonly windowRefService: WindowRefService,
  ) {
  }

  public ngOnChanges() {
    this.type = this.isElectronic ? 'EBOOKS.OVERDRIVE' : 'CATALOGUE';
    this.uuid = this.isElectronic ? this.reserveId : this.recordId;
    if (this.recordId) {
      this.url = `${this.windowRefService.nativeWindow().location.origin}/search/card?recordId=${this.recordId}`;
    }
    if (this.qrCodeElement) {
      this.createQrCodeElement();
    }
  }

  public ngAfterViewInit() {
    this.createQrCodeElement();
  }

  // NLB doesn't observe attributes and doesn't rerender component on attributes change,
  // so normal creation via html with attribute binding is not possible.
  // This is a workaround to manually create NLB QR code component with dynamic values
  private createQrCodeElement() {
    if (this.qrCodeElement) {
      this.renderer.removeChild(this.qrCodeContainer, this.qrCodeElement);
    }
    this.qrCodeElement = this.renderer.createElement('nlb-qrcode');
    this.renderer.setAttribute(this.qrCodeElement, 'data-appcode', 'Vega');
    this.renderer.setAttribute(this.qrCodeElement, 'data-uuid', this.uuid);
    this.renderer.setAttribute(this.qrCodeElement, 'data-type', this.type);
    this.renderer.setAttribute(this.qrCodeElement, 'data-url', this.url);
    this.renderer.setAttribute(this.qrCodeElement, 'data-size', this.size);
    this.renderer.appendChild(this.qrCodeContainer.nativeElement, this.qrCodeElement);
  }
}
