import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetItPickupLocationData, GetItPlaceHoldData, GetItPlaceTabHold, HoldsFormInfo, HoldsFormResponse } from 'user/models/get-it';

@Injectable()
export class GetItService {
  constructor(private http: HttpClient) {}

  public getHoldForm(getItPickupLocationData: GetItPickupLocationData): Observable<HoldsFormInfo> {
    return this.http.post<HoldsFormResponse>('api/search-result/gates/request-form', getItPickupLocationData);
  }

  public placeHold(getItPlaceHoldData: GetItPlaceHoldData): Observable<{ holdId: string }> {
    return this.http.post<{ holdId: string }>('api/search-result/gates/holds', getItPlaceHoldData);
  }

  public placeTabHold(getItPlaceHoldData: GetItPlaceTabHold):Observable<{ recordId: string }> {
    return this.http.post<{ recordId: string }>('api/search-result/gates/tab-hold', getItPlaceHoldData);
  }

  public changeHoldLocation(holdId: string, pickupLocation: string): Observable<void> {
    return this.http.put<void>(`api/search-result/gates/holds/${holdId}/location`, {pickupLocation});
  }
}
