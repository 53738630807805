import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { loadFormatGroupAboutInfo } from '../../actions/full-entity.actions';
import {
  AvailabilityStatus,
  AvailabilityUpdateEditionInfo,
  Edition,
  EditionType,
  GetItRequest,
  HoldRequest,
  SearchResultResponse,
  VendorType,
} from '../../models/entity';
import { FullEntityState, selectInstanceAboutInfo, } from '../../reducers/full-entity.reducer';
import { AboutPopupComponent } from '../about-popup/about-popup.component';
import { GetItModalContentComponent } from '../get-it-modal-content/get-it-modal-content.component';
import { FormattedResource } from 'app/entity/models/econtent';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { VendorLogo } from 'shared/models/configuration';

@Component({
  selector: 'app-location-and-edition-table',
  templateUrl: './location-and-edition-table.component.html',
  styleUrls: ['./location-and-edition-table.component.scss'],
})
export class LocationAndEditionTableComponent implements OnInit, OnChanges, OnDestroy {
  public displayedColumns: string[];
  @Input() public tableData: Edition[];
  @Input() public availabilityUpdates: AvailabilityUpdateEditionInfo[];
  @Input() public isElectronic: boolean;
  @Input() public tabName: string;
  @Input() public label: string;
  @Input() public fgId: string;
  @Input() public itemTitle: string;
  @Input() public hasEditionEcontentIssues: boolean;
  @Input() public nonIntegratedLogo: { [key: string]: VendorLogo };
  @Output() public openPanel = new EventEmitter<{ id: string }>();
  @Output() public getIt$ = new EventEmitter<{ item: GetItRequest }>(true);
  @Output() public placeHold$ = new EventEmitter<{ item: HoldRequest }>(true);
  public expandedLocationInformation: Edition;
  public subscriptions = new Subscription();
  public instanceResults: any;
  public availabilityStatuses = AvailabilityStatus;
  public editionType = EditionType;
  public vendorType = VendorType;
  public readonly arrowIconDown = faAngleDown;
  public readonly arrowIconUp = faAngleUp;
  public readonly infoCircle = faInfoCircle
  public readonly formattedResource = FormattedResource;

  public statusKeys = {
    [AvailabilityStatus.AVAILABLE]: 'availableInstance',
    [AvailabilityStatus.CHECKED_OUT]: 'checkedOutInstance',
    [AvailabilityStatus.ON_ORDER]: 'onOrderInstance',
    [AvailabilityStatus.UNAVAILABLE]: 'unavailableInstance',
  };
  public isExpandable: boolean;

  private readonly COMMON_COLUMNS = [
    'date',
    'edition',
    'details',
  ];
  private readonly ELECTRONIC_COLUMNS = [
    ...this.COMMON_COLUMNS,
    'vendor',
    'note',
    'actions',
  ];
  private readonly PHYSICAL_COLUMNS = [
    ...this.COMMON_COLUMNS,
    'publisher',
    'description',
    'isAvailable',
    'actions',
  ];

  constructor(
    private readonly dialog: NgbModal,
    private readonly store: Store<FullEntityState>,
  ) {
  }

  public toggleEdition(edition: Edition) {
    this.expandedLocationInformation = this.expandedLocationInformation === edition ? null : edition;
    this.openPanel.emit({
      id: edition.id,
    });
  }

  public showDetails(edition: Edition) {
    let context;
    if (this.instanceResults[edition.id]) {
      const instanceResult = this.instanceResults[edition.id] as SearchResultResponse;
      context = instanceResult.searchResult;
    } else {
      this.store.dispatch(loadFormatGroupAboutInfo({id: edition.id}));
      context = {
        id: edition.id,
      };
    }

    const modalRef = this.dialog.open(AboutPopupComponent, { windowClass: undefined });
    modalRef.componentInstance.context = context;
    modalRef.componentInstance.tabLabel = this.label;
  }

  public onOpenPublicNoteLink(edition: Edition): void {
    if (edition.eResourceUrls.length > 1) {
      const modalRef = this.dialog.open(GetItModalContentComponent, {windowClass: undefined});
      modalRef.componentInstance.context = {urls: edition.eResourceUrls};
    } else {
      window.open(edition.eResourceUrls[0], '_blank');
    }
  }

  public isAvailableVendor(vendor: any) {
    if (vendor?.vendors?.axis360) {
       return VendorType.AXIS360;
    } else if (vendor?.vendors?.overdrive) {
      return VendorType.OVERDRIVE;
    } else if (vendor?.vendors?.cloudlibrary) {
      return VendorType.CLOUD_LIBRARY;
    } else if (vendor?.vendors?.borrowbox) {
      return VendorType.BORROW_BOX;
    } else if (vendor?.vendors?.oaipmh) {
      return VendorType.OAI_PMH;
    } else if (vendor?.vendors?.hoopla) {
      return VendorType.HOOPLA;
    }
    return '-';
  }

  public getMatchingSubscriptions(idx: number) {
    if (!this.nonIntegratedLogo || !this.availabilityUpdates[idx]?.subscriptionId?.length) {
      return null;
    }
    const matchingId = this.availabilityUpdates[idx]?.subscriptionId?.find(id => this.nonIntegratedLogo[id]);
    return matchingId ? this.nonIntegratedLogo[matchingId].name : null;

  }

  public ngOnInit(): void {

    this.isExpandable = this.tableData && this.tableData.some((item) => item.hasItems);
    this.displayedColumns = this.isElectronic ?
      [...this.ELECTRONIC_COLUMNS] :
      this.isExpandable ? ['expand'].concat(this.PHYSICAL_COLUMNS) : [...this.PHYSICAL_COLUMNS];
    this.subscriptions.add(this.store.pipe(select(selectInstanceAboutInfo)).subscribe((info) => {
      this.instanceResults = info;
    }));
  }

  public ngOnChanges(changes: SimpleChanges) {
    const isEditionExpanded: SimpleChange = changes.isEditionExpanded;
    if (isEditionExpanded && !isEditionExpanded.currentValue) {
      this.expandedLocationInformation = null;
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.dialog.dismissAll();
  }
}
