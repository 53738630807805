<div class="row align-items-center justify-content-center">
  <div class="col-auto">
    <div class="cover-container">
      <app-cover
        [config]="formatGroup"
        [title]="formatGroup.title"
        [author]="formatGroup.primaryAgent?.label"
        [materialType]="formatGroup.materialTabs[0]?.materialTypes[0]"
        size="small">
      </app-cover>
    </div>
  </div>
  <div class="col pl-2">
    <p class="bulk-hold-title m-0" data-automation-id="bulk-hold-item-title">
      {{ formatGroup.title }}
    </p>
    <p class="bulk-hold-author m-0" data-automation-id="bulk-hold-item-author">
      {{ formatGroup.primaryAgent?.label }}
    </p>
  </div>
</div>
