<div aria-live="assertive"
     role="alert"
     (clickOutside)="clickOutsideMessage.emit()"
     [clickOutsideEnabled]="clickOutsideMessageEnabled">
  <p *ngIf="state.type === bookshelfCardActionStateTypeEnum.success || state.type === bookshelfCardActionStateTypeEnum.error"
     class="m-0 alert"
     [class.alert-danger]="state.type === bookshelfCardActionStateTypeEnum.error"
     [class.alert-success]="state.type === bookshelfCardActionStateTypeEnum.success"
     data-automation-id="bookshelf-card-state-message"
     translate>
    {{ state.messageKey }}
  </p>
</div>
<div aria-live="polite" class="d-flex" role="status">
  <div *ngIf="state.type === bookshelfCardActionStateTypeEnum.loading"
       class="spinner-border text-primary p-2"
       data-automation-id="bookshelf-card-state-spinner">
    <span class="sr-only" translate>Loading...</span>
  </div>
</div>
