import { Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { CardActionDirective } from 'common/directives/card-action.directive';
import { HoldCardAction, HoldCardActionPayload, HoldCardActionType } from 'user/models/holds';
import { UserReceiptActionStatus } from 'user/models/user-receipt-card';

@Component({
  selector: 'app-hold-card-action-button',
  templateUrl: './hold-card-action-button.component.html',
  styleUrls: ['./hold-card-action-button.component.scss'],
})
export class HoldCardActionButtonComponent {
  @Input() public action: HoldCardAction | null = null;
  @Input() public ariaDescribedBy = '';
  @Input() public disabled: boolean;
  @Output() public triggerAction = new EventEmitter<HoldCardActionPayload>();

  public cardActions: CardActionDirective[];
  public actionDropdownOpened: boolean;
  public defaultAction: CardActionDirective;
  public readonly actionStatusEnum = UserReceiptActionStatus;
  public readonly actionType = HoldCardActionType;

  @ContentChildren(CardActionDirective)
  public set cardActionsList(actions: QueryList<CardActionDirective>) {
    this.cardActions = actions.toArray();

    if (this.cardActions.length) {
      this.defaultAction = this.cardActions.shift();
    }
  }

  public setDropdownOpened(opened: boolean) {
    this.actionDropdownOpened = opened;
  }

  public toggleDropdown(dropdown: NgbDropdown): void {
    if (!this.disabled && !this.actionDropdownOpened) {
      dropdown.open();
    } else {
      dropdown.close();
    }
  }

  public onTriggerAction(action: CardActionDirective): void {
    this.triggerAction.emit({
      actionType: action.cardAction,
      payload: action.cardActionPayload,
    });
  }
}
