<div id="{{ index }}" class="group-label-hidden">{{ formGroupLabel }}</div>
<div [formGroup]="form" class="form-group" role="group" attr.aria-labelledby="{{ index }}">
  <div class="row no-gutters mb-1">
    <label [for]="ids.line1"
           class="profile-card-info-value-label"
           data-automation-id="profile-card-address-line1-label"
           translate>
      addressLine1
    </label>
    <input [attr.aria-invalid]="line1.invalid && line1.touched"
           [class.is-invalid]="line1.invalid && line1.touched"
           [id]="ids.line1"
           #addressLine1
           [attr.aria-describedby]="ids.line1Error"
           autocomplete="address-line1"
           aria-required="true"
           class="form-control profile-card-info-value-input mb-0"
           data-automation-id="profile-card-address-line1"
           formControlName="line1"
           placeholder="{{ 'profileAddressLine1Placeholder' | translate }}"
           type="text"/>
    <div aria-atomic="true" aria-live="assertive" role="alert" [id]="ids.line1Error"
         data-automation-id="profile-card-address-line1-error">
      <div *ngIf="line1.invalid && line1.touched"
           class="invalid-feedback d-block"
           data-automation-id="profile-card-address-line1-error-message">
        <ng-container *ngIf="line1.errors.notBlank">
          {{ 'profileFieldErrorRequired' | translate: { label: formGroupLabel } }}
        </ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="line2Displayed; else addLine2Button" class="row no-gutters mb-1">
    <label [for]="ids.line2"
           class="profile-card-info-value-label"
           data-automation-id="profile-card-address-line2-label"
           translate>
      addressLine2
    </label>
    <input [attr.aria-invalid]="line2.invalid && line2.touched"
           [class.is-invalid]="line2.invalid && line2.touched"
           [id]="ids.line2"
           #addressLine2
           autocomplete="address-line2"
           aria-required="false"
           class="form-control profile-card-info-value-input mb-0"
           data-automation-id="profile-card-address-line2"
           formControlName="line2"
           placeholder="{{ 'profileAddressLine2Placeholder' | translate }}"
           type="text"/>
  </div>

  <ng-template #addLine2Button>
    <app-add-another-button (onClick)="displayLine2()" (setFocus)="setFocusOnInputField()">
      {{ 'profileAddAnotherAddressLine' | translate }}
    </app-add-another-button>
  </ng-template>

  <div class="row no-gutters mb-1">
    <div class="col-9">
      <label [for]="ids.city"
             class="profile-card-info-value-label"
             data-automation-id="profile-card-address-city-label"
             translate>
        addressCity
      </label>
      <input [attr.aria-invalid]="city.invalid && city.touched"
             [class.is-invalid]="city.invalid && city.touched"
             [id]="ids.city"
             autocomplete="address-level2"
             aria-required="false"
             class="form-control profile-card-info-value-input mb-0"
             data-automation-id="profile-card-address-city"
             formControlName="city"
             placeholder="{{ 'profileAddressCityPlaceholder' | translate }}"
             type="text"/>
    </div>

    <div class="col-3 pl-2">
      <label [for]="ids.stateCode"
             class="profile-card-info-value-label"
             data-automation-id="profile-card-address-state-code-label"
             translate>
        addressStateCode
      </label>
      <input [attr.aria-invalid]="stateCode.invalid && stateCode.touched"
             [class.is-invalid]="stateCode.invalid && stateCode.touched"
             [id]="ids.stateCode"
             autocomplete="address-level1"
             aria-required="false"
             class="form-control profile-card-info-value-input mb-0"
             data-automation-id="profile-card-address-state-code"
             formControlName="stateCode"
             placeholder="{{ 'profileAddressStateCodePlaceholder' | translate }}"
             type="text"/>
    </div>
  </div>

  <div class="row no-gutters">
    <div class="col-9">
      <label [for]="ids.postalCode"
             class="profile-card-info-value-label"
             data-automation-id="profile-card-address-postal-code-label"
             translate>
        addressPostalCode
      </label>
      <input [attr.aria-invalid]="postalCode.invalid && postalCode.touched"
             [class.is-invalid]="postalCode.invalid && postalCode.touched"
             [id]="ids.postalCode"
             autocomplete="postal-code"
             aria-required="false"
             class="form-control profile-card-info-value-input mb-0"
             data-automation-id="profile-card-address-postal-code"
             formControlName="postalCode"
             placeholder="{{ 'profileAddressPostalCodePlaceholder' | translate }}"
             type="text"/>
    </div>
  </div>
</div>
