<div class="account-card-cover-container">
  <a *ngIf="resource?.id; else defaultImage"
     class="activeLink link-content"
     data-automation-id="account-card-cover-link"
     attr.aria-label="{{ 'cardCoverLink' | translate }}"
     (click)="onNavigation.emit()"
     (keydown.enter)="onNavigation.emit()"
     [queryParams]="{id: resource.id, entityType: resource.type}"
     [routerLink]="['/search', 'card']">
    <div class="account-card-cover-container-cover">
      <app-cover
        [config]="{coverUrl: resource.coverUrl}"
        [title]="resource.title"
        [materialType]="resource.materialType"
        size="medium">
      </app-cover>
    </div>
  </a>
  <ng-template #defaultImage>
    <div class="account-card-cover-container-cover">
      <app-cover
        [config]="{}"
        [title]="resource?.title"
        [materialType]="resource?.materialType"
        size="medium">
      </app-cover>
    </div>
  </ng-template>
</div>
