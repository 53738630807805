import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';

import { ConfigurationLoader } from 'shared/configuration-loader';
import { CustomizableLink } from '../../../models/customization';
import { Store } from '@ngrx/store';
import { closeGlobalSidebar } from 'core/actions/global-sidebar.actions';

@Component({
  selector: 'app-global-sidebar',
  templateUrl: './global-sidebar.component.html',
  styleUrls: ['./global-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSidebarComponent implements OnInit {
  public customLinks?: CustomizableLink[];
  public isActive: boolean = true;

  constructor(
    private cdr: ChangeDetectorRef,
    private configLoader: ConfigurationLoader,
    private store: Store,
  ) {}

  public ngOnInit(): void {
    this.customLinks = this.configLoader.headerConfig.links;
  }

  @HostListener('document:keydown.escape')
  public onEscapePress() {
    this.closeSidebar();
  }

  public closeSidebar(): void {
    this.isActive = !this.isActive;
    this.store.dispatch(closeGlobalSidebar());
  }
}
