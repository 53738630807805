import { Record, RecordOf, } from 'immutable';
import { FacetResponseData } from 'search/facets/models/facet-data';
import { FacetSchemaWithDataProps, makeFacetSchemaWithData, } from 'search/facets/models/facet-schema-with-data';
import { CatalogDateFacet, DateFacet, Facet, } from 'search/facets/models/resource-facet';

export interface FacetStateProps<T = FacetSearchData, D = FacetResponseData> {
  selected: T;
  applied: T;
  schemaWithData: FacetSchemaWithDataProps<D>;
  expanded: boolean;
  isLoading: boolean;
}

export const defaultState: FacetStateProps = {
  schemaWithData: makeFacetSchemaWithData(),
  selected: null,
  applied: null,
  expanded: false,
  isLoading: false,
};

export const makeFacetState = <T, D>(values?: Partial<FacetStateProps<T, D>> | any) =>
  Record<FacetState<T, D>>(defaultState as FacetState<T, D>, 'facetState')(values);

export type FacetState<T = FacetSearchData, D = FacetResponseData> = RecordOf<FacetStateProps<T, D>>;

export type FacetSearchData = Facet[] | DateFacet | CatalogDateFacet | Partial<Facet>;

export interface FacetURLFilters {
  [key: string]: string[] | string | boolean | number;
}
