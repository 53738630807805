import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { KeycloakService } from 'app/keycloak/services/keycloak.service';


@Component({
  selector: 'app-over-drive-auth-error-dialog',
  templateUrl: './over-drive-auth-error-dialog.component.html',
  styleUrls: ['./over-drive-auth-error-dialog.component.scss']
})
export class OverDriveAuthErrorDialogComponent {
  public faExclamationCircle = faExclamationCircle;

  constructor(
    public activeModal: NgbActiveModal,
    private keycloakService: KeycloakService
  ) {}

  public signOut() {
    this.keycloakService.logout();
  }
}
