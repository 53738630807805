import { createAction, props } from '@ngrx/store';
import { ListItemEntity } from '../models/list';

export const addToList = createAction(
  '[ForLaterList] Add to list',
  props<{ entity: ListItemEntity }>(),
);

export const removeFromList = createAction(
  '[ForLaterList] Remove from list',
  props<{ entity: ListItemEntity }>(),
);

export const removeSelectedFromList = createAction(
  '[ForLaterList] Remove selected from list',
);

export const removeSelectedFromListComplete = createAction(
  '[ForLaterList] Remove selected from list complete',
);

export const toggleItemSelection = createAction(
  '[ForLaterList] Toggle item selection',
  props<{ itemId: string }>(),
);

export const setAllItemsSelection = createAction(
  '[ForLaterList] Set all items selection',
  props<{ selected: boolean }>(),
);

export const clear = createAction(
  '[ForLaterList] Clear',
);
