import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { Subscription } from 'rxjs';
import {
  AvailabilityStatus,
  AvailabilityUpdateInfo,
  AvailableStatuses,
  GetItRequest,
  HoldRequest,
  MaterialTab,
} from '../../../../models/entity';
import {
  rollupTabCheckLocationAndEditionInformation,
  rollupTabSelected,
  setSelectedResourceId,
  setSelectedTab
} from '../../../../actions/entity.actions';
import { getOverDrive, getSelectedRollupTab } from '../../../../reducers/entity.reducer';
import { DictionariesService } from 'app/services/dictionaries.service';
import { DictionaryItem, DictionaryTypes } from 'app/models/dictionaries';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { VendorIssuesService } from 'app/entity/services/vendor-issues.service';

@Component({
  selector: 'app-rollup-tabs',
  templateUrl: './rollup-tabs.component.html',
  styleUrls: ['./rollup-tabs.component.scss'],
})
export class RollupTabsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() public fgId: string;
  @Input() public fgTitle: string;
  @Input() public materialTabs: MaterialTab[];
  @Input() public availabilityUpdates: AvailabilityUpdateInfo[];
  @Output() public getIt$ = new EventEmitter<{ item: GetItRequest }>(true);
  @Output() public placeHold$ = new EventEmitter<{ item: HoldRequest }>(true);
  @Input() public queryParams = {};
  @Input() public ariaDescribedBy = '';
  @Input() public language: string;

  @ViewChild('nav', {read: DragScrollComponent}) public dragScroll: DragScrollComponent;

  public availabilityStatuses = AvailabilityStatus;
  public availableStatuses = AvailableStatuses;
  public activeId: number;
  private subscriptions = new Subscription();
  private initialOverdriveLoadCompleted = false;
  private activeIdChanged = false;
  private skipFirstAutomaticSelection?: true;
  public formatByCode: DictionaryItem;
  public readonly arrowIconRight = faAngleRight;
  public readonly arrowIconLeft = faAngleLeft;
  public hasEcontentIssues: boolean[];
  public constructor(
    private readonly store: Store,
    public elementRef: ElementRef,
    private readonly dictionariesService: DictionariesService,
    private readonly vendorIssuesService: VendorIssuesService
  ) {
  }

  public moveLeft() {
    this.dragScroll.moveLeft();
  }

  public moveRight() {
    this.dragScroll.moveRight();
  }

  public showArrowButton() {
    const dragScrollContent = this.elementRef.nativeElement.querySelector('.drag-scroll-content');
    return dragScrollContent ? dragScrollContent.scrollWidth > dragScrollContent.clientWidth : false;
  }

  public isAlwaysAvailableType(tab: MaterialTab) {
      this.formatByCode = this.dictionariesService.getDictionaryItemByCode(DictionaryTypes.MATERIAL_TYPES, tab?.materialTypes?.[0]);
      return this.formatByCode?.alwaysAvailable;
  }

  public ngOnInit(): void {
    this.subscriptions.add(this.store.select(getSelectedRollupTab(this.fgId))
      .subscribe((rollupTab) => {
        let selectedIndex = 0;
        let skipFirstAutomaticSelection: true | undefined;
        if (rollupTab) {
          ({selectedIndex = 0, skipFirstAutomaticSelection} = rollupTab);
        }
        this.activeIdChanged = this.activeId !== selectedIndex;
        this.activeId = selectedIndex;
        this.skipFirstAutomaticSelection = skipFirstAutomaticSelection;
      }));

      this.materialTabs.forEach((el: MaterialTab) => {
        this.isAlwaysAvailableType(el);
      });

      this.hasEcontentIssues = this.materialTabs?.map(tab => {
        return this.vendorIssuesService.hasVendorIssuesForTab(tab);
      });
  }

  public checkLocationAndEditionInformation(index: number) {
    this.store.dispatch(rollupTabCheckLocationAndEditionInformation({
      resourceId: this.fgId,
      tabIndex: index,
    }));
    this.store.dispatch(setSelectedResourceId({ resourceId: this.fgId }));
  }

  public ngAfterViewInit() {
    if (this.activeIdChanged) {
      this.dragScroll.moveTo(this.activeId);
      this.activeIdChanged = false;
    }
  }

  public onTabChange(event: NgbNavChangeEvent) {
    const tabInfo = {
      resourceId: this.fgId,
      tab: event.nextId,
    };
    this.store.dispatch(rollupTabSelected(tabInfo));
    this.dragScroll.moveTo(event.nextId);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const availabilityChanges = changes?.availabilityUpdates;
    if (availabilityChanges && this.availabilityUpdates) {
      this.subscriptions.add(this.store.select(getOverDrive).subscribe((overdrive) => {
        if (overdrive[this.fgId] && !this.initialOverdriveLoadCompleted) {
            this.initialOverdriveLoadCompleted = true;
            if (!this.skipFirstAutomaticSelection) {
              this.store.dispatch(setSelectedTab({resourceId: this.fgId}));
            }
          }
      }
      ));
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public trackById(index: number, item: MaterialTab) {
    return item.name;
  }
}
