import { createAction, props } from '@ngrx/store';
import { OverDriveAvailability } from 'core/over-drive/models/over-drive-availability';
import {
  Edition,
  FormatGroup,
  GetItRequest,
  HoldRequest,
  MaterialTypeCardInfo,
  VendorType
} from '../models/entity';
import { EContentVendor } from 'search/models/e-content';
import { Drawer, DrawerRequest, LocationsResponse } from 'search/models/search-models';
import { SearchResult } from 'search/models/search-results';
import { GatesResourceCountResults } from '../models/gates-results';
import { CheckedRollUpTab, RollupCard, RollUpTab } from '../models/rollup-card';
import { Axis360Availability, BorrowboxAvailability, CloudLibraryAvailability } from 'core/econtent/models/econtent-availability';

export const setEntities = createAction(
  '[Entity] Set entities',
  props<{entities: FormatGroup[]}>(),
);

export const resetEntities = createAction(
  '[Entity] Reset entities',
);

export const setSelectedTab = createAction(
  '[Entity::MaterialTab::Selection] Set selected tab after materialTypeGroups was updated load completed',
  props<{ resourceId: string }>(),
);

export const rollupTabSelected = createAction(
  '[Entity::MaterialTab::Selection] Roll-up tab selected',
  props<RollUpTab>(),
);

export const resetRollupTabsSelected = createAction(
  '[Entity::MaterialTab::Selection] Reset roll-up tab selected',
);

export const rollupTabCheckLocationAndEditionInformation = createAction(
  '[Entity::MaterialTab::Selection] Roll-up tab check location and edition information',
  props<CheckedRollUpTab>(),
);

export const rollupTabCollapseLocationAndEditionInformation = createAction(
  '[Entity::MaterialTab::Selection] roll-up tab collapse location and edition information',
  props<CheckedRollUpTab>(),
);

export const gatesLoadResourceCount = createAction(
  '[Entity::MaterialTab::Availability] Gates load resource count information',
  props<{ recordIds: string[] }>(),
);


export const loadVendorAvailability = createAction(
  '[Entity::MaterialTab::Availability] Load availability in process',
  props<{
    vendor: VendorType | string,
    resourceId: string,
    isLoading: boolean
  }>(),
  );

export const gatesLoadResourceCountComplete = createAction(
  '[Entity::MaterialTab::Availability] Gates load resource count information complete',
  props<GatesResourceCountResults>(),
);

export const checkEntityVendorAvailability = createAction(
  '[Entity::MaterialTab::Availability] Check entity vendor availability',
  props<{
    vendor: VendorType | string,
    content: { resourceId: string, reserveId: string[] }
  }>(),
);

export const entityVendorAvailabilityCheckComplete = createAction(
  '[Entity::MaterialTab::Availability] Entity vendor availability check complete',
  props<{
    resourceId: string;
    info: OverDriveAvailability[] | Axis360Availability[] | CloudLibraryAvailability[] | BorrowboxAvailability[];
    vendor?: VendorType
  }>(),
);

export const setScrollPosition = createAction(
  '[Entity::Scroll] Set scroll position',
  props<{ scrollPosition: number }>(),
);

export const setSelectedResourceId = createAction(
  '[Entity::Scroll] Set selected resource id',
  props<{ resourceId: string }>(),
);

export const resetScrollAndSelectedResourceId = createAction(
  '[Entity::Scroll] Reset scroll and selected instance id',
);

export const setFocusedResourceId = createAction(
  '[Entity::Scroll] Set focused resource id',
  props<{ resourceId: string }>(),
);

export const resetFocusedResourceId = createAction(
  '[Entity::Scroll] Reset focused resource id',
);

export const loadLocations = createAction(
  '[Entity::Drawer] Load locations',
  props<{ formatGroupId: string, tabName: string }>(),
);

export const locationsLoadComplete = createAction(
  '[Entity::Drawer] Load locations complete',
  props<{ locations: LocationsResponse }>(),
);

export const drawerInformationLoad = createAction(
  '[Entity::Drawer] Drawer information load',
  props<DrawerRequest>(),
);

export const drawerInformationLoadComplete = createAction(
  '[Entity::Drawer] Drawer information load complete',
  props<Drawer>(),
);

export const queryDrawerContent = createAction(
  '[Entity::Drawer] Drawer information query',
  props<{query: string}>(),
);

export const queryDrawerContentComplete = createAction(
  '[Entity::Drawer] Drawer queried information',
  props<Drawer>(),
);

export const tryToGetIt = createAction(
  '[Entity::GetIt] Try to get it',
  props<{item?: SearchResult | RollupCard | Edition, vendor?: EContentVendor | string, reserveId?: string }>(),
);

export const tryToGetItTab = createAction(
  '[Entity::GetIt] Try to get it Tab',
  props<{cardInfo: MaterialTypeCardInfo }>(),
);

export const tryToPlaceHold = createAction(
  '[Entity::GetIt] Try to get it for rollup card',
  props<{item: Edition, vendor?: EContentVendor | string, reserveId?: string}>(),
);

export const tryToGetItRollup = createAction(
  '[Entity::GetIt] Try to place hold',
  props<{item: GetItRequest}>(),
);

export const tryToPlaceHoldOnRollup = createAction(
  '[Entity::GetIt] Try to place hold on rollup card',
  props<{item: HoldRequest}>(),
);
