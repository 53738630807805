import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionService } from './services/permission.service';
import { JwtDecodeService } from './services/jwt-decode.service';
import { AuthorizationDirective } from './authorization-directive.directive';
import { ShowcasePermissionDirective } from './showcase-permission-directive.directive';


@NgModule({
  declarations: [
    AuthorizationDirective,
    ShowcasePermissionDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AuthorizationDirective,
    ShowcasePermissionDirective
  ],
  providers: [
    PermissionService,
    JwtDecodeService
  ]
})
export class PermissionsModule { }
