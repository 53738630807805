import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ResourceFacet } from 'search/facets/models/resource-facet';
import { FacetLabelService } from 'search/services/facet-label.service';

@Component({
  selector: 'app-search-facets-bubble',
  templateUrl: './search-facets-bubble.component.html',
  styleUrls: ['./search-facets-bubble.component.scss'],
})
export class SearchFacetsBubbleComponent implements OnInit {
  @Input() public facet: ResourceFacet;
  @Input() public isReadonly: boolean;
  @Output() public toggleBubbleLock: EventEmitter<ResourceFacet> = new EventEmitter();
  @Output() public removeFacetBubble: EventEmitter<ResourceFacet> = new EventEmitter();
  public facetLabel: string;

  constructor(private readonly facetLabelService: FacetLabelService) {
  }

  ngOnInit() {
    this.facetLabel = this.facetLabelService.getFacetLabel(this.facet);
  }

  public onToggleBubbleLock() {
    this.toggleBubbleLock.emit(this.facet);
  }

  public onRemoveFacetBubble() {
    this.removeFacetBubble.emit(this.facet);
  }

}
