<div class="display-on-box p-1">
  <div class="header" data-automation-id="custom-showcase-display-on-box-header">
    <fa-icon [icon]="bookIcon" [classes]="['icon']" [fixedWidth]="true"></fa-icon>
    <span class="text">{{ 'title' | translate }}</span>
  </div>
  <div class="body px-1">
    <hr><hr><hr>
  </div>
  <div class="footer" data-automation-id="custom-showcase-display-on-box-footer">
    <fa-icon [icon]="showcaseIcon" [classes]="['icon pr-1']" [fixedWidth]="true"></fa-icon>
    <span class="text">{{ 'customShowcase' | translate }}</span>
  </div>
</div>
