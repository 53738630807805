import { HttpErrorResponse } from '@angular/common/http';

export function toMessageObjectsArray(error: HttpErrorResponse): any {
  let deepestError = error;
  while (typeof deepestError?.error === 'object') {
    deepestError = deepestError.error;
  }
  if (Array.isArray(deepestError)) {
    return deepestError;
  } else {
    return [deepestError];
  }
}
