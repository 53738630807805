import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorCause } from 'core/over-drive/models/over-drive';
import { OverDriveHold } from 'core/over-drive/models/over-drive-holds';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { EContentCheckoutDetails, EContentCheckoutRequestParams, HooplaCheckout, OverdriveCheckout } from 'user/models/checkouts';
import { EContentHold } from 'user/models/holds';
import { Vendor } from 'user/models/vendor';
import { VendorType } from '../../../entity/models/entity';
import { LOCAL_STORAGE } from '../../../models/local-storage';
import { WindowRefService } from '../../../services/window-ref.service';
import { OverDriveAvailability, OverDriveMagazineResponse } from '../models/over-drive-availability';

@Injectable({
  providedIn: 'root',
})
export class OverDriveService {
  private readonly overDriveProductsEndpoint = 'api/search-result/overdrive/products';
  private readonly overDriveCheckoutsEndpoint = 'api/search-result/overdrive/checkouts';
  private readonly overDriveHoldsEndpoint = 'api/search-result/overdrive/holds';
  private readonly overDriveMagazineEndpoint = 'api/overdrive/products/overdrive';

  constructor(
    private readonly http: HttpClient,
    private readonly windowRefService: WindowRefService,
  ) {
  }

  public checkAvailability(resourceIds: string[]): Observable<OverDriveAvailability[]> {
    return this.http.post<OverDriveAvailability[]>(
      `${this.overDriveProductsEndpoint}/availability`,
      resourceIds,
    );
  }

  public getDownloadRedirectLink(reserveId: string): Observable<{body: Blob, location: string}> {
    return this.http.get<any>(`${this.overDriveCheckoutsEndpoint}/overdrive/${reserveId}/formats/download-redirect`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    })
    .pipe(
      map((result) => {
        const locationMatch: RegExpMatchArray | null = result.url.match(/http(s)?:\/\/[^\/]*\//);
        return {body: result.body, location: locationMatch?.[0]};
      }),
    );
  };

  public checkoutOverdriveItem(reserveId: string): Observable<OverdriveCheckout> {
    if (this.isOverdriveAuthError()) {
      return this.throwOverdriveAuthError();
    }
    return this.http.post<OverdriveCheckout>(
      `${this.overDriveCheckoutsEndpoint}/${VendorType.OVERDRIVE}/${reserveId}`,
      {},
    );
  };


  public getIssuesFromOverdriveMagazine(reserveId: string, offset = 0, limit = 25): Observable<OverDriveMagazineResponse> {
    const params = {limit, offset};
    return this.http.get<OverDriveMagazineResponse>(
      `${this.overDriveMagazineEndpoint}/${reserveId}/issues`, { params });
  }

  public checkoutHooplaItem(reserveId: string): Observable<HooplaCheckout> {
    return this.http.post<HooplaCheckout>(
      `${this.overDriveCheckoutsEndpoint}/${VendorType.HOOPLA}`,
      {reserveId},
    );
  }

  public checkoutEContentItem(vendor: VendorType, contentId: string, params?: EContentCheckoutRequestParams): Observable<EContentCheckoutDetails> {
    return this.http.post<EContentCheckoutDetails>(
      `api/search-result/overdrive/${vendor}/checkouts`,
      {contentId, vendor, ...params},
    );
  }

  public placeHoldOverdriveItem(reserveId: string): Observable<OverDriveHold> {
    if (this.isOverdriveAuthError()) {
      return this.throwOverdriveAuthError();
    }
    return this.http.post<OverDriveHold>(
      this.overDriveHoldsEndpoint,
      { reserveId },
    );
  }

  public placeHoldEContentItem(vendor: VendorType, contentId: string): Observable<EContentHold> {
    return this.http.post<EContentHold>(
      `api/search-result/overdrive/${vendor}/holds`,
      {contentId, vendor},
    );
  }

  public checkInTheTitle(reserveId: string, vendorType: VendorType): Observable<void> {
    if (this.isOverdriveAuthError()) {
      return this.throwOverdriveAuthError();
    }
    return this.http.delete<void>(
      `${this.overDriveCheckoutsEndpoint}/${vendorType}/${reserveId}`,
    );
  }

  public checkInEContentItem(vendor: VendorType, checkoutId: string): Observable<void> {
    return this.http.delete<void>(
      `api/search-result/overdrive/${vendor}/checkouts/${checkoutId}`,
    );
  }

  public cancelHoldOverdriveItem(reserveId: string): Observable<void> {
    if (this.isOverdriveAuthError()) {
      return this.throwOverdriveAuthError();
    }
    return this.http.delete<void>(
      `${this.overDriveHoldsEndpoint}/${reserveId}`,
    );
  }

  public cancelHoldEContentItem(vendor: Vendor, holdId: string): Observable<void> {
    return this.http.delete<void>(
      `api/search-result/overdrive/${vendor}/holds/${holdId}`,
    );
  }

  private isOverdriveAuthError(): boolean {
    return !!this.windowRefService.nativeWindow().localStorage.getItem(LOCAL_STORAGE.OVERDRIVE_AUTH_ERROR);
  }

  private throwOverdriveAuthError(): Observable<never> {
    return throwError(new HttpErrorResponse({error: {cause: ErrorCause.PASSWORD_NOT_FOUND}}));
  }
}
