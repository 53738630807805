import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-library-info-schedule',
  templateUrl: './library-info-schedule.component.html',
  styleUrls: ['./library-info-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LibraryInfoScheduleComponent {
  @Input() public data: { title: string, hours: string }[];
}
