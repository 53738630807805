import { Component, Input } from '@angular/core';
import { Tabs } from '../../../../models/tabs';

@Component({
  selector: 'app-no-found-data',
  templateUrl: './no-found-data.component.html',
  styleUrls: ['./no-found-data.component.scss'],
})
export class NoFoundDataComponent {
  @Input() public cardType: string;
  @Input() public tabType: string;
  @Input() public title: string;
  public tabs = Tabs;
}
