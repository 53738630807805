<ng-container>
  <select
    [attr.aria-invalid]="select.invalid && select.touched"
    [class.is-invalid]="select.invalid && select.touched"
    [formControl]="select"
    (change)="setValue($event.target.value)"
    data-automation-id="profile-card-info-value-select-extended"
    class="mb-0 w-100">
    <option
      *ngFor="let value of values; trackBy: trackById"
      [value]="value.id"
      [disabled]="value.disabled"
      data-automation-id="profile-card-info-value-select-option-extended">
      {{ value.value }}
      <ng-container *ngIf="value.description">{{ '(' + value.description + ')'}}</ng-container>
    </option>
  </select>
</ng-container>
