import { Component, ElementRef, EventEmitter, Input, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { BulkHoldSubmitState } from 'user/reducers/bulk-hold.reducer';
import { FormatGroup } from '../../../../entity/models/entity';

@Component({
  selector: 'app-bulk-hold-table',
  templateUrl: './bulk-hold-table.component.html',
  styleUrls: ['./bulk-hold-table.component.scss'],
})
export class BulkHoldTableComponent {
  @Input() formatGroups: FormatGroup[];
  @Input() selectedFormatGroups: FormatGroup[] = [];
  @Input() bulkHoldSubmitState: BulkHoldSubmitState;
  @Input() processingFgId: string;
  @Output() public onRemove = new EventEmitter<FormatGroup>();
  @Output() public formatChange = new EventEmitter<FormatGroup[]>();
  @ViewChildren('tableRow') public tableRows: QueryList<ElementRef>;

  public readonly timesIcon = faTimes;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.processingFgId && this.processingFgId && this.formatGroups.length && this.tableRows) {
      this.scrollTableRowIntoView(this.processingFgId);
    }
  }

  public trackById(index: number, formatGroup: FormatGroup): string {
    return formatGroup.id;
  }

  public removeItem(item: FormatGroup) {
    this.onRemove.emit(item);
  }

  public changeFormat(originalFormatGroup: FormatGroup, selectedValue: string) {
    const selectedFormatGroup = this.selectedFormatGroups.find((formatGroup => formatGroup.id == originalFormatGroup.id));
    if (selectedFormatGroup) {
      const selectedTab = originalFormatGroup.materialTabs.find(tab => tab.name === selectedValue);
      if (selectedTab) {
        selectedFormatGroup.materialTabs = [selectedTab];
      }
    }
    this.formatChange.emit(this.selectedFormatGroups);
  }

  public navigateToFirstNeededAction() {
    this.scrollTableRowIntoView(this.bulkHoldSubmitState.needsActionFgIds[0]);
  }

  public scrollTableRowIntoView(fgId: string) {
    const index = this.formatGroups.findIndex(fg => fg.id === fgId);
    if (index > -1) {
      this.tableRows.toArray()[index].nativeElement.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }
}
