<div class="library-info-container" [focusOnInit]="true">
  <div class="header">
    <span class="title" role="heading" aria-level="1" translate>libraryInfo</span>

    <button class="close-btn"
            aria-label="Close library info"
            data-automation-id="close-library-info-btn"
            cdkMonitorElementFocus
            (click)="close()">
      <span class="icon icon-close"></span>
      <span class="cdk-visually-hidden" translate>closeLibraryInfoBtn</span>
    </button>
  </div>

  <div *ngIf="selectedLocation" class="form-group mx-8 my-3">
    <label class="location-select-label" [for]="selectId" data-automation-id="location-select-label" translate>library</label>
    <select
      class="location-select mb-0 w-100"
      data-automation-id="location-select"
      [id]="selectId"
      [formControl]="selectedLocation"
      (change)="changeLocation($event.target.value)">
      <option *ngFor="let location of locations" [value]="location.code" data-automation-id="location-select-option">
        {{ location.nameOverride || ('location.' + location.code) | translate }}
      </option>
    </select>
  </div>

  <perfect-scrollbar removeTabindexOnYScroll class="scrollbar">
    <app-library-info-content [data]="locationInfoView" [loading]="locationInfoLoading"></app-library-info-content>
  </perfect-scrollbar>
</div>
