<div class="inn-reach-search"
  data-automation-id="inn-reach-search"
  [ngClass]="{'on-full-card': isFullCard}"
  *ngIf="queryParams && innReachConfig?.systemName">

  <span class="title d-none d-lg-block" translate data-automation-id="inn-reach-find-from">findFrom</span>

  <ng-container *ngIf="!isFullCard; else fullCard">
    <div *ngIf="innReachConfig?.imageUrl && innReachConfig.hideSystemName; else multi"
      class="link-container full-image line-clamp line-clamp--two">
      <a [href]="linkUrl"
         [attr.aria-label]="('findFrom' | translate) + ' ' + innReachConfig.systemName"
         class="link full-image"
         target="_blank"
         data-automation-id="inn-reach-full-image-link">
        <img [src]="innReachConfig?.imageUrl" class="image" alt="{{innReachConfig.systemName}}">
        <span class="fa fa-external-link-alt p-2 icon"></span>
      </a>
    </div>
  </ng-container>
</div>

<ng-template #multi>
  <div class="link-container">
    <ng-container *ngIf="innReachConfig?.imageUrl">
      <div class="link-image"
        data-automation-id="inn-reach-logo-link"
        [ngStyle]="{'background-image': 'url(' + innReachConfig?.imageUrl + ')'}">
      </div>
    </ng-container>
    <a [href]="linkUrl"
       [attr.aria-label]="('findFrom' | translate) + ' ' + innReachConfig.systemName"
       [tippy]="innReachConfig.systemName"
       class="link"
       target="_blank"
       data-automation-id="inn-reach-search-link">
      <span class="link-label line-clamp line-clamp--two">
        {{innReachConfig.systemName}}
      </span>
    </a>
    <span class="fa fa-external-link-alt p-1"></span>
  </div>
</ng-template>

<ng-template #fullCard>
  <div class="link-container">
    <a [href]="linkUrl"
       [attr.aria-label]="('findFrom' | translate) + ' ' + innReachConfig.systemName"
       [tippy]="innReachConfig.systemName"
       class="link"
       target="_blank"
       data-automation-id="inn-reach-full-card-link">
      <img *ngIf="innReachConfig?.imageUrl" [src]="innReachConfig?.imageUrl" alt="{{innReachConfig.systemName}}" class="image d-none d-sm-block">

      <ng-container *ngIf="!innReachConfig.hideSystemName; else displaySystemNameOnMobiles">
        <ng-container *ngTemplateOutlet="systemName"></ng-container>
      </ng-container>

      <ng-template #displaySystemNameOnMobiles>
        <div class="d-block d-sm-none">
          <ng-container *ngTemplateOutlet="systemName"></ng-container>
        </div>
      </ng-template>

      <ng-template #systemName>
        <div class="link-label line-clamp line-clamp--two">
          {{innReachConfig.systemName}}
        </div>
      </ng-template>
    </a>
  </div>
</ng-template>
