import {
  AboutShowcase,
  AboutShowcaseAge,
  AboutShowcaseFormat,
  AboutShowcaseType,
  CustomShowcaseDisplayOn,
} from './custom-showcase';

export const metadataDisplayOnTranslations: {[key in keyof CustomShowcaseDisplayOn]: string} = {
  recordPages: 'customShowcaseMetadataTitlePages',
};

export const aboutShowcaseTypeTranslations: {[key in keyof AboutShowcaseType]: string} = {
  fiction: 'customShowcaseMetadataTypeFiction',
  nonfiction: 'customShowcaseMetadataTypeNonfiction',
};

export const aboutShowcaseAgeTranslations: {[key in keyof AboutShowcaseAge]: string} = {
  preschool: 'customShowcaseMetadataAgePreschool',
  primary: 'customShowcaseMetadataAgePrimary',
  preteen: 'customShowcaseMetadataAgePreteen',
  teen: 'customShowcaseMetadataAgeTeen',
  adult: 'customShowcaseMetadataAgeAdult',
  juvenile: 'customShowcaseMetadataAgeJuvenile',
};

export const aboutShowcaseFormatTranslations: {[key in keyof AboutShowcaseFormat]: string} = {
  a: 'customShowcaseMetadataFormatBook',
  l: 'customShowcaseMetadataFormatEbook',
  v: 'customShowcaseMetadataFormatLargePrint',
  g: 'customShowcaseMetadataFormatDvd',
  u: 'customShowcaseMetadataFormatBluRay',
  x: 'customShowcaseMetadataFormatStreamingVideo',
  h: 'customShowcaseMetadataFormatAudioBook',
  y: 'customShowcaseMetadataFormatEAudioBook',
  i: 'customShowcaseMetadataFormatAudioCd',
  '-': 'customShowcaseMetadataFormatOther',
};

export const listMetadataSortOrderTranslations: {[key: string]: string} = {
  'date.desc': 'customShowcaseMetadataNewestFirst',
  'date.asc': 'customShowcaseMetadataOldestFirst',
  'title.asc': 'customShowcaseMetadataTitleAscending',
  'title.desc': 'customShowcaseMetadataTitleDescending',
};

export const savedSearchMetadataSortOrderTranslations: {[key: string]: string} = {
  'relevance:asc': 'sortedByRelevancy',
  'publicationDate:desc': 'sortedByDateNewest',
  'publicationDate:asc': 'sortedByDateOldest',
};

export const aboutShowcaseTranslations: {[key in keyof AboutShowcase]: {[key: string]: string}} = {
  type: aboutShowcaseTypeTranslations,
  age: aboutShowcaseAgeTranslations,
  format: aboutShowcaseFormatTranslations,
};
