<div *ngIf="holdSubmitState" class="hold-state d-flex justify-content-center align-items-center"
     data-automation-id="hold-submit-state-container"
     aria-live="polite" role="status">

  <!--  Waiting -->
  <div *ngIf="holdSubmitState.waiting" class="d-flex flex-column justify-content-center align-items-center">
    <span class="hold-state-icon hold-state-icon__waiting m-2"
          data-automation-id="hold-submit-state-waiting">
      <fa-icon [icon]="faClock" [classes]="['h-100', 'w-100']"></fa-icon>
    </span>
  </div>

  <!--  Loading -->
  <div *ngIf="holdSubmitState.loading" class="d-flex">
    <div class="spinner-border text-primary"
         data-automation-id="hold-submit-state-loading">
    </div>
  </div>

  <!-- Success -->
  <div  *ngIf="holdSubmitState.success" class="d-flex flex-column justify-content-center align-items-center">
    <span class="hold-state-icon hold-state-icon__success m-2" data-automation-id="hold-submit-state-success">
      <fa-icon [icon]="faCircleCheck" [classes]="['h-100', 'w-100']"></fa-icon>
      <span class="sr-only">{{ 'bulkHoldTitleSuccessfullyPlacedOnHold' | translate: {title} }}</span>
    </span>
  </div>

  <!-- Error -->
  <div *ngIf="holdSubmitState.error && !holdSubmitState.error.needsAction" class="d-flex flex-column justify-content-center align-items-center">
    <span class="hold-state-icon hold-state-icon__error m-2" data-automation-id="hold-submit-state-error">
      <fa-icon [icon]="faCircleXmark" [classes]="['h-100', 'w-100']"></fa-icon>
      <span class="sr-only">{{ 'bulkHoldTitleFailedToPlaceOnHold' | translate: {title} }}</span>
    </span>
    <button class="hold-state-button"
            (click)="openErrorDetailsModal()"
            attr.aria-label="{{ 'bulkHoldTitleErrorDetails' | translate: {title} }}"
            aria-haspopup="dialog"
            data-automation-id="hold-submit-state-error-details-button">
      {{ 'bulkHoldErrorDetails' | translate }}
    </button>
  </div>

  <!-- Needs Action -->
  <div *ngIf="holdSubmitState.error?.needsAction" class="d-flex flex-column justify-content-center align-items-center">
    <span class="hold-state-icon  hold-state-icon__needs-action m-2" data-automation-id="hold-submit-state-needs-action">
      <fa-icon [icon]="faCircleExclamation" [classes]="['h-100', 'w-100']"></fa-icon>
      <span class="sr-only">{{ 'bulkHoldTitleNeedsAction' | translate: {title} }}</span>
    </span>
    <button class="hold-state-button"
            aria-haspopup="dialog"
            (click)="handleNeededAction()"
            attr.aria-label="{{ 'bulkHoldPerformNeededActionForTitle' | translate: {title} }}"
            data-automation-id="hold-submit-state-needs-action-button">
      {{ 'bulkHoldNeedsAction' | translate }}
    </button>
  </div>

  <!-- Stopped -->
  <div *ngIf="holdSubmitState.stopped" class="d-flex flex-column justify-content-center align-items-center">
    <span class="hold-state-icon hold-state-icon__stopped m-2" data-automation-id="hold-submit-state-stopped">
      <fa-icon [icon]="faMinusCircle" [classes]="['h-100', 'w-100']"></fa-icon>
      <span class="sr-only">{{ 'bulkHoldTitleStopped' | translate: {title} }}</span>
    </span>
  </div>
</div>
