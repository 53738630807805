import { Injectable, } from '@angular/core';
import { WindowRefService } from './window-ref.service';

export enum SocialMediaType {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter'
}

@Injectable({
  providedIn: 'root'
})
export class ShareOnSocialMediaService {

  private facebookBase = 'https://www.facebook.com/sharer/sharer.php?u=';
  private twitterBase = 'https://twitter.com/intent/tweet?url=';

  constructor(
    private readonly windowRefService: WindowRefService,
  ) {}

  public socialMediaShareLinkHandler(type: SocialMediaType, url: string) {
    const socialMediaBase = type === SocialMediaType.FACEBOOK ? this.facebookBase : this.twitterBase;
    return `${socialMediaBase}${encodeURIComponent(url)}`;
  }

  public shareOnSocialMedia(type: SocialMediaType, url: string) {
    const shareLink = this.socialMediaShareLinkHandler(type, url);
    this.windowRefService.nativeWindow().open(shareLink, '_blank');
  }
}
