import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import {
  ConfirmationModalContentComponent
} from 'common/components/confirmation-modal-content/confirmation-modal-content.component';
import { from, Observable, of, Subscription } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { beginChangeHoldLocation, collapseBookshelf, holdCardActionRequest } from 'user/actions/user-profile.actions';
import { AllowedActions } from 'user/models/allowed-actions';
import { HoldCard, HoldCardActionPayload, HoldCardActionType, HoldStatus } from 'user/models/holds';
import { UserReceiptActionStatus } from 'user/models/user-receipt-card';
import { ECONTENT_VENDORS, Vendor } from 'user/models/vendor';
import { getUserId } from 'user/reducers/user.reducer';
import { tryToGetItRollup } from '../../../../../entity/actions/entity.actions';
import { FormattedVendor } from '../../../../../entity/models/econtent';
import { EntityTypes } from '../../../../../entity/models/entity';

@Component({
  selector: 'app-hold-card',
  templateUrl: './hold-card.component.html',
  styleUrls: ['./hold-card.component.scss'],
})
export class HoldCardComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public card: HoldCard;

  public readonly vendor = Vendor;
  public readonly holdStatus = HoldStatus;
  public readonly actionStatus = UserReceiptActionStatus;
  public readonly allowedActions = AllowedActions;
  public readonly formattedVendor = FormattedVendor;
  public ariaTitleId: string;
  public isReadyForPickUp = false;
  public receiptActionType = HoldCardActionType;
  private userId: string;
  public frcLink: boolean;
  public hasResourceTitle: boolean;
  public editIcon = faEdit;
  private readonly subscriptions = new Subscription();
  public canGetIt = false;
  public canCancel = false;
  public hasActions = false;

  constructor(
    public store: Store,
    private readonly modal: NgbModal,
  ) {
  }

  public ngOnInit() {
    this.isReadyForPickUp = [
      HoldStatus.ItemReadyToPickUp,
      HoldStatus.BibReadyToPickUp,
      HoldStatus.VolumeReadyToPickUp,
    ].includes(this.card.receipt.status);
    this.ariaTitleId = `ariaLabel-${this.card.receipt.id}`;
    this.canGetIt = this.card.receipt.allowedActions?.includes(AllowedActions.GET_IT);
    this.canCancel = this.card.receipt.allowedActions?.includes(AllowedActions.CANCEL);
    this.hasActions = this.card.receipt.allowedActions?.length > 0;

    this.subscriptions.add(
      this.store.select(getUserId).subscribe((userId) => {
        this.userId = userId;
      }),
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public ngOnChanges() {
    this.frcLink = this.card.receipt.resource?.id?.length > 0 && this.card.receipt.resource?.type?.length > 0;
    this.hasResourceTitle = this.card.receipt?.resource?.title?.length > 0;
  }

  public changePickupLocation() {
    this.store.dispatch(beginChangeHoldLocation({hold: this.card.receipt}));
  }

  public onNavigation(): void {
    this.store.dispatch(collapseBookshelf());
  }

  public handleActionTrigger(actionPayload: HoldCardActionPayload): void {
    if (actionPayload.actionType === HoldCardActionType.GetIt) {
      this.getItForRollupCard();
      return;
    }

    const obs = (actionPayload.actionType === HoldCardActionType.CancelHold)
      ? this.openConfirmationDialog({action: actionPayload.actionType})
      : of(true);

    obs.pipe(
      filter(Boolean),
    ).subscribe(() => {
      this.store.dispatch(holdCardActionRequest({
        actionType: actionPayload.actionType,
        holdId: this.card.receipt.id,
        holdRecordId: this.card.receipt.recordId,
        vendor: this.card.receipt.vendor,
        resourceId: this.card.receipt.resource?.id
      }));
    });
  }

  public getItForRollupCard() {
    const instanceId = this.card.receipt.resource?.instanceId || null;

    const payload = {
      item: {
        instanceId,
        id: instanceId,
        entityType: EntityTypes.FORMAT_GROUP,
        formatGroupId: this.card.receipt.resource?.id,
        vendor: this.card.receipt.vendor,
        reserveId: this.card.receipt.recordId,
        readyHold: true,
      },
    };
    this.store.dispatch(tryToGetItRollup(payload));
  }

  private openConfirmationDialog(newContext: { action: HoldCardActionType }): Observable<any> {
    const modalRef = this.modal.open(ConfirmationModalContentComponent, {
      centered: true,
      windowClass : 'inspire-custom-modal',
    });
    modalRef.componentInstance.context = newContext;
    return from(modalRef.result).pipe(catchError((_) => of(null)));
  }

  isEcontentVendor(): boolean {
    return ECONTENT_VENDORS.includes(this.card.receipt.vendor);
  }

  canCancelHold(): boolean {
    return !(this.card.receipt.vendor === Vendor.CLOUD_LIBRARY && this.canGetIt);
  }
}
