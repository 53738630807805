import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { faHouseUser } from '@fortawesome/pro-regular-svg-icons';
import { Observable, Subject } from 'rxjs';
import { FormArrayControlConnector } from 'user/components/bookshelf/helpers/form-array-control-connector';
import { ProfileUpdateServerError } from 'user/models/user';
import { ProfileFormFieldInputAddressComponent } from '../profile-form-field-input-address/profile-form-field-input-address.component';

@Component({
  selector: 'app-profile-form-addresses',
  templateUrl: './profile-form-addresses.component.html',
  styleUrls: ['./profile-form-addresses.component.scss'],
})
export class ProfileFormAddressesComponent extends FormArrayControlConnector {
  @Input() public isEditing: boolean;
  @Input() public touchForm$: Observable<undefined>;
  @Input() public serverError?: ProfileUpdateServerError;
  @Input() public libraryContactLink?: string;
  @ViewChildren(ProfileFormFieldInputAddressComponent) public addressList: QueryList<ProfileFormFieldInputAddressComponent>;
  public onFocus$: Subject<void> = new Subject<void>();
  public readonly addressIcon = faHouseUser;

  constructor(public readonly controlContainer: ControlContainer) {
    super(controlContainer);
  }

  public handleAddAnotherAddress() {
   setTimeout(() => {
      this.onFocus$.next();
    });
  }
}
