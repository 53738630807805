<div class="fine-card card" data-automation-id="fine-card">
  <div class="container-fluid px-3 py-2">
    <div class="row">
      <div class="col-auto d-none d-sm-block">
        <ng-container *ngTemplateOutlet="fineCardCover"></ng-container>
      </div>
      <div class="col pr-1">
        <div class="row">
          <div class="col-auto d-block d-sm-none">
            <ng-container *ngTemplateOutlet="fineCardCover"></ng-container>
          </div>
          <div class="col pl-0">
            <h2 class="fine-card-item-title">
              <a *ngIf="frcLink; else noFrcLink" (click)="onNavigation()"
                 (keydown.enter)="onNavigation()"
                 class="activeLink link-content overflow-hidden"
                 data-automation-id="fine-card-title"
                 [queryParams]="{id: fine.resource.id, entityType: fine.resource.type}"
                 [routerLink]="['/search', 'card']">
                <app-cut-text-with-certain-height
                  [id]="ariaTitleId"
                  [text]="fine.resource.title"
                  [height]="50"
                  [withoutMoreLess]="true"
                  class="fine-card-title"
                  minLengthToCut="60">
                </app-cut-text-with-certain-height>
              </a>
            </h2>
            <div *ngIf="fine.resource" class="fine-card-title-designator" data-automation-id="fine-card-title-designator">
              {{fine.resource.titleDesignator}}
            </div>
            <div *ngIf="fine.resource" class="fine-card-material-type" data-automation-id="fine-card-material-type">
              {{fine.resource.materialType}}
            </div>
          </div>
          <ng-template #noFrcLink>
            <app-cut-text-with-certain-height *ngIf="hasResourceTitle; else noResourceTitle"
                                              [id]="ariaTitleId"
                                              [text]="fine.resource.title"
                                              [height]="50"
                                              [withoutMoreLess]="true"
                                              class="fine-card-title fine-card-title--no-link"
                                              data-automation-id="fine-card-title"
                                              minLengthToCut="60">
            </app-cut-text-with-certain-height>
            <ng-template #noResourceTitle>
              <div class="col pl-0">
                <span [id]="ariaTitleId"
                      class="fine-card-title fine-card-title--no-link"
                      data-automation-id="fine-card-title"
                      translate>
                  fineLibraryCharge
                </span>
              </div>
            </ng-template>
          </ng-template>
        </div>
        <div class="row">
          <div class="col-4 col-sm-2 pl-sm-0">
            <div class="fine-card-info" data-automation-id="fine-card-info-amount">
              <div class="fine-card-info-title" translate>amount</div>
              <div class="fine-card-info-value-bold">{{fine.outstandingAmount | configuredCurrency}}</div>
            </div>
          </div>
          <div class="col-8 col-sm-2 ml-n2">
            <div class="fine-card-info" data-automation-id="fine-card-info-fine-type">
              <div class="fine-card-info-title" translate>fineType</div>
              <div class="fine-card-info-value">{{fine.type}}</div>
            </div>
          </div>
          <div class="col-12 col-sm-3">
            <div class="fine-card-info" data-automation-id="fine-card-info-creation-date">
              <div class="fine-card-info-title" translate>creationDate</div>
              <div class="fine-card-info-value">{{fine.creationDate | date : 'longDate'}}</div>
            </div>
          </div>
          <div class="col-12 col-sm-5">
            <div class="fine-card-info" *ngIf="fine.description" data-automation-id="fine-card-info-fine-description">
              <div class="fine-card-info-title" translate>fineDescription</div>
              <div class="fine-card-info-value">{{fine.description}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-2 mt-n3 mr-n3 pl-0" *ngIf="showCheckbox">
        <div class="fine-card-select mt-7 mb-5 mt-sm-4">
          <ins-bs-checkbox (change)="onSelect.emit()"
                           [checked]="isSelected"
                           [ariaLabelledby]="ariaTitleId"
                           [containerClass]="['mr-2']">
            <ng-container>
              <span class="checkbox-label">{{ 'select' | translate }}</span>
            </ng-container>
          </ins-bs-checkbox>
        </div>
      </div>
    </div>
  </div>

  <ng-template #fineCardCover>
    <div class="fine-card-cover-container">
      <a *ngIf="frcLink; else noLink"
         class="activeLink link-content"
         data-automation-id="fine-card-cover-link"
         (click)="onNavigation()"
         (keydown.enter)="onNavigation()"
         [queryParams]="{id: fine.resource.id, entityType: fine.resource.type}"
         [routerLink]="['/search', 'card']">
        <div class="fine-card-cover">
          <app-cover
            [config]="{coverUrl: fine.resource.coverUrl}"
            [title]="fine.resource.title"
            [materialType]="fine.resource.materialType"
            data-automation-id="search-card-image"
            size="medium">
          </app-cover>
        </div>
      </a>
      <ng-template #noLink>
        <div class="fine-card-cover" *ngIf="ill; else libraryCover">
          <app-cover
            [config]="{}"
            [title]="fine.resource.title"
            [materialType]="fine.resource.materialType"
            data-automation-id="fine-card-cover-ill"
            size="medium">
          </app-cover>
        </div>
        <ng-template #libraryCover>
          <span aria-hidden="true" class="d-flex icon-library-cover" data-automation-id="fine-card-cover-library"></span>
        </ng-template>
      </ng-template>
    </div>
  </ng-template>
</div>
