import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, } from '@angular/core';
import { Subscription } from 'rxjs';

const MAIN_PAGE_REGEXP = '(/search$)|(/search\\?.*query*)|(^\/?(\\?.+|#.+)?$)';

@Component({
  selector: 'app-global-header',
  templateUrl: './global-header.component.html',
  styleUrls: ['./global-header.component.scss'],
})
export class GlobalHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public url: string;
  @Input() public title: string;
  @Input() public logo: string;
  @Output() public skipTo = new EventEmitter();
  @Output() public onResetFacets = new EventEmitter();

  public isMainPage = true;
  public logoSrc: string;
  public isLogoLoaded = true;
  public isLogoError = false;

  private readonly subscriptions: Subscription = new Subscription();

  public ngOnInit() {
    this.logoSrc = this.logo ? `${this.logo}?timestamp=${Date.now()}` : ''; // solution to bypass browser cache
    this.isMainPage = !!this.url.match(MAIN_PAGE_REGEXP);
    this.isLogoLoaded = !this.logoSrc;
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public ngOnChanges() {
    this.isMainPage = !!this.url.match(MAIN_PAGE_REGEXP);
  }

  public resetFacets(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.onResetFacets.emit();
  }

  public skipToElement(event: Event, elementName: string) {
    event.preventDefault();
    this.skipTo.emit(elementName);
  }

  public onImgLoad() {
    this.isLogoLoaded = true;
  }

  public onImgError() {
    this.isLogoError = true;
    this.isLogoLoaded = true;
  }
}
