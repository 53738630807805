export const enum DictionaryTypes {
  MATERIAL_TYPES = 'materialTypes',
  ITEM_STATUSES = 'itemStatuses',
  PHYSICAL_LOCATIONS = 'physicalLocations',
  PICKUP_LOCATIONS = 'pickupLocations',
}

export enum AvailabilityStatuses {
  AVAILABLE = 'Available',
  UNAVAILABLE = 'Unavailable',
}

export interface DictionaryItem {
  code: string;
  description?: string;
  name?: string;
  getItSuppressed?: boolean;
  availabilityStatus?: AvailabilityStatuses;
  alwaysAvailable?: boolean;
  allowRequestsWithNoItems?: boolean;
}
