import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CheckoutCardAction, CheckoutCardActionPayload, CheckoutCardActionType, } from 'user/models/checkouts';
import { Vendor } from 'user/models/vendor';

@Component({
  selector: 'app-checkout-card-action-button-container',
  templateUrl: './checkout-card-action-button-container.component.html',
  styleUrls: ['./checkout-card-action-button-container.component.scss'],
})
export class CheckoutCardActionButtonContainerComponent {
  @Input() public vendor: Vendor;
  @Input() public action: CheckoutCardAction | null = null;
  @Input() public url: string | null;
  @Input() public disabled: boolean;
  @Input() public ariaDescribedBy = '';
  @Output() public triggerAction = new EventEmitter<CheckoutCardActionPayload>();

  public readonly econtentVendors = [Vendor.AXIS360, Vendor.CLOUD_LIBRARY, Vendor.BORROW_BOX];
  public readonly vendorsWithoutCheckIn = [Vendor.AXIS360];

  public readonly actionTypeEnum = CheckoutCardActionType;
  public readonly vendorEnum = Vendor;
}
