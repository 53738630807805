import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconDefinition, faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { randomNumericString } from 'shared/utils/random';
import { ProfileUpdateServerError } from 'user/models/user';

@Component({
  selector: 'app-profile-form-field-label',
  templateUrl: './profile-form-field-label.component.html',
  styleUrls: ['./profile-form-field-label.component.scss'],
})
export class ProfileFormFieldLabelComponent {
  @Input() public icon: IconDefinition;
  @Input() public inputLabel: string;
  @Input() public isHelpDisplayed: boolean;
  @Input() public isRemoveButtonDisplayed = false;
  @Input() public fieldName?: string;
  @Input() public serverError?: ProfileUpdateServerError;
  @Input() public libraryContactLink?: string;
  @Output() public readonly onRemove = new EventEmitter<undefined>();

  public readonly labelId = `profile-input-label-${randomNumericString()}`;
  public readonly errorId = `profile-input-label-error-${randomNumericString()}`;
  public readonly serverErrorEnum = ProfileUpdateServerError;
  public infoIcon = faQuestionCircle;
  isPopoverDisplayed = false;
}
