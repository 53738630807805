<div class="account h-100">
  <div class="nav nav-tabs account-tab-header" ngbNav #nav="ngbNav" [(activeId)]="activeTabId">
    <ng-container [ngbNavItem]="accountTabs.CHECKOUTS">
      <a ngbNavLink class="account-tab-label">
        <div data-automation-id="account-tab-checkouts-title">
          <span translate>accountTabCheckouts</span>
          <span class="account-tab-label-postfix d-none d-sm-inline">
            {{ checkoutsTotal }}
          </span>
        </div>
      </a>
      <ng-template ngbNavContent>
        <app-checkouts></app-checkouts>
      </ng-template>
    </ng-container>

    <ng-container [ngbNavItem]="accountTabs.HOLDS">
      <a ngbNavLink class="account-tab-label">
        <div data-automation-id="account-tab-holds-title">
          <span translate>accountTabHolds</span>
          <span class="account-tab-label-postfix d-none d-sm-inline">
            {{ holdsTotal }}
          </span>
        </div>
      </a>
      <ng-template ngbNavContent>
        <app-holds></app-holds>
      </ng-template>
    </ng-container>

    <ng-container [ngbNavItem]="accountTabs.FINES">
      <a ngbNavLink class="account-tab-label">
        <div data-automation-id="account-tab-fees-and-fines-title">
          <span class="d-none d-sm-inline" translate>accountTabFeesAndFines</span>
          <span class="d-inline d-sm-none" translate>accountTabFines</span>
          <span class="account-tab-label-postfix d-none d-sm-inline">
            {{ moneyOwed | configuredCurrency }}
          </span>
        </div>
      </a>
      <ng-template ngbNavContent>
        <app-fines></app-fines>
      </ng-template>
    </ng-container>

    <ng-container [ngbNavItem]="accountTabs.PROFILE">
      <a ngbNavLink class="account-tab-label">
        <div data-automation-id="account-tab-profile-title">
          <span translate>accountTabProfile</span>
          <span *ngIf="expirationMessage" class="account-tab-label-action-needed p-1 ml-1">
            <span class="fas fa-exclamation exclamation-icon" aria-hidden="true"></span>
            <span class="d-none d-md-inline ml-1" translate>actionNeeded</span>
          </span>
        </div>
      </a>
      <ng-template ngbNavContent>
        <app-profile></app-profile>
      </ng-template>
    </ng-container>
  </div>
  <div class="h-100" [ngbNavOutlet]="nav"></div>
</div>
