import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-bulk-renew-days-choice-modal',
  templateUrl: './bulk-renew-days-choice-modal.component.html',
  styleUrls: ['./bulk-renew-days-choice-modal.component.scss']
})
export class BulkRenewDaysChoiceModalComponent implements OnInit {
  @Input() public title: string;
  public daysInput: UntypedFormControl;
  public maxDays = 14;
  public minDays = 0;
  constructor(
    private readonly activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
    this.daysInput = new UntypedFormControl(0, [Validators.max(this.maxDays), Validators.min(this.minDays)]);
  }

  public onClose(): void {
    this.activeModal.close(+this.daysInput.value);
  }

  public dismissModal(): void {
    this.activeModal.dismiss();
  }
}
