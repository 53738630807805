import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Product } from 'core/over-drive/models/over-drive-availability';

@Component({
  selector: 'app-issue-list',
  templateUrl: './issue-list.component.html',
  styleUrls: ['./issue-list.component.scss']
})
export class IssueListComponent implements OnInit {
  @Input() public issues: Product[];

  public queriedIssues: Product[] = [];

  constructor(
    public activeModal: NgbActiveModal,
  ) { }


  public ngOnInit(): void {
    this.queriedIssues = this.issues;
  }

  public onClose() {
    this.activeModal.dismiss();
  }


  public checkOutIssue(issue: Product) {
    this.activeModal.close(issue);
  }

  public trackById(index: number, issue: Product) {
    return issue.id;
  }
}
