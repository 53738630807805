<div *ngIf="visible"
     data-automation-id="details-section"
     class="tab-container"
     [ngClass]="{'fix-margin': fixMargin}">
  <h3 class="header" translate data-automation-id="details-header">resourceDetails</h3>

  <div class="d-flex flex-column flex-sm-row" *ngIf="details.frequency">
    <div class="label mb-2 mb-sm-6" translate data-automation-id="frequency-label">frequency</div>
    <div class="value" data-automation-id="frequency-value">
      <app-cut-text-with-certain-height
        [text]="details.frequency"
        [height]="boxHeight"
        class="more-less-link-frc"
        [moreLessColor]="moreLessColor"
        minLengthToCut="60">
      </app-cut-text-with-certain-height>
    </div>
  </div>

  <div class="d-flex flex-column flex-sm-row" *ngIf="details.formerTitle">
    <div class="label mb-2 mb-sm-6" translate data-automation-id="formerTitle-label">formerTitle</div>
    <div class="value" data-automation-id="formerTitle-value">
      <app-cut-text-with-certain-height
        [text]="details.formerTitle"
        [height]="boxHeight"
        class="more-less-link-frc"
        [moreLessColor]="moreLessColor"
        minLengthToCut="60">
      </app-cut-text-with-certain-height>
    </div>
  </div>

  <div class="d-flex flex-column flex-sm-row" *ngIf="details.abbreviatedTitle">
    <div class="label mb-2 mb-sm-6" translate
         data-automation-id="abbreviatedTitle-label">abbreviatedTitle</div>
    <div class="value" data-automation-id="abbreviatedTitle-value">
      <app-cut-text-with-certain-height
        [text]="details.abbreviatedTitle"
        [height]="boxHeight"
        class="more-less-link-frc"
        [moreLessColor]="moreLessColor"
        minLengthToCut="60">
      </app-cut-text-with-certain-height>
    </div>
  </div>

  <div class="d-flex flex-column flex-sm-row" *ngIf="details.genre">
    <div class="label mb-2 mb-sm-6" translate data-automation-id="genre-label">genre</div>
    <div class="value" data-automation-id="genre-value">
      <app-cut-text-with-certain-height
        [text]="details.genre"
        [height]="boxHeight"
        class="more-less-link-frc"
        [moreLessColor]="moreLessColor"
        minLengthToCut="60">
      </app-cut-text-with-certain-height>
    </div>
  </div>

  <div class="d-flex flex-column flex-sm-row" *ngIf="details.language">
    <div class="label mb-2 mb-sm-6" translate data-automation-id="language-label">Language</div>
    <div class="value" data-automation-id="language-value">
      {{ language }}
    </div>
  </div>

  <div class="d-flex flex-column flex-sm-row" *ngIf="details.intendedAudience">
    <div class="label mb-2 mb-sm-6" translate
         data-automation-id="intendedAudience-label">intendedAudience</div>
    <div class="value" data-automation-id="intendedAudience-value">
      <app-cut-text-with-certain-height
        [text]="details.intendedAudience"
        [height]="boxHeight"
        class="more-less-link-frc"
        [moreLessColor]="moreLessColor"
        minLengthToCut="60">
      </app-cut-text-with-certain-height>
    </div>
  </div>

  <div class="d-flex flex-column flex-sm-row" *ngIf="details.supplementaryContent">
    <div class="label mb-2 mb-sm-6" translate
         data-automation-id="supplementaryContent-label">supplementInformation</div>
    <div class="value" data-automation-id="supplementaryContent-value">
      <app-cut-text-with-certain-height
        [text]="details.supplementaryContent"
        [height]="boxHeight"
        class="more-less-link-frc"
        [moreLessColor]="moreLessColor"
        minLengthToCut="60">
      </app-cut-text-with-certain-height>
    </div>
  </div>

  <div class="d-flex flex-column flex-sm-row" *ngIf="details.credits">
    <div class="label mb-2 mb-sm-6" translate data-automation-id="credits-label">creditsNote</div>
    <div class="value" data-automation-id="credits-value">
      <app-cut-text-with-certain-height
        [text]="details.credits"
        [height]="boxHeight"
        class="more-less-link-frc"
        [moreLessColor]="moreLessColor"
        minLengthToCut="60">
      </app-cut-text-with-certain-height>
    </div>
  </div>

  <div class="d-flex flex-column flex-sm-row" *ngIf="details.series">
    <div class="label mb-2 mb-sm-6" translate data-automation-id="series-label">series</div>
    <div class="value" data-automation-id="series-value">
      <app-cut-text-with-certain-height
        [text]="details.series"
        [height]="boxHeight"
        class="more-less-link-frc"
        [moreLessColor]="moreLessColor"
        minLengthToCut="60">
      </app-cut-text-with-certain-height>
    </div>
  </div>

  <ng-container *ngFor="let key of identifierKeys">
    <div class="d-flex flex-column flex-sm-row" *ngIf="details.identifiers?.get(key).length">
      <div class="label mb-2 mb-sm-6" translate [translateParams]="{ value: key }"
        [attr.data-automation-id]="key + '-label'">
        identifiersLabel
      </div>
      <ul class="value" [attr.data-automation-id]="key + '-value'">
        <li class="identifier" *ngFor="let value of details.identifiers?.get(key)">{{value}}</li>
      </ul>
    </div>
  </ng-container>

  <div class="d-flex flex-column flex-sm-row" *ngIf="details.awards">
    <div class="label mb-2 mb-sm-6" translate data-automation-id="awards-label">awards</div>
    <div class="value" data-automation-id="awards-value">
      <div class="awards-item" *ngFor="let item of details.awards">
        <app-cut-text-with-certain-height
          [text]="item"
          [height]="boxHeight"
          class="more-less-link-frc"
          [moreLessColor]="moreLessColor"
          minLengthToCut="60">
        </app-cut-text-with-certain-height>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column flex-sm-row" *ngIf="details.tableOfContents">
    <div class="label mb-2 mb-sm-6" translate
         data-automation-id="tableOfContents-label">tableOfContents</div>
    <div class="value" data-automation-id="tableOfContents-value">{{details.tableOfContents}}</div>
  </div>

  <div class="d-flex flex-column flex-sm-row" *ngIf="details.bibId">
    <div class="label mb-2 mb-sm-6" translate
         data-automation-id="bibId-label">bibId</div>
    <div class="value" data-automation-id="bibId-value">{{details.bibId}}</div>
  </div>
</div>
