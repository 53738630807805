import { Action } from '@ngrx/store';
import { VendorType } from '../../../entity/models/entity';
import { EContentGetItPayload, OverDriveRequestFailedPayload, } from '../models/over-drive';
import { OverDriveMagazineResponse, Product } from '../models/over-drive-availability';

export const PREPARE_OVERDRIVE_GET_IT = '[OverDrive] Prepare Overdrive get it';
export const CANCEL_PREPARE_OVERDRIVE_GET_IT = '[OverDrive] Cancel Prepare Overdrive get it';
export const PREPARE_HOOPLA_GET_IT = '[OverDrive] Prepare Hoopla get it';
export const PREPARE_ECONTENT_GET_IT = '[OverDrive] Prepare EContent get it';
export const OVERDRIVE_GET_IT_SUCCESSFUL = '[OverDrive] OverDrive get it successful';
export const HOOPLA_GET_IT_SUCCESSFUL = '[OverDrive] Hoopla get it successful';
export const ECONTENT_GET_IT_SUCCESSFUL = '[OverDrive] EContent get it successful';
export const OVERDRIVE_GET_ISSUES_SUCCESSFUL = '[OverDrive] OverDrive get issues successful';
export const OVERDRIVE_GET_ISSUES_FAILED = '[OverDrive] OverDrive get issues failed';
export const OVERDRIVE_GET_IT_FAILED = '[OverDrive] OverDrive get it failed';
export const PREPARE_OVERDRIVE_HOLD_REQUEST = '[OverDrive] Prepare hold request';
export const PREPARE_ECONTENT_HOLD_REQUEST = '[OverDrive] Prepare EContent hold request';
export const OVERDRIVE_HOLD_REQUEST_SUCCESSFUL = '[OverDrive] Hold request successful';
export const ECONTENT_HOLD_REQUEST_SUCCESSFUL = '[OverDrive] EContent Hold request successful';
export const OVERDRIVE_HOLD_REQUEST_FAILED = '[OverDrive] Hold request failed';
export const FINALIZE_ISSUE = '[OverDrive] Checkout item as issue';
export const OVERDRIVE_CHECKOUT = '[OverDrive] Checkout item successful';
export const OVERDRIVE_CHECKOUT_COMPLETE = '[OverDrive] Checkout item complete';
export type PrepareVendorGetItAction = PrepareOverDriveGetItAction
  | PrepareHooplaGetItAction
  | PrepareEContentGetItAction;

export class PrepareOverDriveGetItAction implements Action {
  public readonly type = PREPARE_OVERDRIVE_GET_IT;

  constructor(public payload: EContentGetItPayload) {}
}

export class CancelPrepareOverDriveGetItAction implements Action {
  public readonly type = CANCEL_PREPARE_OVERDRIVE_GET_IT;
}

export class PrepareHooplaGetItAction implements Action {
  public readonly type = PREPARE_HOOPLA_GET_IT;

  constructor(public payload: EContentGetItPayload) {}
}

export class PrepareEContentGetItAction implements Action {
  public readonly type = PREPARE_ECONTENT_GET_IT;

  constructor(public payload: EContentGetItPayload) {}
}

export class OverDriveGetItSuccessfulAction implements Action {
  public readonly type = OVERDRIVE_GET_IT_SUCCESSFUL;

  constructor(public payload: EContentGetItPayload) {}
}

export class OverDriveCheckoutIssueAction implements Action {
  public readonly type = OVERDRIVE_CHECKOUT;

  constructor(public payload: string) {}
}

export class OverDriveActionComplete implements Action {
  public readonly type = OVERDRIVE_CHECKOUT_COMPLETE;

  constructor() {}
}

export class HooplaGetItSuccessfulAction implements Action {
  public readonly type = HOOPLA_GET_IT_SUCCESSFUL;

  constructor(public payload: EContentGetItPayload) {}
}

export class EContentGetItSuccessfulAction implements Action {
  public readonly type = ECONTENT_GET_IT_SUCCESSFUL;

  constructor(public payload: EContentGetItPayload) {}
}

export class OverDriveGetIssueSuccessfulAction implements Action {
  public readonly type = OVERDRIVE_GET_ISSUES_SUCCESSFUL;

  constructor(public payload: { overdriveData: EContentGetItPayload, issueData: OverDriveMagazineResponse }) {}
}

export class OverDriveGetIssueFailedAction implements Action {
  public readonly type = OVERDRIVE_GET_ISSUES_FAILED;

  constructor() {}
}

export class FinalizeIssueAction implements Action {
  public readonly type = FINALIZE_ISSUE;

  constructor(public payload: {overdriveData: EContentGetItPayload, issueData: Product}) {}
}
export class OverDriveGetItFailedAction implements Action {
  public readonly type = OVERDRIVE_GET_IT_FAILED;

  constructor(public payload: OverDriveRequestFailedPayload) {}
}

export class PrepareOverDriveHoldRequestAction implements Action {
  public readonly type = PREPARE_OVERDRIVE_HOLD_REQUEST;

  constructor(public payload: { itemId: string, reserveId: string }) {}
}

export class PrepareEContentHoldRequestAction implements Action {
  public readonly type = PREPARE_ECONTENT_HOLD_REQUEST;

  constructor(public payload: { contentId: string, vendor: VendorType }) {}
}

export class OverDriveHoldRequestSuccessfulAction implements Action {
  public readonly type = OVERDRIVE_HOLD_REQUEST_SUCCESSFUL;

  constructor(public payload: { itemId: string, reserveId: string }) {}
}

export class EContentHoldRequestSuccessfulAction implements Action {
  public readonly type = ECONTENT_HOLD_REQUEST_SUCCESSFUL;

  constructor() {}
}

export class OverDriveHoldRequestFailedAction implements Action {
  public readonly type = OVERDRIVE_HOLD_REQUEST_FAILED;

  constructor(public payload: OverDriveRequestFailedPayload) {}
}

export type OverDriveActions =
  | PrepareOverDriveGetItAction
  | PrepareHooplaGetItAction
  | OverDriveGetItSuccessfulAction
  | HooplaGetItSuccessfulAction
  | OverDriveGetItFailedAction
  | PrepareOverDriveHoldRequestAction
  | OverDriveHoldRequestSuccessfulAction
  | OverDriveHoldRequestFailedAction;
