import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { Drawer } from 'search/models/search-models';
import { GroupedLocationInfo } from 'search/models/search-results';

@Component({
  selector: 'ins-rollup-side-drawer',
  templateUrl: './rollup-side-drawer.component.html',
  styleUrls: ['./rollup-side-drawer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RollupSideDrawerComponent implements AfterViewInit, OnDestroy {
  @Input() public drawer: Drawer;
  @Input() public title: string;
  @Input() public getItButtonTitle: string;
  @Input() public loading: boolean;
  @Input() public fgId: string;
  @Input() public tabName: string;
  @Input() public location: GroupedLocationInfo;
  @Input() public allLocations: GroupedLocationInfo[];
  @Input() public displayNotes: boolean;
  @Input() public recordId: string;
  @Input() public allItemsRare: boolean;
  @Input() public isNlbCustomer: boolean;
  @Input() public language: string;
  @Output() public getItButtonClick = new EventEmitter<void>();
  @Output() public dismissModal = new EventEmitter<void>();
  @Output() public changeLocation = new EventEmitter<GroupedLocationInfo>();
  @Output() public goToLocations$ = new EventEmitter<void>();
  @Output() public onDrawerSearch$ = new EventEmitter<string>();
  @ViewChild('drawerSearchInput') public drawerSearchInput: ElementRef;
  @ViewChild('firstElement') public firstElement: ElementRef;
  public readonly goToLocationsIcon = faArrowLeft;
  public readonly searchIcon = faSearch;

  ngAfterViewInit(): void {
    if (this.drawerSearchInput?.nativeElement) {
      fromEvent(this.drawerSearchInput.nativeElement,'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => {
            this.onDrawerSearch$.emit(this.drawerSearchInput.nativeElement.value);
          })
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.onDrawerSearch$.emit(null);
  }

  public onDismiss() {
    this.dismissModal.emit();
  }

  public onGetIt() {
    this.getItButtonClick.emit();
  }

  public onChangeLocation(option: string) {
    const groupedLocationInfo = this.allLocations.find((location) => location.label === option);
    this.changeLocation.emit(groupedLocationInfo);
  }

  public focusFirstElement() {
    this.firstElement.nativeElement.focus();
  }
}
