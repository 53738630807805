import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FacetSchema, FacetSettingsForm, makeFacetSchema } from 'search/facets/models/facet-schema';

@Injectable()
export class FacetsSchemaService {
  private readonly bffCustomizationUrl = 'api/search-result/customization';
  private _facetSchemas: FacetSchema[];

  constructor(
    private readonly http: HttpClient,
  ) {
  }

  public getFacetSchema(): Observable<FacetSettingsForm> {
    return this.http.get<FacetSettingsForm>(`${this.bffCustomizationUrl}/configurations/facet-settings`);
  }

  public getInitialFacetTypes() {
    return this.getActualFacetSchemas()
      .map((facet) => facet.get('mapField'))
      .filter((type) => ['agent', 'concept', 'UNKNOWN'].every((item) => type !== item));
  }

  public loadFacetSchemas(): Promise<void> {
    return this.getFacetSchema()
      .pipe(map((response: FacetSettingsForm) => {
        this._facetSchemas = response.facets.map((schema) => makeFacetSchema(schema));
      }))
      .toPromise()
      .catch((err) => {
        console.error(err);
      });
  }

  public getActualFacetSchemas(): FacetSchema[] {
    return this._facetSchemas
      ?.filter((schema) => schema.isShown)
      .sort((a, b) => a.order - b.order);
  }

}
