import { Component, Input, } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { CustomShowcaseCreatedFromType } from '../../models/custom-showcase';
import {
  metadataDisplayOnTranslations,
  listMetadataSortOrderTranslations
} from '../../models/custom-showcase.translations';
import { faSort } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-custom-showcase-form-metadata',
  templateUrl: './custom-showcase-form-metadata.component.html',
  styleUrls: ['./custom-showcase-form-metadata.component.scss'],
})
export class CustomShowcaseFormMetadataComponent {
  @Input() nickname: string;
  @Input() staffName?: string;
  @Input() createdFrom: CustomShowcaseCreatedFromType;
  public readonly createdFromType = CustomShowcaseCreatedFromType;
  public readonly sortOrderTranslations = listMetadataSortOrderTranslations;
  public readonly displayOnTranslations = metadataDisplayOnTranslations;
  public readonly sortIcon = faSort;

  constructor(public readonly controlContainer: ControlContainer) {
  }

  get metadata(): UntypedFormGroup {
    return this.controlContainer.control as UntypedFormGroup;
  }

  get sortOrder(): UntypedFormGroup {
    return this.metadata.controls.sortOrder as UntypedFormGroup;
  }

  get displayOn(): UntypedFormGroup {
    return this.metadata.controls.displayOn as UntypedFormGroup;
  }

  get aboutShowcase(): UntypedFormGroup {
    return this.metadata.controls.aboutShowcase as UntypedFormGroup;
  }

  public originalOrder() {
    return 0;
  }
}
