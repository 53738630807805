<div class="p-4">
  <div class="modal-header">
    <h2 ngbAutofocus class="modal-title" translate>confirmationActionDialogTitle</h2>
    <button data-automation-id="close-get-it-dialog-btn" type="button" class="close-btn p-0"
      [attr.aria-label]="'closeDialog' | translate" (click)="activeModal.dismiss()">
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
  </div>
  <div class="modal-body">
    <section class="status-section">
      <fa-icon class="success-icon" [icon]="faCheckCircle" size="4x"></fa-icon>
      <div class="status-message" *ngIf="data?.message || data?.remainingCheckouts">
        <p *ngIf="data.message" data-automation-id="hoopla-get-it-successful-dialog-message" externalLink [innerHTML]="data.message"></p>
        <p *ngIf="data.remainingCheckouts" data-automation-id="hoopla-get-it-successful-dialog-remaining-checkouts"
              translate [translateParams]="{ checkoutsRemaining: data.remainingCheckouts }">
          hooplaCheckoutRemainingLabel
        </p>
      </div>
    </section>
  </div>
  <div class="modal-footer">
      <button *ngIf="data?.url" class="ins-buttons button-primary flint" (click)="navigateToLink()"
              data-automation-id="hoopla-success-get-item" translate>
        getItem
      </button>
      <button *ngIf="!data?.url" class="ins-buttons button-primary flint" aria-label="OK"
              data-automation-id="hoopla-success-ok" translate (click)="activeModal.close()">
        ok
      </button>
  </div>
</div>
