import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-load-more-btn',
  templateUrl: './load-more-btn.component.html',
  styleUrls: ['./load-more-btn.component.scss'],
})
export class LoadMoreBtnComponent {
  @ViewChild('loadBtn', { static: true }) public loadBtn: ElementRef;
  @Output() public loadMore = new EventEmitter<boolean>();
  public isMouseEvent = false;

  public onClick() {
    this.loadBtn.nativeElement.blur();
    this.loadMore.emit(this.isMouseEvent);
  }

  public toggleMouseEvent(value: boolean): void {
    this.isMouseEvent = value;
  }
}
