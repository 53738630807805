<ng-container [formGroup]="control">
  <ng-container *ngFor="let address of control.controls; index as i; last as isLast">
    <app-profile-form-field (onRemove)="removeControl(i)"
                            [displayRemoveFieldButton]="control.controls.length > 1"
                            [formControlName]="i"
                            [libraryContactLink]="libraryContactLink"
                            [icon]="addressIcon"
                            [isEditing]="isEditing"
                            [serverError]="serverError"
                            inputLabel="{{'profileHomeAddress' | translate}} {{i + 1}}">
      <ng-template inputDisplay>
        <app-profile-form-field-input-address [formControl]="address"
                                              [index]="i"
                                              [onFocus$]="onFocus$"
                                              [touchForm$]="touchForm$"
                                              formGroupLabel="{{'profileHomeAddress' | translate}} {{i + 1}}">
        </app-profile-form-field-input-address>
        <app-add-another-button (onClick)="onAddControl.emit()"  (setFocus)="handleAddAnotherAddress()" *ngIf="isLast">
          {{ 'profileAddAnotherAddress' | translate }}
        </app-add-another-button>
      </ng-template>

      <ng-template valueDisplay>
        <div data-automation-id="profile-card-address">
          <div>{{address.value.line1}}</div>
          <div *ngIf="address.value.line2">{{address.value.line2}}</div>
          <span *ngIf="address.value.city">{{address.value.city}}<span
            *ngIf="address.value.stateCode || address.value.postalCode">, </span></span>
          <span>{{address.value.stateCode}} </span>
          <span>{{address.value.postalCode}}</span>
        </div>
      </ng-template>
    </app-profile-form-field>
  </ng-container>
</ng-container>
