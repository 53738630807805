<div class="search-results search-results-cards" [@flyInItems]="entities?.length">
  <ng-container *ngIf="!isEmpty">
    <ng-container *ngIf="view === viewType.GRID; else listView">
      <app-grid-view-v2 [entities]="entities"></app-grid-view-v2>
    </ng-container>
    <ng-template #listView>
      <div *ngFor="let entity of entities; trackBy: trackById; let index = index">
        <app-rollup-card
          [bulkFeatureKey]="bulkFeatureKey"
          [formatGroup]="entity"
          [attr.id]="'rollup-card-' + index"
          (getIt$)="getItForRollupCard($event)"
          (placeHold$)="placeHoldOnRollupCard($event)">
        </app-rollup-card>
      </div>
    </ng-template>
  </ng-container>
  <div class="bottom-controls">
    <app-paginator
      *ngIf="pagination?.totalPages > 1"
      [currentPage]="pagination.pageNum"
      [totalPagesCount]="pagination.totalPages"
      (onNavigate)="pageSelected.emit($event)">
    </app-paginator>
  </div>
</div>
