import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { error } from 'core/utils/error-decorator';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Drawer, LocationsResponse } from 'search/models/search-models';

@Injectable({ providedIn: 'root' })
export class SearchResultService {
  private static readonly searchResultUrl = 'api/search-result';

  constructor(
    private readonly http: HttpClient,
  ) { }

  @error([])
  public getDrawer(fgId: string, tabName: string, codes: string[]): Observable<Drawer> {
    const url = `${SearchResultService.searchResultUrl}/drawer/${fgId}`;
    const params = new HttpParams()
      .set('tab', tabName)
      .set('locationCodes', codes.join(','));
    return this.http.get<Drawer>(url, { params }).pipe(catchError(() => of(null)));
  }

  @error({available: [], unavailable: []})
  public getLocations(formatGroupId: string, tabName: string): Observable<LocationsResponse> {
    const url = `${SearchResultService.searchResultUrl}/drawer/format-groups/${formatGroupId}/locations`;
    return this.http.get<LocationsResponse>(url, {params: {tab: tabName}});
  }

}
