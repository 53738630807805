import { Component, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnector } from 'user/components/bookshelf/helpers/control-value-accessor-connector';

export interface SelectValue {
  id: string;
  value: string;
  disabled?: boolean;
}

@Component({
  selector: 'app-profile-form-field-input-select',
  templateUrl: './profile-form-field-input-select.component.html',
  styleUrls: ['./profile-form-field-input-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ProfileFormFieldInputSelectComponent,
    multi: true,
  }],
})
export class ProfileFormFieldInputSelectComponent extends ControlValueAccessorConnector {
  @Input() public values: SelectValue[];
  @Input() public inputLabel: string;

  constructor(injector: Injector) {
    super(injector);
  }

  public trackById(index: number, value: SelectValue) {
    return value.id;
  }
}
