import { Injectable } from '@angular/core';
import { ResourceFacet } from 'search/facets/models/resource-facet';
import { TranslateService } from '@ngx-translate/core';
import { ResourceFacetBlock } from 'search/models/filter-panel';
import { DictionaryTypes } from '../../models/dictionaries';
import { DictionariesService } from '../../services/dictionaries.service';

@Injectable({ providedIn: 'root' })
export class FacetLabelService {

  constructor(
    private readonly translate: TranslateService,
    private readonly dictionariesService: DictionariesService,
  ) {
  }

  public getFacetLabel(facet: ResourceFacet): string {
    if (facet.facetKey === ResourceFacetBlock.LOCATION) {
      return this.translate.instant('location.' + facet.id);
    } else if (facet.facetKey === ResourceFacetBlock.FORMATS) {
      const item = this.dictionariesService.getDictionaryItemByCode(DictionaryTypes.MATERIAL_TYPES, facet.id);
      if (item) {
        return this.translate.instant('material-type.' + facet.id);
      }
    }

    return facet.label || facet.id;
  }
}
