import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ResourceInfo } from 'user/models/resource-item-data';

@Component({
  selector: 'app-account-card-cover',
  templateUrl: './account-card-cover.component.html',
  styleUrls: ['./account-card-cover.component.scss']
})
export class AccountCardCoverComponent {
  @Input() public resource: ResourceInfo;
  @Output() public onNavigation = new EventEmitter<undefined>();
}
