import { Component, ContentChild, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { InputDisplayDirective } from 'user/components/bookshelf/directives/input-display.directive';
import { ValueDisplayDirective } from 'user/components/bookshelf/directives/value-display.directive';
import { NoopControlValueAccessor } from 'user/components/bookshelf/helpers/noop-control-value-accessor';
import { ProfileUpdateServerError } from 'user/models/user';

@Component({
  selector: 'app-profile-form-field',
  templateUrl: './profile-form-field.component.html',
  styleUrls: ['./profile-form-field.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ProfileFormFieldComponent,
    multi: true,
  }],
})
export class ProfileFormFieldComponent extends NoopControlValueAccessor implements OnChanges {
  @Input() public formControl: UntypedFormControl;
  @Input() public formControlName: string | number;
  @Input() public formArrayName: string;
  @Input() public icon: IconDefinition;
  @Input() public inputLabel: string;
  @Input() public fieldName?: string;
  @Input() public serverError?: ProfileUpdateServerError;
  @Input() public libraryContactLink?: string;
  @Input() public isEditing = false;
  @Input() public hideHelp = false;
  @Input() public displayRemoveFieldButton = false;
  @Input() autocompleteValue: string;
  @Output() public readonly onRemove = new EventEmitter<undefined>();

  @ContentChild(InputDisplayDirective) public inputDisplayDirective!: InputDisplayDirective;
  @ContentChild(ValueDisplayDirective) public valueDisplayDirective!: ValueDisplayDirective;

  public isArrayControl = false;

  constructor(public readonly controlContainer: ControlContainer) {
    super();
  }

  get control() {
    if (this.isArrayControl) {
      return this.controlContainer.control;
    }

    return this.formControl || this.controlContainer.control.get(this.formControlName.toString());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.formArrayName) {
      this.isArrayControl = !!changes.formArrayName.currentValue;
    }
  }
}
