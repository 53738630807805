import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { faBookmark } from '@fortawesome/pro-regular-svg-icons';
import { faConveyorBeltAlt, faHistory } from '@fortawesome/pro-solid-svg-icons';
import { NgbNavLink } from '@ng-bootstrap/ng-bootstrap';
import { select, Store, } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SitePermissions } from 'app/permissions/models/permissions';
import { startReadingHistorySynchronization } from 'app/reading-history/actions/reading-history.actions';
import { ReadingHistoryState } from 'app/reading-history/reducers/reading-history.reducer';
import { WindowRefService } from 'app/services/window-ref.service';
import { combineLatest, Subscription } from 'rxjs';
import {
  bookmarksTabOpened,
  showcasesTabOpened,
  stillActive,
  stopCollapseBookshelf,
  stopToggleBookmarks
} from 'user/actions/user-profile.actions';
import { MaxHeightPerfectScrollbar } from 'user/models/bookshelf';
import { UserPermission } from 'user/models/user';
import {
  doesUserHavePermission,
  getDoOpenBookmarksTab,
  getDoOpenShowcasesTab,
  getEnableReadingHistorySettingStatus,
  getIsCollapseBookshelfPending,
  getIsToggleBookmarksPending,
  getOpenBookshelfAccountTabPending,
  getUserNickname,
  UserState
} from 'user/reducers/user.reducer';
import { CustomShowcaseCreatedFromType } from '../../../custom-showcase/models/custom-showcase';
import { CustomShowcaseFormData, getFormData } from '../../../custom-showcase/reducers/custom-showcase.reducer';
import { CustomerFeature } from '../../../customer-integration/customer-integration';
import { CustomerFeatureToggleService } from '../../../customer-integration/services/customer-feature-toggle.service';
import { isAuthorizedInKeycloak, isStaffAuthorizedInKeycloak } from '../../../keycloak/reducers/keycloak.reducer';
import { Breakpoints, Viewport } from '../../../models/viewport';
import { getRunState } from '../../../saved-search/reducers/saved-search.reducer';
import { FeatureToggleService } from '../../../services/feature-toggle.service';
import { I18NService } from '../../../services/i18-n.service';
import { ViewportService } from '../../../services/viewport.service';

export enum BookshelfTabs {
  ACCOUNT = 'account',
  BOOKMARKS = 'bookmarks',
  SAVED_SEARCHES = 'savedSearches',
  SHOWCASES = 'showcases',
  READING_HISTORY = 'reading_history',
}

@Component({
  selector: 'app-bookshelf',
  templateUrl: './bookshelf.component.html',
  styleUrls: ['./bookshelf.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookshelfComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public isDisplayed: boolean;
  @Output() public isDisplayedChange = new EventEmitter<boolean>();
  @ViewChild('actions') public actions: ElementRef;
  @ViewChild('bookmarksTabLink') public bookmarksTabLink: ElementRef;
  @ViewChildren(NgbNavLink) private accessLevelSelects: QueryList<NgbNavLink>;
  public expanded = false;
  public manualHeight: string;
  public heightToHeader: string;
  public readonly maxHeightPerfectScrollbar = MaxHeightPerfectScrollbar.BASE;
  public pressed = false;
  public tabsConfig: Map<BookshelfTabs, boolean>;
  public activeTabId: BookshelfTabs | null = BookshelfTabs.BOOKMARKS;
  public bookshelfTitle: string;
  public isXsWidth: boolean;
  public showcaseIcon = faConveyorBeltAlt;
  public readingHistoryIcon = faHistory;
  public formData: CustomShowcaseFormData;
  public bookmarkIcon = faBookmark;
  public sitePermissions = SitePermissions;
  public isAuthPatronFlagEnabled: boolean;
  public readonly bookshelfTabs = BookshelfTabs;
  public readonly showcaseType = CustomShowcaseCreatedFromType;
  public bookmarksV2Preview: boolean;

  private readonly subscription: Subscription = new Subscription();

  constructor(
    private readonly windowRef: WindowRefService,
    private readonly store: Store<ReadingHistoryState|UserState>,
    private readonly cdRef: ChangeDetectorRef,
    private readonly translateService: TranslateService,
    private readonly i18NService: I18NService,
    private readonly featureToggleService: FeatureToggleService,
    private readonly viewportService: ViewportService,
    private readonly customerFeatureService: CustomerFeatureToggleService
  ) { }

  public ngOnInit() {
    this.isAuthPatronFlagEnabled = this.featureToggleService.getToggles()['DIS-30793_2024-04-27_auth_patron'];
    this.bookmarksV2Preview = this.featureToggleService.getToggles()['VE-5395_2024-12-31_showcase_for_later'];

    this.subscription.add(
      combineLatest([
        this.store.pipe(select(isAuthorizedInKeycloak)),
        this.store.pipe(select(isStaffAuthorizedInKeycloak)),
        this.store.pipe(select(doesUserHavePermission(UserPermission.SHOWCASES_VIEW))),
        this.store.pipe(select(getEnableReadingHistorySettingStatus)),
      ])
      .subscribe(([isPatronAuthorized, isStaffAuthorized, hasViewShowcasesPermission, enableReadingHistorySettingStatus]) => {
        this.setTabsEnabled(isPatronAuthorized, isStaffAuthorized, hasViewShowcasesPermission, enableReadingHistorySettingStatus);
        this.activeTabId = this.findFirstTabEnabled();
        this.isDisplayed = (this.activeTabId !== null);
        this.isDisplayedChange.emit(this.isDisplayed);
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(this.store.pipe(select(getRunState))
      .subscribe((runState) => {
        if (runState?.loaded) {
          this.expanded = false;
          this.cdRef.markForCheck();
        }
      }),
    );

    this.subscription.add(this.store.pipe(select(getIsToggleBookmarksPending))
      .subscribe((isToggleBookmarksPending) => {
        if (isToggleBookmarksPending) {
          this.store.dispatch(stopToggleBookmarks());
          this.expanded = !this.expanded;
          this.activeTabId = BookshelfTabs.BOOKMARKS;
          this.bookmarksTabLink?.nativeElement.focus();
          this.cdRef.markForCheck();
        }
      }),
    );

    this.subscription.add(this.store.pipe(select(getIsCollapseBookshelfPending))
      .subscribe((isCollapsePending) => {
        if (isCollapsePending) {
          this.store.dispatch(stopCollapseBookshelf());
          this.expanded = false;
          this.cdRef.markForCheck();
        }
      }),
    );

    this.subscription.add(
      this.store.pipe(select(getOpenBookshelfAccountTabPending))
      .subscribe((isOpenBookshelfAccountTabPending) => {
        if (isOpenBookshelfAccountTabPending !== null) {
          this.expanded = true;
          this.activeTabId = BookshelfTabs.ACCOUNT;
          this.cdRef.markForCheck();
        }
      }),
    );

    this.subscription.add(this.store.pipe(select(getUserNickname))
      .subscribe((name) => this.setBookshelfTitle(name)),
    );

    this.subscription.add(
      this.store.pipe(select(getDoOpenShowcasesTab))
      .subscribe((doOpenShowcaseTab) => {
        if (doOpenShowcaseTab) {
          this.store.dispatch(showcasesTabOpened());
          this.expanded = true;
          this.activeTabId = BookshelfTabs.SHOWCASES;
          this.cdRef.markForCheck();
        }
      }),
    );

    this.subscription.add(
      this.store.pipe(select(getDoOpenBookmarksTab))
      .subscribe((doOpenShowcaseTab) => {
        if (doOpenShowcaseTab) {
          this.store.dispatch(bookmarksTabOpened());
          this.expanded = true;
          this.activeTabId = BookshelfTabs.BOOKMARKS;
          this.cdRef.markForCheck();
        }
      }),
    );

    this.subscription.add(this.store.pipe(select(getFormData))
      .subscribe((formData) => {
        this.formData = formData;
        this.cdRef.markForCheck();
      }),
    );
  }

  public ngAfterViewInit() {
    this.subscription.add(this.viewportService.getViewport()
      .subscribe((viewport) => this.setBookshelfExpandedHeight(viewport)),
    );
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public handleExpand() {
    this.expanded = !this.expanded;
    this.store.dispatch(stillActive());
  }

  public expand() {
    if (!this.expanded) {
      this.expanded = true;
    }
  }

  public setValueAndExpand(tabId: BookshelfTabs) {
    this.activeTabId = tabId;
    this.expand();
    if (tabId === BookshelfTabs.READING_HISTORY) {
      this.store.dispatch(startReadingHistorySynchronization());
    }
  }

  public handleDown(event: any) {
    this.pressed = true;
    event.preventDefault();
  }

  @HostBinding('class.h-100') get isFullHeight() {
    return this.expanded && this.isXsWidth;
  }

  @HostListener('document:mouseup')
  public handleUp() {
    this.pressed = false;
  }

  @HostListener('document:mousemove', ['$event'])
  public handleMove(event: any) {
    if (event.which !== 1) {
      this.pressed = false;
    }
    if (this.pressed) {
      this.manualHeight = (this.windowRef.nativeWindow().innerHeight - event.clientY) + 'px';
      this.cdRef.detectChanges();
    }
  }

  private setBookshelfExpandedHeight(viewport: Viewport) {
    const header = this.windowRef.nativeWindow().document.getElementById('header');
    const searchBarHeight = 112;
    const headerHeight = header?.getBoundingClientRect().height ?? 0;
    const headerAndSearchBarHeight = headerHeight + searchBarHeight;

    this.isXsWidth = viewport.width <= Breakpoints.RESOLUTION_MAX_XS;
    this.heightToHeader = this.isXsWidth ? '100%' : `calc(100vh - ${headerAndSearchBarHeight}px)`;
    this.manualHeight = this.heightToHeader;
  }

  private setBookshelfTitle(nickname: string) {
    const nick = nickname ? nickname.slice(0, 20) : null;
    let possessive;
    if (nick) {
      possessive = this.i18NService.isEnglishLanguage() ? `${nick}'s` : nick;
    } else {
      possessive = this.translateService.instant('my');
    }
    this.bookshelfTitle = `${possessive} ${this.translateService.instant('bookshelf')}`;
    this.cdRef.markForCheck();
  }

  private setTabsEnabled(isPatronAuthorized: boolean, isStaffAuthorized: boolean,
                         hasViewShowcasesPermission: boolean, enableReadingHistorySettingStatus: boolean): void {
    const toggles = this.featureToggleService.getToggles();
    const isAuthorized = isPatronAuthorized || isStaffAuthorized;
    const bookmarksAvailableForCustomer = this.customerFeatureService.isNlbCustomer()
      ? isStaffAuthorized && hasViewShowcasesPermission
      : this.isFeatureAvailable(CustomerFeature.BookshelfBookmarksTab);

    const accountEnabled = isPatronAuthorized && this.isFeatureAvailable(CustomerFeature.BookshelfAccountTab);
    const bookmarksEnabled = toggles.licensePersonalLists && bookmarksAvailableForCustomer;
    const savedSearchesEnabled = toggles.licenseSavedSearches && isAuthorized
      && this.isFeatureAvailable(CustomerFeature.BookshelfSavedSearchesTab);
    const showcasesEnabled = toggles.licenseCustomShowcases && isAuthorized && this.hasShowcasePermission(hasViewShowcasesPermission)
      && this.isFeatureAvailable(CustomerFeature.BookshelfShowcasesTab);
    const readingHistoryEnabled = toggles.licenseReadingHistory && isPatronAuthorized && enableReadingHistorySettingStatus
      && this.isFeatureAvailable(CustomerFeature.BookshelfReadingHistoryTab);

    this.tabsConfig = new Map([
      [BookshelfTabs.ACCOUNT, accountEnabled],
      [BookshelfTabs.BOOKMARKS, bookmarksEnabled],
      [BookshelfTabs.SAVED_SEARCHES, savedSearchesEnabled],
      [BookshelfTabs.SHOWCASES, showcasesEnabled],
      [BookshelfTabs.READING_HISTORY, readingHistoryEnabled],
    ]);
  }

  private findFirstTabEnabled(): BookshelfTabs | null {
    const firstTabEnabled = [...this.tabsConfig.entries()].find(([, enabled]) => enabled);
    return firstTabEnabled ? firstTabEnabled[0] : null;
  }

  private isFeatureAvailable(customerFeature: CustomerFeature): boolean {
    return this.customerFeatureService.isFeatureAvailable(customerFeature);
  }

  private hasShowcasePermission(hasViewShowcasesPermission: boolean): boolean {
    // TODO: all visibility checks should be accounted in tabsConfig map (DIS-32927, DIS-32928)
    // Returning true when auth patron feature flag is enabled, assuming the check in auth directive.
    return this.isAuthPatronFlagEnabled ? true : hasViewShowcasesPermission;
  }
}
