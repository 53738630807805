import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { openIneligibleForBulkHoldModal } from 'user/actions/bulk-hold.actions';
import { BulkHoldState } from 'user/reducers/bulk-hold.reducer';
import { FormatGroup } from '../../../../entity/models/entity';

@Component({
  selector: 'app-ineligible-for-bulk-hold-banner',
  templateUrl: './ineligible-for-bulk-hold-banner.component.html',
  styleUrls: ['./ineligible-for-bulk-hold-banner.component.scss']
})
export class IneligibleForBulkHoldBannerComponent {
  @Input() ineligibleFormatGroups: FormatGroup[];

  constructor(
    private readonly store: Store<BulkHoldState>,
  ) {
  }

  public onSeeTitlesClick() {
    this.store.dispatch(openIneligibleForBulkHoldModal({ineligibleFormatGroups: this.ineligibleFormatGroups}));
  }
}
