import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import { resetPatronPasscodeState, updatePatronPasscode } from 'user/actions/user-profile.actions';
import { getPasscodeUpdateStatus } from 'user/reducers/user.reducer';
import { matchValidator } from 'shared/utils/validators';

@Component({
  selector: 'app-change-passcode-dialog',
  templateUrl: './change-passcode-dialog.component.html',
  styleUrls: ['./change-passcode-dialog.component.scss'],
})
export class ChangePasscodeDialogComponent implements OnInit, OnDestroy {
  @Input() public passcodePolicyMessage: string;
  public changePasscodeForm: UntypedFormGroup;
  public serverError: string;
  public passcodeUpdateLoading: boolean;

  private readonly subscriptions = new Subscription();

  constructor(
    private activeModal: NgbActiveModal,
    private store: Store
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(resetPatronPasscodeState());
    this.createForm();

    this.subscriptions.add(
      this.store.select(getPasscodeUpdateStatus)
      .subscribe((status) => {
        this.serverError = status.error;
        this.passcodeUpdateLoading = status.loading;

        if (status.success) {
          this.activeModal.close();
        }
      }),
    );

    this.subscriptions.add(
      this.changePasscodeForm?.valueChanges.subscribe(() => {
        this.store.dispatch(resetPatronPasscodeState());
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onClose(): void {
    this.activeModal.dismiss();
  }

  public onSubmit(): void {
    this.changePasscodeForm.markAllAsTouched();
    if (this.changePasscodeForm.invalid || this.passcodeUpdateLoading) return;

    const formValue = {...this.changePasscodeForm.value};
    delete formValue.confirmNewPasscode;
    this.store.dispatch(updatePatronPasscode({patronPasscodePayload: formValue}));
  }

  /*
   * Getters
   */
  public get currentPasscode(): AbstractControl {
    return this.changePasscodeForm.get('currentPasscode');
  }

  public get newPasscode(): AbstractControl {
    return this.changePasscodeForm.get('newPasscode');
  }

  public get confirmNewPasscode(): AbstractControl {
    return this.changePasscodeForm.get('confirmNewPasscode');
  }

  /*
   * Private methods
   */
  private createForm(): void {
    this.changePasscodeForm = new UntypedFormGroup({
      currentPasscode: new UntypedFormControl(null, [Validators.required]),
      newPasscode: new UntypedFormControl(null, [Validators.required]),
      confirmNewPasscode: new UntypedFormControl(null, [Validators.required]),
    }, [matchValidator('newPasscode', 'confirmNewPasscode')]);
  }
}
