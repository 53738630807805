<span class="contact-your-library" data-automation-id="contact-your-library">
  <a class="link"
     *ngIf="libraryContactLink"
     href="{{libraryContactLink}}"
     target="_blank"
     data-automation-id="contact-your-library-link">
    <ng-container *ngTemplateOutlet="contactYourLibraryText"></ng-container>
  </a>

  <a class="link"
     href=""
     *ngIf="!libraryContactLink && libraryInfoWidgetEnabled"
     (click)="openLibraryInfo($event)"
     data-automation-id="contact-your-library-info-popup-link">
    <ng-container *ngTemplateOutlet="contactYourLibraryText"></ng-container>
  </a>

  <span *ngIf="!libraryContactLink && !libraryInfoWidgetEnabled">
    <ng-container *ngTemplateOutlet="contactYourLibraryText"></ng-container>
  </span>

  <ng-template #contactYourLibraryText>
    <ng-content></ng-content>
  </ng-template>
</span>
