import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'common/shared.module';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { CustomerIntegrationModule } from '../customer-integration/customer-integration.module';
import { BulkAddToListComponent } from './components/bulk-add-to-list/bulk-add-to-list.component';
import { BulkSelectCheckboxComponent } from './components/bulk-select-checkbox/bulk-select-checkbox.component';
import { BulkSelectPanelComponent } from './components/bulk-select-panel/bulk-select-panel.component';
import * as bulkSelect from './reducers/bulk-select.reducer';

const COMPONENTS = [
  BulkSelectCheckboxComponent,
  BulkSelectPanelComponent,
  BulkAddToListComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature(bulkSelect.featureKey, bulkSelect.reducer),
    FeatureToggleModule,
    CustomerIntegrationModule,
  ],
  exports: [
    ...COMPONENTS,
  ],
})
export class BulkSelectModule { }
