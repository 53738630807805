export interface UserReceiptCard<R, A> {
  receipt: R;
  action: A | null;
}

export interface UserReceiptAction<T> {
  type: T;
  status: UserReceiptActionStatus | null;
  error: string | null;
}

export enum UserReceiptActionStatus {
  Pending = 'Pending',
  Success = 'Success',
  Failed = 'Failed',
}

export interface SortField<F = string> {
  field: F;
  translationKey: string;
}
