import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LibraryList } from '../../library-list/reducers/library-list.reducer';
import { ListCreate, ListItemEntity, ListUpdate } from '../models/list';
import {
  ListItemDto,
  ListItemsDto,
  ListPaginationParams,
  ListPatchItemsRequest,
  ListPatchItemsResponse,
  ListSortByString
} from '../models/list.dto';

export type ListItemsDtoWithoutSortBy = Omit<ListItemsDto, 'sortedBy'>;

@Injectable()
export class ListService {
  private static readonly listUrl = 'api/search-result/personalization/lists';

  constructor(private readonly http: HttpClient) {
  }

  public createList(name: string, items: ListItemDto[] = []): Observable<ListCreate> {
    return this.http
    .post<{ id: string }>(ListService.listUrl, {name, items}, {headers: { 'api-version': '2' }})
    .pipe(map(({id}) => ({id})));
  }

  public updateList(request: ListUpdate): Observable<ListUpdate> {
    return this.http
    .patch<void>(`${ListService.listUrl}/${request.id}/rename`, request, {headers: { 'api-version': '2' }})
    .pipe(map(() => request));
  }

  public getItems(
    id: string,
    sortBy: ListSortByString,
    paginationParams: ListPaginationParams,
    filterHiddenShowcaseItems = false,
  ): Observable<ListItemsDtoWithoutSortBy> {
    return this.http.get<ListItemsDtoWithoutSortBy>(`${ListService.listUrl}/${id}/items`, {
      params: {
        sortBy,
        pageNum: paginationParams.pageNum.toString(),
        pageSize: paginationParams.pageSize.toString(),
        filterHiddenShowcaseItems,
      },
      headers: { 'api-version': '2' }
    });
  }

  public patchItems(body: ListPatchItemsRequest): Observable<ListPatchItemsResponse> {
    if (!body.addTo.length && !body.deleteFrom.length) {
      return of({addedTo: [], deletedFrom: [], notFound: []});
    }
    return this.http.patch<ListPatchItemsResponse>(`${ListService.listUrl}/items`, body, {
      headers: { 'api-version': '2' }
    });
  }

  public makePatchItem(entity: ListItemEntity): ListItemDto {
    return {
      id: entity.id,
      entityType: entity.entityType,
      title: entity.title,
      recordId: entity.selectedTabRecordId,
    };
  }

  public deleteList(id: string): Observable<string> {
    return this.http
    .delete<void>(`${ListService.listUrl}/${id}`, {headers: { 'api-version': '2' }})
    .pipe(map(() => id));
  }

  public getList(id: string): Observable<LibraryList> {
    return this.http.get<LibraryList>(`${ListService.listUrl}/${id}`, {headers: {'api-version': '2'}});
  }
}
