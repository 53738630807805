import { createAction, props } from '@ngrx/store';
import { ListItem } from '../../list/models/list';
import {
  ReadingHistoryPagination,
  ReadingHistoryPaginationParams,
  ReadingHistorySort,
  ReadingHistorySortByString,
  ReadingHistoryStatus,
} from '../models/reading-history';

export const getReadingHistoryStatus = createAction(
  '[ReadingHistory] Get Reading History status',
);

export const getReadingHistoryStatusSuccess = createAction(
  '[ReadingHistory] Get Reading History status success',
  props<{ readingHistoryStatus: ReadingHistoryStatus }>(),
);

export const getReadingHistoryStatusFailure = createAction(
  '[ReadingHistory] Failed to Get Reading History status',
  props<{ error: string }>(),
);

export const notifyReadingHistorySettingLoaded = createAction(
  '[ReadingHistory] Notify Reading History setting loaded',
  props<{ setting: boolean}>(),
);

export const updateReadingHistoryOptIn = createAction(
  '[ReadingHistory] Update Reading History Opt In',
);

export const updateReadingHistoryOptOut = createAction(
  '[ReadingHistory] Update Reading History Opt Out',
);

export const updateReadingHistoryOptionFailure = createAction (
  '[ReadingHistory] Failed to Update Reading History option',
  props<{ option: ReadingHistoryStatus }>(),
);

export const updateReadingHistoryOptionSuccess = createAction(
  '[ReadingHistory] Update Reading History option Success',
  props<{ option: ReadingHistoryStatus }>(),
);

export const startReadingHistorySynchronization = createAction(
  '[ReadingHistory] Start Reading History Synchronization',
);

export const loadReadingHistoryItems = createAction(
  '[ReadingHistory] Load reading history items',
  props<{ paginationParams?: ReadingHistoryPaginationParams, searchText?: string, sortBy?: ReadingHistorySortByString, clearBeforeAdd?: boolean }>(),
);

export const loadMoreReadingHistoryItems = createAction(
  '[ReadingHistory] Load more reading history items',
);

export const loadReadingHistoryItemsSuccess = createAction(
  '[ReadingHistory] Load reading history items success',
  props<{ readingHistoryItems: ListItem[], pagination: ReadingHistoryPagination, sortedBy: ReadingHistorySortByString, searchText: string }>(),
);

export const loadReadingHistoryItemsFailure = createAction(
  '[ReadingHistory] Failed to load reading history items',
  props<{ errorStatus: number }>(),
);

export const clearReadingHistoryItems = createAction(
  '[ReadingHistory] Clear reading history items',
);
