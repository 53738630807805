import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction, RouterNavigationPayload, } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import { concat, EMPTY, merge, of } from 'rxjs';
import { catchError, filter, map, pairwise, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { FullEntityService } from '../services/full-entity.service';
import {
  loadEntity,
  loadEntityComplete,
  loadFormatGroup,
  loadFormatGroupAboutInfo,
  loadFormatGroupAboutInfoComplete,
  loadFormatGroupComplete,
  loadRelatedItems,
  loadRelatedItemsComplete,
  loadRelatedItemsFailure,
  loadRelatedItemsOfEditionComplete,
  loadRelatedItemsOfEditionFailure,
  loadRelatedItemsOfMaterialTabEditions,
  updateEditionAvailabilityState,
} from '../actions/full-entity.actions';
import { SearchResult } from 'search/models/search-results';
import { State } from 'search/reducers/root.reducer';
import { SearchService } from 'search/services/search.service';
import { getUserId } from 'user/reducers/user.reducer';
import { isAuthorizedInKeycloak } from '../../keycloak/reducers/keycloak.reducer';
import { notifyNewEntitiesOnPage } from '../../list/actions/list.actions';
import { Entity, EntityTypes, FormatGroup } from '../models/entity';
import { selectEntity } from '../reducers/full-entity.reducer';

@Injectable()
export class FullEntityEffects {
  public cardNavigation$ = createEffect(() => this.actions$.pipe(
    ofType(ROUTER_NAVIGATION),
    map((action: RouterNavigationAction) => action.payload),
    startWith<RouterNavigationPayload | null>(null),
    pairwise(),
    map(([oldEvent, newEvent]) => ({
      hasSameId: oldEvent && oldEvent.routerState.root.queryParams.id === newEvent.routerState.root.queryParams.id,
      routerEvent: newEvent,
    })),
    filter((payload) => {
      const {url} = payload.routerEvent.event;
      return /^\/search\/card(\W|)/.test(url);
    }),
    withLatestFrom(
      this.store.pipe(select(isAuthorizedInKeycloak)),
      this.store.pipe(select(getUserId)),
    ),
    switchMap(([payload, isAuthorized, userId]) => {
      const {id, entityType, recordId, isbn, upc, issn, controlNumber} = payload.routerEvent.routerState.root.queryParams;
      if (!isAuthorized || (isAuthorized && userId)) {

        if (entityType === EntityTypes.FORMAT_GROUP || recordId) {
          return [loadFormatGroup({entity: {id, entityType, recordId}})];
        } else if (isbn) {
          return [loadFormatGroup({entity: {id, entityType, isbn}})];
        } else if (upc) {
          return [loadFormatGroup({entity: {id, entityType, upc}})];
        } else if (issn) {
          return [loadFormatGroup({entity: {id, entityType, issn}})];
        } else if (controlNumber) {
          return [loadFormatGroup({entity: {id, entityType, controlNumber}})];
        }

        return [loadEntity({entity: {id, entityType}})];

      } else {
        return EMPTY;
      }

    }),
  ));

  public formatGroupLoadInstanceAboutInfo$ = createEffect(() => this.actions$.pipe(
    ofType(loadFormatGroupAboutInfo),
    switchMap(({id}) => {
      return this.searchService.getResource(id).pipe(
        map((result: SearchResult[]) => loadFormatGroupAboutInfoComplete({results: result})),
      );
    }),
  ));

  public loadRelatedItems$ = createEffect(() => this.actions$.pipe(
    ofType(loadRelatedItems),
    map(({item}) => item),
    switchMap(({id}) => {
      return this.fullCardService.getRelatedItems(id).pipe(
        map((relatedItems) => loadRelatedItemsComplete({relatedItems: relatedItems})),
        catchError((error) => of(loadRelatedItemsFailure({error}))),
      );
    }),
  ));

  public notifyAboutNewBookmarkItem = createEffect(() => merge(
    this.actions$.pipe(
      ofType(loadFormatGroupComplete),
      map(({entity}) => entity.fgEntity),
    ),
    this.actions$.pipe(
      ofType(loadEntityComplete),
      map(({entity}) => entity),
    ),
  ).pipe(
    map((entity: Entity | FormatGroup) => notifyNewEntitiesOnPage({entities: [entity]})),
  ));

  public loadEditionsAvailabilityOfMaterialTab$ = createEffect(() => this.actions$.pipe(
    ofType(loadRelatedItemsOfMaterialTabEditions),
    withLatestFrom(this.store.select(selectEntity)),
    tap(([{materialTabIndex, editionIndex}, entity]) => {
      const {materialTabs} = entity as FormatGroup;
      if (!materialTabs) {
        return;
      }
      const materialTab = materialTabs[materialTabIndex];
      const {editions} = materialTab;
      if (editionIndex !== undefined) {
        if (!editions[editionIndex].availability?.items) {
          this.store.dispatch(updateEditionAvailabilityState({materialTabIndex, editionIndex, expanded: true, loading: true}));
          this.fullCardService.getRelatedItems(editions[editionIndex].id).pipe(
            map((relatedItems) => {
              this.store.dispatch(loadRelatedItemsOfEditionComplete({materialTabIndex, editionIndex, relatedItems}));
            }),
            catchError((error) => of(loadRelatedItemsOfEditionFailure({materialTabIndex, editionIndex, error}))),
          ).subscribe();
          return;
        } else {
          this.store.dispatch(updateEditionAvailabilityState({materialTabIndex, editionIndex, expanded: true, loading: false}));
        }
      }
      if (editionIndex == undefined) {
        const calls = editions.filter(({availability}) => !availability?.items)
          .map((edition, editionIdx) => {
            if (!editions[editionIdx].availability?.items) {
              const request = this.fullCardService.getRelatedItems(edition.id).pipe(
                map((relatedItems) => {
                  this.store.dispatch(loadRelatedItemsOfEditionComplete({materialTabIndex, editionIndex: editionIdx, relatedItems}));
                }),
                catchError((error) => of(loadRelatedItemsOfEditionFailure({materialTabIndex, editionIndex: editionIdx, error}))),
              );
              return request;
            } else {
              this.store.dispatch(updateEditionAvailabilityState({materialTabIndex, editionIndex: editionIdx, expanded: true, loading: false}));
              return of(EMPTY);
            }
          });
        return concat(...calls).subscribe() ;
      }
    },
  ),
  ), {dispatch: false});

  constructor(
    private readonly actions$: Actions<RouterNavigationAction>,
    private readonly searchService: SearchService,
    private readonly fullCardService: FullEntityService,
    private readonly store: Store<State>,
  ) {
  }
}
