import { animate, query, stagger, style, transition, trigger } from '@angular/animations';

export const flyInItemsAnimation = trigger('flyInItems', [
  transition('* => *', [
    query(':enter', [
      style({opacity: 0, transform: 'translateX(-30px)'}),
      stagger('100ms', [
        animate('200ms', style({opacity: 1, transform: 'translateX(0)'})),
      ]),
    ], {optional: true, limit: 10}),
  ]),
]);
