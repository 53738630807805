import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { closeGlobalSidebar, openGlobalSidebar } from 'core/actions/global-sidebar.actions';

export interface GlobalSidebarState {
  isOpen: boolean;
}

export const initialState: GlobalSidebarState = {
  isOpen: false,
};

const _reducer = createReducer(
  initialState,
  on(openGlobalSidebar, (state) => ({ ...state, isOpen: true })),
  on(closeGlobalSidebar, (state) => ({ ...state, isOpen: false })),
);

export function reducer(state: GlobalSidebarState | undefined, action: Action) {
  return _reducer(state, action);
}

export const featureKey = 'globalSidebar';

export const getGlobalSidebarState = createFeatureSelector(featureKey);

export const getGlobalSidebarVisibility = createSelector(getGlobalSidebarState, (state: GlobalSidebarState): boolean => {
  return state.isOpen;
});

