<div *ngIf="counter !== undefined" class="countdown" data-automation-id="countdown-container">
  <h3 id="countdown-title" class="countdown__title" data-automation-id="countdown-title" translate>
    youWillBeAutomaticallyRedirectedIn
  </h3>
  <div class="countdown__time" role="timer" aria-live="polite" aria-labelledby="countdown-title" data-automation-id="countdown-time">
    {{ counter }}
  </div>

  <div aria-live="polite" role="status" *ngIf="counter < 2" class="mt-5" data-automation-id="countdown-loading">
    <span class="spinner-border text-primary"
          data-automation-id="countdown-redirect-spinner">
      <span class="sr-only" translate>Loading...</span>
    </span>
    <span class="m-2" data-automation-id="countdown-redirecting" translate>redirecting</span>
  </div>
</div>
