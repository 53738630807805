<div class="tab-container" *ngIf="visible">
  <h3 class="header" translate
     data-automation-id="description-header">description</h3>

  <div class="d-flex flex-column flex-sm-row" *ngIf="description.format">
    <div class="label mb-2 mb-sm-6" translate data-automation-id="format-label@@format">format</div>
    <div class="value capitalized" data-automation-id="format-value">{{tabLabel}}</div>
  </div>

  <div class="d-flex flex-column flex-sm-row" *ngIf="description.title">
    <div class="label mb-2 mb-sm-6" translate data-automation-id="title-label">title</div>
    <div class="value" data-automation-id="title-value">
      <app-cut-text-with-certain-height
        [text]="description.title"
        [height]="boxHeight"
        class="more-less-link-frc"
        [moreLessColor]="moreLessColor"
        minLengthToCut="60">
      </app-cut-text-with-certain-height>
    </div>
  </div>

  <div class="d-flex flex-column flex-sm-row" *ngIf="description.edition">
    <div class="label mb-2 mb-sm-6" translate data-automation-id="edition-label">edition</div>
    <div class="value" data-automation-id="edition-value">
      <app-cut-text-with-certain-height
        [text]="description.edition"
        [height]="boxHeight"
        class="more-less-link-frc"
        [moreLessColor]="moreLessColor"
        minLengthToCut="60">
      </app-cut-text-with-certain-height>
    </div>
  </div>

  <div class="d-flex flex-column flex-sm-row" *ngIf="description.dissertation">
    <div class="label mb-2 mb-sm-6" translate data-automation-id="dissertation-label">dissertation</div>
    <div class="value" data-automation-id="dissertation-value">
      <app-cut-text-with-certain-height
        [text]="description.dissertation"
        [height]="boxHeight"
        class="more-less-link-frc"
        [moreLessColor]="moreLessColor"
        minLengthToCut="60">
      </app-cut-text-with-certain-height>
    </div>
  </div>

  <div class="d-flex flex-column flex-sm-row" *ngIf="description.primaryAgent">
    <div class="label mb-2 mb-sm-6" translate [translateParams]="{ role: description.primaryAgent.role }"
         data-automation-id="primary-agent-title" id="primary-agent">
      primaryAgentLabel
    </div>
    <div class="value" data-automation-id="author-value">
      <a
        class="link"
        [routerLink]="['/search', 'card']"
        [queryParams]="{id: description.primaryAgent.id, entityType: 'Agent'}"
        aria-describedby="primary-agent">
        {{description.primaryAgent.label}}
      </a>
    </div>
  </div>

  <div class="d-flex flex-column flex-sm-row" *ngIf="description.publisherInformation">
    <div class="label mb-2 mb-sm-6" translate
         data-automation-id="publisher-information-label">publisherInformation</div>
    <div class="value"
         data-automation-id="publisher-information-value">{{description.publisherInformation}}</div>
  </div>

  <div class="d-flex flex-column flex-sm-row" *ngIf="description.vendorInformation">
      <div class="label mb-2 mb-sm-6"
           data-automation-id="vendor-name-label" translate>vendorInformationLabel</div>
      <div class="value"
           data-automation-id="vendor-name-value">
            {{ isEcontentVendor() ? formattedResource[description.vendorInformation] : description.vendorInformation }}
      </div>
    </div>

  <div class="d-flex flex-column flex-sm-row" *ngIf="description.physicalDescription">
    <div class="label mb-2 mb-sm-6" translate
         data-automation-id="physical-description-label">physicalDescription</div>
    <div class="value"
         data-automation-id="physical-description-value">{{description.physicalDescription}}</div>
  </div>

  <div class="d-flex flex-column flex-sm-row" *ngIf="description.note">
      <div class="label mb-2 mb-sm-6"
           data-automation-id="note-label" translate>noteLabel</div>
      <div class="value public-note-link"
           data-automation-id="note-value"
           (click)="onOpenPublicNoteLink(description.note.url)">{{description.note.label}}</div>
    </div>
</div>
