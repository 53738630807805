import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'ins-bs-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  @Input() public checked: boolean;
  @Input() public containerClass: string[] = ['m-2'];
  @Input() public disabled = false;
  @Input('aria-label') public ariaLabel = '';
  @Input() public ariaLabelledby = '';
  @Output() public change = new EventEmitter<CheckboxComponent>();
  @ViewChild('checkbox', { static: true }) public checkbox: ElementRef;

  public onChange(event: Event): void {
    event.stopPropagation();
    this.checked = this.checkbox.nativeElement.checked;
    this.disabled = this.checkbox.nativeElement.disabled;
    this.change.emit(this);
  }
}
