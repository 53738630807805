import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BulkHoldSubmitState } from 'user/reducers/bulk-hold.reducer';

@Component({
  selector: 'app-bulk-hold-counters',
  templateUrl: './bulk-hold-counters.component.html',
  styleUrls: ['./bulk-hold-counters.component.scss']
})
export class BulkHoldCountersComponent {
  @Input() public bulkHoldSubmitState: BulkHoldSubmitState;
  @Output() public neededActionClick = new EventEmitter<void>();
}
