import { Component, OnInit } from '@angular/core';
import { changeHoldsSort, loadHolds } from 'user/actions/user-profile.actions';
import { MaxHeightPerfectScrollbar } from 'user/models/bookshelf';
import { HoldCard, HoldSortingCriteria } from 'user/models/holds';
import { SortField } from 'user/models/user-receipt-card';
import { getHoldsState, UserState } from 'user/reducers/user.reducer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-holds',
  templateUrl: './holds.component.html',
  styleUrls: ['./holds.component.scss'],
})
export class HoldsComponent implements OnInit {
  public holdsState$ = this.store.select(getHoldsState);
  public maxHeightPerfectScrollbar = MaxHeightPerfectScrollbar.BASE_ACCOUNT_TAB;
  public readonly holdSorting: SortField<HoldSortingCriteria>[] = [
    {field: 'status', translationKey: 'sortByStatus'},
    {field: 'title', translationKey: 'sortByTitle'},
  ];

  constructor(
    private readonly store: Store<UserState>,
  ) {
  }

  public ngOnInit(): void {
    this.store.dispatch(loadHolds());
  }

  public trackById(index: number, card: HoldCard): string {
    return card.receipt.id;
  }

  public sortHolds(field: HoldSortingCriteria): void {
    this.store.dispatch(changeHoldsSort({sortingCriteria: field}));
  }
}
