import { Component, Input, OnInit } from '@angular/core';
import { DrawerElement } from 'search/models/search-models';
import { AvailabilityStatuses } from '../../../../../models/dictionaries';
import { GroupedLocationInfo } from 'search/models/search-results';
import { isEmptyMessage } from 'core/utils/empty-message';
import { FeatureToggleService } from '../../../../../services/feature-toggle.service';
import { ItemLanguageMapperService } from '../../../../../services/item-language-mapper.service';

@Component({
  selector: 'app-drawer-item',
  templateUrl: './drawer-item.component.html',
  styleUrls: ['./drawer-item.component.scss'],
})
export class DrawerItemComponent implements OnInit {
  @Input() public item: DrawerElement;
  @Input() public displayNotes: boolean;
  @Input() public recordId?: string;
  @Input() public title?: string;
  @Input() public location?: GroupedLocationInfo;
  @Input() public isLast: boolean;
  @Input() public isNlbCustomer?: boolean;
  @Input() public language: string;
  public matchedLanguage: string;
  public showOpacMessage: boolean;
  public itemAvailabilityStatuses = AvailabilityStatuses;

  constructor(
    private readonly featureToggleService: FeatureToggleService
  ) {
  }

  public ngOnInit() {
    this.showOpacMessage = !isEmptyMessage(this.item.opacMessage);
    if (this.featureToggleService.getToggles()['VE-4791_2024-07-31_pass_item_language']) {
      this.matchedLanguage = ItemLanguageMapperService.getLanguageByCode(this.item?.itemLanguageCode)?.name;
    }
  }
}
