import { Injectable } from '@angular/core';
import { Edition, IssuanceTypes, MaterialTab } from '../models/entity';
import { ECONTENT_VENDORS_WITH_ISSUES } from 'shared/vendor-config';

@Injectable({
  providedIn: 'root'
})
export class VendorIssuesService {

  constructor() { }


  public hasVendorIssuesForTab(tab: MaterialTab): boolean {
    return tab.issuance?.includes(IssuanceTypes.SERIAL)
      && ECONTENT_VENDORS_WITH_ISSUES.some(vendor => tab.availability.vendors?.hasOwnProperty(vendor));
  }


  public hasVendorIssuesForEdition(edition: Edition): boolean {
    return edition.issuance?.includes(IssuanceTypes.SERIAL)
      && ECONTENT_VENDORS_WITH_ISSUES.some(vendor => edition.vendors?.hasOwnProperty(vendor));
  }
}
