import { Injectable } from '@angular/core';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { CustomerFeature, CustomerIntegrationType } from '../customer-integration';
import { INN_REACH_CUSTOMER_HIDDEN_FEATURES } from '../inn-reach/configs/inn-reach.config';
import { NLB_CUSTOMER_HIDDEN_FEATURES } from '../nlb/configs/nlb.config';

@Injectable({
  providedIn: 'root'
})
export class CustomerFeatureToggleService {
  private readonly CustomerHiddenFeaturesMap: { [key in CustomerIntegrationType]?: CustomerFeature[] } = {
    [CustomerIntegrationType.NLB]: NLB_CUSTOMER_HIDDEN_FEATURES,
    [CustomerIntegrationType.INNREACH]: INN_REACH_CUSTOMER_HIDDEN_FEATURES,
  };

  constructor(
    private readonly configLoader: ConfigurationLoader,
  ) {}

  public get customerHiddenFeatures(): CustomerFeature[] {
    return this.CustomerHiddenFeaturesMap[this.configLoader.customerIntegrationType] || [];
  }

  public isFeatureAvailable(feature: CustomerFeature): boolean {
    return !this.customerHiddenFeatures.includes(feature);
  }

  public isAnyFeatureAvailable(features: CustomerFeature[]): boolean {
    return features.some(feature => this.isFeatureAvailable(feature));
  }

  public isNlbCustomer(): boolean {
    return this.configLoader.customerIntegrationType === CustomerIntegrationType.NLB;
  }
}
