import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getVendorAvailability } from 'app/entity/reducers/entity.reducer';
import { ModalQueueService } from 'app/list/services/modal-queue.service';
import {
  EcontentRichFeatureSuccessfulDialogComponent
} from 'core/over-drive/components/econtent-rich-feature-successful-dialog/econtent-rich-feature-successful-dialog.component';
import { RichFeatureAction } from 'core/over-drive/components/econtent-rich-feature-successful-dialog/models';
import {
  OverdriveDownloadRedirectDialogComponent,
} from 'core/over-drive/components/overdrive-checkout-dialog/overdrive-download-redirect-dialog.component';
import { EMPTY, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import {
  Axis360CheckoutRequestParams,
  EContentCheckoutDetails,
  EContentCheckoutRequestParams,
  HooplaCheckout
} from 'user/models/checkouts';
import { checkEntityVendorAvailability } from '../../../entity/actions/entity.actions';
import { ExternalGetItSuccessfulAction, ExternalHoldRequestSuccessfulAction } from '../../../entity/actions/vendors.actions';
import { VendorType } from '../../../entity/models/entity';
import { LOCAL_STORAGE } from '../../../models/local-storage';
import { WindowRefService } from '../../../services/window-ref.service';
import {
  ECONTENT_GET_IT_SUCCESSFUL,
  ECONTENT_HOLD_REQUEST_SUCCESSFUL,
  EContentGetItSuccessfulAction,
  EContentHoldRequestSuccessfulAction,
  FINALIZE_ISSUE,
  FinalizeIssueAction,
  HOOPLA_GET_IT_SUCCESSFUL,
  HooplaGetItSuccessfulAction,
  OVERDRIVE_CHECKOUT,
  OVERDRIVE_GET_ISSUES_SUCCESSFUL,
  OVERDRIVE_GET_IT_FAILED,
  OVERDRIVE_GET_IT_SUCCESSFUL,
  OVERDRIVE_HOLD_REQUEST_FAILED,
  OVERDRIVE_HOLD_REQUEST_SUCCESSFUL,
  OverDriveCheckoutIssueAction,
  OverDriveActionComplete,
  OverDriveGetIssueFailedAction,
  OverDriveGetIssueSuccessfulAction,
  OverDriveGetItFailedAction,
  OverDriveGetItSuccessfulAction,
  OverDriveHoldRequestFailedAction,
  OverDriveHoldRequestSuccessfulAction,
  PREPARE_ECONTENT_GET_IT,
  PREPARE_ECONTENT_HOLD_REQUEST,
  PREPARE_HOOPLA_GET_IT,
  PREPARE_OVERDRIVE_GET_IT,
  PREPARE_OVERDRIVE_HOLD_REQUEST,
  PrepareEContentGetItAction,
  PrepareEContentHoldRequestAction,
  PrepareHooplaGetItAction,
  PrepareOverDriveGetItAction,
  PrepareOverDriveHoldRequestAction,
} from '../actions/over-drive.actions';
import {
  HooplaGetItSuccessfulDialogComponent
} from '../components/hoopla-get-it-successful-dialog/hoopla-get-it-successful-dialog.component';
import { OverDriveAuthErrorDialogComponent } from '../components/over-drive-auth-error-dialog/over-drive-auth-error-dialog.component.';
import {
  OverDriveGetItFailedDialogComponent
} from '../components/over-drive-get-it-failed-dialog/over-drive-get-it-failed-dialog.component';
import {
  OverDriveGetItSuccessfulDialogComponent
} from '../components/over-drive-get-it-successful-dialog/over-drive-get-it-successful-dialog.component';
import { EContentGetItPayload, ErrorCause } from '../models/over-drive';
import { EContentAvailability, OverDriveMagazineResponse } from '../models/over-drive-availability';
import { OverDriveService } from '../services/over-drive.service';
import { IssueListComponent } from 'user/components/issue-list/issue-list.component';

@Injectable()
export class OverDriveEffects {

  private static readonly AXIS_NOW_FORMAT = 'AxisNow';

  public prepareOverdriveGetIt$ = createEffect(() => this.actions$.pipe(
    ofType(PREPARE_OVERDRIVE_GET_IT),
    map((action: PrepareOverDriveGetItAction) => action.payload),
    switchMap((item) => {
      if (item.hasEcontentIssues) {
        return this.overDriveService.getIssuesFromOverdriveMagazine(item.reserveId)
          .pipe(
            switchMap((issueData: OverDriveMagazineResponse) => [
                new OverDriveGetIssueSuccessfulAction({overdriveData: item, issueData})
              ]),
            catchError(() => of(new OverDriveGetIssueFailedAction())),
          );
      } else {
        return this.overDriveService.checkoutOverdriveItem(item.reserveId)
        .pipe(
          switchMap(() => [
            new OverDriveGetItSuccessfulAction(item),
            new ExternalGetItSuccessfulAction({vendor: item.vendor, ...item}),
          ]),
          catchError((error) => of(new OverDriveGetItFailedAction({response: error, vendor: VendorType.OVERDRIVE}))),
        );
      }
    }),
  ));

  public checkoutIssue$ = createEffect(() => this.actions$.pipe(
    ofType(OVERDRIVE_GET_ISSUES_SUCCESSFUL),
    map((action: OverDriveGetIssueSuccessfulAction) => action.payload),
    switchMap((getItIssueData) => {
      const modalRef = this.modalService.open(IssueListComponent, {centered: true, windowClass: 'inspire-custom-modal'});
      modalRef.componentInstance.issues = getItIssueData.issueData.products;
      return from(modalRef.result).pipe(
        map((product) => new FinalizeIssueAction({overdriveData: getItIssueData.overdriveData, issueData: product})),
        catchError((_) => {
          this.modalQueueService.startQueue();
          return EMPTY;
        })
      );
    }),
  ));

  public finalizeIssueFlow$ = createEffect(() => this.actions$.pipe(
    ofType(FINALIZE_ISSUE),
    map((action: FinalizeIssueAction) => action.payload),
    switchMap((item) => {
      return this.overDriveService.checkoutOverdriveItem(item.issueData.id).pipe(
        switchMap(() => [
          new OverDriveCheckoutIssueAction(item.issueData.id),
          new ExternalGetItSuccessfulAction({vendor: item.overdriveData.vendor, ...item.overdriveData}),
        ]),
        catchError((error) => {
          return of(new OverDriveGetItFailedAction({response: error, vendor: VendorType.OVERDRIVE}));
        }),
      );
    }),
  ));

  public prepareHooplaGetIt$ = createEffect(() => this.actions$.pipe(
    ofType(PREPARE_HOOPLA_GET_IT),
    map((action: PrepareHooplaGetItAction) => action.payload),
    switchMap((item: EContentGetItPayload) => {
      const vendor = VendorType.HOOPLA;
      return this.overDriveService.checkoutHooplaItem(item.reserveId)
        .pipe(
          map((response: HooplaCheckout) => {
            return {
              itemId: item.itemId,
              reserveId: item.reserveId,
              vendor,
              entityType: item.entityType,
              formatGroupId: item.formatGroupId,
              instanceId: item.instanceId,
              message: response.hoopla?.message,
              remainingCheckouts: response.hoopla?.remainingCheckouts,
              url: response.hoopla?.url,
            };
          }),
          switchMap((responseItem) => [
            new HooplaGetItSuccessfulAction(responseItem),
            new ExternalGetItSuccessfulAction({ vendor, ...responseItem }),
          ]),
          catchError((error) => of(new OverDriveGetItFailedAction({ response: error, vendor }))),
        );
    }),
  ));

  private getAxis360GetItParams$(item: EContentGetItPayload): Observable<Axis360CheckoutRequestParams> {
    return this.store.select(getVendorAvailability(item.vendor as VendorType))
    .pipe(
      take(1),
      map(axis360Data => {
        const availabilities: EContentAvailability[] = axis360Data[item.formatGroupId];
        const availability = availabilities.find(availability => availability.reserveId === item.reserveId);
        const format = availability.availableFormats.includes(OverDriveEffects.AXIS_NOW_FORMAT)
          ? OverDriveEffects.AXIS_NOW_FORMAT
          : availability.availableFormats[0];
        return {format};
      }),
      catchError(() => of({format: OverDriveEffects.AXIS_NOW_FORMAT})),
    );
  }

  private getEContentGetItParams$(item: EContentGetItPayload): Observable<EContentCheckoutRequestParams> {
    switch (item.vendor) {
      case VendorType.AXIS360:
        return this.getAxis360GetItParams$(item);
      default:
        return of({});
    }
  }

  public prepareEContentGetIt$ = createEffect(() => this.actions$.pipe(
    ofType(PREPARE_ECONTENT_GET_IT),
    map((action: PrepareEContentGetItAction) => action.payload),
    switchMap((item: EContentGetItPayload) => {
      const vendor = item.vendor as VendorType;
      return this.getEContentGetItParams$(item)
        .pipe(
          switchMap(params => {
            return this.overDriveService.checkoutEContentItem(vendor, item.reserveId, params);
          }),
          map((response: EContentCheckoutDetails) => {
            return {
              itemId: item.itemId,
              reserveId: item.reserveId,
              vendor,
              entityType: item.entityType,
              formatGroupId: item.formatGroupId,
              instanceId: item.instanceId,
              url: response.titleUrl,
            };
          }),
          switchMap((responseItem) => [
            new EContentGetItSuccessfulAction(responseItem),
            new ExternalGetItSuccessfulAction({ vendor, ...responseItem }),
          ]),
          catchError((error) => of(new OverDriveGetItFailedAction({ response: error, vendor }))),
        );
    }),
  ));

  public overdriveCheckoutIssueItem = createEffect(() => this.actions$.pipe(
    ofType(OVERDRIVE_CHECKOUT),
    map((action: OverDriveCheckoutIssueAction) => action.payload),
    switchMap((reserveId) => {
      const modalRef = this.modalService.open(OverdriveDownloadRedirectDialogComponent, {size: 'md'});
      modalRef.componentInstance.reserveId = reserveId;
      from(modalRef.result)
      .subscribe(
        () => this.modalQueueService.startQueue(),
        () => this.modalQueueService.startQueue(),
      );
      return [
        new OverDriveActionComplete()
      ];
    }),
  ));

  public overdriveGetItSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType(OVERDRIVE_GET_IT_SUCCESSFUL),
    map((action: OverDriveGetItSuccessfulAction) => action.payload),
    switchMap(({formatGroupId, reserveId, vendor}) => {
      const modalRef = this.modalService.open(OverdriveDownloadRedirectDialogComponent, {size: 'md'});
      modalRef.componentInstance.reserveId = reserveId;
      from(modalRef.result)
      .subscribe(
        () => this.modalQueueService.startQueue(),
        () => this.modalQueueService.startQueue(),
      );
      return [checkEntityVendorAvailability({
        vendor,
        content: {
          resourceId: formatGroupId,
          reserveId: [reserveId],
        },
      })];
    }),
  ));

  public hooplaGetItSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType(HOOPLA_GET_IT_SUCCESSFUL),
    map((action: HooplaGetItSuccessfulAction) => action.payload),
    map(({ formatGroupId, reserveId, vendor, message, remainingCheckouts, url }) => {
      const modalRef = this.modalService.open(HooplaGetItSuccessfulDialogComponent, { size: 'sm' });
      modalRef.componentInstance.data = {
        vendor,
        reserveId,
        message,
        remainingCheckouts,
        url,
      };
      from(modalRef.result)
        .subscribe(
          () => this.modalQueueService.startQueue(),
          () => this.modalQueueService.startQueue(),
        );
      return checkEntityVendorAvailability({
        vendor,
        content: {
          resourceId: formatGroupId,
          reserveId: [reserveId],
        },
      });
    }),
  ));

  public eContentGetItSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType(ECONTENT_GET_IT_SUCCESSFUL),
    map((action: EContentGetItSuccessfulAction) => action.payload),
    map(({ formatGroupId, reserveId, vendor, url }) => {
      const modalRef = this.modalService.open(EcontentRichFeatureSuccessfulDialogComponent, { size: 'sm' });
      modalRef.componentInstance.url = url;
      modalRef.componentInstance.action = RichFeatureAction.GET_IT;
      from(modalRef.result)
        .subscribe(
          () => this.modalQueueService.startQueue(),
          () => this.modalQueueService.startQueue(),
        );
      return checkEntityVendorAvailability({
        vendor,
        content: {
          resourceId: formatGroupId,
          reserveId: [reserveId],
        },
      });
    }),
  ));

  public getItFailed$ = createEffect(() => this.actions$.pipe(
    ofType(OVERDRIVE_GET_IT_FAILED, OVERDRIVE_HOLD_REQUEST_FAILED),
    map((action: OverDriveGetItFailedAction) => action.payload),
    switchMap((payload) => {
      if (payload.response.error?.cause === ErrorCause.PASSWORD_NOT_FOUND) {
        this.windowRefService.nativeWindow().localStorage.setItem(LOCAL_STORAGE.OVERDRIVE_AUTH_ERROR, 'true');
        const modalRef = this.modalService.open(OverDriveAuthErrorDialogComponent, { size: 'sm' });
        modalRef.componentInstance.errorsResponse = payload.response.message;
        return from(modalRef.result).pipe(catchError(() => of(null)));
      }
      else {
        const modalRef = this.modalService.open(OverDriveGetItFailedDialogComponent, { size: 'sm' });
        modalRef.componentInstance.errorsResponse = payload.response;
        modalRef.componentInstance.vendorType = payload.vendor;
        return from(modalRef.result).pipe(catchError(() => of(null)));
      }

    }),
    tap(() => this.modalQueueService.startQueue()),
  ), { dispatch: false });

  public placeHold$ = createEffect(() => this.actions$.pipe(
    ofType(PREPARE_OVERDRIVE_HOLD_REQUEST),
    map((action: PrepareOverDriveHoldRequestAction) => action.payload),
    switchMap(
      ({ itemId, reserveId }) => this.overDriveService.placeHoldOverdriveItem(reserveId)
        .pipe(
          switchMap(() => [
            new OverDriveHoldRequestSuccessfulAction({ itemId, reserveId }),
            new ExternalHoldRequestSuccessfulAction({ vendor: VendorType.OVERDRIVE, itemId, reserveId }),
          ]),
          catchError((error) => {
            return of(new OverDriveHoldRequestFailedAction(
              { response: error, vendor: VendorType.OVERDRIVE },
            ));
          }),
        ),
    ),
  ));

  public econtentPlaceHold$ = createEffect(() => this.actions$.pipe(
    ofType(PREPARE_ECONTENT_HOLD_REQUEST),
    map((action: PrepareEContentHoldRequestAction) => action.payload),
    switchMap(
      ({ contentId, vendor }) => this.overDriveService.placeHoldEContentItem(vendor, contentId)
        .pipe(
          switchMap(() => [
            new EContentHoldRequestSuccessfulAction(),
            new ExternalHoldRequestSuccessfulAction({ vendor, reserveId: contentId, itemId: '' }),
          ]),
          catchError((error) => {
            return of(new OverDriveHoldRequestFailedAction(
              { response: error, vendor },
            ));
          }),
        ),
    ),
  ));

  public holdRequestSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType(OVERDRIVE_HOLD_REQUEST_SUCCESSFUL),
    map((action: OverDriveHoldRequestSuccessfulAction) => action.payload),
    switchMap(() => {
      const modalRef = this.modalService.open(OverDriveGetItSuccessfulDialogComponent, { size: 'sm' });
      modalRef.componentInstance.data = { isHoldRequest: true };
      return from(modalRef.result).pipe(catchError(() => of(null)));
    }),
    tap(() => this.modalQueueService.startQueue()),
  ), { dispatch: false });

  public econtentHoldRequestSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType(ECONTENT_HOLD_REQUEST_SUCCESSFUL),
    switchMap(() => {
      const modalRef = this.modalService.open(EcontentRichFeatureSuccessfulDialogComponent, { size: 'sm' });
      modalRef.componentInstance.action = RichFeatureAction.PLACE_HOLD;
      return from(modalRef.result).pipe(catchError(() => of(null)));
    }),
    tap(() => this.modalQueueService.startQueue()),
  ), { dispatch: false });

  constructor(
    private store: Store,
    private actions$: Actions,
    private overDriveService: OverDriveService,
    private modalService: NgbModal,
    private modalQueueService: ModalQueueService,
    private windowRefService: WindowRefService,
  ) { }

}
