import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { setIsAuthorized, setIsStaffAuthorized } from '../actions/keycloak.actions';

export interface KeycloakState {
  isAuthorized: boolean;
  isAuthorizing: boolean;
  isStaffAuthorized: boolean;
}

export const initialState: KeycloakState = {
  isAuthorized: false,
  isAuthorizing: true,
  isStaffAuthorized: false,
};

const keycloakReducer = createReducer(
  initialState,
  on(setIsAuthorized, (state, {isAuthorized}) => ({
    ...state,
    isAuthorized,
    isAuthorizing: false,
    isStaffAuthorized: false,
  })),
  on(setIsStaffAuthorized, (state, {isStaffAuthorized}) => ({
    ...state,
    isAuthorized: false,
    isAuthorizing: false,
    isStaffAuthorized,
  })),
);

export function reducer(state: KeycloakState | undefined, action: Action) {
  return keycloakReducer(state, action);
}

const keycloakState = createFeatureSelector('keycloak');

export const isAuthorizedInKeycloak = createSelector(keycloakState, (state: KeycloakState) => state.isAuthorized);

export const isAuthorizingInKeycloak = createSelector(keycloakState, (state: KeycloakState) => state.isAuthorizing);

export const isStaffAuthorizedInKeycloak = createSelector(keycloakState, (state: KeycloakState) => state.isStaffAuthorized);
