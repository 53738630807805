import { Action } from '@ngrx/store';
import { ExternalResourceRequestPayload } from 'search/models/e-content';

export const PREPARE_EXTERNAL_GET_IT = '[Vendors] Prepare get it';
export const EXTERNAL_GET_IT_SUCCESSFUL = '[Vendors] Get it successful';
export const PREPARE_EXTERNAL_HOLD_REQUEST = '[Vendors] Prepare hold request';
export const EXTERNAL_HOLD_REQUEST_SUCCESSFUL = '[Vendors] Hold request successful';

export class PrepareExternalGetItAction implements Action {
  public readonly type = PREPARE_EXTERNAL_GET_IT;

  constructor(public payload: ExternalResourceRequestPayload) {}
}

export class ExternalGetItSuccessfulAction implements Action {
  public readonly type = EXTERNAL_GET_IT_SUCCESSFUL;

  constructor(public payload: ExternalResourceRequestPayload) {}
}

export class PrepareExternalHoldRequestAction implements Action {
  public readonly type = PREPARE_EXTERNAL_HOLD_REQUEST;

  constructor(public payload: ExternalResourceRequestPayload) {}
}

export class ExternalHoldRequestSuccessfulAction implements Action {
  public readonly type = EXTERNAL_HOLD_REQUEST_SUCCESSFUL;

  constructor(public payload: ExternalResourceRequestPayload) {}
}

export type VendorActions =
  | PrepareExternalGetItAction
  | ExternalGetItSuccessfulAction
  | PrepareExternalHoldRequestAction
  | ExternalHoldRequestSuccessfulAction;
