import { ResourceFacet } from 'search/facets/models/resource-facet';
import { BookCover } from 'search/models/search-results';
import {ListItem, ListItemEntity} from '../../list/models/list';
import { ListPagination } from '../../list/models/list.dto';

export type CustomShowcaseSingle = {
  id: string;
  name: string;
  creator: CustomShowcaseCreator;
  createdFrom: CustomShowcaseCreatedFrom;
  published: boolean;
  publishDate: string | null;
  metadata: CustomShowcaseMetadata;
};

export interface CoversAndItemsCount {
  itemsCount: number;
  items: Array <ListItemEntity | {coverConfig: BookCover}>;
}

export type CustomShowcase = CustomShowcaseSingle & CoversAndItemsCount;

export type OpenedCuratedShowcaseWithDetails = {
  showcase: CustomShowcaseSingle,
  items: ListItem[],
  pagination: ListPagination,
  type: CustomShowcaseCreatedFromType.list,
};

export type OpenedDynamicShowcaseWithDetails = {
  showcase: CustomShowcaseSingle,
  type: CustomShowcaseCreatedFromType.savedSearch,
  facets: ResourceFacet[],
  searchText: string,
  sorting: string,
}

export type OpenedShowcaseWithDetails = OpenedCuratedShowcaseWithDetails | OpenedDynamicShowcaseWithDetails;

export type CustomShowcaseForm = {
  id?: string;
  name?: string;
  creator?: CustomShowcaseCreator;
  createdFrom: CustomShowcaseCreatedFrom;
  published?: boolean;
  publishDate?: string | null;
  metadata?: CustomShowcaseMetadata;
};

export enum CustomShowcaseCreatedFromType {
  list = 'LIST',
  savedSearch = 'SAVED_SEARCH',
}

export interface CustomShowcaseCreator {
  id: string;
  nickname: string;
}

export interface CustomShowcaseCreatedFrom {
  id: string;
  name: string;
  type: CustomShowcaseCreatedFromType;
}

export interface CustomShowcaseDisplayOn {
  recordPages: boolean;
}

export type CustomShowcaseSortOrder = 'date.asc' | 'date.desc' | 'title.asc' | 'title.desc';
export type CustomShowcaseBooleanSetMetadata = CustomShowcaseDisplayOn | AboutShowcaseType | AboutShowcaseAge | AboutShowcaseFormat;

export interface CustomShowcaseMetadata {
  sortOrder: CustomShowcaseSortOrder | null;
  displayOn: CustomShowcaseDisplayOn;
  displayCreator: boolean;
  aboutShowcase: AboutShowcase;
}

export interface CustomShowcaseCreationForm {
  name: string;
  createdFrom: CustomShowcaseCreatedFrom;
  metadata: CustomShowcaseMetadata;
}

export interface CustomShowcaseUpdatedFields {
  name?: string;
  published?: boolean;
  metadata?: CustomShowcaseMetadata;
}

export interface CustomShowcaseReference {
  id: string;
}

export interface CustomShowcasePublishDate {
  publishDate: string | null;
}

export interface AboutShowcase {
  type: AboutShowcaseType;
  age: AboutShowcaseAge;
  format: AboutShowcaseFormat;
  startDate?: string,
  endDate?: string,
  deleteExpired?: boolean
}

export interface AboutShowcaseType {
  fiction: boolean;
  nonfiction: boolean;
}

export interface AboutShowcaseAge {
  preschool: boolean;
  primary: boolean;
  preteen: boolean;
  teen: boolean;
  adult: boolean;
  juvenile: boolean;
}

export interface AboutShowcaseFormat {
  a: boolean;
  l: boolean;
  v: boolean;
  g: boolean;
  u: boolean;
  x: boolean;
  h: boolean;
  y: boolean;
  i: boolean;
  '-': boolean;
}

export type CustomShowcaseMetadataIterable = AboutShowcaseType | AboutShowcaseAge | AboutShowcaseFormat | CustomShowcaseDisplayOn;

export function isCuratedShowcaseType(type: CustomShowcaseCreatedFromType): boolean {
  return type === CustomShowcaseCreatedFromType.list;
}

export function isDynamicShowcaseType(type: CustomShowcaseCreatedFromType): boolean {
  return type === CustomShowcaseCreatedFromType.savedSearch;
}

export function isCuratedShowcase(showcase?: CustomShowcase | CustomShowcaseForm): boolean {
  return showcase?.createdFrom.type === CustomShowcaseCreatedFromType.list;
}

export function isDynamicShowcase(showcase?: CustomShowcase | CustomShowcaseForm): boolean {
  return showcase?.createdFrom.type === CustomShowcaseCreatedFromType.savedSearch;
}

export function isOpenedCuratedShowcase(showcase: OpenedShowcaseWithDetails): showcase is OpenedCuratedShowcaseWithDetails {
  return showcase.type === CustomShowcaseCreatedFromType.list;
}

export function isOpenedDynamicShowcase(showcase: OpenedShowcaseWithDetails): showcase is OpenedDynamicShowcaseWithDetails {
  return showcase.type === CustomShowcaseCreatedFromType.savedSearch;
}
