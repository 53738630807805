import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-confirm-delete-items',
  templateUrl: './confirm-delete-items.component.html',
  styleUrls: ['./confirm-delete-items.component.scss'],
})
export class ConfirmDeleteItemsComponent {
  @Input() public itemsCount: number;
  @Output() public onConfirm = new EventEmitter<void>();
  @Output() public onCancel = new EventEmitter<void>();

  public confirmDelete() {
    this.onConfirm.emit();
  }

  public cancel() {
    this.onCancel.emit();
  }
}
