import { Action, createFeatureSelector, createReducer, createSelector, on, } from '@ngrx/store';
import * as Immutable from 'immutable';
import { SearchResult } from 'search/models/search-results';
import {
  loadEntity,
  loadEntityComplete,
  loadEntityFailure,
  loadFormatGroup,
  loadFormatGroupAboutInfo,
  loadFormatGroupAboutInfoComplete,
  loadFormatGroupByRecordIdFailure,
  loadFormatGroupByIdentifierFailure,
  loadFormatGroupComplete,
  loadFormatGroupFailure,
  loadRelatedItems,
  loadRelatedItemsComplete,
  loadRelatedItemsFailure,
  loadRelatedItemsOfEditionComplete,
  loadRelatedItemsOfEditionFailure,
  updateEditionAvailabilityState,
  queryEditions
} from '../actions/full-entity.actions';
import { Entity, FormatGroup, SearchResultResponse } from '../models/entity';
import { RelatedItem } from '../models/related-items-result';

export interface AvailabilityState {
  loading: boolean;
  error: string;
  totalResults: number;
  items: RelatedItem[];
}

export interface FullEntityState {
  loading: boolean;
  error: any;
  entity: FormatGroup | Entity;
  instanceAboutInfo: Immutable.Map<string, SearchResultResponse>;
  availability: AvailabilityState;
  editionsQuery?: string;
}

export const initialState: FullEntityState = {
  loading: false,
  error: null,
  entity: null,
  instanceAboutInfo: Immutable.Map<string, SearchResultResponse>(),
  availability: {
    loading: false,
    error: '',
    totalResults: 0,
    items: [],
  },
  editionsQuery: null
};

const fullEntityReducer = createReducer(
  initialState as FullEntityState,
  on(loadFormatGroup, (state) => ({
    ...state,
    loading: true,
    entity: null,
  })),
  on(loadFormatGroupComplete, (state, {entity}) => ({
    ...state,
    loading: false,
    entity: entity.fgEntity,
  })),
  on(loadFormatGroupFailure, (state, {error}) => ({
    ...state,
    loading: false,
    error,
  })),
  on(loadFormatGroupByRecordIdFailure, (state, {error}) => ({
    ...state,
    loading: false,
    error,
  })),
  on(loadFormatGroupByIdentifierFailure, (state, {error}) => ({
    ...state,
    loading: false,
    error,
  })),
  on(loadEntity, (state, {entity}) => ({
    ...state,
    loading: true,
    entity: entity.hasSameId ? state.entity : null,
  })),
  on(loadEntityComplete, (state, {entity}) => ({
    ...state,
    loading: false,
    entity,
  })),
  on(loadEntityFailure, (state, {error}) => ({
    ...state,
    loading: false,
    error,
  })),
  on(loadFormatGroupAboutInfo, (state, {id}) => ({
    ...state,
    instanceAboutInfo: state.instanceAboutInfo.set(id, {
      loading: true,
    }),
  })),
  on(loadFormatGroupAboutInfoComplete, (state, {results}) => ({
    ...state,
    instanceAboutInfo: results.reduce((acc: Immutable.Map<string, SearchResultResponse>, cur: SearchResult) => {
      return acc.set(cur.id, {
        searchResult: cur,
        loading: false,
      });
    }, state.instanceAboutInfo),
  })),
  on(loadRelatedItems, (state) => ({
    ...state,
    availability: {
      ...state.availability,
      loading: true,
      items: [],
    }
  })),
  on(loadRelatedItemsComplete, (state, {relatedItems}) => ({
    ...state,
    availability: {
      ...state.availability,
      loading: false,
      totalResults: relatedItems.length,
      items: relatedItems,
    }
  })),
  on(loadRelatedItemsFailure, (state, {error}) => ({
    ...state,
    availability: {
      ...state.availability,
      loading: false,
      error,
    },
  })),
  on(loadRelatedItemsOfEditionComplete, (state, {materialTabIndex, editionIndex, relatedItems}) => {
    if (!(state.entity as FormatGroup).materialTabs) {
      return state;
    }

    return {
      ...state,
      entity: {
        ...state.entity,
        materialTabs: (state.entity as FormatGroup).materialTabs.map((materialTab, idx) => {
          if (materialTabIndex != idx) {
            return materialTab;
          }

          return {...materialTab,
            editions: materialTab.editions.map((edition, editionIdx) => {
              if (editionIndex !== editionIdx) {
                return edition;
              }

              return {
                ...edition,
                availability: {
                  ...edition?.availability,
                  items: relatedItems,
                  expanded: true,
                  loading: false
                }
              };
            })};
        })
      }
    } as FullEntityState;
  }),
  on(loadRelatedItemsOfEditionFailure, (state, {materialTabIndex, editionIndex, error}) => {
    return state;
  }),
  on(updateEditionAvailabilityState, (state, {materialTabIndex, editionIndex, expanded, loading}) => {
    if (!(state.entity as FormatGroup).materialTabs) {
      return state;
    }

    return {
      ...state,
      entity: {
        ...state.entity,
        materialTabs: (state.entity as FormatGroup).materialTabs.map((materialTab, idx) => {
          if (materialTabIndex != idx) {
            return materialTab;
          }

          return {...materialTab,
            editions: materialTab.editions.map((edition, editionIdx) => {
              if (editionIndex !== editionIdx) {
                return edition;
              }

              return {
                ...edition,
                availability: {
                  ...edition?.availability,
                  expanded,
                  loading
                }
              };
            })};
        })
      }
    } as FullEntityState;
  }),
  on(queryEditions, (state, {query}) => ({
    ...state,
    editionsQuery: query
  })),
);

export function reducer(state: FullEntityState, action: Action) {
  return fullEntityReducer(state, action);
}

export const fullEntityFeatureKey = 'fullEntity';
export const fullEntityState = createFeatureSelector<FullEntityState>(fullEntityFeatureKey);

export const selectEntity = createSelector(
  fullEntityState,
  (state: FullEntityState): FormatGroup | Entity => state.entity,
);

export const selectInstanceAboutInfo = createSelector(
  fullEntityState,
  (state: FullEntityState): any => state.instanceAboutInfo.toJS(),
);

export const selectAvailability = createSelector(
  fullEntityState,
  (state: FullEntityState): AvailabilityState => state.availability,
);

export const selectAvailabilityLoading = createSelector(
  selectAvailability,
  (state: AvailabilityState): boolean => state.loading,
);

export const getEditionsQuery = createSelector(fullEntityState, (state: FullEntityState) => {
  return state.editionsQuery;
});

