<app-bookshelf-template>
  <ng-template bookshelfTemplateTitle>
    <div class="d-flex flex-nowrap px-1" data-automation-id="reading-history-title">
      <div class="text-truncate d-block" role="heading" aria-level="1" translate>ReadingHistoryViewTitle</div>
      <div *ngIf="readingHistoryEnabled && pagination?.totalResults" class="w-auto">
        &nbsp;| {{ pagination?.totalResults }}
      </div>
    </div>
  </ng-template>
  <ng-template bookshelfTemplateSubheaderActions>
    <div class="reading-history-subheader-container d-flex justify-content-between w-100">
      <div class="reading-history-search-bar d-inline-block py-1 ml-4 ml-sm-1">
        <app-bookshelf-search-bar [placeholder]="'bookshelfSearch' | translate" (onSearch)="search($event)">
        </app-bookshelf-search-bar>
      </div>

      <div
        *ngIf="sortBy"
        class="d-inline-block reading-history-dropdown"
        ngbDropdown
        #sortDropdown="ngbDropdown"
        [autoClose]="'outside'">
        <button
          class="bookmark__header__button"
          ngbDropdownToggle
          data-automation-id="reading-history-action-button-sort-by"
          aria-haspopup="menu"
          type="button">
          <ng-container
            [ngTemplateOutlet]="sortLabel"
            [ngTemplateOutletContext]="{field: sortBy.field, order: sortBy.order, prefix: true}">
          </ng-container>
        </button>
        <div role="menu" ngbDropdownMenu data-automation-id="reading-history-sort-by-dropdown" class="menu">
          <button
            *ngFor="let item of [['checkoutDate', 'asc'], ['checkoutDate', 'desc'], ['title', 'asc'], ['title', 'desc'], ['author', 'asc'], ['author', 'desc']]"
            (click)="setSort(item[0], item[1], sortDropdown)"
            class="d-block py-2 px-3 w-100 h-auto sort-by-dropdown-button"
            data-automation-id="reading-history-sort-by-dropdown-button"
            ngbDropdownItem
            role="menuitem">
            <ng-container
              [ngTemplateOutlet]="sortLabel"
              [ngTemplateOutletContext]="{field: item[0], order: item[1]}">
            </ng-container>
          </button>
        </div>
      </div>

      <ng-template #sortLabel let-field="field" let-order="order" let-prefix="prefix">
          <ng-container *ngIf="prefix">{{ 'bookmarksSortBy' | translate }}</ng-container>
          {{ sortTranslateKeys[field][order] | translate }}
      </ng-template>
    </div>
  </ng-template>

  <ng-container *ngIf="readingHistoryEnabled">
    <ng-template *ngIf="readingHistory?.list?.length" bookshelfTemplateBody="ps-medium" let-psScroll="psScroll">
      <div
        infiniteScroll
        *ngIf="!readingHistory?.itemsLoadingState.firstPageLoading; else firstPageLoading"
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [infiniteScrollContainer]="psScroll"
        [infiniteScrollDisabled]="!psScroll || readingHistory?.itemsLoadingState.loading || allReadingHistoryItemsLoaded"
        (scrolled)="loadMoreItems()">
        <app-bookshelf-items-list
          [bookshelfItems]="readingHistory?.list"
          [ariaLabel]="'bookmarksListItemsListTitle' | translate"
          [isExtendedCard]="true"
          [hideCheckbox]="true">
        </app-bookshelf-items-list>
        <ng-container
          [ngTemplateOutlet]="itemsLoading"
          [ngTemplateOutletContext]="{state: readingHistory?.itemsLoadingState}">
        </ng-container>
      </div>
      <ng-template #firstPageLoading>
        <ng-container
          *ngIf="readingHistory?.itemsLoadingState.firstPageLoading"
          [ngTemplateOutlet]="itemsLoading"
          [ngTemplateOutletContext]="{state: readingHistory?.itemsLoadingState}">
        </ng-container>
      </ng-template>
    </ng-template>

    <ng-template *ngIf="!readingHistory?.list?.length" bookshelfTemplateBody="no-ps">
      <div
        *ngIf="!readingHistory?.itemsLoadingState.loading && !readingHistory?.itemsLoadingState.error"
        class="d-flex h-100 flex-column align-items-center justify-content-center mt-5">
        <span
          class="no-items-message text-center mx-4"
          data-automation-id="reading-history-empty-message"
          translate>
          {{(searchText ? 'ReadingHistoryNoResultsFound' : 'ReadingHistoryEmptyMessage') }}
        </span>
      </div>
      <ng-container
        [ngTemplateOutlet]="itemsLoading"
        [ngTemplateOutletContext]="{state: readingHistory?.itemsLoadingState}">
      </ng-container>
    </ng-template>

    <ng-template #itemsLoading let-state="state">
      <div class="d-flex justify-content-center text-white" role="status" aria-live="polite">
        <div
          *ngIf="state?.loading"
          class="mt-5 spinner-border list-items-loading-spinner"
          data-automation-id="reading-history-loader">
          <span class="sr-only" translate>Loading...</span>
        </div>
      </div>
      <div
        class="d-flex h-100 flex-column align-items-center justify-content-center"
        aria-atomic="true"
        aria-live="assertive"
        role="alert">
        <div *ngIf="state?.error" class="no-items-message m-4 text-white" data-automation-id="reading-history-error">
          {{ state.error !== conflictServerError ? ('generalServerError' | translate)
          : ('ReadingHistoryLoadingMessage' | translate) }}
        </div>
      </div>
    </ng-template>
  </ng-container>

  <ng-template *ngIf="!readingHistoryEnabled" bookshelfTemplateBody="no-ps">
    <div class="d-flex h-100 flex-column align-items-center justify-content-center">
      <span class="no-items-message text-center mx-4" data-automation-id="reading-history-opt-in-message" translate>
        ReadingHistoryOptInMessage
      </span>
      <button
        (click)="optInToRH()"
        class="opt-in-btn btn-lg mt-3"
        data-automation-id="reading-history-opt-in-btn"
        translate>
        ReadingHistoryOptInBtn
      </button>
    </div>
  </ng-template>
</app-bookshelf-template>
