import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { toMessageObjectsArray } from 'shared/utils/unify-error';
import { FormattedVendor } from '../../../../entity/models/econtent';
import { VendorType } from '../../../../entity/models/entity';

@Component({
  selector: 'app-over-drive-get-it-failed-dialog',
  templateUrl: './over-drive-get-it-failed-dialog.component.html',
  styleUrls: ['./over-drive-get-it-failed-dialog.component.scss'],
})
export class OverDriveGetItFailedDialogComponent implements OnInit {
  @Input() public errorsResponse: HttpErrorResponse;
  @Input() public vendorType: VendorType;
  public vendorName: string;
  public errors: string;
  public faExclamationCircle = faExclamationCircle;

  constructor(public activeModal: NgbActiveModal) {
  }

  public ngOnInit(): void {
    this.errors = toMessageObjectsArray(this.errorsResponse);
    this.vendorName = FormattedVendor[this.vendorType];
  }
}
