import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isAuthorizedInKeycloak, isStaffAuthorizedInKeycloak } from 'app/keycloak/reducers/keycloak.reducer';
import { WindowRefService } from 'app/services/window-ref.service';

import { ApiVersionService } from 'core/services/api-version.service';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { State } from 'search/reducers/root.reducer';
import { getUserHomeLibraryCode, UserState } from 'user/reducers/user.reducer';
import { CustomDomainLoader } from '../../services/custom-domain-loader';
import { FeatureToggleService } from '../../services/feature-toggle.service';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  private subscriptions = new Subscription();
  private isPatronAuthorized: boolean;
  private isStaffAuthorized: boolean;
  private userHomeLibraryCode: string;
  private readonly anonymousHeaderName = 'Anonymous-User-Id';
  private readonly domain: string;

  constructor(
    private readonly windowRef: WindowRefService,
    private readonly apiVersionService: ApiVersionService,
    private readonly store: Store<State>,
    private readonly userStore: Store<UserState>,
    private readonly customDomainLoader: CustomDomainLoader,
    private readonly router: Router,
    private readonly featureToggleService: FeatureToggleService,
  ) {
    this.domain = customDomainLoader.domain;

    this.subscriptions.add(this.store.select(isAuthorizedInKeycloak).subscribe((isPatronAuthorized) => {
      this.isPatronAuthorized = isPatronAuthorized;
    }));

    this.subscriptions.add(this.store.select(isStaffAuthorizedInKeycloak).subscribe((isStaffAuthorized) => {
      this.isStaffAuthorized = isStaffAuthorized;
    }));

    this.subscriptions.add(this.userStore.select(getUserHomeLibraryCode).subscribe((userHomeLibraryCode) => {
      this.userHomeLibraryCode = userHomeLibraryCode;
    }));
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let apiReq = req;
    let headers = this.addVersion(req.headers, req.url)
    .set('iii-customer-domain', this.domain)
    .set('iii-host-domain', this.windowRef.host());

    if (!(this.isPatronAuthorized || this.isStaffAuthorized)) {
      headers = this.addAnonymouseHeader(headers);
    }

    if (this.userHomeLibraryCode && req.url.startsWith('api/search-result')) {
      headers = this.addUserHomeLibraryCode(headers);
    }

    if (!apiReq.url.startsWith('http')) {
      apiReq = apiReq.clone({
        url: `${API_URL}/${req.url}`,
        headers: (
          req.url.match(/gates-edge/)
            ? headers
            .set('X-III-SITE-CODE', this.domain.split('.')[0])
            : headers
        ),
      });
    }

    return next.handle(apiReq).pipe(catchError(this.interceptResponseError.bind(this)));
  }

  private interceptResponseError (error: HttpErrorResponse) {
    const maintenancePageFeatureEnabled = this.featureToggleService.getToggles()['DIS-31747_2024-06-01_patron_ui_maintenance_page'];

    if (maintenancePageFeatureEnabled && [HttpStatusCode.ServiceUnavailable].includes(error.status)) {
      this.router.navigate([`/${error.status}`]);
    }

    return throwError(error);
  }

  private addVersion(headers: HttpHeaders, url: string): HttpHeaders {
    return headers.has('api-version') ? headers : headers.set('api-version', this.apiVersionService.getVersion(url));
  }

  private addUserHomeLibraryCode(headers: HttpHeaders): HttpHeaders {
    return headers.has('iii-user-home-library-code') ?
      headers :
      headers.set('iii-user-home-library-code', this.userHomeLibraryCode);
  }

  private addAnonymouseHeader(headers: HttpHeaders): HttpHeaders {
    const existedId = localStorage.getItem(this.anonymousHeaderName);
    if (!existedId) {
      const newId = this.generateUUID();
      localStorage.setItem(this.anonymousHeaderName, newId);
      return headers.set(this.anonymousHeaderName, newId);
    }
    return headers.set(this.anonymousHeaderName, existedId);
  }

  private generateUUID(): string {
    const UUID_TEMPLATE = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    return UUID_TEMPLATE.replace(/[xy]/g, (c) => {
      // tslint disabled as this approach of generating UUID is more preferable than including dependency of 'uuid' package
      // eslint-disable-next-line
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
