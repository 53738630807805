<div class="p-4">
  <app-modal-header
    [title]="'getItErrorDialogTitle' | translate"
    titleId="get-it-error-dialog-title"
    closeId="close-get-it-dialog-btn"
    (close)="onClose()">
  </app-modal-header>
  <div class="modal-body">
    <div class="d-flex">
      <div>
        <span class="fas fa-exclamation-circle fa-4x warn-icon" aria-hidden="true"></span>
      </div>
      <div class="pl-4" data-automation-id="get-it-error-dialog-message">
        <p translate>
          requestForMaterialUnsuccessful
        </p>
        <p data-automation-id="get-it-error-message">
          {{message}}
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      data-automation-id="get-it-error-dialog-ok-button"
      class="ins-buttons button-primary flint"
      (click)="onClose()"
      translate>
      ok
    </button>
  </div>
</div>
