import { Action, createFeatureSelector, createReducer, createSelector, on, } from '@ngrx/store';
import { setFirstNavigationId, updateCurrentNavigationId } from '../actions/history.actions';

export interface HistoryState {
  firstNavId: number;
  currentNavId: number;
}

export const initialState: HistoryState = {
  firstNavId: 1,
  currentNavId: 1,
};

const historyReducer = createReducer(
  initialState,
  on(updateCurrentNavigationId, (state, {navId}) => ({
    ...state,
    currentNavId: navId,
  })),
  on(setFirstNavigationId, (state, {navId}) => ({
    ...state,
    firstNavId: navId,
  })),
);

export function reducer(state: HistoryState | undefined, action: Action) {
  return historyReducer(state, action);
}

export const selectHistory = createFeatureSelector<HistoryState>(
  'history',
);

export const getIsFirstPage = createSelector(
  selectHistory,
  (state: HistoryState) => (state) ? state.firstNavId === state.currentNavId : null,
);

export const getFirstPageId = createSelector(
  selectHistory,
  (state: HistoryState) => state?.firstNavId,
);
