<div class="dynamic-showcase-search p-4">
  <div class="d-flex align-items-center flex-wrap">
    <span class="dynamic-showcase-search__term" data-automation-id="saved-search-term">
      “{{openedShowcase.searchText}}”&nbsp;
    </span>
    <span *ngIf="openedShowcase.facets.length" class="mx-2" data-automation-id="search-data-divider" translate>
      andBooleanOperator
    </span>
    <app-search-facets-bubble
      *ngFor="let facet of openedShowcase.facets"
      [facet]="facet"
      [isReadonly]="true"
    ></app-search-facets-bubble>
    <span data-automation-id="search-data-sorting" class="dynamic-showcase-search__sorting">
      {{'sortedBy' | translate}}
      <span class="dynamic-showcase-search__sorting--bold">{{metadataSortOrderTranslation[openedShowcase.sorting] | translate}}</span>
    </span>
  </div>
  <button
    (click)="runSavedSearch()"
    class="ins-buttons button-primary flint mt-2"
    translate
    data-automation-id="dynamic-showcase-run-search">
    <span class="icon fas fa-search mr-2" aria-hidden="true"></span>
    customShowcaseRunSavedSearch
  </button>
</div>

