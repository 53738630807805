import { ChangeDetectorRef, Component, ContentChild, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, } from '@angular/core';
import { select, Store, } from '@ngrx/store';
import { combineLatest, Subscription, } from 'rxjs';
import { State } from 'search/reducers/root.reducer';
import {
  getCheckoutsCards,
  getOverDriveCheckedOutIds,
  getPhysicalCheckedOutIds,
  getRequestedHoldsIds,
} from 'user/reducers/user.reducer';
import { VendorInfo, VendorType } from '../models/entity';
import { Vendor } from 'user/models/vendor';
import { CheckoutCard, OverdriveCheckout } from 'user/models/checkouts';

@Component({
  selector: 'app-checked-and-held-items-update-container',
  template: '<ng-container *ngTemplateOutlet="childTemplate; ' +
      'context:{heldCount: heldCount, checkedOutCount: checkedOutCount}"></ng-container>',
})
export class CheckedAndHeldItemsUpdateContainer implements OnInit, OnChanges {

  @ContentChild(TemplateRef, { static: true })
  public childTemplate: TemplateRef<{ heldCount: boolean, checkedOutCount: boolean }>;

  @Input() public recordIds: string[];
  @Input() public instanceIds: string[];
  @Input() public vendors: Partial<Record<VendorType, VendorInfo>>;
  @Input() public hasEcontentIssues: boolean;

  public checkoutCards: CheckoutCard[];
  public heldCount: number;
  public checkedOutCount: number;

  private vendorReserveIds: string[];
  private checkedOutOverdriveIds: string[] = [];
  private checkedOutPhysicalIds: string[] = [];
  private holdsIds: string[] = [];
  private subscriptions = new Subscription();

  public constructor(
    private readonly store: Store<State>,
    private readonly cdRef: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {
    this.updateVendorReserveIds();
    this.subscriptions.add(combineLatest([
        this.store.pipe(select(getRequestedHoldsIds)),
        this.store.pipe(select(getOverDriveCheckedOutIds)),
        this.store.pipe(select(getCheckoutsCards)),
        this.store.pipe(select(getPhysicalCheckedOutIds)),
      ],
    ).subscribe(([holdsIds, checkedOutOverdriveIds, checkouts, checkedOutPhysicalIds]) => {
      this.checkoutCards = checkouts;
      this.holdsIds = holdsIds;
      this.checkedOutOverdriveIds = checkedOutOverdriveIds;
      this.checkedOutPhysicalIds = checkedOutPhysicalIds;

      this.heldCount = this.countHeldResources();
      this.checkedOutCount = this.countCheckedOutResources();
      this.cdRef.markForCheck();
    }));
  }

  private convertToLowerCase(stringArray: string[]): string[] {
    return (stringArray ?? []).map(str => str?.toLowerCase());
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes?.recordIds || changes?.instanceIds || changes?.vendors) {
      this.updateVendorReserveIds();
      this.heldCount = this.countHeldResources();
      this.checkedOutCount = this.countCheckedOutResources();
    }
  }

  private countHeldResources(): number {
    return this.intersectionCount(this.holdsIds, this.instanceIds);
  }

  private countCheckedOutResources(): number {
    const physicalRequestsCount = this.intersectionCount(this.checkedOutPhysicalIds, this.instanceIds);
    const electronicRequestsCount =
      this.hasEcontentIssues
      ? this.countCheckoutsForIssues(this.checkoutCards)
      : this.intersectionCount(this.checkedOutOverdriveIds, this.vendorReserveIds);
    return physicalRequestsCount + electronicRequestsCount;
  }

  private intersectionCount(source: string[], target: string[]): number {
    const targetLowerCase = this.convertToLowerCase(target);
    return source?.reduce((acc, id) => {
      if (targetLowerCase.includes(id?.toLowerCase())) {
        acc++;
      }
      return acc;
    }, 0) ?? 0;
  }

  private updateVendorReserveIds() {
    this.vendorReserveIds = Object.values(VendorType)
     .flatMap(vendor => this.vendors?.[vendor]?.reserveId || []);
  }

  public countCheckoutsForIssues(checkouts: CheckoutCard[]): number {
    let totalCount = 0;

    checkouts?.forEach(checkout => {
      const parentMagazineReferenceId = (checkout.receipt as OverdriveCheckout)[Vendor.OVERDRIVE]?.parentMagazineReferenceId?.toLowerCase();
      if (this.vendorReserveIds.includes(parentMagazineReferenceId)) {
        totalCount++;
      }
    });

    return totalCount;
  }
}
