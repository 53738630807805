import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  loadSavedSearches,
  runSavedSearch,
  stopFocusAvailableCardOrEmptyResultMessage
} from 'app/saved-search/actions/saved-search.actions';
import { Subscription } from 'rxjs';
import { SavedSearch } from '../../models/saved-search';
import {
  getIsFocusPending,
  getLoadState,
  getSavedSearches,
  LoadState,
  SavedSearchState
} from '../../reducers/saved-search.reducer';
import { SavedSearchCardComponent } from '../saved-search-card/saved-search-card.component';

@Component({
  selector: 'app-saved-searches-list',
  templateUrl: './saved-searches-list.component.html',
  styleUrls: ['./saved-searches-list.component.scss'],
})
export class SavedSearchesListComponent implements OnInit, OnDestroy {
  public loadState: LoadState | null;
  public savedSearches: SavedSearch[] | null;

  @ViewChildren(SavedSearchCardComponent) private cards: QueryList<SavedSearchCardComponent>;
  @ViewChild('zeroSearches') private zeroSearches: ElementRef;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly store: Store<SavedSearchState>,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {

    this.subscriptions.add(
      this.store.select(getLoadState).subscribe((loadState) => {
        this.loadState = loadState;
        this.changeDetectorRef.detectChanges();

        if (!loadState || (!loadState.loaded && !loadState.loading && !loadState.error)) {
          this.store.dispatch(loadSavedSearches());
        }
      }),
    );

    this.subscriptions.add(
      this.store.select(getSavedSearches).subscribe((savedSearches) => {
        this.savedSearches = savedSearches;
        this.changeDetectorRef.detectChanges();
      }),
    );

    this.subscriptions.add(
      this.store.select(getIsFocusPending).subscribe((isFocusPending) => {
        if (isFocusPending) {
          this.store.dispatch(stopFocusAvailableCardOrEmptyResultMessage());
          const focusRef = this.cards?.length ? this.cards.first : this.zeroSearches.nativeElement;
          focusRef.focus();
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public trackById(index: number, savedSearch: SavedSearch): string {
    return savedSearch.id;
  }

  public runSavedSearch(savedSearchId: string) {
    this.store.dispatch(runSavedSearch({ savedSearchId }));
  }
}
