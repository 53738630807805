import { EntityTypes } from '../../entity/models/entity';

export interface ShareItSubjectResponse {
  title: string;
}

export enum ShareItShareEntityType {
  SAVED_SEARCH = 'savedSearch',
  SHOWCASE = 'showcase',
  LIST = 'personalList',
  RESOURCE = 'resource',
}

interface ShareItShareResourceBase<T extends ShareItShareEntityType, A = undefined> {
  id?: string;
  type: T;
  attributes: A;
}

export interface ShareItSavedSearchResourceAttributes {
  id?: string;
  url: string;
  title?: string;
  description?: string;
  author?: string;
  publicationDate?: string;
  resourceType?: EntityTypes;
  resourceName?: string;
}

export type ShareItSavedSearchResource = ShareItShareResourceBase<ShareItShareEntityType, ShareItSavedSearchResourceAttributes>;
export type ShareItShowcaseResource = ShareItShareResourceBase<ShareItShareEntityType, ShareItSavedSearchResourceAttributes>;

export type ShareItResource = ShareItSavedSearchResource;

export interface ShareItShareBody {
  to: string;
  subject: string;
  resource: ShareItResource;
}

export interface EmailSavedSearchError {
  notFound: boolean;
}
