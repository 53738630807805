<div
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollDisabled]="!scrollContainer || !hasMoreItems || loadState.loading"
  [infiniteScrollThrottle]="50"
  (scrolled)="moreItemsRequested.emit()"
  [infiniteScrollContainer]="scrollContainer">
  <ng-container *ngIf="showcases">
    <ol *ngIf="showcases.length; else noCustomShowcases"
        class="d-flex flex-wrap justify-content-center justify-content-md-start mb-0"
        data-automation-id="custom-showcases-list"
        [attr.aria-label]="'customShowcasesListTitle' | translate">
      <li *ngFor="let showcase of showcases; trackBy: trackById"
          class="d-block m-2 m-md-4"
          data-automation-id="bookmarks-custom-showcase-card">
        <app-custom-showcase-card [showcase]="showcase"
                                  [isShowcaseCreator]="userId === showcase.creator.id"
                                  (onShowcaseOpened)="openShowcase(showcase)"></app-custom-showcase-card>
      </li>
    </ol>
  </ng-container>

  <div *ngIf="!loadState.loading" aria-atomic="true" aria-live="polite" role="status">
    <span class="sr-only" data-automation-id="custom-showcases-results-count">
      <span translate [translateParams]="{value: resultsCount}">
        customShowcasesResults
      </span>
      <span *ngIf="searchText" translate [translateParams]="{search: searchText}">
        customShowcasesFoundForMessage
      </span>
    </span>
  </div>

  <p *ngIf="loadState.errorCode"
     role="alert"
     aria-live="assertive"
     aria-atomic="true"
     class="m-4 text-white"
     translate
     data-automation-id="custom-showcases-error-message">
    generalServerError
  </p>
  <div *ngIf="loadState.loading" class="d-flex justify-content-center pt-5">
    <div class="spinner-border text-primary"
         style="width: 7rem; height: 7rem;"
         role="status"
         aria-live="polite"
         data-automation-id="custom-showcases-spinner">
      <span class="sr-only" translate>Loading...</span>
    </div>
  </div>
</div>

<ng-template #noCustomShowcases>
  <div #zeroCustomShowcases
       *ngIf="loadState.loaded"
       class="m-4 text-white"
       data-automation-id="custom-showcases-status-message"
       tabindex="-1">
    <p>{{(searchText ? 'customShowcasesNoResultsFound' : 'youHaveZeroCustomShowcases') | translate}}</p>
  </div>
</ng-template>
