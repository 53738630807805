import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { createList, resetListCreatedState } from '../../../list/actions/list.actions';
import { getCreateState } from '../../../list/reducers/list.reducer';

@Component({
  selector: 'app-create-list',
  templateUrl: './create-list.component.html',
  styleUrls: ['./create-list.component.scss'],
})
export class CreateListComponent implements OnInit, OnDestroy {
  @Output() public onCancel = new EventEmitter<void>();
  @Output() public onCreate = new EventEmitter<string>();
  @ViewChild('newListNameInput', {static: true}) public newListNameInput: ElementRef;
  public createListForm: UntypedFormGroup;
  public isCreatingList: boolean;

  private isCreatedList: boolean;
  private readonly subscription = new Subscription();

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly store: Store,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.store.dispatch(resetListCreatedState());
    this.createListForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
    });
  }

  public ngOnInit(): void {
    this.newListNameInput.nativeElement.focus({preventScroll: true});

    this.subscription.add(
      this.store.select(getCreateState).subscribe((createState) => {
        if (createState.error) {
          if (createState.error.status === 409) {
            this.name.setErrors({duplicate: 'List name duplicate'});
          } else if (createState.error.status === 422) {
            this.createListForm.setErrors({limit: 'Limit of lists exceeded'});
          } else {
            this.createListForm.setErrors({
              unknown: {message: 'General server error', originalMessage: createState.error.message},
            });
          }
        }

        if (createState.createdListId) {
          this.onCreate.emit(createState.createdListId);
          this.store.dispatch(resetListCreatedState());
          this.isCreatedList = true;
        }

        this.isCreatingList = createState.loading || this.isCreatedList;
        this.cdr.markForCheck();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public submit(): void {
    this.name.markAsTouched();
    if (!this.createListForm.valid || this.isCreatingList) {
      return;
    }

    this.store.dispatch(createList({name: this.name.value}));
  }

  public cancel(): void {
    this.onCancel.emit();
  }

  public get name(): AbstractControl | null {
    return this.createListForm.get('name');
  }
}
