import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'search/reducers/root.reducer';
import { tryToGetItTab } from '../../actions/entity.actions';

@Component({
  selector: 'app-get-it-button',
  templateUrl: './get-it-button.component.html',
  styleUrls: ['./get-it-button.component.scss'],
})
export class GetItButtonComponent {
  @Input() public resourceId: string;
  @Input() public tabName: string;
  @Input() public ariaDescribedBy = '';

  constructor(
    private readonly store: Store<State>,
  ) { }

  public onGetItRollUp(event: Event) {
    event.stopPropagation();
    this.store.dispatch(tryToGetItTab({cardInfo: {id: this.resourceId, tabName: this.tabName}}));
  }
}
