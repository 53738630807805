import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NlbItemLocatorService} from '../../services/nlb-item-locator.service';
import {
  NlbCollectionToUsageLevelMap,
  NlbItemLocation,
  NlbItemLocationRequest,
  NlbItemLocatorOperationMode,
} from '../../models/nlb-item-locator';
import {Subscription} from 'rxjs';
import {NlbLibraryList} from '../../../nlb-library-list';

@Component({
  selector: 'app-nlb-item-locator',
  templateUrl: './nlb-item-locator.component.html',
  styleUrls: ['./nlb-item-locator.component.scss']
})
export class NlbItemLocatorComponent implements OnInit, OnDestroy {
  @Input() public recordId: string;
  @Input() public collectionLocationName: string;
  @Input() public callNumber: string;
  @Input() public title: string;
  @Input() public locationLabel: string;
  @Input() public mode: number = NlbItemLocatorOperationMode.loadOnMount;
  @Input() public fgId: string;
  @Input() public tabName: string;
  @Input() public codes: string[];
  @Input() public language: string;
  @Input() public loadOnMount: boolean;

  public usageLevelCode: string;
  public locationCode: string;
  public loading: boolean = true;
  public error: boolean = false;

  private itemLocationRequest: NlbItemLocationRequest;
  public itemLocation: NlbItemLocation;
  private readonly subscriptions = new Subscription();
  public static readonly suffixRegex = /([^-]*)(?=(\s(-\[.*\])))/;

  public locationDescription: string;

  constructor(
    private readonly nlbItemLocatorService: NlbItemLocatorService,
  ) { }

  ngOnInit(): void {
    this.usageLevelCode = NlbCollectionToUsageLevelMap[this.collectionLocationName];
    this.locationCode = NlbLibraryList.find((library) => library.name === this.locationLabel)?.abbreviation;
    this.loadLocationConditionally();
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public loadLocationConditionally() {
    this.buildItemLocationRequest();
    if (this.mode == NlbItemLocatorOperationMode.loadOnMount) {
      this.loadLocation();
    }
  }

  public loadLocation() {
    this.loading = true;
    this.nlbItemLocatorService.loadItemLocation(this.itemLocationRequest)
      .subscribe((payload) => {
        if ((payload as any).error) {
          this.loading = false;
          return this.error = true;
        }
        this.itemLocation = payload;
        const { tier, tier1, tier2, level, shelf } = this.itemLocation;
        const tiers = [tier, tier1, tier2].filter((t) => t).join('/');
        const position = `Level ${level}/Shelf ${shelf}`;
        this.locationDescription = `${tiers} ${position} `;
        this.loading = false;
      }, () => {
        this.loading = false;
        return this.error = true;
      });
  }

  public buildItemLocationRequest() {
    const [,callNumber,, suffix] = this.callNumber?.match(NlbItemLocatorComponent.suffixRegex) ?? [];
    this.itemLocationRequest = {
      itemId: this.recordId,
      locationCode: this.locationCode,
      usageLevelCode: this.usageLevelCode,
      language: this.language,
      callNumber: this.callNumber,
      title: this.title,
      ...(suffix && callNumber && {suffix, callNumber})
    };
  }
}
