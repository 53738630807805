import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss'],
})
export class InformationModalComponent {
  @Input() public titleTranslateKey = '';
  @Input() public bodyTranslateKey: string;

  constructor(private readonly activeModal: NgbActiveModal) {
  }

  public onClose(): void {
    this.activeModal.dismiss();
  }

  public onSubmit(): void {
    this.activeModal.close();
  }
}
