import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { SitePermissions } from './models/permissions';
import { PermissionService } from './services/permission.service';
import { Subscription, filter } from 'rxjs';
import { FeatureToggleService } from 'app/services/feature-toggle.service';
import { ConfigurationLoader } from 'shared/configuration-loader';

@Directive({
  selector: '[authorizationDirective]',
})

export class AuthorizationDirective implements OnInit, OnDestroy {
  @Input() authorizationDirective: { permission: SitePermissions};

  private hasPermission: boolean = false;
  private subscription: Subscription = new Subscription();
  public isAuthPatronFlagEnabled: boolean;
  public contentRendered: boolean = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private permissionService: PermissionService,
    private readonly featureToggleService: FeatureToggleService,
    private cdr: ChangeDetectorRef,
    private readonly configLoader: ConfigurationLoader,
  ) {}

  public ngOnInit(): void {
    this.isAuthPatronFlagEnabled = this.featureToggleService.getToggles()['DIS-30793_2024-04-27_auth_patron'];
    this.subscription = this.permissionService.permissionsUpdated$.pipe(
      filter((value) => value !== null))
      .subscribe(() => {
        this.applyPermission();
        this.cdr.detectChanges();
      });
  }

  private calculateSite(): string {
    const siteId = this.configLoader.siteId;
    const currentSiteResource = siteId ? `site-${siteId}` : 'main-site';
    return currentSiteResource;
  }

  private applyPermission(): void {
    if (!this.isAuthPatronFlagEnabled) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      return;
    }
    const currentSiteResource = this.calculateSite();

    const { permission } = this.authorizationDirective;
    this.hasPermission = this.permissionService.hasPermission(currentSiteResource, permission);

    if (this.hasPermission && !this.contentRendered) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.contentRendered = true;
    } else if (!this.hasPermission && this.contentRendered) {
      this.viewContainerRef.clear();
      this.contentRendered = false;
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
