const ITEM_LANGUAGE_CODE_MAP = [
  { id: '1', name: 'English' },
  { id: '2', name: 'Spanish' },
  { id: '3', name: 'German' },
  { id: '4', name: 'French' },
  { id: '5', name: 'Italian' },
  { id: '6', name: 'Hebrew' },
  { id: '7', name: 'Hungarian' },
  { id: '8', name: 'Chinese' },
  { id: '9', name: 'Polish' },
  { id: '10', name: 'Korean' },
  { id: '11', name: 'Japanese' },
  { id: '12', name: 'Arabic' },
  { id: '13', name: 'Greek' },
  { id: '14', name: 'Romanian' },
  { id: '15', name: 'Portuguese' },
  { id: '16', name: 'Vietnamese' },
  { id: '17', name: 'Russian' },
  { id: '18', name: 'Albanian' },
  { id: '19', name: 'Armenian' },
  { id: '20', name: 'Bengali' },
  { id: '21', name: 'Bosnian' },
  { id: '22', name: 'Bulgarian' },
  { id: '23', name: 'Czech' },
  { id: '24', name: 'Dutch' },
  { id: '25', name: 'Gujarati' },
  { id: '26', name: 'Hindi' },
  { id: '27', name: 'Kannada' },
  { id: '28', name: 'Marathi' },
  { id: '29', name: 'Persian' },
  { id: '30', name: 'Panjabi' },
  { id: '31', name: 'Serbian' },
  { id: '32', name: 'Tamil' },
  { id: '33', name: 'Telugu' },
  { id: '34', name: 'Thai' },
  { id: '35', name: 'Urdu' },
  { id: '36', name: 'Hawaiian' },
  { id: '37', name: 'Haitian Creole' },
  { id: '38', name: 'Somali' },
  { id: '39', name: 'Tagalog' },
  { id: '40', name: 'Filipino' },
  { id: '41', name: 'Khmer' },
  { id: '42', name: 'Malay' },
  { id: '43', name: 'Other' },
  { id: '44', name: 'Catalan' },
  { id: '45', name: 'Occitan' },
  { id: '46', name: 'Swedish' }
];
export default ITEM_LANGUAGE_CODE_MAP;
