import { Facet } from 'search/facets/models/resource-facet';

export interface IterableFacetData {
  totalResults: number;
  page: number;
  totalPages: number;
  data: Facet[];
}

export type FacetResponseData = IterableFacetData | Partial<Facet>;

export const defaultIterableFacetData: IterableFacetData = {
  totalResults: 0,
  page: 0,
  totalPages: 0,
  data: [],
};
