<div class="issues-list-modal-container p-4">
  <div data-automation-id="issues-list-header">
    <app-modal-header
      title="{{'selectIssueCopyTitle' | translate}}"
      [subtitle]="issues[0]?.title"
      titleId="issues-list-title"
      closeId="close-issues-list-dialog-btn"
      (close)="onClose()">
    </app-modal-header>
  </div>

  <div class="modal-body">
    <table class="table table-hover table-bordered" data-automation-id="issue-list-table">
      <thead class="thead thead-light">
        <tr class="d-flex">
          <th class="table-header issue-table-data align-middle pl-sm-4 col-5" translate>magazineIssue</th>
          <th class="table-header issue-table-data align-middle col-4" translate>magazineEdition</th>
          <th class="table-header issue-table-data align-middle col-3"></th>
        </tr>
      </thead>
      <tbody infiniteScroll infiniteScrollDisabled="true">
        <tr class="d-flex" *ngFor="let issue of queriedIssues; trackBy: trackById; index as i">
          <td data-automation-id="issue-title-row" class="issue-table-data align-middle align-content-center pl-2 pl-sm-4 col-5">
            <div class="cover-container">
              <img
                alt=""
                role="presentation"
                data-automation-id="issue-image"
                [src]="issue.thumbnail"
                class="issue-cover">
            </div>
          </td>
          <td class="issue-table-data align-middle align-content-center pl-2 col-4"
              data-automation-id="issue-edition-row">{{issue.edition}}
          </td>
          <td data-automation-id="issue-action-row"
              class="issue-table-data align-middle align-content-center pl-2 col-3">
            <button translate class="ins-buttons button-secondary flint my-1 mr-2 get-issue-btn"
                    [attr.data-automation-id]="'get-issue-btn-' + i"
                    (click)="checkOutIssue(issue)">getIssue
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

