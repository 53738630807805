import { Component, EventEmitter, Output } from '@angular/core';

export enum RenewOptionsEnum {
  OVERDUE = 'renewOverdue',
  DUE_SOON = 'renewDueSoon',
  OVERDUE_AND_DUE_SOON = 'overdueAndDueSoon',
}

@Component({
  selector: 'app-bulk-renew-button',
  templateUrl: './bulk-renew-button.component.html',
  styleUrls: ['./bulk-renew-button.component.scss']
})
export class BulkRenewButtonComponent {
  @Output() public renew = new EventEmitter<RenewOptionsEnum>();

  public readonly options: RenewOptionsEnum[] = [
    RenewOptionsEnum.OVERDUE,
    RenewOptionsEnum.DUE_SOON,
    RenewOptionsEnum.OVERDUE_AND_DUE_SOON
  ];

  renewItems(option: RenewOptionsEnum) {
    this.renew.emit(option);
  }
}
