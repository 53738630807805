<div class="bookshelf-card card">
  <div class="d-flex px-4 pt-4 pb-2">
    <div class="bookshelf-card-body d-flex flex-column">
      <div class="bookshelf-card-body-title mx-1"
           [class.bookshelf-card-body-title--with-creator]="displayCreator"
           data-automation-id="bookshelf-card-title">
        <a #titleAnchor
           class="bookshelf-card-body-title-name line-clamp line-clamp--two"
           data-automation-id="bookshelf-card-title-name"
           [id]="ariaTitleId"
           [tippy]="getTitle()"
           (click)="$event.preventDefault(); cardClick.emit()"
           href="">
          {{ getTitle() }}
        </a>

        <app-showcase-creator *ngIf="displayCreator"
                          [isCreator]="isCreator"
                          [creator]="creator">
        </app-showcase-creator>
      </div>

      <div (click)="cardClick.emit()" data-automation-id="bookshelf-card-body-covers"
           class="bookshelf-card-body-covers d-flex align-items-end my-1 overflow-hidden">
        <div class="bookshelf-card-body-covers-image-container mx-1"
             *ngFor="let item of items; let i = index">
          <app-cover
            [config]="item.coverConfig"
            [title]="item.title"
            [author]="item.primaryAgent?.label"
            [materialType]="item.materialType"
            size="small">
          </app-cover>
        </div>
      </div>
    </div>

    <div *ngIf="cardActions.length" class="d-inline-block" ngbDropdown placement="left-top">
      <button #actionsMenuToggleButton
              [attr.aria-describedby]="ariaTitleId"
              [attr.aria-label]="'bookshelfCardMoreActionsButtonLabel' | translate"
              aria-haspopup="menu"
              class="p-0 app-button bookshelf-card-more"
              data-automation-id="bookshelf-card-more-btn"
              (click)="toggleDropdown()"
              ngbDropdownToggle
              type="button">
        <fa-icon class="bookshelf-card-more-icon" [icon]="ellipsisIcon"></fa-icon>
      </button>
      <div class="py-2" ngbDropdownMenu role="menu" data-automation-id="bookshelf-card-more-dropdown">
        <button *ngFor="let cardAction of cardActions"
                [attr.aria-describedby]="ariaTitleId"
                (click)="onTriggerAction(cardAction)"
                aria-haspopup="dialog"
                class="d-block py-2 px-3 w-100 h-auto app-button bookshelf-card-action-btn"
                data-automation-id="bookshelf-card-more-action-btn"
                ngbDropdownItem
                role="menuitem">
          <ng-template [ngTemplateOutlet]="cardAction.template"></ng-template>
        </button>
        <div *ngIf="isLoading">
          <div class="d-flex justify-content-center pt-5">
            <div class="spinner-container" role="status" aria-live="polite">
              <div class="spinner-border text-primary" data-automation-id="custom-showcase-spinner">
                <span class="sr-only" translate>Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bookshelf-card-footer d-flex flex-row justify-content-between align-items-center px-3">
    <div class="d-flex align-items-center">
      <div *ngIf="cardShareActions.length" class="d-inline-block" ngbDropdown placement="right-top auto">
        <button #shareMenuToggleButton
                [attr.aria-describedby]="ariaTitleId"
                [attr.aria-label]="'bookshelfCardShare' | translate"
                aria-haspopup="menu"
                class="bookshelf-card-footer-share p-1 border-0"
                data-automation-id="bookshelf-card-footer-share-btn"
                ngbDropdownToggle
                type="button">
          <fa-icon [icon]="sharedIcon"></fa-icon>
        </button>
        <div class="py-2" ngbDropdownMenu role="menu" data-automation-id="bookshelf-card-share-dropdown">
          <button *ngFor="let cardShareAction of cardShareActions"
                  [attr.aria-describedby]="ariaTitleId"
                  (click)="onTriggerShareAction(cardShareAction)"
                  class="d-block py-2 px-3 w-100 h-auto app-button bookshelf-card-action-btn"
                  data-automation-id="bookshelf-card-footer-share-action-btn"
                  ngbDropdownItem
                  role="menuitem">
            <ng-template [ngTemplateOutlet]="cardShareAction.template"></ng-template>
          </button>
        </div>
      </div>

      <ng-content select="app-bookshelf-card-action-state"></ng-content>
    </div>

    <div *ngIf="cardStatus">
      <ng-template [ngTemplateOutlet]="cardStatus.template"></ng-template>
    </div>
  </div>
</div>
