<div class="user-address p-4" data-automation-id="borrow-by-mail-user-address">
  <div class="user-address__title mb-1" translate>yourAddress</div>
  <div class="user-address__body">
    <span>{{userAddress.line1}}<span *ngIf="userAddress.line2 || userAddress.city || userAddress.stateCode || userAddress.postalCode">, </span></span>
    <span *ngIf="userAddress.line2">{{userAddress.line2}}<span *ngIf="userAddress.city || userAddress.stateCode || userAddress.postalCode">, </span></span>
    <span *ngIf="userAddress.city">{{userAddress.city}}<span *ngIf="userAddress.stateCode || userAddress.postalCode">, </span></span>
    <span *ngIf="userAddress.stateCode">{{userAddress.stateCode}} </span>
    <span>{{userAddress.postalCode}}</span>
  </div>
</div>
