import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomCheckboxComponent),
      multi: true,
    },
  ],
})
export class CustomCheckboxComponent implements ControlValueAccessor {
  @Input('aria-label') public ariaLabel = '';
  @Input() public containerClass: string[] = ['m-4'];
  @Input() public ariaLabelledby = '';

  public checked: boolean;
  public disabled: boolean;
  public propagateChange = (_: boolean) => {
  }

  public onChange(event: Event): void {
    event.stopPropagation();
    const target = event.target as HTMLInputElement;
    this.propagateChange(target.checked);
    this.writeValue(target.checked);
  }

  writeValue(value: boolean): void {
    this.checked = value || false;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
