import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent {
  @Input() title: string;
  @Input() titleId = 'modal-header-title';
  @Input() closeId = 'modal-header-close';
  @Input() closeDisabled = false;
  @Input() subtitle? : string;
  @Output() close = new EventEmitter();

  public closeIcon = faXmark;

  public onClose() {
    this.close.emit();
  }
}
