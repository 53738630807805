import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, Renderer2, ViewEncapsulation } from '@angular/core';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { SyndeticsUnboundService, SyndeticsWidget } from '../../../services/syndetics-unbound.service';

@Component({
  selector: 'app-syndetics-unbound-see-full-excerpt',
  templateUrl: './syndetics-unbound-see-full-excerpt.component.html',
  styleUrls: ['./syndetics-unbound-see-full-excerpt.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SyndeticsUnboundSeeFullExcerptComponent implements AfterViewInit, OnDestroy {
  @Input() isbn: string;
  public isLoaded = false;
  public isDisplayed = false;
  public shouldLoadWidgets: boolean;

  constructor(
    private readonly syndeticsUnboundService: SyndeticsUnboundService,
    private readonly renderer: Renderer2,
    private readonly cdr: ChangeDetectorRef,
    private configLoader: ConfigurationLoader,
  ) {
  }

  public ngAfterViewInit(): void {
    const connectionConfig = this.configLoader.syndeticsUnboundConnection;
    this.shouldLoadWidgets = connectionConfig?.accountId && connectionConfig?.displaySettings.lookinside;
    if (this.shouldLoadWidgets) {
      // for the FormatGroup we call insertWidgets function (which in turn calls LibraryThingConnector.runUnboundWithMetadata)
      // twice on FRC page: to render full excerpt here and to render other widgets along with showcases in the showcase page.
      // Each call is time consuming, because syndetic scans whole html page to render all widgets (even if you need only one). In the
      // usual flow rendering of this widget happens first, therefore rendering of the whole showcase page is waiting until it's finished.
      // added timeout to give a priority for the showcase page to render all widgets and showcases before full excerpt widget
      setTimeout(() => {
        this.syndeticsUnboundService.insertWidgets(this.isbn, [SyndeticsWidget.lookinside], connectionConfig.accountId, this.renderer)
        .then((widgetsLoaded) => {
          this.isLoaded = true;
          this.isDisplayed = widgetsLoaded[SyndeticsWidget.lookinside];
          this.cdr.detectChanges();
        });
      });
    } else {
      this.isLoaded = true;
      this.cdr.detectChanges();
    }
  }

  public ngOnDestroy(): void {
    if (this.shouldLoadWidgets) {
      this.syndeticsUnboundService.removeWidgets([SyndeticsWidget.lookinside], this.renderer);
    }
  }
}
