import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { CustomerIntegrationType } from '../../../customer-integration/customer-integration';
import { DiscoverV2BffService } from '../../service/discover-v2-bff.service';
import { TranslateService } from '@ngx-translate/core';

enum MonsterAvailableRoutes{
  Homepage = '/',
  Search = '/search',
  Card = '/search/card'
}

@Component({
  selector: 'app-monster-help-v2', templateUrl: './monster-help-v2.component.html',
})
export class MonsterHelpV2Component implements OnInit, OnDestroy {
  public flowId: number;
  public params: any;
  public isKidsCatalog: boolean = false;
  public show$: Observable<boolean>;
  private readonly showSubject = new BehaviorSubject<boolean>(false);
  public subscriptions = new Subscription();
  public isNlbCustomer: boolean;
  public translations: any = {};

  constructor(
    private discoverV2BffService: DiscoverV2BffService,
    private router: Router,
    private configLoader: ConfigurationLoader,
    private translateService: TranslateService
  ) {
    this.show$ = this.showSubject.asObservable();
    this.isNlbCustomer = this.configLoader.customerIntegrationType === CustomerIntegrationType.NLB;
  }

  public ngOnInit() {

    const url = this.router.url.split('?')[0];

    this.subscriptions.add(
      combineLatest([
        this.discoverV2BffService.$page,
      ]).subscribe(([page]) => {
        if (page && page.settings.audience.isKidsCatalog) {
          if (url === MonsterAvailableRoutes.Homepage) {
            this.flowId = 0;
            this.showSubject.next(true);
            return;
          }
          if (url === MonsterAvailableRoutes.Search) {
            this.flowId = 1;
            this.showSubject.next(true);
            return;
          }
          if (url === MonsterAvailableRoutes.Card) {
            this.flowId = 2;
            this.showSubject.next(true);
            return;
          }
        }
      }),
    );

    this.translations = {
      DetailspageHelpDialogue: {
        lookForCallNumber: this.translateService.instant('kidsLookForCallNumber'),
      },
      HomepageHelpDialogue: {
        typeTheTitle: this.translateService.instant('kidsTypeTheTitle'),
      },
      SearchpageHelloDialogue: {
        seeMoreBooks: this.translateService.instant('kidsSeeMoreBooks'),
      },
      SearchpageHelpDialogue: {
        clickTheArrow: this.translateService.instant('kidsClickTheArrow'),
      },
      Common:{
        yes: this.translateService.instant('kidsYes'),
        noThanks: this.translateService.instant('kidsNoThanks'),
        showThisBook: this.translateService.instant('kidsShowThisBook'),
      }
    };
  }

  public ngOnDestroy() {
    this.showSubject.complete();
    this.subscriptions.unsubscribe();
  }
}
