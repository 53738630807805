import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'common/shared.module';
import { CitationExportContentComponent } from './components/citation-modal-window/citation-modal-window.component';
import { CitationService } from './services/citation.service';

const COMPONENTS = [
  CitationExportContentComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    SharedModule,
  ],
  exports: [
    ...COMPONENTS,
  ],
  providers: [
    CitationService,
  ],
})
export class CitationModule { }
