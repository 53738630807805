<div class="p-4">
  <div class="modal-header">
    <h2 ngbAutofocus class="modal-title" translate>confirmationActionDialogTitle</h2>
    <button data-automation-id="close-get-it-dialog-btn" type="button" class="close-btn p-0"
      [attr.aria-label]="'closeDialog' | translate" (click)="activeModal.dismiss()">
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
  </div>
  <div class="modal-body">
    <section class="status-section">
      <fa-icon class="success-icon" [icon]="faCheckCircle" size="4x"></fa-icon>
      <ng-container [ngSwitch]="action">
        <div class="status-message" *ngSwitchCase="RichFeatureAction.GET_IT">
          <p data-automation-id="econtent-rich-feature-successful-dialog-message" translate>
            econtentSuccessfulCheckout
          </p>
        </div>
        <div class="status-message" *ngSwitchCase="RichFeatureAction.PLACE_HOLD">
          <p data-automation-id="econtent-rich-feature-successful-dialog-message" translate>
            econtentSuccessfulPlaceHold
          </p>
        </div>
      </ng-container>
    </section>
  </div>
  <div class="modal-footer">
      <button *ngIf="url" class="ins-buttons button-primary flint" (click)="navigateToLink()"
              data-automation-id="econtent-success-get-item" translate>
        getItem
      </button>
      <button *ngIf="!url" class="ins-buttons button-primary flint" aria-label="OK"
              data-automation-id="econtent-success-ok" translate (click)="activeModal.close()">
        ok
      </button>
  </div>
</div>
