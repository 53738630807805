import * as Immutable from 'immutable';
import { FacetSchemaWithData } from 'search/facets/models/facet-schema-with-data';
import { FacetState } from 'search/facets/models/facet-state';
import { CatalogDate } from 'search/models/search-results';
import { FacetBlockState } from '../../models/filter-panel';

export interface Facets {
  v2State?: Immutable.OrderedMap<string, FacetState>;
  facetsResults?: AllResourceFacets;
}

export interface FacetResult<T> {
  totalResults: number;
  page: number;
  totalPages: number;
  data: T[];
}

export interface AllResourceFacets {
  agent: FacetResult<Facet>;
  concept: FacetResult<Facet>;
  materialType: FacetResult<Facet>;
  intendedAudience: FacetResult<Facet>;
  universalLimiter: FacetResult<Facet>;
  language: FacetResult<Facet>;
  fullText: Partial<Facet>;
  newModel: FacetSchemaWithData[];
}

export interface ResponseFacet {
  id: string;
  count: number;
  label?: string;
}
export interface AllResponseFacets {
  agent: FacetResult<ResponseFacet>;
  concept: FacetResult<ResponseFacet>;
  materialType: FacetResult<ResponseFacet>;
  intendedAudience: FacetResult<ResponseFacet>;
  universalLimiter: FacetResult<ResponseFacet>;
  language: FacetResult<ResponseFacet>;
  literaryForm?: FacetResult<ResponseFacet>;
  fullText: Partial<Facet>;
}

export interface SearchFacetsObject {
  personFacets?: Facet[];
  conceptFacets?: Facet[];
  availabilityFacet?: Facet[];
  formatFacet?: Facet[];
  languageFacet?: Facet[];
  dateFacets?: DateFacet;
  catalogDateFacets?: CatalogDateFacet;
  fullText?: Partial<Facet>;
}

export interface ResourceFacets {
  personFacets?: FacetResult<Facet>;
  conceptFacets?: FacetResult<Facet>;
  formatFacet?: FacetResult<Facet>;
  availabilityFacet?: FacetResult<Facet>;
  languageFacet?: FacetResult<Facet>;
  dateFacets?: DateFacet;
  fullText?: Partial<Facet>;
  blockStates?: FacetBlockState[];
  isFacetsLoading?: boolean;
}

export interface Facet extends BlockFacet {
  id: string;
  count: number;
  label: string;
}

export interface BlockFacet {
  facetKey: string;
  isResetLocked: boolean;
  selected?: boolean;
}

export interface DateFacet extends BlockFacet {
  id?: string;
  dateFrom: string;
  dateTo: string;
  selected: boolean;
  label?: string;
}

export interface CatalogDateFacet extends BlockFacet {
  id?: string;
  catalogDate: CatalogDate;
  selected: boolean;
  label?: string;
}

export interface FacetsLocked {
    [key: string]: string[];
}

export interface ResourceFacetLoadCompletePayload {
  facets: AllResourceFacets;
  facetsLocked: FacetsLocked;
  isLazyFacets?: boolean;
  lazyFacetIds?: string[];
}

export type ResourceFacet = Facet;

export const emptyFacetResult: FacetResult<ResourceFacet> = {
  totalResults: 0,
  page: 0,
  totalPages: 0,
  data: [],
};

export const defaultResourceFacet: ResourceFacet = {
  id: null,
  count: null,
  label: null,
  facetKey: null,
  selected: false,
  isResetLocked: false,
};

export const defaultFacetResult: FacetResult<Facet> = {
  totalResults: 0,
  page: 0,
  totalPages: 0,
  data: [],
};

export const defaultAllResourceFacets: AllResourceFacets = {
  agent: defaultFacetResult,
  materialType: defaultFacetResult,
  intendedAudience: defaultFacetResult,
  universalLimiter: defaultFacetResult,
  concept: defaultFacetResult,
  language: defaultFacetResult,
  fullText: null,
  newModel: [],
};
