import { catchError } from 'rxjs/operators';
import {
  Observable,
  of,
} from 'rxjs';

export function error(errorResponse: any) {
  return (t: any, name: any, descriptor: any) => {
    const original = descriptor.value;

    descriptor.value = function(...args: any[]) {
      return original.apply(this, args).pipe(catchError((err) => {
        console.error(`Error from ${name}: ${err.status}(status) ${err.message}(error message)`);
        return errorResponse instanceof Observable ?
          errorResponse:
          of(!errorResponse || Array.isArray(errorResponse) ? errorResponse : {...errorResponse});

      }));
    };

    return descriptor;
  };
}
