import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RelatedItem } from '../../models/related-items-result';
import { ItemDetailsComponent } from '../item-details/item-details.component';
import { isEmptyMessage } from 'core/utils/empty-message';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-location-table',
  templateUrl: './location-table.component.html',
  styleUrls: ['./location-table.component.scss'],
})
export class LocationTableComponent implements OnInit {
  @Input() public itemTitle: string;
  @Input() public tableItems: RelatedItem[];
  public showSpecialColumn = false;
  public isEmptyMsg = isEmptyMessage;
  public readonly infoCircle = faInfoCircle
  constructor(
    private readonly dialog: NgbModal,
  ) {
  }

  ngOnInit(): void {
    this.showSpecialColumn = this.tableItems.some((item) => item.rareContentLink);
  }

  showDetails(item: RelatedItem) {
    const modalRef = this.dialog.open(ItemDetailsComponent, { windowClass: undefined });
    modalRef.componentInstance.itemTitle = this.itemTitle;
    modalRef.componentInstance.item = item;
  }
}
