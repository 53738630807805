import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TippyModule } from '@ngneat/helipopper';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'common/shared.module';
import { NlbBookmarkButtonComponent } from './components/nlb-bookmark-button/nlb-bookmark-button.component';
import { NlbBookmarkAfterLoginEffects } from './effects/nlb-bookmark-after-login.effects';
import { NlbBookmarkEffects } from './effects/nlb-bookmark.effects';
import * as nlbBookmark from './reducers/nlb-bookmark.reducer';
import { NlbBookmarkService } from './services/nlb-bookmark.service';

@NgModule({
  declarations: [
    NlbBookmarkButtonComponent,
  ],
  exports: [
    NlbBookmarkButtonComponent,
  ],
  imports: [
    StoreModule.forFeature(nlbBookmark.featureKey, nlbBookmark.reducer),
    EffectsModule.forFeature([
      NlbBookmarkEffects,
      NlbBookmarkAfterLoginEffects,
    ]),
    CommonModule,
    SharedModule,
    TippyModule,
  ],
  providers: [
    NlbBookmarkService,
  ],
})
export class NlbBookmarkModule {
}
