import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CustomerFeature } from 'app/customer-integration/customer-integration';
import { Subscription } from 'rxjs';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { stopHighlightHeaderBookmarkButton, toggleBookmarks, toggleOpenedUserPanel } from 'user/actions/user-profile.actions';
import { getIsHighlightHeaderBookmarkButtonPending } from 'user/reducers/user.reducer';
import { CustomerIntegrationService } from '../../../customer-integration/services/customer-integration.service';

@Component({
  selector: 'app-toggle-bookmarks-button',
  templateUrl: './toggle-bookmarks-button.component.html',
  styleUrls: ['./toggle-bookmarks-button.component.scss'],
  animations: [
    trigger('highlight', [
      state('stop', style({
        color: '#454f5b',
      })),
      state('start', style({
        color: '#0c7e11',
      })),
      transition('stop => start', [
        animate('2s', keyframes([
          style({color: '#0c7e11', offset: 0}),
          style({color: '#0c7e11', offset: 1.0}),
        ])),
      ]),
      transition('start => stop', [
        animate('0s'),
      ]),
    ]),
  ],
})
export class ToggleBookmarksButtonComponent implements OnInit {
  public customLink?: string;
  public highlightBookmarkButton: boolean;
  public readonly CustomerFeature = CustomerFeature;
  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly configLoader: ConfigurationLoader,
    private readonly customerIntegrationService: CustomerIntegrationService,
  ) { }

  public ngOnInit() {
    this.customLink = this.customerIntegrationService.CustomerIntegrationLinksMap[this.configLoader.customerIntegrationType]?.bookmarksUrl;
    this.subscriptions.add(this.store.select(getIsHighlightHeaderBookmarkButtonPending).subscribe((highlightBookmarkButton) => {
      this.highlightBookmarkButton = highlightBookmarkButton;
    }));
  }

  public toggleBookmarks(event: Event) {
    event.stopPropagation();
    this.store.dispatch(toggleOpenedUserPanel({isOpened: false}));
    this.store.dispatch(toggleBookmarks());
  }

  public onNavigateToBookmarks() {
    this.store.dispatch(toggleOpenedUserPanel({isOpened: false}));
  }

  public onAnimationDoneEvent(event: any) {
    if (event.fromState === 'stop' && event.toState === 'start') {
      this.store.dispatch(stopHighlightHeaderBookmarkButton());
    }
  }
}
