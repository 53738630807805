<div class="modal-header">
  <h2 class="modal-title m-0" translate tabindex="-1" cdkFocusInitial>
    sessionAboutToExpire
  </h2>
</div>
<div class="modal-body">
  <p data-automation-id="session-expired-message">
    <ng-container *ngIf="isAuthorized; else guestAuthorized">
      {{ 'youWillBeLoggedOut' | translate:{value: secondsLeft} }}
    </ng-container>
    <ng-template #guestAuthorized>
      {{ 'dataClearedOnSessionExpire' | translate:{value: secondsLeft} }}
    </ng-template>
  </p>
  <p>
    <ngb-progressbar type="info" [value]="(maxFrames - framesLeft) / maxFrames * 100"></ngb-progressbar>
  </p>
</div>
<div class="modal-footer">
  <button
    class="ins-buttons button-primary flint mr-3"
    aria-label="Stay signed in"
    (click)="handleAction(false)"
    data-automation-id="staySignedInButton">
    <ng-container *ngIf="isAuthorized; else continueMessage">
      {{ 'staySignedIn' | translate }}
    </ng-container>
    <ng-template #continueMessage>
      {{ 'continue' | translate }}
    </ng-template>
  </button>
  <button
    class="ins-buttons button-primary flint"
    aria-label="Logout"
    (click)="handleAction(true)"
    data-automation-id="logoutButton">
    <ng-container *ngIf="isAuthorized; else endSession">
      {{ 'logOut' | translate }}
    </ng-container>
    <ng-template #endSession>
      {{ 'endSession' | translate }}
    </ng-template>
  </button>
</div>
