import { Component, Input, OnInit } from '@angular/core';
import { faCircleCheck, faCircleExclamation, faCircleXmark, faClock, faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { handleNeededAction, openErrorDetailsModal } from 'user/actions/bulk-hold.actions';
import { BulkHoldState, getIndividualHoldSubmitState, IndividualHoldSubmitState } from 'user/reducers/bulk-hold.reducer';


@Component({
  selector: 'app-hold-submit-state',
  templateUrl: './hold-submit-state.component.html',
  styleUrls: ['./hold-submit-state.component.scss']
})
export class HoldSubmitStateComponent implements OnInit {
  @Input() public fgId: string;
  @Input() public title: string;
  public holdSubmitState: IndividualHoldSubmitState;
  public readonly faCircleCheck = faCircleCheck;
  public readonly faCircleXmark = faCircleXmark;
  public readonly faCircleExclamation = faCircleExclamation;
  public readonly faClock = faClock;
  public readonly faMinusCircle = faMinusCircle;
  private readonly subscriptions = new Subscription();

  constructor(
    private readonly store: Store<BulkHoldState>,
  ) {
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.store.select(getIndividualHoldSubmitState, {id: this.fgId})
      .subscribe((holdSubmitState) => {
        this.holdSubmitState = holdSubmitState;
      }),
    );
  }

  public openErrorDetailsModal(): void {
    this.store.dispatch(openErrorDetailsModal({message: this.holdSubmitState.error.message}));
  }

  public handleNeededAction(): void {
    const {error, holdRequest} = this.holdSubmitState;
    this.store.dispatch(handleNeededAction({holdRequest, error}));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
