
export enum SitePermissions {
  SHOWCASE_VIEW = 'site::showcase::view',
  SHOWCASE_CREATE = 'site::showcase::create',
}

export enum ShowcasePermissions {
  DELETE = 'showcase::delete',
  PUBLISH = 'showcase::publish',
  EDIT = 'showcase::edit'
}

export interface Scopes {
  scopes: string[];
}

export interface Permissions extends Scopes {
  rsid: string;
  rsname: string;
}
