import { Component } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import {
  aboutShowcaseAgeTranslations,
  aboutShowcaseFormatTranslations,
  aboutShowcaseTypeTranslations,
} from '../../models/custom-showcase.translations';
import { faBookReader } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-custom-showcase-form-about-showcase',
  templateUrl: './custom-showcase-form-about-showcase.component.html',
  styleUrls: ['./custom-showcase-form-about-showcase.component.scss'],
})
export class CustomShowcaseFormAboutShowcaseComponent {
  public readonly typeTranslations = aboutShowcaseTypeTranslations;
  public readonly ageTranslations = aboutShowcaseAgeTranslations;
  public readonly formatTranslations = aboutShowcaseFormatTranslations;
  public readonly bookReaderIcon = faBookReader;

  constructor(public readonly controlContainer: ControlContainer) {
  }

  get aboutShowcase(): UntypedFormGroup {
    return this.controlContainer.control as UntypedFormGroup;
  }

  get types(): UntypedFormGroup {
    return this.aboutShowcase.get('type') as UntypedFormGroup;
  }

  get ages(): UntypedFormGroup {
    return this.aboutShowcase.get('age') as UntypedFormGroup;
  }

  get formats(): UntypedFormGroup {
    return this.aboutShowcase.get('format') as UntypedFormGroup;
  }

  public originalOrder() {
    return 0;
  }
}
