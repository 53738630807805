import {
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild
} from '@angular/core';
import { CardActionDirective } from 'common/directives/card-action.directive';
import { CardShareActionDirective } from 'common/directives/card-share-action.directive';
import { CardStatusDirective } from 'common/directives/card-status.directive';
import { BookshelfCardActionPayload, BookshelfCardActionType, BookshelfCardShareActionType } from 'common/models/bookshelf-card';
import { Observable, Subscription } from 'rxjs';
import { BookCover } from 'search/models/search-results';
import { ListItemEntity } from '../../../list/models/list';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons';
import { faShareAlt } from '@fortawesome/pro-solid-svg-icons';
import { FeatureToggleService } from 'app/services/feature-toggle.service';
import { PermissionService } from 'app/permissions/services/permission.service';

@Component({
  selector: 'app-bookshelf-card',
  templateUrl: './bookshelf-card.component.html',
  styleUrls: ['./bookshelf-card.component.scss'],
})
export class BookshelfCardComponent<A extends BookshelfCardActionType> implements OnInit, AfterViewInit, OnDestroy {
  private static readonly defaultTitleWidth = 229;
  @Input() public name: string;
  @Input() public coverConfigs: BookCover[];
  @Input() public items: Array<ListItemEntity | {coverConfig: BookCover}>;
  @Input() public ariaTitleId: string;
  @Input() public showcaseId: string;
  @Input() public itemsCount?: number;
  @Input() public creator?: string;
  @Input() public isCreator?: boolean;
  @Input() public displayCreator?: boolean;
  @Input() public focus$?: Observable<undefined>;
  @Input() public focusActionsMenuToggleButton$?: Observable<undefined>;
  @Input() public focusShareMenuToggleButton$?: Observable<undefined>;

  @Output() public readonly cardClick = new EventEmitter<undefined>();
  @Output() public readonly triggerAction = new EventEmitter<BookshelfCardActionPayload<A>>();
  @Output() public readonly triggerShareAction = new EventEmitter<BookshelfCardActionPayload<BookshelfCardShareActionType, undefined>>();
  @Output() public readonly requestPermissions = new EventEmitter();

  @ContentChildren(CardActionDirective) public cardActions: QueryList<CardActionDirective<A>>;
  @ContentChildren(CardShareActionDirective) public cardShareActions: QueryList<CardShareActionDirective>;
  @ContentChild(CardStatusDirective) public cardStatus!: CardStatusDirective;

  @ViewChild('titleAnchor') public titleAnchor: ElementRef;
  @ViewChild('actionsMenuToggleButton') public actionsMenuToggleButton: ElementRef;
  @ViewChild('shareMenuToggleButton') public shareMenuToggleButton: ElementRef;

  public titleWidth = BookshelfCardComponent.defaultTitleWidth;
  public ellipsisIcon = faEllipsisV;
  public sharedIcon = faShareAlt;
  public isAuthPatronFlagEnabled: boolean;
  public isDropdownOpen: boolean = false;
  public isLoading: boolean = false;
  private readonly subscriptions = new Subscription();

  constructor(
    private readonly featureToggleService: FeatureToggleService,
    private readonly permissionService: PermissionService,
    private elementRef: ElementRef
  ) {}

  @HostListener('window:resize')
  public onResize(): void {
    this.setTitleWidth();
  }

  @HostListener('document:click', ['$event.target'])
  public handleClickOutside(targetElement: any): void {
    if (!this.elementRef.nativeElement.contains(targetElement)) {
      this.isDropdownOpen = false;
    }
  }

  public ngOnInit(): void {
   this.isAuthPatronFlagEnabled = this.featureToggleService.getToggles()['DIS-30793_2024-04-27_auth_patron'];

    if (this.focus$) {
      this.subscriptions.add(
        this.focus$.subscribe(() => {
          this.titleAnchor?.nativeElement.focus();
        }),
      );
    }

    if (this.focusActionsMenuToggleButton$) {
      this.subscriptions.add(
        this.focusActionsMenuToggleButton$.subscribe(() => {
          this.actionsMenuToggleButton?.nativeElement.focus();
        }),
      );

    }
    if (this.focusShareMenuToggleButton$) {
      this.subscriptions.add(
        this.focusShareMenuToggleButton$.subscribe(() => {
          this.shareMenuToggleButton?.nativeElement.focus();
        }),
      );
    }
    this.permissionService.loading$.subscribe((state) => {
      this.isLoading = state;
    });
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.setTitleWidth();
    }, 0);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public toggleDropdown() {
    if (this.showcaseId && this.isAuthPatronFlagEnabled && !this.isDropdownOpen) {
      this.requestPermissions.emit(this.showcaseId);
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  public getTitle(): string {
    return `${this.name}${((this.itemsCount !== undefined && this.itemsCount !== null) ? (' | ' + this.itemsCount) : '')}`;
  }

  public onTriggerAction(action: CardActionDirective<A>): void {
    this.triggerAction.emit({
      actionType: action.cardAction,
      payload: action.cardActionPayload,
    });
  }

  public onTriggerShareAction(action: CardShareActionDirective): void {
    this.triggerShareAction.emit({actionType: action.cardShareAction});
  }

  private setTitleWidth(): void {
    this.titleWidth = this.titleAnchor?.nativeElement.clientWidth || BookshelfCardComponent.defaultTitleWidth;
  }
}
