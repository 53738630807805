import { Component, Input, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ListItemEntity, ListWithItemsCount } from '../../../list/models/list';
import { ListStoreAdapterService } from '../../../list/services/list-store-adapter.service';
import { ListTransformerService } from '../../../list/services/list-transformer.service';
import { BulkSelectItem } from '../../models/bulk-select-item';

@Component({
  selector: 'app-bulk-add-to-list',
  templateUrl: './bulk-add-to-list.component.html',
  styleUrls: ['./bulk-add-to-list.component.scss']
})
export class BulkAddToListComponent {
  @Input() selectedItems: BulkSelectItem[];
  public selectedItemsAsListItems: ListItemEntity[];
  public lists: ListWithItemsCount[];
  public authorized: boolean;
  private readonly subscription = new Subscription();

  constructor(
    private readonly listStoreAdapterService: ListStoreAdapterService,
    private readonly listTransformerService: ListTransformerService,
  ) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedItems) {
      this.selectedItemsAsListItems = this.selectedItems.map((item) => this.listTransformerService.transformFgOrEntityToListItemEntity(item));
    }
  }

  public ngOnInit() {
    this.subscription.add(
      this.listStoreAdapterService.getLists().subscribe(([lists, authorized]) => {
        this.lists = lists;
        this.authorized = authorized;
      }),
    );
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
