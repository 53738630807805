import { Injectable } from '@angular/core';
import { FacetSchema } from 'search/facets/models/facet-schema';
import { FacetType } from 'search/facets/models/facet-type';
import { EntityTypes, } from '../../../entity/models/entity';
import { defaultSearchObjectConfig, MappedSearchObject, SearchObject, SearchQuery, SearchRequestFacets } from 'search/models/search-object';
import { SearchRequestBody, } from 'search/models/search-results';
import { initialState } from 'search/reducers/root.reducer';
import { copyObject } from 'search/reducers/utils';
import { BoolQueryHelper } from 'search/utils/bool-query-helper';

@Injectable()
export class SearchMapperService {

  constructor(
    private readonly boolQueryHelper: BoolQueryHelper,
  ) {}

  public prepareMappedSearchObjectFromSearchObject(searchObject: SearchObject): MappedSearchObject {
    if (searchObject) {
      return {
        searchText: searchObject.searchParams.type.searchText,
        searchType: searchObject.searchType,
        metadataBoolQuery: searchObject.searchParams.type.metadataBoolQuery,
        resourceIds: searchObject.searchParams.type.resourceIds,
        pageSize: searchObject.pagination.pageSize,
        pageNum: searchObject.pagination.pageNum,
        resourceType: searchObject.resourceType,
        sorting: searchObject.sortingParams.sorting,
        sortOrder: searchObject.sortingParams.sortOrder,
        filters: searchObject.filters,
      };
    } else {
      return null;
    }
  }

  public prepareNewSearchObjectFromQuery(queryParams: SearchQuery, facetSchemas: FacetSchema[]): SearchObject {
    const newSearchObject = this.createBaseSearchObject(queryParams, queryParams.boolQuery);
    const facets = this.createSearchObjectFacets(queryParams, facetSchemas);

    if (queryParams.query) {
      newSearchObject.searchParams.type.searchText = queryParams.query;
    }
    if (queryParams.metadata) {
      newSearchObject.searchParams.type.metadataBoolQuery = queryParams.metadata;
    }

    return {
      ...newSearchObject,
      filters: {
        ...newSearchObject.filters,
        facets,
      },
    };
  }

  public prepareSearchObjectFromSearchRequest(searchRequestBody: SearchRequestBody, facetSchemas: FacetSchema[]): SearchObject {
    const newSearchObject = this.createBaseSearchObject(searchRequestBody, searchRequestBody.metadataBoolQuery);
    const facets = this.createSearchObjectFacets(searchRequestBody, facetSchemas);

    if (searchRequestBody.searchText) {
      newSearchObject.searchParams.type.searchText = searchRequestBody.searchText;
    }
    if (searchRequestBody.metadataBoolQuery) {
      newSearchObject.searchParams.type.metadataBoolQuery = searchRequestBody.metadataBoolQuery;
    }

    return {
      ...newSearchObject,
      filters: {
        ...newSearchObject.filters,
        facets,
      },
    };
  }

  private createBaseSearchObject(object: SearchRequestBody | SearchQuery, boolQueryParam = ''): SearchObject {
    const newSearchObject: SearchObject = copyObject(initialState.searchObject);

    newSearchObject.pagination.pageNum = object.pageNum ? +object.pageNum : defaultSearchObjectConfig.pagination.pageNum;
    newSearchObject.pagination.pageSize = object.pageSize ? +object.pageSize : defaultSearchObjectConfig.pagination.pageSize;
    newSearchObject.sortingParams.sorting = object.sorting || defaultSearchObjectConfig.sortingParams.sorting;
    newSearchObject.sortingParams.sortOrder = object.sortOrder || defaultSearchObjectConfig.sortingParams.sortOrder;

    const query = this.boolQueryHelper.extractQuery({query: boolQueryParam});
    const isACRequest = ['Agent', 'Concept'].indexOf(query[0].entityType) !== -1;

    if (object.searchType) {
      newSearchObject.searchType = object.searchType;
    }
    if (isACRequest) {
      newSearchObject.searchParams.type.metadataBoolQuery = boolQueryParam;
    } else {
      const regexp = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/mg;
      newSearchObject.searchParams.type.resourceIds = boolQueryParam.match(regexp);
    }

    newSearchObject.resourceType = EntityTypes.FORMAT_GROUP;

    return newSearchObject;
  }

  private createSearchObjectFacets(object: SearchRequestBody | SearchQuery, facetSchemas: FacetSchema[]): SearchRequestFacets {
    return facetSchemas.reduce((acc, facetSchema) => {
      switch (facetSchema.type) {
        case FacetType.Iterable: {
          const facetQueryParamName = facetSchema.mapField + 'Ids';
          const queryParam = (object as any)[facetQueryParamName];
          if (queryParam) {
            acc[facetQueryParamName] = Array.isArray(queryParam) ? queryParam : this.extractFacetsIdsFromQuery(queryParam);
          }
          return acc;
        }
        case FacetType.Date: {
          acc['dateFrom'] = object.dateFrom ? +object.dateFrom : null;
          acc['dateTo'] = object.dateTo ? +object.dateTo : null;
          return acc;
        }
        case FacetType.CatalogDate: {
          acc['catalogDate'] = object.catalogDate ? object.catalogDate : null;
          return acc;
        }
        case FacetType.Single: {
          const queryParam = (object as any)[facetSchema.mapField];
          if (queryParam) {
            acc[facetSchema.mapField] = queryParam;
          }
          return acc;
        }
      }
    }, {} as SearchRequestFacets);
  }

  private extractFacetsIdsFromQuery(facetFromQuery: string): string[] {
    return facetFromQuery ? facetFromQuery
    .split(',')
    .map((id) => decodeURIComponent(id)) : [];
  }
}
