<div class="p-4">
  <div class="modal-header d-flex flex-row-reverse">
    <button
      data-automation-id="redirect-dialog-close-icon"
      type="button"
      class="close-btn p-0"
      [attr.aria-label]="'closeDialog' | translate"
      (click)="dismissModal()">
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
  </div>
  <div class="modal-body iframe-wrapper">
    <iframe
      #iframe
      title="Download Redirect Content"
      height="100%"
      width="100%"
      data-automation-id="redirect-url-iframe">
    </iframe>
    <div *ngIf="error" class="d-flex justify-content-center pt-5" aria-atomic="true" aria-live="assertive" role="alert">
      <div data-automation-id="redirect-dialog-error">
        {{'generalServerError' | translate}}
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="ins-buttons button-secondary flint" (click)="dismissModal()"
      data-automation-id="redirect-dialog-cancel-btn"
      translate>cancel
    </button>
  </div>
</div>
