import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { Axis360Availability, BorrowboxAvailability, CloudLibraryAvailability } from 'core/econtent/models/econtent-availability';
import { EcontentAvailability } from 'core/econtent/services/econtent.service';
import { EContentAvailability, OverDriveAvailability } from 'core/over-drive/models/over-drive-availability';
import * as Immutable from 'immutable';
import { SearchCard } from 'search/models/search-card';
import { DrawerState, Locations, QueriedDrawerState } from 'search/models/search-models';
import { BookCover, SelectedRollUpTab } from 'search/models/search-results';
import {
  drawerInformationLoad,
  drawerInformationLoadComplete,
  entityVendorAvailabilityCheckComplete,
  gatesLoadResourceCountComplete,
  loadLocations,
  loadVendorAvailability,
  locationsLoadComplete,
  queryDrawerContent,
  queryDrawerContentComplete,
  resetEntities,
  resetFocusedResourceId,
  resetRollupTabsSelected,
  resetScrollAndSelectedResourceId,
  rollupTabCheckLocationAndEditionInformation,
  rollupTabCollapseLocationAndEditionInformation,
  rollupTabSelected,
  setEntities,
  setFocusedResourceId,
  setScrollPosition,
  setSelectedResourceId
} from '../actions/entity.actions';
import { FormatGroup, VendorType } from '../models/entity';
import { StoredGatesResource } from '../models/gates-results';

export const entityFeatureKey = 'entity';

export type AllVendorAvailability = { [key in VendorType]: { [key: string]: EContentAvailability[] } }
export type AvailabilityType = Immutable.Map<VendorType, Immutable.Map<string, EContentAvailability[]>>

export interface EntityState {
  entities: FormatGroup[];
  selectedInstanceId: string;
  focusedResourceId: string;
  selectedTab: Immutable.Map<string, SelectedRollUpTab>;
  resourceCountData: Immutable.Map<string, StoredGatesResource>;
  availability: AvailabilityType;
  overdrive: Immutable.Map<string, OverDriveAvailability[]>;
  isAxisLoading: Immutable.Map<string, boolean>;
  isBorrowboxLoading: Immutable.Map<string, boolean>;
  isOverdriveLoading: Immutable.Map<string, boolean>;
  isCloudLibraryLoading: Immutable.Map<string, boolean>;
  searchCard?: SearchCard;
  drawer: DrawerState;
  queriedDrawer: QueriedDrawerState;
  drawerLocations: Locations,
  scroll: number;
}

const initialAvailabilityState: AvailabilityType = Immutable.Map(Object.values(VendorType)
  .map(vendorType => [vendorType as VendorType, Immutable.Map()]));

export const initialState: EntityState = {
  entities: [],
  selectedInstanceId: '',
  focusedResourceId: '',
  selectedTab: Immutable.Map(),
  resourceCountData: Immutable.Map(),
  availability: initialAvailabilityState,
  overdrive: Immutable.Map(),
  isAxisLoading: Immutable.Map(),
  isBorrowboxLoading: Immutable.Map(),
  isOverdriveLoading: Immutable.Map(),
  isCloudLibraryLoading: Immutable.Map(),
  drawer: {
    content: null,
    loading: false,
  },
  queriedDrawer: {
    content: null,
    loading: false,
    query: null,
  },
  drawerLocations: {
    content: null,
    loading: false,
  },
  scroll: 0,
};

const entityReducer = createReducer(
  initialState as EntityState,
  on(setEntities, (state, {entities}) => ({...state, entities})),
  on(resetEntities, (state) => ({...state, entities: []})),
  on(setFocusedResourceId, (state, {resourceId}) => ({...state, focusedResourceId: resourceId})),
  on(resetFocusedResourceId, (state) => ({...state, focusedResourceId: ''})),
  on(setScrollPosition, (state, {scrollPosition}) => ({
    ...state,
    scroll: scrollPosition,
  })),
  on(setSelectedResourceId, (state, {resourceId}) => ({
    ...state,
    selectedInstanceId: resourceId,
  })),
  on(resetScrollAndSelectedResourceId, (state) => ({
    ...state,
    scroll: 0,
    selectedInstanceId: '',
  })),
  on(loadVendorAvailability, (state, {vendor, resourceId, isLoading }) => {
    switch (vendor) {
      case VendorType.OVERDRIVE: {
        return {
          ...state,
          isOverdriveLoading: state.isOverdriveLoading.set(resourceId, isLoading)
        };
      }
      case VendorType.AXIS360: {
        return {
          ...state,
          isAxisLoading: state.isAxisLoading?.set(resourceId, isLoading)
        };
      }
      case VendorType.CLOUD_LIBRARY: {
        return {
          ...state,
          isCloudLibraryLoading: state.isCloudLibraryLoading?.set(resourceId, isLoading)
        };
      }
      case VendorType.BORROW_BOX: {
        return {
          ...state,
          isBorrowboxLoading: state.isBorrowboxLoading?.set(resourceId, isLoading)
        };
      }
    }
    return {
      ...state
    };
  }),
  on(entityVendorAvailabilityCheckComplete, (state, {resourceId, info, vendor}) => {
    const newAvailabilities = mappedData(info, vendor) as EContentAvailability[];
    const newReserveIds = newAvailabilities.map(availability => availability.reserveId);
    const currenAvailabilities = (state?.availability?.get(vendor)?.get(resourceId) || [])
      .filter(availability => !newReserveIds.includes(availability.reserveId));
    const previousVendorAvailability = state.availability?.get(vendor);
    return {
      ...state,
      availability: state.availability?.set(vendor, (previousVendorAvailability)
        ?.set(resourceId, currenAvailabilities.concat(newAvailabilities))
      ),
    };
  }),
  on(rollupTabSelected, (state, {resourceId, tab, skipFirstAutomaticSelection}) => ({
    ...state,
    selectedTab: state.selectedTab.set(resourceId, {
      selectedIndex: tab,
      expandLocationAndEditionInformation: false,
      skipFirstAutomaticSelection,
    }),
  })),

  on(rollupTabCheckLocationAndEditionInformation, (state, {resourceId, tabIndex}) => ({
    ...state,
    selectedTab: state.selectedTab.set(resourceId, {
      selectedIndex: tabIndex,
      expandLocationAndEditionInformation: true,
    }),
  })),

  on(rollupTabCollapseLocationAndEditionInformation, (state, {resourceId}) => ({
    ...state,
    selectedTab: state.selectedTab.update(resourceId, (oldValue: SelectedRollUpTab) => ({
      ...oldValue,
      expandLocationAndEditionInformation: false,
    })),
  })),
  on(resetRollupTabsSelected, (state) => ({
    ...state,
    selectedTab: Immutable.Map(),
  })),
  on(gatesLoadResourceCountComplete, (state, {entries}) => {
    const newState = entries.reduce((acc, cur) => {
      return acc.set(cur.bibId, {
        loading: false,
        error: false,
        entity: cur,
      });
    }, state.resourceCountData);

    return {
      ...state,
      resourceCountData: newState,
    };
  }),
  on(drawerInformationLoad, (state) => ({
    ...state,
    drawer: {
      content: null,
      loading: true,
    },
    queriedDrawer: {
      content: null,
      loading: true,
      query: null,
    }
  })),
  on(drawerInformationLoadComplete, (state, {holdings, items}) => ({
    ...state,
    drawer: {
      content: {items, holdings},
      loading: false,
    },
    queriedDrawer: {
      content: {items, holdings},
      loading: false,
      query: state.queriedDrawer.query
    }
  })),
  on(queryDrawerContent, (state, {query}) => ({
    ...state,
    queriedDrawer: {
      ...state.queriedDrawer,
      query
    },
  })),
  on(queryDrawerContentComplete, (state, {holdings, items}) => ({
    ...state,
    queriedDrawer: {
      content: {items, holdings},
      loading: false,
      query: state.queriedDrawer.query
    },
  })),
  on(loadLocations, (state) => ({
    ...state,
    drawerLocations: {
      ...state.drawerLocations,
      loading: true,
    },
  })),
  on(locationsLoadComplete, (state, {locations}) => ({
    ...state,
    drawerLocations: {
      content: locations,
      loading: false,
    },
  })),
);

export function reducer(state: EntityState, action: Action) {
  return entityReducer(state, action);
}

const entityState = createFeatureSelector<EntityState>(entityFeatureKey);

export const getEntities = createSelector(entityState, (state) => state.entities);

export const getTopResultsCovers = createSelector(getEntities, (entities): BookCover[] => {
  return entities?.slice(0, 5).map((fg) => ({ coverUrl: fg.coverUrl })) || [];
});

export const getVendorAvailability = (vendorType: VendorType) => createSelector(entityState, (state): { [key: string]: EContentAvailability[] } =>
state && state?.availability?.get(vendorType as VendorType)?.toJS() as { [key: string]: EContentAvailability[] });

export const getAvailability = createSelector(entityState, (state): AllVendorAvailability =>
  state && state?.availability?.toJS() as AllVendorAvailability);

export const getOverDrive = createSelector(entityState, (state): { [key: string]: OverDriveAvailability[] } =>
  state && state?.overdrive?.toJS() as { [key: string]: OverDriveAvailability[] });
export const isLoadingInProgress = (resourceId: string) => createSelector(entityState, (state): boolean => {
  return state?.isAxisLoading?.get(resourceId) ||
    state?.isOverdriveLoading?.get(resourceId) ||
    state?.isCloudLibraryLoading?.get(resourceId) ||
    state?.isBorrowboxLoading?.get(resourceId);
});

export const getResourceCountData = createSelector(entityState, (state): { [key: string]: StoredGatesResource } =>
  state && state.resourceCountData.toJS() as { [key: string]: StoredGatesResource });

export const getSelectedResourceId = createSelector(entityState, (searchState) => {
  return searchState.selectedInstanceId;
});

export const getFocusedResourceId = createSelector(entityState, (searchState) => {
  return searchState.focusedResourceId;
});

export const getSelectedRollupTabs = createSelector(entityState, (state) => (state) ? state.selectedTab.toJS() : null);

export const getSelectedRollupTab = (resourceId: string) => createSelector(getSelectedRollupTabs,
  (rollupTabs: { [key: string]: SelectedRollUpTab }): SelectedRollUpTab => (rollupTabs && rollupTabs[resourceId]) ? rollupTabs[resourceId] : null);


export const getSelectedRollupTabIndex = (resourceId: string) => createSelector(getSelectedRollupTab(resourceId),
  (selectedRollupTab: SelectedRollUpTab): number => selectedRollupTab ? selectedRollupTab.selectedIndex : 0);

export const getDrawer = createSelector(entityState, (searchState) => {
  return searchState && {...searchState.drawer };
});

export const getQueriedDrawer = createSelector(entityState, (searchState) => {
  return searchState && {...searchState.queriedDrawer};
});

export const getDrawerLocations = createSelector(entityState, (searchState) => searchState.drawerLocations);

export const mappedData = (
  info: Array<OverDriveAvailability |Axis360Availability | CloudLibraryAvailability | BorrowboxAvailability>,
  vendorType: VendorType
  ): EContentAvailability[] => {
  switch (vendorType) {
    case VendorType.OVERDRIVE: {
      return info as EContentAvailability[];
    }
    default: {
      const vendorData = info as EcontentAvailability;
      return vendorData?.map((el) => ({...el, reserveId: el.contentId}));
    }
  }
};
