import { createAction, props } from '@ngrx/store';
import { ItemVolume, NlbPayload, Volume } from 'user/models/get-it';

export const nlbEditionHold = createAction(
  '[NLB Hold] NLB placing hold by edition ',
  props<{nlbPayload: NlbPayload}>()
);

export const nlbEditionVolumeHold = createAction(
  '[NLB Hold] NLB edition volume hold',
  props<{volumes: Volume[]}>()
);

export const nlbEditionItemAsVolumeHold = createAction(
  '[NLB Hold] NLB edition items as volume hold',
  props<{itemsAsVolumes: ItemVolume[], recordNumber: string}>()
);

export const nlbEditionHoldFinalize = createAction(
  '[NLB Hold] NLB edition finalize',
  props<{recordNumber: string}>()
);
