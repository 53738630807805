<div class="px-3 pb-2 showcase-generation-popup">
  <div class="modal-header align-items-center">
    <h3 class="modal-title" data-automation-id="showcase-generation-title" translate>generateEmbeddableShowcase</h3>
    <button
      class="close-btn p-0 m-n2"
      type="button"
      data-automation-id="close-showcase-gen-dialog-btn"
      [attr.aria-label]="'closeDialog' | translate"
      (click)="onClose()">
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
  </div>
  <div class="modal-body pb-0">
    <form class="mb-0" [formGroup]="showcaseGenForm">
      <div class="form-group">
        <div class="mt-3">
          <label class="mb-2" for="showcase-list-select" data-automation-id="showcase-list-select-title" translate>
            makeShowcaseFrom
          </label>
          <select
            data-automation-id="showcase-list-select"
            class="w-100 showcase-list-select"
            formControlName="showcaseId"
            id="showcase-list-select">
            <option
              data-automation-id="showcase-list-option"
              *ngFor="let showcase of publishedShowcases; trackBy: trackById"
              [value]="showcase.id">
              {{ showcase.name }}
            </option>
          </select>
        </div>
        <div class="mt-2">
          <fieldset>
            <legend class="mb-2" data-automation-id="books-display-amount" translate>
              booksDisplayAmount
            </legend>
            <div class="d-flex mt-5 w-100 justify-content-around">
              <div *ngFor="let slideView of booksDisplayAmount" class="d-flex flex-column align-items-center">
                <div class="d-flex mb-3" data-automation-id="slide-view">
                  <!-- generates columns to represent showcase slide view -->
                  <div
                    class="slide-preview-block"
                    data-automation-id="slide-view-block"
                    *ngFor="let n of slideView.booksNum | range">
                  </div>
                  <label class="sr-and-mobile-only" [for]="'display-amount' + slideView.booksNum">
                    {{slideView.booksNum}}
                  </label>
                </div>
                <input
                  type="radio"
                  [id]="'display-amount' + slideView.booksNum"
                  formControlName="displayAmount"
                  data-automation-id="books-display-radio-btn"
                  [value]="slideView.booksNum">
              </div>
            </div>
          </fieldset>
        </div>
        <div class="mt-3">
          <label class="mb-2" for="total-books-num" data-automation-id="total-books-num" translate>
            booksTotalAmount
          </label>
          <div>
            <select id="total-books-num" formControlName="totalAmount" data-automation-id="total-books-num-select">
              <!-- numbers from 2 to 20 -->
              <option
                data-automation-id="total-books-num-option"
                *ngFor="let num of (maxBooksNum - 1) | range : 2"
                [selected]="num === defaultTotalAmount"
                [value]="num">
                {{ num }}
              </option>
            </select>
            <span class="showcase-generation-info-text ml-2" data-automation-id="max-books-num">
              {{ 'maxOf' | translate }} {{ maxBooksNum }}
            </span>
          </div>
        </div>
        <div
          *ngIf="showcaseGenForm.errors?.booksAmount"
          data-automation-id="showcase-generation-error-message"
          class="showcase-generation-error-message"
          aria-live="assertive"
          role="alert"
          translate>
          booksAmountValidationError
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="w-100">
      <label data-automation-id="script-section-title" translate>Script</label>
      <span class="showcase-generation-info-text" data-automation-id="script-label" translate>scriptLabel</span>
      <div
        class="d-flex flex-column flex-sm-row align-items-center justify-content-between mt-3 mb-10 showcase-generation-info-text">
        <div class="d-flex align-items-center justify-content-center showcase-widget-script">
          <div class="m-1 p-2 d-flex align-items-center justify-content-center showcase-widget-script--inner">
            <span *ngIf="showcaseWidgetScript; else placeholder" data-automation-id="widget-script">
              {{ showcaseWidgetScript }}
            </span>
            <ng-template #placeholder>
              <span data-automation-id="script-placeholder" translate>scriptPlaceholder</span>
            </ng-template>
          </div>
        </div>
        <button class="btn ins-buttons flint copy-button" data-automation-id="copy-script-btn" (click)="copyScript()">
          <span translate>copy</span>
        </button>
      </div>
    </div>
    <div class="w-100 d-flex justify-content-between align-items-center">
      <button class="btn-lg cancel-button" data-automation-id="cancel-showcase-gen-dialog-btn" (click)="onClose()">
        <span translate>cancelButton</span>
      </button>
      <button
        class="ins-buttons button-primary flint generate-button" data-automation-id="generate-button"
        (click)="generateScript()">
        <span translate>generateEmbedCode</span>
        <span class="fa fa-check-circle-o ml-2" aria-hidden="true"></span>
      </button>
    </div>
  </div>
</div>


