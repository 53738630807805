import { Action, ActionReducer, ActionReducerMap, } from '@ngrx/store';

import { environment } from 'environments/environment';
import {
  addToList,
  clear,
  removeFromList,
  removeSelectedFromListComplete,
  setAllItemsSelection,
  toggleItemSelection
} from '../list/actions/for-later-list.actions';
import {
  backupStateToJsonString,
  forLaterListFeatureKey,
  mergeStates,
  restoreStateFromJsonString
} from '../list/reducers/for-later-list.reducer';

const STORAGE = '@ngrx/store/storage';
const UPDATE = '@ngrx/store/update-reducers';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */

// uncomment when state is filled with anything
// eslint-disable-next-line
export interface State {
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<State> = {};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<any, any> {
  return (state: any, action: any): State => reducer(state, action);
}

/**
 * This meta reducer is necessary for keeping ngrx browser tab state in sync.
 * For now, only key "auth" is syncing.
 * @export localStorageSyncReducer
 * @param {ActionReducer<any>} reducer
 * @returns {ActionReducer<any>}
 */

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: any, action: any): State => {
    const forLaterListKey = forLaterListFeatureKey;
    if (
      (action.type === STORAGE && action.features && action.features.includes(forLaterListKey)) ||
      (action.type === UPDATE && action.features && action.features.includes(forLaterListKey) && localStorage.getItem(forLaterListKey))
    ) {

      const rehydratedState = restoreStateFromJsonString(localStorage.getItem(forLaterListKey));
      const defaultState = reducer(state, action);

      if (rehydratedState) {
        return {
          ...defaultState,
          [forLaterListKey]: mergeStates(defaultState[forLaterListKey], rehydratedState),
        };
      }

      return defaultState;
    } else if (isForLaterListType(action)) {
      const newState = reducer(state, action);
      if (newState && newState[forLaterListKey]) {
        localStorage.setItem(forLaterListKey, backupStateToJsonString(newState[forLaterListKey]));
      }

      return newState;
    } else {
      return reducer(state, action);
    }
  };
}

function isForLaterListType(action: Action): boolean {
  return ([
    addToList.type,
    removeFromList.type,
    removeSelectedFromListComplete.type,
    toggleItemSelection.type,
    setAllItemsSelection.type,
    clear.type,
  ] as string[]).indexOf(action.type) >= 0;
}

/* istanbul ignore next */
export const metaReducers: any[] = !environment.production
  ? [logger, localStorageSyncReducer]
  : [localStorageSyncReducer];
