<div *ngIf="formatByCode?.alwaysAvailable; else notAlwaysAvailable"
     class="status alwaysAvailable d-inline-flex "
     [class.text-capitalize]="capitalize"
     data-automation-id="rollup-tabs-content-status-container">
     <span class="available" translate>tabsAvailable</span>
</div>

<ng-template #notAlwaysAvailable>
  <div
     [ngSwitch]="availabilityStatus"
     class="status d-inline-flex"
     [class.text-capitalize]="capitalize"
     data-automation-id="rollup-tabs-content-status-container">
    <span *ngSwitchDefault class="unavailable" translate [attr.aria-label]="'tabsUnavailable' | translate">tabsUnavailable</span>
    <span *ngSwitchCase="availabilityStatuses.UNAVAILABLE" class="unavailable" translate [attr.aria-label]="'tabsUnavailable' | translate">tabsUnavailable</span>
    <span *ngSwitchCase="availabilityStatuses.AVAILABLE" class="available" translate [attr.aria-label]="'tabsAvailable' | translate">tabsAvailable</span>
    <span *ngSwitchCase="availabilityStatuses.GET_ISSUES" class="available" translate [attr.aria-label]="'tabsAvailable' | translate">tabsAvailable</span>
    <span *ngSwitchCase="availabilityStatuses.GET_MATERIAL" class="available" translate [attr.aria-label]="'tabsAvailable' | translate">tabsAvailable</span>
    <span *ngSwitchCase="availabilityStatuses.ON_ORDER" class="available" translate [attr.aria-label]="'tabsOnOrder' | translate">tabsOnOrder</span>
    <span *ngSwitchCase="availabilityStatuses.CHECKED_OUT" class="checked-out" translate [attr.aria-label]="'tabsCheckedOut' | translate">tabsCheckedOut</span>
    <span *ngSwitchCase="availabilityStatuses.CHECK_AVAILABILITY" class="check-availability" translate [attr.aria-label]="'tabsCheckAvailability' | translate">tabsCheckAvailability</span>     
  </div>
</ng-template>
