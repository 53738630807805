import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { CustomDropdownOptionComponent } from 'common/components/custom-dropdown/custom-dropdown-option/custom-dropdown-option.component';
import { DropdownValue } from 'common/models/custom-dropdown';
import { merge, Observable, Subscription } from 'rxjs';
import { startWith, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss'],
})
export class CustomDropdownComponent implements OnInit, OnDestroy, AfterContentInit, OnChanges {
  public readonly OVERLAY_OFFSET_X = 0;
  public readonly OVERLAY_OFFSET_Y = 5;

  public defaultPositionConfig: ConnectionPositionPair[];
  public topPositionConfig: ConnectionPositionPair[];
  public subscriptions = new Subscription();

  @Input() public selectedOption: string;
  @Input() public isTopOriented: string;
  @Input() public overlayOffsetX = 0;
  @Input() public fixedWidth: boolean = false;
  @Input() public focus$: Observable<undefined>;
  @Input('aria-label') public ariaLabel: string;
  @Output() public change: EventEmitter<DropdownValue<any>> = new EventEmitter();
  @ViewChild('toggleDropdownButton') public toggleDropdownButton: ElementRef;
  @ContentChildren(CustomDropdownOptionComponent) public options: QueryList<CustomDropdownOptionComponent>;
  public keyManager: ActiveDescendantKeyManager<CustomDropdownOptionComponent>;
  public isExpanded = false;
  public valueSelected: string;
  public labelSelected: string;
  public imageSelected: string;
  public isMouseEvent: boolean;
  public arrowIconDown = faAngleDown;
  private selectedOptionIndex = 0;
  private subscription = new Subscription();

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
  }

  public ngOnInit() {
    this.defaultPositionConfig = [
      new ConnectionPositionPair(
        {originX: 'end', originY: 'bottom'},
        {overlayX: 'end', overlayY: 'top'},
        this.overlayOffsetX ? this.overlayOffsetX : this.OVERLAY_OFFSET_X,
        this.OVERLAY_OFFSET_Y,
      ),
    ];

    this.topPositionConfig = [
      new ConnectionPositionPair(
        {originX: 'end', originY: 'top'},
        {overlayX: 'end', overlayY: 'bottom'},
        this.overlayOffsetX ? this.overlayOffsetX : this.OVERLAY_OFFSET_X,
        -this.OVERLAY_OFFSET_Y,
      ),
    ];

    if (this.focus$) {
      this.subscription.add(this.focus$.subscribe(() => {
        setTimeout(() => {
          this.toggleDropdownButton.nativeElement.focus();
        });
      }));
    }
  }

  public ngAfterContentInit(): void {
    this.keyManager = new ActiveDescendantKeyManager(this.options).withWrap();

    this.options.changes.pipe(
      startWith(this.options),
      tap((options) => {
        const selectedOption = options.find((option: any) => option.value.toString() === this.selectedOption) || options.first;
        selectedOption.selected = true;
        this.valueSelected = selectedOption.value;
        this.labelSelected = selectedOption.label;
        this.imageSelected = selectedOption?.image;
        this.selectedOptionIndex = this.options.toArray().findIndex((option) => option.value === this.valueSelected);
      }),
      switchMap((options) => merge(...options.map((option: any) => option.select))),
    ).subscribe((optionSelectEvt) => this.select(optionSelectEvt));
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.options && changes?.selectedOption && changes.selectedOption.previousValue !== changes.selectedOption.currentValue) {
      const selectedOption = this.options.find((option: any) => option.value.toString() === this.selectedOption) || this.options.first;
      selectedOption.selectOption();
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public keyHandler(event: KeyboardEvent): boolean {
    switch (event.key) {
      case 'Escape':
        this.close();
        break;

      case 'Enter':
      case ' ':
      case 'Spacebar':
        if (this.keyManager.activeItemIndex !== -1) {
          this.keyManager.activeItem.selectOption();
        }
        break;

      default:
        this.keyManager.onKeydown(event);
    }

    return false;
  }

  public select(option: any): void {
    const {value, label, image = ''} = option;

    const previousOption = this.options.find((option) => option.value === this.valueSelected);

    if (previousOption && previousOption.value !== value) {
      previousOption.selected = false;

      this.valueSelected = value;
      this.labelSelected = label;
      this.imageSelected = image;
      this.selectedOptionIndex = this.options.toArray().findIndex((option) => option.value === this.valueSelected);

      this.change.emit({selected: value, label, image, isMouseEvent: this.isMouseEvent});
    }
    this.close();
  }

  public close(): void {
    this.isExpanded = false;
    this.changeDetectorRef.markForCheck();
  }

  public open(): void {
    this.isExpanded = true;
  }

  public toggle(): void {
    if (this.isMouseEvent) {
      this.keyManager.setActiveItem(-1);
    } else {
      this.keyManager.setActiveItem(this.selectedOptionIndex);
    }
    this.isExpanded ? this.close() : this.open();
  }

  @HostListener('mouseover')
  public onMouseover() {
    this.keyManager.setActiveItem(-1);
    this.isMouseEvent = true;
  }

  @HostListener('mouseout')
  public onMouseout() {
    this.isMouseEvent = false;
  }
}
