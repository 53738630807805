import { ChangeDetectorRef, Component, ContentChild, Input, OnInit, TemplateRef, } from '@angular/core';
import { select, Store, } from '@ngrx/store';
import { AvailabilityService } from 'app/entity/services/availability.service';
import { EContentAvailability } from 'core/over-drive/models/over-drive-availability';
import { combineLatest, Subscription, } from 'rxjs';
import { ConfigurationLoader } from 'shared/configuration-loader';
import {
  AvailabilityUpdateEditionInfo,
  AvailabilityVendorType,
  Edition,
  EditionType,
  VendorType
} from '../../entity/models/entity';
import { getAvailability, isLoadingInProgress } from '../../entity/reducers/entity.reducer';
import { VendorIssuesService } from 'app/entity/services/vendor-issues.service';

@Component({
  selector: 'app-overdrive-and-sierra-edition-availability-container',
  // eslint-disable-next-line max-len
  template: '<ng-container *ngTemplateOutlet="containerData ? childTemplate : null; context:{availabilityUpdates: availabilityUpdates, hasEditionEcontentIssues: hasEditionEcontentIssues}">' +
    '</ng-container>',
})
export class OverdriveAndSierraEditionAvailabilityContainer implements OnInit {
  @Input() public type: EditionType;
  @Input() public containerData: Edition[];
  @Input() public resourceId: string;
  @ContentChild(TemplateRef) public childTemplate: TemplateRef<{
    availabilityUpdates: AvailabilityUpdateEditionInfo[],
    hasEditionEcontentIssues: boolean
  }>;

  public availabilityUpdates: AvailabilityUpdateEditionInfo[];
  private subscriptions = new Subscription();
  public hasEditionEcontentIssues = false;

  public constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly store: Store,
    private readonly availabilityService: AvailabilityService,
    private readonly configLoader: ConfigurationLoader,
    private readonly vendorIssuesService: VendorIssuesService
  ) { }

  public ngOnInit(): void {
    this.subscriptions.add(combineLatest([
      this.store.pipe(select(getAvailability)),
      this.store.pipe(select(isLoadingInProgress(this.resourceId)))
    ]).subscribe(([availability, isLoadingInProgress]) => {
      this.availabilityUpdates = this.containerData.map((edition): AvailabilityUpdateEditionInfo => {
        if (isLoadingInProgress) {
          return { status: {[AvailabilityVendorType.GENERAL]: edition.availabilityStatus}, eVendorAvailabilityPending: true };
        }
        if (this.type === EditionType.ELECTRONIC) {
          this.hasEditionEcontentIssues = this.vendorIssuesService.hasVendorIssuesForEdition(edition);
          const transformAvailability = {} as Partial<Record<VendorType,EContentAvailability[]>>;
          for (let vendorType in availability) {
            transformAvailability[vendorType as VendorType] = availability[vendorType as VendorType][this.resourceId] || [];
          }

          return this.availabilityService.countEContentAvailabilityForEditions(transformAvailability, edition);
        }
        return {status: {[AvailabilityVendorType.GENERAL]: edition.availabilityStatus}};
      });
        this.cdRef.markForCheck();
    }));
  }
}
