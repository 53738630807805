<app-checked-and-held-items-update-container
  *customerFeatureToggle="CustomerFeature.HeldItemsBanner"
  [recordIds]="recordIds"
  [instanceIds]="instanceIds"
  [hasEcontentIssues]="hasEcontentIssues"
  [vendors]="vendors">
  <ng-template let-checkedOutCount="checkedOutCount" let-heldCount="heldCount">
    <app-checked-and-held-items-banner
      [checkedOutCount]="checkedOutCount"
      [heldCount]="heldCount"
      [customHoldsLink]="customHoldsLink"
      [customCheckoutsLink]="customCheckoutsLink">
    </app-checked-and-held-items-banner>
  </ng-template>
</app-checked-and-held-items-update-container>
