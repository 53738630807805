import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { RelatedItem } from '../../models/related-items-result';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss'],
})
export class ItemDetailsComponent implements OnInit {
  @Input() public itemTitle: string;
  @Input() public item: RelatedItem;
  public subheading: string;
  public displayNotes: boolean;

  constructor(
    private readonly ngbActiveModal: NgbActiveModal,
    private readonly configLoader: ConfigurationLoader,
  ) {
  }

  ngOnInit() {
    this.displayNotes = this.configLoader.displayConfiguration.displayNotes;
    this.subheading = [this.item?.location, this.item?.callNumber].filter(Boolean).join(' ');
  }

  onClose() {
    this.ngbActiveModal.close();
  }
}
