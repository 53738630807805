/*
* Browsers store the delay as a 32-bit signed integer internally.
* This causes an integer overflow when using delays larger than 2,147,483,647 ms
* (about 24.8 days), resulting in the timeout being executed immediately.
* This function returns the maximum delay value if the input value exceeds the limit.
* */
export function limitTimeoutDelay(timeoutDelay: number) {
  const maxDelayValue = 2147483647; // maximum timeout delay in ms
  return timeoutDelay > maxDelayValue ? maxDelayValue : timeoutDelay;
}
