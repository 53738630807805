import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bookshelf-remove-selected-from-list-button',
  templateUrl: './bookshelf-remove-selected-from-list-button.component.html',
  styleUrls: ['./bookshelf-remove-selected-from-list-button.component.scss'],
})
export class BookshelfRemoveSelectedFromListButtonComponent {
  @Input() public itemsSelectedCount: number;
  @Input() public buttonClass: string;
  @Output() public readonly remove = new EventEmitter<undefined>();

  public removeDropdownOpened = false;

  public removeSelectedFromList(dropdown: NgbDropdown): void {
    this.remove.emit();
    dropdown.close();
  }

  public toggleRemoveConfirmation(dropdown: NgbDropdown): void {
    if (this.itemsSelectedCount && !this.removeDropdownOpened) {
      dropdown.open();
    } else {
      dropdown.close();
    }
  }

  public removeDropdownOpenChange(opened: boolean): void {
    this.removeDropdownOpened = opened;
  }
}
