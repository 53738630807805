import { Component, Input } from '@angular/core';
import { faCaretUp, faCaretDown } from '@fortawesome/pro-regular-svg-icons';
import { ListItemEntity } from '../../../list/models/list';

@Component({
  selector: 'app-select-list-unavailable-items',
  templateUrl: './select-list-unavailable-items.component.html',
  styleUrls: ['./select-list-unavailable-items.component.scss']
})
export class SelectListUnavailableItemsComponent {
  @Input() public unavailableItems: ListItemEntity[];
  public readonly caretUpIcon = faCaretUp;
  public readonly caretDownIcon = faCaretDown;
  public isCollapsed = true;

  public onToggleCollapsibleList() {
    this.isCollapsed = !this.isCollapsed;
  }
}
