<div class="email-container p-4">
  <app-modal-header
    title="{{'email' | translate}}"
    titleId="email-modal-title"
    closeId="close-popup"
    (close)="close()">
  </app-modal-header>
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-group">
        <label for="emailTo">{{ 'toField' | translate }} *</label>
        <input formControlName="to" type="email" class="form-control form-control-lg" id="emailTo"
               [class.is-invalid]="toControl.invalid && (toControl.touched  || toControl.dirty)"
               placeholder="{{ 'emailSample' | translate }}" data-automation-id="to-field">
        <div aria-atomic="true" aria-live="assertive" role="alert">
          <div class="invalid-feedback d-block" *ngIf="toControl.invalid && toControl.hasError('required') && (toControl.touched  || toControl.dirty)"
               data-automation-id="invalid-email-required">{{'emailRequired' | translate}}</div>
          <div class="invalid-feedback d-block" *ngIf="toControl.invalid && !toControl.hasError('required') && (toControl.touched  || toControl.dirty)"
               data-automation-id="invalid-email-format" translate>{{'emailValidation' | translate}}</div>
        </div>
      </div>
      <div class="form-group">
        <label for="subject" translate>subject</label>
        <input formControlName="subject" type="text" class="form-control form-control-lg" id="subject" data-automation-id="subject-field" readonly>
      </div>
    </form>
  </div>
  <div class="modal-footer d-flex justify-content-start">
    <button translate [disabled]="!form.valid" class="ins-buttons button-primary flint" data-automation-id="send-email" (click)="send()">sendButton</button>
    <button translate class="ins-buttons button-secondary flint ml-3" data-automation-id="cancel-email" (click)="close()">cancelButton</button>
  </div>
</div>
