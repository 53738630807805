import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import Keycloak, { KeycloakConfig, KeycloakInitOptions, KeycloakInstance } from 'keycloak-js';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { limitTimeoutDelay } from 'shared/utils/limit-timeout-delay';
import { toggleOpenedUserPanel } from 'user/actions/user-profile.actions';
import { LOCAL_STORAGE } from '../../models/local-storage';
import { CustomDomainLoader } from '../../services/custom-domain-loader';
import { PendoService } from '../../services/pendo.service';
import { WindowRefService } from '../../services/window-ref.service';
import { loggedIn, logout, updateAuthData, willLogoutSoon, staffLoggedIn, } from '../actions/keycloak.actions';

import { AuthData, KeycloakLoginError, KeycloakRole, KeycloakTokenParsedWithClaims, TokenData, UserInfo } from '../models/keycloak';
import { KeycloakState } from '../reducers/keycloak.reducer';

@Injectable()
export class KeycloakService {
  public keycloak: KeycloakInstance;
  private sessionEndsTimer: any;
  private idpHint: string;
  private defaultIdpHint = 'cas';
  private readonly memorizedUsernameKey = 'memorizedUsername';

  constructor(
    public readonly store: Store<KeycloakState>,
    private readonly http: HttpClient,
    private readonly pendoService: PendoService,
    private readonly windowRefService: WindowRefService,
    private readonly configurationLoader: ConfigurationLoader,
    private readonly customDomainLoader: CustomDomainLoader,
  ) {
  }

  public createKeycloakInstance(): KeycloakInstance {
    const domain = this.customDomainLoader.domain;
    const sites = domain.split('.')[0].split('-');
    const client = sites.length > 1 ? `-site-${sites[0]}` : '';
    const config: KeycloakConfig = {
      url: typeof KEYCLOAK_URL === 'undefined' ? `https://${domain}` : KEYCLOAK_URL.replace(/:443/, ''),
      realm:  ENVIRONMENT === 'local' ? 'stafftest' : domain.split('.')[0].replace(/^[^.]*-/, ''),
      clientId: `convergence${client}`,
    };
    return Keycloak(config);
  }

  public init() {
    this.keycloak = this.createKeycloakInstance();

    const token = this.windowRefService.localStorage.bearerToken;
    const refreshToken = this.windowRefService.localStorage.refreshToken;
    const hostUrl = 'api/customer/customers/connection/idptype';

    this.http.get<any>(hostUrl, {observe: 'response'}).pipe(
      catchError((err: HttpErrorResponse) => {
        this.idpHint = this.defaultIdpHint;
        if (err.status === 401) {
          return of(null);
        } else {
          return throwError(err);
        }
      }),
    ).subscribe(
      (resp) => {
        if (resp) {
          if (resp.body.hasOwnProperty('idpType')) {
            this.idpHint = resp.body.idpType;
          } else {
            this.idpHint = this.defaultIdpHint;
          }
        } else {
          this.idpHint = this.defaultIdpHint;
        }
      },
      (err) => {
        console.error(err);
      },
    );
    return new Promise<void>((resolve) => {
      setTimeout(resolve, 2000);

      const options: KeycloakInitOptions = {token, refreshToken};
      options.enableLogging = true;
      options.timeSkew = 0;
      options.checkLoginIframe = false;

      this.keycloak.init(options)
      .then((authenticated) => {
        if (authenticated && this.keycloak.refreshTokenParsed.exp * 1000 > Date.now()) {
          if (!this.keycloak.hasResourceRole(KeycloakRole.PATRON, 'convergence')) {
            this.store.dispatch(staffLoggedIn({authData: this.composeAuthDataParams()}));
          } else {
            this.store.dispatch(loggedIn({authData: this.composeAuthDataParams()}));
          }
          this.restartEndSessionTimer();
        } else {
          this.store.dispatch(logout());
        }

        resolve();
      });
    });
  }

  public startLogin(): void {
    if (this.isLoginWithCredentialsConfigured()) {
      this.store.dispatch(toggleOpenedUserPanel({isOpened: true}));
    } else {
      this.login();
    }
  }

  public makeUmaRequest(token: string, resources: string[]): Observable<TokenData> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${token}`,
    });

    let formData = new HttpParams()
      .set('audience', 'authorization')
      .set('grant_type', 'urn:ietf:params:oauth:grant-type:uma-ticket');

    resources.forEach(resource => {
      formData = formData.append('permission', resource);
    });
    const umaUrl = `${this.keycloak.authServerUrl}/realms/${this.keycloak.realm}/protocol/openid-connect/token`;
    return this.http.post<{ access_token: string, refresh_token: string }>(umaUrl, formData, { headers });
  }

  public login() {
    this.keycloak.login({redirectUri: this.windowRefService.nativeWindow().location.href, idpHint: this.idpHint});
  }

  public loginWithCredentials(username: string, password: string, rememberUserName: boolean): Observable<true | KeycloakLoginError> {
    const formData = new HttpParams()
    .set('password', password)
    .set('username', username)
    .set('client_id', this.keycloak.clientId)
    .set('grant_type', 'password');

    return this.http.post<{ access_token: string, refresh_token: string }>(
      `${this.keycloak.authServerUrl}/realms/${this.keycloak.realm}/protocol/openid-connect/token`,
      formData,
    ).pipe(
      map((data) => {
        if (rememberUserName) {
          this.windowRefService.localStorage.setItem(this.memorizedUsernameKey, username);
        } else {
          this.windowRefService.localStorage.removeItem(this.memorizedUsernameKey);
        }
        this.windowRefService.localStorage.setItem(LOCAL_STORAGE.BEARER_TOKEN, data.access_token);
        this.windowRefService.localStorage.setItem(LOCAL_STORAGE.REFRESH_TOKEN, data.refresh_token);
        this.windowRefService.nativeWindow().location.reload();
        return true as const;
      }),
      catchError((err) => {
        const error: KeycloakLoginError = (err.status === 401) ? 'incorrect-credentials' : 'unknown';
        return of(error);
      }),
    );
  }

  public logout(redirectUri?: string) {
    const refreshToken = this.keycloak.refreshToken || this.windowRefService.localStorage.getItem(LOCAL_STORAGE.REFRESH_TOKEN);
    this.http.post('token/blacklist', refreshToken).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          return of(null);
        } else {
          return throwError(err);
        }
      }),
    ).subscribe(
      () => {
        this.store.dispatch(logout());
        this.pendoService.clearSession();
        this.keycloak.logout({redirectUri});
      },
      (err) => {
        console.error(err);
      },
    );
  }

  public updateToken(restartEndSessionTimer = false, minValidity = -1): Promise<void> {
    return this.keycloak.updateToken(minValidity)
    .then((tokenUpdated) => {
      if (!tokenUpdated) {
        return;
      }
      if (restartEndSessionTimer) {
        this.restartEndSessionTimer();
      }
      this.store.dispatch(updateAuthData({authData: this.composeAuthDataParams()}));
    })
    .catch(() => {
      this.store.dispatch(logout());
    });
  }

  public get memorizedUsername(): string | null {
    return this.windowRefService.localStorage.getItem(this.memorizedUsernameKey);
  }

  public get resetPasscodeLink(): string | null {
    const uri = encodeURIComponent(this.windowRefService.origin());
    const {authServerUrl, realm, clientId} = this.keycloak;
    return `${authServerUrl}/realms/${realm}/login-actions/reset-credentials?client_id=${clientId}&redirect_uri=${uri}`;
  }

  public get tokenParsed(): KeycloakTokenParsedWithClaims {
    return this.keycloak.tokenParsed as KeycloakTokenParsedWithClaims;
  }

  public get keycloakRoles(): KeycloakRole[] {
    return this.tokenParsed?.resource_access?.convergence?.roles || [];
  }

  private composeAuthDataParams(): AuthData {
    const {preferred_username, given_name, family_name, email} = this.tokenParsed;
    const userInfo: UserInfo = {
      roles: this.keycloakRoles,
      userName: preferred_username,
    };
    if (given_name) {
      userInfo.firstName = given_name;
    }
    if (family_name) {
      userInfo.lastName = family_name;
    }
    if (email) {
      userInfo.email = email;
    }
    return {
      userInfo,
      bearerToken: this.keycloak.token,
      refreshToken: this.keycloak.refreshToken,
    };
  }

  private restartEndSessionTimer() {
    const endSessionTimeout = (this.keycloak.refreshTokenParsed.exp - 60) * 1000 - Date.now();
    clearTimeout(this.sessionEndsTimer);
    this.sessionEndsTimer = setTimeout(() => this.store.dispatch(willLogoutSoon()), limitTimeoutDelay(endSessionTimeout));
  }

  private isLoginWithCredentialsConfigured(): boolean {
    return this.configurationLoader.loginType === 'modal';
  }

}
