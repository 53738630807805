<ng-container [formGroup]="control">
  <div *ngFor="let value of control.controls; index as i" class="form-control-row">
    <ng-container [formGroupName]="i">
      <div class="d-flex input-wrapper">
        <div class="d-flex flex-column w-100 mb-1">
          <app-profile-form-field-input
            #fieldInput
            [formControlName]="'phone'"
            [formControlLabel]="inputLabel"
            [autocompleteValue]="'tel'"
            (change)="onChange(i)">
          </app-profile-form-field-input>
          <div
            *ngIf="getControl(i, 'phone').invalid && getControl(i, 'phone').touched"
            class="invalid-feedback d-block"
            aria-atomic="true"
            aria-live="assertive"
            role="alert"
            data-automation-id="phone-required-error">
            <ng-container *ngIf="getControl(i, 'phone').errors.siblingRequired">
              {{ 'profileFieldErrorRequired' | translate: { label: inputLabel ?? '' } }}
            </ng-container>
          </div>
        </div>
        <app-remove-button
          *ngIf="control.controls.length > 1"
          [controlsId]="fieldInput.formControlLabel"
          [inputValue]="value.value.phone"
          (onClick)="removeControl(i)">
        </app-remove-button>
      </div>
      <div class="d-flex flex-column w-100 ml-md-5">
        <app-profile-form-field-input-select
          *ngIf="getControl(i, 'carrierId')"
          [formControlName]="'carrierId'"
          [values]="carrierValues"
          (change)="onChange(i)">
        </app-profile-form-field-input-select>
        <div
          *ngIf="getControl(i, 'carrierId')?.invalid && getControl(i, 'carrierId')?.touched"
          aria-atomic="true"
          aria-live="assertive"
          role="alert"
          class="invalid-feedback d-block"
          data-automation-id="carrier-required-error">
          <ng-container *ngIf="getControl(i, 'carrierId')?.errors.siblingRequired">
            {{ 'profileFieldErrorCarrierRequired' | translate }}
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
  <app-add-another-button [inputLabel]="inputLabel" (onClick)="onAddControl.emit()" (setFocus)="setFocusOnInputField()">
    {{ 'profileAddAnother' | translate }}
  </app-add-another-button>
</ng-container>
