import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, finalize, map, pluck, switchMap, tap } from 'rxjs/operators';
import { LOCAL_STORAGE } from '../../models/local-storage';
import { WindowRefService } from '../../services/window-ref.service';

import {
  getPermissionsFromToken,
  loggedIn,
  logout,
  setIsAuthorized,
  setIsStaffAuthorized,
  staffLoggedIn,
  updateAuthData,
} from '../actions/keycloak.actions';
import { AuthData, TokenData } from '../models/keycloak';
import { PermissionService } from 'app/permissions/services/permission.service';
import { KeycloakService } from '../services/keycloak.service';
import { of } from 'rxjs';
import { ConfigurationLoader } from 'shared/configuration-loader';

@Injectable()
export class KeycloakEffect {

  public authInKeycloak$ = createEffect(() => this.action$.pipe(
    ofType(loggedIn),
    map(() => setIsAuthorized({isAuthorized: true})),
  ));

  public authStaffInKeycloak$ = createEffect(() => this.action$.pipe(
    ofType(staffLoggedIn),
    map(() => setIsStaffAuthorized({isStaffAuthorized: true})),
  ));

  public logoutFromKeycloak$ = createEffect(() => this.action$.pipe(
    ofType(logout),
    tap(this.removeAuthParams.bind(this)),
    switchMap(() => [
      setIsAuthorized({isAuthorized: false}),
      setIsStaffAuthorized({isStaffAuthorized: false}),
    ]),
  ));

  public updateAuthDataOnLoggedIn$ = createEffect(() => this.action$.pipe(
    ofType(loggedIn, staffLoggedIn),
    map(({authData}) => updateAuthData({authData})),
  ));

  public getPermissionsFromToken$ = createEffect(() => this.action$.pipe(
    ofType(getPermissionsFromToken),
    switchMap(({showcaseResource, actionsToDispatch = []})=> {
      const currentSiteResource = this.calculateCurrentSiteResource();
      return this.handleGetPermissionsFromToken(showcaseResource, currentSiteResource).pipe(
        switchMap(() => actionsToDispatch),
        catchError(error => of(error))
      );
    }),
  ));


  public updateAuthData$ = createEffect(() => this.action$.pipe(
    ofType(updateAuthData),
    pluck('authData'),
    tap(this.setAuthParams.bind(this)),
  ), {dispatch: false});

  constructor(
    public action$: Actions,
    private readonly windowRefService: WindowRefService,
    private readonly keycloakService: KeycloakService,
    private readonly permissionService: PermissionService,
    private readonly configLoader: ConfigurationLoader,
  ) {
  }

  private calculateCurrentSiteResource() {
    const siteId = this.configLoader.siteId;
    return siteId ? `site-${siteId}` : 'main-site';
  }

  private handleGetPermissionsFromToken(showcaseResource: string, currentSiteResource: string) {
    const userData = this.getAuthParams();
    const resourceList = showcaseResource ? [currentSiteResource, showcaseResource] : [currentSiteResource];
    if (showcaseResource) {
     this.permissionService.setShowcaseLoading(true);
    }
    return this.keycloakService.makeUmaRequest(userData.bearerToken, resourceList).pipe(
      map((data: TokenData) => {
        this.permissionService.updateStorageAndPermissionsWithTokenData(data);
        this.windowRefService.localStorage.setItem(LOCAL_STORAGE.USER_INFO, JSON.stringify(userData.userInfo));
      }),
      catchError(() => of(undefined)),
      finalize(() => {
        if (showcaseResource) {
          this.permissionService.setShowcaseLoading(false);
        }
      })
    );
  }

  private setAuthParams({userInfo, bearerToken, refreshToken}: AuthData) {
    this.windowRefService.localStorage.setItem(LOCAL_STORAGE.USER_INFO, JSON.stringify(userInfo));
    this.windowRefService.localStorage.setItem(LOCAL_STORAGE.BEARER_TOKEN, bearerToken);
    this.windowRefService.localStorage.setItem(LOCAL_STORAGE.REFRESH_TOKEN, refreshToken);
  }

  private getAuthParams(): AuthData {
    const userInfo = JSON.parse(this.windowRefService.localStorage.getItem(LOCAL_STORAGE.USER_INFO) || '{}');
    const bearerToken = this.windowRefService.localStorage.getItem(LOCAL_STORAGE.BEARER_TOKEN);
    const refreshToken = this.windowRefService.localStorage.getItem(LOCAL_STORAGE.REFRESH_TOKEN);
    return {userInfo, bearerToken, refreshToken};
  }

  private removeAuthParams() {
    this.windowRefService.localStorage.removeItem(LOCAL_STORAGE.USER_INFO);
    this.windowRefService.localStorage.removeItem(LOCAL_STORAGE.BEARER_TOKEN);
    this.windowRefService.localStorage.removeItem(LOCAL_STORAGE.REFRESH_TOKEN);
  }
}
