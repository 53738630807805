import {
  Directive,
  AfterViewInit,
  Renderer2,
  ElementRef,
} from '@angular/core';

@Directive({
  selector: '[removeTabindexOnYScroll]',
})
export class RemoveTabindexOnYScrollDirective implements AfterViewInit {
  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
  ) {}

  public ngAfterViewInit() {
    const scrollEl = this.element.nativeElement.querySelector('.ps__thumb-y');
    if (scrollEl) {
      this.renderer.removeAttribute(scrollEl, 'tabindex');
    }
  }
}
