import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-get-it-error-dialog',
  templateUrl: './get-it-error-dialog.component.html',
  styleUrls: ['./get-it-error-dialog.component.scss'],
})
export class GetItErrorDialogComponent {
  @Input() public message: string;

  constructor(private activeModal: NgbActiveModal) {
  }

  public onClose() {
    this.activeModal.dismiss();
  }
}
