import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { ListItem } from '../../list/models/list';
import {
  clearReadingHistoryItems,
  getReadingHistoryStatusSuccess,
  loadMoreReadingHistoryItems,
  loadReadingHistoryItems,
  loadReadingHistoryItemsFailure,
  loadReadingHistoryItemsSuccess,
  updateReadingHistoryOptionFailure,
  updateReadingHistoryOptionSuccess,
} from '../actions/reading-history.actions';
import * as Immutable from 'immutable';
import { ReadingHistoryPagination, ReadingHistorySortByString } from '../models/reading-history';

export interface ReadingHistoryItemsLoadingState {
  loading: boolean;
  firstPageLoading: boolean;
  error: number | null;
}

export const ReadingHistoryItemsLoadingStateRecord = Immutable.Record<ReadingHistoryItemsLoadingState>({
  loading: false,
  firstPageLoading: false,
  error: null,
});

export interface AllReadingHistoryItems {
  list: ListItem[],
  itemsLoadingState: ReadingHistoryItemsLoadingState;
}

export const ReadingHistoryItemsPaginationRecord = Immutable.Record<ReadingHistoryPagination>({
  page: 0,
  totalPages: 0,
  totalResults: 0,
});

export interface ReadingHistoryState {
  readingHistoryStatus: boolean;
  readingHistoryItems: {
    list: ListItem[],
    pagination: Immutable.Record<ReadingHistoryPagination>,
    itemsLoadingState: Immutable.Record<ReadingHistoryItemsLoadingState>;
    searchText: string;
    sortedBy: ReadingHistorySortByString;
  }
}

export const initialState: ReadingHistoryState = {
  readingHistoryStatus: null,
  readingHistoryItems: {
    list: [],
    pagination: ReadingHistoryItemsPaginationRecord(),
    itemsLoadingState: ReadingHistoryItemsLoadingStateRecord(),
    searchText: '',
    sortedBy: 'checkoutDate.desc',
  },
};

export const isLastPageLoaded = (pagination: ReadingHistoryPagination) => (pagination.page >= pagination.totalPages - 1);

const _reducer = createReducer(
  initialState,
  on(getReadingHistoryStatusSuccess, (state, {readingHistoryStatus}) => ({
    ...state,
    readingHistoryStatus: readingHistoryStatus.readingHistoryActivation,
  })),
  on(updateReadingHistoryOptionFailure, (state, {option}) => ({
    ...state,
    readingHistoryStatus: option.readingHistoryActivation,
  })),
  on(updateReadingHistoryOptionSuccess, (state, {option}) => ({
    ...state,
    readingHistoryStatus: option.readingHistoryActivation,
  })),
  on(loadReadingHistoryItems, (state, {searchText, paginationParams}) => ({
    ...state,
    readingHistoryItems: {
      ...state.readingHistoryItems,
      itemsLoadingState: ReadingHistoryItemsLoadingStateRecord({
        loading: true,
        firstPageLoading: !paginationParams || paginationParams.pageNum === 0,
      }),
      searchText,
    },
  })),
  on(loadMoreReadingHistoryItems, (state) => ({
    ...state,
    readingHistoryItems: {
      ...state.readingHistoryItems,
      itemsLoadingState: ReadingHistoryItemsLoadingStateRecord({loading: true}),
    },
  })),
  on(loadReadingHistoryItemsFailure, (state, {errorStatus}) => ({
    ...state,
    readingHistoryItems: {
      ...state.readingHistoryItems,
      itemsLoadingState: ReadingHistoryItemsLoadingStateRecord({error: errorStatus}),
    },
  })),
  on(loadReadingHistoryItemsSuccess, (state, {readingHistoryItems, pagination, sortedBy, searchText}) => ({
    ...state,
    readingHistoryItems: {
      ...state.readingHistoryItems,
      list: [...state.readingHistoryItems.list, ...readingHistoryItems],
      pagination: ReadingHistoryItemsPaginationRecord(pagination),
      itemsLoadingState: ReadingHistoryItemsLoadingStateRecord(),
      sortedBy: sortedBy,
      searchText,
    },
  })),
  on(clearReadingHistoryItems, (state) => ({
    ...state,
    readingHistoryItems: {
      ...state.readingHistoryItems,
      list: [],
    },
  })),
);

export function reducer(state: ReadingHistoryState | undefined, action: Action) {
  return _reducer(state, action);
}
export const featureKey = 'readingHistory';
export const getReadingHistoryState = createFeatureSelector<ReadingHistoryState>(featureKey);
export const getReadingHistoryStatusState = createSelector(getReadingHistoryState, (state): boolean => state.readingHistoryStatus);
export const getReadingHistoryItems = createSelector(getReadingHistoryState, (state): AllReadingHistoryItems => {
  return {
    list: state.readingHistoryItems.list,
    itemsLoadingState: state.readingHistoryItems.itemsLoadingState.toJS(),
  };
});
export const getReadingHistoryPagination = createSelector(getReadingHistoryState, (state): ReadingHistoryPagination => {
  return state.readingHistoryItems.pagination.toJS();
});
export const getReadingHistorySearchText = createSelector(getReadingHistoryState, (state: ReadingHistoryState): string => {
  return state.readingHistoryItems.searchText;
});
export const getReadingHistorySortedBy = createSelector(getReadingHistoryState, (state: ReadingHistoryState): ReadingHistorySortByString => {
  return state.readingHistoryItems.sortedBy;
});
