import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import {
  loadRelatedItemsOfMaterialTabEditions, queryEditions,
  updateEditionAvailabilityState
} from '../../actions/full-entity.actions';
import {
  AvailabilityUpdateEditionInfo,
  Edition,
  EntityTypes,
  FormatGroup,
  GetItRequest,
  HoldRequest,
  MaterialTab
} from '../../models/entity';
import { RelatedItem } from '../../models/related-items-result';
import {
  FullEntityState,
  getEditionsQuery,
  selectEntity
} from '../../reducers/full-entity.reducer';
import { VendorLogo } from 'shared/models/configuration';

@Component({
  selector: 'app-location-and-edition-table-container',
  template: `
    <app-location-and-edition-table
      [fgId]="fgId"
      [label]="label"
      [tabName]="tabName"
      [nonIntegratedLogo]="nonIntegratedLogo"
      [isElectronic]="isElectronic"
      [tableData]="queriedTableData"
      [availabilityUpdates]="availabilityUpdates"
      [itemTitle]="itemTitle"
      [hasEditionEcontentIssues]="hasEditionEcontentIssues"
      (openPanel)="onOpenPanel($event)"
      (getIt$)="getIt$.emit($event)"
      (placeHold$)="placeHold$.emit($event)"
    ></app-location-and-edition-table>`,
})
export class LocationAndEditionTableContainerComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public fgId: string;
  @Input() public tabName = '';
  @Input() public label = '';
  @Input() public nonIntegratedLogo: { [key: string]: VendorLogo };
  @Input() public isElectronic: boolean;
  @Input() public hasEditionEcontentIssues: boolean;
  @Input() public availabilityUpdates: AvailabilityUpdateEditionInfo[];
  @Output() public getIt$ = new EventEmitter<{ item: GetItRequest }>(true);
  @Output() public placeHold$ = new EventEmitter<{ item: HoldRequest }>(true);
  @Output() public tableInited = new EventEmitter();

  public subscriptions = new Subscription();
  public tableData: Edition[];
  public itemTitle: string;

  private rollup: FormatGroup;

  private readonly FILTER_FIELDS = ['location', 'callNumber', 'barcode'];
  private editionsQuery: string;
  private queriedTableDataEmitter = new Subject<void>();
  public queriedTableData: Edition[];

  constructor(
    private readonly store: Store<FullEntityState>,
  ) { }

  public ngOnInit() {
    this.queriedTableDataEmitter.subscribe(() => {
      this.updateQueriedTableData();
    });

    this.subscriptions.add(this.store.select(selectEntity).subscribe((entity) => {
      if (entity?.entityType === EntityTypes.FORMAT_GROUP) {
        this.mapRollupData(entity as FormatGroup);
      }
      this.queriedTableDataEmitter.next();
    }));

    this.subscriptions.add(this.store.select(getEditionsQuery).subscribe((query) => {
      this.editionsQuery = query;
        if (this.editionsQuery !== null && this.editionsQuery !== undefined) {
          const materialTabIndex = this.rollup.materialTabs.findIndex((tab) => tab.name === this.tabName);
          this.store.dispatch(loadRelatedItemsOfMaterialTabEditions({materialTabIndex}));
          this.queriedTableDataEmitter.next();
        }
      })
    );
  }

  public ngAfterViewInit() {
    this.tableInited.emit();
  }

  public onOpenPanel(data: {id: string, isExpanded: boolean}) {
    const editionIndex = this.tableData.findIndex(row => row.id == data.id);
    const edition = this.tableData[editionIndex].availability;
    const materialTabIndex = this.rollup.materialTabs.findIndex((tab) => tab.name === this.tabName);
    if (edition?.expanded) {
      this.store.dispatch(updateEditionAvailabilityState({materialTabIndex, editionIndex, expanded: !edition?.expanded, loading: false}));
      return;
    }
    this.store.dispatch(loadRelatedItemsOfMaterialTabEditions({materialTabIndex, editionIndex}));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.store.dispatch(queryEditions(null));
  }

  private mapRollupData(rollupData: FormatGroup) {
    this.rollup = rollupData;
    if (this.rollup && this.rollup.materialTabs) {
      const currentTab = this.rollup.materialTabs.find((tab) => tab.name === this.tabName);
      this.tableData = currentTab.editions;
      this.itemTitle = rollupData.title;
    }
  }

  private updateQueriedTableData() {
    if (!this.editionsQuery) {
      this.queriedTableData = this.tableData;
      return;
    }
    this.queriedTableData = this.tableData.map((edition) => {
      if (!edition.availability?.items && this.editionsQuery) {
        return edition;
      }
      return {
        ...edition,
        availability: {
          ...edition.availability,
          items: this.filterUsingQuery(edition.availability.items, this.editionsQuery)
        }
      };
    });
  }

  private filterUsingQuery(rows: RelatedItem[], query: string) {
    if (!query) {
      return rows;
    }
    return rows.reduce((target: RelatedItem[], item) => {
      for (const filter of this.FILTER_FIELDS) {
        if ((item as any)[filter]?.toLowerCase()?.trim()?.includes(query?.toLowerCase()?.trim())) {
          target.push(item);
          break;
        }
      }
      return target;
    }, []);
  }
}
