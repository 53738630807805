export const NlbLibraryList = [
  {
    'name': 'Ang Mo Kio Public Library',
    'abbreviation': 'AMKPL',
    'display_name': 'Ang Mo Kio Public Library',
    'url_address': 'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/ang-mo-kio-public-library'
  },
  {
    'name': 'Bukit Batok Public Library',
    'abbreviation': 'BBPL',
    'display_name': 'Bukit Batok Public Library',
    'url_address':
      'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/bukit-batok-public-library'
  },
  {
    'name': 'Bedok Public Library',
    'abbreviation': 'BEPL',
    'display_name': 'Bedok Public Library',
    'url_address': 'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/bedok-public-library'
  },
  {
    'name': 'Bishan Public Library',
    'abbreviation': 'BIPL',
    'display_name': 'Bishan Public Library',
    'url_address': 'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/bishan-public-library'
  },
  {
    'name': 'Bukit Panjang Public Library',
    'abbreviation': 'BPPL',
    'display_name': 'Bukit Panjang Public Library',
    'url_address':
      'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/bukit-panjang-public-library'
  },
  {
    'name': 'Choa Chu Kang Public Library',
    'abbreviation': 'CCKPL',
    'display_name': 'Choa Chu Kang Public Library',
    'url_address':
      'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/choa-chu-kang-public-library'
  },
  {
    'name': 'Clementi Public Library',
    'abbreviation': 'CMPL',
    'display_name': 'Clementi Public Library',
    'url_address': 'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/clementi-public-library'
  },
  {
    'name': 'library@chinatown',
    'abbreviation': 'CNPL',
    'display_name': 'library@chinatown',
    'url_address': 'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/library-chinatown'
  },
  {
    'name': 'Cheng San Public Library',
    'abbreviation': 'CSPL',
    'display_name': 'Cheng San Public Library',
    'url_address': 'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/cheng-san-public-library'
  },
  {
    'name': 'library@esplanade',
    'abbreviation': 'EPPL',
    'display_name': 'library@esplanade',
    'url_address': 'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/library-esplanade'
  },
  {
    'name': 'Geylang East Public Library',
    'abbreviation': 'GEPL',
    'display_name': 'Geylang East Public Library',
    'url_address':
      'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/geylang-east-public-library'
  },
  {
    'name': 'library@harbourfront',
    'abbreviation': 'HBPL',
    'display_name': 'library@harbourfront',
    'url_address': 'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/library-harbourfront'
  },
  {
    'name': 'Jurong West Public Library',
    'abbreviation': 'JWPL',
    'display_name': 'Jurong West Public Library',
    'url_address':
      'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/jurong-west-public-library'
  },
  {
    'name': 'Marine Parade Public Library',
    'abbreviation': 'MPPL',
    'display_name': 'Marine Parade Public Library',
    'url_address':
      'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/marine-parade-public-library'
  },
  {
    'name': 'library@orchard',
    'abbreviation': 'OCPL',
    'display_name': 'library@orchard',
    'url_address': 'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/library-orchard'
  },
  {
    'name': 'Pasir Ris Public Library',
    'abbreviation': 'PRPL',
    'display_name': 'Pasir Ris Public Library',
    'url_address': 'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/pasir-ris-public-library'
  },
  {
    'name': 'Queenstown Public Library',
    'abbreviation': 'QUPL',
    'display_name': 'Queenstown Public Library',
    'url_address': 'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/queenstown-public-library'
  },
  {
    'name': 'Sembawang Public Library',
    'abbreviation': 'SBPL',
    'display_name': 'Sembawang Public Library',
    'url_address': 'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/sembawang-public-library'
  },
  {
    'name': 'Sengkang Public Library',
    'abbreviation': 'SKPL',
    'display_name': 'Sengkang Public Library',
    'url_address': 'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/sengkang-public-library'
  },
  {
    'name': 'Serangoon Public Library',
    'abbreviation': 'SRPL',
    'display_name': 'Serangoon Public Library',
    'url_address': 'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/serangoon-public-library'
  },
  {
    'name': 'Toa Payoh Public Library',
    'abbreviation': 'TPPL',
    'display_name': 'Toa Payoh Public Library',
    'url_address': 'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/toa-payoh-public-library'
  },
  {
    'name': 'Jurong Regional Library',
    'abbreviation': 'JRL',
    'display_name': 'Jurong Regional Library',
    'url_address': 'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/jurong-regional-library'
  },
  {
    'name': 'Punggol Regional Library',
    'abbreviation': 'PRL',
    'display_name': 'Punggol Regional Library',
    'url_address': 'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/punggol-regional-library'
  },
  {
    'name': 'Tampines Regional Library',
    'abbreviation': 'TRL',
    'display_name': 'Tampines Regional Library',
    'url_address': 'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/tampines-regional-library'
  },
  {
    'name': 'Yishun Public Library',
    'abbreviation': 'YIPL',
    'display_name': 'Yishun Public Library',
    'url_address': 'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/yishun-public-library'
  },
  {
    'name': 'Woodlands Regional Library',
    'abbreviation': 'WRL',
    'display_name': 'Woodlands Regional Library',
    'url_address':
      'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/woodlands-regional-library'
  },
  {
    'name': 'Central Public Library',
    'abbreviation': 'CLL',
    'display_name': 'Central Public Library',
    'url_address': 'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/central-public-library'
  },
  {
    'name': 'Lee Kong Chian Reference Library Level 7',
    'abbreviation': '07LKCRL',
    'display_name': 'Lee Kong Chian Reference Library Level 7',
    'url_address':
      'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/national-library-singapore'
  },
  {
    'name': 'Lee Kong Chian Reference Library Level 7',
    'abbreviation': '07LKCRL',
    'display_name': 'Lee Kong Chian Reference Library Level 7',
    'url_address':
      'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/national-library-singapore'
  },
  {
    'name': 'Lee Kong Chian Reference Library Level 8',
    'abbreviation': '08LKCRL',
    'display_name': 'Lee Kong Chian Reference Library Level 8',
    'url_address':
      'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/national-library-singapore'
  },
  {
    'name': 'Lee Kong Chian Reference Library Level 9',
    'abbreviation': '09LKCRL',
    'display_name': 'Lee Kong Chian Reference Library Level 9',
    'url_address':
      'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/national-library-singapore'
  },
  {
    'name': 'Lee Kong Chian Reference Library Level 11',
    'abbreviation': '11LKCRL',
    'display_name': 'Lee Kong Chian Reference Library Level 11',
    'url_address':
      'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/national-library-singapore'
  },
  {
    'name': 'Publication SG',
    'abbreviation': 'LD',
    'display_name': 'Publication SG',
    'url_address': 'https://www.nlb.gov.sg/main/services/reference-and-research-services/publicationsg'
  },
  {
    'name': 'National Archives',
    'abbreviation': 'NASB',
    'display_name': 'National Archives',
    'url_address':
      'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/national-archives-of-singapore'
  },
  {
    'name': 'The LLiBrary (Lifelong Learning Institute)',
    'abbreviation': 'WDALLI',
    'display_name': 'The LLiBrary (Lifelong Learning Institute)',
    'url_address': 'https://www.nlb.gov.sg/main/visit-us/our-libraries-and-locations/libraries/the-lliBrary.aspx'
  }
];
