import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';

@Injectable()
export class CopyToClipboardService {

  constructor(
    private readonly clipboard: Clipboard,
  ) {
  }

  public copyToClipboard(text: string): void {
    const pending = this.clipboard.beginCopy(text);
    let remainingAttempts = 3;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        pending.destroy();
      }
    };
    attempt();
  }
}
