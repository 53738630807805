import { Component, OnInit } from '@angular/core';
import { FeatureToggleService } from 'app/services/feature-toggle.service';

@Component({
  selector: 'app-onetrust-footer-button',
  templateUrl: './onetrust-footer-button.component.html',
  styleUrls: ['./onetrust-footer-button.component.scss']
})

export class OneTrustFooterButton implements OnInit {
  public featureOneTrustBanner = true;

  constructor(
    private readonly featureToggleService: FeatureToggleService
  ) {
  }

  public ngOnInit(): void {
    this.featureOneTrustBanner = this.featureToggleService.getToggles()['VE-3951_2024-04-30_discover-one-trust-banner'];
  }
}
