import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[bookshelfTemplateBody]',
})
export class BookshelfTemplateBodyDirective {
  @Input('bookshelfTemplateBody') public mode: 'ps' | 'ps-short' | 'no-ps' = 'ps';

  constructor(public template: TemplateRef<{ psScroll?: HTMLElement }>) {
  }
}
