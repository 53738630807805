<div class="row mb-3">
  <section class="col-auto col" *ngIf="availabilityUpdateInfo?.status?.electronicVendor && availabilityUpdateInfo?.reserve?.vendorName; else nonIntegratedEcontent">
    <div data-automation-id="material-type-info" class="d-flex align-items-center content-status-text">
      <app-availability-icon [status]="availabilityStatus"></app-availability-icon>
      <app-material-tab-type-label
        [status]="availabilityStatus"
        [label]="materialTab.translationKey ? (materialTab.translationKey | translate) : materialTab.name">
      </app-material-tab-type-label>
      <app-rollup-tabs-content-status
        class="tabs-content-status"
        data-automation-id="content-status"
        [materialTab]="materialTab"
        [availabilityStatus]="availabilityStatus">
      </app-rollup-tabs-content-status>
      <ng-container *ngIf="!!availabilityUpdateInfo?.status?.electronicVendor" [ngSwitch]="availabilityUpdateInfo?.reserve?.vendorName">
        <span *ngSwitchCase="'axis360'" class="d-flex align-items-center" data-automation-id="axis360-image-text">
          {{ 'searchResultPageFrom' | translate }} <img class="ml-2 axis360-img logo" src="{{vendorsLogo['axis360'] || 'assets/axis360.png'}}" alt="axis360-logo" data-automation-id="axis360-image" />
        </span>
        <span *ngSwitchCase="'cloudlibrary'" class="d-flex align-items-center" data-automation-id="cloudlibrary-image-text">
          {{ 'searchResultPageFrom' | translate }} <img class="ml-2 cloudlibrary-img logo" src="{{vendorsLogo['cloudlibrary'] || 'assets/cloudlibrary.png'}}" alt="cloudlibrary-logo" data-automation-id="cloudlibrary-image" />
        </span>
        <span *ngSwitchCase="'borrowbox'" class="d-flex align-items-center" data-automation-id="borrowbox-image-text">
          {{ 'searchResultPageFrom' | translate }} <img class="ml-2 borrowbox-img logo" src="{{vendorsLogo['borrowbox'] || 'assets/borrowbox.png'}}" alt="borrowbox-logo" data-automation-id="borrowbox-image" />
        </span>
        <span *ngSwitchCase="'overdrive'" class="d-flex align-items-center" data-automation-id="over-drive-image-text">
          {{ 'searchResultPageFrom' | translate }} <img class="ml-2 over-drive-img logo" src="{{vendorsLogo['overdrive'] || 'assets/overdrive.png'}}" alt="over-drive-logo" data-automation-id="over-drive-image" />
        </span>
        <span *ngSwitchCase="'hoopla'" class="d-flex align-items-center" data-automation-id="hoopla-image-text">
          {{ 'searchResultPageFrom' | translate }} <img class="ml-2 hoopla-img logo" src="{{vendorsLogo['hoopla'] || 'assets/hoopla.png'}}" alt="hoopla-logo" data-automation-id="hoopla-image" />
        </span>
      </ng-container>
    </div>
  </section>
  <ng-template #nonIntegratedEcontent>
    <section class="col-auto col">
      <div data-automation-id="material-type-info" class="d-flex align-items-center content-status-text">
        <ng-container *ngIf="getMatchingSubscriptions() as logoUrl">
          <app-availability-icon [status]="availabilityStatus"></app-availability-icon>
          <app-material-tab-type-label
            [status]="availabilityStatus"
            [label]="materialTab.translationKey ? (materialTab.translationKey | translate) : materialTab.name">
          </app-material-tab-type-label>
          <app-rollup-tabs-content-status
            class="tabs-content-status"
            data-automation-id="content-status"
            [materialTab]="materialTab"
            [availabilityStatus]="availabilityStatus">
          </app-rollup-tabs-content-status>
          <span class="d-flex align-items-center" data-automation-id="non-integrated-logo-text">
            {{ 'searchResultPageFrom' | translate }}
            <img class="ml-4 logo" [src]="logoUrl" alt="non-integrated-logo" data-automation-id="non-integrated-logo-image" />
          </span>
        </ng-container>
      </div>
    </section>
  </ng-template>
  <div class="col call-number-item-location" data-automation-id="item-call-number-and-location">
    <span *ngIf="materialTab?.itemLocation" data-automation-id="item-location">{{materialTab.itemLocation}}</span>
  </div>
</div>
