import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ShowcasePermissions, SitePermissions } from './models/permissions';
import { PermissionService } from './services/permission.service';
import { Subscription, filter } from 'rxjs';
import { FeatureToggleService } from 'app/services/feature-toggle.service';

@Directive({
  selector: '[hasShowcasePermission]',
})

export class ShowcasePermissionDirective implements OnInit, OnDestroy {
  @Input() hasShowcasePermission: { permission: ShowcasePermissions, showcaseId?: string | undefined };

  private hasPermission: boolean = false;
  private subscription: Subscription = new Subscription();
  public contentRendered: boolean = false;
  public isAuthPatronFlagEnabled: boolean;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private permissionService: PermissionService,
    private readonly featureToggleService: FeatureToggleService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.isAuthPatronFlagEnabled = this.featureToggleService.getToggles()['DIS-30793_2024-04-27_auth_patron'];
    this.subscription = this.permissionService.permissionsUpdated$.pipe(
      filter((value) => value !== null))
      .subscribe(() => {
        this.applyPermission();
        this.cdr.detectChanges();
      });


  }

  private applyPermission(): void {
    if (!this.isAuthPatronFlagEnabled) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      return;
    }

    const { permission, showcaseId } = this.hasShowcasePermission;
    const currentShowcaseResource = `showcase-${showcaseId}`;
    this.hasPermission = this.permissionService.hasPermission(currentShowcaseResource, permission);

    if (this.hasPermission && !this.contentRendered) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.contentRendered = true;
    } else if (!this.hasPermission && this.contentRendered) {
      this.viewContainerRef.clear();
      this.contentRendered = false;
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

