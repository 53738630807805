import {
  Pipe,
  PipeTransform,
} from '@angular/core';
import { formatDate } from '@angular/common';
import { LocalizationService } from 'app/services/localization.service';

@Pipe({
  name: 'configuredDate',
})
export class ConfiguredDatePipe implements PipeTransform {
  constructor(
    private localizationService: LocalizationService,
  ) {}

  public transform(value: string, format?: string): any {
    return value
      ? formatDate(
          value,
          format || this.localizationService.dateFormat,
          this.localizationService.locale,
        )
      : '';
  }
}
