import { Action, createAction, props } from '@ngrx/store';
import { RollupResultsV2 } from 'search/models/search-results';
import { ElementTriggeredSearch } from 'search/reducers/search.reducer';
import { MappedSearchObject, SearchObject, } from '../models/search-object';

export const SEARCH_RESULTS_LOAD = '[SearchResults] Load';
export const SEARCH_RESULTS_LOAD_COMPLETE = '[SearchResults] Search results load complete';
export const SEARCH_RESULTS_LOAD_FAILURE = '[SearchResults] Search results load failure';
export const SEARCH_RESULTS_RESET = '[SearchResults Rollups]  Rollups reset';
export const SEARCH_RESULTS_PAGE_NAVIGATION = '[SearchResults] Handle Search Results Router Navigation';
export const SEARCH_RESULTS_RESET_LOADING = '[SearchResults] Loading stopped';

export const searchChanged = createAction(
  '[Search results page] Search Changed',
  props<{ url: string }>(),
);

export class SearchResultsLoadAction implements Action {
  public readonly type = SEARCH_RESULTS_LOAD;

  constructor(public payload?: SearchObject) {
  }
}

export interface SearchResultsLoadCompleteActionPayload {
  searchObject: MappedSearchObject;
  results: RollupResultsV2;
}

export class SearchResultsLoadCompleteAction implements Action {
  public readonly type = SEARCH_RESULTS_LOAD_COMPLETE;

  constructor(public payload: SearchResultsLoadCompleteActionPayload) {
  }
}

export class SearchResultsLoadFailureAction implements Action {
  public readonly type = SEARCH_RESULTS_LOAD_FAILURE;

  constructor(public payload: { status: number, message: string }) {
  }
}
export class SearchResultsReset implements Action {
  public readonly type = SEARCH_RESULTS_RESET;
}

export class SearchResultsResetLoadingAction implements Action {
  public readonly type = SEARCH_RESULTS_RESET_LOADING;
}

export class SearchResultsPageRouteNavigation implements Action {
  public readonly type = SEARCH_RESULTS_PAGE_NAVIGATION;

  constructor(public payload: string) {
  }
}

export const setFilterPanelFullscreen = createAction(
  '[FilterPanel] Set filter panel fullscreen',
  props<{ fullscreen: boolean }>(),
);

export const setFilterPanelExpanded = createAction(
  '[FilterPanel] Set filter panel expanded',
  props<{ expanded: boolean }>(),
);

export const focusRefineResultsButton = createAction(
  '[FilterPanel] Focus refine results button',
);

export const stopFocusRefineResultsButton = createAction(
  '[FilterPanel] Stop focus refine results button',
);

export const setElementTriggeredSearch = createAction(
  '[SearchResults] Set element triggered search',
  props<{ element: ElementTriggeredSearch }>(),
);

export const resetElementTriggeredSearch = createAction(
  '[SearchResults] Reset element triggered search',
);

export const focusSortedByButton = createAction(
  '[SearchResults] Focus sorted by button',
);

export const stopFocusSortedByButton = createAction(
  '[SearchResults] Stop focus sorted by button',
);

export const focusResultsNumberButton = createAction(
  '[SearchResults] Focus results number button',
);

export const stopFocusResultsNumberButton = createAction(
  '[SearchResults] Stop focus results number button',
);

export const focusoutSearchInput = createAction(
    '[SearchResults] Stop focus search input',
    props<{ query: string }>(),
);

export const setAdvancedMode = createAction(
    '[SearchMode] set search mode',
    props<{ advancedMode: boolean }>(),
);

export const updateAdvancedMode = createAction(
    '[SearchMode] update search mode',
    props<{ advancedMode: boolean }>(),
);

export type SearchActions =
  | SearchResultsLoadAction
  | SearchResultsLoadCompleteAction
  | SearchResultsLoadFailureAction
  | SearchResultsResetLoadingAction
  | SearchResultsReset
  | SearchResultsPageRouteNavigation;
