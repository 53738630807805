<div class="row px-4 px-sm-19 px-md-15 mt-8">
  <ng-content></ng-content>
</div>
<div class="row justify-content-center">
  <button class="ins-buttons button-secondary flint mb-5"
          (click)="onClick()"
          (mouseenter)="toggleMouseEvent(true)"
          (mouseleave)="toggleMouseEvent(false)"
          translate
          data-automation-id="load-more-btn"
          #loadBtn>
    loadMore
  </button>
</div>
