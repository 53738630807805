export interface GatesResource {
  bibId: string;
  holds: number;
  copies: number;
}
export interface GatesResourceCountResults {
  total: number;
  entries: GatesResource[];
}

export interface StoredGatesResource {
  loading: boolean;
  error: boolean;
  entity: GatesResource;
}

export const defaultGatesResourceCountResults: GatesResourceCountResults = {
  total: 0,
  entries: [],
};
