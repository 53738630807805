<perfect-scrollbar class="ps-show-always" scrollIndicators removeTabindexOnYScroll [ngStyle]="{'max-height': maxHeightPerfectScrollbar}">
  <div class="account-tab-content">
    <div class="page-title d-flex flex-row flex-sm-row flex-wrap" [class.flex-column]="isEditing">
      <div class="mb-3" data-automation-id="profile-page-title">
        <span role="heading" aria-level="1">{{'accountTabProfile' | translate}}</span>
        <span class="px-1">|</span>
        <span class="page-title-name">{{user?.name}}</span>
      </div>

      <div *ngIf="user"
           class="d-flex page-title-buttons-end"
           [ngClass]="{'justify-content-center page-title-buttons-initial-mobile': isEditing}">
        <button *ngIf="!isEditing"
                (click)="edit()"
                data-automation-id="profile-edit"
                class="ins-buttons button-secondary flint page-title-button ml-3">
          <span class="d-inline d-sm-none" translate>profileEdit</span>
          <span class="d-none d-sm-inline" translate>profileEditProfile</span>
        </button>

        <ng-container *ngIf="isEditing">
          <button (click)="save()"
                  data-automation-id="profile-save"
                  class="ins-buttons button-secondary flint page-title-button ml-0 ml-sm-3"
                  translate>
            profileSave
          </button>
          <button (click)="cancel()"
                  data-automation-id="profile-cancel"
                  class="ins-buttons button-primary flint ml-3"
                  translate>
            profileCancel
          </button>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="user; else loadingOrErrorMessage">
      <div class="page-body mb-10 mt-2">
        <app-profile-form [user]="user"
                          [isEditing]="isEditing"
                          [updateState$]="profileUpdateState$"
                          [touchForm$]="touchForm$"
                          (formValueChange)="onFormValueChange($event)">
        </app-profile-form>
      </div>
    </ng-container>
  </div>
</perfect-scrollbar>

<ng-template #loadingOrErrorMessage>
  <div class="d-flex justify-content-center text-white" role="status" aria-live="polite">
    <div *ngIf="isUserLoading;"
         class="mt-5 spinner-border items-loading-spinner"
         data-automation-id="profile-loading-spinner">
      <span class="sr-only" translate>Loading...</span>
    </div>
  </div>
  <div aria-atomic="true" aria-live="assertive" role="alert">
    <div *ngIf="!isUserLoading;"
         class="m-4 text-white"
         data-automation-id="profile-loading-error">
      {{'generalServerError' | translate}}
    </div>
  </div>
</ng-template>
