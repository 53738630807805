<div class="pickup-location form-group">
  <label id="choose-pickup-location-label"
         for="choose-pickup-location"
         data-automation-id="choose-pickup-location-label"
         class="pickup-location__label">
    {{ 'choosePickupLocation' | translate }}
  </label>
  <select class="form-control pickup-location__select select-custom-arrow"
          id="choose-pickup-location"
          [(ngModel)]="selectedLocationCode"
          [disabled]="disabled"
          (change)="onLocationChange($event.target.value)"
          [attr.data-automation-id]="dataAutomationId"
          aria-labelledby="choose-pickup-location-label">
    <option *ngFor="let location of pickupLocations" [value]="location.code"
            data-automation-id="pickupLocationOption">
      <ng-container *ngIf="location.name">
        {{ 'location.' + location.code | translate }}
      </ng-container>
      <ng-container *ngIf="!location.name">
        {{ 'Unknown' | translate }} ('{{ location.code }}')
      </ng-container>
    </option>
  </select>
</div>
