import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { openList, stopFocusAvailableListCard } from '../../actions/list.actions';
import { ListWithItemsCount } from '../../models/list';
import { getIsFocusListCardPending } from '../../reducers/list.reducer';
import { ListCardComponent } from '../list-card/list-card.component';

@Component({
  selector: 'app-lists-list',
  templateUrl: './lists-list.component.html',
  styleUrls: ['./lists-list.component.scss'],
})
export class ListsListComponent implements OnInit, OnDestroy {
  @Input() public lists: ListWithItemsCount[];

  @ViewChildren(ListCardComponent) private listCards: QueryList<ListCardComponent>;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly store: Store,
  ) {
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.store.select(getIsFocusListCardPending).subscribe((isFocusPending) => {
        if (isFocusPending) {
          this.store.dispatch(stopFocusAvailableListCard());
          setTimeout(() => {
            this.listCards.first.focus();
          });
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public openList(list: ListWithItemsCount): void {
    this.store.dispatch(openList({listId: list.id}));
  }

  public trackById(index: number, list: ListWithItemsCount): string {
    return list.id;
  }
}
