<app-bookshelf-template [class.invisible]="isInvisible">
  <ng-template #showcaseLoading>
    <ng-container *ngIf="!!openedShowcaseLoadingState.errorCode">
      <ng-template bookshelfTemplateTitle>
        <button class="showcases__header__button showcases__header__button--title w-100"
                data-automation-id="custom-showcases-back-button"
                (click)="onCloseShowcase()">
          <fa-icon class="arrow-icon" [icon]="arrowIconLeft"></fa-icon>
          <div class="d-flex flex-nowrap px-1 bookmarks-title" data-automation-id="custom-showcases-title">
            <div class="text-truncate d-block" translate>customShowcaseBackButton</div>
          </div>
        </button>
      </ng-template>
    </ng-container>
    <ng-template bookshelfTemplateBody="no-ps">
      <ng-container [ngTemplateOutlet]="loadingState"
                    [ngTemplateOutletContext]="{state: {
                            loading: openedShowcaseLoadingState.loading,
                            error: !!openedShowcaseLoadingState.errorCode
                          }}">
      </ng-container>
    </ng-template>
  </ng-template>

  <ng-container *ngIf="openedShowcase && !openedShowcaseLoadingState?.errorCode; else showcaseLoading">
    <ng-template bookshelfTemplateTitle>
      <button class="showcases__header__button showcases__header__button--title w-100"
              data-automation-id="custom-showcases-back-button"
              (click)="onCloseShowcaseAndDeselectItems()">
        <fa-icon class="arrow-icon" [icon]="arrowIconLeft"></fa-icon>
        <div class="d-flex flex-nowrap px-1 bookmarks-title" data-automation-id="custom-showcases-title">
          <div class="text-truncate d-block">{{ openedShowcase.name }}</div>
          &nbsp;
          <div class="w-auto">| {{ openedShowcaseItemsCount }}</div>
        </div>
      </button>
    </ng-template>

    <ng-container *ngIf="openedShowcaseItems.length">

      <!-- Select All -->
      <ng-template bookshelfTemplateAction>
        <button class="showcases__header__button"
                data-automation-id="custom-showcases-action-button-toggle-select-all"
                (click)="setAllItemsSelection(!isAllSelected)"
                [attr.aria-pressed]="isAllSelected">
          <span class="icon" aria-hidden="true"
                [ngClass]="isAllSelected ? 'fas fa-check-square' :  'far fa-square'"></span>
          <span translate class="text">customShowcaseSelectAll</span>
        </button>
      </ng-template>

      <!-- Selection Counter -->
      <ng-template bookshelfTemplateAction>
        <div aria-live="polite" role="status"
             class="d-inline-flex align-items-center h-100 p-2"
             data-automation-id="custom-showcases-selection-counter">
          <strong class="text-nowrap">
            {{ 'customShowcaseItemsCountSelected' | translate: {itemsCount: itemsSelectedCount} }}:
          </strong>
        </div>
      </ng-template>

      <!-- Add To Lists -->
      <ng-template bookshelfTemplateAction>
        <app-select-list-button [buttonClass]="'showcases__header__button'"
                                [lists]="lists$ | async"
                                [listItemEntities]="itemsEntitySelected"
                                [listIdsSelected]="[openedShowcase.createdFrom.id]"
                                [disabled]="!itemsSelectedCount"
                                [multiselect]="true"
                                [canCreateNew]="true"
                                [confirmDelete]="true"
                                data-automation-id="custom-showcases-action-add-to-list">
          <span class="fas fa-folder-plus icon d-inline-flex" aria-hidden="true"></span>
          <span class="text" translate>customShowcaseAddToLists</span>
        </app-select-list-button>
      </ng-template>

      <!-- Remove From List -->
      <ng-template bookshelfTemplateAction *ngIf="isShowcaseCreator">
        <app-bookshelf-remove-selected-from-list-button [buttonClass]="'showcases__header__button'"
                                                        [itemsSelectedCount]="itemsSelectedCount"
                                                        (remove)="removeSelectedFromRelatedList()">
        </app-bookshelf-remove-selected-from-list-button>
      </ng-template>
    </ng-container>

    <!-- Settings (auth ff OFF) -->
    <ng-container *ngIf="!isAuthPatronFlagEnabled">
      <ng-template bookshelfTemplateAction *ngIf="(isShowcaseCreator)
                                    ? (hasUpdatePersonalShowcasePermission$ | async)
                                    : (hasUpdateNonPersonalShowcasePermission$ | async)">
        <button (click)="openShowcaseSettings()"
                class="showcases__header__button"
                data-automation-id="custom-showcases-settings-button">
          <span class="fas fa-cog icon mr-1" aria-hidden="true"></span>
          <span translate class="text">customShowcaseSettingsButton</span>
        </button>
      </ng-template>
    </ng-container>

    <!-- Settings -->
    <ng-container *ngIf="isAuthPatronFlagEnabled">
      <ng-container *hasShowcasePermission="{
          permission: showcasePermissions.EDIT,
          showcaseId: showcaseId
        }"
      >
        <ng-template bookshelfTemplateAction>
          <button (click)="openShowcaseSettings()"
                  class="showcases__header__button"
                  data-automation-id="custom-showcases-settings-button">
              <span class="fas fa-cog icon mr-1" aria-hidden="true"></span>
              <span translate class="text">customShowcaseSettingsButton</span>
          </button>
        </ng-template>
      </ng-container>
    </ng-container>

    <!-- Items List -->
    <ng-template bookshelfTemplateBody="ps-short" let-psScroll="psScroll">
      <div
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollDisabled]="!psScroll || openedShowcaseDetailsLoadingState?.loading || allOpenedShowcaseItemsLoaded"
        [infiniteScrollThrottle]="50"
        (scrolled)="loadMoreShowcaseItems()"
        [infiniteScrollContainer]="psScroll">
        <div class="mx-2 mx-md-8 mt-3">
          <app-custom-showcase-settings-header
            [showcase]="openedShowcase"
            [isShowcaseCreator]="isShowcaseCreator"
            (onShowcaseTypeClick)="openList()">
          </app-custom-showcase-settings-header>
        </div>
        <app-bookshelf-items-list [bookshelfItems]="openedShowcaseItems"
                                  [ariaLabel]="'bookmarksShowcaseItemsListTitle' | translate"
                                  (onToggleItem)="onToggleItem(openedShowcase.id, $event)">
        </app-bookshelf-items-list>
        <ng-container *ngIf="openedShowcaseDetailsLoadingState">
          <ng-container [ngTemplateOutlet]="loadingState"
                        [ngTemplateOutletContext]="{state: openedShowcaseDetailsLoadingState}"></ng-container>
        </ng-container>
      </div>
    </ng-template>
  </ng-container>

  <!-- Loading/Error -->
  <ng-template #loadingState let-state="state">
    <div class="d-flex justify-content-center text-white" role="status" aria-live="polite">
      <div *ngIf="state.loading"
           class="mt-5 spinner-border custom-showcases-loading-spinner"
           data-automation-id="custom-showcases-loading-spinner">
        <span class="sr-only" translate>Loading...</span>
      </div>
    </div>
    <div aria-atomic="true" aria-live="assertive" role="alert">
      <div *ngIf="state.error"
           class="m-4 text-white"
           data-automation-id="custom-showcases-error">
        {{'generalServerError' | translate}}
      </div>
    </div>
  </ng-template>
</app-bookshelf-template>
