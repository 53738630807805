import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons';
import { Store } from '@ngrx/store';
import { runSavedSearch } from 'app/saved-search/actions/saved-search.actions';
import { Subscription } from 'rxjs';
import { UserPermission } from 'user/models/user';
import { doesUserHavePermission } from 'user/reducers/user.reducer';
import { showForm } from '../../actions/custom-showcase.actions';
import { CustomShowcaseSingle, OpenedDynamicShowcaseWithDetails } from '../../models/custom-showcase';
import { getSearchDataLoadingState, ShowcaseSavedSearchLoadingState } from '../../reducers/custom-showcase.reducer';
import { ShowcasePermissions } from 'app/permissions/models/permissions';
import { FeatureToggleService } from 'app/services/feature-toggle.service';

@Component({
  selector: 'app-dynamic-showcase-view',
  templateUrl: './dynamic-showcase-view.component.html',
  styleUrls: ['./dynamic-showcase-view.component.scss'],
})
export class DynamicShowcaseViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public openedShowcaseWithDetails: OpenedDynamicShowcaseWithDetails;
  @Input() public isShowcaseCreator: boolean;
  @Input() public isInvisible: boolean;
  @Output() public readonly closeShowcase = new EventEmitter<undefined>();
  public openedShowcase: CustomShowcaseSingle;
  public hasUpdatePersonalShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_PERSONAL_UPDATE));
  public hasUpdateNonPersonalShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_NON_PERSONAL_UPDATE));
  public searchDataLoadingState: ShowcaseSavedSearchLoadingState;
  public showcaseId: string;
  public isAuthPatronFlagEnabled: boolean;
  public showcasePermissions = ShowcasePermissions;
  public readonly arrowIconLeft = faAngleLeft;
  private readonly subscriptions = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly featureToggleService: FeatureToggleService,
    ) {
  }

  public ngOnChanges(): void {
    this.openedShowcase = this.openedShowcaseWithDetails.showcase;
    this.showcaseId = this.openedShowcaseWithDetails.showcase.id;
  }

  ngOnInit(): void {
    this.isAuthPatronFlagEnabled = this.featureToggleService.getToggles()['DIS-30793_2024-04-27_auth_patron'];
    this.subscriptions.add(
      this.store.select(getSearchDataLoadingState)
      .subscribe((state) => {
        this.searchDataLoadingState = state;
      }),
    );
  }

  public onCloseShowcase(): void {
    this.closeShowcase.emit();
  }

  public openShowcaseSettings(): void {
    this.store.dispatch(showForm({showcase: this.openedShowcase}));
  }

  public runSavedSearch(): void {
    this.store.dispatch(runSavedSearch({savedSearchId: this.openedShowcase.createdFrom.id}));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
