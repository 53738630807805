import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, Renderer2, ViewEncapsulation } from '@angular/core';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { SyndeticsUnboundService, SyndeticsWidget } from '../../../services/syndetics-unbound.service';

@Component({
  selector: 'app-syndetics-unbound-author-bio',
  templateUrl: './syndetics-unbound-author-bio.component.html',
  styleUrls: ['./syndetics-unbound-author-bio.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class SyndeticsUnboundAuthorBioComponent implements AfterViewInit, OnDestroy {
  @Input() isbn: string;
  public isLoaded = false;
  public isDisplayed = false;
  public shouldLoadWidgets: boolean;

  constructor(
    private readonly syndeticsUnboundService: SyndeticsUnboundService,
    private readonly renderer: Renderer2,
    private readonly cdr: ChangeDetectorRef,
    private configLoader: ConfigurationLoader,
  ) {
  }

  public ngAfterViewInit(): void {
    const connectionConfig = this.configLoader.syndeticsUnboundConnection;
    this.shouldLoadWidgets = !!connectionConfig?.accountId;
    if (this.shouldLoadWidgets) {
      this.syndeticsUnboundService.insertWidgets(this.isbn, [SyndeticsWidget.author], connectionConfig.accountId, this.renderer)
      .then((widgetsLoaded) => {
        this.isLoaded = true;
        this.isDisplayed = widgetsLoaded[SyndeticsWidget.author];
        this.cdr.detectChanges();
      });
    } else {
      this.isLoaded = true;
      this.cdr.detectChanges();
    }
  }

  public ngOnDestroy(): void {
    if (this.shouldLoadWidgets) {
      this.syndeticsUnboundService.removeWidgets([SyndeticsWidget.author], this.renderer);
    }
  }
}
