import { Component, Input } from '@angular/core';
import { DrawerHoldings } from 'search/models/search-models';

@Component({
  selector: 'app-drawer-holdings',
  templateUrl: './drawer-holdings.component.html',
  styleUrls: ['./drawer-holdings.component.scss'],
})
export class DrawerHoldingsComponent {
  @Input() public isLast: boolean;
  @Input() public holdings: DrawerHoldings;
}
