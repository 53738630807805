<div *ngIf="showConfig.showIssuesCount > 0" class="row mb-4">
  <div class="col-auto rollup-tabs-content-card-col-mw-fixed" data-automation-id="library-has-label">
    {{'libraryHas' | translate}}
  </div>
  <div [ngClass]="isFullScreen ? 'd-md-none' : 'd-lg-none' " class="w-100" data-automation-id="library-has-spacer"></div>
  <div class="col" data-automation-id="library-has">
    <p *ngFor="let issue of issuesStrings; let i = index;" class="mb-0">
      <ng-container *ngIf="i < showConfig.showIssuesCount">
        {{issue}}
      </ng-container>
    </p>
  </div>
</div>
<div *ngIf="showConfig.showIndexesCount > 0" class="row  mb-4">
  <div class="col-auto rollup-tabs-content-card-col-mw-fixed" data-automation-id="index-label">
    {{'indexes' | translate}}
  </div>
  <div [ngClass]="isFullScreen ? 'd-md-none' : 'd-lg-none' " class="w-100" data-automation-id="index-spacer"></div>
  <div class="col" data-automation-id="index-strings">
    <p *ngFor="let index of indexesStrings; let i = index;" class="mb-0">
      <ng-container *ngIf="i < showConfig.showIndexesCount">
        {{index}}
      </ng-container>
    </p>
  </div>
</div>
<div *ngIf="showConfig.showSupplementsCount > 0" class="row  mb-4">
  <div class="col-auto rollup-tabs-content-card-col-mw-fixed" data-automation-id="supplement-label">
    {{'supplements' | translate}}
  </div>
  <div [ngClass]="isFullScreen ? 'd-md-none' : 'd-lg-none' " class="w-100" data-automation-id="supplement-spacer"></div>
  <div class="col" data-automation-id="supplement-strings">
    <p *ngFor="let supplement of supplementsStrings; let i = index;" class="mb-0">
      <ng-container *ngIf="i < showConfig.showSupplementsCount">
        {{supplement}}
      </ng-container>
    </p>
  </div>
</div>
<div class="row">
  <div class="col-auto rollup-tabs-content-card-col-mw-fixed"></div>
  <div [ngClass]="isFullScreen ? 'd-md-none' : 'd-lg-none' " class="w-100"></div>
  <div class="col" data-automation-id="show-more">
    <p *ngIf="!expanded && showConfig.showMore" class="mt-1">
      <a class="holdings-details-link"
         [ngClass]="isFullScreen ? '' : 'holdings-details-link-md' "
         (click)="$event.preventDefault(); toggleExpanded()"
         data-automation-id="more-holdings-details"
         tabindex="0"
         href="">{{'moreHoldingsDetails' | translate}}
        <img src="../../../../assets/icon-dropdown-down.png" height="16" width="16" aria-hidden="true"/>
      </a>
    </p>
    <p *ngIf="expanded && showConfig.showMore" class="mt-1">
      <a class="holdings-details-link"
         [ngClass]="isFullScreen ? '' : 'holdings-details-link-md' "
         (click)="$event.preventDefault(); toggleExpanded()"
         data-automation-id="fewer-holdings-details"
         tabindex="0"
         href="">{{'fewerHoldingsDetails' | translate}}
        <img src="../../../../assets/icon-dropdown-up.png" height="16" width="16" aria-hidden="true"/>
      </a>
    </p>
  </div>
</div>
