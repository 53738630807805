import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ConfigurationLoader } from 'shared/configuration-loader';

@Injectable({
  providedIn: 'root',
})

export class BrowserTabTitleService {
  public currentTabTitle: string;
  private libraryName = this.configLoader.headerConfig.title;

  constructor(
    private titleService: Title,
    private configLoader: ConfigurationLoader,
  ) { }

  public setTabTitle(title = '') {
    if (title !== this.currentTabTitle) {
      this.titleService.setTitle(title ? `${title} | ${this.libraryName}` : this.libraryName);
      this.currentTabTitle = title;
    }
  }
}
