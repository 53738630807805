<div class="profile-expiration-message" *ngIf="expirationMessage" aria-live="assertive" role="alert"
     data-automation-id="profile-expiration-message">
  <div class="profile-expiration-message-header p-2">
    <fa-icon [classes]="['profile-icon']" [icon]="exclamationCircleIcon"></fa-icon>
    <span class="p-1" data-automation-id="profile-expiration-message-title">
      {{ (expirationMessage.daysLeft >= 0)
        ? ('profileAccountExpiresTitle' | translate: {days: expirationMessage.daysLeft})
        : ('profileAccountExpiredTitle' | translate)
      }}
    </span>
  </div>

  <div class="profile-expiration-message-body text-center px-1 py-2 p-sm-4"
       data-automation-id="profile-expiration-message-body">
    <ng-container *ngIf="expirationMessage.daysLeft >= 0; else accountExpiredMessage">
      <span class="d-none d-sm-inline">
        <ng-container>{{'profileAccountExpiresMessage' | translate: {
          days: expirationMessage.daysLeft,
          date: expirationDate | date : 'longDate'
        } }} </ng-container>
        <ng-container *ngTemplateOutlet="contactYourLibraryToRenew"></ng-container>
      </span>
      <span class="d-inline d-sm-none">
        <ng-container *ngTemplateOutlet="contactYourLibraryToRenewMobile"></ng-container>
      </span>
    </ng-container>

    <ng-template #accountExpiredMessage>
      <span class="d-none d-sm-inline" *ngIf="expirationMessage.daysLeft < 0">
        <ng-container>{{'profileAccountExpiredMessage' | translate: {date: expirationDate | date : 'longDate'} }} </ng-container>
        <ng-container *ngTemplateOutlet="contactYourLibraryToRenew"></ng-container>
      </span>
      <span class="d-inline d-sm-none">
        <ng-container *ngTemplateOutlet="contactYourLibraryToRenewMobile"></ng-container>
      </span>
    </ng-template>

    <ng-template #contactYourLibraryToRenew>
      <app-contact-your-library-link [libraryContactLink]="libraryContactLink">
        <ng-container>{{'profileAccountContactYourLibraryLowercase' | translate }}</ng-container>
      </app-contact-your-library-link>
      <span> {{'profileAccountToRenewYourAccount' | translate }}</span>
    </ng-template>

    <ng-template #contactYourLibraryToRenewMobile>
      <app-contact-your-library-link [libraryContactLink]="libraryContactLink">
        <ng-container>{{'profileAccountContactYourLibraryCapital' | translate }}</ng-container>
      </app-contact-your-library-link>
      <ng-container> {{'profileAccountToRenewYourAccount' | translate }}</ng-container>
    </ng-template>
  </div>
</div>
