import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SearchResultHelper } from 'core/utils/search-result-helper';
import { Edition, Entity, EntityTypes, FormatGroup } from '../../entity/models/entity';
import { ReadingHistoryData } from '../../reading-history/models/reading-history';
import { DictionariesService } from '../../services/dictionaries.service';
import { List, ListItemEntity, ListSort, ListType } from '../models/list';
import { ListDto, ListSortByString } from '../models/list.dto';

@Injectable()
export class ListTransformerService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly searchResultHelper: SearchResultHelper,
    private readonly dictionaryService: DictionariesService,
  ) {
  }

  public transformListDtoToList(listDto: ListDto): Pick<List, 'id' | 'name' | 'type' | 'showcaseRef'> {
    return {
      id: listDto.id,
      name: (listDto.type === ListType.regular) ? listDto.name : this.getForLaterListName(),
      type: listDto.type,
      showcaseRef: (listDto.type === ListType.regular) ? listDto.showcaseRef : null,
    };
  }

  public transformFgOrEntityToListItemEntity(item: FormatGroup | Entity | ReadingHistoryData): ListItemEntity {
    return (item.entityType === EntityTypes.FORMAT_GROUP)
      ? this.transformFormatGroupToListItemEntity(item as FormatGroup)
      : this.transformEntityToListItemEntity(item);
  }

  public transformListSortByStringToSort(sortBy: ListSortByString): ListSort {
    if (!sortBy) {
      return null;
    }

    const [field, order] = sortBy.split('.');
    if ((field !== 'date' && field !== 'title') || (order !== 'asc' && order !== 'desc')) {
      return null;
    }

    return {field, order};
  }

  public transformSortToListSortByString(sort: ListSort): ListSortByString {
    return `${sort.field}.${sort.order}`;
  }

  private transformFormatGroupToListItemEntity(formatGroup: FormatGroup | ReadingHistoryData): ListItemEntity {
    let listItemEntity: ListItemEntity = {
      id: formatGroup.id,
      entityType: EntityTypes.FORMAT_GROUP,
      title: formatGroup.title || '',
      isUnresolved: formatGroup.isUnresolved,
      primaryAgent: formatGroup.primaryAgent || null,
      materialType: this.dictionaryService.getFormatDescriptionByCode(
        formatGroup.materialTabs?.length &&
        formatGroup.materialTabs[0]?.materialTypes?.length &&
        formatGroup.materialTabs[0]?.materialTypes[0]),
      coverConfig: {
        coverUrl: formatGroup.coverUrl,
      },
      sourceEntity: formatGroup,
    };

    if ('personalLists' in formatGroup) {
      listItemEntity.personalLists = [...formatGroup.personalLists];
    }

    if ('selectedTabRecordId' in formatGroup) {
      listItemEntity.selectedTabRecordId = formatGroup.selectedTabRecordId;
    }

    if (formatGroup.materialTabs) {
      listItemEntity.editionsData = formatGroup.materialTabs[0]?.editions?.map((edition: Edition) => ({
        instanceId: edition.id,
        editionStatement: [edition.edition],
        publicationDate: edition.publicationDate,
        recordId: edition.recordId,
      }));
    }

    const readingHistory = (formatGroup as ReadingHistoryData);
    if (readingHistory.checkoutDate) {
      listItemEntity = {
        ...listItemEntity,
        checkoutDate: readingHistory.checkoutDate,
        readingHistoryItemId: readingHistory.readingHistoryItemId,
        format: readingHistory.format,
        primaryAgent: readingHistory.primaryAgent,
      };
    }

    return listItemEntity;
  }

  private transformEntityToListItemEntity(entity: Entity): ListItemEntity {
    return {
      id: entity.id,
      entityType: entity.entityType,
      title: this.searchResultHelper.extractTitle(entity) || entity.label,
      coverConfig: {
        type: entity.entityType,
        coverUrl: entity.coverUrl,
      },
      personalLists: entity.personalLists,
      sourceEntity: entity,
    };
  }

  private getForLaterListName(): string {
    return this.translateService.instant('forLater');
  }
}
