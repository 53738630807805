import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PatronUser } from 'user/models/user';

@Injectable()
export class PatronUserService {
  private readonly PATRON_BASE_URL = 'api/patron/patrons';

  constructor(private http: HttpClient) { }

  getMe(): Observable<PatronUser> {
    return this.http.get<PatronUser>(`${this.PATRON_BASE_URL}/me`);
  }

}
