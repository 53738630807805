import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-showcase-form-publish-status',
  templateUrl: './custom-showcase-form-publish-status.component.html',
  styleUrls: ['./custom-showcase-form-publish-status.component.scss'],
})
export class CustomShowcaseFormPublishStatusComponent {
  @Input() published: boolean;
  @Input() publishDate: string | null;
}
