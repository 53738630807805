import { Action, createAction, props } from '@ngrx/store';
import { AuthData } from '../models/keycloak';

export const login = createAction(
  '[Keycloak] Login',
);

export const setIsAuthorized = createAction(
  '[Keycloak] Set Is Authorized',
  props<{ isAuthorized: boolean }>(),
);

export const setIsStaffAuthorized = createAction(
  '[Keycloak] Set Is Staff Authorized',
  props<{ isStaffAuthorized: boolean }>(),
);

export const loggedIn = createAction(
  '[Keycloak] Logged in',
  props<{ authData: AuthData }>(),
);

export const staffLoggedIn = createAction(
  '[Keycloak] Staff logged in',
  props<{ authData: AuthData }>(),
);

export const logout = createAction(
  '[Keycloak] Logout',
);

export const updateAuthData = createAction(
  '[Keycloak] Update Auth Data',
  props<{ authData: AuthData }>(),
);

export const getPermissionsFromToken = createAction(
  '[Keycloak] Get permissions from token',
   props<{ showcaseResource?: string, actionsToDispatch?: Action[]}>()
);

export const willLogoutSoon = createAction(
  '[Keycloak] Will logout soon',
);

export const updateToken = createAction(
  '[Keycloak] Update access token',
);

