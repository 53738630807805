<div class="ineligible-titles-banner mb-4" *ngIf="ineligibleFormatGroups.length" aria-live="polite" role="status">
  <span class="ineligible-titles-banner__message"
        id="ineligible-titles-message"
        data-automation-id="ineligible-titles-message">
    {{'bulkHoldSelectedTitlesNotEligibleForBulkHold' | translate}}
  </span>
  <button class="ineligible-titles-banner__button"
          (click)="onSeeTitlesClick()"
          aria-haspopup="dialog"
          aria-describedby="ineligible-titles-message"
          data-automation-id="see-ineligible-titles-button">
    {{ 'bulkHoldSeeTitles' | translate }}
  </button>
</div>
