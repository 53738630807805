import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HoldSettingType } from 'user/models/user';
import { DictionaryTypes } from '../../models/dictionaries';
import { LocationWithName, PhysicalLocation } from '../../models/locations';
import { DictionariesService } from '../../services/dictionaries.service';

@Injectable({
  providedIn: 'root',
})
export class PickupLocationService {
  constructor(
    private readonly dictionaryService: DictionariesService,
  ) {}

  public getUserPickupLocations(holdSetting: HoldSettingType, homeLibraryCode: string): Observable<PhysicalLocation[]> {
    if (holdSetting === HoldSettingType.HOME) {
      const homeLocation = this.dictionaryService.getDictionaryItemByCode(DictionaryTypes.PHYSICAL_LOCATIONS, homeLibraryCode);
      return of([homeLocation as PhysicalLocation]);
    }
    return this.dictionaryService.getPickupLocations();
  }

  public getUserPickupLocation(pickupLocations: PhysicalLocation[], homeLibraryCode: string, preferredPickupLocationCode: string): PhysicalLocation {
    const userHomeLibrary = pickupLocations.find((loc: LocationWithName) => loc.code === homeLibraryCode);
    const userPreferredPickupLocation = pickupLocations.find((loc: LocationWithName) => loc.code === preferredPickupLocationCode);
    return userPreferredPickupLocation || userHomeLibrary || pickupLocations[0];
  }
}
