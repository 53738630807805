import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { highlightHeaderBookmarkButton } from 'user/actions/user-profile.actions';
import { isAuthorizedInKeycloak, isStaffAuthorizedInKeycloak } from '../../keycloak/reducers/keycloak.reducer';
import {
  addToList,
  removeFromList,
  removeSelectedFromList as forLaterRemoveSelectedFromList,
  setAllItemsSelection as forLaterSetAllItemsSelection,
  toggleItemSelection as forLaterToggleItemSelection
} from '../actions/for-later-list.actions';
import {
  addBookmarkViaButton,
  removeBookmarkViaButton,
  removeSelectedFromList,
  setAllItemsSelection,
  toggleItemSelection
} from '../actions/list.actions';
import { ListItemEntity, ListWithItemsCount } from '../models/list';
import { getForLaterList } from '../reducers/for-later-list.reducer';
import { getListItemsCount, getLists, getOpenedList } from '../reducers/list.reducer';

@Injectable()
export class ListStoreAdapterService {
  private readonly authorized$: Observable<[boolean, boolean]>;

  constructor(private readonly store: Store) {
    this.authorized$ = combineLatest([
      this.store.select(isAuthorizedInKeycloak),
      this.store.select(isStaffAuthorizedInKeycloak),
    ]);
  }

  public getLists(): Observable<[ListWithItemsCount[], boolean]> {
    return this.authorized$.pipe(
      switchMap(([authorized, staffAuthorized]) => combineLatest([
        (authorized || staffAuthorized)
          ? this.store.select(getLists)
          : this.store.select(getForLaterList).pipe(map((list) => [{...list, itemsCount: getListItemsCount(list)}])),
        of(authorized || staffAuthorized),
      ])),
    );
  }

  public getListsWithOpened(): Observable<[ListWithItemsCount[], ListWithItemsCount | null, boolean]> {
    return this.getLists().pipe(
      switchMap(([lists, authorized]) => combineLatest([
        of(lists),
        (lists.length === 1)
          ? of(lists[0])
          : this.store.select(getOpenedList),
        of(authorized),
      ])),
    );
  }

  public addBookmarkFromButton(entity: ListItemEntity): void {
    this.store.dispatch(highlightHeaderBookmarkButton());
    this.dispatchIfAuthorized(addBookmarkViaButton({entity}), addToList({entity}));
  }

  public removeBookmark(entity: ListItemEntity): void {
    this.dispatchIfAuthorized(removeBookmarkViaButton({entity}), removeFromList({entity}));
  }

  public removeSelectedFromList(listId: string): void {
    this.dispatchIfAuthorized(removeSelectedFromList({listId}), forLaterRemoveSelectedFromList());
  }

  public toggleItemSelection(listId: string, itemId: string): void {
    this.dispatchIfAuthorized(toggleItemSelection({listId, itemId}), forLaterToggleItemSelection({itemId}));
  }

  public setAllItemsSelection(listId: string, selected: boolean): void {
    this.dispatchIfAuthorized(setAllItemsSelection({listId, selected}), forLaterSetAllItemsSelection({selected}));
  }

  private dispatchIfAuthorized(action: Action, elseAction: Action): void {
    this.authorized$.pipe(
      take(1),
      tap(([authorized, staffAuthorized]) => {
        this.store.dispatch((authorized || staffAuthorized) ? action : elseAction);
      }),
    ).subscribe();
  }
}
