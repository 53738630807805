import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DropdownValue } from 'common/models/custom-dropdown';
import { filter } from 'rxjs';
import { EntityTypes } from '../../../entity/models/entity';
import { searchLists } from '../../actions/list.actions';
import { ListWithItemsCount } from '../../models/list';
import { getSearchResults } from '../../reducers/list.reducer';

@Component({
  selector: 'app-searchable-bookmarks',
  templateUrl: './searchable-bookmarks.component.html',
  styleUrls: ['./searchable-bookmarks.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({left: '-100%'})),
      state('false', style({left: '0%'})),
      transition('true <=> false', animate('0.5s ease-out')),
    ]),
  ],
})
export class SearchableBookmarksComponent implements OnInit {
  public lists: ListWithItemsCount[] = [];
  public isReversedSmall = false;

  constructor(
    private readonly store: Store
  ) {
  }

  ngOnInit() {
    this.store.select(getSearchResults)
    .pipe(filter(Boolean))
    .subscribe(data => {
      this.lists = Object.entries(data).map(([key, value]) => value);
    });
  }

  public onOpenCloseAnimation(event: AnimationEvent): void {

  }

  public onKeydownEnter(value: string) {
    this.store.dispatch(searchLists({
      query: {
        pageNum: 0,
        pageSize: 100,
        resourceType: EntityTypes.FORMAT_GROUP,
        searchText: value
      }
    }));
  }

  public onDropdownChange(event: DropdownValue<string>) {
  }
}
