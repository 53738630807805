<div class="profile-card-info-title d-flex align-items-center mb-1">
  <fa-icon [classes]="['profile-icon']" [icon]="icon"></fa-icon>
  <div class="d-flex justify-content-between align-items-center flex-grow-1">
    <div>
      <label data-automation-id="profile-card-info-title-label"
           [id]="labelId"
           for="{{inputLabel}}"
           [attr.aria-describedby]="errorId">
        {{ inputLabel }}
      </label>
      <div aria-atomic="true" aria-live="assertive" role="alert" [id]="errorId"
           data-automation-id="profile-card-info-title-label-error">
        <div *ngIf="serverError" class="invalid-feedback d-block mt-1"
             data-automation-id="profile-card-info-title-label-error-message">
          <ng-container *ngIf="serverError === serverErrorEnum.update">
            {{ 'profileFieldErrorServerUpdate' | translate: {inputLabel: inputLabel} }}
          </ng-container>
        </div>
      </div>
    </div>

    <ng-container *ngIf="isHelpDisplayed">
      <button [ngbPopover]="popoverContent"
              attr.aria-label="{{inputLabel}} {{ 'profileFieldInformation' | translate }}"
              aria-haspopup="true"
              attr.aria-expanded="{{ isPopoverDisplayed }}"
              class="app-button profile-card-info-button"
              placement="bottom bottom-right"
              popoverClass="profile-field-popover"
              data-automation-id="profile-card-info-title-help-button"
              type="button"
              (click)="isPopoverDisplayed = !isPopoverDisplayed">
        <fa-icon class="arrow-icon" [icon]="infoIcon"></fa-icon>
      </button>

      <ng-template #popoverContent>
        <div data-automation-id="profile-field-popover">
          {{ 'profileToChangeYour' | translate }}
          <span *ngIf="fieldName; else inputLabelTemplate">{{ fieldName }}</span>

          <ng-template #inputLabelTemplate>
            <span>{{ inputLabel }}</span>
          </ng-template>
          <span>, </span>

          <app-contact-your-library-link [libraryContactLink]="libraryContactLink">
            {{ 'profileContactYourLibrary' | translate }}
          </app-contact-your-library-link>
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="isRemoveButtonDisplayed">
      <app-remove-button [controlsId]="labelId" (onClick)="onRemove.emit()"></app-remove-button>
    </ng-container>
  </div>
</div>
