import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DictionariesService } from 'app/services/dictionaries.service';
import { I18NService } from 'app/services/i18-n.service';

import { SearchResultHelper } from 'core/utils/search-result-helper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SessionExpiredDialogComponent } from 'user/components/session-expired-dialog/session-expired-dialog.component';
import { Checkout } from 'user/models/checkouts';
import { FinesData } from 'user/models/fine';
import { Hold } from 'user/models/holds';
import { PatronPasscodePayload } from 'user/models/passcode';

import { ProfileUpdate, StaffUser, User, UserLocationsToUpdate } from 'user/models/user';
import { Vendor } from 'user/models/vendor';
import { CustomShowcaseEffects } from '../../custom-showcase/effects/custom-showcase.effects';
import { CustomShowcaseDto } from '../../custom-showcase/models/custom-showcase.dto';
import { FormattedVendor } from '../../entity/models/econtent';
import { ListEffects } from '../../list/effects/list.effects';
import { ListDto } from '../../list/models/list.dto';

@Injectable()
export class UserProfileService {
  private readonly SEARCH_RESULT_PATRONS_URL = 'api/search-result/gates/patrons/me';
  private readonly SEARCH_RESULT_PATRONS_ME_URL = 'api/search-result/patrons/me';
  private readonly SEARCH_RESULT_USERS_ME_URL = 'api/search-result/users/me';
  private readonly SEARCH_RESULT_GATES_HOLDS_URL = 'api/search-result/gates/holds';
  private readonly HOLD_ECONTENT_VENDORS = [Vendor.OVERDRIVE, Vendor.AXIS360, Vendor.CLOUD_LIBRARY, Vendor.BORROW_BOX];
  private sessionExpiredDialog: NgbModalRef;

  constructor(
    private readonly http: HttpClient,
    private readonly searchResultHelper: SearchResultHelper,
    private readonly dictionariesService: DictionariesService,
    private readonly dialog: NgbModal,
    private readonly i18nService: I18NService,
  ) {
  }

  public renewCheckout(checkoutId: string): Observable<Checkout> {
    return this.http.post<Checkout>(`${this.SEARCH_RESULT_PATRONS_ME_URL}/checkouts/${checkoutId}/renew`, null);
  }

  public openSessionExpiredDialog(isAuthorized: boolean) {
    if (this.sessionExpiredDialog) {
      return;
    }
    this.sessionExpiredDialog = this.dialog.open(SessionExpiredDialogComponent, {
      centered: true,
      windowClass: 'inspire-custom-modal',
      backdrop: 'static',
    });
    this.sessionExpiredDialog.componentInstance.isAuthorized = isAuthorized;
    this.sessionExpiredDialog.result.finally(() => {
      this.sessionExpiredDialog = null;
    });
  }

  public getUser(): Observable<{ user: User, lists?: ListDto[], showcases?: CustomShowcaseDto[] }> {
    return this.http.get<User & { lists?: ListDto[] } & { showcases?: CustomShowcaseDto[] }>(this.SEARCH_RESULT_PATRONS_ME_URL, {
      headers: {'api-version': '2'},
      params: {
        listsPrefetch: ListEffects.listItemsPrefetched.toString(),
        showcasesPrefetch: CustomShowcaseEffects.showcaseItemsPrefetched.toString(),
      },
    }).pipe(
      map((userWithListsAndShowcases) => {
        const {lists, showcases, ...user} = userWithListsAndShowcases;
        const libraryLocations: UserLocationsToUpdate = this.extractLibraryLocationsFromCodes({
          homeLibrary: userWithListsAndShowcases.homeLibraryCode,
          preferredPickupLocation: userWithListsAndShowcases.preferredPickupLocationCode,
          registeredBranch: userWithListsAndShowcases.registeredBranchId
        });

        return {
          user: {...user, ...libraryLocations},
          lists,
          showcases,
        };
      }),
    );
  }

  public getStaffUser(): Observable<{ user: StaffUser, lists?: ListDto[] }> {
    return this.http.get<StaffUser & { lists?: ListDto[] }>(this.SEARCH_RESULT_USERS_ME_URL, {
      params: {
        listsPrefetch: ListEffects.listItemsPrefetched.toString(),
      },
    })
    .pipe(
      map((userWithLists) => {
        const {lists, ...user} = userWithLists;
        return {user, lists};
      }),
    );
  }

  public getUserCheckouts(): Observable<Checkout[]> {
    return this.http.get<Checkout[]>(`${this.SEARCH_RESULT_PATRONS_ME_URL}/checkouts`);
  }

  public getUserHolds(requestedHoldIds: string[] = []): Observable<Hold[]> {
    const params = {placedHoldIds: requestedHoldIds.join(',')};
    return this.http.get<Hold[]>(`${this.SEARCH_RESULT_PATRONS_ME_URL}/holds`, {params})
    .pipe(
      map((holds) => (
        holds.map((hold) => ({
          ...hold,
          location: this.extractPickupLocation(hold),
          locationCode: hold.location
        }))
      )),
    );
  }

  public getUserFines(): Observable<FinesData> {
    return this.http.get<FinesData>(`${this.SEARCH_RESULT_PATRONS_URL}/fines`);
  }

  public updateUserProfile(profileUpdate: ProfileUpdate): Observable<void> {
    return this.http.patch<void>(`${this.SEARCH_RESULT_PATRONS_ME_URL}/profile`, profileUpdate, {headers: {'api-version': '2'}});
  }

  public updatePatronPasscode(patronPasscodePayload: PatronPasscodePayload): Observable<void> {
    return this.http.post<void>(`${this.SEARCH_RESULT_PATRONS_ME_URL}/passcode`, patronPasscodePayload);
  }

  public cancelHold(holdId: string): Observable<void> {
    return this.http.delete<void>(`${this.SEARCH_RESULT_GATES_HOLDS_URL}/${holdId}`);
  }

  public freezeHold(holdId: string): Observable<void> {
    return this.http.put<void>(`${this.SEARCH_RESULT_GATES_HOLDS_URL}/${holdId}/freeze`, null);
  }

  public unfreezeHold(holdId: string): Observable<void> {
    return this.http.put<void>(`${this.SEARCH_RESULT_GATES_HOLDS_URL}/${holdId}/unfreeze`, null);
  }

  public extractLibraryLocationsFromCodes(libraryCodesForUpdate: Object): UserLocationsToUpdate {
    let libraryLocations: UserLocationsToUpdate = {};
    for (const [key, value] of Object.entries(libraryCodesForUpdate)) {
      if (value) {
        (libraryLocations)[key] = this.searchResultHelper.extractPhysicalLocationByCode(value);
      }
    }

    return libraryLocations;
  }

  private extractPickupLocation(hold: Hold): string {
    let location = this.searchResultHelper.extractPhysicalLocationByCode(hold.location) ? `location.${hold.location}` : null;
    if (!location) {
      location = this.HOLD_ECONTENT_VENDORS.includes(hold.vendor)
        ? `${this.i18nService.getTranslation('pickUpLocationOnline')} ${FormattedVendor[hold.vendor]}`
        : `${this.i18nService.getTranslation('Unknown')} ('${hold.location}')`;
    }
    return location;
  }
}
