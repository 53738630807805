import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CustomerIntegrationType } from '../../../../../../customer-integration/customer-integration';
import {
  AvailabilityUpdateInfo,
  EditionType,
  GetItRequest,
  HoldRequest,
  MaterialTab,
} from '../../../../../models/entity';
import { GroupedLocationInfo, LocationAvailabilityStatus } from 'search/models/search-results';
import { RollupSideDrawerContainer } from '../../../../rollup-side-drawer-container/rollup-side-drawer-container.component';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { VendorLogo } from 'shared/models/configuration';

@Component({
  selector: 'app-rollup-tabs-content',
  templateUrl: './rollup-tabs-content.component.html',
  styleUrls: ['./rollup-tabs-content.component.scss'],
})
export class RollupTabsContentComponent implements OnInit, OnDestroy {
  @Input() public materialTab: MaterialTab;
  @Input() public availabilityUpdateInfo: AvailabilityUpdateInfo;
  @Input() public queryParams = {};
  @Input() public fgId: string;
  @Input() public fgTitle: string;
  @Input() public ariaDescribedBy = '';
  @Input() public language: string;
  @Input() public hasEcontentIssues: boolean;

  @Output() public getIt$ = new EventEmitter<{ item: GetItRequest }>(true);
  @Output() public placeHold$ = new EventEmitter<{ item: HoldRequest }>(true);
  @Output() public checkLocationAndEditionInfo$ = new EventEmitter<{ resourceId: string }>(true);

  public readonly subscriptions = new Subscription();
  public isElectronic: boolean;
  public instanceIds: string[];
  public isNlbCustomer: boolean;
  public locations: GroupedLocationInfo[];
  public vendorsLogo: { [key: string]: string };
  public nonIntegratedLogo: { [key: string]: VendorLogo }
  public recordId: string;

  @ViewChild('getItButton', { read: ElementRef }) public getItButton: ElementRef;

  constructor(
    private configLoader: ConfigurationLoader,
    public router: Router,
    private readonly modalService: NgbModal,
  ) {
  }

  public ngOnInit() {
    this.recordId = this.materialTab.recordIds?.[0];
    this.vendorsLogo = this.configLoader.vendorsLogo;
    this.nonIntegratedLogo = this.configLoader.nonIntegratedConnectionsLogoMapping;
    this.isElectronic = EditionType.ELECTRONIC === this.materialTab.type;
    this.isNlbCustomer = this.configLoader.customerIntegrationType === CustomerIntegrationType.NLB;
    this.instanceIds = this.materialTab.editions?.map((instance) => instance.id);
    const locationMap: { [key: string]: GroupedLocationInfo } = {};

    this.materialTab.locations?.forEach(({ label, code, availabilityStatus }) => {
      const current: GroupedLocationInfo = locationMap[label] || { label, codes: [], availabilityStatus };
      current.codes.push(code);
      locationMap[label] = current;
      if (availabilityStatus === LocationAvailabilityStatus.Available) {
        locationMap[label].availabilityStatus = availabilityStatus;
      }
    }, locationMap);
    this.locations = Object.values(locationMap);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onCheckEditions() {
    this.router.navigate(['/search', 'card'], {
      queryParams: this.queryParams,
    });
    this.checkLocationAndEditionInfo$.emit();
  }

  public onCheckItemAvailability(location?: GroupedLocationInfo) {
    const getItButtonElement = this.getItButton && this.getItButton.nativeElement as HTMLElement;
    const modal = this.modalService.open(RollupSideDrawerContainer, {
      windowClass: 'drawer-modal',
      ariaLabelledBy: 'drawer-items-title'
    });
    modal.componentInstance.fgId = this.fgId;
    modal.componentInstance.tabName = this.materialTab.name;
    modal.componentInstance.title = this.fgTitle;
    modal.componentInstance.getItButtonTitle = getItButtonElement && getItButtonElement.textContent;
    modal.componentInstance.location = location;
    modal.componentInstance.showAllLocations = !location;
    modal.componentInstance.hideBlock = location ? 'step1' : 'step2';
    modal.componentInstance.isNlbCustomer = this.isNlbCustomer;
    modal.componentInstance.language = this.language;

    modal.result.then((result) => {
      if (result === 'getIt') {
        const element = getItButtonElement && getItButtonElement.querySelector<HTMLElement>('button, a');
        element.click();
      }
    }, () => {
    });
  }
}
