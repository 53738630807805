import { FacetType } from 'search/facets/models/facet-type';
import {
  Record,
  RecordOf,
} from 'immutable';

export interface FacetSettingsForm {
  configurationId: string;
  facets: FacetSchemaProps[];
}

export interface FacetSchemaProps {
  key: string;
  blockName: string;
  singleName?: string;
  pluralName?: string;
  order: number;
  type: FacetType;
  mapField?: string;
  hasModal?: boolean;
  isShown: boolean;
}

export const defaultFacetSchema: FacetSchemaProps = {
  key: 'UNKNOWN',
  blockName: 'UNKNOWN',
  pluralName: 'UNKNOWN',
  singleName: 'UNKNOWN',
  order: 999,
  type: FacetType.Iterable,
  isShown: false,
  hasModal: false,
  mapField: 'UNKNOWN',
};

export const makeFacetSchema = Record(defaultFacetSchema, 'facetSchema');

export type FacetSchema = RecordOf<FacetSchemaProps>;
