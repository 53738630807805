import { Component, Input, OnInit, } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationWithName } from 'app/models/locations';
import { Hold } from 'user/models/holds';

interface HoldLocationForm {
  pickupLocation: FormControl<string>;
}

@Component({
  selector: 'app-change-hold-location-dialog',
  templateUrl: './change-hold-location-dialog.component.html',
  styleUrls: ['./change-hold-location-dialog.component.scss'],
})
export class ChangeHoldLocationDialogComponent implements OnInit {
  @Input() public pickupLocations: LocationWithName[];
  @Input() public hold: Hold;
  public holdLocationForm: FormGroup<HoldLocationForm>;
  public selectedLocationCode: string;

  constructor(
    public readonly activeModal: NgbActiveModal,
    private readonly fb: FormBuilder,
  ) {}

  public ngOnInit() {
    const currentLocation = this.findLocationByCode(this.hold.locationCode);
    this.selectedLocationCode = currentLocation ? currentLocation.code : this.pickupLocations[0].code;
    this.holdLocationForm = this.fb.group({
      pickupLocation: new FormControl(this.selectedLocationCode, Validators.required),
    });
  }

  public onClose() {
    this.activeModal.dismiss();
  }

  public onSubmit() {
    const pickupLocation = this.findLocationByCode(this.holdLocationForm.value.pickupLocation);
    this.activeModal.close({pickupLocation});
  }

  private findLocationByCode(code: string): LocationWithName {
    return this.pickupLocations.find((loc) => loc.code === code);
  }
}
