import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle as faCheckCircleSolid, faStar, faTimesCircle as faTimesCircleSolid } from '@fortawesome/pro-solid-svg-icons';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { GroupedLocationInfoAvailability } from 'search/models/search-models';
import { GroupedLocationInfo } from 'search/models/search-results';

@Component({
  selector: 'app-location-list-drawer',
  templateUrl: './location-list-drawer.component.html',
  styleUrls: ['./location-list-drawer.component.scss'],
})
export class LocationListDrawerComponent implements OnInit, OnDestroy {
  @Input() public loading: boolean;
  @Input() public locations$: Observable<GroupedLocationInfoAvailability>;
  @Input() public title: string;
  @Output() public dismissModal = new EventEmitter<void>();
  @Output() public changeLocation = new EventEmitter<GroupedLocationInfo>();
  @ViewChild('firstElement') public firstElement: ElementRef;

  public readonly availableIcon = faCheckCircle;
  public readonly unavailableIcon = faTimesCircle;
  public readonly availableIconSolid = faCheckCircleSolid;
  public readonly unavailableIconSolid = faTimesCircleSolid;
  public readonly starIcon = faStar;

  public readonly searchLocationsForm: UntypedFormGroup;
  public filteredLocations: GroupedLocationInfoAvailability = {
    available: [],
    unavailable: [],
  };
  private readonly subscriptions = new Subscription();

  constructor(
    private readonly fb: UntypedFormBuilder,
  ) {
    this.searchLocationsForm = this.fb.group({
      search: [''],
    });
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      combineLatest([
        this.searchLocationsForm.get('search').valueChanges.pipe(startWith('')),
        this.locations$,
      ]).subscribe(([searchTerm, locations]) => {
        if (!locations) {
          this.filteredLocations = {available: [], unavailable: []};
          return;
        }

        this.filteredLocations = (searchTerm)
          ? {
            available: this.filterLocations(locations.available, searchTerm),
            unavailable: this.filterLocations(locations.unavailable, searchTerm),
          }
          : {
            available: [...locations.available],
            unavailable: [...locations.unavailable],
          };
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onDismiss() {
    this.dismissModal.emit();
  }

  public focusFirstElement() {
    this.firstElement.nativeElement.focus();
  }

  private filterLocations(locations: GroupedLocationInfo[], searchTerm: string): GroupedLocationInfo[] {
    return locations.filter((location) => (
      location.label.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    ));
  }
}
