<ng-container [formGroup]="control">
  <div *ngFor="let value of control.controls; index as i" class="d-flex mb-1">
    <app-profile-form-field-input #fieldInput
      [formControlName]="i"
      [formControlLabel]="inputLabel"
      [autocompleteValue]="autocompleteValue" class="flex-grow-1">
    </app-profile-form-field-input>
    <app-remove-button *ngIf="control.controls.length > 1"
                       [controlsId]="fieldInput.formControlLabel"
                       [inputValue]="value.value"
                       (onClick)="removeControl(i)"></app-remove-button>
  </div>
  <app-add-another-button [inputLabel]="inputLabel" (setFocus)="setFocusOnInputField()" (onClick)="onAddControl.emit()">
    {{ 'profileAddAnother' | translate }}
  </app-add-another-button>
</ng-container>
