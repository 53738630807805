import { HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { loadFines, resetFinesPaymentStatus } from 'user/actions/user-profile.actions';
import { MaxHeightPerfectScrollbar } from 'user/models/bookshelf';
import {
  Fine,
  FineActions,
  FineActionsPaypal,
  FinePaymentUrl,
  FinePaymentUrlPaypal,
  FineQueryParamNames,
  FinesPaymentStatus
} from 'user/models/fine';
import { FinesState, getFinesPaymentStatus, getFinesState, UserState } from 'user/reducers/user.reducer';
import { WindowRefService } from '../../../../services/window-ref.service';

@Component({
  selector: 'app-fines',
  templateUrl: './fines.component.html',
  styleUrls: ['./fines.component.scss'],
})
export class FinesComponent implements OnInit, OnDestroy {
  public totalAmount?: number;
  public selectedFines: Set<string> = new Set();
  public selectedAmount = 0;
  public finesState: FinesState | null;
  public fines: Fine[] | undefined;
  public fineActions: FineActions | undefined;
  public paymentStatus: FinesPaymentStatus | undefined;
  public maxHeightPerfectScrollbar = MaxHeightPerfectScrollbar.BASE_ACCOUNT_TAB;
  public payAllLink: string;
  public paySelectedLink: string;
  public isPaySelectedEnabled = false;
  public isPayAllEnabled = false;
  public minimumAmount = 0;
  public readonly paymentStatusEnum = FinesPaymentStatus;
  private readonly subscriptions = new Subscription();

  constructor(
    private readonly store: Store<UserState>,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly windowRef: WindowRefService,
  ) {
  }

  public ngOnInit(): void {
    this.store.dispatch(loadFines());

    this.subscriptions.add(
      this.store.select(getFinesState).subscribe((finesState) => {
        this.finesState = finesState;
        this.fines = finesState?.finesData?.data;
        this.fineActions = finesState?.finesData?.actions;
        this.minimumAmount = this.fineActions?.minimumAmount || 0;
        this.totalAmount = finesState?.finesData?.totalOutstandingAmount;
        this.isPayAllEnabled = this.totalAmount > 0 && this.totalAmount >= this.minimumAmount;
        this.generatePayAllLink();
        this.changeDetectorRef.detectChanges();
      }),
    );

    this.subscriptions.add(
      this.store.select(getFinesPaymentStatus).subscribe((paymentStatus) => {
        this.paymentStatus = paymentStatus;
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
    if (this.paymentStatus) {
      this.store.dispatch(resetFinesPaymentStatus());
    }
  }

  public onFineSelectionChange(fine: Fine): void {
    if (this.selectedFines.has(fine.id)) {
      this.selectedFines.delete(fine.id);
      this.selectedAmount = +(this.selectedAmount - fine.outstandingAmount).toFixed(2);
    } else {
      this.selectedFines.add(fine.id);
      this.selectedAmount = +(this.selectedAmount + fine.outstandingAmount).toFixed(2);
    }
    this.isPaySelectedEnabled = this.selectedAmount && (this.selectedAmount >= this.minimumAmount);
    this.generatePaySelectedLink();
  }

  public trackById(index: number, fine: Fine): string {
    return fine.id;
  }

  public paySelected(event: Event): void {
    if (!this.isPaySelectedEnabled) {
      event.preventDefault();
    }

    this.windowRef.nativeWindow().open(this.paySelectedLink, '_blank');
  }

  public payTotal(event: Event): void {
    if (!this.isPayAllEnabled) {
      event.preventDefault();
    }

    this.windowRef.nativeWindow().open(this.payAllLink, '_blank');
  }

  private static generatePaymentLink(paymentUrl: FinePaymentUrl, fines: string[], amount: number): string {
    const queryParams: { [p: string]: string } = {};
    let paymentLink = paymentUrl.url;
    const queryParamNames = (paymentUrl as FinePaymentUrlPaypal).queryParamNames;
    if (queryParamNames?.length) {
      if (queryParamNames.includes(FineQueryParamNames.fines)) {
        queryParams[FineQueryParamNames.fines] = fines.toString();
      }
      if (queryParamNames.includes(FineQueryParamNames.amount)) {
        queryParams[FineQueryParamNames.amount] = amount.toString();
      }
      const httpParams = new HttpParams({fromObject: queryParams}).toString();
      paymentLink = `${paymentLink}&${httpParams}`;
    }
    return paymentLink;
  }

  private generatePayAllLink(): void {
    const totalFines = this.fines?.map((fine) => fine.id);
    this.payAllLink = (this.isPayAllEnabled && this.fineActions?.payAll) ?
      FinesComponent.generatePaymentLink(this.fineActions.payAll, totalFines, this.totalAmount) : '';
  }

  private generatePaySelectedLink(): void {
    const paySelected = (this.fineActions as FineActionsPaypal)?.paySelected;
    this.paySelectedLink = (this.isPaySelectedEnabled && paySelected) ?
      FinesComponent.generatePaymentLink(paySelected, [...this.selectedFines], this.selectedAmount) : '';
  }
}
