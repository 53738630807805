import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { isAuthorizedInKeycloak } from '../reducers/keycloak.reducer';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'app/keycloak/services/keycloak.service';
import { Location } from '@angular/common';

@Injectable()
export class AuthorizedGuard implements CanActivate {
  constructor(
    private store: Store,
    private keyCloakService: KeycloakService,
    private location: Location,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    return this.store.select(isAuthorizedInKeycloak)
      .pipe(
        map((isAuthorized) => {
          if (!isAuthorized) {
            this.location.go(state.url);
            this.keyCloakService.login();
          }
          return true;
        }),
      );
  }
}
