import { getJSON } from 'shared/utils/get-json';
import { LOCAL_STORAGE } from '../models/local-storage';

export class CustomDomainLoader {
  private readonly customDomainURL = `${window.location.origin}/lookup`;
  private readonly defaultDomain = window.location.host;
  private _domain: string;

  public get domain() {
    return this._domain;
  }

  public async loadDomain(): Promise<string> {
    this._domain = this._domain || this.getDomainFromLocalStorage();
    if (!this._domain) {
      this._domain = await this.lookupCustomDomain() || this.defaultDomain;
      this.setDomainToLocalStorage(this._domain);
    }
    return Promise.resolve(this._domain);
  }

  private getDomainFromLocalStorage(): string {
    return localStorage.getItem(LOCAL_STORAGE.CUSTOM_DOMAIN);
  }

  private setDomainToLocalStorage(domain: string): void {
    localStorage.setItem(LOCAL_STORAGE.CUSTOM_DOMAIN, domain);
  }

  private lookupCustomDomain(): Promise<string | undefined> {
    return getJSON(this.customDomainURL)
    .then((code: string) => this.constructDomain(code))
    .catch(() => undefined);
  }

  private constructDomain(code: string): string {
    return `${code}.${API_URL.replace(/^https?:\/\//, '')}`;
  }
}

export const customDomainLoader = new CustomDomainLoader();
