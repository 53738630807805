<div aria-live="polite" class="d-flex w-100 mt-2 justify-content-center" role="status">
  <div *ngIf="!isLoaded"
       class="spinner-border text-primary p-2 mb-8"
       data-automation-id="unbound-lookinside-spinner">
    <span class="sr-only" translate>Loading...</span>
  </div>
</div>

<div [class.unbound_lookinside--d-none]="!isDisplayed">
  <div class="unbound_lookinside"></div>
</div>
