import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { CustomDomainLoader } from '../../../../services/custom-domain-loader';
import { CustomerIntegrationType } from '../../../customer-integration';
import { ItemVolume, Volume } from 'user/models/get-it';
import { Observable } from 'rxjs';
import { MaterialTypeCardInfo } from 'app/entity/models/entity';

@Injectable({
  providedIn: 'root',
})
export class NlbHoldService {
  private readonly nlbRedirectUrl = `${API_URL}/api/search-result/public/nlb/redirect`;
  private readonly volumeRedirectUrl = `${API_URL}/api/search-result/public/nlb/redirect/volume`;
  private readonly itemVolumeRedirectUrl = `${API_URL}/api/search-result/public/nlb/redirect/item-as-volume`;
  private readonly nlbOptimalEditionUrl = 'api/search-result/public/nlb/redirect/optimal-edition';

  constructor(
    private readonly configLoader: ConfigurationLoader,
    private readonly customDomainLoader: CustomDomainLoader,
    private readonly http: HttpClient,
  ) {
  }

  public nlbTabHold(payload: MaterialTypeCardInfo): Observable<string> {
    const params = {
      formatGroupId: payload.id,
      materialTabName: payload.tabName,
      iii_customer_domain: this.customDomainLoader.domain
    };
    return this.http.get<string>(`${this.nlbOptimalEditionUrl}`, {params});
  }

  public redirectToVolume(volume: Volume): Observable<any> {
    const volumeId = JSON.parse(volume.id).bibId;
    const params = {
      brn: volumeId,
      volumeName: volume.volume,
      iii_customer_domain: this.customDomainLoader.domain
    };
    return this.http.get(`${this.volumeRedirectUrl}`, { params });
  };

  public redirectToItemVolume(itemAsVolume: ItemVolume, recordId: string): Observable<any> {
    const params = {
      recordId: recordId,
      itemId: itemAsVolume.id,
      iii_customer_domain: this.customDomainLoader.domain
    };
    return this.http.get(`${this.itemVolumeRedirectUrl}`, { params });
  };

  public getOptimalEditionUrl(fgId: string, tabName: string, pickupLocation?: string): string | null {
    if (this.configLoader.customerIntegrationType !== CustomerIntegrationType.NLB) {
      return null;
    }

    let httpParams = new HttpParams()
    .set('formatGroupId', fgId)
    .set('materialTabName', tabName)
    .set('iii_customer_domain', this.customDomainLoader.domain);

    if (pickupLocation) {
      httpParams = httpParams.set('pickupLocation', pickupLocation);
    }

    return `${this.nlbRedirectUrl}/optimal-edition?${httpParams.toString()}`;
  }

  public getRedirectUrlToVolume(volume: Volume): string {
    const volumeId = JSON.parse(volume.id).bibId;
    const httpParams = new HttpParams()
      .set('brn', volumeId)
      .set('volumeName', volume.volume)
      .set('iii_customer_domain', this.customDomainLoader.domain);
      return `${this.volumeRedirectUrl}?${httpParams.toString()}`;
  };

  public getRedirectUrlToItemAsVolume(itemAsVolume: ItemVolume, recordId: string): string {
    const httpParams = new HttpParams()
      .set('recordId', recordId)
      .set('itemId', itemAsVolume.id)
      .set('iii_customer_domain', this.customDomainLoader.domain);
      return `${this.itemVolumeRedirectUrl}?${httpParams.toString()}`;
  };

  public getEditionUrl(recordId: string): string | null {
    if (this.configLoader.customerIntegrationType !== CustomerIntegrationType.NLB) {
      return null;
    }

    const httpParams = new HttpParams()
    .set('recordId', recordId)
    .set('iii_customer_domain', this.customDomainLoader.domain);

    return `${this.nlbRedirectUrl}/edition?${httpParams.toString()}`;
  }
}
