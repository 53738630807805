import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import { LocationInfoView } from 'shared/models/library-info';

@Component({
  selector: 'app-library-info-content',
  templateUrl: './library-info-content.component.html',
  styleUrls: ['./library-info-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LibraryInfoContentComponent {
  @Input() public data: LocationInfoView;
  @Input() public loading: boolean;
}
