import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as Immutable from 'immutable';
import { ResourceFacet } from 'search/facets/models/resource-facet';
import { BookCover } from 'search/models/search-results';
import { ListItem, ListItemEntity, ListSort } from '../../list/models/list';
import { ListPagination } from '../../list/models/list.dto';
import { ListItemsLoadingState } from '../../list/reducers/list.reducer';
import { focusShareButton, stopFocusShareButton } from '../../saved-search/actions/saved-search.actions';
import { getSavedSearchState } from '../../saved-search/reducers/saved-search.reducer';
import * as CustomShowcaseActions from '../actions/custom-showcase.actions';
import {
  CustomShowcase,
  CustomShowcaseCreatedFrom,
  CustomShowcaseCreator,
  CustomShowcaseForm,
  CustomShowcaseMetadata,
  CustomShowcaseSingle,
} from '../models/custom-showcase';
import { CustomShowcasePagination } from '../models/custom-showcase.dto';

export interface CreateState {
  loading: boolean;
  errorCode: number | null;
  id: string | null;
}

export interface UpdateState {
  id: string;
  loading: boolean;
  errorCode: number | null;
  updated: boolean;
}

export interface RemoveState {
  loading: boolean;
  errorCode: number | null;
  removed: boolean;
}

export interface LoadState {
  loading: boolean;
  errorCode: number | null;
  loaded: boolean;
}

export interface CustomShowcaseTotalCounts {
  my: number | null;
  all: number | null;
}

export interface CustomShowcaseFormData {
  showcase?: CustomShowcaseForm;
}

export interface StoredCustomShowcase {
  id: string;
  name: string;
  creator: CustomShowcaseCreator;
  createdFrom: CustomShowcaseCreatedFrom;
  published: boolean;
  publishDate: string | null;
  metadata: CustomShowcaseMetadata;
  itemsCount: number;
  items: Array <ListItemEntity | {coverConfig: BookCover}>;
}

export interface AllCustomShowcases {
  list: StoredCustomShowcase[];
  pagination: CustomShowcasePagination | null;
}

export interface ShowcaseLoadingState {
  loading: boolean;
  errorCode: number | null;
  loaded: boolean;
}

export interface ShowcaseSavedSearchLoadingState {
  loading: boolean;
  error: number | null;
}

export type CustomShowcaseRecord = Immutable.Record<StoredCustomShowcase>;
export type CustomShowcaseSingleRecord = Immutable.Record<CustomShowcaseSingle>;

export type OpenedState = {
  showcase: CustomShowcaseSingleRecord | null,
  showcaseLoadingState: Immutable.Record<ShowcaseLoadingState>;
  items: Immutable.List<ListItem>;
  itemsLoadingState: Immutable.Record<ListItemsLoadingState>;
  itemsPagination: Immutable.Record<ListPagination>;
  listSortToRestoreOnClose: ListSort | null;
  showcaseSearchData: ShowcaseSearchData;
  showcaseSearchDataLoading: Immutable.Record<ShowcaseSavedSearchLoadingState>;
};

export interface ShowcaseSearchData {
  facets: ResourceFacet[];
  searchText: string;
  sorting: string;
}

export interface CustomShowcaseState {
  myCustomShowcases: Immutable.List<CustomShowcaseRecord>;
  allCustomShowcases: {
    list: Immutable.List<CustomShowcaseRecord>;
    pagination: CustomShowcasePagination | null;
  };
  searchMyCustomShowcases: Immutable.List<CustomShowcaseRecord>;
  searchText: string;
  switcherState: {
    isAll: boolean;
  };
  allTotalCount: number;
  loadState: Immutable.Record<LoadState>;
  createState: Immutable.Record<CreateState>;
  updateState: Immutable.Record<UpdateState>;
  removeState: Immutable.Record<RemoveState>;
  formData: Immutable.Record<CustomShowcaseFormData>;
  doFocusList: boolean;
  openedState: OpenedState | null;
  sharedShowcase: CustomShowcase;
  focusShareButton: string | null;
}

export const isLastPageLoaded = (pagination: CustomShowcasePagination) => (pagination.page >= pagination.totalPages - 1);

export const CustomShowcaseRecordFactory = Immutable.Record<StoredCustomShowcase>({
  id: '',
  name: '',
  creator: null,
  createdFrom: null,
  published: false,
  publishDate: null,
  metadata: null,
  itemsCount: null,
  items: null,
});

export const CustomShowcaseSingleRecordFactory = Immutable.Record<CustomShowcaseSingle>({
  id: '',
  name: '',
  creator: null,
  createdFrom: null,
  published: false,
  publishDate: null,
  metadata: null,
});

const CreateStateRecord = Immutable.Record<CreateState>({
  loading: false,
  errorCode: null,
  id: null,
});

const UpdateStateRecord = Immutable.Record<UpdateState>({
  id: null,
  loading: false,
  errorCode: null,
  updated: false,
});

const LoadStateRecord = Immutable.Record<LoadState>({
  loading: false,
  errorCode: null,
  loaded: false,
});

const RemoveStateRecord = Immutable.Record<RemoveState>({
  loading: false,
  errorCode: null,
  removed: false,
});

const FormDataRecord = Immutable.Record<CustomShowcaseFormData>({
  showcase: null,
});

export const ShowcaseLoadingStateRecord = Immutable.Record<ShowcaseLoadingState>({
  loading: false,
  errorCode: null,
  loaded: false,
});

export const ShowcaseItemsLoadingStateRecord = Immutable.Record<ListItemsLoadingState>({
  loading: false,
  error: null,
});

export const ShowcaseSavedSearchLoadingStateRecord = Immutable.Record<ShowcaseSavedSearchLoadingState>({
  loading: false,
  error: null,
});

export const ShowcaseItemsPaginationRecord = Immutable.Record<ListPagination>({
  page: 0,
  totalPages: 0,
  totalResults: 0,
});

export const initialState: CustomShowcaseState = {
  myCustomShowcases: Immutable.List(),
  allCustomShowcases: {
    list: Immutable.List(),
    pagination: null,
  },
  searchMyCustomShowcases:Immutable.List(),
  searchText: '',
  switcherState: {
    isAll: false,
  },
  allTotalCount: null,
  loadState: LoadStateRecord(),
  createState: CreateStateRecord(),
  updateState: UpdateStateRecord(),
  removeState: RemoveStateRecord(),
  formData: FormDataRecord(),
  doFocusList: false,
  openedState: null,
  sharedShowcase: null,
  focusShareButton: null,
};

const _reducer = createReducer(
  initialState,
  on(CustomShowcaseActions.applyMyShowcasesLoaded, (state, {customShowcases}) => ({
    ...state,
    myCustomShowcases: Immutable.List(customShowcases.map((customShowcase) => CustomShowcaseRecordFactory(customShowcase))),
  })),
  on(CustomShowcaseActions.applySearchMyShowcasesLoaded, (state, {customShowcases}) => ({
    ...state,
    searchMyCustomShowcases: Immutable.List(customShowcases.map((customShowcase) => CustomShowcaseRecordFactory(customShowcase))),
  })),
  on(CustomShowcaseActions.addAllShowcasesLoaded, (state, {customShowcases, pagination}) => ({
    ...state,
    allCustomShowcases: {
      list: state.allCustomShowcases.list.push(...customShowcases.map((customShowcase) => CustomShowcaseRecordFactory(customShowcase))),
      pagination,
    },
  })),
  on(CustomShowcaseActions.updateAllTotalCount, (state, {totalCount}) => ({
    ...state,
    allTotalCount: totalCount,
  })),
  on(CustomShowcaseActions.clearAllShowcases, (state) => ({
    ...state,
    allCustomShowcases: {list: Immutable.List(), pagination: null},
  })),
  on(CustomShowcaseActions.clearSearchMyShowcases, (state) => ({
    ...state,
    searchMyCustomShowcases: Immutable.List(),
  })),
  on(CustomShowcaseActions.loadMyShowcases,
    CustomShowcaseActions.loadAllShowcases, (state) => ({
      ...state,
      loadState: LoadStateRecord({loading: true}),
    })),
  on(CustomShowcaseActions.loadSuccess, (state) => ({
    ...state,
    loadState: LoadStateRecord({loaded: true}),
  })),
  on(CustomShowcaseActions.loadFailure, (state, {errorCode}) => ({
    ...state,
    loadState: LoadStateRecord({errorCode}),
  })),
  on(CustomShowcaseActions.resetLoadState, (state) => ({
    ...state,
    loadState: LoadStateRecord(),
  })),
  on(CustomShowcaseActions.create, (state) => ({
    ...state,
    createState: CreateStateRecord({loading: true}),
  })),
  on(CustomShowcaseActions.createSuccess, (state, {id}) => ({
    ...state,
    createState: CreateStateRecord({id}),
  })),
  on(CustomShowcaseActions.createFailure, (state, {errorCode}) => ({
    ...state,
    createState: CreateStateRecord({errorCode}),
  })),
  on(CustomShowcaseActions.resetCreateState, (state) => ({
    ...state,
    createState: CreateStateRecord(),
  })),
  on(CustomShowcaseActions.update, (state, {id}) => ({
    ...state,
    updateState: UpdateStateRecord({id, loading: true}),
  })),
  on(CustomShowcaseActions.updateSuccess, (state, {id}) => ({
    ...state,
    updateState: UpdateStateRecord({id, updated: true}),
  })),
  on(CustomShowcaseActions.updateFailure, (state, {errorCode, id}) => ({
    ...state,
    updateState: UpdateStateRecord({id, errorCode}),
    myCustomShowcases: (errorCode === 404)
      ? state.myCustomShowcases.filter((customShowcase) => customShowcase.get('id') !== id)
      : state.myCustomShowcases,
    searchMyCustomShowcases: (errorCode === 404)
      ? state.searchMyCustomShowcases.filter((customShowcase) => customShowcase.get('id') !== id)
      : state.searchMyCustomShowcases,
    allTotalCount: (errorCode === 404)
      ? state.allTotalCount - 1
      : state.allTotalCount,
  })),
  on(CustomShowcaseActions.resetUpdateState, (state) => ({
    ...state,
    updateState: UpdateStateRecord(),
  })),
  on(CustomShowcaseActions.applyUpdate, (state, {id, updatedFields = {}, publishDateUpdate = {}, coversAndItemsCount = {}}) => (
    updateShowcaseIfFound(
      state,
      (customShowcase) => customShowcase.get('id') === id,
      (customShowcase => {
        const showcase = customShowcase.toJS();
        const updatedShowcase = {...showcase, ...updatedFields, ...publishDateUpdate, ...coversAndItemsCount} as CustomShowcase;
        return CustomShowcaseRecordFactory(updatedShowcase);
      }),
    )
  )),
  on(CustomShowcaseActions.remove, (state) => ({
    ...state,
    removeState: RemoveStateRecord({loading: true}),
  })),
  on(CustomShowcaseActions.removeSuccess, (state, {id}) => ({
    ...state,
    myCustomShowcases: state.myCustomShowcases.filter((e) => e.get('id') !== id),
    searchMyCustomShowcases: state.searchMyCustomShowcases.filter((e) => e.get('id') !== id),
    removeState: RemoveStateRecord({removed: true}),
    allTotalCount: state.allTotalCount - 1,
  })),
  on(CustomShowcaseActions.removeFailure, (state, {id, errorCode}) => ({
    ...state,
    removeState: RemoveStateRecord({errorCode}),
    myCustomShowcases: (errorCode === 404)
      ? state.myCustomShowcases.filter((customShowcase) => customShowcase.get('id') !== id)
      : state.myCustomShowcases,
    searchMyCustomShowcases: (errorCode === 404)
      ? state.searchMyCustomShowcases.filter((customShowcase) => customShowcase.get('id') !== id)
      : state.searchMyCustomShowcases,
    allTotalCount: (errorCode === 404)
      ? state.allTotalCount - 1
      : state.allTotalCount,
  })),
  on(CustomShowcaseActions.resetRemoveState, (state) => ({
    ...state,
    removeState: RemoveStateRecord(),
  })),
  on(CustomShowcaseActions.showForm, (state, {showcase}) => ({
    ...state,
    formData: FormDataRecord({showcase}),
  })),
  on(CustomShowcaseActions.hideForm, (state) => ({
    ...state,
    formData: FormDataRecord(),
  })),
  on(CustomShowcaseActions.applyFormDataUpdate, (state, {id, updatedFields = {}, publishDateUpdate = {}}) => ({
    ...state,
    formData: state.formData.get('showcase')?.id === id
      ? FormDataRecord({showcase: {...state.formData.get('showcase'), ...updatedFields, ...publishDateUpdate}})
      : state.formData,
  })),
  on(CustomShowcaseActions.focusList, (state) => ({
    ...state,
    doFocusList: true,
  })),
  on(CustomShowcaseActions.focusListComplete, (state) => ({
    ...state,
    doFocusList: false,
  })),
  on(CustomShowcaseActions.openShowcase, (state) => ({
    ...state,
    openedState: {
      showcase: null,
      showcaseLoadingState: ShowcaseLoadingStateRecord({loading: true}),
      items: Immutable.List(),
      itemsLoadingState: ShowcaseItemsLoadingStateRecord(),
      itemsPagination: ShowcaseItemsPaginationRecord(),
      listSortToRestoreOnClose: null,
      showcaseSearchData: {
        facets: null,
        searchText: null,
        sorting: null,
      },
      showcaseSearchDataLoading: ShowcaseSavedSearchLoadingStateRecord(),
    },
  })),
  on(CustomShowcaseActions.switcherStateSet, (state, {isAll}) => ({
    ...state,
    switcherState: {isAll},
  })),
  on(CustomShowcaseActions.setSearchText, (state, {searchText}) => ({
    ...state,
    searchText,
  })),
  on(CustomShowcaseActions.loadShowcaseSuccess, (state, {showcase, listSortToRestoreOnClose}) => ({
    ...state,
    openedState: {
      ...state.openedState,
      showcase: CustomShowcaseSingleRecordFactory(showcase),
      showcaseLoadingState: ShowcaseLoadingStateRecord({loaded: true}),
      listSortToRestoreOnClose,
    },
  })),
  on(CustomShowcaseActions.loadShowcaseFailure, (state, {errorCode}) => ({
    ...state,
    openedState: {
      ...state.openedState,
      showcaseLoadingState: ShowcaseLoadingStateRecord({errorCode}),
    },
  })),
  // <editor-fold desc="Curated showcases">
  on(CustomShowcaseActions.loadCuratedShowcaseItems, (state) => ({
    ...state,
    openedState: {
      ...state.openedState,
      itemsLoadingState: ShowcaseItemsLoadingStateRecord({loading: true}),
    },
  })),
  on(CustomShowcaseActions.loadCuratedShowcaseItemsSuccess, (state, {pagination}) => ({
    ...state,
    openedState: {
      ...state.openedState,
      itemsLoadingState: ShowcaseItemsLoadingStateRecord(),
      itemsPagination: ShowcaseItemsPaginationRecord(pagination),
    },
  })),
  on(CustomShowcaseActions.loadCuratedShowcaseItemsFailure, (state, {error}) => ({
    ...state,
    openedState: {
      ...state.openedState,
      itemsLoadingState: ShowcaseItemsLoadingStateRecord({error}),
    },
  })),
  on(CustomShowcaseActions.loadMoreCuratedShowcaseItems, (state) => ({
    ...state,
    openedState: {
      ...state.openedState,
      itemsLoadingState: ShowcaseItemsLoadingStateRecord({loading: true}),
    },
  })),
  on(CustomShowcaseActions.toggleOpenedCuratedShowcaseItemSelection, (state, {itemId}) => {
    if (state.openedState) {
      const foundIndex = state.openedState.items.findIndex((item) => item.id === itemId);
      if (foundIndex >= 0) {
        return {
          ...state,
          openedState: {
            ...state.openedState,
            items: state.openedState.items.updateIn([foundIndex], (item: ListItem) => ({
              ...item,
              selected: !item.selected,
            })),
          },
        };
      }
    }

    return state;
  }),
  on(CustomShowcaseActions.setAllOpenedCuratedShowcaseItemsSelection, (state, {selected}) => {
    if (state.openedState) {
      return {
        ...state,
        openedState: {
          ...state.openedState,
          items: state.openedState.items.map((item) => ({...item, selected})),
        },
      };
    }

    return state;
  }),
  on(CustomShowcaseActions.clearCuratedShowcaseItems, (state) => ({
    ...state,
    openedState: {
      ...state.openedState,
      items: state.openedState.items.clear(),
    },
  })),
  on(CustomShowcaseActions.addLoadedCuratedShowcaseItems, (state, {entities}) => ({
    ...state,
    openedState: {
      ...state.openedState,
      items: state.openedState.items.push(...entities.map(makeShowcaseItem)),
    },
  })),
  // </editor-fold>
  on(CustomShowcaseActions.clearOpenedState, (state) => ({
    ...state,
    openedState: null,
  })),
  on(CustomShowcaseActions.loadSavedSearch, (state) => ({
    ...state,
    openedState: {
      ...state.openedState,
      showcaseSearchDataLoading: ShowcaseSavedSearchLoadingStateRecord({loading: true}),
    },
  })),
  on(CustomShowcaseActions.loadSavedSearchSuccess, (state, {facets, searchText, sorting}) => ({
    ...state,
    openedState: {
      ...state.openedState,
      showcaseSearchData: {
        facets,
        searchText,
        sorting,
      },
      showcaseSearchDataLoading: ShowcaseSavedSearchLoadingStateRecord({loading: false}),
    },
  })),
  on(CustomShowcaseActions.loadSavedSearchFailure, (state, {error}) => ({
    ...state,
    openedState: {
      ...state.openedState,
      showcaseSearchDataLoading: ShowcaseSavedSearchLoadingStateRecord({error}),
    },
  })),
  on(CustomShowcaseActions.openEmailModal, (state, {sharedShowcase}) => ({
    ...state,
    sharedShowcase,
  })),
  on(focusShareButton, (state, { savedSearchId }) => ({
    ...state,
    focusShareButton: savedSearchId,
  })),
  on(stopFocusShareButton, (state) => ({
    ...state,
    focusShareButton: null,
  })),
);

export function reducer(state: CustomShowcaseState | undefined, action: Action) {
  return _reducer(state, action);
}

export const featureKey = 'customShowcase';

export const getCustomShowcaseState = createFeatureSelector<CustomShowcaseState>(featureKey);

export const getCustomShowcases = createSelector(getCustomShowcaseState, (state: CustomShowcaseState): CustomShowcase[] => {
  return state.myCustomShowcases.toJS() as CustomShowcase[];
});

export const getMyCustomShowcasesConsideringSearch = createSelector(getCustomShowcaseState, (state: CustomShowcaseState): CustomShowcase[] => {
  return state.searchText ? state.searchMyCustomShowcases.toJS() as StoredCustomShowcase[] : state.myCustomShowcases.toJS() as StoredCustomShowcase[];
});

export const getAllCustomShowcases = createSelector(getCustomShowcaseState, (state: CustomShowcaseState): AllCustomShowcases => {
  return {
    list: state.allCustomShowcases.list.toJS() as StoredCustomShowcase[],
    pagination: state.allCustomShowcases.pagination,
  };
});

export const getMyShowcase = createSelector(
  getCustomShowcases,
  (myShowcases: CustomShowcase[], props: { id: string }): CustomShowcase => (
    myShowcases.find(({id}) => id === props.id) || null
  ),
);

export const getVisibleShowcase = createSelector(
  getCustomShowcaseState,
  getCustomShowcases,
  getAllCustomShowcases,
  (state: CustomShowcaseState, myShowcases: CustomShowcase[], allShowcases: AllCustomShowcases, props: { id: string }): CustomShowcase => {
  const visibleShowcases = (state.switcherState.isAll) ? allShowcases.list : myShowcases;
  return visibleShowcases.find(({id}) => id === props.id) || null;
});

export const getCreateState = createSelector(getCustomShowcaseState, (state: CustomShowcaseState): CreateState => {
  return state.createState.toJS();
});

export const getUpdateState = createSelector(getCustomShowcaseState, (state: CustomShowcaseState): UpdateState => {
  return state.updateState.toJS();
});

export const getLoadState = createSelector(getCustomShowcaseState, (state: CustomShowcaseState): LoadState => {
  return state.loadState.toJS();
});

export const getRemoveState = createSelector(getCustomShowcaseState, (state): RemoveState | null => {
  return (state && state.removeState) ? state.removeState.toJS() : null;
});

export const getFormData = createSelector(getCustomShowcaseState, (state: CustomShowcaseState): CustomShowcaseFormData => {
  return state.formData.toJS() as CustomShowcaseFormData;
});

export const getCustomShowcaseTotalCounts = createSelector(getCustomShowcaseState, (state: CustomShowcaseState): CustomShowcaseTotalCounts => {
  return {
    my: state.myCustomShowcases.size,
    all: state.allTotalCount,
  };
});

export const getIsEditFormOpened = createSelector(getCustomShowcaseState, (state: CustomShowcaseState): boolean => {
  return !!state.formData.get('showcase')?.id;
});

export const getDoFocusList = createSelector(getCustomShowcaseState, (state: CustomShowcaseState): boolean => {
  return state.doFocusList;
});

export const getIsShowcaseOpened = createSelector(getCustomShowcaseState, (state: CustomShowcaseState): boolean => {
  return !!state.openedState;
});

export const getOpenedShowcase = createSelector(getCustomShowcaseState, (state: CustomShowcaseState): CustomShowcaseSingle => {
  return state.openedState?.showcase?.toJS() as CustomShowcaseSingle;
});

export const getOpenedShowcaseItems = createSelector(getCustomShowcaseState, (state: CustomShowcaseState): ListItem[] => {
  return state.openedState?.items.toJS() as ListItem[];
});

export const getOpenedShowcaseItemsPagination = createSelector(getCustomShowcaseState, (state: CustomShowcaseState): ListPagination => {
  return state.openedState?.itemsPagination.toJS();
});

export const getOpenedShowcaseLoadingState = createSelector(getCustomShowcaseState, (state: CustomShowcaseState): ShowcaseLoadingState => {
  return state.openedState?.showcaseLoadingState.toJS();
});

export const getOpenedShowcaseItemsLoadingState = createSelector(getCustomShowcaseState, (state: CustomShowcaseState): ListItemsLoadingState => {
  return state.openedState?.itemsLoadingState.toJS() as ListItemsLoadingState;
});

export const getOpenedShowcaseListSortToRestoreOnClose = createSelector(getCustomShowcaseState, (state: CustomShowcaseState): ListSort => {
  return state.openedState?.listSortToRestoreOnClose;
});

export const getSearchText = createSelector(getCustomShowcaseState, (state: CustomShowcaseState): string => {
  return state.searchText;
});

export const getShowcaseSearchData = createSelector(getCustomShowcaseState, (state: CustomShowcaseState): ShowcaseSearchData => {
  return state.openedState?.showcaseSearchData;
});

export const getSearchDataLoadingState = createSelector(getCustomShowcaseState, (state: CustomShowcaseState): ShowcaseSavedSearchLoadingState => {
  return state.openedState?.showcaseSearchDataLoading.toJS();
});

export const sharedShowcase = createSelector(getCustomShowcaseState, (state: CustomShowcaseState): CustomShowcase => {
  return state.sharedShowcase;
});

export const getFocusShareButton = createSelector(getSavedSearchState, (state): string | null => {
  return (state) ? state.focusShareButton : null;
});

export const makeShowcaseItem = (entity: ListItemEntity) => ({id: Math.random().toString().slice(2), selected: false, entity});

const updateShowcaseIfFound = (
  state: CustomShowcaseState,
  customShowcasePredicate: (customShowcase: CustomShowcaseRecord) => boolean,
  callback: (customShowcase: CustomShowcaseRecord) => CustomShowcaseRecord,
): CustomShowcaseState => {
  let newState = state;
  let someMyShowcaseMutated = false;

  const myShowcasesMutated = state.myCustomShowcases.withMutations((customShowcases) => {
    const showcaseIndex = customShowcases.findIndex(customShowcasePredicate);

    if (showcaseIndex >= 0) {
      someMyShowcaseMutated = true;
      customShowcases.updateIn([showcaseIndex], callback);
    }
  });

  if (someMyShowcaseMutated) {
    newState = {
      ...newState,
      myCustomShowcases: myShowcasesMutated,
    };
  }

  let someSearchMyShowcaseMutated = false;

  const searchMyCustomShowcasesMutated = state.searchMyCustomShowcases.withMutations((customShowcases) => {
    const showcaseIndex = customShowcases.findIndex(customShowcasePredicate);

    if (showcaseIndex >= 0) {
      someSearchMyShowcaseMutated = true;
      customShowcases.updateIn([showcaseIndex], callback);
    }
  });

  if (someSearchMyShowcaseMutated) {
    newState = {
      ...newState,
      searchMyCustomShowcases: searchMyCustomShowcasesMutated,
    };
  }

  let someAllShowcaseMutated = false;

  const allShowcasesMutated = state.allCustomShowcases.list.withMutations((customShowcases) => {
    const showcaseIndex = customShowcases.findIndex(customShowcasePredicate);

    if (showcaseIndex >= 0) {
      someAllShowcaseMutated = true;
      customShowcases.updateIn([showcaseIndex], callback);
    }
  });

  if (someAllShowcaseMutated) {
    newState = {
      ...newState,
      allCustomShowcases: {
        list: allShowcasesMutated,
        pagination: newState.allCustomShowcases.pagination,
      },
    };
  }

  return newState;
};
