import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType, } from '@ngrx/effects';
import { Store, } from '@ngrx/store';
import { EMPTY, from, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { redirectNlbTabHoldFailure, redirectSuccess, tabHoldNlb } from 'user/actions/user-profile.actions';
import { ItemVolumeListComponent } from 'user/components/item-volume-list/item-volume-list.component';
import { VolumeListComponent } from 'user/components/volume-list/volume-list.component';
import { ItemVolume, Volume } from 'user/models/get-it';
import { UserState, } from 'user/reducers/user.reducer';
import { nlbEditionHold, nlbEditionHoldFinalize, nlbEditionItemAsVolumeHold, nlbEditionVolumeHold } from '../actions/nlb-hold.actions';
import { NlbHoldService } from '../services/nlb-hold.service';

@Injectable()
export class NlbHoldEffects {

  public tabHoldNlb$ = createEffect(() => this.actions$.pipe(
    ofType(tabHoldNlb),
    switchMap(({payload}) => {
      return this.nlbHoldService.nlbTabHold(payload).pipe(
        map((recordNumber) => {
          return nlbEditionHoldFinalize({recordNumber});
        }),
        catchError((error) => {
          if (error.status === 409) {
            if (error.error.data?.itemsAsVolumes?.length) {
              this.store.dispatch(nlbEditionItemAsVolumeHold({
                itemsAsVolumes: error.error.data?.itemsAsVolumes,
                recordNumber: error.error.data?.editionId
              }));
            } else if (error.error.data.volumes?.length) {
              this.store.dispatch(nlbEditionVolumeHold({volumes: error.error.data?.volumes}));
            }
          }
          return of(redirectNlbTabHoldFailure());
        })
      );
    })
  ));

  public nlbEditionHold$ = createEffect(() =>
      this.actions$.pipe(
        ofType(nlbEditionHold),
        tap(({nlbPayload}) => {
          if (nlbPayload.volumes?.length) {
            this.store.dispatch(nlbEditionVolumeHold({volumes: nlbPayload.volumes}));
          } else if (nlbPayload.itemsAsVolumes?.length) {
            this.store.dispatch(nlbEditionItemAsVolumeHold({itemsAsVolumes: nlbPayload.itemsAsVolumes, recordNumber: nlbPayload.editionId}));
          } else {
            this.store.dispatch(nlbEditionHoldFinalize({recordNumber: nlbPayload.editionId}));
          }
        }),
      ),
    {dispatch: false}
  );

  public nlbEditionVolumeHold$ = createEffect(() =>
    this.actions$.pipe(
      ofType(nlbEditionVolumeHold),
      mergeMap((action) => {
        const {volumes} = action;
        const modalRef = this.modalService.open(VolumeListComponent, {centered: true, windowClass: 'inspire-custom-modal'});
        modalRef.componentInstance.volumes = volumes;

        return from(modalRef.result).pipe(
          switchMap((volume: Volume) => {
            const redirectLink = this.nlbHoldService.getRedirectUrlToVolume(volume);
            window.open(redirectLink, '_blank');
            return of(redirectSuccess());
          }),
          catchError(() => {
            return EMPTY;
          })
        );
      })
    )
  );

  public nlbEditionHoldFinalize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(nlbEditionHoldFinalize),
      switchMap(({recordNumber}) => {
        const redirectLink = this.nlbHoldService.getEditionUrl(recordNumber);
        window.open(redirectLink, '_blank');
        return of(redirectSuccess());
      }),
      catchError(() => {
        return EMPTY;
      })
    )
  );

  public nlbEditionItemAsVolumeHold$ = createEffect(() => this.actions$.pipe(
    ofType(nlbEditionItemAsVolumeHold),
    mergeMap((action) => {
      const {itemsAsVolumes, recordNumber} = action;
      const modalRef = this.modalService.open(ItemVolumeListComponent, {centered: true, windowClass: 'inspire-custom-modal'});
      modalRef.componentInstance.volumes = itemsAsVolumes;

      return from(modalRef.result).pipe(
        switchMap((itemAsVolumes: ItemVolume) => {
          const redirectLink = this.nlbHoldService.getRedirectUrlToItemAsVolume(itemAsVolumes, recordNumber);
          window.open(redirectLink, '_blank');
          return of(redirectSuccess());
        }),
        catchError(() => {
          return EMPTY;
        })
      );
    }),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly modalService: NgbModal,
    private readonly store: Store<UserState>,
    private readonly nlbHoldService: NlbHoldService
  ) {
  }
}
