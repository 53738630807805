<app-bookshelf-template [class.invisible]="isInvisible">
  <ng-container *ngIf="openedShowcase">
    <ng-template bookshelfTemplateTitle>
      <button
        data-automation-id="dynamic-showcases-back-button"
        class="showcases__header__button showcases__header__button--title w-100"
        (click)="onCloseShowcase()">
        <fa-icon class="arrow-icon" [icon]="arrowIconLeft"></fa-icon>
        <div class="d-flex flex-nowrap px-1 bookmarks-title">
          <div class="text-truncate d-block" data-automation-id="search-title">{{openedShowcase.name}}</div>
        </div>
      </button>
    </ng-template>

    <ng-container *ngIf="!isAuthPatronFlagEnabled">
      <ng-template bookshelfTemplateAction *ngIf="(isShowcaseCreator)
                                    ? (hasUpdatePersonalShowcasePermission$ | async)
                                    : (hasUpdateNonPersonalShowcasePermission$ | async)">
        <button
          (click)="openShowcaseSettings()"
          class="showcases__header__button"
          data-automation-id="dynamic-showcases-settings-button">
          <span class="fas fa-cog icon mr-1" aria-hidden="true"></span>
          <span translate class="text">customShowcaseSettingsButton</span>
        </button>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="isAuthPatronFlagEnabled">
      <ng-container *hasShowcasePermission="{
          permission: showcasePermissions.EDIT,
          showcaseId: showcaseId
        }"
      >
        <ng-template bookshelfTemplateAction>
          <button
            (click)="openShowcaseSettings()"
            class="showcases__header__button"
            data-automation-id="dynamic-showcases-settings-button">
            <span class="fas fa-cog icon mr-1" aria-hidden="true"></span>
            <span translate class="text">customShowcaseSettingsButton</span>
          </button>
        </ng-template>
      </ng-container>
    </ng-container>

    <ng-template bookshelfTemplateBody>
      <div class="mx-2 mx-md-8 mt-3">
        <app-custom-showcase-settings-header
          [showcase]="openedShowcase"
          [isShowcaseCreator]="isShowcaseCreator"
          (onShowcaseTypeClick)="runSavedSearch()">
        </app-custom-showcase-settings-header>
        <app-dynamic-showcase-search-data
          *ngIf="!searchDataLoadingState.loading && !searchDataLoadingState.error; else loadingState"
          [openedShowcase]="openedShowcaseWithDetails">
        </app-dynamic-showcase-search-data>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #loadingState>
    <div class="d-flex justify-content-center text-white" role="status" aria-live="polite">
      <div
        *ngIf="searchDataLoadingState.loading"
        class="mt-5 spinner-border custom-showcases-loading-spinner"
        data-automation-id="saved-search-loader">
        <span class="sr-only" translate>Loading...</span>
      </div>
    </div>
    <div aria-atomic="true" aria-live="assertive" role="alert">
      <div *ngIf="searchDataLoadingState.error" class="m-4 text-white" data-automation-id="saved-search-error">
        {{'generalServerError' | translate}}
      </div>
    </div>
  </ng-template>
</app-bookshelf-template>
