import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bulk-renew-progress',
  templateUrl: './bulk-renew-progress.component.html',
  styleUrls: ['./bulk-renew-progress.component.scss']
})
export class BulkRenewProgressComponent {
  @Input() processedItemsNumber: number;
  @Input() totalItemsNumber: number;
}
