import { Component, Input } from '@angular/core';
import { UserAddress } from 'user/models/user';

@Component({
  selector: 'app-borrow-by-mail-user-address',
  templateUrl: './borrow-by-mail-user-address.component.html',
  styleUrls: ['./borrow-by-mail-user-address.component.scss']
})
export class BorrowByMailUserAddressComponent {
  @Input() userAddress: UserAddress;
}
