import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ShareItShareBody } from '../models/share-it';

@Injectable({
  providedIn: 'root',
})
export class ShareItService {
  private static readonly searchResultShareItUrl = 'api/search-result/share-it/email';

  constructor(private http: HttpClient) { }

  public shareItShare(body: ShareItShareBody): Observable<undefined> {
    return this.http.post<undefined>(ShareItService.searchResultShareItUrl, body, {headers: {'api-version': '2'}});
  }
}
