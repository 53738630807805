import { Component, Inject, OnInit, } from '@angular/core';
import { WindowRefService } from 'app/services/window-ref.service';
import { Language } from 'core/models/language';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { LOCALE_ID_WRAPPER } from '../../../app.module';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent implements OnInit {
  public availableLanguages = this.configloader.availableLanguages;
  public shortLocale: string;
  public selectedLanguage: string;

  constructor(
    @Inject(LOCALE_ID_WRAPPER) public locale: string,
    public configloader: ConfigurationLoader,
    private windowRefService: WindowRefService,
  ) {}

  public ngOnInit() {
    this.shortLocale = this.locale.substring(0, 2).toUpperCase();
    const selectedLanguage = this.availableLanguages.find((lang) => lang.code === this.locale);
    this.selectedLanguage = selectedLanguage ? selectedLanguage.name : 'Unknown language';
  }

  public selectLanguage(language: Language) {
    localStorage.setItem('language', language.selected);
    this.windowRefService.nativeWindow().location.reload();
  }
}
