<div class="bookshelf-template h-100">
  <div class="bookshelf-template__header row no-gutters flex-wrap flex-sm-nowrap align-items-center">
    <div
      class="col-12 col-sm-auto d-flex justify-content-between align-items-center flex-shrink-1 flex-grow-1 flex-shrink-below-content-size">
      <div *ngIf="bookshelfTemplateTitle"
           class="bookshelf-template__header__title p-1 col-auto flex-shrink-1 flex-shrink-below-content-size"
           data-automation-id="bookshelf-template-title">
        <ng-template *ngTemplateOutlet="bookshelfTemplateTitle.template"></ng-template>
      </div>
      <div *ngIf="bookshelfTemplateSortAction"
           class="bookshelf-template__header__sort-action col p-1 flex-grow-0"
           data-automation-id="bookshelf-template-sort-action-container">
        <ng-template *ngTemplateOutlet="bookshelfTemplateSortAction.template"></ng-template>
      </div>
    </div>
    <div *ngIf="bookshelfTemplateActions && bookshelfTemplateActions.length"
         class="bookshelf-template__header__actions col-12 col-sm d-flex flex-grow-0">
      <div *ngFor="let bookshelfTemplateAction of bookshelfTemplateActions"
           data-automation-id="bookshelf-template-action-container"
           class="p-1">
        <ng-template *ngTemplateOutlet="bookshelfTemplateAction.template"></ng-template>
      </div>
    </div>
  </div>
  <div *ngIf="bookshelfTemplateSubheaderActions"
       class="bookshelf-template__subheader-actions row no-gutters flex-wrap flex-sm-nowrap align-items-center">
    <div class="p-1 w-100" data-automation-id="bookshelf-template-subheader-actions-container">
      <ng-template *ngTemplateOutlet="bookshelfTemplateSubheaderActions.template"></ng-template>
    </div>
  </div>

  <div class="h-100">
    <ng-container *ngIf="bookshelfTemplateBody">
      <ng-container
        *ngIf="bookshelfTemplateBody.mode === 'ps' || bookshelfTemplateBody.mode === 'ps-short' || bookshelfTemplateBody.mode === 'ps-medium' || !bookshelfTemplateBody.mode; else bodyTemplate">
        <perfect-scrollbar
          removeTabindexOnYScroll
          class="d-flex flex-column h-100 ps-show-always bookshelf-template__body-container--ps"
          [class.bookshelf-template__body-container--ps-short]="bookshelfTemplateBody.mode === 'ps-short'"
          [class.bookshelf-template__body-container--ps-medium]="bookshelfTemplateBody.mode === 'ps-medium'"
          data-automation-id="bookshelf-template-perfect-scrollbar"
          lifecycleHooks
          (afterViewInit)="psAfterViewInit($event)"
          (onDestroy)="psDestroy()">
          <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
        </perfect-scrollbar>
      </ng-container>

      <ng-template #bodyTemplate>
        <div
          class="d-flex flex-column h-100 bookshelf-template__body-container--no-ps"
          data-automation-id="bookshelf-template-body-container">
          <div *ngIf="bookshelfTemplateSubheader"
               data-automation-id="bookshelf-template-subheader-container"
               class="bookshelf-template__subheader px-6 py-4">
            <ng-template *ngTemplateOutlet="bookshelfTemplateSubheader.template"></ng-template>
          </div>
          <ng-template *ngTemplateOutlet="bookshelfTemplateBody.template; context: {psScroll: psScroll}"></ng-template>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>
