import {
  Directive,
  Input,
  HostListener,
} from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Directive({
  selector: '[announce]',
})
export class AnnounceDirective {
  @Input() public announce: string;

  constructor(
    private liveAnnouncer: LiveAnnouncer,
  ) {}

  @HostListener('click')
  public announceMessage() {
    this.liveAnnouncer.announce(this.announce);
  }
}
