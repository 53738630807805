<div class="passcode-visibility-toggler-container">
  <div #inputContainerRef class="input-container" data-automation-id="passcode-visibility-toggler-input-container">
    <ng-content select="input[type=password]"></ng-content>
  </div>

  <button
    type="button"
    class="toggle-button"
    data-automation-id="passcode-visibility-toggler-button"
    [attr.aria-label]="'togglePasscodeVisibility' | translate"
    (click)="toggleVisibility()"
  >
    <fa-icon class="visibility-icon" aria-hidden="true" [icon]="isVisible ? showIcon : hideIcon"></fa-icon>
  </button>
</div>
