import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export function notBlankValidator(control: UntypedFormControl): ValidationErrors | null {
  return !!(control.value || '').trim() ? null : {notBlank: true};
}

export function phoneNumberValidator(control: UntypedFormControl): ValidationErrors | null {
  const patternError = Validators.pattern(/^[0-9()+-_.\s]*$/)(control);
  return (patternError) ? {phoneNumber: true} : null;
}

export function matchValidator(controlName: string, matchingControlName: string): ValidatorFn {
  return (formGroup: UntypedFormGroup): ValidationErrors | null => {
    const control = formGroup.get(controlName);
    const matchingControl = formGroup.get(matchingControlName);

    if (!control || !matchingControl) return null;

    const matchingControlErrors = matchingControl.errors ? { ...matchingControl.errors } : {};

    if (!control.value || !matchingControl.value || control.value === matchingControl.value) {
      delete matchingControlErrors.match;
      matchingControl.setErrors(Object.keys(matchingControlErrors).length ? matchingControlErrors : null);
      return null;
    }

    matchingControl.setErrors({ ...matchingControlErrors, match: true }, { emitEvent: true });
    return { match: true };
  };
}
