export type NlbItemLocationRequest = {
  itemId: string;
  locationCode: string;
  usageLevelCode: string
  language: string;
  callNumber: string;
  title: string;
}

export type NlbItemLocation = {
  branchName: string;
  level: string;
  shelf: string;
  callNumber: string;
  title: string;
  tier1: string;
  tier2: string;
  bay: string;
  tier: string;
  mapUrl: string;
};

export enum NlbItemLocatorOperationMode {
  loadOnClick,
  loadOnMount
}

export const NlbCollectionToUsageLevelMap: { [key: string]: string } = {
  'Accompanying Item': 'ATT',
  'Adult Accessible Collection': 'ADULT ACC',
  Adult: 'ADULT',
  'Adult Audio Visual': 'ADULT AV',
  'Adult Singapore': 'ADULT SING',
  'Early Literacy 0-3': 'ELL 0-3',
  'Early Literacy 4-6': 'ELL 4-6',
  'Junior Accessible Collection': 'JUNIOR ACC',
  Junior: 'JUNIOR',
  'Junior Audiovisual': 'JUNIOR AV',
  'Junior Picture Book': 'JUNIOR PB',
  'Junior Simple Fiction': 'JUNIOR SF',
  'Junior Reference': 'JUNIOR R',
  'Junior Reference Audiovisual': 'JUNIOR RAV',
  'Junior Reference Singapore': 'JUNIOR RSING',
  'Junior Singapore': 'JUNIOR SING',
  'Quick Reference': 'RQUICK',
  'Reference Asian Children Literature': 'RACL',
  Reference: 'R',
  'Reference Art': 'RART',
  'Reference Asian Children': 'RAC',
  'Reference Audiovisual': 'RAV',
  'Reference Business': 'RBUS',
  'Reference China Resource': 'RCR',
  'Reference Chinese Overseas': 'RCO',
  'Reference Closed Access': 'RCLOS',
  'Reference Donor Edwin Thumboo': 'RDET',
  'Reference Donor Istana': 'RDIST',
  'Reference Donor Koh Seow Chuan': 'RDKSC',
  'Reference Donor Kuo Pao Kun': 'RDKPK',
  'Reference Donor Lee Kip Lin': 'RDLKL',
  'Reference Donor Masuri S.N.': 'RDMSN',
  'Reference Donor Tan Swie Hian': 'RDTSH',
  'Reference Donor Tan Yeok Seong': 'RDTYS',
  'Reference Rare': 'RRARE',
  'Reference Singapore': 'RSING',
  'Reference Southeast Asia': 'RSEA',
  'SBG Archived &amp; Rare Collection': 'SBG ARC RARE',
  'SBG Reference Collection': 'SBG R',
  'SBG Staff Reference Collection': 'SBG STAFF',
  'Teen Accessible Collection': 'TEEN ACC',
  Teen: 'TEEN',
  'Teen Audiovisual': 'TEEN AV',
  'Teen Singapore': 'TEEN SING',
  'Teen Student': 'TEEN STU',
  'Teen Reference': 'TEEN R',
  'Teen Reference Audiovisual': 'TEEN RAV',
  'Teen Reference Singapore': 'TEEN RSING',
  'Teen Reference Student': 'TEEN RSTU',
  'No Longer Needed': 'NoLongerNeeded',
  'Restricted/ARR': 'ARR',
  'SBG Staff Reference Collection (Journal)': 'SBG STAFF JNL',
  'Reference Donor Arts Repository': 'RDAR',
};

export type NlbItemLanguageCodeMap = {
  id: string,
  name: string
}