import { Portal } from '@angular/cdk/portal';
import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { FacetState, } from 'search/facets/models/facet-state';
import { DateFacet, FacetResult, ResourceFacet, } from '../facets/models/resource-facet';

export interface FilterBlock {
  component: Portal<any>;
}

export interface FacetFilterBlock extends FilterBlock {
  dataEmitter: EventEmitter<FacetState>;
  key: string;
}

export interface TermsFilterBlock extends FilterBlock {
  block: string;
  toggleOpenState?: (opened: boolean) => void;
  count: number;
  expanded?: boolean;
}

export interface FacetComponentData {
  existingFacets: FacetResult<ResourceFacet>;
  selectedFacets: ResourceFacet[];
  facetBlockExpanded: boolean;
  v2facetModel: FacetState;
  isLoading: boolean;
}

export interface ComponentData {
  facetKey: string;
  facetData: Observable<FacetState>;
}

export interface DateFacetComponentData {
  centuries: any[];
  selectedDateFacet: DateFacet;
  isLoading: boolean;
}

export enum SearchCardBlock {
  EDIT_TERMS = 'EDIT_TERMS',
  EXCLUDE_NODES = 'EXCLUDE_NODES',
}

export enum ResourceFacetBlock {
  AVAILABILITIES = 'AVAILABILITIES',
  DATE = 'DATE',
  CATALOG_DATE = 'CATALOG_DATE',
  FORMATS = 'FORMATS',
  AGES = 'AGES',
  PERSONS = 'PERSONS',
  CONCEPTS = 'CONCEPTS',
  LANGUAGE = 'LANGUAGE',
  FULLTEXT = 'FULLTEXT',
  LOCATION = 'LOCATIONS',
}

export interface SearchCardBlockState {
  expanded: boolean;
  block: SearchCardBlock;
}

export interface FacetBlockState {
  expanded: boolean;
  facetKey: string;
}
