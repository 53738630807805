import { FacetType } from 'search/facets/models/facet-type';
import { CatalogDateFacetsActionsProcessor } from 'search/facets/processors/actions/catalog-date-facets-actions-processor';
import { IterableFacetsActionsProcessor } from 'search/facets/processors/actions/iterable-facets-actions-processor';
import { DateFacetsActionsProcessor } from 'search/facets/processors/actions/date-facets-actions-processor';
import { SingleFacetActionsProcessor } from 'search/facets/processors/actions/single-facet-actions-processor';
import { FacetsActionsProcessor } from 'search/facets/processors/actions/facets-actions-processor';

export function getProcessor(type: FacetType): FacetsActionsProcessor {
  switch (type) {
    case FacetType.Iterable: {
      return new IterableFacetsActionsProcessor();
    }
    case FacetType.Date: {
      return new DateFacetsActionsProcessor();
    }
    case FacetType.CatalogDate: {
      return new CatalogDateFacetsActionsProcessor();
    }
    case FacetType.Single: {
      return new SingleFacetActionsProcessor();
    }
    default: {
      return null;
    }
  }
}
