<div class="p-4">
  <ng-container *ngIf="!isDone">
    <div class="modal-body" data-automation-id="save-multiple-message">
      <p class="bold-text mb-2">
        {{'bookmarksYouHaveNumberBookmarks' | translate: {itemsCount: listItemEntities.length} }}
      </p>
      <p class="mb-0" translate>bookmarksWhatToDoWithBookmarks</p>
    </div>
    <div class="modal-footer justify-content-start">
      <app-select-list-button [buttonClass]="'ins-buttons button-primary flint px-6'"
                              [listItemEntities]="listItemEntities"
                              [lists]="lists"
                              [listIdsSelected]="[]"
                              [canCreateNew]="true"
                              (onDone)="onDone()"
                              data-automation-id="modal-save-bookmarks">
        {{ 'listSaveMultipleSaveTo' | translate }}
      </app-select-list-button>

      <button (click)="onDiscardAll()"
              class="ins-buttons button-secondary flint ml-3 text-uppercase"
              data-automation-id="save-multiple-discard-all"
              translate>
        listSaveMultipleDiscardAll
      </button>
    </div>
  </ng-container>

  <div aria-live="polite" role="status">
    <div class="modal-body" *ngIf="isDone" data-automation-id="save-multiple-have-been-saved">
      <p class="mb-0" translate>listYourTemporaryBookmarksHaveBeenSaved</p>
    </div>
  </div>

  <div *ngIf="isDone" class="modal-footer justify-content-start">
    <button data-automation-id="save-multiple-ok-button"
            (click)="close()"
            translate
            class="ins-buttons button-primary flint">ok
    </button>
  </div>
</div>
