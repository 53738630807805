import { Component, Input, OnInit, } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ShareItResource, ShareItShareBody } from 'app/share-it/models/share-it';
import { ShareItService } from 'app/share-it/services/share-it.service';
import { EntityTypes } from '../../../entity/models/entity';
import { WindowRefService } from '../../../services/window-ref.service';

@Component({
  selector: 'app-email-popup',
  templateUrl: './email-popup.component.html',
  styleUrls: ['./email-popup.component.scss'],
})
export class EmailPopupComponent implements OnInit {
  @Input() public resourceData: ShareItResource;
  public EMAIL_PATTERN = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$';
  public form: UntypedFormGroup;
  public toControl = new UntypedFormControl('', Validators.compose([
    Validators.required,
    Validators.email,
    Validators.pattern(this.EMAIL_PATTERN),
  ]));
  public error: any;

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly shareItService: ShareItService,
    private readonly windowRefService: WindowRefService,
    private readonly fb: UntypedFormBuilder,
  ) {
    this.form = this.fb.group({
      to: this.toControl,
      subject: new UntypedFormControl({ value: '', disabled: true }),
    });
  }

  public ngOnInit() {
    const { title, author, resourceType } = this.resourceData.attributes;
    const subject = resourceType === EntityTypes.FORMAT_GROUP && author ?
      title + ' by ' + author : title;
    this.form.patchValue({ subject });
  }

  public close() {
    this.activeModal.dismiss();
  }

  public send() {
    if (this.form.valid) {
      this.shareItService.shareItShare(this.setEmailBody()).subscribe(() => {
        this.close();
      }, ({ error }) => {
        this.error = error.message;
      });
    }
  }

  public setEmailBody(): ShareItShareBody {
    this.resourceData.attributes.url = this.windowRefService.origin() + this.resourceData.attributes.url;

    return {
      to: this.form.value.to,
      subject: this.form.controls.subject.value,
      resource: this.resourceData,
    };
  }
}
