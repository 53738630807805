import { Component, Input, OnInit } from '@angular/core';
import { NlbLibraryList } from '../../nlb-library-list';
import { NlbLibrary } from '../../models/nlb-site-link';

@Component({
  selector: 'app-nlb-site-link',
  templateUrl: './nlb-site-link.component.html',
  styleUrls: ['./nlb-site-link.component.scss']
})
export class NlbSiteLinkComponent implements OnInit {
  @Input() label: string;
  public url: string;

  ngOnInit(): void {
    const library: NlbLibrary | undefined = NlbLibraryList.find(lib => lib.name === this.label);
    this.url = library?.url_address || null;
  }
}
