import { Params } from '@angular/router';
import { Injectable } from '@angular/core';

export type SortOrderType = 'asc' | 'desc';
export type SortingType = 'publicationDate' | 'catalogDate' | 'relevance';

export interface ISortingValue {
  key?: string;
  sorting: SortingType;
  sortOrder: SortOrderType;
}

@Injectable()
export class SearchParamsService {
  public extractSorting(query: Params = {}): SortingType {
    switch (query.sorting) {
      case 'publicationDate':
        return 'publicationDate';
      case 'catalogDate':
        return 'catalogDate';
      default:
        return 'relevance';
    }
  }
  public extractSortOrder(query: Params = {}): SortOrderType {
    switch (query.sortOrder) {
      case 'desc':
        return 'desc';
      default:
        return 'asc';
    }
  }
  public extractValue(query: Params = {}): ISortingValue {
    const value: ISortingValue = {
      sorting: this.extractSorting(query),
      sortOrder: this.extractSortOrder(query),
    };
    value.key = `${value.sorting}:${value.sortOrder}`;
    return value;
  }
}
