export const getJSON = <T>(url: string, headers: { [key: string]: any } = {}, domain?: string) => new Promise<T>((resolve, reject) => {
  const configXhr = new XMLHttpRequest();
  configXhr.open('GET', url, true);

  const headersNames = Object.keys(headers);

  headersNames.forEach((name) => {
    configXhr.setRequestHeader(name, headers[name]);
  });
  if (domain) {
    configXhr.setRequestHeader('iii-customer-domain', domain);
  }

  configXhr.onreadystatechange = () => {
    if (configXhr.readyState !== 4) {
      return;
    }
    if (configXhr.status === 200) {
      let response;
      try {
        response = JSON.parse(configXhr.responseText);
      } catch {
        response = configXhr.responseText;
      }
      resolve(response);
    } else {
      reject();
    }
  };

  configXhr.send();
});
