import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export const modalWindowClass = 'inspire-custom-modal';
export const secondBackdropClass = 'second-modal-backdrop';

export const secondModalOptions: NgbModalOptions = {
  centered: true,
  windowClass: modalWindowClass,
  backdropClass: secondBackdropClass,
};
