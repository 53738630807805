import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SortField } from 'user/models/user-receipt-card';

@Component({
  selector: 'app-sort-button',
  templateUrl: './sort-button.component.html',
  styleUrls: ['./sort-button.component.scss'],
})
export class SortButtonComponent implements OnInit {
  @Input() public sortFields: SortField[];
  @Input() public currentSortField: string;
  @Output() public onSort = new EventEmitter<string>();
  public sortFieldsMap: { [key: string]: SortField; };

  public ngOnInit(): void {
    this.sortFieldsMap = {};
    this.sortFields.forEach((sortField) => this.sortFieldsMap[sortField.field] = sortField);
  }

  public setSort(sortField: SortField): void {
    if (sortField.field !== this.currentSortField) {
      this.onSort.emit(sortField.field);
    }
  }
}
