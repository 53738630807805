import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { CustomerFeature } from '../customer-integration';
import { CustomerFeatureToggleService } from '../services/customer-feature-toggle.service';

@Directive({
  selector: '[customerFeatureToggle]'
})
export class CustomerFeatureToggleDirective implements OnInit {
  @Input() customerFeatureToggle: CustomerFeature;

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly customerFeatureToggleService: CustomerFeatureToggleService,
  ) {}

  ngOnInit() {
    this.updateView();
  }

  private updateView(): void {
    if (this.customerFeatureToggleService.isFeatureAvailable(this.customerFeatureToggle)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
