<div class="modal-header">
  <div>
    <h1 class="modal-title m-0" data-automation-id="item-detail-title">
      {{itemTitle}}
    </h1>
    <h2 data-automation-id="item-detail-call-number">
      {{subheading}}
    </h2>
  </div>
  <button type="button"
          class="close-btn p-0"
          (click)="onClose()"
          aria-label="Close dialog"
          data-automation-id="close-item-details-button">
    <span aria-hidden="true" class="icon icon-close"></span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex flex-column">
    <h2 translate class="header" data-automation-id="item-detail-header">
      itemDetailsHeader
    </h2>
    <div class="d-flex flex-column flex-sm-row">
      <div class="label mb-2 mb-sm-6 flex-shrink-0" translate data-automation-id="recordId-label">itemId</div>
      <div class="value" data-automation-id="recordId-value">
        {{item?.recordId  || '—' }}
      </div>
    </div>
    <div class="d-flex flex-column flex-sm-row">
      <div class="label mb-2 mb-sm-6 flex-shrink-0" translate data-automation-id="barcode-label">itemBarcode</div>
      <div class="value" data-automation-id="barcode-value">
        {{item?.barcode || '—' }}
      </div>
    </div>
    <div class="d-flex flex-column flex-sm-row" *ngIf="item?.donorName">
      <div class="label mb-2 mb-sm-6 flex-shrink-0" translate data-automation-id="donor-label">donorName</div>
      <div class="value" data-automation-id="donor-value">
        {{item?.donorName  || '—' }}
      </div>
    </div>
    <div class="d-flex flex-column flex-sm-row" *ngIf="item?.donorOrganization">
      <div class="label mb-2 mb-sm-6 flex-shrink-0" translate data-automation-id="donor-organization-label">donorOrganization</div>
      <div class="value" data-automation-id="donor-organization-value">
        {{item?.donorOrganization || '—' }}
      </div>
    </div>
    <div class="d-flex flex-column flex-sm-row" *ngIf="displayNotes && item?.publicNote">
      <div class="label mb-2 mb-sm-6 flex-shrink-0 text-capitalize" translate data-automation-id="item-notes-label">itemNotes</div>
      <div class="value" data-automation-id="item-notes-value">
        {{item.publicNote }}
      </div>
    </div>
  </div>
</div>
