<img
  *ngIf="url; else defaultImage"
  alt=""
  role="presentation"
  data-automation-id="cover-image"
  [src]="url"
  class="cover-image"
  (load)="handleImageLoad($event)"
  (error)="handleImageError()">
<ng-template #defaultImage>
  <app-default-cover
    [title]="title"
    [author]="author"
    [materialType]="materialType">
  </app-default-cover>
</ng-template>

<div class="cover-details"></div>



