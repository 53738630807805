<div class="card">
  <div class="card-block">
    <h4 class="card-title" data-automation-id="server-error-title" translate>serverError</h4>
    <p role="alert"
       aria-live="assertive"
       aria-atomic="true"
       class="m-4"
       translate
       data-automation-id="server-error-message">
      generalServerError
    </p>
    <a [routerLink]="['/']"
      class="btn btn-primary"
       data-automation-id="server-error-link-home"
      translate>serverErrorTakeHome</a>
  </div>
</div>
