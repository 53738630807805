export enum Tabs {
  availability = 'availability',
  about = 'about',
  relationships = 'relationships',
  resources = 'resources',
  agents = 'agents',
  concepts = 'concepts',
}

export enum TabPageSizes {
  RELATIONSHIPS = 5,
  DEFAULT = 10,
  SHOWCASES_RESOURCES = 12,
  SHOWCASES = 13,
}
