import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-passcode-visibility-toggler',
  templateUrl: './passcode-visibility-toggler.component.html',
  styleUrls: ['./passcode-visibility-toggler.component.scss'],
})
export class PasscodeVisibilityTogglerComponent implements AfterViewInit {
  @ViewChild('inputContainerRef') inputContainerRef: ElementRef;
  public readonly showIcon = faEye;
  public readonly hideIcon = faEyeSlash;
  public isVisible: boolean = false;

  private inputEl: HTMLInputElement | null;

  constructor(private renderer: Renderer2) {}

  public ngAfterViewInit(): void {
    this.inputEl = this.inputContainerRef.nativeElement.firstChild;
  }

  public toggleVisibility(): void {
    this.isVisible = !this.isVisible;
    this.renderer.setProperty(this.inputEl, 'type', this.isVisible ? 'text' : 'password');
  }
}
