import { Component, ElementRef, Injector, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { randomNumericString } from 'shared/utils/random';
import { ControlValueAccessorConnector } from 'user/components/bookshelf/helpers/control-value-accessor-connector';

@Component({
  selector: 'app-profile-form-field-input',
  templateUrl: './profile-form-field-input.component.html',
  styleUrls: ['./profile-form-field-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ProfileFormFieldInputComponent,
    multi: true,
  }],
})
export class ProfileFormFieldInputComponent extends ControlValueAccessorConnector {
  @Input() public formControlLabel: string;
  @Input() autocompleteValue = 'on';
  public readonly errorId = `profile-form-field-input-error-${randomNumericString()}`;

  @ViewChild('inputFieldElement') public inputFieldElement: ElementRef;
  constructor(injector: Injector) {
    super(injector);
  }

  public setFocusOnInputField() {
    this.inputFieldElement.nativeElement.focus();
  }
}
