import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { I18NService } from 'app/services/i18-n.service';
import { loadLocationInfo } from 'core/actions/library-info.actions';
import { WEEK_DAYS } from 'core/models/library-info-widget';
import { getDefaultLibraryCode, getVisibleLocations, getLocationInfoLoading, getSelectedLocationInfo } from 'core/reducers/library-info.reducer';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { filter } from 'rxjs/operators';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { LibraryInfo, LocationInfo, LocationInfoView, Schedule, ScheduleView, TransformedSchedule, } from 'shared/models/library-info';
import { randomNumericString } from 'shared/utils/random';
import { focusLibraryInfoButton, toggleOpenedLibraryInfoWidget } from 'user/actions/user-profile.actions';
import { LOCALE_ID_WRAPPER } from '../../../app.module';
import { LocationWithName, LocationWithNameOverride } from '../../../models/locations';

@Component({
  selector: 'app-library-info-widget',
  templateUrl: './library-info-widget.component.html',
  styleUrls: ['./library-info-widget.component.scss'],
})
export class LibraryInfoWidgetComponent implements OnInit, OnDestroy {
  public locationInfoView: LocationInfoView;
  public libraryInfo: LibraryInfo;
  public locations: LocationWithNameOverride[];
  public locationInfoLoading: boolean;
  public selectedLocation: UntypedFormControl;
  public selectId = `location-select-${randomNumericString()}`;

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private configLoader: ConfigurationLoader,
    private store: Store,
    private elementRef: ElementRef,
    private i18nService: I18NService,
    @Inject(LOCALE_ID_WRAPPER) private locale: string,
  ) {
  }

  @HostListener('body:click', ['$event.target'])
  public onClick(targetElement: any) {
    if (!this.elementRef.nativeElement.contains(targetElement)) {
      this.close();
    }
  }

  @HostListener('document:keydown.escape')
  public escapePressed() {
    this.close();
  }

  public ngOnInit() {
    moment.locale(this.locale);
    this.libraryInfo = this.configLoader.libraryInfoConfiguration.libraryInfo;

    this.subscriptions.add(
      this.store.select(getSelectedLocationInfo)
      .pipe(filter((locationInfo) => !!locationInfo))
      .subscribe((locationInfo) => {
        this.locationInfoView = this.extractLocationInfo(locationInfo);
        this.cdr.detectChanges();
      }),
    );

    this.subscriptions.add(
      this.store.select(getLocationInfoLoading)
      .subscribe((loading) => {
        this.locationInfoLoading = loading;
        this.cdr.detectChanges();
      }),
    );

    this.subscriptions.add(
      combineLatest([
        this.store.select(getVisibleLocations),
        this.store.select(getDefaultLibraryCode),
      ]).pipe(
        filter((locations) => !!locations)
      ).subscribe(([locations, code]) => {
        this.locations = locations;
        this.selectedLocation = new UntypedFormControl(code);
        this.changeLocation(code);
      })
    );
  }

  public changeLocation(locationId: string) {
    this.store.dispatch(loadLocationInfo({configurationId: this.libraryInfo.configurationId, locationId}));
  }

  public close() {
    this.store.dispatch(toggleOpenedLibraryInfoWidget({isOpened: false}));
    this.store.dispatch(focusLibraryInfoButton());
  }

  public extractLocationInfo(locationInfo: LocationInfo): LocationInfoView {
    const {
      libraryLocation,
      webSite,
      email,
      phones,
      regularScheduleList,
      exceptionScheduleList,
      overrideHours
    } = locationInfo;

    const sortedRegularSchedule = this.getSortedRegularSchedule(regularScheduleList);
    const sortedExceptionSchedule = this.getSortedExceptionsSchedule(exceptionScheduleList);

    return {
      location: libraryLocation,
      webSite,
      email,
      phones,
      todayHours: this.extractTodayHours(sortedRegularSchedule, sortedExceptionSchedule),
      regular: this.extractTransformedRegularSchedule(sortedRegularSchedule),
      exceptions: this.extractTransformedExceptionSchedule(sortedExceptionSchedule),
      overrideHours,
    };
  }

  public getSortedRegularSchedule(regular: Schedule[]): Schedule[] {
    if (!regular) {
      return null;
    }
    regular.sort(this.sortByDayOfWeek);
    return regular;
  }

  public getSortedExceptionsSchedule(exceptions: Schedule[]): Schedule[] {
    if (!exceptions) {
      return null;
    }
    exceptions.sort(this.sortByDateFromAndDateTo);
    return exceptions;
  }

  public extractTodayHours(regular: Schedule[], exceptions: Schedule[]): string {
    const today = this.getTodayFromExceptionsSchedule(exceptions) || this.getTodayFromRegularSchedule(regular);
    return this.getDisplayTime(today, true);
  }

  public getTodayFromExceptionsSchedule(exceptions: Schedule[]): Schedule {
    if (!exceptions) {
      return null;
    }

    const currentDayWithoutHours = moment().format('LL');
    const currentDay = moment(currentDayWithoutHours);

    return exceptions.filter((item) => {
      return currentDay.isSameOrAfter(item.dateFrom) && currentDay.isSameOrBefore(item.dateTo);
    })[0];
  }

  public getTodayFromRegularSchedule(regular: Schedule[]): Schedule {
    if (!regular) {
      return null;
    }

    const currentDayIndex = new Date().getDay();
    const isSunday = currentDayIndex === 0;
    const indexMondaySunday = isSunday ? 6 : currentDayIndex - 1;
    return regular[indexMondaySunday];
  }

  public sortByDayOfWeek(a: Schedule, b: Schedule) {
    return WEEK_DAYS.indexOf(a.dayOfWeek) - WEEK_DAYS.indexOf(b.dayOfWeek);
  }

  public sortByDateFromAndDateTo(a: Schedule, b: Schedule) {
    return a.dateFrom.localeCompare(b.dateFrom) || a.dateTo.localeCompare(b.dateTo);
  }

  public getDisplayTime(today: Schedule, isForTodaysHours?: boolean): string {
    if (isForTodaysHours && today && today.closed) {
      return 'closed';
    }

    if (!today || today && today.closed) {
      return null;
    }

    const openTime = moment(today.timeOpen, 'hh-mm').format('hh:mm a');
    const closeTime = moment(today.timeClose, 'hh-mm').format('hh:mm a');
    return `${openTime} - ${closeTime}`;
  }

  public extractTransformedRegularSchedule(schedule: Schedule[]): ScheduleView[] {
    if (!schedule) {
      return [];
    }

    const groupedDays = this.getDaysGroupedBySameHours(schedule);

    return groupedDays.map((item) => {
      const moreThanTwoDaysInRange = item.title.length > 2;
      const dayRange = moreThanTwoDaysInRange ? item.title.slice(0, 1).concat(item.title.slice(-1)) : item.title;
      const separator = moreThanTwoDaysInRange ? ' - ' : ', ';

      return {
        title: dayRange.join(separator),
        hours: this.getDisplayTime(item),
      };
    });
  }

  public getDaysGroupedBySameHours(schedule: Schedule[]): TransformedSchedule[] {
    return schedule
    .map((item: Schedule) => {
      return {
        ...item,
        dayOfWeek: this.i18nService.getTranslation(item.dayOfWeek?.toLowerCase()),
      };
    })
    .reduce((acc, day: Schedule) => {
      let bothClosed;
      let sameHours;
      const prevDay = acc[acc.length - 1] as any;

      if (prevDay) {
        bothClosed = [day, prevDay].every((day) => day.closed);
        sameHours = day.timeOpen === prevDay.timeOpen && day.timeClose === prevDay.timeClose;
      }

      if (prevDay && day.closed === prevDay.closed && (bothClosed || sameHours)) {
        prevDay.title.push(day.dayOfWeek);
      } else {
        acc.push({...day, title: [day.dayOfWeek]});
      }

      return acc;
    }, []);
  }

  public extractTransformedExceptionSchedule(exceptionScheduleList: Schedule[]): ScheduleView[] {
    if (!exceptionScheduleList) {
      return [];
    }

    return exceptionScheduleList.map((item) => {
      let title = moment(item.dateFrom).format('LL');

      if (item.dateFrom !== item.dateTo) {
        title = [item.dateFrom, item.dateTo]
        .map((date: string) => moment(date).format('LL'))
        .join(' - ');
      }

      return {
        title,
        hours: this.getDisplayTime(item),
      };
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
