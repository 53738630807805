import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { LoginType } from 'shared/models/configuration';

@Component({
  selector: 'app-inspire-sign-in',
  templateUrl: './inspire-sign-in.component.html',
  styleUrls: ['./inspire-sign-in.component.scss'],
})
export class InspireSignInComponent {
  @Input() public libraryName?: string;
  @Input() public loginType?: LoginType;
  @Input() public signUpCustomLink?: string;
  @Output() public onSignIn = new EventEmitter();
  @Output() public onClose = new EventEmitter();
  public readonly userIcon = faUser;

  public signIn() {
    this.onSignIn.emit();
  }

  public close() {
    this.onClose.emit();
  }
}
