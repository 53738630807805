<div data-automation-id="full-text" class="box cut-text-with-certain-height" #box>
  <!-- Used to calculate the full height of the component -->
  <span *ngIf="!shortText" [innerHtml]="shortText ? '' : text"></span>
  <span data-automation-id="truncated-text" [innerHtml]="shortText ? shortText : ''"></span>
  <span data-automation-id="additional-text" [innerHtml]="shortText && expanded ? additionalText : ''" aria-live="polite"></span>

  <span *ngIf="source && expanded" data-automation-id="cut-text-source"> From:
    <a class="cut-text-link" *ngIf="url" href="{{url}}" target="_blank" data-automation-id="cut-text-source-link">{{source}}</a>
    <ng-container *ngIf="!url">{{source}}</ng-container>
  </span>
  <span *ngIf="!expanded">... </span>
  <div class="button-wrapper" *ngIf="moreLessVisible && !withoutMoreLess">
    <button class="more-less-link"
      [ngStyle]="{color: moreLessColor}"
      (click)="toggleExpandedText()"
      [attr.aria-expanded]="expanded"
      [attr.aria-label]="expanded ? 'show less text' : 'show more text'"
      data-automation-id="more-less-button"
      cdkMonitorElementFocus
      #morelessbtn>
        <span *ngIf="expanded" >« </span>
        <span translate [translateParams]="{ value: moreLessLabel }">moreOrLess</span>
        <span *ngIf="!expanded" > »</span>
    </button>
  </div>
</div>
