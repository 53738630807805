<div [focusOnInit]="true" class="sign-in-panel" data-automation-id="sign-in-panel">
  <button class="sign-in-panel__btn-close" aria-label="Close user panel" data-automation-id="user-panel-close-btn"
          cdkMonitorElementFocus (click)="close()">
    <span class="icon icon-close sign-in-panel__icon-close"></span>
  </button>

  <ng-template #userPhoto>
    <fa-icon class="sign-in-panel__icon" size="3x" [icon]="userIcon"
             data-automation-id="user-panel-photo"></fa-icon>
  </ng-template>

  <ng-container [ngSwitch]="loginType">
    <ng-container *ngSwitchCase="'page'">
      <ng-container *ngTemplateOutlet="userPhoto"></ng-container>

      <div class="sign-in-panel__header" data-automation-id="user-panel-header"
           translate
           [translateParams]="{ value: libraryName }">
        signInTo
      </div>

      <div class="sign-in-panel__instruction" data-automation-id="user-panel-instruction"
           translate
           [translateParams]="{ value: libraryName }">
        toUseLibrarySignIn
      </div>

      <button class="app-button button-raised medium sign-in-panel__button" (click)="signIn()" translate
              data-automation-id="keycloak-sign-in-button" cdkMonitorElementFocus>
        signIn
      </button>

      <a *ngIf="signUpCustomLink"
         [href]="signUpCustomLink"
         target="_blank"
         class="sign-up-link d-flex justify-content-center mt-2"
         data-automation-id="user-panel-sign-up-link"
      >{{ 'signUp' | translate }}
      </a>
    </ng-container>

    <ng-container *ngSwitchCase="'modal'">
      <app-inspire-login-form class="w-100"></app-inspire-login-form>
    </ng-container>
  </ng-container>
</div>
