import { Component, Input, OnInit, } from '@angular/core';
import { DictionaryItem, DictionaryTypes } from 'app/models/dictionaries';
import { DictionariesService } from 'app/services/dictionaries.service';
import { AvailabilityStatus, MaterialTab } from '../../models/entity';

@Component({
  selector: 'app-rollup-tabs-content-status',
  templateUrl: './rollup-tabs-content-status.component.html',
  styleUrls: ['./rollup-tabs-content-status.component.scss'],
})
export class RollupTabsContentStatusComponent implements OnInit {
  @Input() public availabilityStatus: AvailabilityStatus;
  @Input() public capitalize = false;
  @Input() public materialTab: MaterialTab;
  public availabilityStatuses = AvailabilityStatus;
  public formatByCode: DictionaryItem;

  constructor(
    private readonly dictionariesService: DictionariesService
  ) {}

  ngOnInit() {
    this.formatByCode = this.dictionariesService.getDictionaryItemByCode(
      DictionaryTypes.MATERIAL_TYPES, this.materialTab?.materialTypes?.[0]);
  }

}
