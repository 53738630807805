import { Injectable } from '@angular/core';

@Injectable()
export class WindowRefService {
  public nativeWindow(): any | Window {
    return window;
  }

  public origin(): string {
    return this.nativeWindow().location.origin;
  }

  public host(): string {
    return this.nativeWindow().location.host;
  }

  public get localStorage(): Storage {
    return this.nativeWindow().localStorage;
  }

  public resetScroll(): void {
    return this.nativeWindow().scrollTo(0, 0);
  }
}
