import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { FeatureToggleService } from 'app/services/feature-toggle.service';
import { Observable } from 'rxjs';
import { CustomerFeature } from '../../../customer-integration/customer-integration';
import { CustomerFeatureToggleService } from '../../../customer-integration/services/customer-feature-toggle.service';
import { bulkSelectAllItems, resetBulkSelection, tryToBulkHoldSelectedItems } from '../../actions/bulk-select.actions';
import { BulkFeatureKey } from '../../enums/bulk-feature-key';
import { BulkSelectItem } from '../../models/bulk-select-item';
import { BulkSelectState, getSelectedItems } from '../../reducers/bulk-select.reducer';

@Component({
  selector: 'app-bulk-select-panel',
  templateUrl: './bulk-select-panel.component.html',
  styleUrls: ['./bulk-select-panel.component.scss']
})
export class BulkSelectPanelComponent implements OnInit, OnChanges {
  @Input() bulkFeatureKey: BulkFeatureKey;
  @Input() items: BulkSelectItem[] = [];
  public selectedItems$: Observable<BulkSelectItem[]>;
  public isBulkFeaturingEnabled: boolean;
  public itemsIds: Set<string> = new Set();
  public readonly CustomerFeature = CustomerFeature;

  constructor(
    private readonly store: Store<BulkSelectState>,
    private readonly featureToggleService: FeatureToggleService,
    private readonly customerFeatureToggleService: CustomerFeatureToggleService,
  ) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      // A workaround to avoid the issue with duplicated items returned from the search.
      // "Deselect all" functionality stops working in a library list view for showcases
      // created from a saved search, when newly loaded items are not unique.
      // All items counter and selected items counter become inconsistent, causing this issue.
      // This commit can be reverted when the issue with search is fixed
      this.itemsIds = new Set(this.items.map(item => item.id));
    }
  }

  public ngOnInit() {
    this.isBulkFeaturingEnabled = this.customerFeatureToggleService.isAnyFeatureAvailable([CustomerFeature.BulkHolds, CustomerFeature.BulkBookmarks]);
    this.selectedItems$ = this.store.select(getSelectedItems(this.bulkFeatureKey));
  }

  public isAllItemsSelected(selectedItems: BulkSelectItem[]): boolean {
    return this.itemsIds.size === selectedItems.length;
  }

  public onBulkSelectionChange(selectedItems: BulkSelectItem[]) {
    const selectAll = !this.isAllItemsSelected(selectedItems);
    selectAll
      ? this.store.dispatch(bulkSelectAllItems({bulkFeatureKey: this.bulkFeatureKey, items: this.items}))
      : this.store.dispatch(resetBulkSelection({bulkFeatureKey: this.bulkFeatureKey}));
  }

  public onBulkPlaceHold(event: Event, selectedItems: BulkSelectItem[]) {
    event.stopPropagation();
    if (selectedItems.length) {
      this.store.dispatch(tryToBulkHoldSelectedItems({bulkFeatureKey: this.bulkFeatureKey, items: selectedItems}));
    }
  }
}
