import { Injectable } from '@angular/core';
import { Permissions, ShowcasePermissions, SitePermissions } from '../models/permissions';
import { KeycloakTokenParsedWithClaims, TokenData } from 'app/keycloak/models/keycloak';
import { WindowRefService } from 'app/services/window-ref.service';
import { LOCAL_STORAGE } from 'app/models/local-storage';
import { JwtDecodeService } from './jwt-decode.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  public permissions: Permissions[] = [];
  private permissionsUpdatedSubject: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  public permissionsUpdated$: Observable<void> = this.permissionsUpdatedSubject.asObservable();
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  constructor(
    private readonly windowRefService: WindowRefService,
    private readonly jwtDecodeService: JwtDecodeService
  ) {}

  public updateStorageAndPermissionsWithTokenData(data: TokenData): void {
    this.windowRefService.localStorage.setItem(LOCAL_STORAGE.BEARER_TOKEN, data.access_token);
    this.windowRefService.localStorage.setItem(LOCAL_STORAGE.REFRESH_TOKEN, data.refresh_token);
    const decodedToken = this.jwtDecodeService.decode(data.access_token) as KeycloakTokenParsedWithClaims;
     if (decodedToken?.authorization?.permissions) {
      this.permissions = decodedToken.authorization.permissions;
      this.permissionsUpdatedSubject.next();
    }
  }

  public setShowcaseLoading(loading: boolean) {
    this.loadingSubject.next(loading);
  }

  public getPermissions(): Permissions[] {
    return this.permissions;
  }

  public hasPermission(resource: string, permission: SitePermissions | ShowcasePermissions): boolean {
    return this.hasScopeForResource(resource, permission);
  }

  private hasScopeForResource(resource: string, scope: SitePermissions | ShowcasePermissions): boolean {
    for (const permission of this.permissions) {
      if (permission && permission.rsname === resource && permission.scopes.includes(scope)) {
        return true;
      }
    }
    return false;
  }
}
