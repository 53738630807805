<ng-container *ngIf="isBulkFeaturingEnabled">
  <div class="d-flex mt-md-1 flex-wrap align-items-start fixed-bottom-container">
    <ng-container *ngIf="(selectedItems$ | async) as selectedItems">

      <!-- Select All / Deselect All -->
      <button class="ins-buttons button-secondary flint bulk-select-button mb-1"
              data-automation-id="bulk-toggle-select-all-button"
              [attr.aria-pressed]="isAllItemsSelected(selectedItems)"
              (click)="onBulkSelectionChange(selectedItems)">
        <span>{{ 'bulkSelectAll' | translate }}</span>
        <span class="icon ml-2" [ngClass]="isAllItemsSelected(selectedItems) ? 'fas fa-check-square' :  'far fa-square'" aria-hidden="true"></span>
      </button>

      <!-- Selected Items Counter -->
      <div aria-live="polite" role="status" class="bulk-select-count mx-3 py-1 text-nowrap" data-automation-id="bulk-select-count">
        <strong>{{ 'bulkItemsCountSelected' | translate: {itemsCount: selectedItems.length} }}:</strong>
      </div>

      <!-- Add to Lists -->
      <app-bulk-add-to-list
        *customerFeatureToggle="CustomerFeature.BulkBookmarks"
        [selectedItems]="selectedItems">
      </app-bulk-add-to-list>

      <!-- Bulk Place Hold -->
      <button class="ins-buttons no-border button-primary flint bulk-action-button"
              *customerFeatureToggle="CustomerFeature.BulkHolds"
              [class.button-disabled]="!selectedItems.length"
              [attr.aria-disabled]="!selectedItems.length"
              (click)="onBulkPlaceHold($event, selectedItems)"
              data-automation-id="bulk-holds-button">
        <span>{{ 'bulkPlaceHolds' | translate }}</span>
      </button>
    </ng-container>
  </div>
</ng-container>
