export interface OverDriveFormatContainer {
  id: OverDriveFormat | string;
  name: string;
}

export enum OverDriveFormat {
  EPUB = 'ebook-epub-adobe',
  KINDLE = 'ebook-kindle',
  OVERDRIVE_READ = 'ebook-overdrive',
  OVERDRIVE_MP3 = 'audiobook-mp3',
  OVERDRIVE_LISTEN = 'audiobook-overdrive',
}

export type OverDriveFormatsResponse = OverDriveFormatContainer[];
