<ng-container *ngIf="!customFooter?.enabled || stopCustom; else customFooterSection">
  <footer [class.border-top-0]="noBorderTop" data-automation-id="universal-footer">
    <ul class="d-flex flex-wrap justify-content-center list-unstyled">
      <li *ngIf="privacyPolicyLink; else defaultPrivacyPolicyLinks">
        <a class="link"
          *ngIf="privacyPolicyLink.href; else emptyLink"
          [href]="privacyPolicyLink.href"
          target="_blank"
          data-automation-id="footerLibraryPrivacyPolicyLink">{{ privacyPolicyLink.title }}</a>
        <ng-template #emptyLink>
          <span class="link" data-automation-id="footerLibraryPrivacyPolicyLink">{{ privacyPolicyLink.title }}</span>
        </ng-template>
      </li>
      <ng-template #defaultPrivacyPolicyLinks>
        <li>
          <a class="link"
            href="https://www.iii.com/privacy-policy/"
            target="_blank"
            data-automation-id="footerInnovativePrivacyPolicyLink">
            {{ 'innovativePrivacyPolicyLink' | translate }}</a>
        </li>
        <li>
          <a class="link"
            href="https://www.iii.com/services-privacy-policy/"
            target="_blank"
            data-automation-id="footerInnovativeServicesPrivacyPolicyLink">
            {{ 'innovativeServicesPrivacyPolicyLink' | translate }}</a>
        </li>          
      </ng-template>
    </ul>
  </footer>
</ng-container>

<ng-template #customFooterSection>
  <app-custom-section [code]="customFooter.code"></app-custom-section>
</ng-template>
