import { BidiModule } from '@angular/cdk/bidi';
import { CommonModule, } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule, } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationRef,
  DoBootstrap,
  ErrorHandler,
  forwardRef,
  Injectable,
  Injector,
  LOCALE_ID,
  NgModule,
  Provider,
  InjectionToken,
} from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule, ReactiveFormsModule, } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl, MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TippyModule } from '@ngneat/helipopper';
import { EffectsModule } from '@ngrx/effects';
import { MinimalRouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HelpButtonComponent } from 'core/components/help-button/help-button.component';
import { LanguageSwitcherComponent } from 'core/components/language-switcher/language-switcher.component';
import { LibraryInfoButtonComponent } from 'core/components/library-info-button/library-info-button.component';
import { LoginButtonComponent } from 'core/components/login-button/login-button.component';
import { ToggleBookmarksButtonComponent } from 'core/components/toggle-bookmarks-button/toggle-bookmarks-button.component';
import { AppComponent } from 'core/containers/app/app.component';
import { CoreModule } from 'core/core.module';
import { ApiVersionService } from 'core/services/api-version.service';

import { APIInterceptor } from 'core/services/api.interceptor';
import { SiteCodeGuard } from 'core/services/site-code-guard.service';
import { environment } from 'environments/environment';
import { isOn, set } from 'feature-toggle-service';
import 'focus-visible';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { FacetsSchemaService } from 'search/facets/services/facets-schema.service';
import { configurationLoader, ConfigurationLoader } from 'shared/configuration-loader';
import { InspireTranslateLoader } from 'shared/inspire-translate-loader';
import { LocaleHelper, MAORI_FAKE, MAORI_REAL } from 'shared/locale';
import { MatPaginatorIntlCustom } from 'shared/mat-paginator-intl-custom/mat-paginator-intl-custom';
import { routes } from './app.routes';
import { CustomerIntegrationModule } from './customer-integration/customer-integration.module';
import { KeyCloakModule } from './keycloak/keycloak.module';
import { metaReducers, reducers, } from './reducers';
import { customDomainLoader, CustomDomainLoader } from './services/custom-domain-loader';
import { DictionariesService } from './services/dictionaries.service';
import { FEATURE_TOGGLE_SERVICE_LIBRARY } from './services/feature-toggle.service';
import { I18NService } from './services/i18-n.service';
import { MetaTagLoaderService } from './services/meta-tag-loader.service';
import { PendoService } from './services/pendo.service';
import { StartupService } from './services/startup.service';
import { SyndeticsUnboundService } from './services/syndetics-unbound.service';
import { ViewportService } from './services/viewport.service';
import { WindowRefService } from './services/window-ref.service';
import { OneTrustFooterButton } from 'common/components/onetrust-footer-button/onetrust-footer-button.component';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  public handleError(error: any): void {
    import('./shared/sentry')
      .then(({ captureException }) => captureException(error.originalError || error));
    if (!PRODUCTION) {
      console.error(error);
    }
  }
}

export function startupServiceFactory(startupService: StartupService) {
  return () => startupService.load();
}

const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true },
];

export const LOCALE_ID_WRAPPER = new InjectionToken<String>('LOCALE_ID_WRAPPER');

const PROVIDERS: Provider[] = [
  { provide: LOCALE_ID, useFactory: forwardRef(() => LocaleHelper.locale) },
  // use LOCALE_ID_WRAPPER instead of LOCALE_ID across the app until ngx-translate adds Maori language support
  {provide: LOCALE_ID_WRAPPER, useFactory: forwardRef(() => LocaleHelper.locale === MAORI_FAKE ? MAORI_REAL : LocaleHelper.locale)},
  {provide: FEATURE_TOGGLE_SERVICE_LIBRARY, useValue: {isOn, set}},
  { provide: MatPaginatorIntl, useValue: MatPaginatorIntlCustom },
  { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  SiteCodeGuard,
  StartupService,
  I18NService,
  DictionariesService,
  MetaTagLoaderService,
  FacetsSchemaService,
  httpInterceptorProviders,
  {
    provide: ConfigurationLoader,
    useValue: configurationLoader,
  },
  {
    provide: CustomDomainLoader,
    useValue: customDomainLoader,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: startupServiceFactory,
    deps: [StartupService],
    multi: true,
  },
  ApiVersionService,
  WindowRefService,
  ViewportService,
  PendoService,
  SyndeticsUnboundService,
];

if (typeof SENTRY_DSN !== 'undefined' && SENTRY_DSN && typeof RELEASE !== 'undefined') {
  PROVIDERS.push({ provide: ErrorHandler, useClass: SentryErrorHandler });
}

const CUSTOM_ELEMENTS: [any, string][] = [
  [LanguageSwitcherComponent, 'vega-language-switcher'],
  [ToggleBookmarksButtonComponent, 'vega-toggle-bookmarks-button'],
  [LoginButtonComponent, 'vega-login-button'],
  [LibraryInfoButtonComponent, 'vega-library-info-button'],
  [HelpButtonComponent, 'vega-help-button'],
  [OneTrustFooterButton, 'vega-cookie-settings'],
];

/* there is a PR to make it work using `hideOnEscape` setting https://github.com/ngneat/helipopper/pull/43 */
const hideOnEsc = {
  name: 'hideOnEsc',
  defaultValue: true,
  fn(instance: any) {
    function onKeyDown(event: any) {
      if (event.keyCode === 27) {
        instance.hide();
      }
    }
    return {
      onShow() {
        document.addEventListener('keydown', onKeyDown);
      },
      onHide() {
        document.removeEventListener('keydown', onKeyDown);
      },
    };
  },
};

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        environment.disableAnimation ? NoopAnimationsModule : BrowserAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        BidiModule,
        MatNativeDateModule,
        MatPaginatorModule,
        RouterModule.forRoot(routes, { useHash: false, onSameUrlNavigation: 'reload' }),
        KeyCloakModule,
        CustomerIntegrationModule,
        /**
         * StoreModule.forRoot is imported once in the root module, accepting a reducer
         * function or object map of reducer functions. If passed an object of
         * reducers, combineReducers will be run creating your application
         * meta-reducer. This returns all providers for an @ngrx/store
         * based application.
         */
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
                strictStateSerializability: false,
                strictActionSerializability: false,
            },
        }),
        /**
         * @ngrx/router-store keeps router state up-to-date in the store.
         */
        StoreRouterConnectingModule.forRoot({ serializer: MinimalRouterStateSerializer }),
        /**
         * Store devtools instrument the store retaining past versions of state
         * and recalculating new states. This enables powerful time-travel
         * debugging.
         *
         * To use the debugger, install the Redux Devtools extension for either
         * Chrome or Firefox
         *
         * See: https://github.com/zalmoxisus/redux-devtools-extension
         */
        /* istanbul ignore next */
        environment.NODE_ENV !== 'production' ? StoreDevtoolsModule.instrument() : [],
        /**
         * EffectsModule.forRoot() is imported once in the root module and
         * sets up the effects class to be initialized immediately when the
         * application starts.
         *
         * See: https://github.com/ngrx/platform/blob/master/docs/effects/api.md#forroot
         */
        EffectsModule.forRoot([]),
        CoreModule.forRoot(),
        TranslateModule.forRoot({
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler,
            },
            loader: {
                provide: TranslateLoader,
                useClass: InspireTranslateLoader,
            },
        }),
        FeatureToggleModule,
        TippyModule.forRoot({
            defaultVariation: 'tooltip',
            variations: {
                tooltip: {
                    arrow: false,
                    appendTo: 'parent',
                    interactive: true,
                    interactiveBorder: 5,
                    plugins: [hideOnEsc],
                    placement: 'bottom-start',
                    delay: [1500, null],
                    aria: {
                        content: 'describedby',
                        expanded: false,
                    },
                },
            },
        }),
    ],
    providers: PROVIDERS,
})
export class AppModule implements DoBootstrap {
  constructor(private injector: Injector) {}

  ngDoBootstrap(appRef: ApplicationRef) {
    appRef.bootstrap(AppComponent);

    for (const [component, name] of CUSTOM_ELEMENTS) {
      const el = createCustomElement(component, {injector: this.injector});
      customElements.define(name, el);
    }
  }
}
