import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NlbItemLocatorComponent } from './components/nlb-item-locator/nlb-item-locator.component';
import { TranslateModule } from '@ngx-translate/core';
import { NlbItemLocatorService } from './services/nlb-item-locator.service';


@NgModule({
  declarations: [
    NlbItemLocatorComponent
  ],
  exports: [
    NlbItemLocatorComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  providers: [
    NlbItemLocatorService
  ],
})
export class NlbItemLocatorModule { }
