import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'app-cut-text-with-certain-height',
  templateUrl: './cut-text-with-certain-height.component.html',
  styleUrls: ['./cut-text-with-certain-height.component.scss'],
})
export class CutTextWithCertainHeightComponent implements AfterViewInit, AfterViewChecked {
  @Input() public text: string;
  @Input() public source: string;
  @Input() public url: string;
  @Input() public height: number;
  @Input() public moreLessColor: string;
  @Input() public withoutMoreLess: boolean | false;
  @Input() public minLengthToCut?: number;
  @Output() public onExpandedChange = new EventEmitter<boolean>();
  @ViewChild('box', { static: true }) public box: ElementRef;
  @ViewChild('morelessbtn') public btn: ElementRef;

  public shortText: string;
  public additionalText: string;
  public heightCutIndex: number;
  public expanded = true;
  public moreLessVisible = false;
  public moreLessLabel = 'more';

  constructor(public changeDetector: ChangeDetectorRef) {}

  @HostListener('window:resize')
  public onResize() {
    this.expanded = true;
    this.moreLessLabel = 'less';
    this.changeDetector.detectChanges();
    this.makeShortTextIfNeeded();
  }

  public ngAfterViewInit() {
    this.makeShortTextIfNeeded();

    setTimeout(() => {
      this.onResize();
      this.changeDetector.markForCheck();
    });
  }

  public ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  public makeShortTextIfNeeded() {
    const elementHeight = this.box.nativeElement.clientHeight;
    this.heightCutIndex = this.height / elementHeight;
    if (this.heightCutIndex < 1 && (!this.minLengthToCut || this.text.length > this.minLengthToCut)) {
      this.shortText = this.getShortText();
      this.additionalText = this.getAdditionaText();
      this.expanded = false;
      this.moreLessVisible = true;
      this.moreLessLabel = 'more';
    }
  }

  public getShortText(): string {
    const end = Math.floor(+this.heightCutIndex.toFixed(4) * this.text.length);
    const text = this.text.slice(0, end);
    return text[text.length - 1] === ' ' ? text : text.split(' ').slice(0, -1).join(' ');
  }

  public getAdditionaText(): string {
    return this.text.slice(this.shortText.length, this.text.length);
  }

  public toggleExpandedText() {
    this.expanded = !this.expanded;
    this.moreLessLabel = this.expanded ? 'less' : 'more';
    this.onExpandedChange.emit(this.expanded);
    this.btn.nativeElement.focus();
  }
}
