import { EntityTypes, PersonalList } from '../../entity/models/entity';
import { BookCover } from 'search/models/search-results';
import { EditionData } from '../../citation/models/editions';

export enum SavedSearchCardActionType {
  edit = 'edit',
  createShowcase = 'createShowcase',
  viewShowcase = 'viewShowcase',
  delete = 'delete',
}

export enum ListCardActionType {
  edit = 'edit',
  delete = 'delete',
  createShowcase = 'createShowcase',
  viewShowcase = 'viewShowcase',
}

export enum CustomShowcaseActionType {
  edit = 'edit',
  delete = 'delete',
  publish = 'publish',
  unpublish = 'unpublish',
  generate = 'generate',
  open = 'open'
}

export type BookshelfCardActionType = SavedSearchCardActionType | ListCardActionType | CustomShowcaseActionType;

export enum BookshelfCardShareActionType {
  copyLink,
  email,
  facebook,
  twitter
}

export interface BookshelfCardActionPayload<T extends BookshelfCardActionType | BookshelfCardShareActionType, P = any> {
  actionType: T;
  payload?: P;
}

export interface BookshelfItem {
  id: string;
  selected: boolean;
  entity: BookshelfItemEntity;
}

export interface BookshelfItemEntity {
  coverConfig?: BookCover;
  isUnresolved?: boolean;
  editionsData?: EditionData[];
  entityType: EntityTypes;
  id: string;
  title: string;
  checkoutDate?: string;
  format?: string;
  personalLists?: PersonalList[];
  primaryAgent?: {
    id: string;
    label: string;
  };
  selectedTabRecordId?: string;
}
