<div class="p-4">
  <ng-container *ngIf="noRenewalsInProgress$ | async; else processingRenewals">
    <div class="modal-header justify-content-between">
      <h2 ngbAutofocus class="modal-title" data-automation-id="bulk-renew-modal-title">
        {{'renewalSuccessMessage' | translate : {renewedItemsNumber: (renewedCheckouts$ | async).length, totalItemsNumber} }}
      </h2>
      <button
        data-automation-id="close-dialog-btn" type="button" class="close-btn p-0"
        [attr.aria-label]="'closeDialog' | translate" (click)="onClose()">
        <span aria-hidden="true" class="icon icon-close"></span>
      </button>
    </div>
    <div class="modal-body">
      <ng-container *ngIf="renewedCheckouts$ | async as renewedCheckouts">
        <div *ngIf="renewedCheckouts.length" class="mb-4">
          <app-bulk-renew-results
            [checkouts]="renewedCheckouts"
            [isSuccess]="true"
            [title]="'renewedItems'">
          </app-bulk-renew-results>
        </div>
      </ng-container>
      <ng-container *ngIf="failedToRenewCheckouts$ | async as failedToRenewCheckouts">
        <app-bulk-renew-results
          *ngIf="failedToRenewCheckouts.length"
          [checkouts]="failedToRenewCheckouts"
          [isSuccess]="false"
          [title]="'failedToRenewItems'">
        </app-bulk-renew-results>
      </ng-container>
      <div class="modal-footer">
        <button
          class="ins-buttons button-primary flint mt-3"
          data-automation-id="ok-btn" translate (click)="onClose()">
          ok
        </button>
      </div>
    </div>
  </ng-container>

  <ng-template #processingRenewals>
    <div class="modal-header">
      <h2 ngbAutofocus class="modal-title" data-automation-id="bulk-renew-modal-title">
        {{title | translate}}
      </h2>
    </div>
    <div class="modal-body">
      <app-bulk-renew-progress
        *ngIf="totalItemsNumber !== null"
        [processedItemsNumber]="processedItemsNumber$ | async"
        [totalItemsNumber]="totalItemsNumber"
      ></app-bulk-renew-progress>
    </div>
  </ng-template>
</div>


