import { Component, HostBinding, Input } from '@angular/core';
import { AvailabilityStatus, AvailableStatuses } from '../../models/entity';

@Component({
  selector: 'app-material-tab-type-label',
  templateUrl: './material-tab-type-label.component.html',
  styleUrls: ['./material-tab-type-label.component.scss'],
})
export class MaterialTabTypeLabelComponent {
  @HostBinding('class.d-flex') public flexClass = 'd-flex';

  @Input() public status: AvailabilityStatus;
  @Input() public label: string;
  public statuses = AvailabilityStatus;
  public availableStatuses = AvailableStatuses;
}
