import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Concept, Entity, EntityTypes, FormatGroup } from '../models/entity';
import { RelatedItem } from '../models/related-items-result';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root',
})
export class FullEntityService {

  private static readonly searchUrl = 'api/search';
  private static readonly searchResultUrl = 'api/search-result/search';
  private static readonly types = new Map([
    ['Agent', 'agents'],
    ['Concept', 'concepts'],
    ['Instance', 'instances'],
    ['FormatGroup', 'format-groups'],
  ]);

  constructor(
    private readonly http: HttpClient,
    private readonly translateService: TranslateService,
  ) { }

  public getCardItemById(id: string, entityType: EntityTypes): Observable<Entity | Concept | FormatGroup> {
    if (entityType === EntityTypes.INSTANCE) {
      return this.http.get<Entity>(`${FullEntityService.searchUrl}/resources/instances/${id}`);
    }

    const type = FullEntityService.types.get(entityType);
    return this.http.get<Entity | Concept | FormatGroup>(`${FullEntityService.searchResultUrl}/${type}/${id}`, {
      headers: {'api-version': '2'},
    });
  }

  public getFormatGroupByRecordId(recordId?: string): Observable<FormatGroup> {
    const type = FullEntityService.types.get(EntityTypes.FORMAT_GROUP);
    return this.http.get<FormatGroup>(`${FullEntityService.searchResultUrl}/${type}?recordId=${recordId}`);
  }

  public getFormatGroupByIsbn(isbn?: string): Observable<FormatGroup> {
    const type = FullEntityService.types.get(EntityTypes.FORMAT_GROUP);
    return this.http.get<FormatGroup>(`${FullEntityService.searchResultUrl}/${type}?isbn=${isbn}`);
  }

  public getFormatGroupByUpc(upc?: string): Observable<FormatGroup> {
    const type = FullEntityService.types.get(EntityTypes.FORMAT_GROUP);
    return this.http.get<FormatGroup>(`${FullEntityService.searchResultUrl}/${type}?upc=${upc}`);
  }

  public getFormatGroupByIssn(issn?: string): Observable<FormatGroup> {
    const type = FullEntityService.types.get(EntityTypes.FORMAT_GROUP);
    return this.http.get<FormatGroup>(`${FullEntityService.searchResultUrl}/${type}?issn=${issn}`);
  }

  public getFormatGroupByControlNumber(controlNumber?: string): Observable<FormatGroup> {
    const type = FullEntityService.types.get(EntityTypes.FORMAT_GROUP);
    return this.http.get<FormatGroup>(`${FullEntityService.searchResultUrl}/${type}?controlNumber=${controlNumber}`);
  }

  public getRelatedItems(resourceId: string): Observable<RelatedItem[]> {
    const url = `${FullEntityService.searchResultUrl}/instances/${resourceId}/items`;
    return this.http.get<RelatedItem[]>(url).pipe(
      map((items) => items.map((item: RelatedItem) => {
        const location = this.translateService.instant(`location.${item.locationCode}`);
        const status = item.itemRecentlyReturnedMessage || this.translateService.instant(`item-status.${item.itemStatusCode}`);

        return {...item, location, status};
      }))
    );
  }
}
