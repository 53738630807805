import { Directive, EventEmitter, Output } from '@angular/core';
import { ControlContainer, UntypedFormArray } from '@angular/forms';

@Directive()
export abstract class FormArrayControlConnector {
  @Output() public readonly onAddControl = new EventEmitter<undefined>();

  constructor(public readonly controlContainer: ControlContainer) {
  }

  get control(): UntypedFormArray {
    return this.controlContainer.control as UntypedFormArray;
  }

  public removeControl(index: number) {
    if (this.control.length > 1) {
      this.control.removeAt(index);
    } else {
      this.control.at(index).reset();
    }
  }
}
