<app-custom-dropdown *ngIf="availableLanguages && availableLanguages.length > 1"
  (change)="selectLanguage($event)"
  [selectedOption]="locale"
  [overlayOffsetX]="45"
  [aria-label]="'languages' | translate"
  data-automation-id="languageSwitcherDropdown"
  tippy="{{ selectedLanguage }}">
  <span custom-dropdown-label>
    <span class="d-block d-md-none">{{ shortLocale }}</span>
    <span class="d-none d-md-block px-2">{{ selectedLanguage }}</span>
  </span>
  <app-custom-dropdown-option
    *ngFor="let language of availableLanguages"
    [value]="language.code"
    [id]="language.code"
    [lang]="language.code"
    data-automation-id="languageOption">
    {{ language.name }}
  </app-custom-dropdown-option>
</app-custom-dropdown>
