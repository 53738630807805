import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { CommonState } from 'search/full-card/models/card-header';
import { getIsFirstPage } from 'search/reducers/history.reducer';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit, OnDestroy {
  public hasPreviousUrl = false;
  public isShowcaseFullPage = false;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly location: Location,
    private readonly store: Store<CommonState>,
    private readonly route: ActivatedRoute,
    private readonly cdRef: ChangeDetectorRef,
  ) { }

  public ngOnInit(): void {
    this.addSubscriptions();
  }

  public goToPreviousPage(): void {
    this.location.back();
  }

  private addSubscriptions(): void {
    this.subscriptions.add(this.store.pipe(select(getIsFirstPage)).subscribe((isFirstPage: boolean) => {
      this.hasPreviousUrl = !isFirstPage;
    }));

    this.isShowcaseFullPage = !!this.route.snapshot.queryParams.showcase;
    this.cdRef.markForCheck();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
