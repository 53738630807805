<div class="save-search-modal-container p-4">
  <div class="modal-header">
    <h2 class="modal-title title"
        translate
        id="save-search-modal-title"
        data-automation-id="save-search-modal-title"
        tabindex="-1">
      saveSearchTitle
    </h2>
    <button type="button"
            class="close-btn p-0 m-n2"
            (click)="onClose()"
            aria-label="Close dialog"
            data-automation-id="close-save-search-dialog-btn">
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
  </div>
  <div class="modal-body">
    <drag-scroll class="nav nav-tabs tab-header" ngbNav #nav="ngbNav" [(activeId)]="activeTabId">
      <ng-container [ngbNavItem]="0">
        <a ngbNavLink class="tab-label" drag-scroll-item>
          <div class="tab-label-content" data-automation-id="save-new-search-tab-title" translate>
            saveNewSearch
          </div>
        </a>
        <ng-template ngbNavContent>
          <div class="pt-4">
            <form class="save-new-search-form"
                  id="save-new-search-form"
                  data-automation-id="save-new-search-form"
                  [formGroup]="saveNewSearchForm"
                  (ngSubmit)="onSubmit()">
              <div class="form-group">
                <label for="save-new-search-form-name"
                       class="save-new-search-form-name-label"
                       data-automation-id="save-new-search-form-name-label"
                       translate>
                  newSavedSearch
                </label>
                <input type="text"
                       formControlName="name"
                       class="form-control save-new-search-form-name"
                       [class.is-invalid]="name.invalid && name.touched"
                       id="save-new-search-form-name"
                       placeholder="{{ 'savedSearchNamePlaceholder' | translate }}"
                       [attr.aria-invalid]="name.invalid && name.touched"
                       aria-required="true"
                       data-automation-id="save-new-search-form-name"/>
                <div aria-atomic="true" aria-live="assertive" role="alert">
                  <div *ngIf="saveNewSearchForm.invalid && saveNewSearchForm.touched"
                       class="invalid-feedback invalid-feedback-force-display"
                       data-automation-id="save-new-search-form-name-error">
                    <ng-container *ngIf="saveNewSearchForm.errors?.limit">
                      {{ 'saveNewSearchLimitError' | translate }}
                    </ng-container>
                    <ng-container *ngIf="saveNewSearchForm.errors?.unknown">
                      {{ 'generalServerError' | translate }}
                    </ng-container>

                    <ng-container *ngIf="name.errors">
                      <ng-container *ngIf="name.errors.required">
                        {{ 'saveNewSearchNameRequiredError' | translate }}
                      </ng-container>
                      <ng-container *ngIf="name.errors.maxlength">
                        {{ 'saveNewSearchNameMaxLengthError' | translate: {maxCharactersNumber: name.errors.maxlength.requiredLength} }}
                      </ng-container>
                      <ng-container *ngIf="name.errors.duplicate">
                        {{ 'saveNewSearchNameDuplicateError' | translate }}
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </form>

            <div class="spinner-container"
                 role="status"
                 aria-live="polite">
              <div *ngIf="isCreatingSearch"
                   class="spinner-border text-primary"
                   data-automation-id="save-new-search-spinner">
                <span class="sr-only" translate>Loading...</span>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-container [ngbNavItem]="1">
        <a ngbNavLink class="tab-label" data-automation-id="update-saved-search-tab" drag-scroll-item>
          <div class="tab-label-content" data-automation-id="update-saved-search-tab-title" translate>
            updateSavedSearchTabTitle
          </div>
        </a>
        <ng-template ngbNavContent>
          <div class="pt-4">
            <form class="update-saved-search-form"
                  id="update-saved-search-form"
                  data-automation-id="update-saved-search-form"
                  [formGroup]="updateSavedSearchForm"
                  (ngSubmit)="onSubmit()">
              <div class="form-group">
                <label id="update-saved-search-select-label"
                       for="update-saved-search-select"
                       class="update-saved-search-select-label"
                       data-automation-id="update-saved-search-select-label"
                       translate>
                  updateSavedSearchLabel
                </label>
                <mat-form-field subscriptSizing="dynamic">
                  <mat-select class="form-control update-saved-search-select"
                              id="update-saved-search-select"
                              formControlName="id"
                              data-automation-id="update-saved-search-select"
                              aria-labelledby="update-saved-search-select-label"
                              [attr.aria-expanded]="id ? id.panelOpen : false"
                              [class.is-invalid]="id.invalid && id.touched"
                              [attr.aria-invalid]="id.invalid && id.touched"
                              aria-required="true">
                    <mat-option value="" disabled hidden>{{'updateSavedSearchPlaceholder' | translate}}</mat-option>
                    <mat-option
                      *ngFor="let savedSearch of savedSearches"
                      [value]="savedSearch.id"
                      data-automation-id="update-saved-search-select-option">
                      <div class="showcase-icon-wrap mr-2">
                        <fa-icon
                          *ngIf="savedSearch.showcaseRef"
                          data-automation-id="showcase-icon"
                          [icon]="showcaseIcon"
                          aria-hidden="true"
                          [attr.aria-label]="'listSelectListShowcase' | translate"
                          [class]="['showcase-icon', 'h-100', 'w-100', 'rounded-circle']">
                        </fa-icon>
                      </div>
                      <span class="showcase-text">{{ savedSearch.name }}</span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div aria-atomic="true" aria-live="assertive" role="alert">
                  <div *ngIf="updateSavedSearchForm.invalid && updateSavedSearchForm.touched"
                       class="invalid-feedback invalid-feedback-force-display"
                       data-automation-id="update-saved-search-form-error">
                    <ng-container *ngIf="updateSavedSearchForm.errors?.unknown">
                      {{ 'generalServerError' | translate }}
                    </ng-container>

                    <ng-container *ngIf="id.errors">
                      <ng-container *ngIf="id.errors.required">
                        {{ 'updateSavedSearchRequiredError' | translate }}
                      </ng-container>
                      <ng-container *ngIf="id.errors.notFound">
                        {{ 'updateSavedSearchNotFoundError' | translate }}
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </form>

            <div class="spinner-container"
                 role="status"
                 aria-live="polite">
              <div *ngIf="isUpdatingSearch"
                   class="spinner-border text-primary"
                   data-automation-id="update-saved-search-spinner">
                <span class="sr-only" translate>Loading...</span>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </drag-scroll>

    <div class="save-search-modal-tab-content" [ngbNavOutlet]="nav"></div>
  </div>
  <div class="modal-footer justify-content-start">
    <button
      type="submit"
      form="save-new-search-form"
      data-automation-id="save-search-modal-confirm-button"
      aria-label="Save Search"
      (click)="onSubmit()"
      translate
      class="ins-buttons button-primary flint">saveSearchButton
    </button>
    <button (click)="onClose()"
            data-automation-id="save-search-modal-cancel-button"
            aria-label="Cancel"
            class="ins-buttons button-secondary flint ml-3"
            translate>cancelButton
    </button>
  </div>
</div>
