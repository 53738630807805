import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function requiredIfSiblingHasData(siblingControlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent) {
      return null;
    }

    const siblingControl = ((control.parent as UntypedFormGroup).get(siblingControlName) as UntypedFormControl);

    if (control.value && !siblingControl.value) {
      const {...errors} = siblingControl.errors;
      errors['siblingRequired'] = true;
      siblingControl.setErrors(errors);
      siblingControl.markAsDirty();
      siblingControl.updateValueAndValidity();
      return null;
    }

    if (!control.value && siblingControl.value) {
      return {siblingRequired: true};
    }

    if (
      siblingControl.errors?.siblingRequired &&
      ((control.value && siblingControl.value) ||
        (!control.value && !siblingControl.value))
    ) {
      const {...errors} = siblingControl.errors;
      errors['siblingRequired'] = null;
      siblingControl.setErrors(errors);
      siblingControl.updateValueAndValidity();
    }

    return null;
  };
}
