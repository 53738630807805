import { Component, OnInit, Input } from '@angular/core';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { Store } from '@ngrx/store';
import { toggleOpenedLibraryInfoWidget } from 'user/actions/user-profile.actions';

@Component({
  selector: 'app-contact-your-library-link',
  templateUrl: './contact-your-library-link.component.html',
  styleUrls: ['./contact-your-library-link.component.scss'],
})
export class ContactYourLibraryLinkComponent implements OnInit {
  @Input() public libraryContactLink?: string;
  public libraryInfoWidgetEnabled = false;

  constructor(
    private readonly configLoader: ConfigurationLoader,
    private readonly store: Store,
  ) { }

  public ngOnInit() {
    this.libraryInfoWidgetEnabled = this.configLoader.libraryInformationEnabled;
  }

  public openLibraryInfo(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.store.dispatch(toggleOpenedLibraryInfoWidget({isOpened: true}));
  }
}
