import { ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import { select, Store, } from '@ngrx/store';
import { AvailabilityService } from 'app/entity/services/availability.service';
import { EContentAvailability } from 'core/over-drive/models/over-drive-availability';
import { combineLatest, Subscription, } from 'rxjs';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { checkEntityVendorAvailability, gatesLoadResourceCount } from '../actions/entity.actions';
import { AvailabilityUpdateInfo, EditionType, MaterialTab, VendorType } from '../models/entity';
import { getAvailability, getResourceCountData, isLoadingInProgress } from '../reducers/entity.reducer';
import { VendorIssuesService } from '../services/vendor-issues.service';
@Component({
  selector: 'app-overdrive-and-sierra-availability-container',
  template: '<ng-container *ngTemplateOutlet="containerData ? childTemplate : null; context:{availabilityUpdates: availabilityUpdates}">' +
    '</ng-container>',
})
export class OverdriveAndSierraAvailabilityUpdateContainer implements OnInit {
  @Input() public containerData: MaterialTab[];
  @Input() public resourceId: string;
  @Output() public availabilityUpdatesChange = new EventEmitter<AvailabilityUpdateInfo[]>();
  @ContentChild(TemplateRef) public childTemplate: TemplateRef<{ availabilityUpdates: AvailabilityUpdateInfo[] }>;

  public availabilityUpdates: AvailabilityUpdateInfo[];
  private subscriptions = new Subscription();
  public isOverdriveMagazine: boolean;

  public constructor(
    private cdRef: ChangeDetectorRef,
    private readonly store: Store,
    private availabilityService: AvailabilityService,
    private configLoader: ConfigurationLoader,
    private readonly vendorIssuesService: VendorIssuesService
  ) { }

  public ngOnInit(): void {
    const initial = { electronic: [] as MaterialTab[], physical: [] as MaterialTab[] };
    const materialTabsByType = this.containerData?.reduce((acc, tab): { [key: string]: MaterialTab[] } => {
      if (tab.type === EditionType.ELECTRONIC) {
        acc.electronic = acc.electronic.concat(tab);
      }
      if (tab.type === EditionType.PHYSICAL) {
        acc.physical = acc.physical.concat(tab);
      }
      return acc;
    }, initial);


    const overdriveIds = materialTabsByType.electronic?.reduce((acc, tab) => {
     this.isOverdriveMagazine = this.vendorIssuesService.hasVendorIssuesForTab(tab);
      const hasOverdrive = !!(tab.availability.vendors && tab.availability.vendors[VendorType.OVERDRIVE]);
      if (hasOverdrive && tab.availability.vendors && tab.availability.vendors[VendorType.OVERDRIVE].reserveId) {
        return acc.concat(tab.availability.vendors[VendorType.OVERDRIVE].reserveId);
      }
      return acc;
    }, []);

    const axis360Ids = materialTabsByType.electronic?.reduce((acc, tab) => {
      const hasAxis360 = !!(tab.availability.vendors && tab.availability.vendors[VendorType.AXIS360]);
      if (hasAxis360 && tab.availability.vendors && tab.availability.vendors[VendorType.AXIS360].reserveId) {
        return acc.concat(tab.availability.vendors[VendorType.AXIS360].reserveId);
      }
      return acc;
    }, []);

    const cloudLibraryIds = materialTabsByType.electronic?.reduce((acc, tab) => {
      const hascloudLibrary = !!(tab.availability.vendors && tab.availability.vendors[VendorType.CLOUD_LIBRARY]);
      if (hascloudLibrary && tab.availability.vendors && tab.availability.vendors[VendorType.CLOUD_LIBRARY].reserveId) {
        return acc.concat(tab.availability.vendors[VendorType.CLOUD_LIBRARY].reserveId);
      }
      return acc;
    }, []);

    const borrowboxIds = materialTabsByType.electronic?.reduce((acc, tab) => {
      const hasborrowbox = !!(tab.availability.vendors && tab.availability.vendors[VendorType.BORROW_BOX]);
      if (hasborrowbox && tab.availability.vendors && tab.availability.vendors[VendorType.BORROW_BOX].reserveId) {
        return acc.concat(tab.availability.vendors[VendorType.BORROW_BOX].reserveId);
      }
      return acc;
    }, []);

    const recordIds = materialTabsByType.physical.reduce((acc, tab) => {
      return tab.recordIds ? acc.concat(tab.recordIds) : acc;
    }, []);

    if (axis360Ids.length) {
      this.requestElectronicAvailabilityInfo(axis360Ids, VendorType.AXIS360);
    }
    if (borrowboxIds.length) {
      this.requestElectronicAvailabilityInfo(borrowboxIds, VendorType.BORROW_BOX);
    }
    if (overdriveIds.length && !this.isOverdriveMagazine) {
      this.requestElectronicAvailabilityInfo(overdriveIds, VendorType.OVERDRIVE);
    }
    if (cloudLibraryIds.length) {
      this.requestElectronicAvailabilityInfo(cloudLibraryIds, VendorType.CLOUD_LIBRARY);
    }
    this.requestPhysicalAvailabilityInfo(recordIds);

    this.subscriptions.add(combineLatest([
      this.store.pipe(select(getResourceCountData)),
      this.store.pipe(select(getAvailability)),
      this.store.pipe(select(isLoadingInProgress(this.resourceId)))
    ]).subscribe(([ resourceCountData, availability, isLoadingInProgress]) => {
      this.availabilityUpdates = this.containerData.map((tab: MaterialTab): AvailabilityUpdateInfo => {
        if (isLoadingInProgress) {
          return { status: tab.availability.status, eVendorAvailabilityPending: true };
        }
        if (tab.type === EditionType.ELECTRONIC) {
          const transformAvailability = {} as Partial<Record<VendorType,EContentAvailability[]>>;
          for (let vendorType in availability) {
            transformAvailability[vendorType as VendorType] = availability[vendorType as VendorType][this.resourceId] || [];
          }

         return this.availabilityService.countEContentAvailability(transformAvailability, tab);
        }

        if (tab.type === EditionType.PHYSICAL) {
          return this.availabilityService.countPhysicalContentAvailability(resourceCountData, tab);
        }
        return { status: tab.availability.status };
      });

      this.availabilityUpdatesChange.emit(this.availabilityUpdates);
      this.cdRef.markForCheck();
    }));
  }

  private requestElectronicAvailabilityInfo(reserveIds: string[], type: VendorType) {
    const uniqueReserveIds = Array.from(new Set(reserveIds).values());
    const connections = this.configLoader.connectionsConfiguration?.includes(type);
    if (connections && uniqueReserveIds.length) {
      this.store.dispatch(checkEntityVendorAvailability({
        vendor: type, content: {
          resourceId: this.resourceId,
          reserveId: uniqueReserveIds,
        },
      }));
    }
 }

  private requestPhysicalAvailabilityInfo(recordId: string[]) {
    const uniqueRecordIds = Array.from(new Set(recordId).values());
    if (uniqueRecordIds.length) {
      this.store.dispatch(gatesLoadResourceCount({recordIds: uniqueRecordIds}));
    }
  }
}
