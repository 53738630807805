import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { CustomShowcaseState, getRemoveState } from '../../reducers/custom-showcase.reducer';
import { focusList, remove, resetRemoveState } from '../../actions/custom-showcase.actions';
import { CustomShowcase } from '../../models/custom-showcase';

@Component({
  selector: 'app-remove-custom-showcase-modal',
  templateUrl: './remove-custom-showcase-modal.component.html',
  styleUrls: ['./remove-custom-showcase-modal.component.scss'],
})
export class RemoveCustomShowcaseModalComponent implements OnInit, OnDestroy {

  public customShowcase: CustomShowcase;

  public pendingRemove: boolean;
  public displayError: boolean;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly store: Store<CustomShowcaseState>,
  ) {
    this.store.dispatch(resetRemoveState());
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.store.select(getRemoveState).subscribe((removeState) => {
        if (removeState?.removed) {
          this.activeModal.close();
          this.store.dispatch(resetRemoveState());
          this.store.dispatch(focusList());
        }

        if (removeState?.errorCode) {
          this.displayError = true;
        }

        this.pendingRemove = removeState?.loading || false;
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onClose(): void {
    this.activeModal.dismiss();
  }

  public onConfirm() {
    this.displayError = false;
    if (this.pendingRemove) {
      return;
    }

    this.store.dispatch(remove({
      id: this.customShowcase.id,
      createdFrom: this.customShowcase.createdFrom,
    }));
  }
}
