import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { KeycloakService } from './keycloak.service';
import { WindowRefService } from '../../services/window-ref.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private withoutHeaderUrls = [
    'api/customization/configuration/',
    'api/customization/configurations-extended/',
    'api/customization/customer-languages/',
    'api/customization/configurations/',
    'api/customization/logos/',
    'api/customization/material-types',
    'api/customization/item-statuses',
    'status',
    'api/search-result/customization/configurations-extended/',
  ];

  constructor(
    private readonly keycloakService: KeycloakService,
    private readonly windowRefService: WindowRefService,
    ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addToken(req)).pipe(
      catchError((error) => this.handleError(error, req, next)),
    );
  }

  private addToken(req: HttpRequest<any>): HttpRequest<any> {
    const url = req.url;
    let apiReq = req;

    const bearerToken = this.windowRefService.localStorage.bearerToken;
    const needToSetHeader = !this.withoutHeaderUrls.some((link) => url.startsWith(link));

    if (!url.match(/^http/) && bearerToken && needToSetHeader) {
      apiReq = apiReq.clone({
        headers: apiReq.headers.set('Authorization', `Bearer ${bearerToken}`),
      });
    }

    return apiReq;
  }

  private handleError(error: HttpErrorResponse, request?: HttpRequest<any>, next?: HttpHandler): Observable<HttpEvent<any>> {
    if (error.status !== 401 || request.url.includes('token/blacklist')) {
      return throwError(error);
    }

    return this.refreshAndRetry(request, next);
  }

  private refreshAndRetry(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Use Keycloak.KeycloakInstance.updateToken() method under the hood:
    // If the token expires within minValidity seconds, the token is refreshed otherwise it does nothing.
    // Keycloak.KeycloakInstance.updateToken() returns Promise<boolean>
    // to set functions that can be invoked if the token is still valid,
    // or if the token is no longer valid.
    return from(this.keycloakService.updateToken(false, 5)).pipe(
      switchMap(() => next.handle(this.addToken(request))),
      catchError((error) => throwError(error)),
    ) as Observable<HttpEvent<any>>;
  }
}
