<div class="save-search-modal-container p-4">
  <div class="modal-header">
    <h2 class="modal-title title mb-0"
        id="information-modal-title"
        data-automation-id="information-modal-title"
        tabindex="-1">
      <ng-container *ngIf="titleTranslateKey">{{ titleTranslateKey | translate }}</ng-container>
    </h2>
    <button type="button"
            class="close-btn p-0 m-n2"
            (click)="onClose()"
            [attr.aria-label]="'closeDialog' | translate"
            data-automation-id="information-modal-close-button">
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
  </div>

  <div class="modal-body" data-automation-id="information-modal-body">
    {{ bodyTranslateKey | translate }}
  </div>

  <div class="modal-footer justify-content-start">
    <button
      data-automation-id="information-modal-ok-button"
      (click)="onSubmit()"
      translate
      class="ins-buttons button-primary flint">ok
    </button>
  </div>
</div>
