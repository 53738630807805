import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { error } from 'core/utils/error-decorator';
import { Observable } from 'rxjs';
import { defaultGatesResourceCountResults, GatesResourceCountResults, } from '../../entity/models/gates-results';

@Injectable()
export class GatesService {
  private static readonly gatesUrl = 'api/gates-edge/gates';

  constructor(private readonly http: HttpClient) {
  }

  @error(defaultGatesResourceCountResults)
  public getResourceCountForRecordIds(recordIds: string[]): Observable<GatesResourceCountResults> {
    const url = `${GatesService.gatesUrl}/resource-count`;
    return this.http.get<GatesResourceCountResults>(url, {
      params: {
        bibIds: recordIds.join(','),
      },
    });
  }

}
