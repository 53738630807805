import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faListUl, faTh } from '@fortawesome/pro-regular-svg-icons';
import { ViewType } from '../../models/view';

@Component({
  selector: 'app-view-toggle',
  templateUrl: './view-toggle.component.html',
  styleUrls: ['./view-toggle.component.scss']
})
export class ViewToggleComponent {
  @Input() public view: ViewType;
  @Output() public viewToggled = new EventEmitter<ViewType>();
  public readonly viewType = ViewType;
  public readonly listIcon = faListUl;
  public readonly gridIcon = faTh;

  public onToggleView(view: ViewType) {
    this.viewToggled.emit(view);
  }
}
