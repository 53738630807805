import { ResourceInfo } from 'user/models/resource-item-data';
import { UserReceiptAction, UserReceiptCard } from 'user/models/user-receipt-card';
import { Vendor } from 'user/models/vendor';
import { VendorType } from '../../entity/models/entity';

export interface Hold {
  id: string;
  recordId: string;
  recordType: string;
  activationDate: string;
  neededBy: string;
  expirationDate: string;
  location: string;
  locationCode: string;
  status: HoldStatus;
  frozen: boolean;
  priority: number;
  priorityQueueLength: number;
  vendor: Vendor;
  resource: ResourceInfo;
  borrowByMail: boolean;
  allowedActions : string[];
}

export interface EContentHold {
  contentId: string;
  vendor: VendorType;
  holdPlaced: boolean;
}

export enum HoldStatus {
  ItemReadyToPickUp = 'i',
  BibReadyToPickUp = 'b',
  VolumeReadyToPickUp = 'j',
  OverdriveItemReadyToBorrow = 'r',
  ItemSentByMail = 's',
  InTransit = 't',
  Waiting = '0',
  Pending = '',
}

export enum HoldCardActionType {
  CancelHold = 'cancelHold',
  FreezeHold = 'freezeHold',
  UnfreezeHold = 'unfreezeHold',
  GetIt = 'getIt',
}

export type HoldCardAction = UserReceiptAction<HoldCardActionType>;

export type HoldCard = UserReceiptCard<Hold, HoldCardAction>;

export interface HoldCardActionPayload {
  actionType: HoldCardActionType;
  payload?: any;
}

export interface HoldsLoadingState {
  loading: boolean;
  error: HoldsError | null;
}

export interface HoldsState {
  loadingState: HoldsLoadingState;
  cards: HoldCard[];
  sortingCriteria: HoldSortingCriteria;
}

export interface HoldsError {
  status: number;
  message: string;
}

export type HoldSortingCriteria = 'status' | 'title';
