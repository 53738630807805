import { Component, Input } from '@angular/core';
import { CheckoutCard } from 'user/models/checkouts';

@Component({
  selector: 'app-bulk-renew-results',
  templateUrl: './bulk-renew-results.component.html',
  styleUrls: ['./bulk-renew-results.component.scss']
})
export class BulkRenewResultsComponent {
  @Input() checkouts: CheckoutCard[];
  @Input() title: string;
  @Input() isSuccess: boolean;

  constructor() { }
}
