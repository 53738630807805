import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OverDriveService } from 'core/over-drive/services/over-drive.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-overdrive-download-redirect-dialog',
  templateUrl: './overdrive-download-redirect-dialog.component.html',
  styleUrls: ['./overdrive-download-redirect-dialog.component.scss'],
})
export class OverdriveDownloadRedirectDialogComponent implements OnInit, OnDestroy {
  @Input() public reserveId: string;
  @ViewChild('iframe', {static: true}) public iframe: ElementRef;
  public error = false;
  private readonly subscription: Subscription = new Subscription();

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly overdriveService: OverDriveService,
  ) {
  }

  public ngOnInit() {
    this.subscription.add(this.overdriveService.getDownloadRedirectLink(this.reserveId)
    .subscribe(
      (response: { body: Blob, location: string }) => {
        const blob = new Blob([response.body], {type: 'text/html'});
        let objectUrl = URL.createObjectURL(blob);
        if (this.iframe) {
          this.iframe.nativeElement.src = objectUrl;
        }

        // do not clear too soon
        setTimeout(() => {
          if (objectUrl) {
            URL.revokeObjectURL(objectUrl);
            objectUrl = null;

            const baseTag = this.iframe.nativeElement.contentDocument.createElement('base');
            baseTag.setAttribute('href', response.location);
            this.iframe.nativeElement.contentDocument.getElementsByTagName('head')[0]?.appendChild(baseTag);
          }
        }, 100);
      },
      (error) => {
        console.error(error);
        this.error = true;
      },
    ));
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public dismissModal(): void {
    this.activeModal.dismiss();
  }
}
