import { Injectable } from '@angular/core';
import { CoverColor } from 'shared/models/cover';
import { hashCode } from 'shared/utils/hash-calculate';

@Injectable({
  providedIn: 'root'
})
export class CoverColorService {
  private readonly accessibleCover: CoverColor[] = [
    {
      name: 'teal',
      background: '#038486',
      text: '#FFFFFF'
    },
    {
      name: 'blue',
      background: '#1A66AE',
      text: '#FFFFFF'
    },
    {
      name: 'green',
      background: '#0C7E11',
      text: '#FFFFFF'
    },
    {
      name: 'orange',
      background: '#C94F08',
      text: '#FFFFFF'
    },
    {
      name: 'purple',
      background: '#8E43AB',
      text: '#FFFFFF'
    },
    {
      name: 'yellow',
      background: '#FAC22A',
      text: '#34485E'
    },
  ];

  constructor() { }

  public pickColorBasedOnHash(title: string | undefined | null): CoverColor {
    const colorIndex = hashCode(title) % this.accessibleCover.length;
    return this.accessibleCover[colorIndex];
  }
}
