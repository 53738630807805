import { createAction, props } from '@ngrx/store';
import { SearchResult } from 'search/models/search-results';
import { AccountTabs } from 'user/models/bookshelf';
import { Checkout, CheckoutCard, CheckoutCardActionType, CheckoutsError, CheckoutSortingCriteria, } from 'user/models/checkouts';
import { FineError, FinesData, FinesPaymentStatus } from 'user/models/fine';
import { GetItPlaceHoldData, GetItPlaceTabHold, ItemVolume, Volume } from 'user/models/get-it';
import { Hold, HoldCardActionType, HoldsError, HoldSortingCriteria, } from 'user/models/holds';
import { PatronPasscodePayload } from 'user/models/passcode';
import { ProfileUpdate, ProfileUpdated, ProfileUpdateError, StaffUser, User } from 'user/models/user';
import { Vendor } from 'user/models/vendor';
import { GetItRequest, MaterialTypeCardInfo, MaterialTypeFullResourceCardView, } from '../../entity/models/entity';
import { LocationWithName } from '../../models/locations';

export const stillActive = createAction(
  '[User] Still Active',
);

export const endGuestSession = createAction(
  '[User] End guest session',
);

export const collapseBookshelf = createAction(
  '[Bookshelf] Collapse bookshelf',
);

export const stopCollapseBookshelf = createAction(
  '[Bookshelf] Stop collapse bookshelf',
);

export const toggleBookmarks = createAction(
  '[Bookshelf] Toggle bookmarks',
);

export const stopToggleBookmarks = createAction(
  '[Bookshelf] Stop toggle bookmarks',
);

export const highlightHeaderBookmarkButton = createAction(
  '[Bookshelf] Highlight header bookmarks button',
);

export const stopHighlightHeaderBookmarkButton = createAction(
  '[Bookshelf] Stop highlight header bookmarks button',
);

export const holdRequested = createAction(
  '[Get It] Hold Request Sent',
  props<{ holdId: string, isSierra: boolean, skipLoadHolds?: boolean }>(),
);

export const checkoutRequested = createAction(
  '[Get It] Check out Request Sent',
  props<{ reserveId: string}>(),
);

export const beginHold = createAction(
  '[Get It] Begin placing hold',
  props<{ payload: GetItRequest | SearchResult | MaterialTypeFullResourceCardView | MaterialTypeFullResourceCardView[]}>(),
);

export const beginTabHold = createAction(
  '[Get It] Begin placing hold by Tab',
  props<{ payload: MaterialTypeCardInfo}>(),
);

export const redirectSuccess = createAction(
  '[Get It] Redirect success',
);

export const tabHoldNlb = createAction(
  '[Get It] Begin placing hold by Tab for Nlb',
  props<{ payload: MaterialTypeCardInfo}>(),
);

export const redirectNlbTabHoldFailure = createAction(
  '[Get It] redirect Nlb tab hold failure',
);

export const pickHoldVolume = createAction(
  '[Get It] Pick volume for hold',
  props<{ volumes: Volume[], pickupLocations: LocationWithName[] }>(),
);

export const pickHoldItemVolume = createAction(
  '[Get It] Pick item volume for hold',
  props<{ itemsAsVolumes: ItemVolume[], pickupLocations: LocationWithName[] }>(),
);

export const pickHoldLocation = createAction(
  '[Get It] Pick pickup location for hold',
  props<{
    itemVolume?: string,
    pickupLocations: LocationWithName[],
    recordNumber: string,
    recordType: string,
    volume?: string,
    usePublicApi?: boolean
  }>(),
);

export const beginChangeHoldLocation = createAction(
  '[Get It] Begin change hold pickup location',
  props<{ hold: Hold }>(),
);

export const changeHoldLocation = createAction(
  '[Get It] Change hold pickup location',
  props<{ hold: Hold, pickupLocations: LocationWithName[] }>(),
);

export const changeHoldLocationSuccess = createAction(
  '[Get It] Change hold pickup location success',
  props<{ holdId: string, pickupLocationName: string, locationCode: string }>(),
);

export const loadUser = createAction(
  '[User] Load user',
);

export const setUser = createAction(
  '[User] Set User Info',
  props<{ user: User}>(),
);

export const loadStaffUser = createAction(
  '[User] Load staff user',
);

export const setStaffUser = createAction(
  '[User] Set staff user',
  props<{ staffUser: StaffUser }>(),
);

export const loadCheckouts = createAction(
  '[User] Load checkouts',
);

export const loadCheckoutsSuccess = createAction(
  '[User] Load checkouts success',
  props<{ checkouts: Checkout[] }>(),
);

export const loadCheckoutsFailure = createAction(
  '[User] Load checkouts failure',
  props<{ error: CheckoutsError }>(),
);

export const resetCheckoutsState = createAction(
  '[User] Reset checkouts state',
);

export const loadHolds = createAction(
  '[User] Load holds',
);

export const loadHoldsSuccess = createAction(
  '[User] Load holds success',
  props<{ holds: Hold[] }>(),
);

export const loadHoldsFailure = createAction(
  '[User] Load holds failure',
  props<{ error: HoldsError }>(),
);

export const resetHoldsState = createAction(
  '[User] Reset holds state',
);

export const loadFines = createAction(
  '[User] Load fines',
);

export const loadFinesSuccess = createAction(
  '[User] Load fines success',
  props<{ finesData: FinesData }>(),
);

export const loadFinesFailure = createAction(
  '[User] Load fines failure',
  props<{ error: FineError }>(),
);

export const resetFinesState = createAction(
  '[User] Reset fines state',
);

export const openBookshelfAccountTab = createAction(
  '[User] Open Bookshelf Account tab',
  props<{ accountTab: AccountTabs }>(),
);

export const stopOpenBookshelfAccountTab = createAction(
  '[User] Stop open Bookshelf Account tab',
);

export const setFinesPaymentStatus = createAction(
  '[User] Set fines payment status',
  props<{ paymentStatus: FinesPaymentStatus }>(),
);

export const resetFinesPaymentStatus = createAction(
  '[User] Reset fines payment status',
);

export const checkoutCardActionRequest = createAction(
  '[User] Checkout card action request',
  props<{ checkoutId: string; actionType: CheckoutCardActionType; payload?: any; resourceId? : string }>(),
);

export const checkoutCardActionSuccess = createAction(
  '[User] Checkout card action success',
  props<{ checkoutId: string; actionType: CheckoutCardActionType }>(),
);

export const checkoutCardActionFailure = createAction(
  '[User] Checkout card action failure',
  props<{ checkoutId: string, actionType: CheckoutCardActionType, error: string }>(),
);

export const clearCheckoutCardActionRequest = createAction(
  '[User] Clear checkout card action request',
  props<{ checkoutId: string }>(),
);

export const updateCheckout = createAction(
  '[User] Update checkout',
  props<{ checkout: Checkout }>(),
);

export const removeCheckout = createAction(
  '[User] Remove checkout',
  props<{ checkoutId: string }>(),
);

export const changeCheckoutsSort = createAction(
  '[User] Change checkouts sort',
  props<{ sortingCriteria: CheckoutSortingCriteria }>(),
);

export const holdCardActionRequest = createAction(
  '[User] Hold card action request',
  props<{ actionType: HoldCardActionType, holdId: string, holdRecordId: string, vendor: Vendor, resourceId?: string }>(),
);

export const holdCardActionSuccess = createAction(
  '[User] Hold card action success',
  props<{ actionType: HoldCardActionType, holdId: string }>(),
);

export const holdCancelSuccess = createAction(
  '[User] Hold card cancel success',
  props<{ holdId: string, holdRecordId: string }>(),
);

export const holdFreezeSuccess = createAction(
  '[User] Hold card freeze success',
  props<{ holdId: string }>(),
);

export const holdUnfreezeSuccess = createAction(
  '[User] Hold card unfreeze success',
  props<{ holdId: string }>(),
);

export const holdCardActionFailure = createAction(
  '[User] Hold card action failure',
  props<{ actionType: HoldCardActionType, holdId: string, error: string }>(),
);

export const changeHoldsSort = createAction(
  '[User] Change holds sort',
  props<{ sortingCriteria: HoldSortingCriteria }>(),
);

export const updateProfile = createAction(
  '[User] Update profile',
  props<{ profileUpdate: ProfileUpdate }>(),
);

export const updateProfileSuccess = createAction(
  '[User] Update profile success',
  props<{ profileUpdated: ProfileUpdated }>(),
);

export const updateProfilePartialSuccess = createAction(
  '[User] Update profile partial success',
  props<{ profileUpdated: ProfileUpdated }>(),
);

export const updateProfileFailure = createAction(
  '[User] Update patron settings failure',
  props<{ error: ProfileUpdateError }>(),
);

export const resetUpdateProfileState = createAction(
  '[User] Reset update profile state',
);

export const openShowcasesTab = createAction(
  '[User] Open showcases tab',
);

export const showcasesTabOpened = createAction(
  '[User] Showcase tab opened',
);

export const openBookmarksTab = createAction(
  '[User] Open bookmarks tab',
);

export const bookmarksTabOpened = createAction(
  '[User] Bookmarks tab opened',
);

export const toggleOpenedUserPanel = createAction(
  '[User] Toggle opened user panel',
  props<{ isOpened: boolean }>(),
);

export const toggleOpenedLibraryInfoWidget = createAction(
  '[User] Toggle opened library info widget',
  props<{ isOpened: boolean }>(),
);

export const focusUserPanelButton = createAction(
  '[User] Focus user panel button',
);

export const stopFocusUserPanelButton = createAction(
  '[User] Stop focus user panel button',
);

export const focusLibraryInfoButton = createAction(
  '[User] Focus library info button',
);

export const stopFocusLibraryInfoButton = createAction(
  '[User] Stop focus library info button',
);

export const updateShouldCloseModal = createAction(
  '[User] Update shouldCloseModal',
  props<{ shouldCloseModal?: boolean, errorMessage?: string }>()
);


export const loadTabHold = createAction(
  '[User] Load tab hold',
);

export const loadTabHoldComplete = createAction(
  '[User] Load tab hold success',
);

export const tabHoldItemAsVolume = createAction(
  '[User] Pick item volume for tab hold',
  props<{ data: GetItPlaceTabHold, itemsAsVolumes: ItemVolume[]}>(),
);

export const tabHoldVolume = createAction(
  '[User] Pickvolume for tab hold',
  props<{ data: GetItPlaceTabHold, volumes: Volume[]}>(),
);

export const finalizeVolumeFlow = createAction(
  '[User] place hold tab item as volume',
  props<{ data: GetItPlaceHoldData }>(),
);

export const updatePatronPasscode = createAction(
  '[User] Update Patron Passcode',
  props<{ patronPasscodePayload: PatronPasscodePayload }>(),
);

export const bulkRenew = createAction(
  '[User] Bulk renew',
  props<{ cards: CheckoutCard[] }>(),
);

export const updatePatronPasscodeSuccess = createAction(
  '[User] Update Patron Passcode Success'
);

export const updatePatronPasscodeFailure = createAction(
  '[User] Update Patron Passcode Failure',
  props<{error: string}>(),
);

export const resetPatronPasscodeState = createAction(
  '[User] Reset Patron Passcode State',
);
