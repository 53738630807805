import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { faXTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faShareNodes } from '@fortawesome/pro-light-svg-icons';
import { WindowRefService } from 'app/services/window-ref.service';
import { FeatureToggleService } from 'app/services/feature-toggle.service';
import { ShareOnSocialMediaService, SocialMediaType } from '../../../services/share-on-social-media.service';

@Component({
  selector: 'app-share-icons-button',
  templateUrl: './share-icons-button.component.html',
  styleUrls: ['./share-icons-button.component.scss'],
})
export class ShareIconsButtonComponent implements OnInit {
  @Input() public smallButton = false;
  @Input() public bigButton = false;
  @Input() public disabled: boolean;
  @ViewChild('shareIconsToggleButton') public shareIconsToggleButton: ElementRef;
  @Input() url?: string;
  @Input() queryParams?: { [key: string]: string };
  @Input() title?: string;
  @Input() author?: string;

  public faFacebook = faFacebook;
  public faXTwitter = faXTwitter;
  public faShareNodes = faShareNodes;

  public shareTwitterUrl: string;
  public shareFacebookUrl: string;
  public isSocialMediaShareEnabled: boolean;

  constructor(
    private readonly windowRefService: WindowRefService,
    private readonly featureToggleService: FeatureToggleService,
    private readonly shareOnSocialMediaService: ShareOnSocialMediaService,
  ) {}

  ngOnInit() {
    this.isSocialMediaShareEnabled = this.featureToggleService.getToggles()['DIS-30364_2024-06-30_Social-Media-Share'];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.url || changes.queryParams) {
      this.constructShareUrl();
    }
  }

  private createTwitterShareUrl(title: string, url: string, origin: string, queryParamsString: string) {
    const twitterBaseUrl = 'https://twitter.com/intent/tweet?';
    const encodedText = encodeURIComponent(title || '');
    const twitterTextParam = title ? `text=${encodedText}&` : '';
    const twitterUrlParam = `url=${encodeURIComponent(origin + url + queryParamsString)}`;

    return `${twitterBaseUrl}${twitterTextParam}${twitterUrlParam}`;
  }

  private createFacebookShareUrl(url: string, origin: string, queryParamsString: string) {
    const facebookFullUrl = `${origin}${url}${queryParamsString}`;
    return this.shareOnSocialMediaService.socialMediaShareLinkHandler(SocialMediaType.FACEBOOK, facebookFullUrl);
  }

  private constructShareUrl() {
    const queryParamsString = Object.keys(this.queryParams)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(this.queryParams[key])}`)
      .join('&');

    const origin = this.windowRefService.nativeWindow().location.origin;
    this.shareTwitterUrl = this.createTwitterShareUrl(this.title, this.url, origin, queryParamsString);
    this.shareFacebookUrl = this.createFacebookShareUrl(this.url, origin, queryParamsString);
  }

  shareOnTwitter() {
    this.windowRefService.nativeWindow().open(this.shareTwitterUrl, '_blank');
  }

  shareOnFacebook() {
    this.windowRefService.nativeWindow().open(this.shareFacebookUrl, '_blank');
  }
}
