import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { areEqualDeeply } from 'shared/utils/are-equal-deeply';
import { notBlankValidator } from 'shared/utils/validators';
import { UserAddress } from 'user/models/user';

@Component({
  selector: 'app-profile-form-field-input-address',
  templateUrl: './profile-form-field-input-address.component.html',
  styleUrls: ['./profile-form-field-input-address.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ProfileFormFieldInputAddressComponent,
    multi: true,
  }, {
    provide: NG_VALIDATORS,
    useExisting: ProfileFormFieldInputAddressComponent,
    multi: true,
  }],
})
export class ProfileFormFieldInputAddressComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {
  @Input() public index: number;
  @Input() public touchForm$: Observable<undefined>;
  @Input() public formGroupLabel = '';
  @Input() public onFocus$: Subject<void>;
  @ViewChildren('addressLine1, addressLine2') addressLines: QueryList<ElementRef>;

  public line2Displayed: boolean;
  public readonly form: UntypedFormGroup;
  public ids: {
    line1: string;
    line1Error: string;
    line2: string;
    city: string;
    stateCode: string;
    postalCode: string;
  };
  private readonly subscriptions = new Subscription();
  private onChange: (_: UserAddress) => void;
  private onTouch: () => void;

  constructor(private readonly fb: UntypedFormBuilder, private translate: TranslateService) {
    this.form = this.fb.group({
      line1: new UntypedFormControl('', [notBlankValidator]),
      line2: [''],
      city: [''],
      stateCode: [''],
      postalCode: [''],
    });
  }

  public setFocusOnInputField() {
    setTimeout(() => {
      this.addressLines?.last?.nativeElement.focus();
    });
  }

  ngOnInit() {
    if (this.formGroupLabel) {
      this.formGroupLabel = `${this.formGroupLabel} ${this.translate.instant('addressLine1')}`;
    }

    this.subscriptions.add(
      this.onFocus$.subscribe(() => {
        this.addressLines?.last?.nativeElement.focus();
      })
    );
  }

  get line1(): UntypedFormControl {
    return this.form.get('line1') as UntypedFormControl;
  }

  get line2(): UntypedFormControl {
    return this.form.get('line2') as UntypedFormControl;
  }

  get city(): UntypedFormControl {
    return this.form.get('city') as UntypedFormControl;
  }

  get stateCode(): UntypedFormControl {
    return this.form.get('stateCode') as UntypedFormControl;
  }

  get postalCode(): UntypedFormControl {
    return this.form.get('postalCode') as UntypedFormControl;
  }

  public ngAfterViewInit(): void {
    this.subscriptions.add(
      this.form.valueChanges.pipe(distinctUntilChanged(areEqualDeeply)).subscribe((value) => {
        this.onChange(value);
      }),
    );

    this.subscriptions.add(
      this.touchForm$.subscribe(() => {
        this.form.markAllAsTouched();
      }),
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.index) {
      this.setHtmlIds(changes.index.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public displayLine2(): void {
    this.line2Displayed = true;
  }

  public registerOnChange(fn: (_: UserAddress) => void): void {
    this.onChange = fn;
  }

  public writeValue(value?: UserAddress): void {
    this.form.setValue(value || {
      line1: '',
      line2: '',
      city: '',
      stateCode: '',
      postalCode: '',
    });

    if (this.line2.value) {
      this.line2Displayed = true;
    }
  }

  public validate(): ValidationErrors | null {
    return this.form.valid ? null : {address: true};
  }

  private setHtmlIds(index: number): void {
    this.ids = {
      line1: `profile-card-address-${index}-line1`,
      line1Error: `profile-card-address-${index}-line1-error`,
      line2: `profile-card-address-${index}-line2`,
      city: `profile-card-address-${index}-city`,
      stateCode: `profile-card-address-${index}-state-code`,
      postalCode: `profile-card-address-${index}-postal-code`,
    };
  }
}
