<div class="p-4">
  <div class="modal-header">
    <h2 ngbAutofocus class="modal-title" data-automation-id="overdrive-get-it-failed-title" translate> getItErrorDialogTitle</h2>
    <button data-automation-id="close-get-it-dialog-btn" type="button" class="close-btn p-0"
      [attr.aria-label]="'closeDialog' | translate" (click)="activeModal.dismiss()">
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="status-section" data-automation-id="overdrive-get-it-failed-status-message">
      <fa-icon class="text-danger mr-1" [icon]="faExclamationCircle" size="4x"></fa-icon>
      <span class="status-message">
        {{'overDrivePasswordNotFoundException' | translate }}
      </span>
    </div>
  </div>
  <div class="modal-footer">
        <div class="actions-section">
          <button class="ins-buttons button-primary flint" (click)="signOut()" translate
            data-automation-id="sign-out-button" cdkMonitorElementFocus>
            signOut
          </button>
        </div>
  </div>
</div>
