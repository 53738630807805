<div class="ineligible-titles-modal p-0 p-sm-4" data-automation-id="ineligible-titles-modal-container">
  <app-modal-header
    title="{{'bulkHoldIneligibleForBulkHold' | translate}}"
    titleId="ineligible-titles-modal-title"
    closeId="ineligible-titles-modal-close-icon"
    (close)="onClose()">
  </app-modal-header>
  <div class="modal-body" data-automation-id="ineligible-titles-modal-body">
    <p data-automation-id="ineligible-titles-description">{{'bulkHoldIneligibleForBulkHoldDescription' | translate}}</p>
    <perfect-scrollbar style="max-height: 260px">
      <ul class="ineligible-titles-list m-0" data-automation-id="ineligible-titles-list">
        <li *ngFor="let ineligibleFormatGroup of ineligibleFormatGroups" class="mb-0 px-8 py-3">
          <app-bulk-hold-item-overview [formatGroup]="ineligibleFormatGroup"></app-bulk-hold-item-overview>
        </li>
      </ul>
    </perfect-scrollbar>
  </div>
  <div class="modal-footer justify-content-end">
    <button (click)="onClose()"
            class="ins-buttons button-secondary flint"
            data-automation-id="ineligible-titles-modal-close-btn">
      {{ 'bulkHoldClose' | translate }}
    </button>
  </div>
</div>
