import { Component, Input, OnDestroy, OnInit, } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store, } from '@ngrx/store';
import { SearchResultHelper } from 'core/utils/search-result-helper';
import { Subscription } from 'rxjs';
import { AboutTabDetails } from './components/details/details.component';
import { SearchResultResponse, } from '../../models/entity';
import { FullEntityState, selectInstanceAboutInfo, } from '../../reducers/full-entity.reducer';
import { SearchResult } from 'search/models/search-results';
import { AboutTabDescription } from './components/description/description.component';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { VendorLogo } from 'shared/models/configuration';

@Component({
  selector: 'app-about-popup',
  templateUrl: './about-popup.component.html',
  styleUrls: ['./about-popup.component.scss'],
})
export class AboutPopupComponent implements OnInit, OnDestroy {
  @Input() public tabLabel: string;
  public details: AboutTabDetails;
  public description: AboutTabDescription;
  public context: SearchResult;
  public dataIsNotEmpty: boolean;
  public loading = false;
  public nonIntegratedLogo: { [key: string]: VendorLogo }

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly searchResultHelper: SearchResultHelper,
    private readonly store: Store<FullEntityState>,
    private readonly ngbActiveModal: NgbActiveModal,
    private readonly configLoader: ConfigurationLoader
  ) {
  }

  public ngOnInit() {
    this.nonIntegratedLogo = this.configLoader.nonIntegratedConnectionsLogoMapping;
    this.subscriptions.add(this.store.pipe(select(selectInstanceAboutInfo)).subscribe((info) => {
      const infoElement = info[this.context.id] as SearchResultResponse;
      if (infoElement) {
        this.context = {
          ...this.context,
          ...infoElement.searchResult,
        };
        this.loading = infoElement.loading;
        this.extractDataForDescriptionAndDetailsComponents();
      }
    }));
  }

  public isNotEmpty(obj: any) {
    return obj != null && Object.keys(obj).some((key) => obj[key]);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public closePopup() {
    this.ngbActiveModal.close();
  }

  private extractDataForDescriptionAndDetailsComponents() {
    const aboutTab = this.searchResultHelper.getAboutTabData(this.context, this.nonIntegratedLogo);

    if (aboutTab) {
      this.description = aboutTab.description;
      this.details = aboutTab.details;
    }

    this.dataIsNotEmpty = this.isNotEmpty(this.description) || this.isNotEmpty(this.details);
  }
}
