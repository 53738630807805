<app-custom-dropdown
  class="item-volume-dropdown pb-2"
  data-automation-id="item-volume-list-sort"
  [aria-label]="'sortedBy' | translate"
  [selectedOption]="value?.sorting+':'+value?.sortOrder"
  (change)="onDropdownChange($event)">
  <span custom-dropdown-label translate>sortedBy</span>
  <app-custom-dropdown-option
    *ngFor="let option of sortingOptions"
    [value]="option.key"
    [id]="option.key"
    data-automation-id="item-volume-list-sort-option">
    {{ option.description | translate }}
  </app-custom-dropdown-option>
</app-custom-dropdown>
