<div class="delete-saved-search-modal-container p-4">
  <div class="modal-header">
    <h2 class="modal-title title text-capitalize"
        data-automation-id="delete-saved-search-modal-title"
        id="delete-saved-search-modal-title"
        translate>
      savedSearchDeleteConfirmation
    </h2>
    <button (click)="onClose()"
            [attr.aria-label]="'closeDialog' | translate"
            class="close-btn p-0 m-n2"
            data-automation-id="close-delete-saved-search-dialog-btn"
            type="button">
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
  </div>
  <div class="modal-body">
    <p data-automation-id="delete-saved-search-caution"
       id="delete-saved-search-caution">
      <ng-container *ngIf="canDeleteSavedSearch; else canNotDelete">
        {{ 'savedSearchDeleteCaution' | translate }}
      </ng-container>
      <ng-template #canNotDelete>
        {{ 'savedSearchDeleteErrorCannotDeleteRelatedShowcase' | translate }}
      </ng-template>
    </p>

    <div *ngIf="search.showcaseRef && canDeleteSavedSearch"
         class="delete-showcase-caution py-2 mt-5 mb-1"
         data-automation-id="delete-saved-search-showcase-caution">
      <fa-icon [icon]="exclamationCircle"></fa-icon>
      {{ 'savedSearchDeleteCautionShowcaseWillBeDeleted' | translate }}
    </div>

    <div aria-live="assertive" role="alert">
      <p *ngIf="displayError"
           class="error"
           data-automation-id="delete-saved-search-error">
        <ng-container [ngSwitch]="displayError">
          <ng-container *ngSwitchCase="errorEnum.showcase">
            {{'savedSearchDeleteErrorShowcase' | translate}}
          </ng-container>
          <ng-container *ngSwitchCase="errorEnum.showcaseRemovedButNotSavedSearch">
            {{'savedSearchDeleteErrorButShowcaseRemoved' | translate}}
          </ng-container>
          <ng-container *ngSwitchCase="errorEnum.savedSearch">
            {{'savedSearchDeleteError' | translate}}
          </ng-container>
        </ng-container>
      </p>
    </div>
    <div aria-live="polite" role="status">
      <div *ngIf="pendingDelete"
           class="spinner-border text-primary"
           data-automation-id="delete-saved-search-spinner">
        <span class="sr-only" translate>Loading...</span>
      </div>
    </div>
  </div>

  <div *ngIf="canDeleteSavedSearch" class="modal-footer justify-content-start">
    <button (click)="onConfirm()"
            class="ins-buttons button-primary flint"
            data-automation-id="delete-saved-search-modal-confirm-btn"
            type="button">
      <ng-container *ngIf="search.showcaseRef; else deleteOnlySavedSearch">
        {{'savedSearchDeleteWithShowcaseConfirmationButton' | translate}}
      </ng-container>
      <ng-template #deleteOnlySavedSearch>
        {{'deleteSavedSearchConfirmationButton' | translate}}
      </ng-template>
    </button>
    <button (click)="onClose()"
            class="ins-buttons button-secondary flint ml-3 text-uppercase"
            data-automation-id="delete-saved-search-modal-cancel-btn"
            translate>
      deleteSavedSearchKeepButton
    </button>
  </div>
</div>
