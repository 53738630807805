<perfect-scrollbar class="ps-show-always" scrollIndicators *ngIf="(holdsState$ | async) as holdsState" removeTabindexOnYScroll [ngStyle]="{'max-height': maxHeightPerfectScrollbar}">
  <div class="account-tab-content">
    <div class="page-title d-flex justify-content-between mb-3">
      <div data-automation-id="hold-page-title">
        <h2>{{'accountTabHolds' | translate}} |
          <span *ngIf="!holdsState.loadingState.loading" class="page-title-counter">{{holdsState.cards.length}}</span>
        </h2>
      </div>
      <app-sort-button
        *ngIf="holdsState.cards.length"
        [sortFields]="holdSorting"
        [currentSortField]="holdsState.sortingCriteria"
        (onSort)="sortHolds($event)">
      </app-sort-button>
    </div>

    <ng-container *ngIf="holdsState.loadingState.loading || holdsState.loadingState.error; else holds">
      <div class="d-flex justify-content-center text-white" role="status" aria-live="polite">
        <div *ngIf="holdsState.loadingState.loading"
             class="mt-5 spinner-border items-loading-spinner"
             data-automation-id="hold-items-loading-spinner">
          <span class="sr-only" translate>Loading...</span>
        </div>
      </div>
      <div aria-atomic="true" aria-live="assertive" role="alert">
        <div *ngIf="holdsState.loadingState.error"
             class="m-4 text-white"
             data-automation-id="hold-items-loading-error">
          {{'generalServerError' | translate}}
        </div>
      </div>
    </ng-container>
  </div>

  <ng-template #holds>
    <div class="page-body">
      <ol class="mb-0" data-automation-id="holds-list">
        <li *ngFor="let holdCard of holdsState.cards; trackBy: trackById"
            class="d-block mb-5 mt-2" data-automation-id="hold-list-card">
          <app-hold-card [card]="holdCard"></app-hold-card>
        </li>
      </ol>
    </div>
  </ng-template>
</perfect-scrollbar>
