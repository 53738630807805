import { Action, createReducer, on } from '@ngrx/store';
import { FacetActions } from 'search/actions/facet.actions';
import { TruncatedSearch, } from 'search/models/search-results';
import { RootState } from 'search/reducers/root.reducer';
import { VendorActions, } from '../../entity/actions/vendors.actions';
import {
  focusRefineResultsButton,
  focusResultsNumberButton,
  focusSortedByButton,
  resetElementTriggeredSearch,
  SEARCH_RESULTS_LOAD_COMPLETE,
  SEARCH_RESULTS_LOAD_FAILURE,
  SEARCH_RESULTS_PAGE_NAVIGATION,
  SEARCH_RESULTS_RESET,
  SEARCH_RESULTS_RESET_LOADING,
  SearchActions,
  setElementTriggeredSearch,
  setFilterPanelExpanded,
  setFilterPanelFullscreen,
  stopFocusRefineResultsButton,
  stopFocusResultsNumberButton,
  stopFocusSortedByButton,
  focusoutSearchInput,
  updateAdvancedMode,
} from '../actions/search.actions';

export enum ElementTriggeredSearch {
  NONE,
  RESULTS_NUMBER_BUTTON,
  SORTED_BY_BUTTON,
}

export interface SearchResultsMetadataState {
  totalResults: number;
  totalPages: number;
  alternativeSearch?: string;
  truncatedSearch?: TruncatedSearch;
}

export interface FilterPanelState {
  expanded: boolean;
  fullscreen: boolean;
}

export interface SearchState {
  searchResultsMetadata: SearchResultsMetadataState;
  lastSearchResultsUrl?: string;
  filterPanelState: FilterPanelState;
  doFocusRefineResultsButton: boolean;
  doFocusResultsNumberButton: boolean;
  doFocusSortedByButton: boolean;
  elementTriggeredSearch: ElementTriggeredSearch;
  currentSearchInput?: string;
  advancedMode: boolean;
}

export const initialSearchState: SearchState = {
  searchResultsMetadata: {
    totalResults: 0,
    totalPages: 0,
  },
  filterPanelState: {
    expanded: true,
    fullscreen: false,
  },
  doFocusRefineResultsButton: false,
  doFocusResultsNumberButton: false,
  doFocusSortedByButton: false,
  elementTriggeredSearch: ElementTriggeredSearch.NONE,
  advancedMode: false,
};

export type CombinedState = SearchState & RootState;

type SearchReducerActions = SearchActions | FacetActions | VendorActions;

const newSearchReducer = createReducer(
  initialSearchState as CombinedState,
  on(setFilterPanelExpanded, (state, {expanded}) => ({
    ...state,
    filterPanelState: {...state.filterPanelState, expanded},
  })),
  on(setFilterPanelFullscreen, (state, {fullscreen}) => ({
    ...state,
    filterPanelState: {
      ...state.filterPanelState,
      fullscreen,
      expanded: fullscreen ? true : state.filterPanelState.expanded,
    },
  })),
  on(focusRefineResultsButton, (state) => ({
    ...state,
    doFocusRefineResultsButton: true,
  })),
  on(stopFocusRefineResultsButton, (state) => ({
    ...state,
    doFocusRefineResultsButton: false,
  })),
  on(focusResultsNumberButton, (state) => ({
    ...state,
    doFocusResultsNumberButton: true,
  })),
  on(stopFocusResultsNumberButton, (state) => ({
    ...state,
    doFocusResultsNumberButton: false,
  })),
  on(focusSortedByButton, (state) => ({
    ...state,
    doFocusSortedByButton: true,
  })),
  on(stopFocusSortedByButton, (state) => ({
    ...state,
    doFocusSortedByButton: false,
  })),
  on(setElementTriggeredSearch, (state, {element}) => ({
    ...state,
    elementTriggeredSearch: element,
  })),
  on(resetElementTriggeredSearch, (state) => ({
    ...state,
    elementTriggeredSearch: ElementTriggeredSearch.NONE,
  })),
  on(focusoutSearchInput, (state, {query}) => ({
    ...state,
    currentSearchInput: query,
  })),
  on(updateAdvancedMode, (state, {advancedMode}) => ({
    ...state,
    advancedMode: advancedMode,
  })),
);

export const searchReducer = (genericAction: SearchReducerActions | Action) =>
  (state: CombinedState): CombinedState => {
  const action = genericAction as SearchReducerActions;
  switch (action.type) {

    case SEARCH_RESULTS_LOAD_COMPLETE: {
      const searchResultsMetadata = action.payload.results;
      const alternativeSearch = searchResultsMetadata.alternativeSearch ?
      searchResultsMetadata.alternativeSearch.replace(/<em>((.|\n)*?)<\/em>/gi, '<b>$1</b>') : null;
      return {
        ...state,
        loading: false,
        error: null,
        searchResultsMetadata: {
          ...searchResultsMetadata,
          alternativeSearch,
        },
      };
    }

    case SEARCH_RESULTS_LOAD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        searchResultsMetadata: {
          ...initialSearchState.searchResultsMetadata,
        },
      };
    }

    case SEARCH_RESULTS_RESET: {
      return {
        ...state,
        searchResultsMetadata: {
          ...initialSearchState.searchResultsMetadata,
        },
        searchObject: {
          ...state.searchObject,
          pagination: {
            ...state.searchObject.pagination,
            pageNum: 0,
          },
        },
      };
    }

    case SEARCH_RESULTS_RESET_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_RESULTS_PAGE_NAVIGATION: {
      return {
        ...state,
        lastSearchResultsUrl: action.payload,
      };
    }

    default: {
      return newSearchReducer(state, action as Action);
    }
  }
};
