import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import { GroupedLocationInfo, LocationAvailabilityStatus, } from 'search/models/search-results';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { CustomerIntegrationType } from '../../../customer-integration/customer-integration';

@Component({
  selector: 'ins-rollup-tabs-content-locations',
  templateUrl: './rollup-tabs-content-locations.component.html',
  styleUrls: ['./rollup-tabs-content-locations.component.scss'],
})
export class RollupTabsContentLocationsComponent implements OnInit {
  @Input() public locations: GroupedLocationInfo[];
  @Input() public displayAllLocationsLink = false;
  @Input() public title: string;
  @Output() public locationClicked$ = new EventEmitter<GroupedLocationInfo>(true);
  @Output() public allLocationsClicked$ = new EventEmitter<undefined>(true);

  public readonly locationAvailabilityStatusEnum = LocationAvailabilityStatus;
  public readonly availableIcon = faCheckCircle;
  public readonly unavailableIcon = faTimesCircle;
  public readonly locationIcon = faMapMarkerAlt;
  public isNlbCustomer: boolean;

  public trackByCode(index: number, locationInfo: GroupedLocationInfo) {
    return locationInfo.label;
  }

    constructor(
    private readonly configLoader: ConfigurationLoader,
  ) { }

  ngOnInit(): void {
    this.isNlbCustomer = this.configLoader.customerIntegrationType === CustomerIntegrationType.NLB;
  }
}
