import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { isAuthorizedInKeycloak } from 'app/keycloak/reducers/keycloak.reducer';
import { Subscription } from 'rxjs';
import { CommonState } from 'search/full-card/models/card-header';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { toggleOpenedUserPanel } from 'user/actions/user-profile.actions';
import { CustomerIntegrationType } from '../../../customer-integration/customer-integration';
import {
  AvailabilityStatus,
  AvailabilityStatusMap,
  AvailabilityVendorType,
  EntityTypes,
  GetItRequest,
  HoldRequest,
  Reserve,
  VendorType,
} from '../../models/entity';
import { GetItModalContentComponent } from '../get-it-modal-content/get-it-modal-content.component';

enum GetItMode {
  SingleMode,
  MultipleMode,
}

@Component({
  selector: 'app-toolbar-buttons',
  templateUrl: './toolbar-buttons.component.html',
  styleUrls: ['./toolbar-buttons.component.scss'],
})
export class ToolbarButtonsComponent implements OnInit, OnDestroy {

  @Input() public instanceId: string;
  @Input() public label: string;
  @Input() public recordId: string[];
  @Input() public isElectronicResource: boolean;
  @Input() public eResourceUrls: string[];
  @Input() public tabName: string;
  @Input() public hasEcontentIssues: boolean;
  @Input() public availability: AvailabilityStatusMap;
  @Input() public reserve: Reserve;
  @Input() public eVendorAvailabilityPending = false;

  @Input() public type = 'primary';
  @Input() public resourceId: string;
  @Input() public title: string;
  @Input() public getOneEdition = false;
  @Input() public ariaDescribedBy = '';
  @Output() public getIt$ = new EventEmitter<{ item: GetItRequest }>(true);
  @Output() public placeHold$ = new EventEmitter<{ item: HoldRequest }>(true);
  @Output() public checkLocationAndEditionInfo$ = new EventEmitter<{ resourceId: string }>(true);
  public subscriptions = new Subscription();
  public isAuthorised$ = this.store.select(isAuthorizedInKeycloak);
  public isAuthorised: boolean = false;
  public availabilityStatus = AvailabilityStatus;
  public vendorType = AvailabilityVendorType;
  public modes = GetItMode;
  public mode: GetItMode;
  public isNlbCustomer: boolean;

  constructor(
    private readonly store: Store<CommonState>,
    private readonly dialog: NgbModal,
    private readonly configLoader: ConfigurationLoader,
  ) { }

  public ngOnInit() {
    this.mode = this.recordId && this.recordId.length > 1 ? GetItMode.MultipleMode : GetItMode.SingleMode;
    this.isAuthorised$.subscribe(isAuthorised => this.isAuthorised = isAuthorised);
    this.isNlbCustomer = this.configLoader.customerIntegrationType === CustomerIntegrationType.NLB;
  }

  public openGetItPicker(event: Event) {
    event.stopPropagation();

    if (this.isAuthorizationRequiredForAvailability() && !this.isAuthorised) {
      this.store.dispatch(toggleOpenedUserPanel({isOpened: true}));
      return;
    }

    if (this.isDisplayElectronicLocators()) {
      if (this.eResourceUrls && this.eResourceUrls.length) {
        if (this.eResourceUrls.length > 1) {
          const modalRef = this.dialog.open(GetItModalContentComponent, {windowClass: undefined});
          modalRef.componentInstance.context = {
            urls: this.eResourceUrls,
          };
        } else {
          window.open(this.eResourceUrls[0], '_blank');
        }
      }
    } else {
      this.checkLocationAndEditionInfo$.emit();
    }
  }

  private isAuthorizationRequiredForAvailability(): boolean {
    return [VendorType.CLOUD_LIBRARY].includes(this.reserve?.vendorName);
  }

  public getIt(event: Event) {
    event.stopPropagation();
    if (!this.isElectronicResource) {
      this.getItForOnePhysicalEdition();
    } else if (this.reserve?.id) {
      this.getItForOneElectronicEdition(this.reserve.id, this.reserve.vendorName);
    } else {
      this.checkLocationAndEditionInfo$.emit();
    }
  }


  public placeHold(event: Event) {
    event.stopPropagation();
    if (this.mode === GetItMode.SingleMode) {
      this.placeHoldForOneEdition();
    } else {
      this.checkLocationAndEditionInfo$.emit();
    }
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private getItForOneElectronicEdition(reserveId: string, vendor: VendorType) {
    const getItRequest = {
      item: {
        id: this.instanceId,
        entityType: EntityTypes.FORMAT_GROUP,
        formatGroupId: this.resourceId,
        instanceId: this.instanceId || null,
        reserveId,
        vendor,
        hasEcontentIssues: this.hasEcontentIssues,
      },
    };
    this.getIt$.emit(getItRequest);
  }

  private getItForOnePhysicalEdition() {
    const getItRequest = {
      item: {
        hasEcontentIssues: this.hasEcontentIssues,
        id: this.instanceId,
        recordId: this.recordId[0],
        vendor: '',
        usePublicApi: this.availability?.[AvailabilityVendorType.GENERAL] === this.availabilityStatus.ON_ORDER,
      },
    };
    this.getIt$.emit(getItRequest);
  }

  private placeHoldForOneEdition() {
    const holdRequest = {
      item: {
        id: this.instanceId,
        reserveId: this.reserve?.id,
        vendor: this.reserve?.id ? this.reserve?.vendorName || '' : '',
      },
    };
    this.placeHold$.emit(holdRequest);
  }

  private isDisplayElectronicLocators(): boolean {
    if (this.recordId && this.recordId.length > 1) {
      const electronicVendorPresent = !!this.reserve?.id;
      const electronicVendorUnavailable = !this.availability
        || this.availability?.[AvailabilityVendorType.ELECTRONIC_VENDOR] === this.availabilityStatus.UNAVAILABLE;

      if (electronicVendorPresent && electronicVendorUnavailable) {
        if (this.recordId.length > 2) {
          return false;
        }
      } else {
        return false;
      }
    }

    return true;
  }
}
