import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { emailEntity, resetEntityEmailState } from '../../actions/share-it.actions';
import { ShareItResource } from '../../models/share-it';
import { getEmailEntityState, ShareItState } from '../../reducers/share-it.reducer';

const emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$';

@Component({
  selector: 'app-email-entity-modal',
  templateUrl: './email-entity-modal.component.html',
  styleUrls: ['./email-entity-modal.component.scss'],
})
export class EmailEntityModalComponent implements OnInit, OnDestroy {
  @Input() public defaultSubject: string;
  @Input() public resource: ShareItResource;
  public emailEntityForm: UntypedFormGroup;
  public isSending: boolean;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly store: Store<ShareItState>,
    private readonly formBuilder: UntypedFormBuilder,
  ) {
    this.store.dispatch(resetEntityEmailState());
    this.emailEntityForm = this.formBuilder.group({
      to: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern(emailPattern),
      ]],
      subject: ['', [Validators.required]],
    });
  }

  public ngOnInit(): void {
    this.subject.setValue(this.defaultSubject);
    this.subscriptions.add(
      this.store.select(getEmailEntityState).subscribe((emailState) => {
        if (emailState) {
          if (emailState.error) {
            if (emailState.error.notFound) {
              this.emailEntityForm.setErrors({ notFound: { message: 'Not found' } });
            } else {
              this.emailEntityForm.setErrors({ unknown: { message: 'General server error' } });
            }
          }

          if (emailState.sent) {
            this.activeModal.close();
          }
        }

        this.isSending = emailState?.sending || false;
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onClose(): void {
    this.activeModal.dismiss();
  }

  public onSubmit(): void {
    this.to.markAsTouched();
    this.subject.markAsTouched();
    if (!this.emailEntityForm.valid || this.isSending) {
      return;
    }

    this.store.dispatch(emailEntity({
      body: {
        to: this.to.value,
        subject: this.subject.value,
        resource: this.resource,
      },
    }));
  }

  public get to(): AbstractControl {
    return this.emailEntityForm.get('to');
  }

  public get subject(): AbstractControl {
    return this.emailEntityForm.get('subject');
  }

  public submitIfEnter(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.onSubmit();
    }
  }
}
