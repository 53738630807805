import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { stillActive } from 'user/actions/user-profile.actions';

import { UserState } from 'user/reducers/user.reducer';

@Injectable()
export class ActiveInterceptor implements HttpInterceptor {

  constructor(private userStore: Store<UserState>) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.userStore.dispatch(stillActive());
    return next.handle(req);
  }
}
