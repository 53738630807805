import { Component } from '@angular/core';
import { faConveyorBeltAlt } from '@fortawesome/pro-solid-svg-icons';
import { faBook } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-custom-showcase-display-on-box',
  templateUrl: './custom-showcase-display-on-box.component.html',
  styleUrls: ['./custom-showcase-display-on-box.component.scss'],
})
export class CustomShowcaseDisplayOnBoxComponent {
  public readonly bookIcon = faBook;
  public readonly showcaseIcon = faConveyorBeltAlt;
}
