import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MAORI_FAKE, MAORI_REAL } from 'shared/locale';
import { TranslationStrings } from './models/translation';

@Injectable({
  providedIn: 'root'
})

export class InspireTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  public getTranslation(lang: string) {
    lang = lang === MAORI_FAKE ? MAORI_REAL : lang;
    return this.http.get<TranslationStrings>('api/search-result/customization/translations', {
      headers: {'accept-language': `${lang}`},
    }).pipe(
      catchError(() => of()),
    );
  }
}

