<div class="d-inline-block bookmark-button">
  <button
    (click)="onToggleBookmark()"
    class="app-button button-labelled position-relative nlb-bookmark"
    [class.button-disabled]="toggleState?.loading"
    [attr.data-automation-id]="isSaved ? 'nlb-remove-bookmark-button' : 'nlb-add-bookmark-button'"
    aria-haspopup="dialog"
    [attr.aria-label]="(isSaved ? 'savedRemoveFromSaved' : 'saveToBookmarks') | translate"
    [attr.aria-pressed]="isSaved"
    [attr.aria-describedby]="ariaDescribedBy">
    <span class="icon fa-bookmark" [class]="(isSaved ? 'fas' : 'far')" aria-hidden="true"></span>
    <span class="text text-capitalize">{{ (isSaved ? 'saved' : 'bookmark') | translate }}</span>

    <div *ngIf="toggleState?.loading" role="status" aria-live="polite" class="position-absolute nlb-bookmark-loading">
    <span class="spinner-border text-primary nlb-bookmark-spinner" data-automation-id="nlb-bookmark-spinner">
      <span class="sr-only" translate>Loading...</span>
    </span>
    </div>

    <div *ngIf="toggleState?.error" role="alert" aria-live="assertive" class="position-absolute nlb-bookmark-error">
      <div class="text-danger" data-automation-id="nlb-bookmark-error">
        <span class="fas fa-exclamation-circle" aria-hidden="true"></span>
        <span class="sr-only">{{'generalServerError' | translate}}</span>
      </div>
    </div>
  </button>
</div>
