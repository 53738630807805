import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faSearchPlus } from '@fortawesome/pro-regular-svg-icons';
import { CutTextWithCertainHeightComponent } from 'common/components/cut-text-with-certain-height/cut-text-with-certain-height.component';
import { CustomShowcaseCreatedFromType, CustomShowcaseMetadataIterable, CustomShowcaseSingle } from '../../models/custom-showcase';
import { faBookmark, faConveyorBeltAlt } from '@fortawesome/pro-solid-svg-icons';
import {
  aboutShowcaseTranslations,
  metadataDisplayOnTranslations,
  listMetadataSortOrderTranslations
} from '../../models/custom-showcase.translations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-custom-showcase-settings-header',
  templateUrl: './custom-showcase-settings-header.component.html',
  styleUrls: ['./custom-showcase-settings-header.component.scss'],
})
export class CustomShowcaseSettingsHeaderComponent implements OnInit {
  @Input() showcase: CustomShowcaseSingle;
  @Input() isShowcaseCreator: boolean;
  @Output() public onShowcaseTypeClick = new EventEmitter<void>();
  public displayOnTranslations: string[] = [];
  public aboutShowcaseTranslations: string[] = [];
  public showcaseTypeEnum = CustomShowcaseCreatedFromType;
  @ViewChild(CutTextWithCertainHeightComponent) cutTextWithCertainHeight: CutTextWithCertainHeightComponent;

  public readonly searchIcon = faSearchPlus;
  public readonly bookmarksIcon = faBookmark;
  public readonly showcaseIcon = faConveyorBeltAlt;
  public readonly metadataSortOrderTranslation = listMetadataSortOrderTranslations;

  constructor(
    private readonly translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    const metadata = this.showcase.metadata;
    const typeTranslations = this.getTranslatedMetadata(metadata.aboutShowcase.type, aboutShowcaseTranslations.type);
    const ageTranslations = this.getTranslatedMetadata(metadata.aboutShowcase.age, aboutShowcaseTranslations.age);
    const formatTranslations = this.getTranslatedMetadata(metadata.aboutShowcase.format, aboutShowcaseTranslations.format);

    this.aboutShowcaseTranslations = [...typeTranslations, ...ageTranslations, ...formatTranslations];
    this.displayOnTranslations = this.getTranslatedMetadata(metadata.displayOn, metadataDisplayOnTranslations);
  }

  private getTranslatedMetadata(
    metadataObject: { [key in keyof CustomShowcaseMetadataIterable]: boolean },
    translations: { [key: string]: string },
  ): string[] {
    const translatedMetadata: string[] = [];
    Object.keys(metadataObject).forEach((key) => {
      if ((metadataObject as any)[key]) {
        translatedMetadata.push(this.translateService.instant(translations[key]));
      }
    });
    return translatedMetadata;
  }
}
