<section class="header-bottom">
  <ng-container *ngIf="links?.length">
    <button class="all-links-button" data-automation-id="all-links-button" (click)="openGlobalSidebar()">
      <fa-icon class="all-links-button-icon" [icon]="allLinksIcon" aria-hidden="true"></fa-icon>

      <span class="all-links-button-text">{{'allLinksButton' | translate}}</span>
    </button>

    <nav class="custom-links" data-automation-id="header-links">
      <a *ngFor="let link of links" class="custom-link" [href]="link.href" target="_blank">{{link.title}}</a>
    </nav>
  </ng-container>

  <div class="search-bar-container">
    <div
      *ngIf="isWiderThanXs; else reversedSmallTmp"
      ngbDropdown
      placement="bottom-right"
      class="search-dropdown"
      (openChange)="onSearchDropdownToggle($event)"
    >
      <button
        ngbDropdownToggle
        class="search-dropdown-toggle"
        data-automation-id="search-dropdown-toggle-button"
        [attr.aria-label]="'toggleSearchPanel' | translate"
      >
        <fa-icon class="search-dropdown-toggle-icon" [icon]="searchIcon" aria-hidden="true"></fa-icon>
      </button>

      <div ngbDropdownMenu class="search-dropdown-menu">
        <app-search-bar searchStyle="compact"></app-search-bar>
      </div>
    </div>

    <ng-template #reversedSmallTmp>
      <app-search-bar searchStyle="reversedSmall"></app-search-bar>
    </ng-template>
  </div>
</section>
