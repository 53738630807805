<form id="bookshelf-search-form"
      data-automation-id="bookshelf-search-form"
      class="search-form"
      [formGroup]="searchForm"
      (ngSubmit)="search()">
  <div class="d-flex">
    <input formControlName="searchText"
           class="form-control search-form__text-input"
           [placeholder]="placeholder"
           [attr.aria-label]="'bookshelfSearch' | translate"
           data-automation-id="bookshelf-search-text"/>
    <button *ngIf="searchText.value"
            [attr.aria-label]="'bookshelfClearSearch' | translate"
            (click)="clearSearch()"
            data-automation-id="bookshelf-clear-search-button"
            type="button"
            class="search-form__clear-button px-3">
      <fa-icon [icon]="timesIcon"></fa-icon>
    </button>
    <button type="submit"
            form="bookshelf-search-form"
            [attr.aria-label]="'bookshelfStartSearch' | translate"
            data-automation-id="bookshelf-search-button"
            class="search-form__search-button pr-1 pl-2">
      <fa-icon [icon]="searchIcon"></fa-icon>
    </button>
  </div>
</form>
