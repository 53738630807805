import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CombinedToggleData,
  CustomerFeatureToggleKey,
  customerFeatureToggles,
  FeatureToggleServiceLibrary,
  LicenseToggleKey,
} from '../models/feature-toggle';
import { License } from '../models/license';

export const FEATURE_TOGGLE_SERVICE_LIBRARY = new InjectionToken<FeatureToggleServiceLibrary>('feature-toggle-service');

@Injectable({
  providedIn: 'root',
})
export class FeatureToggleService {
  private readonly licenseMapping: ReadonlyMap<License, LicenseToggleKey> = new Map([
    [License.PERSONAL_LISTS, 'licensePersonalLists'],
    [License.SAVED_SEARCHES, 'licenseSavedSearches'],
    [License.CUSTOM_SHOWCASES, 'licenseCustomShowcases'],
    [License.READING_HISTORY, 'licenseReadingHistory'],
    [License.DISCOVERY, 'licenseDiscovery'],
    [License.DISCOVER_PLUS, 'licenseDiscoverPlus'],
    [License.DISCOVER_PREMIUM, 'licenseDiscoverPremium'],
    [License.DISCOVER_CONSORTIA, 'licenseDiscoverConsortia'],
  ]);

  constructor(@Inject(FEATURE_TOGGLE_SERVICE_LIBRARY) private readonly featureToggleService: FeatureToggleServiceLibrary) {
  }

  public initToggles(licenses: License[], customerFeatures: CustomerFeatureToggleKey[]): void {
    const licenseData = this.mapLicenseData(licenses);
    const customerFeatureData = this.mapCustomerFeaturesData(customerFeatures);
    this.featureToggleService.set({...licenseData, ...customerFeatureData});
  }

  public getToggles(): CombinedToggleData {
    return [
      ...this.licenseMapping.values(),
      ...customerFeatureToggles
    ].reduce((toggleData: CombinedToggleData, toggle: LicenseToggleKey) => {
      toggleData[toggle] = this.featureToggleService.isOn(toggle);

      return toggleData;
    }, {});
  }

  private mapLicenseData(licenses: License[]): CombinedToggleData {
    const data: CombinedToggleData = {};
    this.licenseMapping.forEach((toggle, license) => {
      data[toggle] = licenses.includes(license);
    });
    return data;
  }

  private mapCustomerFeaturesData(customerFeatures: CustomerFeatureToggleKey[]): CombinedToggleData {
    const data: CombinedToggleData = {};
    customerFeatureToggles
      .filter(toggle => customerFeatures.includes(toggle))
      .forEach((toggle) => {
        data[toggle] = true;
      });
    return data;
  }
}
