import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadVisibleLocations, setDefaultLibraryCode } from 'core/actions/library-info.actions';
import { getVisibleLocations } from 'core/reducers/library-info.reducer';
import { combineLatest, Subscription } from 'rxjs';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { stopFocusLibraryInfoButton, toggleOpenedLibraryInfoWidget, toggleOpenedUserPanel } from 'user/actions/user-profile.actions';
import {
  getDoFocusLibraryInfoButton,
  getLibraryInfoWidgetState,
  getUserHomeLibraryCode,
  getUserPanelState
} from 'user/reducers/user.reducer';
import { LocationWithName } from '../../../models/locations';


@Component({
  selector: 'app-library-info-button',
  templateUrl: './library-info-button.component.html',
  styleUrls: ['./library-info-button.component.scss']
})
export class LibraryInfoButtonComponent implements OnInit, OnDestroy {
  public libraryInfoConfigEnabled: boolean;
  public defaultLibraryCode: string;
  public guestLibraryCode: string;
  public isUserPanelShown: boolean;
  public isLibraryInfoShown: boolean;
  private readonly subscriptions: Subscription = new Subscription();
  @ViewChild('libraryInfoButton') public libraryInfoButton: ElementRef;

  constructor(
    private readonly store: Store,
    private readonly configLoader: ConfigurationLoader,
  ) {
  }

  public ngOnInit() {
    this.store.dispatch(loadVisibleLocations({configurationId: this.configLoader.config.libraryInfoWidget?.libraryInfo.configurationId}));
    this.libraryInfoConfigEnabled = this.configLoader.libraryInformationEnabled;
    this.guestLibraryCode = this.configLoader.config.libraryInfoWidget?.libraryInfo.defaultLocationCode;

    this.subscriptions.add(
      combineLatest([
        this.store.select(getUserHomeLibraryCode),
        this.store.select(getVisibleLocations),
      ]).subscribe(([homeLibraryCode, locations]) => {
        this.defineLibraryCode(homeLibraryCode, locations);
      }),
    );

    this.subscriptions.add(this.store.select(getUserPanelState).subscribe((isOpened) => {
      this.isUserPanelShown = isOpened;
    }));

    this.subscriptions.add(this.store.select(getLibraryInfoWidgetState).subscribe((isOpened) => {
      this.isLibraryInfoShown = isOpened;
    }));

    this.subscriptions.add(this.store.select(getDoFocusLibraryInfoButton).subscribe((focusLibraryInfoButton) => {
      if (focusLibraryInfoButton) {
        this.libraryInfoButton?.nativeElement.focus();
        this.store.dispatch(stopFocusLibraryInfoButton());
      }
    }));
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public toggleLibraryInfo(event: Event) {
    event.stopPropagation();
    if (this.isUserPanelShown) {
      this.store.dispatch(toggleOpenedUserPanel({isOpened: false}));
    }
    this.store.dispatch(toggleOpenedLibraryInfoWidget({isOpened: !this.isLibraryInfoShown}));
  }

  private defineLibraryCode(homeLibraryCode: string, locations: LocationWithName[]) {
    const userLibraryCodeToPreselect = homeLibraryCode && locations?.some((el) => el.code === homeLibraryCode)
      ? homeLibraryCode
      : null;

    const guestLibraryCodeToPreselect = this.guestLibraryCode && locations?.some((el) => el.code === this.guestLibraryCode)
      ? this.guestLibraryCode
      : null;

    const firstLocationCode = locations?.[0]?.code || null;

    this.defaultLibraryCode = userLibraryCodeToPreselect || guestLibraryCodeToPreselect || firstLocationCode;
    this.store.dispatch(setDefaultLibraryCode({defaultLibraryCode: this.defaultLibraryCode}));
  }
}
