<div class="confirmation-container p-3 position-relative" #confirmationContainer
  attr.data-close-confirmation-dialog-message="{{ 'confirmationDialogCloseMessage' | translate }}">
  <div class="modal-header">
    <h1 tabindex="-1" class="modal-title confirmation-title" cdkFocusInitial ngbAutofocus [ngSwitch]="context.action">
      <ng-container *ngSwitchCase="holdActionType.CancelHold">
        {{ 'cancelHoldConfirmationTitle' | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="checkoutActionType.CheckIn">
        {{ 'checkInConfirmationTitle' | translate }}
      </ng-container>
    </h1>

    <button type="button" class="close-btn p-0" data-dismiss="modal"
            (click)="activeModal.close()"
            [announce]="confirmationContainer.dataset['closeConfirmationDialogMessage']"
            aria-label="Close dialog"
            data-automation-id="confirmationCloseButton">
      <span aria-hidden="true" class="icon icon-close p-1"></span>
    </button>
  </div>

  <div class="modal-body">
    <p class="confirmation-message" #confirmationMessage>
      <ng-container [ngSwitch]="context.action">
        <span *ngSwitchCase="holdActionType.CancelHold"
              translate
              attr.data-cancel-hold-action-message="{{ 'renewHoldsConfirmationMessage' | translate }}">
          cancelHoldConfirmationMessage
        </span>
        <span *ngSwitchCase="checkoutActionType.CheckIn" translate
              attr.data-check-in-action-message="{{ 'checkInConfirmationMessage' | translate }}">
          checkInConfirmationMessage
        </span>
      </ng-container>
    </p>
  </div>

  <div class="modal-footer justify-content-start">
    <button type="button" class="ins-buttons button-primary flint" data-automation-id="confirmationConfirmButton"
            (click)="activeModal.close(true)"
      [announce]="confirmationAnnounceMessage" [ngSwitch]="context.action">
      <ng-container *ngSwitchCase="checkoutActionType.CheckIn">
        {{ 'checkInButton' | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="holdActionType.CancelHold">
        {{ 'cancelHoldButton' | translate }}
      </ng-container>
    </button>
    <button type="button" class="ins-buttons button-secondary flint ml-3" (click)="activeModal.close()"
      [announce]="confirmationContainer.dataset['closeConfirmationDialogMessage']" [ngSwitch]="context.action">
      <ng-container *ngSwitchCase="holdActionType.CancelHold">
        {{ 'keepHoldButton' | translate }}
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ 'cancelButton' | translate }}
      </ng-container>
    </button>
  </div>
</div>
