<div class="no-found" [ngClass]="{'availability': tabType === tabs.availability}">
  <h3 class="header" translate [translateParams]="{ tabType: tabType, cardType: cardType }"
    data-automation-id="no-tab-data-header">
    noDataTabTitle
  </h3>

  <div class="label" [ngSwitch]="tabType">
    <ng-container *ngIf="title">
      <ng-container *ngSwitchCase="tabs.availability">
        {{ 'availabilityDataAbsentFor' | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="tabs.about">
        {{ 'aboutDataAbsentAbout' | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="tabs.relationships">
        {{ 'relationshipsDataAbsentFor' | translate }}
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!title">
      <ng-container *ngSwitchCase="tabs.availability">
        {{ 'availabilityDataAbsent' | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="tabs.about">
        {{ 'aboutDataAbsent' | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="tabs.relationships">
        {{ 'relationshipsDataAbsent' | translate }}
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <span *ngIf="title" translate [translateParams]="{ tabType: tabType }">
        agentOrConceptDataAbsent
      </span>
      <span *ngIf="!title" translate [translateParams]="{ tabType: tabType }">
        relatedDataAbsent
      </span>
      <span *ngIf="title" translate [translateParams]="{ tabType: tabType }">
        infoDataAbsent
      </span>
    </ng-container>
  </div>

  <div *ngIf="title" class="title">'{{title}}'</div>
</div>
