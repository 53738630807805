import { LibraryHasConfiguration } from 'shared/models/library-has-configuration';

const LINE_LIMIT = 6;

export function configLibraryHas(expanded: boolean, issuesStrings: string[], indexesStrings: string[], supplementsStrings: string[]):
  LibraryHasConfiguration {
  const issueCount = stringArraySize(issuesStrings);
  const indexCount = stringArraySize(indexesStrings);
  const supplementCount = stringArraySize(supplementsStrings);

  let showIssuesCount = issueCount;
  let showIndexesCount = indexCount;
  let showSupplementsCount = supplementCount;

  const total = issueCount + indexCount + supplementCount;
  const showMore = total > LINE_LIMIT;

  if (!expanded) {
    showIssuesCount = LINE_LIMIT > issueCount ? issueCount : LINE_LIMIT;

    showIndexesCount = LINE_LIMIT > showIssuesCount ? LINE_LIMIT - showIssuesCount : 0;
    if (indexCount < showIndexesCount) {
      showIndexesCount = indexCount;
    }

    showSupplementsCount = LINE_LIMIT > (showIssuesCount + showIndexesCount)
      ? LINE_LIMIT - (showIssuesCount + showIndexesCount) : 0;
    if (supplementCount < showSupplementsCount) {
      showSupplementsCount = supplementCount;
    }
  }

  return {
    showMore,
    showIssuesCount,
    showIndexesCount,
    showSupplementsCount,
  } as LibraryHasConfiguration;
}

export function stringArraySize(values: string[]): number {
  return values ? values.length : 0;
}
