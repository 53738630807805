import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faSignIn } from '@fortawesome/pro-light-svg-icons';
import { Subscription } from 'rxjs';
import { KeycloakService } from '../../../keycloak/services/keycloak.service';

@Component({
  selector: 'app-inspire-login-form',
  templateUrl: './inspire-login-form.component.html',
  styleUrls: ['./inspire-login-form.component.scss'],
})
export class InspireLoginFormComponent implements OnInit, OnDestroy {
  public readonly loginForm: UntypedFormGroup;
  public readonly loginIcon = faSignIn;
  public isLoading = false;
  public resetPasscodeLink: string;
  private readonly subscriptions = new Subscription();

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly keycloakService: KeycloakService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.loginForm = this.fb.group({
      barcode: [this.keycloakService.memorizedUsername || '', [Validators.required]],
      passcode: ['', [Validators.required]],
      rememberMe: [false],
    });
  }

  public ngOnInit(): void {
    this.resetPasscodeLink = this.keycloakService.resetPasscodeLink;
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onSubmit(): void {
    this.barcode.markAsTouched();
    this.passcode.markAsTouched();
    if (!this.loginForm.valid || this.isLoading) {
      return;
    }

    this.isLoading = true;

    this.subscriptions.add(
      this.keycloakService.loginWithCredentials(this.barcode.value, this.passcode.value, this.rememberMe.value)
      .subscribe((result) => {
        switch (result) {
          case 'incorrect-credentials': {
            this.isLoading = false;
            this.loginForm.setErrors({incorrectCredentials: true});
            break;
          }
          case 'unknown': {
            this.isLoading = false;
            this.loginForm.setErrors({unknown: true});
            break;
          }
        }

        this.cdr.detectChanges();
      }),
    );
  }

  get barcode(): AbstractControl | null {
    return this.loginForm.get('barcode');
  }

  get passcode(): AbstractControl | null {
    return this.loginForm.get('passcode');
  }

  get rememberMe(): AbstractControl | null {
    return this.loginForm.get('rememberMe');
  }
}
