<div class="h-100 w-200 position-relative clearfix"
     [@openClose]="showcaseOpenCloseState"
     (@openClose.start)="onOpenCloseAnimation($event)"
     (@openClose.done)="onOpenCloseAnimation($event)">
  <div class="w-50 h-100 float-left">
    <app-bookshelf-template [class.invisible]="stepDisplayed === stepDisplayedEnum.singleShowcase">
      <ng-template bookshelfTemplateTitle>
        <span data-automation-id="custom-showcases-title" class="p-4">
          {{ 'customShowcasesTitle' | translate }}
        </span>
        <span class="custom-showcases-switcher">
          <button (click)="selectMyShowcases()"
                  [class.active]="!isAllView"
                  class="custom-showcases-switcher-button px-2 px-sm-4"
                  data-automation-id="custom-showcases-switch-to-my">
            <span translate>customShowcasesCreatedByMe</span>
            <span class="ml-2">{{totalCounts.my}}</span>
          </button>
          <button (click)="selectAllShowcases()"
                  [class.active]="isAllView"
                  class="custom-showcases-switcher-button px-2 px-sm-4"
                  data-automation-id="custom-showcases-switch-to-all">
            <span translate>customShowcasesAll</span>
            <span *ngIf="totalCounts?.all !== null" class="ml-2">{{totalCounts.all}}</span>
          </button>
        </span>
        <div class="custom-showcases-search-bar d-inline-block ml-4 ml-sm-7">
          <app-bookshelf-search-bar [placeholder]="'customShowcasesSearch' | translate" (onSearch)="searchShowcases($event)"></app-bookshelf-search-bar>
        </div>

      </ng-template>
      <ng-template bookshelfTemplateBody="ps-short" let-psScroll="psScroll">
        <app-custom-showcases-list [showcases]="customShowcases$ | async"
                                   [scrollContainer]="psScroll"
                                   [hasMoreItems]="isAllView && !allShowcasesLoaded"
                                   [resultsCount]="isAllView ? allResultsCount : myResultsCount"
                                   [searchText]="searchText"
                                   (moreItemsRequested)="loadMoreItems()">
        </app-custom-showcases-list>
      </ng-template>
    </app-bookshelf-template>
  </div>

  <div class="w-50 h-100 float-right">
    <app-curated-showcase-view *ngIf="openedShowcaseType === showcaseType.list && (showcaseOpenCloseState || openedShowcase)"
                               (closeShowcase)="closeShowcase()"
                               [openedShowcaseWithDetails]="openedCuratedShowcaseWithDetails"
                               [isShowcaseCreator]="userId === openedShowcase.creator.id"
                               [isInvisible]="stepDisplayed === stepDisplayedEnum.showcases"
    ></app-curated-showcase-view>

    <app-dynamic-showcase-view
      *ngIf="openedShowcaseType === showcaseType.savedSearch && (showcaseOpenCloseState || openedShowcase)"
      (closeShowcase)="closeShowcase()"
      [openedShowcaseWithDetails]="openedDynamicShowcaseWithDetails"
      [isShowcaseCreator]="userId === openedShowcase.creator.id"
      [isInvisible]="stepDisplayed === stepDisplayedEnum.showcases"
    ></app-dynamic-showcase-view>
  </div>
</div>
