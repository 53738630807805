export enum License {
  CONTEXT_ENGINE = 'Context engine',
  DISCOVERY = 'Discovery',
  DISCOVER_PLUS = 'Discover+',
  DISCOVER_PREMIUM = 'Custom Header',
  DISCOVER_CONSORTIA = 'Consortium',
  SAVED_SEARCHES = 'Saved Searches',
  PERSONAL_LISTS = 'Personal Lists',
  CUSTOM_SHOWCASES = 'Custom Showcases',
  READING_HISTORY = 'Reading History',
}

export const licenses = [
  License.PERSONAL_LISTS,
  License.SAVED_SEARCHES,
  License.CUSTOM_SHOWCASES,
  License.READING_HISTORY,
  License.CONTEXT_ENGINE,
  License.DISCOVERY,
  License.DISCOVER_PLUS,
  License.DISCOVER_PREMIUM,
  License.DISCOVER_CONSORTIA,
];
