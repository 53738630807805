<app-bookshelf-card [name]="showcase.name"
                    [items]="showcase.items | slice:0:5"
                    [ariaTitleId]="ariaTitleId"
                    [showcaseId]="showcase.id"
                    (requestPermissions)="requestPermissions($event)"
                    [displayCreator]="true"
                    [creator]="showcase.creator.nickname"
                    [isCreator]="isShowcaseCreator"
                    [itemsCount]="showcase.itemsCount"
                    [focus$]="focus$"
                    [focusActionsMenuToggleButton$]="focusActionsMenuToggleButton$"
                    [focusShareMenuToggleButton$]="focusShareMenuToggleButton$"
                    (cardClick)="onShowcaseOpened.emit()"
                    (triggerAction)="triggerCardAction($event)"
                    (triggerShareAction)="triggerCardShareAction($event)">
                  <!-- OPEN BTN-->
  <ng-container *ngIf="isAuthPatronFlagEnabled">
    <ng-container *cardAction="cardActionTypeEnum.open">
      <app-bookshelf-card-action-item [icon]="folderOpenIcon" messageKey="customShowcaseOpenAction">
      </app-bookshelf-card-action-item>
    </ng-container>
  </ng-container>
                    <!-- EDIT BTN-->
  <ng-container *ngIf="!isAuthPatronFlagEnabled">
    <ng-container *ngIf="
        (isShowcaseCreator)
        ? (hasUpdatePersonalShowcasePermission$ | async)
        : (hasUpdateNonPersonalShowcasePermission$ | async)">
      <ng-container *cardAction="cardActionTypeEnum.edit">
        <app-bookshelf-card-action-item [icon]="editIcon" messageKey="customShowcaseEditAction">
        </app-bookshelf-card-action-item>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isAuthPatronFlagEnabled">
    <ng-container *hasShowcasePermission="{
        permission: showcasePermissions.EDIT,
        showcaseId: showcase.id
      }"
    >
      <ng-container *cardAction="cardActionTypeEnum.edit">
        <app-bookshelf-card-action-item [icon]="editIcon" messageKey="customShowcaseEditAction">
        </app-bookshelf-card-action-item>
      </ng-container>
    </ng-container>
  </ng-container>
                                <!-- PUBLISH / UNPUBLISH BTN-->
  <ng-container *ngIf="!isAuthPatronFlagEnabled">
    <ng-container *ngIf="hasPublishShowcasePermission$ | async">
      <ng-container *ngIf="showcase.published; else publishActionTemplate">
        <ng-container *cardAction="cardActionTypeEnum.unpublish">
          <app-bookshelf-card-action-item [icon]="unpublishIcon" messageKey="customShowcaseUnpublishAction">
          </app-bookshelf-card-action-item>
        </ng-container>
      </ng-container>

      <ng-template #publishActionTemplate>
        <ng-container *cardAction="cardActionTypeEnum.publish">
          <app-bookshelf-card-action-item [icon]="publishIcon"
                                          messageKey="customShowcasePublishAction"></app-bookshelf-card-action-item>
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isAuthPatronFlagEnabled">
    <ng-container *hasShowcasePermission="{
        permission: showcasePermissions.PUBLISH,
        showcaseId: showcase.id
      }"
    >
      <ng-container *ngIf="showcase.published; else publishActionTemplate">
          <ng-container *cardAction="cardActionTypeEnum.unpublish">
            <app-bookshelf-card-action-item [icon]="unpublishIcon" messageKey="customShowcaseUnpublishAction">
            </app-bookshelf-card-action-item>
          </ng-container>
      </ng-container>

      <ng-template #publishActionTemplate>
          <ng-container *cardAction="cardActionTypeEnum.publish">
            <app-bookshelf-card-action-item [icon]="publishIcon" messageKey="customShowcasePublishAction">
            </app-bookshelf-card-action-item>
          </ng-container>
      </ng-template>
    </ng-container>
  </ng-container>

                                          <!-- GENERATE BTN-->
  <ng-container *ngIf="!isAuthPatronFlagEnabled">
    <ng-container *ngIf="hasGenerateShowcasePermission$ | async">
      <ng-container *ngIf="showcase.published">
        <ng-container *cardAction="cardActionTypeEnum.generate">
          <app-bookshelf-card-action-item [icon]="showcaseIcon" messageKey="customShowcaseGenerateAction">
          </app-bookshelf-card-action-item>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isAuthPatronFlagEnabled">
    <ng-container *hasShowcasePermission="{
        permission: showcasePermissions.PUBLISH,
        showcaseId: showcase.id
      }"
    >
      <ng-container *ngIf="showcase.published">
        <ng-container *cardAction="cardActionTypeEnum.generate">
          <app-bookshelf-card-action-item [icon]="showcaseIcon" messageKey="customShowcaseGenerateAction">
          </app-bookshelf-card-action-item>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
                                          <!-- DELETE BTN-->
  <ng-container *ngIf="!isAuthPatronFlagEnabled">
    <ng-container *ngIf="
      (isShowcaseCreator)
      ? (hasDeletePersonalShowcasePermission$ | async)
      : (hasDeleteNonPersonalShowcasePermission$ | async)">
      <ng-container *cardAction="cardActionTypeEnum.delete">
        <app-bookshelf-card-action-item [icon]="trashIcon" messageKey="customShowcaseDeleteAction">
        </app-bookshelf-card-action-item>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isAuthPatronFlagEnabled">
    <ng-container *hasShowcasePermission="{
        permission: showcasePermissions.DELETE,
        showcaseId: showcase.id
      }"
    >
      <ng-container *cardAction="cardActionTypeEnum.delete">
        <app-bookshelf-card-action-item [icon]="trashIcon" messageKey="customShowcaseDeleteAction">
        </app-bookshelf-card-action-item>
      </ng-container>
    </ng-container>
  </ng-container>


  <ng-container *ngIf="showcase.published">
    <span *cardShareAction="cardShareActionTypeEnum.email">
      <app-bookshelf-card-action-item [icon]="emailIcon" messageKey="shareShowcaseEmailAction">
      </app-bookshelf-card-action-item>
    </span>
    <span *cardShareAction="cardShareActionTypeEnum.copyLink">
      <app-bookshelf-card-action-item [icon]="copyIcon" messageKey="showcaseCopyLinkAction">
      </app-bookshelf-card-action-item>
    </span>
  </ng-container>

  <app-bookshelf-card-status *cardStatus
                             [cardType]="isDynamicShowcase(showcase) ? bookshelfCardType.savedSearch : bookshelfCardType.list"
                             [published]="showcase.published"
                             [isScheduled]="isScheduleShowcase"
                             [loading]="isUpdating"
                             (onClick)="onShowcaseTypeClick()">
  </app-bookshelf-card-status>
  <app-bookshelf-card-action-state
    [state]="
      displayState === displayStateEnum.LOADING ? {type: cardActionStateTypeEnum.loading}
      : displayState === displayStateEnum.SHARE_COPIED ? {type: cardActionStateTypeEnum.success, messageKey: 'savedSearchCopied'}
      : displayState === displayStateEnum.SHARE_FAILED ? {type: cardActionStateTypeEnum.error, messageKey: 'savedSearchShareError'}
      : {type: cardActionStateTypeEnum.none}
    "
    [clickOutsideMessageEnabled]="
      displayState === displayStateEnum.SHARE_COPIED
      || displayState === displayStateEnum.SHARE_FAILED
    "
    (clickOutsideMessage)="clearStatus()"
  >
  </app-bookshelf-card-action-state>
</app-bookshelf-card>
