import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faCatSpace, faUser } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { stopFocusUserPanelButton, toggleOpenedLibraryInfoWidget, toggleOpenedUserPanel } from 'user/actions/user-profile.actions';
import {
  getDoFocusUserPanelButton,
  getLibraryInfoWidgetState,
  getStaffUserName,
  getUserNicknameOrName,
  getUserPanelState
} from 'user/reducers/user.reducer';
import { isAuthorizedInKeycloak, isStaffAuthorizedInKeycloak } from '../../../keycloak/reducers/keycloak.reducer';
import { State } from '../../../reducers';

@Component({
  selector: 'app-login-button',
  templateUrl: './login-button.component.html',
  styleUrls: ['./login-button.component.scss']
})
export class LoginButtonComponent implements OnInit, OnDestroy {
  public userName: string;
  public isPatronAuthorized: boolean;
  public isStaffAuthorized: boolean;
  public isLoginPanelShown: boolean;
  public isLibraryInfoShown: boolean;
  public readonly userIcon = faUser;
  public readonly staffUserIcon = faCatSpace;

  @ViewChild('userPanelButton') public userPanelButton: ElementRef;

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly store: Store<State>,
  ) {
  }

  public ngOnInit() {
    this.subscriptions.add(this.store.select(getDoFocusUserPanelButton).subscribe((focusUserPanelButton) => {
      if (focusUserPanelButton) {
        this.userPanelButton?.nativeElement.focus();
        this.store.dispatch(stopFocusUserPanelButton());
      }
    }));

    this.subscriptions.add(this.store.select(getUserPanelState).subscribe((isOpened) => {
      this.isLoginPanelShown = isOpened;
    }));

    this.subscriptions.add(this.store.select(isAuthorizedInKeycloak)
    .subscribe((isAuthorized) => {
      this.isPatronAuthorized = isAuthorized;
    }));

    this.subscriptions.add(this.store.select(isStaffAuthorizedInKeycloak)
    .subscribe((isStaffAuthorized) => {
      this.isStaffAuthorized = isStaffAuthorized;
    }));

    this.subscriptions.add(combineLatest([
      this.store.select(getUserNicknameOrName),
      this.store.select(getStaffUserName),
    ])
    .subscribe(([patronUserName, staffUserName]) => {
      this.userName = patronUserName || staffUserName;
    }));

    this.subscriptions.add(this.store.select(getLibraryInfoWidgetState).subscribe((isOpened) => {
      this.isLibraryInfoShown = isOpened;
    }));
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public toggleOpenedUserMenu(event: Event) {
    event.stopPropagation();
    if (this.isLibraryInfoShown) {
      this.store.dispatch(toggleOpenedLibraryInfoWidget({isOpened: false}));
    }

    this.store.dispatch(toggleOpenedUserPanel({isOpened: !this.isLoginPanelShown}));
  }
}
