<div class="h-100 w-200 position-relative clearfix"
     [@openClose]="false"
     (@openClose.start)="onOpenCloseAnimation($event)"
     (@openClose.done)="onOpenCloseAnimation($event)">
  <div class="w-50 h-100 float-left">
    <div class="d-flex ">
      <input #searchInput
             class="search-input"
             aria-label="What are you looking for?"
             (keydown.enter)="onKeydownEnter(searchInput.value)"
             [value]=""
             data-automation-id="global-header-search-input">
      <app-custom-dropdown class="search-dropdown"
                           data-automation-id="search-bar-dropdown"
                           [overlayOffsetX]="(isReversedSmall) ? 39 : 0"
                           cdkMonitorElementFocus
                           [selectedOption]="'title'" (change)="onDropdownChange($event)"
                           [aria-label]="'searchBy' | translate">
        <app-custom-dropdown-option
          class="search-dropdown"
          [class.search-bar-dropdown-option-small]="isReversedSmall"
          [value]="'Title'"
          [id]="'title'"
          translate>Title
        </app-custom-dropdown-option>
      </app-custom-dropdown>
    </div>
    <app-bookshelf-template [class.invisible]="false">
      <ng-template bookshelfTemplateTitle>
        <span data-automation-id="bookmarks-title" class="p-4" role="heading" aria-level="1">
          {{ 'bookmarksListsTitle' | translate }} | {{ lists.length }}
        </span>
      </ng-template>
      <ng-template bookshelfTemplateBody>
        <app-lists-list [lists]="lists"></app-lists-list>
      </ng-template>
    </app-bookshelf-template>
  </div>
</div>
