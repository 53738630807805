import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as Immutable from 'immutable';
import { GetState } from '../../saved-search/reducers/saved-search.reducer';
import { setLastCopied, writeUrl } from '../actions/custom-showcase.actions';

export interface State {
  copyState: { [key: string]: Immutable.Record<GetState> }
}

export const GetStateRecord = Immutable.Record<GetState>({
  loading: false,
  error: null,
  url: null,
  lastCopied: false,
});

export const initialState: State = {
  copyState: {},
};

export const _reducer = createReducer(
  initialState,
  on(writeUrl, (state, {id, url}) => ({
    ...state,
    copyState: {
      [id]: GetStateRecord({url}),
    },
  })),
  on(setLastCopied, (state, {id}) => ({
    ...state,
    copyState: {
      [id]: GetStateRecord({lastCopied: true}),
    },
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return _reducer(state, action);
}

export const featureKey = 'copyShowcaseUrl';

export const getCopyShowcaseUrlState = createFeatureSelector<State>(featureKey);

export const copyState = createSelector(
  getCopyShowcaseUrlState,
  (state: State, props: { id: string }): GetState => {
    return (state && state.copyState && state.copyState[props.id]) ? (state.copyState[props.id].toJS() as GetState) : null;
  },
);

