<div class="p-4" [class.border-bottom]="!isLast" data-automation-id="holdings-info" role="listitem" tabindex=0>
  <div class="row">
    <div class="col flex-column text-break " style="flex-grow: 2">
      <div class="font-weight-bold mb-4" *ngIf="holdings.callNumber"
           data-automation-id="drawer-holdings-call-number">
              <span translate>callNumber</span>&nbsp;&nbsp;{{ holdings.callNumber  }}
      </div>
      <div class="text-capitalize mb-4"
           data-automation-id="drawer-location">{{ holdings.locationName}}</div>
      <div *ngIf="holdings.materialType"
           class="mb-4"
           data-automation-id="drawer-holdings-format">
        <span translate>format</span>&nbsp;&nbsp;{{ holdings.materialType  }}
      </div>
      <div *ngIf="holdings.latestReceived"
           class="mb-4"
           data-automation-id="drawer-holdings-latest-received">
              <span translate>latestReceived</span>&nbsp;&nbsp;{{ holdings.latestReceived  }}
      </div>
      <app-drawer-library-has *ngIf="holdings.issueStrings?.length || holdings.supplementStrings?.length || holdings.indexStrings?.length"
                       [displayStrings]="holdings.issueStrings"
                       [supplementStrings]="holdings.supplementStrings"
                       [indexStrings]="holdings.indexStrings"
                       class="mb-3">
      </app-drawer-library-has>
    </div>
  </div>
</div>
