import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, Renderer2, } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MendeleyAuthStorage } from 'app/citation/models/citation';
import { CitationService } from 'app/citation/services/citation.service';
import { OAuthHelper } from 'app/citation/utils/oauth-helper';
import { WindowRefService } from 'app/services/window-ref.service';
import { EditionData, PreparedEdition, } from '../../models/editions';

@Component({
  selector: 'citation-export',
  templateUrl: './citation-modal-window.component.html',
  styleUrls: ['./citation-modal-window.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CitationExportContentComponent extends OAuthHelper implements OnInit {
  @Input() public editionsData: EditionData[];

  public editionSelect = new UntypedFormControl();
  public editions: PreparedEdition[];
  public MENDELEY_URL: string;
  public isZoteroCollapsed = true;

  private newWindow: Window;
  private reader: FileReader;
  private readonly REF_WORKS_URL = 'http://www.refworks.com/express/ExpressImport.asp?vendor=Inspire&filter=RIS%20Format&encoding=65001';
  private readonly EASYBIB_URL = 'http://www.easybib.com/impexport/index/add';
  private readonly COINS_CONTAINER = 'coins-container';

  constructor(
    public activeModal: NgbActiveModal,
    public windowRef: WindowRefService,
    private readonly citationService: CitationService,
    private readonly renderer: Renderer2,
    private readonly cdRef: ChangeDetectorRef,
  ) { super(); }

  public ngOnInit() {
    this.citationService.getMendeleyAuthUrl().subscribe((data) => {
      this.MENDELEY_URL = data.url;
      this.cdRef.detectChanges();
    });
    this.prepareEditions();
    this.editionSelect.setValue(this.editions[0].id);
    this.cdRef.detectChanges();
  }

  public getPrepareEdition(data: EditionData): PreparedEdition {
    const editionStatement = data.editionStatement && data.editionStatement.length ?
      data.editionStatement[0] : '';
    const publicationDate = data.publicationDate ? data.publicationDate : '';
    let info = editionStatement;
    if (info) {
      info = publicationDate ? `${info}, ${publicationDate}` : info;
    } else if (publicationDate) {
      info = publicationDate;
    } else {
      info = '-';
    }
    return {
      id: data.instanceId,
      editionInfo: info,
    };
  }

  public prepareEditions() {
    if (this.editionsData && this.editionsData.length) {
      const editionsWithoutDate = this.editionsData.
        filter((data: EditionData) => !data.publicationDate);
      const editionsWithDate = this.editionsData.
        filter((data: EditionData) => !!data.publicationDate).
        sort(this.sortEditions);
      this.editionsData = [...editionsWithDate, ...editionsWithoutDate];
      this.editions = this.editionsData.map(this.getPrepareEdition);
    }
  }

  public onRISFile() {
    this.citationService.getRIS(this.editionSelect.value).subscribe((data) => {
      this.downloadFile(data.body, data.fileName);
      this.activeModal.close();
    });
  }

  public onMendeley() {
    if (this.shouldAuthorize(MendeleyAuthStorage)) {
      this.authorizeInMendeley(this.MENDELEY_URL);
    } else if (this.shouldRefreshToken(MendeleyAuthStorage)) {
      this.refreshMendeleyAuthToken();
    } else {
      this.exportToMendeleyTool();
    }
  }

  public authorizeInMendeley(url: string) {
    this.windowRef.nativeWindow().open(url, '_blank');
    this.windowRef.nativeWindow().addEventListener('storage', this.mendeleyAuthCodeReceiver.bind(this));
  }

  public refreshMendeleyAuthToken() {
    const refreshToken = this.getRefreshToken(MendeleyAuthStorage);
    this.citationService.refreshMendeleyAuthToken(refreshToken).subscribe((data) => {
      this.updateAuthStorage(MendeleyAuthStorage, data);
      this.exportToMendeleyTool();
    });
  }

  public mendeleyAuthCodeReceiver(event: StorageEvent) {
    if (event.newValue && event.key === MendeleyAuthStorage.CODE) {
      this.citationService.getMendeleyAuthToken(event.newValue).subscribe((data) => {
        this.updateAuthStorage(MendeleyAuthStorage, data);
        this.exportToMendeleyTool();
      });
    }
  }

  public exportToMendeleyTool() {
    this.citationService.getMendeleyData(this.editionSelect.value).subscribe((data) => {
      const token = this.windowRef.nativeWindow().localStorage.getItem(MendeleyAuthStorage.ACCESS_TOKEN);
      this.citationService.sendDataToMendeley(data, token).subscribe();
      this.activeModal.close();
    });
  }

  public onZotero() {
    if (this.isZoteroCollapsed) {
      this.addCoinsData(this.editionSelect.value);
    }
    this.isZoteroCollapsed = !this.isZoteroCollapsed;
    this.cdRef.detectChanges();
  }

  public onZoteroRISFile() {
    this.citationService.getRIS(this.editionSelect.value).subscribe((data) => {
      this.downloadFile(data.body, data.fileName);
      this.activeModal.close();
    });
  }

  public onZoteroPlugin() {
    this.activeModal.close();
  }

  public downloadFile(body: Blob, fileName: string): void {
    fileName = fileName ? fileName : 'Inspire_RIS_Export.ris';
    if (this.windowRef.nativeWindow().navigator && this.windowRef.nativeWindow().navigator.msSaveOrOpenBlob) {
      this.windowRef.nativeWindow().navigator.msSaveOrOpenBlob(body, fileName);
    } else {
      const url = this.windowRef.nativeWindow().URL.createObjectURL(body);
      const link = this.renderer.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
      this.windowRef.nativeWindow().URL.revokeObjectURL(url);
      link.remove();
    }
  }

  public onRefworks() {
    this.newWindow = this.windowRef.nativeWindow().open('', '_blank');
    this.citationService.getDataForRefWorks(this.editionSelect.value).subscribe((data: Blob) => {
      this.readFileFromBlob(data, () => {
        this.openRefWorksTool(this.reader.result as string);
        this.activeModal.close();
      });
    });
  }

  public openRefWorksTool(data: string) {
    const refWorksRequestTemplate =
      `<html>
        <head>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <link rel="stylesheet" type="text/css" href="style.css" />
        </head>
        <body onload="document.getElementById(\'exportForm\').submit();">
          <FORM accept-charset="UTF-8"
            name="ExportForm"
            id="exportForm"
            method="POST"
            target="_parent"
            style="display:none"
            action=${this.REF_WORKS_URL}>
          ${data}
        </body>
      </html>`;
    this.newWindow.document.open();
    this.newWindow.document.write(refWorksRequestTemplate);
    this.newWindow.document.close();
  }

  public onEasybib() {
    this.newWindow = this.windowRef.nativeWindow().open('', '_blank');
    this.citationService.getDataForEasyBib(this.editionSelect.value).subscribe((data: Blob) => {
      this.readFileFromBlob(data, () => {
        this.openEasyBibTool(this.reader.result as string);
        this.activeModal.close();
      });
    });
  }

  public openEasyBibTool(data: string) {
    const easyBibRequestTemplate =
      `<html>
        <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        </head>
        <body onload="document.getElementById(\'exportForm\').submit();">
          <form accept-charset="UTF-8"
              name="ExportForm"
              id="exportForm"
              method="POST"
              target="_parent"
              style="display:none"
              action="${this.EASYBIB_URL}">
            ${data}
            <input type="submit">
          </form>
        </body>
      </html>`;
    this.newWindow.document.open();
    this.newWindow.document.write(easyBibRequestTemplate);
    this.newWindow.document.close();
  }

  public onClose() {
    this.activeModal.dismiss('Cross click');
  }

  public trackById(index: number, item: PreparedEdition) {
    return item.id;
  }

  private addCoinsData(instanceId: string) {
    if (!this.isPresent(instanceId)) {
      this.citationService.getCoinsData(instanceId).subscribe((data) => {
        const span = this.renderer.createElement('span');
        span.setAttribute('entry-id', instanceId);
        span.setAttribute('class', 'Z3988');
        span.setAttribute('title', data);
        span.setAttribute('entry-id', instanceId);
        this.getCoinsContainer().appendChild(span);
        this.windowRef.nativeWindow().document.dispatchEvent(new Event('ZoteroItemUpdated', {
          bubbles: true,
          cancelable: true,
        }));
      });
    }
  }

  private getCoinsContainer(): HTMLElement {
    let coinsContainer = this.windowRef.nativeWindow().document.getElementById(this.COINS_CONTAINER);
    if (coinsContainer == null) {
      const body = this.windowRef.nativeWindow().document.getElementsByTagName('body')[0];
      coinsContainer = this.renderer.createElement('div');
      coinsContainer.setAttribute('id', this.COINS_CONTAINER);
      body.appendChild(coinsContainer);
    }
    return coinsContainer;
  }

  private isPresent(instanceId: string) {
    const spans = this.getCoinsContainer().getElementsByTagName('span');
    return Array.from(spans).some((span) => span.getAttribute('entry-id') === instanceId);
  }

  private sortEditions(a: EditionData, b: EditionData) {
    const aDate = new Date(a.publicationDate);
    const bDate = new Date(b.publicationDate);
    return (aDate > bDate) ? -1 : (aDate < bDate) ? 1 : 0;
  }

  private readFileFromBlob(data: Blob, handler: (e: Event) => void) {
    this.reader = new FileReader();
    this.reader.addEventListener('loadend', handler);
    this.reader.readAsText(data);
  }
}
