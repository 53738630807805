import { Injectable } from '@angular/core';
import { MetaData } from '../models/MetaData';
import { FeatureToggleService } from './feature-toggle.service';
import { WindowRefService } from './window-ref.service';
import { Title } from '@angular/platform-browser';

@Injectable()
export class MetaTagLoaderService {

  public defaultOption = [
    'description'
  ];

  public facebookOptions = [
    'og:title',
    'og:description',
    'og:image',
    'og:type',
  ];

  public twitterOptions = [
    'twitter:description',
    'twitter:title',
    'twitter:image',
    'type'
  ];

  constructor(
    private readonly windowRef: WindowRefService,
    public featureToggleService: FeatureToggleService,
    private titleService: Title,
  ) {
  }

  // <meta property="og:title" content="Analysis: Haley will vote for the Trump ‘chaos’ that she once decried | CNN Politics">
  private prepareMetaTagFaceBook(properties: string[], items: string[]): HTMLMetaElement[] {
    return properties.map((item, index) => {
      const metaElement: any = this.windowRef.nativeWindow().document.createElement('meta');
      metaElement.setAttribute('property', item);
      metaElement.content = items[index];
      return metaElement;
    });
  }

  //<meta name="twitter:title" content="Analysis: Haley will vote for the Trump ‘chaos’ that she once decried | CNN Politics">
  private prepareMetaTagTwitter(properties: string[], items: string[]): HTMLMetaElement[] {
    return properties.map((item, index) => {
      const metaElement: any = this.windowRef.nativeWindow().document.createElement('meta');
      metaElement.name = item;
      metaElement.content = items[index];
      return metaElement;
    });
  }

  // <title>Haley will vote for the Trump ‘chaos’ that she once decried | CNN Politics</title>
  // If Meta Tag Service works -> BrowserTabTitleService should be removed and improve also the MetaTagLoaderService
  private defaultTitle(title: string) {
    this.titleService.setTitle(title);
  }

  // <meta name="description" content="The political calculation has changed again for Nikki Haley.">
  private defaultDescription(content: string) {
    const metaElement: any = this.windowRef.nativeWindow().document.createElement('meta');
    metaElement.name = 'description';
    metaElement.content = content;
    return metaElement;
  }

  // <link rel="canonical" href="https://www.cnn.com/2024/05/23/politics/nikki-haley-donald-trump-analysis/index.html" />
  private canonicalURL(url: string) {
    const metaElement: any = this.windowRef.nativeWindow().document.createElement('link');
    metaElement.rel = 'canonical';
    metaElement.href = url;
    return metaElement;
  }

  // <meta name="twitter:card" content="summary_large_image">
  private twitterDefault() {
    const metaElement: any = this.windowRef.nativeWindow().document.createElement('meta');
    metaElement.name = 'twitter:card';
    metaElement.content = 'summary_large_image';
    return metaElement;
  }

  public setMetas(metaData: MetaData): Promise<void> {
    const isSocialMediaOn = this.featureToggleService.getToggles()['DIS-30364_2024-06-30_Social-Media-Share'];
    if (!isSocialMediaOn) {
      return null;
    }
    const title = metaData.title;
    const description = metaData?.description || '';
    const image = metaData?.coverUrl?.large || metaData?.coverUrl?.medium || metaData?.coverUrl?.small || '';
    const type = 'article';
    const url = this.windowRef.nativeWindow().location.href || '';

    this.defaultTitle(title);
    const facebookTags: HTMLMetaElement[] = this.prepareMetaTagFaceBook(this.facebookOptions, [title, description, image, type]);
    const twitterTags: HTMLMetaElement[] = this.prepareMetaTagTwitter(this.twitterOptions, [title, description, image, type]);
    const defaultTags = [this.defaultDescription(description), this.canonicalURL(url), this.twitterDefault()];

    return new Promise((resolve) => {
      [facebookTags, twitterTags, defaultTags].forEach((tags) => {
        tags.forEach((tag: HTMLMetaElement[]) => {
          this.windowRef.nativeWindow().document.head.appendChild(tag);
        });
      });

      resolve();
    });
  }
}
