import { Directive, Injector, Input, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, UntypedFormControl, FormControlDirective } from '@angular/forms';

@Directive()
export abstract class ControlValueAccessorConnector implements ControlValueAccessor {
  @Input() public formControl: UntypedFormControl;
  @Input() public formControlName: string | number;

  @ViewChild(FormControlDirective, {static: true})
  public formControlDirective: FormControlDirective;

  protected constructor(private readonly injector: Injector) {
  }

  get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  get control(): UntypedFormControl {
    return this.formControl || this.controlContainer.control.get(this.formControlName.toString()) as UntypedFormControl;
  }

  public registerOnTouched(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  public registerOnChange(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  public writeValue(obj: any): void {
    this.formControlDirective.valueAccessor.writeValue(obj);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }
}
