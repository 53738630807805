import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from '@angular/core';
import { BrowserTabTitleService } from 'core/services/browser-tab-title.service';

@Component({
  selector: 'bc-forbidden-page',
  templateUrl: './forbidden-page.component.html',
  styleUrls: ['./forbidden-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForbiddenPageComponent implements OnInit {
  constructor(
    private titleService: BrowserTabTitleService,
  ) {}

  public ngOnInit() {
    this.titleService.setTabTitle('403');
  }
}
