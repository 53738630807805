<div class="p-4" [class.border-bottom]="!isLast" data-automation-id="item-info" role="listitem" tabindex=0>
  <div class="row">
    <div class="col flex-column text-break " style="flex-grow: 2">
      <div class="mb-2 first-line font-weight-bold"
           data-automation-id="drawer-publication-date">
        {{ item.publicationDate  }}
      </div>
      <div *ngIf="item.callNumber"
           data-automation-id="drawer-call-number">
        <span class="text-capitalize" translate>callNumber</span>:&nbsp;<span class="font-weight-bold">
          {{item.callNumber}}
        </span>
      </div>
      <div class="text-capitalize"
           data-automation-id="drawer-location">
       {{ 'location.' + item.locationCode | translate }}
      </div>
      <div *ngIf="item.shelfLocationName"
           data-automation-id="drawer-shelf-location">
        <span translate>shelfLocation</span>:&nbsp;<span class="font-weight-bold">
          {{item.shelfLocationName}}
        </span>
      </div>
    </div>
    <div class="col-auto d-flex flex-column align-items-end">
      <div>
        <svg width="10" height="10">
          <circle cx="5" cy="5" r="5"
                  [attr.fill]="item.status.availabilityStatus == itemAvailabilityStatuses.AVAILABLE && !item.dueDate ? '#0C7E11' : '#C0462B'"
                  data-automation-id="drawer-status-indicator"/>
        </svg>
        <span class="pl-2 align-middle"
              data-automation-id="drawer-status">
        <ng-container *ngIf="item.dueDate; else notCheckedOut">
          {{ 'checkedOut' | translate }}
        </ng-container>
        <ng-template #notCheckedOut>
          {{ item.status.recentlyReturnedMessage ||
            (item.status.code ? ('item-status.' + item.status.code | translate) : item.status.description) }}
        </ng-template>
      </span>
      </div>
      <a *ngIf="item.rareContentLink"
         href="{{item.rareContentLink}}"
         class="ins-buttons button-secondary flint mt-5"
         data-automation-id="drawer-rare-content-link"
         target="_blank"
         translate>
        specialRequest
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col flex-column text-break">
      <div *ngIf="item.collectionLocationName"
           data-automation-id="drawer-collection">
        <span translate>collection</span>:&nbsp;<span class="font-weight-bold">
          {{item.collectionLocationName}}
        </span>
      </div>
      <div *ngIf="showOpacMessage"
           data-automation-id="drawer-opac-message">
        <span translate>opacMessage</span>:&nbsp;{{ item.opacMessage }}
      </div>
      <div *ngIf="item.publicNote && displayNotes"
           data-automation-id="drawer-item-notes">
        <span class="text-capitalize" translate>itemNotes</span>:&nbsp;<span>
        {{item.publicNote}}</span>
      </div>
      <div
        class="mt-2"
        data-automation-id="drawer-physical-description">{{ item.physicalDescription  }}</div>
      <div *ngIf="item.language" data-automation-id="drawer-language"><span translate>Language</span>
        <span
          class="text-capitalize">:&nbsp;{{ matchedLanguage ?? item.language  }}</span></div>
      <div *ngIf="item.series" data-automation-id="drawer-series">
        <span translate>series</span>:&nbsp;{{ item.series  }}
      </div>
    </div>
  </div>
  <app-nlb-item-locator
    *ngIf="isNlbCustomer"
    [recordId]="item?.barcode"
    [title]="title"
    [callNumber]="item.callNumber"
    [collectionLocationName]="item.collectionLocationName"
    [locationLabel]="'location.' + location?.codes | translate"
    [mode]=1
    [language]="matchedLanguage ?? language"
  ></app-nlb-item-locator>
</div>
