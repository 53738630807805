import { CurrencyPipe, formatCurrency, } from '@angular/common';
import { Pipe, PipeTransform, } from '@angular/core';
import { LocalizationService } from 'app/services/localization.service';

@Pipe({
  name: 'configuredCurrency',
})
export class ConfiguredCurrencyPipe implements PipeTransform {
  constructor(
    private readonly localizationService: LocalizationService,
    private readonly currencyPipe: CurrencyPipe,
  ) {
  }

  public transform(value: number): any {
    const {locale, currentConfiguration: {currencyDetails, before}} = this.localizationService;

    if (currencyDetails?.displaySymbol) {
      const formattedValue = formatCurrency(value, locale, '');
      return before ? `${currencyDetails.displaySymbol}${formattedValue}` : `${formattedValue}${currencyDetails.displaySymbol}`;
    }

    return this.currencyPipe.transform(value);
  }
}
