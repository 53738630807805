<div class="bulk-hold-counters d-flex flex-column justify-content-center align-items-center" aria-live="polite" role="status">

  <!-- Processed Counter -->
  <div *ngIf="bulkHoldSubmitState.submitted" data-automation-id="bulk-hold-processed-counter">
    {{ 'bulkHoldProcessedCounter' | translate: { processedCount: bulkHoldSubmitState.processedCount, totalCount: bulkHoldSubmitState.totalCount} }}
  </div>

  <!-- Needs Action Counter -->
  <button *ngIf="bulkHoldSubmitState.needsActionFgIds.length > 0"
          (click)="neededActionClick.emit()"
          [attr.aria-label]="('bulkHoldNavigateToFirstNeededActionItem' | translate)"
          [tippy]="('bulkHoldNavigateToFirstNeededActionItem' | translate)"
          class="bulk-hold-needs-action-counter"
          data-automation-id="bulk-hold-needs-action-counter">
    {{ bulkHoldSubmitState.needsActionFgIds.length }} {{ 'bulkHoldNeedsAction' | translate }}
  </button>

  <!-- Stopped Counter -->
  <div *ngIf="bulkHoldSubmitState.stoppedCount > 0"
       class="bulk-hold-stopped-counter d-flex justify-content-center align-items-center"
       data-automation-id="bulk-hold-stopped-counter">
    {{ bulkHoldSubmitState.stoppedCount }} {{ 'bulkHoldStopped' | translate }}
  </div>
</div>
