import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-add-another-button',
  templateUrl: './add-another-button.component.html',
  styleUrls: ['./add-another-button.component.scss'],
})
export class AddAnotherButtonComponent {
  @Input() public inputLabel?: string;
  @Output() public readonly onClick = new EventEmitter<undefined>();
  @Output() public readonly setFocus = new EventEmitter<undefined>();

  public readonly plusIcon = faPlus;
}
