<div *ngIf="isCompact; else fullFeaturedSearchBarTmpl" class="search-compact">
  <span class="icon-search search-compact-icon"></span>
  <input #searchInput
         class="search-compact-input"
         aria-label="What are you looking for?"
         (keydown.enter)="searchInput.value && onKeydownEnter(searchInput.value)"
         [value]="originalSearchQueryValue"
         data-automation-id="global-header-search-input">
</div>

<ng-template #fullFeaturedSearchBarTmpl>
  <div class="search-bar"
       [class.search-bar__reversed-small]="isReversedSmall">
    <div [ngClass]="{
      'search-bar--box': true,
      'activeInput' : searchInputControl.value || searchInput?.nativeElement.classList?.contains('focus-visible')
    }">

      <div class="input-group flex-nowrap flex-row align-items-center">
        <ng-container *ngIf="isReversedSmall; else normalOrderTmpl">
          <ng-container *ngTemplateOutlet="searchTypeDropdownTmpl"></ng-container>
          <ng-container *ngTemplateOutlet="searchInputTmpl"></ng-container>
          <ng-container *ngTemplateOutlet="searchBtnTmpl"></ng-container>
        </ng-container>

        <ng-template #normalOrderTmpl>
          <ng-container *ngTemplateOutlet="searchBtnTmpl"></ng-container>
          <ng-container *ngTemplateOutlet="searchInputTmpl"></ng-container>
          <ng-container *ngTemplateOutlet="searchTypeDropdownTmpl"></ng-container>
        </ng-template>

        <ng-template #searchBtnTmpl>
          <div class="search-bar-btn-container">
            <button data-automation-id="search-btn"
                    [attr.aria-label]="'searchInputPlaceholder' | translate"
                    (click)="onSearch(searchInputControl.value)"
                    class="btn search-bar--btn d-flex align-items-center justify-content-center"
                    type="button">
              <fa-icon [icon]="searchIcon"></fa-icon>
            </button>
          </div>
        </ng-template>

        <ng-template #searchInputTmpl>
          <div class="d-flex w-100 align-items-center search-bar-input-container">
            <input class="form-control search-bar--input"
                   type="text"
                   aria-label="search"
                   [attr.aria-controls]="ariaInputControl"
                   placeholder="{{ 'searchInputPlaceholder' | translate }}"
                   (keydown.enter)="onKeydownEnter(searchInput.value)"
                   (keydown)="typeaheadKeydown(instance)"
                   (blur)="onBlur()"
                   (focus)="onFocus()"
                   (focusout)="onFocusOutEvent(searchInput.value)"
                   [formControl]="searchInputControl"
                   #searchInput
                   #instance="ngbTypeahead"
                   [ngbTypeahead]="search"
                   [resultTemplate]="searchSuggest"
                   [inputFormatter]="formatter"
                   [focusFirst]="false"
                   (selectItem)="onSelected($event)"
                   data-automation-id="search-input-field"/>
            <div *ngIf="searchInput.value" class="search-bar-btn-clear-container">
              <button class="btn close-btn search-bar--clear-btn p-0 h-100 d-flex align-items-center"
                      [class.mr-1]="!isReversedSmall && !isSmall"
                      type="button"
                      (click)="clearSearch()"
                      tabindex="0"
                      aria-label="clear search"
                      data-automation-id="clear-search-btn">
                <span class="icon-close"></span>
              </button>
            </div>
          </div>
        </ng-template>

        <ng-template #searchTypeDropdownTmpl>
          <div class="search-bar--select h-100">
            <app-custom-dropdown class="search-bar-dropdown"
                                 data-automation-id="search-bar-dropdown"
                                 [overlayOffsetX]="(isReversedSmall) ? 39 : 0"
                                 cdkMonitorElementFocus
                                 [selectedOption]="selectedSearchType" (change)="onDropdownChange($event)"
                                 [aria-label]="'searchBy' | translate">
              <app-custom-dropdown-option
                class="search-bar-dropdown-option"
                [class.search-bar-dropdown-option-small]="isReversedSmall"
                [value]="searchTypes[0]"
                [id]="searchTypes[0]"
                translate>All
              </app-custom-dropdown-option>
              <app-custom-dropdown-option
                class="search-bar-dropdown-option"
                [class.search-bar-dropdown-option-small]="isReversedSmall"
                [value]="searchTypes[1]"
                [id]="searchTypes[1]"
                translate>Author
              </app-custom-dropdown-option>
              <app-custom-dropdown-option
                class="search-bar-dropdown-option"
                [class.search-bar-dropdown-option-small]="isReversedSmall"
                [value]="searchTypes[2]"
                [id]="searchTypes[2]"
                translate>Concept
              </app-custom-dropdown-option>
              <app-custom-dropdown-option
                class="search-bar-dropdown-option"
                [class.search-bar-dropdown-option-small]="isReversedSmall"
                [value]="searchTypes[4]"
                [id]="searchTypes[4]"
                translate>Series
              </app-custom-dropdown-option>
              <app-custom-dropdown-option
                class="search-bar-dropdown-option"
                [class.search-bar-dropdown-option-small]="isReversedSmall"
                [value]="searchTypes[3]"
                [id]="searchTypes[3]"
                translate>Title
              </app-custom-dropdown-option>
            </app-custom-dropdown>
          </div>
        </ng-template>
      </div>
    </div>
    <div *ngIf="featureAdvancedSearch" class="flex-nowrap advanced-search-container">
      <div *ngIf="!advancedSearch" class="d-flex justify-content-end">
        <a data-automation-id="advanced-search-link"
           class="advanced-search-text-color"
           [queryParams]="defaultAdvancedSearchParams"
           [routerLink]="['/search']" translate>Advanced Search</a>
      </div>
      <div *ngIf="advancedSearch" class="d-flex justify-content-end">
        <a data-automation-id="basic-search-link"
           class="advanced-search-text-color"
           [routerLink]="['/']" translate>Basic Search</a>
      </div>
    </div>

    <ng-template #searchSuggest class="form-control search-suggest" let-r="result" let-t="term">
      <ngb-highlight class="autocomplete-option fast-link" *ngIf="r.isFastLink"
                     [result]="fastLinkValue(r.suggest.term, r.suggest.type)"
                     [term]="t"></ngb-highlight>
      <ng-container *ngIf="r.isFastLink">
        <hr>
      </ng-container>
      <ngb-highlight *ngIf="!r.isFastLink" class="autocomplete-option" [result]="removeTags(r.suggest.term)"
                     [term]="t"></ngb-highlight>
    </ng-template>
  </div>
</ng-template>

