import { ControlValueAccessor } from '@angular/forms';

export abstract class NoopControlValueAccessor implements ControlValueAccessor {
  public writeValue(obj: any): void {
    // noop
  }

  public registerOnChange(fn: any): void {
    // noop
  }

  public registerOnTouched(fn: any): void {
    // noop
  }
}
