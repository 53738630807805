import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-get-it-success-dialog',
  templateUrl: './get-it-success-dialog.component.html',
  styleUrls: ['./get-it-success-dialog.component.scss'],
})
export class GetItSuccessDialogComponent {

  constructor(private activeModal: NgbActiveModal) {
  }

  public onClose() {
    this.activeModal.dismiss();
  }
}
