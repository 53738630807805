import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { interval, Observable, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-countdown-redirect',
  templateUrl: './countdown-redirect.component.html',
  styleUrls: ['./countdown-redirect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountdownRedirectComponent implements OnInit, OnDestroy {
  @Input() public seconds: number;
  @Input() public redirectTo: string[];
  @Input() public routeExtras?: NavigationExtras;
  public counter$: Observable<number>;
  public counter: number;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly cd: ChangeDetectorRef,
  ) {
  }

  public ngOnInit() {
    this.startCountdown();
  }

  startCountdown() {
    this.counter$ = interval(1000).pipe(
      takeUntil(this.unsubscribe$),
      take(this.seconds + 1),
      map((value) => this.seconds - value),
      tap((value) => {
          this.counter = value;
          this.cd.detectChanges();
        }
      ),
    );
    this.counter$.subscribe({
      complete: () =>
        this.redirect(),
    });
  }

  redirect() {
    this.router.navigate(this.redirectTo, this.routeExtras);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }
}
