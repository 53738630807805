import { Component, Input } from '@angular/core';
import { AvailabilityStatus, AvailableStatuses } from '../../models/entity';

@Component({
  selector: 'app-availability-icon',
  templateUrl: './availability-icon.component.html',
  styleUrls: ['./availability-icon.component.scss'],
})
export class AvailabilityIconComponent {

  @Input() public status: AvailabilityStatus;
  public statuses = AvailabilityStatus;
  public availableStatuses = AvailableStatuses;

}
