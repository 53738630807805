import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CheckoutCard } from 'user/models/checkouts';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-bulk-renew-process-modal',
  templateUrl: './bulk-renew-process-modal.component.html',
  styleUrls: ['./bulk-renew-process-modal.component.scss']
})
export class BulkRenewProcessModalComponent {
  @Input() public renewedCheckouts$: Observable<CheckoutCard[]>;
  @Input() public failedToRenewCheckouts$: Observable<CheckoutCard[]>;
  @Input() public noRenewalsInProgress$: Observable<boolean>;
  @Input() public processedItemsNumber$: Observable<number>;
  @Input() public totalItemsNumber: number = null;
  @Input() public title: string;

  constructor(
    private readonly activeModal: NgbActiveModal,
  ) {
  }

  public onClose(): void {
    this.activeModal.close();
  }
}
