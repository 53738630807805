import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { WindowRefService } from 'app/services/window-ref.service';
import { AutoSuggest, AutosuggestAgentRoles, FastLinks } from 'common/models/autosuggestions';
import { DropdownValue } from 'common/models/custom-dropdown';
import { QueryHelper } from 'core/utils/query-helper';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { ResetFacetBubblesAction, UpdateSearchObjectAction } from 'search/actions/facet.actions';
import { focusoutSearchInput, SearchResultsReset, setAdvancedMode } from 'search/actions/search.actions';
import { FacetURLFilters } from 'search/facets/models/facet-state';
import { SearchQuery } from 'search/models/search-object';
import { Advanced } from 'search/models/search-term';
import { collapseBookshelf } from 'user/actions/user-profile.actions';
import { EntityTypes } from '../../../entity/models/entity';
import { SearchType } from 'search/models/search-type';
import { getLockedFacetsAsQueryParams, State } from 'search/reducers/root.reducer';
import { SuggestsService } from 'search/services/suggests.service';
import { resetScrollAndSelectedResourceId } from '../../../entity/actions/entity.actions';
import { FeatureToggleService } from '../../../services/feature-toggle.service';
import { PendoService } from '../../../services/pendo.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public scrollEvent: any;
  @Input() public searchStyle: 'default' | 'reversedSmall' | 'compact' = 'default';

  @ViewChild('searchInput') public searchInput: ElementRef;
  @ViewChild('instance') public instance: NgbTypeahead;
  public searchInputControl: UntypedFormControl;
  public inputFocus: boolean;
  public originalSearchQueryValue: string;
  public originalSearchType: string;
  public searchTypes: string[] = [SearchType.EVERYTHING, SearchType.AGENT, SearchType.CONCEPT, SearchType.TITLE, SearchType.SERIES];
  public defaultSearchType: string = this.searchTypes[0];
  public lockedFacetsQueryParams: FacetURLFilters;
  public entityTypes = EntityTypes;
  public search: (text$: Observable<string>) => Observable<any[]>;
  public suggestionSelected = false;
  public selectedSearchType = this.defaultSearchType;
  public isReversedSmall = false;
  public isCompact = false;
  public advancedSearch = false;
  public featureAdvancedSearch = false;
  public ariaInputControl: string = '';

  public readonly searchIcon = faSearch;
  public readonly defaultAdvancedSearchParams: SearchQuery = {
    query: '',
    searchType: SearchType.EVERYTHING,
    pageSize: 10,
    mode: Advanced
  };

  private readonly subscription: Subscription = new Subscription();
  private readonly searchEventName: string = 'Vega Search';

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly store: Store<State>,
    private readonly suggestsService: SuggestsService,
    private readonly windowRef: WindowRefService,
    private readonly liveAnnouncer: LiveAnnouncer,
    private readonly translateService: TranslateService,
    private readonly queryHelper: QueryHelper,
    private renderer: Renderer2,
    private readonly pendoService: PendoService,
    private readonly featureToggleService: FeatureToggleService
  ) {
  }

  public formatter = (x: { suggest: {term: string }}) => this.removeTags(x?.suggest?.term);

  public ngOnInit() {
    this.isReversedSmall = this.searchStyle === 'reversedSmall';
    this.isCompact = this.searchStyle === 'compact';
    this.searchInputControl = new UntypedFormControl();
    this.featureAdvancedSearch = this.featureToggleService.getToggles()['DIS-27818_2023-07-01_advanced_search'];
    this.subscription.add(this.store.pipe(select(getLockedFacetsAsQueryParams)).subscribe((result) => {
      this.lockedFacetsQueryParams = result;
    }));

    this.subscription.add(this.route.queryParamMap.subscribe((params) => {
      this.searchInputControl.setValue({suggest: {term: params.get('query') || ''}});
      this.originalSearchQueryValue = params.get('query');
      this.originalSearchType = params.get('searchType');
      const receivedType = this.searchTypes.find((type) => params.get('searchType') === type);
      this.selectedSearchType = receivedType || this.defaultSearchType;
      if (this.featureAdvancedSearch) {
        this.advancedSearch = params.get('mode') === Advanced;
        this.store.dispatch(setAdvancedMode({advancedMode: this.advancedSearch}));
        if (this.advancedSearch) {
          if (this.originalSearchQueryValue === '*') {
            this.originalSearchQueryValue = '';
            this.searchInputControl.setValue({suggest: {term: ''}});
          }
          this.store.dispatch(focusoutSearchInput({query: this.originalSearchQueryValue}));
        }
      }
    }));

    this.search = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter(() => this.inputFocus),
        switchMap((text) => this.hasFourLetters(text) && this.selectedSearchType !== SearchType.SERIES ?
          this.suggestsService.getSearchSuggests(text, this.selectedSearchType) :
          of([])),
        map((suggests) => {
          const fastLinks: FastLinks = {
            concept: null,
          };
          fastLinks.concept = suggests.find((item) => {
            const isConcept = item.type === this.entityTypes.CONCEPT;
            const isSubject = item.type === this.entityTypes.AGENT && item.roles && item.roles.includes(AutosuggestAgentRoles.SUBJECT);
            return isConcept || isSubject;
          });
          const suggestions = suggests.map((suggest) => ({suggest, isFastLink: false}));
          if (fastLinks.concept && (this.selectedSearchType === SearchType.EVERYTHING || this.selectedSearchType === SearchType.CONCEPT)) {
            return [{suggest: fastLinks.concept, isFastLink: true}].concat(suggestions.slice(0, 5));
          }
          return suggestions;
        }),
      );
  }

  public onKeydownEnter(term: string) {
    this.onSearch(term);
    this.pendoService.trackEvent(this.searchEventName);
  }

  public onSearch(term: string | any) {
    if (this.suggestionSelected === true) {
      this.suggestionSelected = false;
      return false;
    }

    if (typeof term === 'object') {
      term = this.formatter(term);
    }

    term = term?.trim?.();
    if (this.skipOnSearch(term)) {
      return false;
    }

    if (this.originalSearchQueryValue !== term
      || this.selectedSearchType !== this.originalSearchType) {
      this.setInputBlur();
      this.store.dispatch(resetScrollAndSelectedResourceId());
      this.store.dispatch(new SearchResultsReset());
      this.store.dispatch(new UpdateSearchObjectAction({searchParams: {type: {searchText: term ?? '*'}}}));
      this.store.dispatch(new ResetFacetBubblesAction({}));
      this.store.dispatch(collapseBookshelf());

      const queryParams = this.queryHelper.prepareQueryParams(term, this.lockedFacetsQueryParams, this.selectedSearchType,
        this.advancedSearch ? Advanced : undefined);
      this.router.navigate(['/search'], queryParams);
    }

    return true;
  }

  public onFastLinkClick(fastLink: AutoSuggest) {
    this.setInputBlur();
    this.store.dispatch(new ResetFacetBubblesAction({}));
    this.router.navigate(['/search', 'card'], { queryParams: {
        id: fastLink.id,
        entityType: fastLink.type,
      }});
  }

  public onSelected(event: NgbTypeaheadSelectItemEvent) {
    this.suggestionSelected = false;
    if (event.item.isFastLink) {
      this.onFastLinkClick(event.item.suggest);
    } else {
      this.onSearch(this.removeTags(event.item?.suggest?.term));
    }
    this.suggestionSelected = true;
  }

  public clearSearch() {
    this.searchInputControl.setValue({ suggest: { term: '' } });
    this.searchInput.nativeElement.focus();
  }

  public hasFourLetters(text: string) {
    return text && text.length > 3;
  }

  public fastLinkValue(text: string, type: string) {
    text = this.removeTags(text);

    if (type === EntityTypes.CONCEPT) {
      return `${this.translateService.instant('FastLinkConcept')} ${text}`;
    } else if (type === EntityTypes.AGENT) {
      return `${this.translateService.instant('FastLinkAgent')} ${text}`;
    } else {
      return text;
    }
  }

  public removeTags(text: string) {
    return text?.replace(/<\/?em>/g, '');
  }

  public setInputBlur() {
    this.searchInput.nativeElement.blur();
  }

  public setFocusOnInputField() {
    this.searchInput.nativeElement.focus();
  }

  public onBlur() {
    this.inputFocus = false;
  }

  public onFocus() {
    this.inputFocus = true;
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public onDropdownChange(option: DropdownValue<string>) {
    const { selected, label } = option;
    const announceMessage = this.translateService.instant('searchTypeChangesAnnouncement', {value: label});
    this.liveAnnouncer.announce(announceMessage);
    this.selectedSearchType = selected;
    this.onSearch(this.searchInput.nativeElement.value);
  }

  public typeaheadKeydown(typeaheadElement: NgbTypeahead) {
    if (!this.instance.isPopupOpen()) {
      return;
    }

    setTimeout(() => {
      const popup = this.windowRef.nativeWindow().document.getElementById(typeaheadElement.popupId);
      if (popup) {
        const activeElements = popup.getElementsByClassName('active');
        if (activeElements.length === 1) {
          const elem = activeElements[0] as HTMLElement;
          elem.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }
    });
  }

  public onFocusOutEvent(term: string) {
    if (this.advancedSearch) {
      this.store.dispatch(focusoutSearchInput({query: term}));
    }
  }

  ngAfterViewInit(): void {
    if (this.searchInput !== undefined) {
      this.renderer.removeAttribute(this.searchInput.nativeElement, 'aria-multiline');
    }
    if (this.instance) {
      this.ariaInputControl = this.instance.popupId;
    }
  }

  private skipOnSearch(term: string): boolean {
    if (!term && !this.advancedSearch) {
      return true;
    }

    return this.advancedSearch && (!term || term === '*')
      && (!this.originalSearchQueryValue || this.originalSearchQueryValue === '*');
  }
}
