import {Component, Input, OnInit} from '@angular/core';
import {CustomerIntegrationService} from '../../../../services/customer-integration.service';
import {ConfigurationLoader} from 'shared/configuration-loader';
import {WindowRefService} from '../../../../../services/window-ref.service';

@Component({
  selector: 'app-nlb-borrow-n-deliver',
  templateUrl: './nlb-borrow-n-deliver.component.html',
  styleUrls: ['./nlb-borrow-n-deliver.component.scss']
})
export class NlbBorrowNDeliverComponent implements OnInit {
  @Input() public recordIds: string[];
  public redirectUrl: string
  constructor(
    private readonly configLoader: ConfigurationLoader,
    private readonly customerIntegrationService: CustomerIntegrationService,
    private readonly windowRef: WindowRefService,
  ) { }

  public ngOnInit() {
    const replacements: any = {'%s': this.recordIds[0]};
    this.redirectUrl = this.customerIntegrationService
      .CustomerIntegrationLinksMap[this.configLoader.customerIntegrationType]
      .borrowAndDeliverUrlTemplate
      .replace(/%\w+/g, (match) => {
        return replacements[match] || match;
      });
  }

  public onClick() {
    this.windowRef.nativeWindow().open(this.redirectUrl, '_blank');
  }
}
