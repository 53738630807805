import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap, withLatestFrom, } from 'rxjs/operators';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { setUser } from 'user/actions/user-profile.actions';
import { UserPermission } from 'user/models/user';
import * as UserReducer from 'user/reducers/user.reducer';
import { login } from '../../../../keycloak/actions/keycloak.actions';
import { isAuthorizedInKeycloak } from '../../../../keycloak/reducers/keycloak.reducer';
import { CustomerIntegrationType } from '../../../customer-integration';
import {
  createNlbBookmark,
  createNlbBookmarkFailure,
  createNlbBookmarkSuccess,
  deleteNlbBookmark,
  deleteNlbBookmarkFailure,
  deleteNlbBookmarkSuccess,
  loadNlbBookmarks,
  loadNlbBookmarksFailure,
  loadNlbBookmarksSuccess,
  tryToCreateNlbBookmark
} from '../actions/nlb-bookmark.actions';
import { NlbBookmark } from '../models/nlb-bookmark';
import { NlbBookmarkService } from '../services/nlb-bookmark.service';

@Injectable()
export class NlbBookmarkEffects {
  public decideLoadNlbBookmarksOnLogin$ = createEffect(() => this.actions$.pipe(
    ofType(setUser),
    concatMap((action) => of(action).pipe(
      withLatestFrom(this.store.select(UserReducer.doesUserHavePermission(UserPermission.SHOWCASES_CREATE))),
    )),
    filter(([_, canCreateShowcase]) => {
      return this.configLoader.customerIntegrationType === CustomerIntegrationType.NLB && !canCreateShowcase;
    }),
    switchMap(() => [loadNlbBookmarks()])
  ));

  public loadNlbBookmarks$ = createEffect(() => this.actions$.pipe(
    ofType(loadNlbBookmarks),
    switchMap(() => {
      return this.nlbBookmarkService.loadBookmarks().pipe(
        map((nlbBookmarks: NlbBookmark[]) => loadNlbBookmarksSuccess({nlbBookmarks})),
        catchError(() => of(loadNlbBookmarksFailure())),
      );
    }),
  ));

  public createNlbBookmark$ = createEffect(() => this.actions$.pipe(
    ofType(createNlbBookmark),
    mergeMap(({formatGroup, tabName}) => {
      return this.nlbBookmarkService.createBookmark(formatGroup.id, tabName).pipe(
        switchMap((nlbBookmark: NlbBookmark) => [createNlbBookmarkSuccess({nlbBookmark, formatGroupId: formatGroup.id})]),
        catchError(() => of(createNlbBookmarkFailure({formatGroupId: formatGroup.id}))),
      );
    }),
  ));

  public tryToCreateNlbBookmark$ = createEffect(() => this.actions$.pipe(
    ofType(tryToCreateNlbBookmark),
    concatLatestFrom(() => this.store.select(isAuthorizedInKeycloak)),
    filter(([{formatGroup, tabName}, isAuthorized]) => {
      if (!isAuthorized) {
        const tree = this.router.createUrlTree([], {
          relativeTo: this.route,
          queryParams: {
            nlbBookmarkFgId: formatGroup.id,
            nlbBookmarkTabName: tabName,
          },
          queryParamsHandling: 'merge',
        }).toString();
        this.location.go(tree);
        this.store.dispatch(login());
      }
      return isAuthorized;
    }),
    map(([props]) => {
      return createNlbBookmark(props);
    }),
  ));

  public deleteNlbBookmark$ = createEffect(() => this.actions$.pipe(
    ofType(deleteNlbBookmark),
    mergeMap(({nlbBookmark, formatGroup}) => {
      return this.nlbBookmarkService.deleteBookmark(nlbBookmark.id).pipe(
        switchMap(() => [deleteNlbBookmarkSuccess({nlbBookmark, formatGroupId: formatGroup.id})]),
        catchError(() => of(deleteNlbBookmarkFailure({formatGroupId: formatGroup.id}))),
      );
    }),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly nlbBookmarkService: NlbBookmarkService,
    private readonly configLoader: ConfigurationLoader,
    private readonly router: Router,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
  ) {
  }
}
