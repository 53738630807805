export enum LOCAL_STORAGE {
  BEARER_TOKEN = 'bearerToken',
  BULK_HOLD = 'bulkHold',
  CUSTOM_DOMAIN = 'iii-customer-domain',
  OVERDRIVE_AUTH_ERROR = 'overdriveAuthError',
  PAGE_SIZE = 'pageSize',
  REFRESH_TOKEN = 'refreshToken',
  USER_INFO = 'userInfo',
  VIEW_TYPE = 'viewType',
}
