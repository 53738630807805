export interface Viewport {
  width: number;
  height: number;
}

export enum Breakpoints {
  RESOLUTION_MAX_XS = 575,
  RESOLUTION_MAX_SM = 767,
  RESOLUTION_MAX_MD = 991,
}
