import {
  AuthStorage,
  AuthTokenData,
} from '../models/citation';

export class OAuthHelper {
  public updateAuthStorage(STORAGE: AuthStorage, data: AuthTokenData) {
    this.clearAuthStorage(STORAGE);
    this.setAuthStorage(STORAGE, data);
  }

  public clearAuthStorage(STORAGE: AuthStorage) {
    localStorage.removeItem(STORAGE.ACCESS_TOKEN);
    localStorage.removeItem(STORAGE.REFRESH_TOKEN);
    localStorage.removeItem(STORAGE.EXPIRATION_DATE);
  }

  public setAuthStorage(STORAGE: AuthStorage, data: AuthTokenData) {
    localStorage.setItem(STORAGE.ACCESS_TOKEN, data.access_token);
    localStorage.setItem(STORAGE.EXPIRATION_DATE, data.expiration_date);
    localStorage.setItem(STORAGE.REFRESH_TOKEN, data.refresh_token);
  }

  public shouldAuthorize(STORAGE: AuthStorage): boolean {
    return !localStorage.getItem(STORAGE.ACCESS_TOKEN);
  }

  public shouldRefreshToken(STORAGE: AuthStorage): boolean {
    const expirationDate = localStorage.getItem(STORAGE.EXPIRATION_DATE);
    return this.isTokenExpired(expirationDate);
  }

  public isTokenExpired(expirationDate: string): boolean {
    const expirationDateToken = new Date(expirationDate).toISOString();
    const currentDate = new Date().toISOString();
    return currentDate > expirationDateToken;
  }

  public getRefreshToken(STORAGE: AuthStorage): string {
    return localStorage.getItem(STORAGE.REFRESH_TOKEN);
  }
}
