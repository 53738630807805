import {
  AboutShowcase,
  AboutShowcaseAge,
  AboutShowcaseFormat,
  AboutShowcaseType,
  CustomShowcaseDisplayOn,
  CustomShowcaseMetadata,
} from './custom-showcase';

export const metadataDisplayOnDefaults: CustomShowcaseDisplayOn = {
  recordPages: true,
};

export const metadataDisplayOnSavedSearchDefaults: CustomShowcaseDisplayOn = {
  recordPages: false,
};

export const aboutShowcaseTypeDefaults: AboutShowcaseType = {
  fiction: false,
  nonfiction: false,
};

export const aboutShowcaseAgeDefaults: AboutShowcaseAge = {
  preschool: false,
  primary: false,
  preteen: false,
  teen: false,
  adult: false,
  juvenile: false,
};

export const aboutShowcaseFormatDefaults: AboutShowcaseFormat = {
  a: false,
  l: false,
  v: false,
  g: false,
  u: false,
  x: false,
  h: false,
  y: false,
  i: false,
  '-': false,
};

export const aboutShowcaseDefaults: AboutShowcase = {
  type: aboutShowcaseTypeDefaults,
  age: aboutShowcaseAgeDefaults,
  format: aboutShowcaseFormatDefaults,
  startDate: '',
  endDate: '',
  deleteExpired: false,
};

export const customShowcaseMetadataDefaults: CustomShowcaseMetadata = {
  sortOrder: 'date.desc',
  displayOn: metadataDisplayOnDefaults,
  displayCreator: true,
  aboutShowcase: aboutShowcaseDefaults,
};

export const customShowcaseMetadataSavedSearchDefaults: CustomShowcaseMetadata = {
  sortOrder: null,
  displayOn: metadataDisplayOnSavedSearchDefaults,
  displayCreator: true,
  aboutShowcase: aboutShowcaseDefaults,
};

