import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as Immutable from 'immutable';
import { emailEntity, emailEntityComplete, emailEntityFailure, resetEntityEmailState } from '../actions/share-it.actions';
import { EmailSavedSearchError } from '../models/share-it';

export interface EmailEntityState {
  sending: boolean;
  error: EmailSavedSearchError | null;
  sent: boolean;
}

export interface ShareItState {
  emailEntityState: Immutable.Record<EmailEntityState>;
}

const EmailEntityStateRecord = Immutable.Record<EmailEntityState>({
  sending: false,
  error: null,
  sent: false,
});

export const initialState: ShareItState = {
  emailEntityState: EmailEntityStateRecord(),
};

const shareItReducer = createReducer(
  initialState,
  on(emailEntity, (state) => ({
    ...state,
    emailEntityState: EmailEntityStateRecord({ sending: true }),
  })),
  on(emailEntityComplete, (state) => ({
    ...state,
    emailEntityState: EmailEntityStateRecord({ sent: true }),
  })),
  on(emailEntityFailure, (state, { error }) => ({
    ...state,
    emailEntityState: EmailEntityStateRecord({ error }),
  })),
  on(resetEntityEmailState, (state) => ({
    ...state,
    emailEntityState: EmailEntityStateRecord(),
  })),
);

export function reducer(state: ShareItState | undefined, action: Action) {
  return shareItReducer(state, action);
}

export const shareItFeatureKey = 'shareIt';

export const getShareItState = createFeatureSelector<ShareItState>(shareItFeatureKey);

export const getEmailEntityState = createSelector(getShareItState, (state): EmailEntityState => {
  return (state && state.emailEntityState) ? (state.emailEntityState.toJS() as EmailEntityState) : null;
});
