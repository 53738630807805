import { Component, Input } from '@angular/core';
import { FormatGroup } from '../../../../entity/models/entity';

@Component({
  selector: 'app-bulk-hold-item-overview',
  templateUrl: './bulk-hold-item-overview.component.html',
  styleUrls: ['./bulk-hold-item-overview.component.scss']
})
export class BulkHoldItemOverviewComponent {
  @Input() formatGroup: FormatGroup;
}
