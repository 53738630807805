import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WindowRefService } from 'app/services/window-ref.service';

@Injectable({
  providedIn: 'root',
})

export class CookieNotificationService {
  public seen$ = new BehaviorSubject<boolean>(false);
  public localStorage: any;
  public seen = false;

  constructor(private readonly windowRef: WindowRefService) {
    this.localStorage = this.windowRef.nativeWindow().localStorage;
    const isCookieAccepted = this.localStorage.getItem('cookie-notification');
    if (isCookieAccepted) {
      this.seen$.next(JSON.parse(isCookieAccepted));
    }
  }

  public accept() {
    this.localStorage.setItem('cookie-notification', true);
    this.seen$.next(true);
  }
}
