import { Permissions } from 'app/permissions/models/permissions';
import { KeycloakTokenParsed } from 'keycloak-js';

export enum KeycloakRole {
  'PATRON' = 'PATRON',
  'STAFF' = 'STAFF',
  'III_ADMIN' = 'III-ADMIN',
  'FEDERATED_STAFF' = 'FEDERATED-STAFF',
  'PROMOTED_PATRON' = 'PROMOTED-PATRON'
}

export type KeycloakLoginError = 'incorrect-credentials' | 'unknown';
export type KeycloakTokenParsedWithClaims = KeycloakTokenParsed & {
  preferred_username: string;
  auth_time: number;
  given_name?: string;
  family_name?: string;
  email?: string;
  resource_access: {
    convergence: {
      roles: [KeycloakRole.PATRON | KeycloakRole.STAFF | KeycloakRole.III_ADMIN | KeycloakRole.FEDERATED_STAFF | KeycloakRole.PROMOTED_PATRON];
    };
  };
  authorization: {
    permissions: Permissions[]
  }
}

export interface UserInfo {
  userName: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  roles: string[];
}

export interface AuthData {
  userInfo: UserInfo,
  bearerToken: string;
  refreshToken: string;
}

export interface TokenData {
  access_token: string;
  refresh_token: string;
}
