<div class="p-4">
  <app-modal-header
    title="{{'getItSuccessDialogTitle' | translate}}"
    titleId="get-it-success-dialog-title"
    closeId="closeButton"
    (close)="onClose()">
  </app-modal-header>
  <div class="modal-body">
    <div class="d-flex" data-automation-id="get-it-success-dialog-message">
      <div>
        <span class="fas fa-check-circle fa-4x success-icon" aria-hidden="true"></span>
      </div>
      <div class="pl-4">
        <p translate>holdProcessingMessage</p>
        <p translate>youCanCheckHoldStatusMessage</p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="ins-buttons button-primary flint"
            data-automation-id="get-it-success-dialog-ok-button"
            (click)="onClose()"
            translate>
      ok
    </button>
  </div>
</div>
