export enum Vendor {
  CLOUD_LIBRARY = 'cloudlibrary',
  BORROW_BOX = 'borrowbox',
  AXIS360 = 'axis360',
  OVERDRIVE = 'overdrive',
  HOOPLA = 'hoopla',
  GATES = 'gates',
  OAI_PMH = 'oaipmh'
}

export const ECONTENT_VENDORS = [
  Vendor.CLOUD_LIBRARY,
  Vendor.AXIS360,
  Vendor.OVERDRIVE,
  Vendor.HOOPLA,
  Vendor.BORROW_BOX
];
