import {
  Injectable,
  InjectionToken,
} from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { ReplaySubject } from 'rxjs';

export const NOTIFICATION_PARAMS = new InjectionToken<{}>('NOTIFICATION_PARAMS');

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  public showNotification$ = new ReplaySubject<{component: ComponentType<any>, params: any}>();

  public showNotification(component: ComponentType<any>, params: any) {
    this.showNotification$.next({component, params});
  }
}
