<perfect-scrollbar class="ps-show-always" *ngIf="(checkoutsState$ | async) as checkoutsState"
                   removeTabindexOnYScroll
                   [ngStyle]="{'max-height': maxHeightPerfectScrollbar}">
  <div class="account-tab-content">
    <div class="page-title d-flex justify-content-between mb-3">
      <div data-automation-id="checkouts-page-title">
        <h2>{{'accountTabCheckouts' | translate}} |
          <ng-container *ngIf="!checkoutsState.loadingState.loading">{{checkoutsState.cards.length}}</ng-container>
        </h2>
      </div>
      <div *ngIf="checkoutsState.cards.length">
        <app-bulk-renew-button (renew)="onRenewCheckouts($event, checkoutsState.cards)">
        </app-bulk-renew-button>
        <app-sort-button
          [sortFields]="checkoutsSortFields"
          [currentSortField]="checkoutsState.sortingCriteria"
          (onSort)="sortCheckouts($event)">
        </app-sort-button>
      </div>
    </div>

    <ng-container *ngIf="checkoutsState.loadingState.loading || checkoutsState.loadingState.error; else checkouts">
      <div class="d-flex justify-content-center text-white" role="status" aria-live="polite">
        <div *ngIf="checkoutsState.loadingState.loading"
             class="mt-5 spinner-border items-loading-spinner"
             data-automation-id="checkout-items-loading-spinner">
          <span class="sr-only" translate>Loading...</span>
        </div>
      </div>
      <div aria-atomic="true" aria-live="assertive" role="alert">
        <div *ngIf="checkoutsState.loadingState.error"
             class="m-4 text-white"
             data-automation-id="checkout-items-loading-error">
          {{'generalServerError' | translate}}
        </div>
      </div>
    </ng-container>

    <ng-template #checkouts>
      <div class="page-body">
        <ol class="mb-0">
          <li *ngFor="let card of checkoutsState.cards; trackBy: trackById"
              class="d-block mb-5 mt-2">
            <app-checkout-card [card]="card"></app-checkout-card>
          </li>
        </ol>
      </div>
    </ng-template>
  </div>
</perfect-scrollbar>
