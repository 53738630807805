<ol>
  <li class="days-range" *ngFor="let daysRange of data; index as i">
    <div class="days-range__title"
         [attr.data-automation-id]="'range' + i">
      {{daysRange.title}}
    </div>

    <div *ngIf="daysRange.hours; else closed"
         class="days-range__hours"
         [attr.data-automation-id]="'hours' + i">
      {{daysRange.hours}}
    </div>
  </li>
</ol>

<ng-template #closed>
  <span class="hours" translate>closed</span>
</ng-template>
