import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  bulkRenew,
  changeCheckoutsSort,
  checkoutCardActionRequest,
  loadCheckouts
} from 'user/actions/user-profile.actions';
import { MaxHeightPerfectScrollbar } from 'user/models/bookshelf';
import { CheckoutCard, CheckoutCardActionType, CheckoutSortingCriteria } from 'user/models/checkouts';
import { SortField } from 'user/models/user-receipt-card';
import { getCheckoutsState } from 'user/reducers/user.reducer';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  BulkRenewProcessModalComponent
} from 'user/components/account/checkouts/bulk-renew/bulk-renew-process-modal/bulk-renew-process-modal.component';
import { Subscription } from 'rxjs';
import { Vendor } from 'user/models/vendor';
import { filter } from 'rxjs/operators';
import {
  checkoutToRenew,
  noRenewalsInProgress,
  processedItemsNumber,
  failedToRenewCheckouts,
  renewedCheckouts
} from 'user/reducers/bulk-renew.reducer';
import {
  RenewOptionsEnum
} from 'user/components/account/checkouts/bulk-renew/bulk-renew-button/bulk-renew-button.component';
import {
  BulkRenewDaysChoiceModalComponent
} from 'user/components/account/checkouts/bulk-renew/bulk-renew-days-choice-modal/bulk-renew-days-choice-modal.component';

@Component({
  selector: 'app-checkouts',
  templateUrl: './checkouts.component.html',
  styleUrls: ['./checkouts.component.scss'],
})
export class CheckoutsComponent implements OnInit, OnDestroy {
  public checkoutsState$ = this.store.select(getCheckoutsState);
  public maxHeightPerfectScrollbar = MaxHeightPerfectScrollbar.BASE_ACCOUNT_TAB;
  public readonly checkoutsSortFields: SortField<CheckoutSortingCriteria>[] = [
    {field: 'dueDate', translationKey: 'sortByDueDate'},
    {field: 'title', translationKey: 'sortByTitle'},
  ];
  private readonly subscriptions: Subscription = new Subscription();
  private canCloseModal: boolean;

  constructor(
    private readonly store: Store,
    private readonly modal: NgbModal
  ) {
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.store.select(checkoutToRenew)
        .pipe(filter((checkoutToRenew) => checkoutToRenew !== null))
        .subscribe((id) => {
          this.store.dispatch(checkoutCardActionRequest({
            actionType: CheckoutCardActionType.RenewCheckout,
            checkoutId: id
          }));
        })
    );

    this.subscriptions.add(
      this.store.select(noRenewalsInProgress)
        .subscribe((noRenewalsInProgress) => {
          this.canCloseModal = noRenewalsInProgress;
        })
    );

    this.store.dispatch(loadCheckouts());
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public trackById(index: number, card: CheckoutCard): string {
    return card.receipt.id;
  }

  public sortCheckouts(field: CheckoutSortingCriteria): void {
    this.store.dispatch(changeCheckoutsSort({sortingCriteria: field}));
  }

  public onRenewCheckouts(option: RenewOptionsEnum, checkouts: CheckoutCard[]) {
    if (option === RenewOptionsEnum.OVERDUE) {
      const renewableItems = this.getRenewableItems(option, checkouts);
      this.showProgressModal(renewableItems);
      return;
    }

    const modal = this.modal.open(BulkRenewDaysChoiceModalComponent, {size: 'sm'});
    modal.componentInstance.title = option === RenewOptionsEnum.DUE_SOON ? 'renewDueSoonItems' : 'renewOverdueAndDueSoonItems';
    modal.result.then((numberOfDays) => {
      const renewableItems = this.getRenewableItems(option, checkouts, numberOfDays);
      this.showProgressModal(renewableItems);
    }, () => {
    });
  }

  private checkDaysLeft(date: string, renewOption: RenewOptionsEnum, daysRange?: number) {
    const now = new Date();
    const endOfTheDay = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());
    const dueDateTime = new Date(date);
    const dueDate = new Date(dueDateTime.getUTCFullYear(), dueDateTime.getUTCMonth(), dueDateTime.getUTCDate());
    const daysLeft = (dueDate.getTime() - endOfTheDay.getTime()) / (24 * 3600 * 1000);

    if (renewOption === RenewOptionsEnum.OVERDUE) {
      return daysLeft < 0;
    } else if (renewOption === RenewOptionsEnum.DUE_SOON) {
      return daysLeft <= daysRange && daysLeft >= 0;
    } else if (renewOption === RenewOptionsEnum.OVERDUE_AND_DUE_SOON) {
      return daysLeft <= daysRange;
    }
  };

  private getRenewableItems(renewOption: RenewOptionsEnum, checkouts: CheckoutCard[], daysRange?: number): CheckoutCard[] {
    return checkouts.filter((card) => {
      return card.receipt.vendor === Vendor.GATES && this.checkDaysLeft(card.receipt.dueDate, renewOption, daysRange);
    });
  }

  private showProgressModal(renewableItems: CheckoutCard[]): void {
    const modal = this.modal.open(BulkRenewProcessModalComponent, {
      size: 'sm',
      beforeDismiss: () => {
        return this.canCloseModal;
      }
    });

    if (renewableItems.length) {
      modal.componentInstance.title = 'processingRenewals';
      modal.componentInstance.noRenewalsInProgress$ = this.store.select(noRenewalsInProgress);
      modal.componentInstance.renewedCheckouts$ = this.store.select(renewedCheckouts);
      modal.componentInstance.failedToRenewCheckouts$ = this.store.select(failedToRenewCheckouts);
      modal.componentInstance.processedItemsNumber$ = this.store.select(processedItemsNumber);
      modal.componentInstance.totalItemsNumber = renewableItems.length;
      this.store.dispatch(bulkRenew({cards: renewableItems}));
    } else {
      modal.componentInstance.title = 'noItemsToRenew';
    }
  }
}
