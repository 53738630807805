import { Injectable } from '@angular/core';

@Injectable()
export class ApiVersionService {

  private readonly versionConfig = {
    mappings: new Map([
      ['api/search', '1'],
      ['api/search-result', '1'],
      ['api/gates-edge', '1'],
      ['api/explore', '1'],
      ['api/customization', '1'],
      ['api/customer', '1'],
      ['status', '1'],
      ['api/customization/configuration', '1'],
      ['token', '1'],
      ['api/share-it', '1'],
      ['api/personalization', '1'],
    ]),

    default: '1',
  };

  public getVersion(url: string): string {
    for (const versionMapping of Array.from(this.versionConfig.mappings.entries())) {
      if (url.startsWith(versionMapping[0])) {
        return versionMapping[1];
      }
    }
    return this.versionConfig.default;
  }
}
