import { Directive, Input, TemplateRef, } from '@angular/core';
import { BookshelfCardShareActionType } from 'common/models/bookshelf-card';

@Directive({
  selector: '[cardShareAction]',
})
export class CardShareActionDirective {
  @Input() public cardShareAction: BookshelfCardShareActionType;

  constructor(
    public template: TemplateRef<any>,
  ) {
  }
}
