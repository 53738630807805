<div cdkTrapFocus cdkTrapFocusAutoCapture class="global-sidebar-container" [class.active]="isActive" role="dialog">
  <div class="backdrop" aria-hidden="true" data-automation-id="global-sidebar-backdrop" (click)="closeSidebar()"></div>

  <aside class="content" data-automation-id="global-sidebar-panel">
    <perfect-scrollbar removeTabindexOnYScroll [config]="{ suppressScrollX: true }">
      <section class="custom-links-container" *ngIf="customLinks?.length">
        <h3 class="custom-links-title" data-automation-id="custom-links-title">{{'allLinksTitle' | translate}}</h3>

        <div class="custom-links" role="navigation" data-automation-id="custom-links-container">
          <div role="list">
            <a *ngFor="let link of customLinks" class="custom-link" [href]="link.href" target="_blank" role="listitem">{{link.title}}</a>
          </div>
        </div>
      </section>
    </perfect-scrollbar>
  </aside>

  <button
    type="button"
    class="close-button"
    [attr.aria-label]="'closeSidebar' | translate"
    data-automation-id="global-sidebar-close-button"
    (click)="closeSidebar()"
  >
    <span aria-hidden="true" class="icon-close"></span>
  </button>
</div>
