import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { BulkFeatureKey } from '../../../bulk-select/enums/bulk-feature-key';
import { tryToGetItRollup, tryToPlaceHoldOnRollup } from '../../actions/entity.actions';
import { flyInItemsAnimation } from '../../animations/fly-in-items';
import { FormatGroup, GetItRequest, HoldRequest } from '../../models/entity';
import { PaginatorPagination } from '../../models/paginator';
import { ViewType } from '../../models/view';

@Component({
  selector: 'app-entities',
  templateUrl: './entities.component.html',
  styleUrls: ['./entities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [flyInItemsAnimation],
})
export class EntitiesComponent {
  @Input() entities: FormatGroup[];
  @Input() isEmpty: boolean;
  @Input() pagination?: PaginatorPagination;
  @Input() view: ViewType = null;
  @Input() bulkFeatureKey: BulkFeatureKey;
  @Output() pageSelected = new EventEmitter<number>();

  public readonly viewType = ViewType;

  constructor(
    private readonly store: Store,
  ) {
  }

  public getItForRollupCard(data: { item: GetItRequest }): void {
    this.store.dispatch(tryToGetItRollup({item: data.item}));
  }

  public placeHoldOnRollupCard(data: { item: HoldRequest }): void {
    this.store.dispatch(tryToPlaceHoldOnRollup({item: data.item}));
  }

  public trackById(index: number, item: FormatGroup): string {
    return item.id;
  }
}
