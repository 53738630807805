export enum MaxHeightPerfectScrollbar {
  XS = 'calc(100% - 55px)',
  BASE = 'calc(100% - 29px)',
  BASE_ACCOUNT_TAB = 'calc(100% - 49px)',
}

export enum AccountTabs {
  CHECKOUTS = 'checkouts',
  HOLDS = 'holds',
  FINES = 'fines-and-fees',
  PROFILE = 'profile',
}
