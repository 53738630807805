<a class="help-button"
   [href]="helpUrl"
   target="_blank"
   role="button"
   data-automation-id="header-help-button"
   cdkMonitorElementFocus
   attr.aria-label="{{ 'help' | translate }}"
   tippy="{{ 'help' | translate }}">
  <span class="icon icon-library-help"></span>
</a>
