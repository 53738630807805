import { Action, createFeatureSelector, createReducer, createSelector, on, } from '@ngrx/store';
import * as Immutable from 'immutable';
import { AllowedActions } from 'user/models/allowed-actions';
import { AccountTabs } from 'user/models/bookshelf';
import { FineError, FinesData, FinesPaymentStatus } from 'user/models/fine';
import { PasscodeUpdatedStateRecord, PasscodeUpdateStatus } from 'user/models/passcode';
import { ResourceInfo } from 'user/models/resource-item-data';
import { UserReceiptActionStatus } from 'user/models/user-receipt-card';
import { Vendor } from 'user/models/vendor';
import { loadPatronUser, loadPatronUserFailure, loadPatronUserSuccess, } from '../actions/patron-user.actions';
import {
  bookmarksTabOpened,
  changeCheckoutsSort,
  changeHoldLocationSuccess,
  changeHoldsSort,
  checkoutCardActionFailure,
  checkoutCardActionRequest,
  checkoutCardActionSuccess,
  checkoutRequested,
  clearCheckoutCardActionRequest,
  collapseBookshelf,
  focusLibraryInfoButton,
  focusUserPanelButton,
  highlightHeaderBookmarkButton,
  holdCancelSuccess,
  holdCardActionFailure,
  holdCardActionRequest,
  holdCardActionSuccess,
  holdFreezeSuccess,
  holdRequested,
  holdUnfreezeSuccess,
  loadCheckouts,
  loadCheckoutsFailure,
  loadCheckoutsSuccess,
  loadFines,
  loadFinesFailure,
  loadFinesSuccess,
  loadHolds,
  loadHoldsFailure,
  loadHoldsSuccess,
  loadStaffUser,
  loadTabHold,
  loadTabHoldComplete,
  loadUser,
  openBookmarksTab,
  openBookshelfAccountTab,
  openShowcasesTab,
  removeCheckout,
  resetCheckoutsState,
  resetFinesPaymentStatus,
  resetFinesState,
  resetHoldsState,
  resetPatronPasscodeState,
  resetUpdateProfileState,
  setFinesPaymentStatus,
  setStaffUser,
  setUser,
  showcasesTabOpened,
  stillActive,
  stopCollapseBookshelf,
  stopFocusLibraryInfoButton,
  stopFocusUserPanelButton,
  stopHighlightHeaderBookmarkButton,
  stopOpenBookshelfAccountTab,
  stopToggleBookmarks,
  toggleBookmarks,
  toggleOpenedLibraryInfoWidget,
  toggleOpenedUserPanel,
  updateCheckout,
  updatePatronPasscode,
  updatePatronPasscodeFailure,
  updatePatronPasscodeSuccess,
  updateProfile,
  updateProfileFailure,
  updateProfilePartialSuccess,
  updateProfileSuccess,
  updateShouldCloseModal,
} from '../actions/user-profile.actions';
import { Checkout, CheckoutCard, CheckoutsLoadingState, CheckoutSortingCriteria, CheckoutsState, } from '../models/checkouts';
import { Hold, HoldCard, HoldsLoadingState, HoldSortingCriteria, HoldsState, HoldStatus } from '../models/holds';
import { PatronUser, ProfileConfiguration, ProfileUpdateError, StaffUser, User, UserAddress, UserPermission } from '../models/user';

export interface FinesState {
  loading: boolean;
  error: FineError | null;
  finesData: FinesData | null;
}

export interface ProfileUpdateState {
  loading: boolean;
  error: ProfileUpdateError | null;
  updated: boolean;
}

export interface CheckoutsStateStored {
  loadingState: Immutable.Record<CheckoutsLoadingState>;
  cards: Immutable.List<Immutable.Record<CheckoutCard>>;
  sortingCriteria: CheckoutSortingCriteria;
}

export interface HoldsStateStored {
  loadingState: Immutable.Record<HoldsLoadingState>;
  cards: Immutable.List<Immutable.Record<HoldCard>>;
  sortingCriteria: HoldSortingCriteria;
}

const FinesStateRecord = Immutable.Record<FinesState>({
  loading: false,
  error: null,
  finesData: null,
});

const ProfileUpdateStateRecord = Immutable.Record<ProfileUpdateState>({
  loading: false,
  error: null,
  updated: false,
});

export const CheckoutsLoadingStateRecord = Immutable.Record<CheckoutsLoadingState>({
  loading: false,
  error: null,
});

export const CheckoutsStateRecord = Immutable.Record<CheckoutsStateStored>({
  loadingState: CheckoutsLoadingStateRecord(),
  cards: Immutable.List(),
  sortingCriteria: 'dueDate',
});

export const HoldsLoadingStateRecord = Immutable.Record<HoldsLoadingState>({
  loading: false,
  error: null,
});

export const HoldsStateRecord = Immutable.Record<HoldsStateStored>({
  loadingState: HoldsLoadingStateRecord(),
  cards: Immutable.List(),
  sortingCriteria: 'status',
});

export const HoldCardRecordFactory = Immutable.Record<HoldCard>({
  receipt: null,
  action: null,
});

export type HoldCardRecord = Immutable.Record<HoldCard>;
const makeHoldCard = (hold: Hold): HoldCard => ({ receipt: hold, action: null });

export const CheckoutCardRecordFactory = Immutable.Record<CheckoutCard>({
  receipt: null,
  action: null,
});

export type CheckoutCardRecord = Immutable.Record<CheckoutCard>;

const makeCheckoutCard = (checkout: Checkout) => CheckoutCardRecordFactory({ receipt: checkout });

export interface UserState {
  currentUser: User | null;
  currentStaffUser : StaffUser | null;
  isUserLoading: boolean;
  isStaffUserLoading: boolean;
  lastActionTime: number;
  openedUserPanel: boolean;
  openedLibraryInfoWidget: boolean;
  requestedHoldIds?: InProgressHold[];
  requestedCheckoutIds?: string[];
  isCollapseBookshelfPending: boolean;
  isToggleBookmarksPending: boolean;
  openBookshelfAccountTabPending: AccountTabs | null;
  isHighlightHeaderBookmarkButtonPending: boolean;
  checkoutsState: Immutable.Record<CheckoutsStateStored>;
  holdsState: Immutable.Record<HoldsStateStored>;
  finesState: Immutable.Record<FinesState>;
  finesPaymentStatus: FinesPaymentStatus | null;
  profileUpdateState: Immutable.Record<ProfileUpdateState>;
  doOpenShowcasesTab: boolean;
  doOpenBookmarksTab: boolean;
  doFocusLibraryInfoButton: boolean;
  doFocusUserPanelButton: boolean;
  patronUser: PatronUser;
  isPatronUserLoading: boolean;
  passcodeUpdateStatus: Immutable.Record<PasscodeUpdateStatus>;
  shouldCloseGetIdDialog: boolean;
  getIdDialogErrorMessage: string;
  getItLoading: boolean;
}

export interface InProgressHold {
  holdId: string;
  isSierra: boolean;
}

export const initialState: UserState = {
  currentUser: null,
  isUserLoading: false,
  currentStaffUser: null,
  isStaffUserLoading: false,
  requestedHoldIds: [],
  requestedCheckoutIds: [],
  lastActionTime: Date.now(),
  openedUserPanel: false,
  openedLibraryInfoWidget: false,
  isCollapseBookshelfPending: false,
  isToggleBookmarksPending: false,
  openBookshelfAccountTabPending: null,
  isHighlightHeaderBookmarkButtonPending: false,
  checkoutsState: CheckoutsStateRecord(),
  holdsState: HoldsStateRecord(),
  finesState: FinesStateRecord(),
  finesPaymentStatus: null,
  profileUpdateState: ProfileUpdateStateRecord(),
  doOpenShowcasesTab: false,
  doOpenBookmarksTab: false,
  doFocusLibraryInfoButton: false,
  doFocusUserPanelButton: false,
  patronUser: null,
  isPatronUserLoading: false,
  passcodeUpdateStatus: PasscodeUpdatedStateRecord(),
  shouldCloseGetIdDialog: true,
  getIdDialogErrorMessage: '',
  getItLoading: false,
};

const mappedHoldStatuses = new Map([
  [HoldStatus.ItemReadyToPickUp, 0],
  [HoldStatus.BibReadyToPickUp, 0],
  [HoldStatus.VolumeReadyToPickUp, 0],
  [HoldStatus.OverdriveItemReadyToBorrow, 0],
  [HoldStatus.InTransit, 1],
  [HoldStatus.Waiting, 2],
  [HoldStatus.Pending, 3],
]);

function _sortCheckouts(checkouts: CheckoutCard[], sortingCriteria: CheckoutSortingCriteria): CheckoutCard[] {
  return checkouts.slice().sort((first: CheckoutCard, second: CheckoutCard) => {
    switch (sortingCriteria) {
      case 'dueDate':
        if (first.receipt.dueDate !== second.receipt.dueDate) {
          if (first.receipt.dueDate === null) {
            return 1;
          } else if (second.receipt.dueDate === null) {
            return -1;
          } else {
            return new Date(first.receipt.dueDate).getTime() - new Date(second.receipt.dueDate).getTime();
          }
        } else {
          return _compareResources(first.receipt.resource, second.receipt.resource);
        }

      case 'title':
        return _compareResources(first.receipt.resource, second.receipt.resource);
    }
  });
}

function _sortHolds(holds: HoldCard[], sortingCriteria: HoldSortingCriteria): HoldCard[] {
  return holds.slice().sort((first: HoldCard, second: HoldCard) => {
    switch (sortingCriteria) {
      case 'status': {
        const firstStatus = mappedHoldStatuses.get(first.receipt.status);
        const secondStatus = mappedHoldStatuses.get(second.receipt.status);
        const statusesDifference = firstStatus - secondStatus;

        if (!statusesDifference) {
          if (firstStatus === 0 && first.receipt.expirationDate !== second.receipt.expirationDate) {
            return _sortByDate(first.receipt.expirationDate, second.receipt.expirationDate);
          } else if (first.receipt.status === HoldStatus.Waiting) {
            return (first.receipt.frozen || second.receipt.frozen) ?
              _sortForFrozen(first, second) : _sortForNotFrozen(first, second);
          } else {
            return _compareResources(first.receipt.resource, second.receipt.resource);
          }
        }

        return statusesDifference;
      }

      case 'title': {
        return _compareResources(first.receipt.resource, second.receipt.resource);
      }
    }
  });
}

function _compareResources(first: ResourceInfo, second: ResourceInfo): number {
  if (!first) {
    return 1;
  } else if (!second) {
    return -1;
  } else {
    return first.title?.localeCompare(second.title);
  }
}

function _sortByDate(first: string, second: string) {
  return new Date(first).getTime() - new Date(second).getTime();
}

function _sortForFrozen(first: HoldCard, second: HoldCard) {
  return +first.receipt.frozen - +second.receipt.frozen || _compareResources(first.receipt.resource, second.receipt.resource);
}

function _sortForNotFrozen(first: HoldCard, second: HoldCard) {
  if (first.receipt.neededBy === null) {
    return 1;
  } else if (second.receipt.neededBy === null) {
    return -1;
  } else {
    return _sortByDate(first.receipt.neededBy, second.receipt.neededBy);
  }
}

function _updateAllowedActions(allowedActions: string[], frozen: boolean): string[] {
  let actions = allowedActions;
  actions = actions.filter(a => a !== AllowedActions.FREEZE && a !== AllowedActions.UNFREEZE);
  actions.push(frozen ? AllowedActions.UNFREEZE : AllowedActions.FREEZE);
  return actions;
}

export function reducer(state = initialState, action: Action): UserState {
  return userReducer(state, action);
}

const userReducer = createReducer(
  initialState,
  on(loadUser, (state) => ({
    ...state,
    isUserLoading: true,
  })),
  on(setUser, (state, {user}) => ({
    ...state,
    currentUser: user,
    isUserLoading: false,
    requestedHoldIds: [],
  })),
  on(loadStaffUser, (state) => ({
    ...state,
    isStaffUserLoading: true,
  })),
  on(setStaffUser, (state, {staffUser}) => ({
    ...state,
    currentStaffUser: staffUser,
    isStaffUserLoading: false,
  })),
  on(stillActive, (state) => ({
    ...state,
    lastActionTime: Date.now(),
  })),
  on(holdRequested, (state, {holdId, isSierra}) => ({
    ...state,
    requestedHoldIds: state.requestedHoldIds.concat({holdId, isSierra}),
  })),
  on(checkoutRequested, (state, {reserveId}) => ({
    ...state,
    requestedCheckoutIds: state.requestedCheckoutIds.concat(reserveId),
  })),
  on(loadFines, (state) => ({
    ...state,
    finesState: FinesStateRecord({ loading: true }),
  })),
  on(loadFinesSuccess, (state, { finesData }) => ({
    ...state,
    finesState: FinesStateRecord({ finesData }),
  })),
  on(loadFinesFailure, (state, { error }) => ({
    ...state,
    finesState: FinesStateRecord({ error }),
  })),
  on(resetFinesState, (state) => ({
    ...state,
    finesState: FinesStateRecord(),
  })),
  on(setFinesPaymentStatus, (state, { paymentStatus }) => ({
    ...state,
    finesPaymentStatus: paymentStatus,
  })),
  on(updateProfile, (state) => ({
    ...state,
    profileUpdateState: ProfileUpdateStateRecord({ loading: true }),
  })),
  on(updateProfileSuccess, (state, { profileUpdated }) => ({
    ...state,
    currentUser: { ...state.currentUser, ...profileUpdated },
    profileUpdateState: ProfileUpdateStateRecord({ updated: true }),
  })),
  on(updateProfilePartialSuccess, (state, { profileUpdated }) => ({
    ...state,
    currentUser: { ...state.currentUser, ...profileUpdated },
  })),
  on(updateProfileFailure, (state, { error }) => ({
    ...state,
    profileUpdateState: ProfileUpdateStateRecord({ error }),
  })),
  on(resetUpdateProfileState, (state) => ({
    ...state,
    profileUpdateState: ProfileUpdateStateRecord(),
  })),
  on(loadCheckouts, (state) => ({
    ...state,
    checkoutsState: state.checkoutsState.withMutations((checkoutsState) => {
      checkoutsState.set('loadingState', CheckoutsLoadingStateRecord({ loading: true }));
    }),
  })),
  on(loadCheckoutsSuccess, (state, { checkouts }) => ({
    ...state,
    checkoutsState: state.checkoutsState.withMutations((checkoutsState) => {
      checkoutsState.set('loadingState', CheckoutsLoadingStateRecord())
        .set('cards', sortCheckoutCards(checkouts.map((checkout) => makeCheckoutCard(checkout)), state.checkoutsState.get('sortingCriteria')));
    }),
  })),
  on(loadCheckoutsFailure, (state, { error }) => ({
    ...state,
    checkoutsState: state.checkoutsState.withMutations((checkoutsState) => {
      checkoutsState.set('loadingState', CheckoutsLoadingStateRecord({ error }))
        .set('cards', Immutable.List());
    }),
  })),
  on(resetCheckoutsState, (state) => ({
    ...state,
    checkoutsState: state.checkoutsState.withMutations((checkoutsState) => {
      checkoutsState.set('loadingState', CheckoutsLoadingStateRecord())
        .set('cards', Immutable.List());
    }),
  })),
  on(changeCheckoutsSort, (state, { sortingCriteria }) => ({
    ...state,
    checkoutsState: state.checkoutsState.withMutations((checkoutsState) => {
      checkoutsState.set('sortingCriteria', sortingCriteria)
        .set('cards', sortCheckoutCards(checkoutsState.get('cards').toJS() as CheckoutCard[], sortingCriteria));
    }),
  })),
  on(loadHolds, (state) => ({
    ...state,
    holdsState: state.holdsState.withMutations((holdsState) => {
      holdsState.set('loadingState', HoldsLoadingStateRecord({ loading: true }));
    }),
  })),
  on(loadHoldsSuccess, (state, { holds }) => ({
    ...state,
    holdsState: state.holdsState.withMutations((holdsState) => {
      holdsState.set('loadingState', HoldsLoadingStateRecord())
        .set('cards', sortHoldCards(holds.map((hold) => makeHoldCard(hold)), holdsState.get('sortingCriteria')));
    }),
  })),
  on(loadHoldsFailure, (state, { error }) => ({
    ...state,
    holdsState: state.holdsState.withMutations((holdsState) => {
      holdsState.set('loadingState', HoldsLoadingStateRecord({ error }))
        .set('cards', Immutable.List());
    }),
  })),
  on(resetHoldsState, (state) => ({
    ...state,
    holdsState: state.holdsState.withMutations((holdsState) => {
      holdsState.set('loadingState', HoldsLoadingStateRecord())
        .set('cards', Immutable.List());
    }),
  })),
  on(holdCardActionRequest, (state: UserState, { actionType, holdId }) => (
    updateHoldCardIfFound(
      state,
      (holdCard) => holdCard.getIn(['receipt', 'id']) === holdId,
      (holdCard) => (
        holdCard.set('action', {
          type: actionType,
          status: UserReceiptActionStatus.Pending,
          error: null,
        })
      ),
    )
  )),
  on(holdCardActionSuccess, (state: UserState, { actionType, holdId }) => (
    updateHoldCardIfFound(
      state,
      (holdCard) => holdCard.getIn(['receipt', 'id']) === holdId,
      (holdCard) => (
        holdCard.set('action', {
          type: actionType,
          status: UserReceiptActionStatus.Success,
          error: null,
        })
      ),
    )
  )),
  on(holdCardActionFailure, (state: UserState, { actionType, holdId, error }) => (
    updateHoldCardIfFound(
      state,
      (holdCard) => holdCard.getIn(['receipt', 'id']) === holdId,
      (holdCard) => (
        holdCard.set('action', {
          type: actionType,
          status: UserReceiptActionStatus.Failed,
          error,
        })
      ),
    )
  )),
  on(holdFreezeSuccess, (state: UserState, { holdId }) => {
    return updateHoldCardIfFound(
      state,
      (holdCard) => holdCard.get('receipt').id === holdId,
      (holdCard) => (
        holdCard.set('receipt', {
          ...holdCard.get('receipt'),
          frozen: true,
          allowedActions: _updateAllowedActions(holdCard.get('receipt').allowedActions, true),
      })),
    );
  }),
  on(holdUnfreezeSuccess, (state: UserState, { holdId }) => {
    return updateHoldCardIfFound(
      state,
      (holdCard) => holdCard.get('receipt').id === holdId,
      (holdCard) => (
        holdCard.set('receipt', {
          ...holdCard.get('receipt'),
          frozen: false,
          allowedActions: _updateAllowedActions(holdCard.get('receipt').allowedActions, false),
        })),
    );
  }),
  on(holdCancelSuccess, (state: UserState, { holdId, holdRecordId }) => (
    deleteHoldCardIfFound(
      state,
      (holdCard) => holdCard.get('receipt').id === holdId,
      (userState) => ({
        ...userState,
        requestedHoldIds: userState.requestedHoldIds.filter((hold) => hold.holdId !== holdRecordId),
      }),
    )
  )),
  on(changeHoldsSort, (state, { sortingCriteria }) => ({
    ...state,
    holdsState: state.holdsState.withMutations((holdsState) => {
      holdsState.set('sortingCriteria', sortingCriteria)
        .set('cards', sortHoldCards(holdsState.get('cards').toJS() as HoldCard[], sortingCriteria));
    }),
  })),
  on(changeHoldLocationSuccess, (state: UserState, { holdId, pickupLocationName, locationCode }) => {
    return updateHoldCardIfFound(
      state,
      (holdCard) => holdCard.get('receipt').id === holdId,
      (holdCard) => holdCard
        .setIn(['receipt', 'location'], pickupLocationName)
        .setIn(['receipt', 'locationCode'], locationCode),
    );
  }),
  on(resetFinesPaymentStatus, (state) => ({
    ...state,
    finesPaymentStatus: null,
  })),
  on(checkoutCardActionRequest, (state: UserState, { checkoutId, actionType }) => (
    updateCheckoutCardIfFound(
      state,
      (checkoutCard) => checkoutCard.get('receipt').id === checkoutId,
      (checkoutCard) => (
        checkoutCard.set('action', {
          type: actionType,
          status: UserReceiptActionStatus.Pending,
          error: null,
        })
      ),
    )
  )),
  on(checkoutCardActionSuccess, (state: UserState, { checkoutId, actionType }) => (
    updateCheckoutCardIfFound(
      state,
      (checkoutCard) => checkoutCard.get('receipt').id === checkoutId,
      (checkoutCard) => (
        checkoutCard.set('action', {
          type: actionType,
          status: UserReceiptActionStatus.Success,
          error: null,
        })
      ),
    )
  )),
  on(checkoutCardActionFailure, (state: UserState, { checkoutId, actionType, error }) => (
    updateCheckoutCardIfFound(
      state,
      (checkoutCard) => checkoutCard.get('receipt').id === checkoutId,
      (checkoutCard) => (
        checkoutCard.set('action', {
          type: actionType,
          status: UserReceiptActionStatus.Failed,
          error,
        })
      ),
    )
  )),
  on(clearCheckoutCardActionRequest, (state: UserState, { checkoutId }) => (
    updateCheckoutCardIfFound(
      state,
      (checkoutCard) => checkoutCard.get('receipt').id === checkoutId,
      (checkoutCard) => checkoutCard.set('action', null),
    )
  )),
  on(updateCheckout, (state: UserState, { checkout }) => (
    updateCheckoutCardIfFound(
      state,
      (checkoutCard) => checkoutCard.get('receipt').id === checkout.id,
      (checkoutCard) => checkoutCard.set('receipt', checkout),
    )
  )),
  on(removeCheckout, (state: UserState, { checkoutId }) => (
    deleteCheckoutCardIfFound(
      state,
      (checkoutCard) => checkoutCard.get('receipt').id === checkoutId,
    )
  )),
  on(openBookshelfAccountTab, (state, { accountTab }) => ({
    ...state,
    openBookshelfAccountTabPending: accountTab,
  })),
  on(stopOpenBookshelfAccountTab, (state) => ({
    ...state,
    openBookshelfAccountTabPending: null,
  })),
  on(openShowcasesTab, (state) => ({
    ...state,
    doOpenShowcasesTab: true,
  })),
  on(showcasesTabOpened, (state) => ({
    ...state,
    doOpenShowcasesTab: false,
  })),
  on(openBookmarksTab, (state) => ({
    ...state,
    doOpenBookmarksTab: true,
  })),
  on(bookmarksTabOpened, (state) => ({
    ...state,
    doOpenBookmarksTab: false,
  })),
  on(collapseBookshelf, (state) => ({
    ...state,
    isCollapseBookshelfPending: true,
  })),
  on(stopCollapseBookshelf, (state) => ({
    ...state,
    isCollapseBookshelfPending: false,
  })),
  on(toggleBookmarks, (state) => ({
    ...state,
    isToggleBookmarksPending: true,
  })),
  on(stopToggleBookmarks, (state) => ({
    ...state,
    isToggleBookmarksPending: false,
  })),
  on(toggleOpenedUserPanel, (state, {isOpened}) => ({
    ...state,
    openedUserPanel: isOpened,
  })),
  on(toggleOpenedLibraryInfoWidget, (state, {isOpened}) => ({
    ...state,
    openedLibraryInfoWidget: isOpened,
  })),
  on(focusLibraryInfoButton, (state) => ({
    ...state,
    doFocusLibraryInfoButton: true,
  })),
  on(stopFocusLibraryInfoButton, (state) => ({
    ...state,
    doFocusLibraryInfoButton: false,
  })),
  on(focusUserPanelButton, (state) => ({
    ...state,
    doFocusUserPanelButton: true,
  })),
  on(stopFocusUserPanelButton, (state) => ({
    ...state,
    doFocusUserPanelButton: false,
  })),
  on(highlightHeaderBookmarkButton, (state) => ({
    ...state,
    isHighlightHeaderBookmarkButtonPending: true,
  })),
  on(stopHighlightHeaderBookmarkButton, (state) => ({
    ...state,
    isHighlightHeaderBookmarkButtonPending: false,
  })),
  on(loadTabHold, (state) => {
    return ({
      ...state,
      getItLoading: true,
      getIdDialogErrorMessage: undefined
    });
  }),
  on(loadTabHoldComplete, (state) => {
    return ({
      ...state,
      getItLoading: false,
    });
  }),
  on(updateShouldCloseModal, (state: UserState, { shouldCloseModal, errorMessage }) => {
    return ({
      ...state,
      shouldCloseGetIdDialog: shouldCloseModal,
      getIdDialogErrorMessage: errorMessage
    });
  }),
  on(
    loadPatronUser,
    (state) => ({ ...state, patronUser: null, isPatronUserLoading: true })),
  on(loadPatronUserSuccess, (state, { patronUser }) => ({ ...state, patronUser, isPatronUserLoading: false })),
  on(loadPatronUserFailure, (state) => ({ ...state, isPatronUserLoading: false })),
  on(updatePatronPasscode, (state) => ({
    ...state,
    passcodeUpdateStatus: PasscodeUpdatedStateRecord({loading: true}),
  })),
  on(updatePatronPasscodeSuccess, (state) => ({
    ...state,
    passcodeUpdateStatus: PasscodeUpdatedStateRecord({success: true}),
  })),
  on(updatePatronPasscodeFailure, (state, {error}) => ({
    ...state,
    passcodeUpdateStatus: PasscodeUpdatedStateRecord({error})
  })),
  on(resetPatronPasscodeState, (state) => ({
    ...state,
    passcodeUpdateStatus: PasscodeUpdatedStateRecord()
  })),
);

export const getUserState = createFeatureSelector<UserState>('user');

export const getUser = createSelector(getUserState, (userState) => userState.currentUser);
export const getStaffUser = createSelector(getUserState, (userState) => userState.currentStaffUser);

export const doesUserHavePermission = (permission: UserPermission) => createSelector(
  getUser, getStaffUser,
  (user, staffUser) => {
    const permissions = user?.permissions || staffUser?.showcasePermissions || [];
    return permissions.includes(permission);
  }
);

export const getUserId = createSelector(getUser, (user) => user && user.id);

export const getStaffUserId = createSelector(getStaffUser, (staffUser): string | null => {
  return staffUser ? `keycloak:${staffUser?.info?.id}` : null;
});

export const getPatronOrStaffUserId = createSelector(getUserId, getStaffUserId, (userId, staffUserId): string | null => {
  return userId || staffUserId;
});

export const getUserNickname = createSelector(getUser, (user): string | null => {
  return user ? user.nickname.trim() : null;
});

export const getStaffUserName = createSelector(getStaffUser, (staffUser): string | null => {
  return staffUser ? `${staffUser.info.firstName} ${staffUser.info.lastName}` : null;
});

export const getUserFirstAddress = createSelector(getUser, (user): UserAddress | null => {
  return user ? user.addresses[0] : null;
});

export const getUserNicknameOrName = createSelector(getUser, getUserNickname, (user, nickname): string | null => {
  const name = user ? user.name : null;
  return nickname || name;
});

export const getUserHomeLibraryCode = createSelector(getUser, (user) => user && user.homeLibraryCode);
export const getUserPreferredPickupLocationCode = createSelector(getUser, (user) => user && user.preferredPickupLocationCode);

const inProgressHoldIds = createSelector(getUserState, (state) => state.requestedHoldIds.map((hold) => hold.holdId));
const inProgressCheckouts = createSelector(getUserState, (state) => state.requestedCheckoutIds);

export const getUserPanelState = createSelector(getUserState, (userState) => userState.openedUserPanel);

export const getLibraryInfoWidgetState = createSelector(
  getUserState,
  (userState) => userState.openedLibraryInfoWidget,
);

export const getIsUserLoading = createSelector(getUserState, (userState) => userState.isUserLoading);

export const getMoneyOwed = createSelector(getUserState, (userState): number => {
  const finesData = userState.finesState.get('finesData');
  return finesData
    ? finesData.totalOutstandingAmount
    : (userState.currentUser) ? userState.currentUser.moneyOwed : null;
});

export const getInProgressSierraHoldIds = createSelector(getUserState, (state) => state.requestedHoldIds
  .filter((hold) => hold.isSierra)
  .map((hold) => hold.holdId));

export const getLastActionTime = createSelector(getUserState, (userState) => userState.lastActionTime);

export const getIsCollapseBookshelfPending = createSelector(getUserState, (state: UserState): boolean => {
  return state.isCollapseBookshelfPending;
});

export const getIsToggleBookmarksPending = createSelector(getUserState, (state: UserState): boolean => {
  return state.isToggleBookmarksPending;
});

export const getOpenBookshelfAccountTabPending = createSelector(getUserState, (state: UserState): AccountTabs | null => {
  return state.openBookshelfAccountTabPending;
});

export const getIsHighlightHeaderBookmarkButtonPending = createSelector(getUserState, (state: UserState): boolean => {
  return state.isHighlightHeaderBookmarkButtonPending;
});

export const getCheckoutsState = createSelector(getUserState, (state: UserState): CheckoutsState => {
  return state.checkoutsState.toJS() as CheckoutsState;
});

export const getCheckoutsTotal = createSelector(getCheckoutsState, (checkoutsState: CheckoutsState): number => {
  return checkoutsState.cards.length;
});

export const getCheckoutsCards = createSelector(getCheckoutsState, (checkoutsState: CheckoutsState): CheckoutCard[] => {
  return checkoutsState.cards;
});

export const getHoldsState = createSelector(getUserState, (state: UserState): HoldsState => {
  return state.holdsState.toJS() as HoldsState;
});

export const getHoldsTotal = createSelector(getHoldsState, (holdsState: HoldsState): number => {
  return holdsState.cards.length;
});

export const getFinesState = createSelector(getUserState, (state: UserState): FinesState | null => {
  return (state && state.finesState) ? state.finesState.toJS() as FinesState : null;
});

export const getFinesPaymentStatus = createSelector(getUserState, (state: UserState): FinesPaymentStatus | null => {
  return state.finesPaymentStatus;
});

export const getShouldCloseModal = createSelector(getUserState, (state: UserState): boolean => {
  return state.shouldCloseGetIdDialog;
});

export const isLoading = createSelector(getUserState, (state: UserState): boolean => {
  return state.getItLoading;
});

export const getErrorMessage = createSelector(getUserState, (state: UserState): string => {
  return state.getIdDialogErrorMessage;
});

export const getProfileUpdateState = createSelector(getUserState, (state: UserState): ProfileUpdateState => {
  return state.profileUpdateState.toJS() as ProfileUpdateState;
});

export const getProfileConfiguration = createSelector(getUserState, (state: UserState): ProfileConfiguration | null => {
  return state.currentUser ? state.currentUser.profileConfiguration : null;
});

export const getEnableReadingHistorySettingStatus = createSelector(getUserState, (state: UserState): boolean => {
  return state.currentUser?.profileConfiguration.fieldsConfiguration.enableReadingHistory;
});

export const getRequestedHoldsIds = createSelector(
  inProgressHoldIds,
  getHoldsState,
  (requestedHoldIds, holdsState) => {
    return requestedHoldIds.concat(
      holdsState.cards
        .filter((card) => card.receipt.resource)
        .map((card) => card.receipt.resource.instanceId));
  });

export const getOverDriveCheckedOutIds = createSelector(
  inProgressCheckouts,
  getCheckoutsState,
  (requestedCheckoutIds, checkoutsState) => {
    return requestedCheckoutIds.concat(
      checkoutsState.cards
        .filter((card) => card.receipt.vendor !== Vendor.GATES)
        .map((card) => card.receipt.id.toLowerCase()),
    );
  },
);

export const getPhysicalCheckedOutIds = createSelector(
  inProgressCheckouts,
  getCheckoutsState,
  (requestedCheckoutIds, checkoutsState) => {
    return requestedCheckoutIds.concat(
      checkoutsState.cards
        .filter((card) => card.receipt.vendor === Vendor.GATES && card.receipt.resource)
        .map((card) => card.receipt.resource.instanceId),
    );
  },
);

export const getUserRequestedIds = createSelector(getRequestedHoldsIds, getOverDriveCheckedOutIds,
  (holdIds, checkedOutIds) => {
    return holdIds.concat(checkedOutIds);
  });

export const getDoOpenShowcasesTab = createSelector(getUserState, (state: UserState): boolean => {
  return state.doOpenShowcasesTab;
});

export const getDoOpenBookmarksTab = createSelector(getUserState, (state: UserState): boolean => {
  return state.doOpenBookmarksTab;
});

export const getDoFocusUserPanelButton = createSelector(getUserState, (state: UserState): boolean => {
  return state.doFocusUserPanelButton;
});

export const getDoFocusLibraryInfoButton = createSelector(getUserState, (state: UserState): boolean => {
  return state.doFocusLibraryInfoButton;
});

export const getPasscodeUpdateStatus = createSelector(getUserState, (state: UserState): PasscodeUpdateStatus => {
  return state.passcodeUpdateStatus.toJS();
});

export const getPatronUser = createSelector(getUserState, (state: UserState): PatronUser => state.patronUser);

const updateCheckoutCardIfFound = (
  state: UserState,
  checkoutCardPredicate: (checkoutCard: CheckoutCardRecord) => boolean,
  callback: (checkoutCard: CheckoutCardRecord) => CheckoutCardRecord,
): UserState => {
  let newState = state;
  let someCheckoutCardMutated = false;

  const checkoutCardsMutated = state.checkoutsState.get('cards').withMutations((checkoutCards) => {
    const checkoutCardIndex = checkoutCards.findIndex(checkoutCardPredicate);

    if (checkoutCardIndex >= 0) {
      someCheckoutCardMutated = true;
      checkoutCards.updateIn([checkoutCardIndex], callback);
    }
  });

  if (someCheckoutCardMutated) {
    newState = {
      ...newState,
      checkoutsState: newState.checkoutsState.set('cards', checkoutCardsMutated),
    };
  }

  return newState;
};

const deleteCheckoutCardIfFound = (
  state: UserState,
  checkoutCardPredicate: (checkoutCard: CheckoutCardRecord) => boolean,
): UserState => {
  let newCheckoutCards;
  let updatedRequestedCheckoutIds: string[];
  let isCheckoutCardsMutated = false;
  const checkoutCards = state.checkoutsState.get('cards');
  const requestedCheckoutIds = state.requestedCheckoutIds;
  const checkoutCardIndex = checkoutCards.findIndex(checkoutCardPredicate);
  if (checkoutCardIndex >= 0) {
    isCheckoutCardsMutated = true;
    newCheckoutCards = checkoutCards.delete(checkoutCardIndex);
    updatedRequestedCheckoutIds = requestedCheckoutIds.filter((el) => {
      return el.toLowerCase() !== (checkoutCards.toJS() as CheckoutCard[])[checkoutCardIndex].receipt.id.toLowerCase();
    });

    return {
      ...state,
      checkoutsState: state.checkoutsState.set('cards', newCheckoutCards),
      requestedCheckoutIds: updatedRequestedCheckoutIds
    };
  }

  return state;
};

const updateHoldCardIfFound = (
  state: UserState,
  holdCardPredicate: (holdCard: HoldCardRecord) => boolean,
  callback: (holdCard: HoldCardRecord) => HoldCardRecord,
): UserState => {
  let newState = state;
  let someHoldCardMutated = false;

  const holdCardsMutated = state.holdsState.get('cards').withMutations((holdCards) => {
    const holdCardIndex = holdCards.findIndex(holdCardPredicate);

    if (holdCardIndex >= 0) {
      someHoldCardMutated = true;
      holdCards.updateIn([holdCardIndex], callback);
    }
  });

  if (someHoldCardMutated) {
    newState = {
      ...newState,
      holdsState: newState.holdsState.set('cards', holdCardsMutated),
    };
  }

  return newState;
};

const deleteHoldCardIfFound = (
  state: UserState,
  holdCardPredicate: (holdCard: HoldCardRecord) => boolean,
  stateCallback?: (state: UserState) => UserState,
): UserState => {
  let newState = state;
  let newHoldCards;
  let isHoldCardsMutated = false;
  const holdCards = state.holdsState.get('cards');
  const holdCardIndex = holdCards.findIndex(holdCardPredicate);

  if (holdCardIndex >= 0) {
    isHoldCardsMutated = true;
    newHoldCards = holdCards.delete(holdCardIndex);

    if (stateCallback) {
      newState = { ...newState, ...stateCallback(newState) };
    }
  }

  if (isHoldCardsMutated) {
    return {
      ...newState,
      holdsState: newState.holdsState.set('cards', newHoldCards),
    };
  }

  return newState;
};

const sortCheckoutCards = (
  checkoutCards: CheckoutCard[],
  sort: CheckoutSortingCriteria,
): Immutable.List<CheckoutCardRecord> => {
  const sortedCards = _sortCheckouts(checkoutCards, sort);
  return Immutable.List(sortedCards.map((card) => CheckoutCardRecordFactory(card)));
};

const sortHoldCards = (
  holdCards: HoldCard[],
  sort: HoldSortingCriteria,
): Immutable.List<Immutable.Record<HoldCard>> => {
  const sortedCards = _sortHolds(holdCards, sort);
  return Immutable.List(sortedCards.map((card) => HoldCardRecordFactory({ ...card })));
};
