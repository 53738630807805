export interface SearchTerm {
  id: string;
  entityType: string;
  value: string;
  operator: TermOperator;
  key?: string; // `${term.id}_${newGuid()}`
}

export enum TermOperator {
  AND = 'AND', OR= 'OR',
}

export const SearchTermWildcard: string = '*';
export const Advanced: string = 'advanced';
