import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { toggleSelectItem } from '../../actions/bulk-select.actions';
import { BulkFeatureKey } from '../../enums/bulk-feature-key';
import { BulkSelectItem } from '../../models/bulk-select-item';
import { BulkSelectState, isItemSelected } from '../../reducers/bulk-select.reducer';

@Component({
  selector: 'app-bulk-select-checkbox',
  templateUrl: './bulk-select-checkbox.component.html',
})
export class BulkSelectCheckboxComponent {
  @Input() bulkFeatureKey: BulkFeatureKey;
  @Input() item: BulkSelectItem;
  @Input() ariaTitleId: string;
  public isItemSelected$: Observable<boolean>;

  constructor(
    private readonly store: Store<BulkSelectState>,
  ) {
  }

  public ngOnInit() {
    this.isItemSelected$ = this.store.select(isItemSelected(this.bulkFeatureKey, this.item.id));
  }

  public onItemSelectChange() {
    this.store.dispatch(toggleSelectItem({bulkFeatureKey: this.bulkFeatureKey, item: this.item}));
  }
}
