import { Injectable } from '@angular/core';

@Injectable()
export class ModalQueueService {
  public queue: (() => void)[] = [];
  public noWaiting = false;

  public addToQueueAndStartIfPossible(openModal: () => void) {
    this.queue.push(openModal);
    if (this.noWaiting) {
      this.startQueue();
    }
  }

  public startQueue() {
    this.queue.forEach((openModal) => {
      openModal();
    });
    this.queue = [];
    this.noWaiting = false;
  }
}
