import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DictionaryItem, DictionaryTypes } from '../../../models/dictionaries';
import { DictionariesService } from '../../../services/dictionaries.service';
import { AvailabilityStatus, AvailabilityUpdateInfo, MaterialTab } from '../../models/entity';
import { VendorLogo } from 'shared/models/configuration';

@Component({
  selector: 'app-electronic-item-availability',
  templateUrl: './electronic-item-availability.component.html',
  styleUrls: ['./electronic-item-availability.component.scss'],
})
export class ElectronicItemAvailabilityComponent implements OnChanges {
  @Input() public availabilityUpdateInfo: AvailabilityUpdateInfo;
  @Input() public materialTab: MaterialTab;
  @Input() public vendorsLogo: { [key: string]: string };
  @Input() public nonIntegratedLogo: { [key: string]: VendorLogo };

  public statuses = AvailabilityStatus;
  public availabilityStatus: AvailabilityStatus;

  constructor(private readonly dictionariesService: DictionariesService) {}

  ngOnChanges() {
    if (this.inputsChanged) {
      const materialType = this.materialTab?.materialTypes?.[0];
      const formatByCode: DictionaryItem = this.dictionariesService.getDictionaryItemByCode(DictionaryTypes.MATERIAL_TYPES, materialType);

      this.availabilityStatus = formatByCode?.alwaysAvailable ? AvailabilityStatus.AVAILABLE : this.availabilityUpdateInfo?.status?.general;
    }
  }

  public getMatchingSubscriptions(): string | null {
    if (!this.nonIntegratedLogo || !this.availabilityUpdateInfo?.subscriptionId?.length) {
      return null;
    }

    const matchingId = this.availabilityUpdateInfo?.subscriptionId?.find(id => this.nonIntegratedLogo[id]);
    return matchingId ? this.nonIntegratedLogo[matchingId].url : null;
  }

  private inputsChanged(changes: SimpleChanges) {
    return changes.materialTab?.previousValue !== changes.materialTab?.currentValue
      && changes.availabilityUpdateInfo?.previousValue !== changes.availabilityUpdateInfo?.currentValue;
  }
}
