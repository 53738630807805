import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomerFeatureToggleDirective } from './directives/customer-feature-toggle.directive';


@NgModule({
  declarations: [
    CustomerFeatureToggleDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    CustomerFeatureToggleDirective,
  ],
  providers: [
  ]
})
export class CustomerIntegrationModule { }
