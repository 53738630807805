import { Component, Input } from '@angular/core';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WindowRefService } from 'app/services/window-ref.service';
import { EContentGetItPayload } from 'core/over-drive/models/over-drive';

@Component({
  selector: 'app-hoopla-get-it-successful-dialog',
  templateUrl: './hoopla-get-it-successful-dialog.component.html',
  styleUrls: ['./hoopla-get-it-successful-dialog.component.scss'],
})
export class HooplaGetItSuccessfulDialogComponent {
  @Input() public data: EContentGetItPayload;
  public faCheckCircle= faCheckCircle;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly windowRefService: WindowRefService,
  ) {
  }

  public navigateToLink() {
    this.windowRefService.nativeWindow().open(this.data.url, '_blank');
    this.activeModal.close();
  }
}
