import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SelectValue } from 'user/components/account/profile/profile-form-field-input-select/profile-form-field-input-select.component';
// eslint-disable-next-line max-len
import { SelectValueExtended } from 'user/components/account/profile/profile-form-field-input-select-extended/profile-form-field-input-select-extended.component';
import { MobilePhone, NotificationPreferenceData, NotificationSupportedTypes, notificationTranslationsMap, User } from 'user/models/user';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NotificationPreferenceService {
  constructor(private translateService: TranslateService) {}

  private maxPhoneNumbers = 3;

  public readonly notificationPreferenceNone: NotificationPreferenceData = {
    channel: 'none',
    preselectedPhone: null,
  };

  public getNotificationOptions(supportedTypes: NotificationSupportedTypes[], profileForm: UntypedFormGroup): SelectValue[] {
    return supportedTypes.map((type) => ({
      id: type,
      value: type,
      disabled: this.isOptionDisabled(profileForm, type)
    }));
  }

  public getNotificationOptionsExtended(supportedTypes: NotificationSupportedTypes[], profileForm: UntypedFormGroup): SelectValueExtended[] {
    const supportedTypesGeneral = supportedTypes.filter((type) => type !== 'phone' && type !== 'text');
    const supportedTypesPhoneAndText = supportedTypes.filter((type) => type === 'phone' || type === 'text');
    const mobilePhones = profileForm.get('mobilePhones').value.map((value: MobilePhone) => value.phone).filter((phone: string) => !!phone);
    const homePhones = profileForm.get('homePhones').value.filter((phone: string) => !!phone);

    let options: SelectValueExtended[] = this.getNotificationOptions(supportedTypesGeneral, profileForm)
    .map((option) => ({...option, outputValue: {channel: option.id, preselectedPhone: null} as NotificationPreferenceData}));

    supportedTypesPhoneAndText.forEach((channel) => {
      const userPhones = channel === 'phone' ? [...mobilePhones, ...homePhones] : mobilePhones;
      options = options.concat(this.getOptionsWithPhone(this.getPhonesArray(userPhones), channel));
    });

    return options;
  }

  public getSelectedValue(notificationPreference: NotificationPreferenceData): string {
    const channel = notificationPreference.channel;
    const phone = notificationPreference.preselectedPhone;
    return phone ? `${channel} ${phone}` : channel;
  }

  public isCurrentOptionSupported(user: User): boolean {
    const supportedTypes = user.profileConfiguration.notificationConfiguration?.supportedTypes;
    return supportedTypes.includes(user.notificationPreference.channel);
  }

  public translateNotificationOptions<T extends SelectValue | SelectValueExtended>(options: T[]): T[] {
    return options.map(option => {
      const translationKey = notificationTranslationsMap[option.id as NotificationSupportedTypes];
      return translationKey ? { ...option, value: this.translateService.instant(translationKey) } : option;
    });
  }

  private getOptionsWithPhone(userPhones: string[], channel: NotificationSupportedTypes): SelectValueExtended[] {
    return userPhones.map((phone, id) => {
      const optionId = id + 1;
      const value = `${channel} ${optionId}`;

      return {
        id: `${channel} ${phone ? phone : optionId}`,
        value,
        disabled: !phone,
        description: phone,
        outputValue: phone ? {channel, preselectedPhone: phone} : null,
      } as SelectValueExtended;
    });
  }

  private isOptionDisabled(form: UntypedFormGroup, type: NotificationSupportedTypes): boolean {
    const mobilePhones = form.get('mobilePhones').value;
    const homePhones = form.get('homePhones').value;
    const emails = form.get('emails').value;

    const options: { [key: string]: boolean } = {
      text: !mobilePhones.filter((phone: string) => !!phone).length && !homePhones.filter((phone: string) => !!phone).length,
      phone: !mobilePhones.filter((phone: string) => !!phone).length && !homePhones.filter((phone: string) => !!phone).length,
      email: !emails.filter((email: string) => !!email).length
    };

    return !!options[type];
  }

  private getPhonesArray(phones: string[]): string[] {
    const phonesPlaceholder = Array.from({length: this.maxPhoneNumbers}).map(() => '');
    return [...phones, ...phonesPlaceholder].slice(0, this.maxPhoneNumbers);
  }
}
