import { FacetSchemaWithData } from 'search/facets/models/facet-schema-with-data';
import { FacetState, FacetURLFilters, } from 'search/facets/models/facet-state';
import { Facet, FacetsLocked } from 'search/facets/models/resource-facet';

export abstract class FacetsActionsProcessor {
  public abstract processFacetsLoaded(oldState: FacetState, appliedFilters: FacetURLFilters,
                                      schemaWithData: FacetSchemaWithData, facetsLocked: FacetsLocked): FacetState;

  public abstract processFacetSelected(oldState: FacetState, selectedFacet: Partial<Facet>): FacetState;

  public abstract processReplaceSelectedFacets(oldState: FacetState, selectedFacets: Facet[]): FacetState;

  public abstract processFacetLocked(oldState: FacetState, selectedFacet?: Partial<Facet>): FacetState;

  public abstract processBubblesReset(oldState: FacetState, resetAll: boolean): FacetState;

  public abstract processBubbleRemoved(oldState: FacetState, facetId?: string): FacetState;

  public abstract processExpandFacets(oldState: FacetState, expanded: boolean, checkSelected?: boolean): FacetState;

  public abstract processFacetBlockSelected(oldState: FacetState): FacetState;

  public abstract processApplyFacets(oldState: FacetState): FacetState;

  public abstract processCancelEditingFacets(oldState: FacetState): FacetState;

  public abstract processResetFacetState(oldState: FacetState): FacetState;

  public abstract getAppliedFiltersForLock(schemaWithData: FacetSchemaWithData, filters: FacetURLFilters): string[];

  public processLoadFacetsStarted(oldState: FacetState): FacetState {
    return oldState.set('isLoading', true);
  }

  public processCollapseFacets(oldState: FacetState): FacetState {
    return oldState.set('expanded', false);
  }
}
