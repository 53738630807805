import { OverDriveFormat } from 'core/over-drive/models/over-drive-formats';
import { ResourceInfo } from 'user/models/resource-item-data';
import { Vendor } from 'user/models/vendor';
import { VendorType } from '../../entity/models/entity';
import { UserReceiptAction, UserReceiptCard } from './user-receipt-card';

interface BaseCheckout<V extends Vendor | null> {
  id: string;
  dueDate?: string;
  renewalCount?: number;
  checkOutDate?: string;
  vendor: V;
  resource?: ResourceInfo;
}

interface OverdriveAttributes {
  reserveId: string;
  expireDate: string;
  checkOutDate: string;
  formats: (OverDriveFormat | string)[];
  availableFormats?: (OverDriveFormat | string)[];
  isFormatLockedIn: boolean;
  parentMagazineReferenceId: string
}

interface HooplaAttributes {
  contentId: string;
  message?: string;
  remainingCheckouts?: number;
  url?: string;
}

export interface EContentAttributes {
  checkOutDate: string;
  contentId: string;
  dueDate: string;
  message?: string;
  titleUrl?: string;
  vendor: Vendor;
}

type VendorProvidedAttributes = OverdriveAttributes | HooplaAttributes;

type VendorProvidedData<V extends Vendor, A extends VendorProvidedAttributes> = {
  [key in V]: A;
};

export type GatesCheckout = BaseCheckout<Vendor.GATES>;
export type OverdriveCheckout = BaseCheckout<Vendor.OVERDRIVE> & VendorProvidedData<Vendor.OVERDRIVE, OverdriveAttributes>;
export type HooplaCheckout = BaseCheckout<Vendor.HOOPLA> & VendorProvidedData<Vendor.HOOPLA, HooplaAttributes>;
export type Axis360Checkout = BaseCheckout<Vendor.AXIS360> & VendorProvidedData<Vendor.AXIS360, EContentAttributes>;
export type CloudLibraryCheckout = BaseCheckout<Vendor.CLOUD_LIBRARY> & VendorProvidedData<Vendor.CLOUD_LIBRARY, EContentAttributes>;
export type BorrowboxCheckout = BaseCheckout<Vendor.BORROW_BOX> & VendorProvidedData<Vendor.BORROW_BOX, EContentAttributes>;
export interface EContentCheckoutDetails {
  contentId: string;
  checkOutDate: string;
  dueDate: string;
  vendor: VendorType;
  titleUrl?: string;
}

export interface Axis360CheckoutRequestParams {
  format: string;
}

export type EContentCheckoutRequestParams = Axis360CheckoutRequestParams | {};

export type Checkout = GatesCheckout | OverdriveCheckout | HooplaCheckout | Axis360Checkout | CloudLibraryCheckout | BorrowboxCheckout;

export enum CheckoutCardActionType {
  RenewCheckout = 'renewCheckout',
  CheckIn = 'checkIn',
  GetItem = 'getItem',
  GetDownloadRedirect = 'GetDownloadRedirect',
}

export type CheckoutCardAction = UserReceiptAction<CheckoutCardActionType>;

export type CheckoutCard = UserReceiptCard<Checkout, CheckoutCardAction>;

export interface CheckoutCardActionPayload {
  actionType: CheckoutCardActionType;
  payload?: any;
}

export interface CheckoutsLoadingState {
  loading: boolean;
  error: CheckoutsError | null;
}

export interface CheckoutsState {
  loadingState: CheckoutsLoadingState;
  cards: CheckoutCard[];
  sortingCriteria: CheckoutSortingCriteria;
}

export interface CheckoutsError {
  status: number;
  message: string;
}

export type CheckoutSortingCriteria = 'dueDate' | 'title';

export interface OverdriveCheckoutFormats {
  owned: (OverDriveFormat | string)[];
  ownedUnknown: { id: string, name: string }[];
  available: (OverDriveFormat | string)[];
  lockedIn: boolean;
  chosen: {
    name: string;
  } | null;
}

export function isOverdriveCheckout(checkout: Checkout): checkout is OverdriveCheckout {
  return checkout.vendor === Vendor.OVERDRIVE;
}

export function isHooplaCheckout(checkout: Checkout): checkout is HooplaCheckout {
  return checkout.vendor === Vendor.HOOPLA;
}
