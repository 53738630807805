import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomerIntegrationLinks, CustomerIntegrationType } from '../customer-integration';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerIntegrationService {
  private nlbLinksUrl = 'api/search-result/customer-integration/nlb/links';

  public CustomerIntegrationLinksMap: {[key in CustomerIntegrationType]?: CustomerIntegrationLinks} = {};

  constructor(private readonly http: HttpClient) {
  }

  private addLinksToMap(links: CustomerIntegrationLinks, type: CustomerIntegrationType) {
    this.CustomerIntegrationLinksMap = {
      ...this.CustomerIntegrationLinksMap,
      [type]: links,
    };
  }

  private getNlbLinks() {
    return this.http.get(this.nlbLinksUrl).pipe(
      tap((links: CustomerIntegrationLinks) => {
        this.addLinksToMap(links, CustomerIntegrationType.NLB);
      })).toPromise();
  }

  public async getCustomerIntegrationLinks(type: CustomerIntegrationType) {
    switch (type) {
      case CustomerIntegrationType.NLB:
        await this.getNlbLinks();
        break;
      default:
        await Promise.resolve();
    }
  }
}
