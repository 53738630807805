import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EmbeddableShowcaseConfigDto } from '../models/custom-showcase.dto';
import { Observable } from 'rxjs';

@Injectable()
export class EmbeddableShowcaseService {
  private static readonly embeddableShowcaseUrl = 'api/search-result/embeddable-showcases/configuration';

  constructor(private readonly http: HttpClient) { }

  public getEmbeddableShowcases(): Observable<EmbeddableShowcaseConfigDto> {
    return this.http.get<EmbeddableShowcaseConfigDto>(`${EmbeddableShowcaseService.embeddableShowcaseUrl}`);
  }
}
