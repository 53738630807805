import { LicenseToggleKey } from '../../models/feature-toggle';
import { License } from '../../models/license';

export const WebCustomizationRankedLicenses: LicenseToggleKey[] = [
  'licenseDiscoverConsortia',
  'licenseDiscoverPremium',
  'licenseDiscoverPlus',
  'licenseDiscovery'
];

export const WebCustomizationInvertedLicenseMapping: ReadonlyMap<LicenseToggleKey, License> = new Map([
  ['licenseDiscoverConsortia', License.DISCOVER_CONSORTIA],
  ['licenseDiscoverPremium', License.DISCOVER_PREMIUM],
  ['licenseDiscoverPlus', License.DISCOVER_PLUS],
  ['licenseDiscovery', License.DISCOVERY]
]);

export const WebCustomizationDefaultLicense: LicenseToggleKey = 'licenseDiscovery';
