import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[lifecycleHooks]',
})
export class LifecycleHooksDirective implements AfterViewInit, OnDestroy {
  @Output() public afterViewInit = new EventEmitter<ElementRef>();
  @Output() public onDestroy = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {
  }

  public ngAfterViewInit(): void {
    this.afterViewInit.emit(this.elementRef);
    this.afterViewInit.complete();
  }

  public ngOnDestroy(): void {
    this.onDestroy.emit();
    this.onDestroy.complete();
  }
}
