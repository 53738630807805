import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, } from '@angular/router';
import { Actions, createEffect, ofType, } from '@ngrx/effects';
import { select, Store, } from '@ngrx/store';
import { isAuthorizedInKeycloak } from 'app/keycloak/reducers/keycloak.reducer';
import { filter, map, withLatestFrom, } from 'rxjs/operators';
import { SearchResult } from 'search/models/search-results';
import { Edition, VendorType } from '../models/entity';
import { beginHold, beginTabHold, tabHoldNlb, toggleOpenedUserPanel, } from 'user/actions/user-profile.actions';
import { tryToGetIt, tryToGetItRollup, tryToGetItTab, tryToPlaceHold, tryToPlaceHoldOnRollup } from '../actions/entity.actions';
import { PrepareExternalGetItAction, PrepareExternalHoldRequestAction, } from '../actions/vendors.actions';
import { RollupCard } from '../models/rollup-card';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { CustomerIntegrationType } from 'app/customer-integration/customer-integration';
import { VendorIssuesService } from '../services/vendor-issues.service';


@Injectable()
export class GetItEffects {
  public tryToGetIt$ = createEffect(() => this.actions$.pipe(
    ofType(tryToGetIt),
    withLatestFrom(this.store.pipe(select(isAuthorizedInKeycloak))),
    filter(([{item, vendor, reserveId}, isAuthorized]) => {
      if (!isAuthorized) {
        const tree = this.router.createUrlTree([], {
          relativeTo: this.route,
          queryParams: {getResourceId: item.id, getResourceVendor: vendor, getReserveId: reserveId, getRecordId: (item as Edition).recordId},
          queryParamsHandling: 'merge',
        }).toString();
        this.location.go(tree);
        this.store.dispatch(toggleOpenedUserPanel({isOpened: true}));
      }

      return isAuthorized;
    }),
    map(([{item, vendor, reserveId}]) => {
      const hasEcontentIssues = this.vendorIssuesService.hasVendorIssuesForEdition(item as Edition);
      return vendor && reserveId ? new PrepareExternalGetItAction({
        entityType: (item as SearchResult | RollupCard).entityType,
        formatGroupId: (item as SearchResult | RollupCard).formatGroupId,
        instanceId: (item as SearchResult | RollupCard).instanceId,
        vendor,
        itemId: item.id,
        reserveId,
        hasEcontentIssues: hasEcontentIssues,
      }) : beginHold({payload: item});
    }),
  ));

  public tryToGetItTab$ = createEffect(() => this.actions$.pipe(
    ofType(tryToGetItTab),
    withLatestFrom(this.store.pipe(select(isAuthorizedInKeycloak))),
    filter(([{cardInfo}, isAuthorized]) => {
      if (!isAuthorized) {
        const tree = this.router.createUrlTree([], {
          relativeTo: this.route,
          queryParams: {
            getTabFgId: cardInfo.id,
            getTabName: cardInfo.tabName
          },
          queryParamsHandling: 'merge',
        }).toString();
        this.location.go(tree);
        this.store.dispatch(toggleOpenedUserPanel({isOpened: true}));
      }

      return isAuthorized;
    }),
    map(([{cardInfo}]) => {
      if (this.configLoader.customerIntegrationType === CustomerIntegrationType.NLB) {
        return tabHoldNlb({ payload: cardInfo });
      } else {
        return beginTabHold({ payload: cardInfo });
      }
    }),
  ));

  public tryToGetItRollupCard$ = createEffect(() => this.actions$.pipe(
    ofType(tryToGetItRollup),
    withLatestFrom(this.store.pipe(select(isAuthorizedInKeycloak))),
    filter(([{item}, isAuthorized]) => {
      if (!isAuthorized) {
        const tree = this.router.createUrlTree([], {
          relativeTo: this.route,
          queryParams: {getResourceId: item.id, getResourceVendor: item.vendor, getReserveId: item.reserveId},
          queryParamsHandling: 'merge',
        }).toString();
        this.location.go(tree);
        this.store.dispatch(toggleOpenedUserPanel({isOpened: true}));
      }

      return isAuthorized;
    }),
    map(([{item}]) => {
      return item.reserveId && item.vendor ? new PrepareExternalGetItAction({
        entityType: item.entityType,
        formatGroupId: item.formatGroupId,
        instanceId: item.instanceId,
        vendor: item.vendor,
        itemId: item.id,
        reserveId: item.reserveId,
        readyHold: item.readyHold,
        hasEcontentIssues: item.hasEcontentIssues,
      }) : beginHold({ payload: item });
    }),
  ));

  public tryToPlaceHold$ = createEffect(() => this.actions$.pipe(
    ofType(tryToPlaceHold),
    withLatestFrom(this.store.pipe(select(isAuthorizedInKeycloak))),
    filter(([{item, vendor, reserveId}, isAuthorized]) => {
      if (!isAuthorized) {
        const tree = this.router.createUrlTree([], {
          relativeTo: this.route,
          queryParams: {holdResourceId: item?.id, holdResourceVendor: vendor, holdReserveId: reserveId},
          queryParamsHandling: 'merge',
        }).toString();
        this.location.go(tree);
        this.store.dispatch(toggleOpenedUserPanel({isOpened: true}));
      }

      return isAuthorized;
    }),
    map(([{item, vendor, reserveId}]) => {
      return new PrepareExternalHoldRequestAction({
        vendor,
        itemId: item?.id,
        reserveId: reserveId || item?.vendors[vendor as VendorType].reserveId[0],
      });
    }),
  ));

  public tryToPlaceHoldOnRollup$ = createEffect(() => this.actions$.pipe(
    ofType(tryToPlaceHoldOnRollup),
    withLatestFrom(this.store.pipe(select(isAuthorizedInKeycloak))),
    filter(([{item}, isAuthorized]) => {
      if (!isAuthorized) {
        const tree = this.router.createUrlTree([], {
          relativeTo: this.route,
          queryParams: {holdResourceId: item.id, holdResourceVendor: item.vendor, holdReserveId: item.reserveId},
          queryParamsHandling: 'merge',
        }).toString();
        this.location.go(tree);
        this.store.dispatch(toggleOpenedUserPanel({isOpened: true}));
      }

      return isAuthorized;
    }),
    map(([{item}]) => {
      return new PrepareExternalHoldRequestAction({
        vendor: item.vendor,
        itemId: item.id,
        reserveId: item.reserveId,
      });
    }),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly router: Router,
    private readonly configLoader: ConfigurationLoader,
    private readonly vendorIssuesService: VendorIssuesService
  ) {
  }
}
