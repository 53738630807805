import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from 'common/shared.module';
import {
  EcontentRichFeatureSuccessfulDialogComponent
} from 'core/over-drive/components/econtent-rich-feature-successful-dialog/econtent-rich-feature-successful-dialog.component';
import {
  HooplaGetItSuccessfulDialogComponent
} from './components/hoopla-get-it-successful-dialog/hoopla-get-it-successful-dialog.component';
// eslint-disable-next-line max-len
import { OverDriveAuthErrorDialogComponent } from './components/over-drive-auth-error-dialog/over-drive-auth-error-dialog.component.';
import {
  OverDriveGetItFailedDialogComponent
} from './components/over-drive-get-it-failed-dialog/over-drive-get-it-failed-dialog.component';
// eslint-disable-next-line max-len
import {
  OverDriveGetItSuccessfulDialogComponent
} from './components/over-drive-get-it-successful-dialog/over-drive-get-it-successful-dialog.component';
import {
  OverdriveDownloadRedirectDialogComponent
} from './components/overdrive-checkout-dialog/overdrive-download-redirect-dialog.component';
import { OverDriveEffects } from './effects/over-drive.effects';

const COMPONENTS = [
  OverDriveGetItFailedDialogComponent,
  OverDriveGetItSuccessfulDialogComponent,
  HooplaGetItSuccessfulDialogComponent,
  EcontentRichFeatureSuccessfulDialogComponent,
  OverDriveAuthErrorDialogComponent,
  OverdriveDownloadRedirectDialogComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    EffectsModule.forFeature([
      OverDriveEffects,
    ]),
    FontAwesomeModule,
    NgbProgressbarModule,
  ],
})
export class OverDriveModule {}
