import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { BookshelfItem } from 'common/models/bookshelf-card';
import { collapseBookshelf } from 'user/actions/user-profile.actions';
import { BulkFeatureKey } from '../../../bulk-select/enums/bulk-feature-key';
import { rollupTabSelected } from '../../../entity/actions/entity.actions';
import { AvailabilityUpdateInfo, EntityTypes } from '../../../entity/models/entity';
import { RollUpTab } from '../../../entity/models/rollup-card';
import { ShareItResource, ShareItShareEntityType } from '../../../share-it/models/share-it';
import { DiscoverV2BffService } from 'app/elements-v2/service/discover-v2-bff.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-bookshelf-item-card',
  templateUrl: './bookshelf-item-card.component.html',
  styleUrls: ['./bookshelf-item-card.component.scss'],
})
export class BookshelfItemCardComponent implements OnInit {
  @Input() public item: BookshelfItem;
  @Input() public listId?: string;
  @Input() public isExtendedCard: boolean;
  // todo: remove; it's a temporary solution for hiding checkbox in RH
  @Input() public hideCheckbox: boolean;
  @Output() public onToggle = new EventEmitter();
  @Output() public cardNavigation = new EventEmitter();

  public format: string = null;
  public ariaTitleId: string;
  public entityTypes = EntityTypes;
  public resourceData: ShareItResource;
  public showCoverImage = false;
  public bookshelfShowShareIcon: boolean;
  private readonly subscriptions = new Subscription();


  constructor(
    public store: Store,
    private discoverV2BffService: DiscoverV2BffService
  ) {
  }

  public ngOnInit() {
    this.resourceData = this.prepopulateDataForSharing();
    this.ariaTitleId = `ariaLabel-${this.item.id}`;
    this.showCoverImage =
      this.item.entity.entityType === this.entityTypes.FORMAT_GROUP ||
      this.item.entity.entityType === this.entityTypes.AGENT;
      this.subscriptions.add(this.discoverV2BffService.$page.subscribe((page) => {
        if (page) {
          this.bookshelfShowShareIcon = page.settings?.social_media_display?.my_bookshelf;
        }
      }));
  }
  public toggleCardSelection() {
    this.onToggle.emit();
  }

  public prepopulateDataForSharing(): ShareItResource {
    const {id, entityType, title, primaryAgent, editionsData} = this.item.entity;

    return {
      type: ShareItShareEntityType.RESOURCE,
      attributes: {
        id,
        url: this.getUrlForResourceData(this.item),
        title,
        author: primaryAgent?.label || '',
        publicationDate: editionsData?.[0]?.publicationDate || '',
        resourceType: entityType,
      }
    };
  }

  public onNavigation(id: string, entityType: EntityTypes, tabInfo?: RollUpTab) {
    if (tabInfo) {
      this.store.dispatch(rollupTabSelected(tabInfo));
    }
    this.store.dispatch(collapseBookshelf());
  }

  private getUrlForResourceData(item: BookshelfItem): string {
    if (item.entity.entityType === EntityTypes.FORMAT_GROUP) {
      const recordId = item.entity.personalLists?.find((list) => list.listIds.includes(this.listId))?.recordId
        || item.entity.editionsData?.[0]?.recordId;

      return `/search/card?recordId=${this.item.entity.selectedTabRecordId || recordId}`;
    } else {
      return `/search/card?id=${item.entity.id}&entityType=${item.entity.entityType}`;
    }
  }
}
