import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, } from '@angular/core';
import { Subscription } from 'rxjs';
import { Breakpoints } from '../../models/viewport';
import { ViewportService } from '../../services/viewport.service';

@Component({
  selector: 'bc-home-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})

export class HomePageComponent implements OnInit, OnDestroy {
  public isWiderThanSm: boolean;
  private readonly subscriptions = new Subscription();

  constructor(
    private readonly viewportService: ViewportService,
    private readonly cdr: ChangeDetectorRef
  ) {

  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.viewportService.isWiderThan(Breakpoints.RESOLUTION_MAX_XS)
      .subscribe((isWiderThanSm) => {
        this.isWiderThanSm = isWiderThanSm;
        this.cdr.markForCheck();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
