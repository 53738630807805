<div class="hold-location-dialog p-4">
  <app-modal-header
    title="{{'getThisItemDialogTitle' | translate}}"
    titleId="hold-location-dialog-title"
    closeId="close-hold-location-dialog-btn"
    (close)="onClose()">
  </app-modal-header>
  <div class="modal-body mt-n4 mb-2">
    <h3 class="hold-resource-title" *ngIf="hold.resource?.title" tabindex="-1"
        data-automation-id="hold-location-resource-title">
      {{ hold.resource.title }}
    </h3>
    <form id="hold-location-form" [formGroup]="holdLocationForm" class="hold-location-form" (ngSubmit)="onSubmit()">
      <app-pickup-location-select
        formControlName="pickupLocation"
        [pickupLocations]="pickupLocations"
        [selectedLocationCode]="selectedLocationCode"
        [dataAutomationId]="'hold-pickup-location-select'">
      </app-pickup-location-select>
    </form>
  </div>
  <div class="modal-footer justify-content-start">
    <button
      class="ins-buttons button-primary flint"
      type="submit"
      form="hold-location-form"
      data-automation-id="confirm-hold-location-btn"
      translate>
      confirmLocation
    </button>
    <button
      type="button"
      class="ins-buttons button-secondary flint ml-3"
      (click)="onClose()"
      data-automation-id="cancel-hold-location-btn"
      translate>
      cancelButton
    </button>
  </div>
</div>
