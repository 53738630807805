<div class="login-form-container">
  <div class="text-center login-title" data-automation-id="login-title" translate>loginFormLogIn</div>

  <div class="login-header-line mt-2 mx-auto" aria-hidden="true"></div>

  <form class="login-form form-group mb-0"
        id="login-form"
        data-automation-id="login-form"
        [formGroup]="loginForm"
        (ngSubmit)="onSubmit()">
    <div aria-atomic="true" aria-live="assertive" role="alert">
      <div *ngIf="loginForm.errors"
           class="px-6 py-2 mt-5 login-error"
           data-automation-id="login-form-error">
        <ng-container *ngIf="loginForm.errors?.incorrectCredentials">
          {{ 'loginFormUsernameAndOrPasswordYouEnteredIsIncorrect' | translate }}
        </ng-container>

        <ng-container *ngIf="loginForm.errors?.unknown">
          {{ 'generalServerError' | translate }}
        </ng-container>
      </div>
    </div>

    <label for="login-form-barcode"
           class="login-form-label d-flex mt-5"
           data-automation-id="login-form-barcode-label"
           translate>
      loginFormBarcode
      <button class="app-button d-block h-100 px-1 login-form-label-button"
              data-automation-id="login-form-barcode-label-button"
              type="button"
              [ngbPopover]="popoverContent"
              popoverClass="login-form-label-button-popover"
              placement="top top-right top-left"
              attr.aria-label="{{ 'loginFormBarcodeInstructionLabel' | translate }}">
        <span class="fas fa-exclamation-circle" aria-hidden="true"></span>
      </button>
      <ng-template #popoverContent>
        <div data-automation-id="login-form-barcode-label-button-content" class="login-form-label-button-popover-body" translate>
          loginFormBarcodeInstruction
        </div>
      </ng-template>
    </label>
    <input type="text"
           formControlName="barcode"
           class="form-control login-form-control mb-0"
           [class.is-invalid]="barcode.invalid && barcode.touched"
           id="login-form-barcode"
           [attr.aria-invalid]="barcode.invalid && barcode.touched"
           aria-required="true"
           data-automation-id="login-form-barcode"/>
    <div aria-atomic="true" aria-live="assertive" role="alert">
      <div *ngIf="barcode.invalid && barcode.touched"
           class="invalid-feedback d-block"
           data-automation-id="login-form-barcode-error">
        <ng-container *ngIf="barcode.errors?.required">
          {{ 'loginFormPleaseEnterYourBarcode' | translate }}
        </ng-container>
      </div>
    </div>

    <label for="login-form-passcode"
           class="login-form-label mt-5"
           data-automation-id="login-form-passcode-label"
           translate>
      loginFormPasscode
    </label>
    <input type="password"
           formControlName="passcode"
           class="form-control login-form-control mb-0"
           [class.is-invalid]="passcode.invalid && passcode.touched"
           id="login-form-passcode"
           [attr.aria-invalid]="passcode.invalid && passcode.touched"
           aria-required="true"
           data-automation-id="login-form-passcode"/>
    <div aria-atomic="true" aria-live="assertive" role="alert">
      <div *ngIf="passcode.invalid && passcode.touched"
           class="invalid-feedback d-block"
           data-automation-id="login-form-passcode-error">
        <ng-container *ngIf="passcode.errors?.required">
          {{ 'loginFormPleaseEnterYourPasscode' | translate }}
        </ng-container>
      </div>
    </div>

    <ins-bs-checkbox [checked]="rememberMe.value"
                     [containerClass]="['my-4', 'mr-3']"
                     (change)="rememberMe.setValue($event.checked)"
                     [aria-label]="'loginFormRememberMe' | translate">
    <span class="login-form-label" data-automation-id="login-form-remember-me-label"
          translate>loginFormRememberMe</span>
    </ins-bs-checkbox>
  </form>

  <button type="submit"
          form="login-form"
          data-automation-id="login-button"
          class="ins-buttons button-primary flint w-100 mt-2 login-form-button"
          [class.button-disabled]="isLoading"
          (click)="onSubmit()"
          translate>
    loginFormLogInButton
    <fa-icon [icon]="loginIcon" class="ml-1"></fa-icon>
  </button>

  <a [href]="resetPasscodeLink"
     target="_blank"
     class="forgot-passcode-link d-flex justify-content-center mt-2"
     data-automation-id="login-forgot-my-passcode-link"
  >{{ 'loginIForgotMyPasscode' | translate }}
  </a>

  <div class="d-flex justify-content-center" role="status" aria-live="polite">
    <div *ngIf="isLoading"
         class="mt-3 spinner-border login-form-loading"
         data-automation-id="login-form-loading">
      <span class="sr-only" translate>Loading...</span>
    </div>
  </div>

</div>
