import { ResourceFacetBlock } from 'search/models/filter-panel';
import { CatalogDate } from 'search/models/search-results';
import { CatalogDateFacet, DateFacet, SearchFacetsObject } from '../facets/models/resource-facet';
import { SortingType, SortOrderType } from '../services/search-params.service';

export interface SearchRequestFacets {
  [key: string]: string[] | boolean | number | string;
}
export interface Filters {
  facets: SearchRequestFacets;
}

export interface SearchObjectPagination {
  pageSize?: number;
  pageNum?: number;
}

export interface SearchObjectSortingParams {
  sorting?: SortingType | string;
  sortOrder?: SortOrderType | string;
}

export interface SearchObjectSearchParams {
  type?: SearchObjectType;
}

export interface SearchObjectType {
  searchText?: string;
  metadataBoolQuery?: string;
  resourceIds?: string[];
}

export interface SearchObject {
  pagination?: SearchObjectPagination;
  sortingParams?: SearchObjectSortingParams;
  searchType?: string;
  filters?: Filters;
  searchParams: SearchObjectSearchParams;
  resourceType?: string;
}

export interface MappedSearchObject {
  searchText?: string;
  searchType?: string;
  metadataBoolQuery?: string;
  resourceIds?: string[];
  pageSize?: number;
  pageNum?: number;
  resourceType?: string;
  sorting?: SortingType | string;
  sortOrder?: SortOrderType | string;
  filters?: Filters;
  expand?: boolean;
  facetTypes?: string[];
}

export interface FacetSearchObject extends MappedSearchObject {
  facetQuery?: FacetQuery;
  facetMapField: string;
  facetKey: string;
}

export interface FacetQuery {
  prefix?: string;
  subString?: string;
  codes?: string[];
}

export interface SearchQueryParams {
  queryParams: SearchQuery;
}

export interface SearchQuery {
  pageSize?: string | number;
  pageNum?: string | number;
  sorting?: string;
  sortOrder?: string;
  query?: string;
  boolQuery?: string;
  metadata?: string;
  searchType?: string;
  dateFrom?: string | number;
  dateTo?: string | number;
  catalogDate?: CatalogDate;
  universalLimiterIds?: string;
  conceptIds?: string;
  agentIds?: string;
  materialTypeIds?: string;
  resourceIds?: string;
  locationIds?: string;
  languageIds?: string;
  fullText?: string | boolean;
  mode?: string;
  collectionIds?: string;
  publisherIds?: string;
  donorIds?: string;
  literaryFormIds? : string;
}

export const defaultDateFacets: Readonly<DateFacet> = {
  dateFrom: '',
  dateTo: '',
  facetKey: ResourceFacetBlock.DATE,
  isResetLocked: false,
  selected: false,
  label: '',
  id: '',
};

export const defaultCatalogDateFacets: Readonly<CatalogDateFacet> = {
  catalogDate: '' as CatalogDate,
  facetKey: ResourceFacetBlock.CATALOG_DATE,
  isResetLocked: false,
  selected: false,
  label: '',
  id: '',
};

export const defaultResourceFacets: Readonly<SearchFacetsObject> = {
  conceptFacets: [],
  personFacets: [],
  availabilityFacet: [],
  formatFacet: [],
  languageFacet: [],
  dateFacets: defaultDateFacets,
  catalogDateFacets: defaultCatalogDateFacets,
  fullText: null,
};

export const defaultSearchObjectConfig: SearchObject = {
  pagination: {
    pageSize: 10,
    pageNum: 0,
  },
  sortingParams: {
    sorting: 'relevance',
    sortOrder: 'asc',
  },
  searchParams: {
    type: {
      searchText: null,
      metadataBoolQuery: null,
      resourceIds: null,
    },
  },
  filters: {
    facets: {
      conceptIds: [],
      agentIds: [],
      materialTypeIds: [],
      languageIds: [],
      universalLimiterIds: [],
      fullText: null,
      dateFrom: null,
      dateTo: null,
    },
  },
};

export const DEFAULT_LAZY_LOADED_FACET_SEARCH_OBJECT: { [key: string]: Partial<MappedSearchObject> } = {
  location: {
    pageNum: 0,
    pageSize: 300,
    sorting: 'count',
    sortOrder: 'desc'
  },
  materialType: {
    pageNum: 0,
    pageSize: 300,
    sorting: 'count',
    sortOrder: 'desc'
  }
};
