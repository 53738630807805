import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reading-history-confirm-modal',
  templateUrl: './reading-history-confirm-modal.component.html',
  styleUrls: ['./reading-history-confirm-modal.component.scss'],
})
export class ReadingHistoryConfirmModalComponent {

  constructor(private readonly activeModal: NgbActiveModal) {
  }

  public onClose(): void {
    this.activeModal.dismiss();
  }

  public onConfirm(): void {
    this.activeModal.close(true);
  }
}
