import { HttpErrorResponse } from '@angular/common/http';
import { VendorType } from '../../../entity/models/entity';
import { OverDriveFormat } from './over-drive-formats';

export interface EContentGetItPayload {
  itemId: string;
  reserveId: string;
  vendor?: string;
  formatType?: OverDriveFormat;
  entityType?: string;
  formatGroupId?: string;
  instanceId?: string;
  message?: string;
  remainingCheckouts?: number;
  hasEcontentIssues?: boolean;
  url?: string;
}

export enum ErrorCause {
  PASSWORD_NOT_FOUND = 'PasswordNotFoundException',
}


export interface ErrorResponse extends HttpErrorResponse {
  cause : string,
}

export interface OverDriveRequestFailedPayload {
  response: ErrorResponse;
  vendor: VendorType;
}
