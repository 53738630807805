import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { FeatureToggleService } from 'app/services/feature-toggle.service';
import { InformationModalComponent } from 'common/components/information-modal/information-modal.component';
import { concat, EMPTY, from, of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ListTransformerService } from '../../list/services/list-transformer.service';
import {
  notifyReadingHistorySettingLoaded,
  getReadingHistoryStatus,
  getReadingHistoryStatusFailure,
  getReadingHistoryStatusSuccess,
  updateReadingHistoryOptionFailure,
  updateReadingHistoryOptionSuccess,
} from '../actions/reading-history.actions';
import * as ReadingHistoryActions from '../actions/reading-history.actions';
import { ReadingHistoryConfirmModalComponent } from '../components/reading-history-confirm-modal/reading-history-confirm-modal.component';
import { ReadingHistoryService } from '../services/reading-history.service';
import { Action, Store } from '@ngrx/store';
import {
  getReadingHistoryPagination,
  getReadingHistorySearchText,
  getReadingHistorySortedBy,
  getReadingHistoryStatusState,
  ReadingHistoryState,
} from '../reducers/reading-history.reducer';
import { getEnableReadingHistorySettingStatus, UserState } from 'user/reducers/user.reducer';
import {
  LoadReadingHistoryError,
  ReadingHistoryItems,
  ReadingHistoryPaginationParams,
} from '../models/reading-history';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ReadingHistoryEffects {
  private static readonly pageSize = 50;
  private static readonly sort = 'checkoutDate.desc';

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<ReadingHistoryState | UserState>,
    private readonly readingHistoryService: ReadingHistoryService,
    private readonly featureToggleService: FeatureToggleService,
    private readonly modal: NgbModal,
    private readonly listTransformerService: ListTransformerService,
  ) {
  }

  public notifyReadingHistorySettingLoaded$ = createEffect(() => this.actions$.pipe(
    ofType(notifyReadingHistorySettingLoaded),
    //check license
    filter((data) => this.isReadingHistoryEnabled(data.setting)),
    map(() => getReadingHistoryStatus()),
  ));


  public getReadingHistoryStatus$ = createEffect(() => this.actions$.pipe(
    ofType(getReadingHistoryStatus),
    switchMap(() => {
        return this.readingHistoryService.getStatus().pipe(
          switchMap((readingHistoryStatus) => {
            const actions: Action[] = [getReadingHistoryStatusSuccess({readingHistoryStatus})];
            // If reading history is opted in, send request to trigger RH loading
            if (readingHistoryStatus.readingHistoryActivation) {
              actions.push(ReadingHistoryActions.startReadingHistorySynchronization());
            }
            return actions;
          }),
          catchError((err) => of(getReadingHistoryStatusFailure({error: err}))),
        );
      },
    ))
  );

  public updateReadingHistoryOptIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReadingHistoryActions.updateReadingHistoryOptIn),
      switchMap(() =>
        this.readingHistoryService.updateReadingHistoryOption({ readingHistoryActivation: true }).pipe(
          concatMap(() => {
            const modalRef = this.modal.open(InformationModalComponent);
            modalRef.componentInstance.titleTranslateKey = 'requestSuccessfulTitle';
            modalRef.componentInstance.bodyTranslateKey = 'ReadingHistoryOptInSuccess';
            return concat(
              of(updateReadingHistoryOptionSuccess({ option: { readingHistoryActivation: true } })),
              of(ReadingHistoryActions.startReadingHistorySynchronization())
            );
          }),
          catchError(() => {
            const modalRef = this.modal.open(InformationModalComponent);
            modalRef.componentInstance.titleTranslateKey = 'requestFailedTitle';
            modalRef.componentInstance.bodyTranslateKey = 'ReadingHistoryOptInFailure';
            return of(updateReadingHistoryOptionFailure({ option: { readingHistoryActivation: false } }));
          })
        )
      )
    )
  );

  public updateReadingHistoryOptOut$ = createEffect(() => this.actions$.pipe(
    ofType(ReadingHistoryActions.updateReadingHistoryOptOut),
    switchMap(() => {
      const modalRef = this.modal.open(ReadingHistoryConfirmModalComponent);
      return from(modalRef.result);
    }),
    switchMap(() => {
      return this.readingHistoryService.updateReadingHistoryOption({readingHistoryActivation: false})
      .pipe(
        map(() => {
          const modalRef = this.modal.open(InformationModalComponent);
          modalRef.componentInstance.titleTranslateKey = 'requestSuccessfulTitle';
          modalRef.componentInstance.bodyTranslateKey = 'ReadingHistoryOptOutSuccess';
          return updateReadingHistoryOptionSuccess({option: {readingHistoryActivation: false}});
        }),
        catchError(() => {
          const modalRef = this.modal.open(InformationModalComponent);
          modalRef.componentInstance.titleTranslateKey = 'requestFailedTitle';
          modalRef.componentInstance.bodyTranslateKey = 'ReadingHistoryOptOutFailure';
          return of(updateReadingHistoryOptionFailure({option: {readingHistoryActivation: true}}));
        }),
      );
    })));

  public startReadingHistorySynchronization$ = createEffect(() => this.actions$.pipe(
    ofType(ReadingHistoryActions.startReadingHistorySynchronization),
    withLatestFrom(this.store.select(getReadingHistoryStatusState), this.store.select(getEnableReadingHistorySettingStatus)),
    filter(([, data, setting]) => this.isReadingHistoryEnabled(data) && setting),
    switchMap(() => {
      return this.readingHistoryService.readingHistorySynchronization().pipe(
        map(() => EMPTY),
      );
    }),
  ), {dispatch: false});

  public loadReadingHistoryItems$ = createEffect(() => this.actions$.pipe(
    ofType(ReadingHistoryActions.loadReadingHistoryItems),
    withLatestFrom(
      this.store.select(getReadingHistorySearchText),
      this.store.select(getReadingHistorySortedBy),
    ),
    switchMap(([{paginationParams, searchText, sortBy, clearBeforeAdd = true}, cachedSearch, cachedSort]) => {
      let pagination;
      const loadItemsPayload = {
        sortBy: sortBy || cachedSort,
        searchText: searchText || cachedSearch,
        paginationParams: paginationParams || this.makePaginationParams(0, ReadingHistoryEffects.pageSize),
      };
      return this.readingHistoryService.getReadingHistoryItems(
        loadItemsPayload.sortBy,
        loadItemsPayload.paginationParams,
        loadItemsPayload.searchText,
      ).pipe(
        switchMap((response) => {
          pagination = this.getPaginationData(response.items);
          const sortedBy = response.items.sortedBy;
          const actions: Action[] = [];
          const readingHistoryItems = response.items.data.map((item) => {
            return {
              id: item.id,
              // todo: implement selection later
              selected: false,
              entity: this.listTransformerService.transformFgOrEntityToListItemEntity(item),
            };
          });
          if (clearBeforeAdd) {
            actions.push(ReadingHistoryActions.clearReadingHistoryItems());
          }
          actions.push(ReadingHistoryActions.loadReadingHistoryItemsSuccess({
            readingHistoryItems: readingHistoryItems,
            pagination: pagination,
            sortedBy,
            searchText,
          }));
          return actions;
        }),
        catchError((err: HttpErrorResponse) => {
          const error = ReadingHistoryEffects.extractErrorOrUnknown(err);
          return of(ReadingHistoryActions.loadReadingHistoryItemsFailure({errorStatus: error.status}));
        }),
      );
    }),
  ));

  public loadMoreReadingHistoryItems$ = createEffect(() => this.actions$.pipe(
    ofType(ReadingHistoryActions.loadMoreReadingHistoryItems),
    concatLatestFrom(() => this.store.select(getReadingHistoryPagination)),
    filter(([_, pagination]) => !(pagination.page >= pagination.totalPages - 1)),
    concatLatestFrom(() => [this.store.select(getReadingHistorySearchText), this.store.select(getReadingHistorySortedBy)]),
    map(([[_, pagination], searchText, sortBy]) => ReadingHistoryActions.loadReadingHistoryItems({
      paginationParams: this.makePaginationParams(pagination.page + 1, ReadingHistoryEffects.pageSize),
      clearBeforeAdd: false,
      searchText,
      sortBy: sortBy
    })),
  ));

  private getPaginationData = (items: ReadingHistoryItems) => {
    const {page, totalPages, totalResults} = items;
    return {page, totalPages, totalResults};
  };

  private static extractErrorOrUnknown(err: HttpErrorResponse): LoadReadingHistoryError {
    return (err.error?.status)
      ? err.error
      : {
        status: err.status,
        message: 'Unknown error',
      };
  }

  private isReadingHistoryEnabled(setting: boolean): boolean {
    return this.featureToggleService.getToggles()['licenseReadingHistory'] && setting;
  };

  private makePaginationParams = (pageNum = 0, pageSize = 999): ReadingHistoryPaginationParams => {
    return {pageNum, pageSize};
  };
}
