import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { clear } from '../../actions/for-later-list.actions';
import { List, ListItemEntity } from '../../models/list';
import { getLists, ListState } from '../../reducers/list.reducer';

@Component({
  selector: 'app-save-multiple-bookmarks-modal',
  templateUrl: './save-multiple-bookmarks-modal.component.html',
  styleUrls: ['./save-multiple-bookmarks-modal.component.scss'],
})
export class SaveMultipleBookmarksModalComponent implements OnInit, OnDestroy {
  @Input() public listItemEntities?: ListItemEntity[];
  public lists: List[];
  public isDone = false;
  private readonly subscriptions = new Subscription();

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly store: Store<ListState>,
  ) {
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.store.select(getLists).subscribe((lists) => this.lists = lists),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onDone(): void {
    this.clearTemporaryBookmarks();
    this.isDone = true;
  }

  public onDiscardAll(): void {
    this.clearTemporaryBookmarks();
    this.close();
  }

  public close(): void {
    this.activeModal.close();
  }

  public canDismiss(): boolean {
    return this.isDone;
  }

  private clearTemporaryBookmarks(): void {
    this.store.dispatch(clear());
  }
}
