export enum BookmarkButtonState {
  NONE,
  SAVED,
  LOGIN,
  SAVED_TO_SINGLE_LIST,
  SAVED_IN_MULTIPLE_LISTS,
  LOADING,
  NONE_UPDATED,
  SAVED_UPDATED,
}
