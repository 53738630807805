<div class="p-3">
  <form id="create-list-form"
        data-automation-id="create-list-form"
        class="mb-1 create-list-form"
        [formGroup]="createListForm"
        (ngSubmit)="submit()">
    <div class="form-group">
      <label for="newListName" class="create-list-form-label" data-automation-id="create-list-form-label" translate>listNewList</label>
      <input #newListNameInput
             id="newListName"
             formControlName="name"
             class="form-control create-list-form-name p-5"
             [class.is-invalid]="name.invalid && name.touched"
             [placeholder]="'listNewListName' | translate"
             [attr.aria-label]="'listNewListName' | translate"
             [attr.aria-invalid]="name.invalid && name.touched"
             aria-required="true"
             data-automation-id="create-list-form-name"/>
      <div aria-atomic="true" aria-live="assertive" role="alert">
        <div *ngIf="createListForm.invalid && createListForm.touched"
             class="invalid-feedback invalid-feedback-force-display"
             data-automation-id="create-list-form-name-error">
          <ng-container *ngIf="createListForm.errors?.limit">
            {{ 'listCreateListLimitError' | translate }}
          </ng-container>
          <ng-container *ngIf="createListForm.errors?.unknown">
            {{ 'generalServerError' | translate }}
          </ng-container>

          <ng-container *ngIf="name.errors">
            <ng-container *ngIf="name.errors.required">
              {{ 'listCreateListNameRequiredError' | translate }}
            </ng-container>
            <ng-container *ngIf="name.errors.maxlength">
              {{ 'listCreateListNameMaxLengthError' | translate: {maxCharactersNumber: name.errors.maxlength.requiredLength} }}
            </ng-container>
            <ng-container *ngIf="name.errors.duplicate">
              {{ 'listCreateListNameDuplicateError' | translate }}
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </form>

  <div class="spinner-container" role="status" aria-live="polite">
    <div *ngIf="isCreatingList" class="spinner-border text-primary" data-automation-id="create-list-spinner">
      <span class="sr-only" translate>Loading...</span>
    </div>
  </div>

  <div class="d-flex justify-content-between pt-2">
    <button type="submit"
            form="create-list-form"
            data-automation-id="create-list-form-confirm-button"
            (click)="submit()"
            translate
            class="ins-buttons button-primary flint">listCreateListDone
    </button>
    <button (click)="cancel()"
            data-automation-id="create-list-form-cancel-button"
            class="ins-buttons button-secondary flint ml-3"
            translate>cancelButton
    </button>
  </div>
</div>
