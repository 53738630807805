<perfect-scrollbar class="ps-show-always" removeTabindexOnYScroll scrollIndicators [ngStyle]="{'max-height': maxHeightPerfectScrollbar}">
  <div class="account-tab-content">
    <div class="d-block d-sm-flex page-title mb-3">
      <div class="page-title-text d-flex d-sm-inline align-items-center mr-2" data-automation-id="fines-page-title">
        <h2>{{'accountTabFeesAndFines' | translate}} |</h2>
        <div class="d-block d-sm-none" *ngIf="fineActions">
          <ng-container *ngTemplateOutlet="pageTitleInfo"></ng-container>
        </div>
      </div>
      <ng-container *ngIf="fineActions">
        <button class="ins-buttons button-secondary flint page-title-action mr-2 mr-sm-4"
           data-automation-id="fines-pay-total-button"
           attr.aria-label="{{ 'payTotal' | translate }} {{totalAmount | configuredCurrency}}"
           (click)="payTotal($event)"
           [disabled]="!isPayAllEnabled"
           [attr.aria-disabled]="!isPayAllEnabled"
           [class.button-disabled]="!isPayAllEnabled">
          <span>{{ $any(fineActions).payAll.buttonText || 'payTotal' | translate }}
            <span class="amount"> {{totalAmount | configuredCurrency}}</span>
          </span>
        </button>
        <button class="ins-buttons button-secondary flint page-title-action mr-2"
           *ngIf="$any(fineActions).paySelected"
           data-automation-id="fines-pay-selected-button"
           attr.aria-label="{{ 'paySelected' | translate }} {{ selectedAmount | configuredCurrency}}"
           (click)="paySelected($event)"
           [disabled]="!isPaySelectedEnabled"
           [attr.aria-disabled]="!isPaySelectedEnabled"
           [class.button-disabled]="!isPaySelectedEnabled">
          <span>{{ 'paySelected' | translate }}
            <span class="amount" *ngIf="selectedAmount"> {{selectedAmount | configuredCurrency}}</span>
          </span>
        </button>
        <div class="page-title-info-container d-none d-sm-block">
          <ng-container *ngTemplateOutlet="pageTitleInfo"></ng-container>
        </div>
      </ng-container>
    </div>


    <ng-container *ngIf="fines; else loadingOrErrorMessage">
      <div class="page-body">
        <div *ngIf="paymentStatus === paymentStatusEnum.OK" class="payment-message mt-4" aria-live="assertive" role="alert"
             data-automation-id="fines-payment-message-success">
          <div class="payment-message-header payment-message-header-success p-2">
            <span class="fas fa-check-circle mr-1" aria-hidden="true"></span>
            <span class="p-1" translate>finePaymentComplete</span>
          </div>
          <div class="payment-message-body text-center p-4" translate>
            finePaymentMessageSuccess
          </div>
        </div>
        <div *ngIf="paymentStatus === paymentStatusEnum.ERROR" class="payment-message mt-4" aria-live="assertive" role="alert"
             data-automation-id="fines-payment-message-failure">
          <div class="payment-message-header payment-message-header-failure p-2">
            <span class="fas fa-exclamation-circle mr-1" aria-hidden="true"></span>
            <span class="p-1" translate>finePaymentUnableToPay</span>
          </div>
          <div class="payment-message-body text-center p-4" translate>
            finePaymentMessageFailure
          </div>
        </div>
        <div *ngIf="minimumAmount" class="minimum-amount text-center pt-3 mt-4"
             data-automation-id="fines-minimum-amount">
          <span class="fas fa-exclamation-circle" aria-hidden="true"></span>
          {{'minimumOnlinePaymentIs' | translate: {minimumAmount: minimumAmount | configuredCurrency} }}
        </div>
        <ol class="mb-0" data-automation-id="fines-list">
          <li *ngFor="let fine of fines; trackBy: trackById"
              class="d-block mb-5 mt-2" data-automation-id="fine-list-card">
            <app-fine-card [fine]="fine"
                           [isSelected]="selectedFines.has(fine.id)"
                           [showCheckbox]="!!$any(fineActions)?.paySelected"
                           (onSelect)="onFineSelectionChange(fine)">
            </app-fine-card>
          </li>
        </ol>
      </div>
    </ng-container>
  </div>
</perfect-scrollbar>

<ng-template #pageTitleInfo>
  <span class="page-title-info" *ngIf="fineActions.paymentNote" data-automation-id="fines-page-title-info">
    <button class="app-button page-title-info-button"
            data-automation-id="fines-page-title-info-button"
            type="button"
            [ngbPopover]="popoverContent"
            popoverClass="fines-popover"
            placement="bottom bottom-right"
            attr.aria-label="{{ 'paymentInformation' | translate }}">
      <span class="far fa-question-circle icon" aria-hidden="true"></span>
    </button>
    <ng-template #popoverContent>
      <perfect-scrollbar class="popover-scrollbar">
        <div class="popover-scrollbar-content" data-automation-id="fines-popover-content">
          {{fineActions.paymentNote}}
        </div>
      </perfect-scrollbar>
    </ng-template>
  </span>
</ng-template>

<ng-template #loadingOrErrorMessage>
  <div class="d-flex justify-content-center text-white" role="status" aria-live="polite">
    <div *ngIf="finesState?.loading"
         class="mt-5 spinner-border items-loading-spinner"
         data-automation-id="fine-items-loading-spinner">
      <span class="sr-only" translate>Loading...</span>
    </div>
  </div>
  <div aria-atomic="true" aria-live="assertive" role="alert">
    <div *ngIf="finesState?.error"
         class="m-4 text-white"
         data-automation-id="fine-items-loading-error">
      {{'generalServerError' | translate}}
    </div>
  </div>
</ng-template>
