<div class="d-inline-block" ngbDropdown placement="bottom-right bottom left-bottom left auto">
  <button
    class="sort-button px-2"
    ngbDropdownToggle
    data-automation-id="bulk-renew-dropdown"
    aria-haspopup="menu"
    type="button"
    translate>
    renewItems
  </button>
  <div role="menu" ngbDropdownMenu>
    <button
      *ngFor="let option of options"
      class="d-block py-2 px-5 w-100 h-auto sort-dropdown-option"
      data-automation-id="bulk-renew-option"
      ngbDropdownItem
      role="menuitem"
      (click)="renewItems(option)">
      {{option | translate}}
    </button>
  </div>
</div>
