import { Component,Input, QueryList, ViewChildren } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormArrayControlConnector } from 'user/components/bookshelf/helpers/form-array-control-connector';
import { NoopControlValueAccessor } from 'user/components/bookshelf/helpers/noop-control-value-accessor';
import { ProfileFormFieldInputComponent } from '../profile-form-field-input/profile-form-field-input.component';

export interface ProfileFormFieldInputMultipleComponent extends FormArrayControlConnector, NoopControlValueAccessor {
}

@Component({
  selector: 'app-profile-form-field-input-multiple',
  templateUrl: './profile-form-field-input-multiple.component.html',
  styleUrls: ['./profile-form-field-input-multiple.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ProfileFormFieldInputMultipleComponent,
    multi: true,
  }],
})
export class ProfileFormFieldInputMultipleComponent extends FormArrayControlConnector {
  @Input() public inputLabel: string;
  @Input() autocompleteValue = 'on';
  @ViewChildren(ProfileFormFieldInputComponent) public inputList: QueryList<ProfileFormFieldInputComponent>;

  constructor(public readonly controlContainer: ControlContainer) {
    super(controlContainer);
  }

  public setFocusOnInputField() {
    setTimeout(() => {
      this.inputList.last.setFocusOnInputField();
    });
  }

  public writeValue(obj: any): void {
    // noop
  }

  public registerOnChange(fn: any): void {
    // noop
  }

  public registerOnTouched(fn: any): void {
    // noop
  }
}
