<button class="header-top__user-log-in"
        id="user-login-button"
        #userPanelButton
        data-automation-id="user-my-account"
        (click)="toggleOpenedUserMenu($event)"
        [attr.aria-expanded]="isLoginPanelShown"
        [attr.aria-label]="(isPatronAuthorized || isStaffAuthorized ? ('accountInformation' | translate: {userName: userName}) : 'logIn') | translate"
        cdkMonitorElementFocus>
  <fa-icon class="user-icon" [icon]="isStaffAuthorized ? staffUserIcon : userIcon" size="lg"></fa-icon>
  <ng-container *ngIf="!(isPatronAuthorized || isStaffAuthorized); else logInTemplate">
    <span translate class="button-text" data-automation-id="unauthorized-user-state">logIn</span>
  </ng-container>
  <ng-template #logInTemplate>
    <span class="button-text" data-automation-id="authorized-user-state">{{userName}}</span>
  </ng-template>
</button>
