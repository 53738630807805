<div class="d-inline-block bookmark-button"
     #dropdown="ngbDropdown"
     ngbDropdown
     placement="bottom-right bottom bottom-left right-bottom right"
     [autoClose]="'outside'"
     (openChange)="syncOpenChange($event)"
     [open]="dropdownOpened">
  <button
    (click)="onBookmark($event)"
    class="app-button button-labelled bookmark-button--hide-dropdown-arrow"
    [attr.data-automation-id]="isSavedState() ? 'remove-bookmark-button' : 'add-bookmark-button'"
    aria-haspopup="dialog"
    ngbDropdownAnchor
    tippy="{{ (isSavedState() ? 'saved' : 'forLater') | translate }}"
    [attr.aria-label]="(isSavedState() ? 'savedRemoveFromSaved' : 'saveForLater') | translate"
    [attr.aria-pressed]="isSavedState()"
    [attr.aria-expanded]="dropdownOpened"
    [attr.aria-describedby]="ariaDescribedBy"
    placement="{{ dropdownOpened ? 'left' : 'bottom' }}">
    <span class="icon fa-bookmark" [class]="(isSavedState() ? 'fas' : 'far')" aria-hidden="true"></span>
    <span class="text">{{ (isSavedState() ? 'saved' : 'forLater') | translate }}</span>
  </button>

  <div class="p-0 bookmark-button--hide-dropdown-arrow__dropdown"
       role="dialog"
       ngbDropdownMenu
       #dropdownMenu
       tabindex="-1"
       [attr.aria-label]="'bookmarkButtonDialog' | translate"
       data-automation-id="bookmark-button-dropdown">
    <ng-container [ngSwitch]="state">
      <div *ngSwitchCase="stateEnum.LOGIN" class="p-4">
        <div class="d-flex bookmark-button--hide-dropdown-arrow__dropdown__message" data-automation-id="bookmark-button-dropdown-sign-in-message">
          <span aria-hidden="true" class="fas fa-check icon pr-2"></span>
          <div class="bookmark-button--hide-dropdown-arrow__dropdown__message__text mb-1">
            <span class="text-bold">{{ 'bookmarksTemporarilySavedTo' | translate }} </span>
            <span class="text-highlighted" translate>bookmarks</span>
            <p class="pt-2 m-0" translate>bookmarksToSavePermanentlySignIn</p>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <button class="ins-buttons button-primary flint"
                  data-automation-id="bookmark-button-dropdown-sign-in-button"
                  (click)="logIn($event)"
                  translate>
            signIn
          </button>
        </div>
      </div>

      <div *ngSwitchCase="stateEnum.SAVED_TO_SINGLE_LIST" class="p-4 d-flex flex-column flex-md-row align-items-center">
        <div class="d-flex mb-3 mb-md-0">
          <span aria-hidden="true" class="fas fa-check icon pr-2"></span>
          <span class="text-bold text-nowrap pr-4"
                data-automation-id="bookmark-button-dropdown-saved-to-message-no-list-name">
            {{ 'bookmarksSavedTo' | translate }}
          </span>
        </div>
        <app-select-list-button
          data-automation-id="bookmark-button-add-to-list"
          [buttonClass]="'btn ins-buttons button-secondary button-secondary--inverse-on-hover flint mw-100 p-2 h-auto'"
          [listItemEntities]="[listItemEntity]"
          [lists]="lists"
          [label]="('bookmarksSavedTo' | translate) + ' ' + justAddedList.name + ' (' + justAddedList.itemsCount + ')'"
          [listIdsSelected]="(personalLists || [])[0]?.listIds"
          [canCreateNew]="true"
          [trigger]="selectListTriggerEnum.BOOKMARK_BUTTON_SINGLE_ENTITY">
          {{justAddedList.name}} ({{justAddedList.itemsCount}})
        </app-select-list-button>
      </div>

      <ng-container *ngSwitchCase="stateEnum.SAVED_IN_MULTIPLE_LISTS">
        <div class="pt-4 px-4 pb-1" translate data-automation-id="bookmark-button-dropdown-saved-in-multiple-message">
          bookmarksListsTitle
        </div>
        <app-select-list
          [listItemEntities]="[listItemEntity]"
          [lists]="lists"
          [listIdsSelected]="(personalLists || [])[0]?.listIds"
          [multiselect]="true"
          (onDone)="multiselectDone()"
          (onCancel)="multiselectCancel()">
        </app-select-list>
      </ng-container>

      <div *ngSwitchCase="stateEnum.LOADING" class="p-4">
        <div aria-live="polite" class="d-flex justify-content-center"
             data-automation-id="bookmark-button-dropdown-loading"
             role="status">
          <div class="spinner-border text-primary p-2">
            <span class="sr-only" translate>Loading...</span>
          </div>
        </div>
      </div>
    </ng-container>

    <div *ngIf="state === stateEnum.NONE_UPDATED || state === stateEnum.SAVED_UPDATED" class="d-flex p-4">
      <span aria-hidden="true" class="fas fa-check icon pr-2"></span>
      <span class="text-bold text-nowrap" data-automation-id="bookmark-button-dropdown-updated">
        {{ 'bookmarksUpdated' | translate }}
      </span>
    </div>
  </div>
</div>
