import { CustomerFeature } from '../../customer-integration';

export const INN_REACH_CUSTOMER_HIDDEN_FEATURES: CustomerFeature[] = [
  CustomerFeature.Bookmarks,
  CustomerFeature.BookshelfAccountTab,
  CustomerFeature.BookshelfBookmarksTab,
  CustomerFeature.BookshelfReadingHistoryTab,
  CustomerFeature.BookshelfSavedSearchesTab,
  CustomerFeature.BookshelfShowcasesTab,
  CustomerFeature.BulkBookmarks,
  CustomerFeature.HeldItemsBanner,
  CustomerFeature.HoldBorrowByMail,
  CustomerFeature.HoldExpirationDate,
  CustomerFeature.UserPopupAccountLink,
  CustomerFeature.UserPopupInfoSection,
  CustomerFeature.UserPopupStatsSection,
  CustomerFeature.SaveSearch,
];
