export enum CustomerIntegrationType {
  NLB = 'NLB',
  INNREACH = 'INNREACH',
}

export interface CustomerIntegrationLinks {
  accountUrl: string;
  checkoutsPhysicalUrl: string;
  holdsPhysicalUrl: string;
  checkoutsElectronicUrl: string;
  holdsElectronicUrl: string;
  finesAndFeesUrl: string;
  bookmarksUrl: string;
  signUpUrl: string;
  borrowAndDeliverUrlTemplate: string;
}

export enum CustomerFeature {
  Bookmarks = 'Bookmarks',
  BookshelfAccountTab = 'BookshelfAccountTab',
  BookshelfBookmarksTab = 'BookshelfBookmarksTab',
  BookshelfReadingHistoryTab = 'BookshelfReadingHistoryTab',
  BookshelfSavedSearchesTab = 'BookshelfSavedSearchesTab',
  BookshelfShowcasesTab = 'BookshelfShowcasesTab',
  BulkBookmarks = 'BulkBookmarks',
  BulkHolds = 'BulkHolds',
  HeldItemsBanner = 'HeldItemsBanner',
  HoldBorrowByMail = 'HoldBorrowByMail',
  HoldExpirationDate = 'HoldExpirationDate',
  UserPopupAccountLink = 'UserPopupAccountLink',
  UserPopupInfoSection = 'UserPopupInfoSection',
  UserPopupStatsSection = 'UserPopupStatsSection',
  SaveSearch = 'SaveSearch',
}
