import { Component, Inject, OnDestroy, OnInit, } from '@angular/core';
import { LOCALE_ID_WRAPPER } from 'app/app.module';
import { Announcement } from 'app/elements-v2/models/settings';
import { Subscription } from 'rxjs';
import { ConfigurationLoader } from 'shared/configuration-loader';

@Component({
  selector: 'app-header-announcement-v2',
  templateUrl: './header-announcement-v2.component.html',
})
export class HeaderAnnouncementV2Component implements OnInit, OnDestroy {
  public announcements: Announcement[] = null;
  private readonly subscriptions = new Subscription();
  public availableLanguages = this.configloader.availableLanguages;
  public shortLocale: string;

  constructor(
    @Inject(LOCALE_ID_WRAPPER) public locale: string,
    public configloader: ConfigurationLoader,
  ) {}

  public ngOnInit(): void {
    this.announcements = this.configloader.config.announcements;
  }

  public ngOnDestroy(): void {
    this.announcements = null;
    this.subscriptions.unsubscribe();
  }
}
