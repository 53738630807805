import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  GoogleBookPreviewLink,
  GoogleBookPreviewLinkResponse,
  PreviewLinkResult
} from '../models/preview-link';

@Injectable({
  providedIn: 'root',
})
export class PreviewService {

  constructor(private http: HttpClient) {
  }

  public getGoogleBookConfig(bibKey: string): Observable<PreviewLinkResult<GoogleBookPreviewLink>> {
    const url = `https://books.google.com/books?bibkeys=${bibKey}&jscmd=viewapi`;

    return this.http.jsonp<GoogleBookPreviewLinkResponse>(url, 'callback')
    .pipe(
      map((config) => ({succeeded: true as const, result: config[bibKey]})),
      catchError(() => of({succeeded: false as const})),
    );
  }
}
