<div ngbDropdown
     #dropdown="ngbDropdown"
     placement="bottom-right bottom top-right auto"
     (openChange)="setDropdownOpened($event)"
     class="d-flex mb-2">
  <button *ngIf="defaultAction"
          class="ins-buttons button-primary flint action-button__current-action"
          [class.no-dropdown]="!cardActions?.length"
          (click)="onTriggerAction(defaultAction)"
          [attr.aria-disabled]="disabled"
          [class.button-disabled]="disabled"
          aria-haspopup="menu"
          data-automation-id="hold-card-action-button-default-action"
          [attr.aria-describedby]="ariaDescribedBy">
    <ng-template [ngTemplateOutlet]="defaultAction?.template"></ng-template>
  </button>
  <button *ngIf="cardActions?.length"
          ngbDropdownAnchor
          class="action-button__caret hide-default-caret ins-buttons button-primary flint"
          (click)="$event.stopPropagation(); toggleDropdown(dropdown)"
          [attr.aria-expanded]="actionDropdownOpened"
          [attr.aria-disabled]="disabled"
          [class.button-disabled]="disabled"
          aria-haspopup="menu"
          attr.aria-label="{{ 'toggleActionButton' | translate }}"
          data-automation-id="hold-card-action-button-toggle"
          [attr.aria-describedby]="ariaDescribedBy">
    <span class="icon-angle-down-thin"></span>
  </button>
  <div ngbDropdownMenu role="menu" data-automation-id="hold-card-action-button-dropdown">
    <button *ngFor="let cardAction of cardActions"
            (click)="onTriggerAction(cardAction)"
            [attr.aria-describedby]="ariaDescribedBy"
            class="d-block py-2 px-5 w-100 h-auto action-button__dropdown-option"
            ngbDropdownItem
            aria-haspopup="dialog"
            role="menuitem"
            data-automation-id="hold-card-action-button-dropdown-option">
      <ng-template [ngTemplateOutlet]="cardAction.template"></ng-template>
    </button>
  </div>
</div>

<div *ngIf="action" class="action-status__current-action">
  <div class="action-status__current-action--pending d-flex align-items-center"
       *ngIf="action.status === actionStatusEnum.Pending">
    <div class="spinner-border text-primary" data-automation-id="hold-card-action-spinner">
      <span class="sr-only" translate>Loading...</span>
    </div>
    <div class="action-status__current-action__label" [ngSwitch]="action.type"
         data-automation-id="hold-card-action-pending">
      <ng-container *ngSwitchCase="actionType.CancelHold">
        {{ 'holdCancelPending' | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="actionType.FreezeHold">
        {{ 'holdFreezePending' | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="actionType.UnfreezeHold">
        {{ 'holdUnfreezePending' | translate }}
      </ng-container>
    </div>
  </div>
  <div class="action-status__current-action--success action-status__current-action__label d-flex align-items-center"
       *ngIf="action.status === actionStatusEnum.Success"
       data-automation-id="hold-card-action-success">
    <span class="fa fa-check mr-1" aria-hidden="true"></span>
    <ng-container [ngSwitch]="action.type">
      <ng-container *ngSwitchCase="actionType.FreezeHold">
        {{ 'holdFreezeSuccess' | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="actionType.UnfreezeHold">
        {{ 'holdUnfreezeSuccess' | translate }}
      </ng-container>
    </ng-container>
  </div>
  <div class="action-status__current-action--failed action-status__current-action__label text-center text-sm-right"
       *ngIf="action.status === actionStatusEnum.Failed"
       data-automation-id="hold-card-action-failure">
    <span class="fas fa-exclamation-circle" aria-hidden="true"></span>
    <ng-container *ngIf="action.error">
      {{action.error}}
    </ng-container>
    <ng-container *ngIf="!action.error"
         [ngSwitch]="action.type">
      <ng-container *ngSwitchCase="actionType.CancelHold">
        {{ 'holdCancelFailed' | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="actionType.FreezeHold">
        {{ 'holdFreezeFailed' | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="actionType.UnfreezeHold">
        {{ 'holdUnfreezeFailed' | translate }}
      </ng-container>
    </ng-container>
  </div>
</div>
