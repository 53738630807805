import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryHelper } from 'core/utils/query-helper';
import { OrderedMap } from 'immutable';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {FacetState, FacetStateProps, FacetURLFilters} from 'search/facets/models/facet-state';
import { FacetType } from 'search/facets/models/facet-type';
import { AllResourceFacets } from 'search/facets/models/resource-facet';
import { getProcessor } from 'search/facets/processors/actions/facets-actions-factory';
import { FacetsSchemaService } from 'search/facets/services/facets-schema.service';
import { FacetsService } from 'search/facets/services/facets.service';
import { SearchObject, SearchQueryParams } from 'search/models/search-object';
import {BookCover, RollupResultsV2, SearchRequestBody} from 'search/models/search-results';
import { SearchMapperService } from 'search/services/mappers/search-mapper.service';
import { SearchParamsService } from 'search/services/search-params.service';
import { ShowcaseSearchData } from '../../custom-showcase/reducers/custom-showcase.reducer';
import {
  GetSavedSearchResponse,
  RunSavedSearchResponse,
  RunSavedSearchResults,
  SavedSearch,
  SavedSearchResponse,
  SavedSearchUpdate,
  SaveSearchRequest,
  SaveSearchResponse
} from '../models/saved-search';

@Injectable()
export class SavedSearchService {
  private static readonly savedSearchesUrl = 'api/search-result/personalization/saved-searches';

  constructor(
    private readonly http: HttpClient,
    private readonly searchMapperService: SearchMapperService,
    private readonly facetsSchemaService: FacetsSchemaService,
    private readonly facetsService: FacetsService,
    private readonly queryHelper: QueryHelper,
    private readonly searchParamsService: SearchParamsService,
  ) {
  }

  public createSavedSearch(request: SaveSearchRequest): Observable<SaveSearchResponse> {
    return this.http
    .post<SaveSearchResponse>(`${SavedSearchService.savedSearchesUrl}`, { ...request });
  }

  public updateSavedSearch(request: SavedSearchUpdate): Observable<void> {
    return this.http
    .patch<void>(`${SavedSearchService.savedSearchesUrl}/${request.id}`, request);
  }

  public loadSavedSearches(): Observable<SavedSearch[]> {
    return this.http
    .get<SavedSearchResponse[]>(`${SavedSearchService.savedSearchesUrl}`)
      .pipe(map((searches: SavedSearchResponse[]) => {
        return searches.map((search) => {
          return {...search, coverConfigs: search.coverConfigs?.map((config: BookCover) => ({coverConfig: config}))};
        });
      }));
  }

  public deleteSavedSearch(savedSearch: SavedSearch): Observable<{}> {
    return this.http
    .delete<{}>(`${SavedSearchService.savedSearchesUrl}/${savedSearch.id}`);
  }

  public getSavedSearch(id: string): Observable<GetSavedSearchResponse> {
    return this.http
    .get<GetSavedSearchResponse>(`${SavedSearchService.savedSearchesUrl}/${id}`);
  }

  public getSavedSearchData(id: string): Observable<ShowcaseSearchData> {
    return this.http.get<GetSavedSearchResponse>(`${SavedSearchService.savedSearchesUrl}/${id}`)
    .pipe(
      switchMap(({searchRequest}) => this.getShowcaseSearchDataBySearchRequestBody(searchRequest)),
    );
  }

  public getShowcaseSearchDataBySearchRequestBody(searchRequest: SearchRequestBody): Observable<ShowcaseSearchData> {
    const searchObject = this.convertToSearchObject(searchRequest);
    const mappedObj = this.searchMapperService.prepareMappedSearchObjectFromSearchObject(searchObject);

    return combineLatest([
      of(searchRequest),
      of(mappedObj),
      this.facetsService.getFacets(mappedObj),
    ])
    .pipe(
      map(([searchRequest, mappedObj, rawFacets]) => {
        let newState = OrderedMap<string, FacetState>();
        (rawFacets as AllResourceFacets).newModel.forEach((schemaWithData) => {
          const facetKey = schemaWithData.schema.key;
          const oldFacetState = newState.get(facetKey);
          newState = newState
          .set(facetKey, getProcessor(schemaWithData.schema.type)
          .processFacetsLoaded(oldFacetState, {...mappedObj.filters.facets} as FacetURLFilters, schemaWithData, {}));
        });

        const fs = newState.toList().toJS().filter((facetState: FacetState) => facetState.schemaWithData.schema.isShown);
        const facets = fs.reduce((acc, facet: FacetState) => {
          switch (facet.schemaWithData.schema.type) {
            case FacetType.Iterable: {
              return acc.concat(facet.applied);
            }
            default: {
              return facet.applied ? acc.concat([facet.applied]) : acc;
            }
          }
        }, []);

        const sorting = this.searchParamsService.extractValue(searchRequest).key;
        const {searchText} = searchRequest;
        return {searchText, sorting, facets};
      }),
    );
  }

  public runSavedSearch(savedSearchId: string): Observable<RunSavedSearchResults> {
    const url = `${SavedSearchService.savedSearchesUrl}/${savedSearchId}/run`;
    return this.http.post<RunSavedSearchResponse>(url, {}, {headers: {'api-version': '2'}})
    .pipe(map((response) => {
      const searchObject = this.convertToSearchObject(response.searchRequest);
      return {results: response.searchResult as RollupResultsV2, searchObject};
    }));
  }

  public makeQueryParams(searchObject: SearchObject): SearchQueryParams {
    return this.queryHelper.prepareQueryParamsFromSearchObject(searchObject);
  }

  public convertToSearchObject(searchRequest: SearchRequestBody) {
    const facetsSchema = this.facetsSchemaService.getActualFacetSchemas();
    return this.searchMapperService.prepareSearchObjectFromSearchRequest(searchRequest, facetsSchema);
  }
}
