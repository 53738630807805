import { defaultIterableFacetData, IterableFacetData, } from 'search/facets/models/facet-data';
import { FacetSchemaWithData, makeFacetSchemaWithData, } from 'search/facets/models/facet-schema-with-data';
import { FacetState, FacetURLFilters, makeFacetState, } from 'search/facets/models/facet-state';
import { Facet, FacetsLocked, } from 'search/facets/models/resource-facet';
import { FacetsActionsProcessor } from 'search/facets/processors/actions/facets-actions-processor';
import { copyObject } from 'search/reducers/utils';

export class IterableFacetsActionsProcessor extends FacetsActionsProcessor {
  public processFacetsLoaded(oldState: FacetState, appliedFilters: FacetURLFilters,
                             schemaWithData: FacetSchemaWithData, facetsLocked: FacetsLocked): FacetState {
    const appliedFiltersIds = appliedFilters[schemaWithData.schema.mapField + 'Ids'] as string[];
    const applied = oldState ? oldState.applied as Facet[] : [];

    const facetData = this.combineFacets(schemaWithData, applied, facetsLocked);
    let newSelectedAndApplied = facetData.data.reduce((result: Facet[], filter: Facet) => {
      return appliedFiltersIds && appliedFiltersIds.find((id: string) => id === filter.id) ? result.concat([{
        ...filter,
        selected: true,
      }]) : result;
    }, []);
    if (oldState && oldState.selected) {
      newSelectedAndApplied = oldState.selected as any;
    }
    // @ts-ignore
    return makeFacetState({
      schemaWithData: makeFacetSchemaWithData({
        data: facetData,
        schema: schemaWithData.schema,
      }),
      selected: copyObject(newSelectedAndApplied),
      applied: copyObject(newSelectedAndApplied),
      expanded: oldState ? oldState.expanded : false,
      isLoading: false,
    });
  }

  public processFacetSelected(oldState: FacetState, selectedFacet: Facet): FacetState {
    const selectedFacets = oldState.selected as Facet[];
    const newSelectedFacets = selectedFacets.reduce((acc, value) => {
      if (!selectedFacet.selected && value.id === selectedFacet.id) {
        const index = acc.findIndex((v) => v.id === selectedFacet.id);
        acc.splice(index, 1);
        return acc;
      }
      return acc.concat([value]);
    }, [selectedFacet]);
    return oldState.set('selected', newSelectedFacets);
  }

  public processFacetLocked(oldState: FacetState<Facet[]>, selectedFacet: Partial<Facet>): FacetState {
    const updated = this.updateResetLocked('selected', oldState, selectedFacet);
    return this.updateResetLocked('applied', updated, selectedFacet);
  }

  public processBubblesReset(oldState: FacetState<Facet[]>, resetAll: boolean): FacetState {
    const stateWithoutApplied = oldState.update('applied', (facets) => {
      return facets.reduce((acc, value) => !resetAll && value.isResetLocked ? acc.concat([value]) : acc, []);
    });
    return stateWithoutApplied.set('selected', stateWithoutApplied.applied);
  }

  public processExpandFacets(oldState: FacetState<Facet[]>, expanded: boolean, checkSelected: boolean): FacetState {
    return oldState.set('expanded', !checkSelected ? expanded : !!oldState.selected.length);
  }

  public processReplaceSelectedFacets(oldState: FacetState<Facet[]>, selectedFacets: Facet[]): FacetState {
    return oldState.set('selected', selectedFacets);
  }

  public processFacetBlockSelected(oldState: FacetState<Facet[]>): FacetState {
    return oldState.set('selected', []);
  }

  public processApplyFacets(oldState: FacetState<Facet[]>): FacetState {
    return oldState.set('applied', copyObject(oldState.selected));
  }

  public processBubbleRemoved(oldState: FacetState<Facet[]>, facetId: string): FacetState {
    const newSelectedFacets = oldState.selected.filter((value) => value.id !== facetId);
    return oldState.set('selected', newSelectedFacets);
  }

  public processCancelEditingFacets(oldState: FacetState): FacetState {
    return oldState.set('selected', copyObject(oldState.applied));
  }

  public processResetFacetState(oldState: FacetState): FacetState {
    oldState = oldState
      .set('selected', [])
      .set('applied', [])
      .set('schemaWithData', makeFacetSchemaWithData({
        schema: oldState.schemaWithData.schema,
        data: {
          totalResults: 0,
          page: 0,
          totalPages: 0,
          data: [],
        },
      }));
    return oldState;
  }

  public getAppliedFiltersForLock(schemaWithData: FacetSchemaWithData, filters: FacetURLFilters): string[] {
    const key = schemaWithData.schema.mapField + 'Ids';
    return filters[key] ? filters[key] as string[] : null;
  }

  private static getLockedFacetsIds(facetsLocked: FacetsLocked, facetKey: string): string[] {
    return facetsLocked.hasOwnProperty(facetKey) ? facetsLocked[facetKey] : [];
  }

  private updateResetLocked(field: 'selected' | 'applied', oldState: FacetState<Facet[]>, selectedFacet: Partial<Facet>) {
    return oldState.update(field, (facets) => {
      return facets.reduce((acc, value) => acc.concat([value.id === selectedFacet.id ? {
        ...value,
        isResetLocked: !value.isResetLocked,
      } : value]), []);
    });
  }

  /**
   * Combining facets from server and already selected
   * Note: assuming, that server will respond with applied facets with not 0 count on the top of the array,
   * already with sorting. Selected facets without count from server should be set with 0 count
   * @param oldStateSchemaWithData - facets from server
   * @param appliedFacets - already selected facets
   */
  private combineFacets(
    oldStateSchemaWithData: FacetSchemaWithData,
    appliedFacets: Facet[], facetsLocked: FacetsLocked): IterableFacetData {
    const receivedFacets = oldStateSchemaWithData.data ? {...oldStateSchemaWithData.data as IterableFacetData} : {...defaultIterableFacetData};
    const facetData = receivedFacets.data;
    const appliedFacetsIds = appliedFacets.map((f) => f.id);
    const facetsWithCounts = facetData.reduce((prev, next) => {
      if (prev.length !== appliedFacetsIds.length && appliedFacetsIds.includes(next.id)) {
        return prev.concat(next);
      }
      return prev;
    }, []);

    const lockedFacetsIds = IterableFacetsActionsProcessor.getLockedFacetsIds(facetsLocked, oldStateSchemaWithData.schema.key);

    const combinedData =
      appliedFacets.reduce((prev, cur) => {
                     const facetWithCount = facetsWithCounts.find((f) => f.id === cur.id);
                     const count = facetWithCount ? facetWithCount.count : 0;
                     return prev.concat({
                       ...cur,
                       count,
                     });
                   }, [])
                   .sort((a, b) => b.count - a.count || a.label && b.label && a.label.localeCompare(b.label))
                  .concat(facetData.filter(facet => {
                    const facetWithCount = facetsWithCounts.find((f) => f.id === facet.id);
                    return !facetsWithCounts.includes(facetWithCount);
                  }))
                   .map((f) => ({
                     ...f,
                     isResetLocked: lockedFacetsIds.includes(f.id),
                     facetKey: oldStateSchemaWithData.schema.key,
                   }));
    return {
      ...receivedFacets,
      data: combinedData,
      totalResults: receivedFacets.totalResults + appliedFacetsIds.length - facetsWithCounts.length,
    };
  }
}
