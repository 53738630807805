import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SearchType } from 'search/models/search-type';
import { error } from 'core/utils/error-decorator';

@Injectable()
export class SuggestsService {
  constructor(private http: HttpClient) {}

  @error([])
  public getSearchSuggests(text: string, searchType: string): Observable<any[]> {
    const typeQueryParam = (searchType !== SearchType.EVERYTHING)
      ? `&searchType=${searchType}` : '';
    const searchPhrase = encodeURIComponent(text);
    const url = `api/search/suggestions?phrase=${searchPhrase}${typeQueryParam}`;

    return this.http.get<any[]>(url);
  }
}
