import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'common/shared.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ReadingHistoryViewComponent } from './components/reading-history-view/reading-history-view.component';
import { ReadingHistoryService } from './services/reading-history.service';
import { ReadingHistoryEffects } from './effects/reading-history.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as readingHistory from './reducers/reading-history.reducer';
import { ReadingHistoryConfirmModalComponent } from './components/reading-history-confirm-modal/reading-history-confirm-modal.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    ReadingHistoryConfirmModalComponent,
    ReadingHistoryViewComponent,
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(readingHistory.featureKey, readingHistory.reducer),
    EffectsModule.forFeature([
      ReadingHistoryEffects,
    ]),
    SharedModule,
    InfiniteScrollModule,
    NgbDropdownModule,
  ],
  exports: [ReadingHistoryViewComponent],
  providers: [
    ReadingHistoryService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class ReadingHistoryModule { }
