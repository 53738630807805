import { Injectable } from '@angular/core';
import { EContentAvailability, EditionAvailabilityData } from 'core/over-drive/models/over-drive-availability';
import {
  Availability,
  AvailabilityStatus,
  AvailabilityStatusMap,
  AvailabilityUpdateEditionInfo,
  AvailabilityUpdateInfo,
  AvailabilityVendorType,
  Edition,
  EditionType,
  FormatGroup,
  MaterialTab,
  Reserve,
  VendorInfo,
  VendorType
} from '../models/entity';
import { StoredGatesResource } from '../models/gates-results';
import { VendorIssuesService } from './vendor-issues.service';
import { ALL_VENDORS, ALWAYS_AVAILABLE_VENDORS, ECONTENT_VENDORS_WITH_ISSUES, GET_MATERIAL_VENDORS } from 'shared/vendor-config';

@Injectable({
  providedIn: 'root'
})
export class AvailabilityService {

  constructor(
    private readonly vendorIssuesService: VendorIssuesService
  ) {}

  public countEContentAvailability(
    eContentAvailabilities: Partial<Record<VendorType,EContentAvailability[]>>,
    tab: MaterialTab
  ): AvailabilityUpdateInfo {
    const hasItemEcontentIssues = this.vendorIssuesService.hasVendorIssuesForTab(tab);
    const latestAvailabilityData = this.getLatestAvailableEdition(tab?.editions, tab.availability, eContentAvailabilities);
    const reserve = latestAvailabilityData?.reserve;
    const subscriptionIdList = this.getSubscriptionIds(tab?.editions);
    const electronicVendorAvailability = this.getElectronicVendorAvailabilityStatus(latestAvailabilityData, hasItemEcontentIssues);
    const isCheckAvailabilityStatus = tab.availability.urls?.length
      && tab.availability.status[AvailabilityVendorType.ELECTRONIC_LOCATOR] === AvailabilityStatus.AVAILABLE
      && electronicVendorAvailability === AvailabilityStatus.UNAVAILABLE;

    const status: AvailabilityStatusMap = {
      [AvailabilityVendorType.GENERAL]: isCheckAvailabilityStatus ? AvailabilityStatus.CHECK_AVAILABILITY : electronicVendorAvailability,
      [AvailabilityVendorType.ELECTRONIC_VENDOR]: electronicVendorAvailability,
      [AvailabilityVendorType.ELECTRONIC_LOCATOR]: tab.availability.status[AvailabilityVendorType.ELECTRONIC_LOCATOR],
    };

    return {
      reserve,
      subscriptionId: subscriptionIdList?.length ? subscriptionIdList : [],
      copies: latestAvailabilityData?.availability?.copiesAvailable || 0,
      holds: latestAvailabilityData?.availability?.numberOfHolds || 0,
      status,
    };
  }

  private getSubscriptionIds(editionData: Edition[] | Edition | undefined): string[] {
    if (Array.isArray(editionData)) {
      return editionData
        .filter(edition => !edition.vendors || Object.keys(edition.vendors).length === 0)
        .flatMap(edition => edition.subscriptionId || []);
    }

    if (editionData && (!editionData.vendors || Object.keys(editionData.vendors).length === 0)) {
      return editionData.subscriptionId || [];
    }

    return [];
  }

  public countEContentAvailabilityForEditions(
    eContentAvailabilities: Partial<Record<VendorType, EContentAvailability[]>>,
    edition: Edition
  ): AvailabilityUpdateEditionInfo {
    const subscriptionIdList = this.getSubscriptionIds(edition);
    const hasItemEcontentIssues = this.vendorIssuesService.hasVendorIssuesForEdition(edition);
    const vendorsReserves = this.getReserveFromVendorsInfo(edition?.vendors);
    const editionsAvailabilityData: EditionAvailabilityData = {
      reserve: vendorsReserves[0],
      availability: eContentAvailabilities[vendorsReserves[0]?.vendorName]?.find((availability) => {
        return availability.reserveId.toLowerCase() === vendorsReserves[0].id.toLowerCase();
      })
    };

    const electronicVendorAvailabilityStatus = this.getElectronicVendorAvailabilityStatus(editionsAvailabilityData, hasItemEcontentIssues);

    const isCheckAvailabilityStatus = edition?.eResourceUrls?.length
      && electronicVendorAvailabilityStatus === AvailabilityStatus.UNAVAILABLE;
    const status: AvailabilityStatusMap = {
      [AvailabilityVendorType.GENERAL]: isCheckAvailabilityStatus ? AvailabilityStatus.CHECK_AVAILABILITY : electronicVendorAvailabilityStatus,
      [AvailabilityVendorType.ELECTRONIC_VENDOR]: electronicVendorAvailabilityStatus,
      [AvailabilityVendorType.ELECTRONIC_LOCATOR]: isCheckAvailabilityStatus
        ? AvailabilityStatus.AVAILABLE
        : AvailabilityStatus.UNAVAILABLE,
    };

    return ({
      reserve: vendorsReserves[0],
      status,
      subscriptionId: subscriptionIdList?.length ? subscriptionIdList : [],
    });
  }

  private getElectronicVendorAvailabilityStatus(
    editionAvailabilityData?: EditionAvailabilityData,
    hasItemEcontentIssues = false
  ): AvailabilityStatus {
    if (ALWAYS_AVAILABLE_VENDORS.includes(editionAvailabilityData?.reserve?.vendorName)) {
      return AvailabilityStatus.AVAILABLE;
    }

    if (GET_MATERIAL_VENDORS.includes(editionAvailabilityData?.reserve?.vendorName)) {
      return AvailabilityStatus.GET_MATERIAL;
    }

    if (ECONTENT_VENDORS_WITH_ISSUES.includes(editionAvailabilityData?.reserve?.vendorName) && hasItemEcontentIssues) {
      return AvailabilityStatus.GET_ISSUES;
    }

    if (!editionAvailabilityData?.availability) {
      return AvailabilityStatus.UNAVAILABLE;
    }

    return editionAvailabilityData?.availability.available
      ? AvailabilityStatus.AVAILABLE
      : AvailabilityStatus.CHECKED_OUT;
  }

  private getLatestAvailableEdition(
    editions: Edition[] | undefined,
    availability: Availability,
    eContentAvailabilities: Partial<Record<VendorType, EContentAvailability[]>>
  ): EditionAvailabilityData {

    const vendorsReserves = editions?.length > 0
      ? editions.flatMap(edition => this.getReserveFromVendorsInfo(edition.vendors))
      : this.getReserveFromVendorsInfo(availability?.vendors);

    const editionsAvailabilityData: EditionAvailabilityData[] = vendorsReserves
    .map(data => {
      return ({
        reserve: data,
        availability: eContentAvailabilities[data.vendorName]?.find(availability => availability.reserveId.toLowerCase() === data.id.toLowerCase()),
      });
    });

    const availableEditionsData = editionsAvailabilityData
      .filter(data => !ALWAYS_AVAILABLE_VENDORS.includes(data.reserve.vendorName))
      .filter(data => data.availability?.available);

    const alwaysAvailableEditionsData = editionsAvailabilityData
      .filter(data => ALWAYS_AVAILABLE_VENDORS.includes(data.reserve.vendorName));

    return availableEditionsData?.[0]
      || alwaysAvailableEditionsData?.[0]
      || editionsAvailabilityData?.[0];
  }

  private getReserveFromVendorsInfo(info: Partial<Record<VendorType, VendorInfo>>): Reserve[] {
    const result: Reserve[] = [];
    for (const vendorName of ALL_VENDORS) {
      const reserveIds = info?.[vendorName]?.reserveId;
      if (reserveIds) {
        for (const id of reserveIds) {
          result.push({vendorName, id});
        }
      }
    }
    return result;
  }

  public countPhysicalContentAvailability(
    resourceCountData: { [key: string]: StoredGatesResource },
    tab: MaterialTab,
  ): AvailabilityUpdateInfo {
    let holds = 0;
    let copies = 0;
    const recordIds = tab.recordIds || [];
    recordIds.forEach((id) => {
      const gatesResponse = resourceCountData[id];
      if (gatesResponse) {
        holds += gatesResponse.entity.holds;
        copies += gatesResponse.entity.copies;
      }
    });
    return {holds, copies, status: tab.availability.status};
  }

  public filterFormatGroupsByPhysicalTabsAvailability(formatGroups: FormatGroup[]): {available: FormatGroup[], unavailable: FormatGroup[]} {
    const formatGroupsClone = JSON.parse(JSON.stringify(formatGroups));
    const available: FormatGroup[] = [];
    const unavailable: FormatGroup[] = [];

    for (const formatGroupClone of formatGroupsClone) {
      formatGroupClone.materialTabs = formatGroupClone.materialTabs.filter(this.isPhysicalTabAvailable);

      formatGroupClone.materialTabs.length > 0
        ? available.push(formatGroupClone)
        : unavailable.push(formatGroupClone);
    }

    return {available, unavailable};
  }

  public isPhysicalTabAvailable(tab: MaterialTab): boolean {
    return tab.type === EditionType.PHYSICAL && !tab.availability.isGetItSuppressed;
  }
}
