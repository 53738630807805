import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as Immutable from 'immutable';
import {
  copySavedSearch,
  createSavedSearch,
  createSavedSearchComplete,
  createSavedSearchFailure,
  deleteSavedSearch,
  deleteSavedSearchComplete,
  deleteSavedSearchFailure,
  focusAvailableCardOrEmptyResultMessage,
  focusShareButton,
  getSavedSearch,
  getSavedSearchComplete,
  getSavedSearchFailure,
  loadSavedSearches,
  loadSavedSearchesComplete,
  loadSavedSearchesFailure,
  openEmailModal,
  resetGetSavedSearch,
  resetSavedSearchCreatedState,
  resetSavedSearchDeleteState,
  resetSavedSearchLoadedState,
  resetSavedSearchRunState,
  resetSavedSearchUpdatedState,
  runSavedSearch,
  runSavedSearchFailure,
  runSavedSearchResults,
  setLastCopied,
  setSavedSearchShowcaseRef,
  shareOnFacebook,
  shareOnTwitter,
  stopFocusAvailableCardOrEmptyResultMessage,
  stopFocusShareButton,
  unsetSavedSearchShowcaseRef,
  updateSavedSearch,
  updateSavedSearchComplete,
  updateSavedSearchFailure,
  writeUrl
} from '../actions/saved-search.actions';
import {
  DeleteSavedSearchWithShowcaseError,
  GetSavedSearchError,
  LoadSavedSearchesError,
  RunSavedSearchError,
  RunSavedSearchResults,
  SavedSearch,
  SaveSearchError,
  UpdateSavedSearchError
} from '../models/saved-search';

export interface CreateState {
  loading: boolean;
  error: SaveSearchError | null;
  created: boolean;
}

export interface UpdateState {
  loading: boolean;
  error: UpdateSavedSearchError | null;
  updated: boolean;
}

export interface LoadState {
  loading: boolean;
  error: LoadSavedSearchesError | null;
  loaded: boolean;
}

export interface DeleteState {
  loading: boolean;
  error: DeleteSavedSearchWithShowcaseError | null;
  deleted: boolean;
}

export interface GetState {
  loading: boolean;
  error: GetSavedSearchError | null;
  url: string;
  lastCopied: boolean;
}

export interface RunState {
  id: string;
  loading: boolean;
  error: RunSavedSearchError | null;
  loaded: boolean;
  response: RunSavedSearchResults | null;
}

export enum ShareType {
  copy = 1,
  email,
  facebook,
  twitter
}

export interface SavedSearchState {
  list: Immutable.List<SavedSearch>;
  createState: Immutable.Record<CreateState>;
  updateState: Immutable.Record<UpdateState>;
  loadState: Immutable.Record<LoadState>;
  deleteState: Immutable.Record<DeleteState>;
  getState: { [key: string]: Immutable.Record<GetState> };
  runState: Immutable.Record<RunState>;
  shareType: ShareType | null;
  isFocusPending: boolean;
  focusShareButton: string | null;
}

const CreateStateRecord = Immutable.Record<CreateState>({
  loading: false,
  error: null,
  created: false,
});

const UpdateStateRecord = Immutable.Record<UpdateState>({
  loading: false,
  error: null,
  updated: false,
});

const LoadStateRecord = Immutable.Record<LoadState>({
  loading: false,
  error: null,
  loaded: false,
});

const DeleteStateRecord = Immutable.Record<DeleteState>({
  loading: false,
  error: null,
  deleted: false,
});

export const GetStateRecord = Immutable.Record<GetState>({
  loading: false,
  error: null,
  url: null,
  lastCopied: false,
});

const RunStateRecord = Immutable.Record<RunState>({
  id: null,
  loading: false,
  error: null,
  loaded: false,
  response: null,
});

export const initialState: SavedSearchState = {
  list: Immutable.List(),
  createState: CreateStateRecord(),
  updateState: UpdateStateRecord(),
  loadState: LoadStateRecord(),
  deleteState: DeleteStateRecord(),
  getState: {},
  runState: RunStateRecord(),
  shareType: null,
  isFocusPending: false,
  focusShareButton: null,
};

const sortByName = (savedSearches: Immutable.List<SavedSearch>): Immutable.List<SavedSearch> => {
  return savedSearches.sortBy((savedSearchValue) => savedSearchValue.name.toLowerCase());
};

const savedSearchReducer = createReducer(
  initialState,
  on(createSavedSearch, (state) => ({
    ...state,
    createState: CreateStateRecord({ loading: true }),
  })),
  on(createSavedSearchComplete, (state, { savedSearch }) => ({
    ...state,
    list: sortByName(state.list.push(savedSearch)),
    createState: CreateStateRecord({ created: true }),
  })),
  on(createSavedSearchFailure, (state, { error }) => ({
    ...state,
    createState: CreateStateRecord({ error }),
  })),
  on(resetSavedSearchCreatedState, (state) => ({
    ...state,
    createState: CreateStateRecord(),
  })),
  on(updateSavedSearch, (state) => ({
    ...state,
    updateState: UpdateStateRecord({ loading: true }),
  })),
  on(updateSavedSearchComplete, (state, { savedSearchUpdate }) => ({
    ...state,
    list: sortByName(
      state.list.update(
        state.list.findIndex((search) => search.id === savedSearchUpdate.id),
        (savedSearch) => {
          const {searchRequest, ...update} = savedSearchUpdate;
          return { ...savedSearch, ...update };
        },
      ),
    ),
    updateState: UpdateStateRecord({ updated: true }),
  })),
  on(updateSavedSearchFailure, (state, { id, error }) => ({
    ...state,
    updateState: UpdateStateRecord({ error }),
    list: error.status === 404
      ? state.list.filter((e) => e.id !== id)
      : state.list,
  })),
  on(resetSavedSearchUpdatedState, (state) => ({
    ...state,
    updateState: UpdateStateRecord(),
  })),
  on(loadSavedSearches, (state) => ({
    ...state,
    loadState: LoadStateRecord({ loading: true }),
  })),
  on(loadSavedSearchesComplete, (state, { savedSearches }) => ({
    ...state,
    list: sortByName(Immutable.List(savedSearches)),
    loadState: LoadStateRecord({ loaded: true }),
  })),
  on(loadSavedSearchesFailure, (state, { error }) => ({
    ...state,
    loadState: LoadStateRecord({ error }),
  })),
  on(resetSavedSearchLoadedState, (state) => ({
    ...state,
    loadState: LoadStateRecord(),
  })),
  on(deleteSavedSearch, (state) => ({
    ...state,
    deleteState: DeleteStateRecord({ loading: true }),
  })),
  on(deleteSavedSearchComplete, (state, { id }) => ({
    ...state,
    list: state.list.filter((e) => e.id !== id),
    deleteState: DeleteStateRecord({ deleted: true }),
  })),
  on(deleteSavedSearchFailure, (state, { id, error }) => ({
    ...state,
    deleteState: DeleteStateRecord({ error, deleted: error.savedSearch?.status === 404 }),
    list: error.savedSearch?.status === 404
      ? state.list.filter((e) => e.id !== id)
      : state.list,
  })),
  on(resetSavedSearchDeleteState, (state) => ({
    ...state,
    deleteState: DeleteStateRecord(),
  })),
  on(getSavedSearch, (state, { id }) => ({
    ...state,
    getState: {
      [id]: GetStateRecord({ loading: true }),
    },
  })),
  on(getSavedSearchFailure, (state, { id, error }) => ({
    ...state,
    list: error.status === 404
      ? state.list.filter((e) => e.id !== id)
      : state.list,
    getState: {
      [id]: GetStateRecord({ error }),
    },
  })),
  on(getSavedSearchComplete, (state, { id }) => ({
    ...state,
    getState: {
      [id]: GetStateRecord(),
    },
  })),
  on(resetGetSavedSearch, (state) => ({
    ...state,
    getState: {},
  })),
  on(openEmailModal, (state) => ({
    ...state,
    shareType: ShareType.email,
  })),
  on(copySavedSearch, (state) => ({
    ...state,
    shareType: ShareType.copy,
  })),
  on(shareOnFacebook, (state) => ({
    ...state,
    shareType: ShareType.facebook,
  })),
  on(shareOnTwitter, (state) => ({
    ...state,
    shareType: ShareType.twitter,
  })),
  on(writeUrl, (state, { id, url }) => ({
    ...state,
    getState: {
      [id]: GetStateRecord({ url }),
    },
  })),
  on(setLastCopied, (state, { id }) => ({
    ...state,
    getState: {
      [id]: GetStateRecord({ lastCopied: true }),
    },
  })),
  on(focusAvailableCardOrEmptyResultMessage, (state) => ({
    ...state,
    isFocusPending: true,
  })),
  on(stopFocusAvailableCardOrEmptyResultMessage, (state) => ({
    ...state,
    isFocusPending: false,
  })),
  on(focusShareButton, (state, { savedSearchId }) => ({
    ...state,
    focusShareButton: savedSearchId,
  })),
  on(stopFocusShareButton, (state) => ({
    ...state,
    focusShareButton: null,
  })),
  on(runSavedSearch, (state, { savedSearchId }) => ({
    ...state,
    runState: RunStateRecord({ id: savedSearchId, loading: true }),
  })),
  on(runSavedSearchResults, (state, { results, searchObject }) => ({
    ...state,
    runState: RunStateRecord({ loaded: true, response: { results, searchObject } }),
  })),
  on(runSavedSearchFailure, (state, { id, error }) => ({
    ...state,
    runState: RunStateRecord({ id, error, loading: false }),
    list: error.status === 404
      ? state.list.filter((e) => e.id !== id)
      : state.list,
  })),
  on(setSavedSearchShowcaseRef, (state, {savedSearchId, showcaseRef}) => ({
    ...state,
    list: updateSavedSearchIfFound(state,
      (savedSearch) => savedSearch.id === savedSearchId,
      (savedSearch) => ({...savedSearch, showcaseRef}),
    ),
  })),
  on(unsetSavedSearchShowcaseRef, (state, {savedSearchId}) => ({
    ...state,
    list: updateSavedSearchIfFound(state,
      (savedSearch) => savedSearch.id === savedSearchId,
      (savedSearch) => ({...savedSearch, showcaseRef: null}),
    ),
  })),
  on(resetSavedSearchRunState, (state) => ({
    ...state,
    runState: RunStateRecord(),
  })),
);

export function reducer(state: SavedSearchState | undefined, action: Action) {
  return savedSearchReducer(state, action);
}

export const savedSearchFeatureKey = 'savedSearch';

export const getSavedSearchState = createFeatureSelector<SavedSearchState>(savedSearchFeatureKey);

export const getSavedSearches = createSelector(getSavedSearchState, (state) => {
  return (state && state.list) ? state.list.toArray() : null;
});

export const getCreateState = createSelector(getSavedSearchState, (state): CreateState => {
  return (state && state.createState) ? (state.createState.toJS() as CreateState) : null;
});

export const getUpdateState = createSelector(getSavedSearchState, (state): UpdateState => {
  return (state && state.updateState) ? (state.updateState.toJS() as UpdateState) : null;
});

export const getLoadState = createSelector(getSavedSearchState, (state): LoadState => {
  return (state && state.loadState) ? (state.loadState.toJS() as LoadState) : null;
});

export const getDeleteState = createSelector(getSavedSearchState, (state): DeleteState => {
  return state.deleteState.toJS();
});

export const getGetState = createSelector(
  getSavedSearchState,
  (state: SavedSearchState, props: { id: string }): GetState => {
    return (state && state.getState && state.getState[props.id]) ? (state.getState[props.id].toJS() as GetState) : null;
  },
);

export const getShareType = createSelector(getSavedSearchState, (state): ShareType | null => {
  return (state && state.shareType) ? state.shareType : null;
});

export const getIsFocusPending = createSelector(getSavedSearchState, (state): boolean => {
  return (state) ? state.isFocusPending : null;
});

export const getFocusShareButton = createSelector(getSavedSearchState, (state): string | null => {
  return (state) ? state.focusShareButton : null;
});

export const getRunState = createSelector(getSavedSearchState, (state): RunState => {
  return (state && state.runState) ? (state.runState.toJS() as RunState) : null;
});

export const getRunResults = createSelector(getRunState, (runState): RunSavedSearchResults => {
  return runState && runState.response ? runState.response : null;
});

const updateSavedSearchIfFound = (
  state: SavedSearchState,
  savedSearchPredicate: (savedSearch: SavedSearch) => boolean,
  callback: (savedSearch: SavedSearch) => SavedSearch,
): Immutable.List<SavedSearch> => {
  let newState = state.list;
  const savedSearchIndex = state.list.findIndex(savedSearchPredicate);

  if (savedSearchIndex >= 0) {
    newState = state.list.update(savedSearchIndex, callback);
  }
  return newState;
};
