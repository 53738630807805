import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { asapScheduler, merge, of, scheduled, zip } from 'rxjs';
import { filter, first, map, pluck, switchMap, tap, } from 'rxjs/operators';
import { rollupTabSelected, setEntities, setFocusedResourceId } from '../../../../entity/actions/entity.actions';
import { loadFormatGroupComplete } from '../../../../entity/actions/full-entity.actions';
import { loadNlbBookmarksSuccess, resumeCreateNlbBookmarkAfterLogin, tryToCreateNlbBookmark } from '../actions/nlb-bookmark.actions';

@Injectable()
export class NlbBookmarkAfterLoginEffects {
  public static readonly SRP_URL = '/search?query';
  public static readonly FRC_URL = '/search/card';

  firstNavigationAfterClickNlbBookmarkAndLogin = (url: string) =>
    this.router.events.pipe(
      first((event) => event instanceof NavigationEnd),
      filter((event: NavigationEnd) => event.url.includes(url)),
      map(() => this.router.parseUrl(this.router.url)),
      pluck('queryParams'),
      filter((queryParams) => !!queryParams.nlbBookmarkFgId),
    );

  public returnAuthenticatedAndCreateNlbBookmarkSRP$ = createEffect(() =>
    zip(
      this.firstNavigationAfterClickNlbBookmarkAndLogin(NlbBookmarkAfterLoginEffects.SRP_URL),
      this.actions$.pipe(ofType(setEntities), first()),
      this.actions$.pipe(ofType(loadNlbBookmarksSuccess), first()),
    ).pipe(
      map(([{nlbBookmarkFgId, nlbBookmarkTabName: tabName}, {entities}, {nlbBookmarks}]) => {
        const formatGroup = entities.find((fg) => fg.id === nlbBookmarkFgId);
        return resumeCreateNlbBookmarkAfterLogin({formatGroup, tabName, nlbBookmarks, focusResource: true});
      }),
    ));

  public returnAuthenticatedAndCreateNlbBookmarkFRC$ = createEffect(() =>
    zip(
      this.firstNavigationAfterClickNlbBookmarkAndLogin(NlbBookmarkAfterLoginEffects.FRC_URL),
      this.actions$.pipe(ofType(loadFormatGroupComplete), first()),
      this.actions$.pipe(ofType(loadNlbBookmarksSuccess), first()),
    ).pipe(
      map(([{nlbBookmarkTabName: tabName}, {entity}, {nlbBookmarks}]) => {
        const formatGroup = entity.fgEntity;
        return resumeCreateNlbBookmarkAfterLogin({formatGroup, tabName, nlbBookmarks, focusResource: false});
      }),
    ));

  public resumeCreateNlbBookmarkAfterLogin$ = createEffect(() => this.actions$.pipe(
    ofType(resumeCreateNlbBookmarkAfterLogin),
    filter(({formatGroup}) => !!formatGroup),
    map(({formatGroup, tabName, nlbBookmarks, focusResource}) => {
      const tabIndex = formatGroup.materialTabs.findIndex((tab) => tab.name === tabName);
      const fgRecordIds = formatGroup.materialTabs.flatMap((materialTab) => materialTab.recordIds);
      const bookmarked = !!nlbBookmarks.find((bookmark) => fgRecordIds.includes(bookmark.recordId));

      return {formatGroup, tabName, tabIndex, bookmarked, focusResource};
    }),
    switchMap(({formatGroup, tabName, tabIndex, bookmarked, focusResource}) => {
      const actions: Action[] = [];
      if (tabIndex >= 0) {
        actions.push(
          rollupTabSelected({resourceId: formatGroup.id, tab: tabIndex, skipFirstAutomaticSelection: true}),
        );
        if (focusResource) {
          actions.push(setFocusedResourceId({resourceId: formatGroup.id}));
        }
        if (!bookmarked) {
          actions.push(tryToCreateNlbBookmark({formatGroup, tabName}));
        }
      }

      return scheduled(of(
        ...actions,
      ), asapScheduler);
    }),
  ));

  public clearIncompleteNlbRequests$ = createEffect(() => merge(
    this.actions$.pipe(ofType(resumeCreateNlbBookmarkAfterLogin)),
  ).pipe(
    filter(() => {
      const {queryParams} = this.router.parseUrl(this.router.url);
      const {nlbBookmarkFgId, nlbBookmarkTabName} = queryParams;
      return nlbBookmarkFgId || nlbBookmarkTabName;
    }),
    tap(() => {
      this.router.navigate([], {
        queryParams: {
          nlbBookmarkFgId: null,
          nlbBookmarkTabName: null,
        },
        queryParamsHandling: 'merge',
      });
    }),
  ), {dispatch: false});

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
  ) {
  }
}
