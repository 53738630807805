import { Action, createReducer, on } from '@ngrx/store';
import { OrderedMap } from 'immutable';
import {
  APPLY_RESOURCE_FACETS,
  CANCEL_EDITING_RESOURCE_FACETS,
  CLEAR_RESOURCE_FACETS,
  COLLAPSE_RESOURCE_FACETS,
  EXPAND_RESOURCE_FACETS,
  EXPAND_RESOURCE_FACETS_BLOCK,
  FacetActions,
  LOAD_LAZY_FACET,
  LOAD_RESOURCE_FACETS,
  loadSingleLazyFacet,
  REMOVE_FACET_BUBBLE,
  RESET_FACET_BUBBLES,
  RESET_RESOURCE_FACETS,
  RESOURCE_FACET_BLOCK_SELECTED,
  RESOURCE_FACET_SELECTED,
  RESOURCE_FACETS_LOAD_COMPLETE,
  singleLazyFacetLoadCompleteAction,
  TOGGLE_FACET_BUBBLE_LOCK,
  UPDATE_SEARCH_OBJECT,
  UPDATE_SELECTED_FACETS,
} from 'search/actions/facet.actions';
import { FacetState, FacetURLFilters, } from 'search/facets/models/facet-state';
import { ResourceFacets, SearchFacetsObject, } from 'search/facets/models/resource-facet';
import { getProcessor } from 'search/facets/processors/actions/facets-actions-factory';
import { SearchObject } from 'search/models/search-object';
import { RootState } from 'search/reducers/root.reducer';
import { copyObject } from 'search/reducers/utils';
import { ResourceFacetBlock } from '../../models/filter-panel';
import { FacetSchemaWithData } from '../models/facet-schema-with-data';
import { defaultResourceFacet, Facet, Facets, ResourceFacet, } from '../models/resource-facet';

export interface ResourceFacetsState {
  facets: Facets;
}

export const initialResourceFacetsState: ResourceFacetsState = {
  facets: {
    v2State: OrderedMap<string, FacetState>(),
  },
};

export type CombinedState = ResourceFacetsState & RootState;

const newResourceFacetsReducer = createReducer(
  initialResourceFacetsState as CombinedState,
  on(loadSingleLazyFacet, (state, {facetType}) => {
    const updatedFacet = state.facets.v2State
    .find((facet) => facet.schemaWithData.schema.mapField === facetType).set('isLoading', true);
    const key = updatedFacet.getIn(['schemaWithData', 'schema', 'key']) as string;
    const newState = state.facets.v2State.set(key, updatedFacet);
    return {
      ...state,
      facets: {
        ...state.facets,
        v2State: newState,
      },
    };
  }),
  on(singleLazyFacetLoadCompleteAction, (state, {facet, facetsLocked, facetKey, schemaWithData}) => {
    let newState = OrderedMap(state.facets.v2State);
    let newFacets = state.facets.facetsResults;
    if ((newFacets as any)?.[schemaWithData.schema.mapField]) {
      (newFacets as any)[schemaWithData.schema.mapField] = facet;
    }
    newState = newState.set(facetKey, getProcessor(schemaWithData.schema.type).processFacetsLoaded(newState.get(facetKey),
      {...state.searchObject.filters.facets} as FacetURLFilters, schemaWithData, facetsLocked));

    return {
      ...state,
      facets: {
        ...state.facets,
        v2State: newState,
        facetsResults: newFacets,
      }
    };
  }),
);

export const resourceFacetsReducer = (genericAction: FacetActions | Action) =>
  (state: CombinedState): CombinedState => {
  const action = genericAction as FacetActions;
  switch (action.type) {

    case UPDATE_SEARCH_OBJECT: {
      return {
        ...state,
        loading: true,
        searchResultsMetadata: { totalResults: 0, totalPages: 0 },
        searchObject: {
          ...mergeSearchObject(state.searchObject, action.payload as SearchObject),
          },
          error: null,
        };
      }

    case EXPAND_RESOURCE_FACETS: {
      const newState = state.facets.v2State.valueSeq()
                            .sort((a, b) => a.schemaWithData.schema.order - b.schemaWithData.schema.order)
                            .reduce((acc, facetState, index) => {
                              const schema = facetState.schemaWithData.schema;
                              const payload = action.payload;
                              const expanded = !!payload && payload.includes(index);
                              return acc.set(schema.key, getProcessor(schema.type).processExpandFacets(facetState, expanded, !payload));
                            }, OrderedMap(state.facets.v2State));
      return {
        ...state,
        facets: {
          ...state.facets,
          v2State: newState,
        },
      };
    }

    case EXPAND_RESOURCE_FACETS_BLOCK: {
      const {facetKey, expanded} = action.payload;
      let newState = OrderedMap(state.facets.v2State);
      const oldFacetState = newState.get(facetKey);
      if (oldFacetState) {
        const processor = getProcessor(oldFacetState.schemaWithData.schema.type);
        newState = newState.set(facetKey, processor.processExpandFacets(oldFacetState, expanded));
      }

      return {
        ...state,
        facets: {
          ...state.facets,
          v2State: newState,
        },
      };
    }

    case COLLAPSE_RESOURCE_FACETS: {
      const newState = state.facets.v2State.valueSeq().reduce((acc, facetState) => {
        const schema = facetState.schemaWithData.schema;
        return acc.set(schema.key, getProcessor(schema.type).processCollapseFacets(facetState));
      }, OrderedMap(state.facets.v2State));
      return {
        ...state,
        facets: {
          ...state.facets,
          v2State: newState,
        },
      };
    }

    case LOAD_LAZY_FACET: {
      const { searchObject } = action.payload;
      const updatedFacet = state.facets.v2State
        .find((facet) => facet.schemaWithData.schema.mapField === searchObject.facetTypes[0]).set('isLoading', true);
      const key = updatedFacet.getIn(['schemaWithData', 'schema', 'key']) as string;
      const newState = state.facets.v2State.set(key, updatedFacet);
      return {
        ...state,
        facets: {
          ...state.facets,
          v2State: newState,
        },
      };
    }

    case RESOURCE_FACETS_LOAD_COMPLETE: {
      const { facets, facetsLocked, isLazyFacets, lazyFacetIds } = action.payload;
      const newFacets = isLazyFacets ? {
        ...state.facets.facetsResults,
        ...facets,
      } : facets;

      let newState = OrderedMap(state.facets.v2State);
      newFacets?.newModel.forEach((schemaWithData) => {
        const facetKey = schemaWithData.schema.key;
        const oldFacetState = newState.get(facetKey);
        let actualSchema;
        if (isLazyFacets) {
          if (schemaWithData.schema.mapField === lazyFacetIds[0]) {
            actualSchema = facets.newModel.find((facet) => facet.schema.mapField === lazyFacetIds[0]);
          } else {
            actualSchema = oldFacetState.schemaWithData as FacetSchemaWithData<Partial<Facet>>;
          }
        } else {
          actualSchema = schemaWithData;
        }
        newState = newState.set(facetKey, getProcessor(actualSchema.schema.type).processFacetsLoaded(oldFacetState,
          {...state.searchObject.filters.facets} as FacetURLFilters, actualSchema, facetsLocked));
      });

      return {
        ...state,
        facets: {
          ...state.facets,
          v2State: newState,
          facetsResults: newFacets,
        },
      };
    }

    case RESOURCE_FACET_SELECTED: {
      const facet = action.payload;
      const {facetKey} = facet;
      let newState = OrderedMap(state.facets.v2State);
      const oldFacetState = newState.get(facetKey);
      if (oldFacetState) {
        newState = newState.set(facetKey, getProcessor(oldFacetState.schemaWithData.schema.type).processFacetSelected(oldFacetState, facet));
      }

      return {
        ...state,
        facets: {
          ...state.facets,
          v2State: newState,
        },
      };
    }

    case UPDATE_SELECTED_FACETS: {
      const selectedFacets = action.payload.selected;
      if (!selectedFacets) {
        return {...state};
      }
      let newState = OrderedMap(state.facets.v2State);
      const facetKey = action.payload.facetKey;
      const oldFacetState = newState.get(facetKey);
      if (oldFacetState) {
        const facetType = oldFacetState.schemaWithData.schema.type;
        newState = newState.set(facetKey, getProcessor(facetType).processReplaceSelectedFacets(oldFacetState, selectedFacets));
      }

      return {
        ...state,
        facets: {
          ...state.facets,
          v2State: newState,
        },
      };
    }

    case RESOURCE_FACET_BLOCK_SELECTED: {
      const {facetKey} = action.payload;
      let newState = OrderedMap(state.facets.v2State);
      const oldFacetState = newState.get(facetKey);
      if (oldFacetState) {
        const facetType = oldFacetState.schemaWithData.schema.type;
        newState = newState.set(facetKey, getProcessor(facetType).processFacetBlockSelected(oldFacetState));
      }

      return {
        ...state,
        facets: {
          ...state.facets,
          v2State: newState,
        },
      };
    }

    case APPLY_RESOURCE_FACETS: {
      const newState = state.facets.v2State.valueSeq().reduce((acc, facetState) => {
        const schema = facetState.schemaWithData.schema;
        return acc.set(schema.key, getProcessor(schema.type).processApplyFacets(facetState));
      }, OrderedMap(state.facets.v2State));
      return {
        ...state,
        facets: {
          ...state.facets,
          v2State: newState,
        },
        loading: true,
        searchObject: {
          ...state.searchObject,
          pagination: {
            ...state.searchObject.pagination,
            pageNum: 0,
          },
        },
      };
    }

    case RESET_RESOURCE_FACETS: {
      const newState = state.facets.v2State.valueSeq().reduce((acc, facetState) => {
        const schema = facetState.schemaWithData.schema;
        return acc.set(schema.key, getProcessor(schema.type).processResetFacetState(facetState));
      }, OrderedMap(state.facets.v2State));
      return {
        ...state,
        facets: {
          ...state.facets,
          v2State: newState,
        },
      };
    }

    case CLEAR_RESOURCE_FACETS: {
      return {
        ...state,
        ...initialResourceFacetsState,
      };
    }

    case LOAD_RESOURCE_FACETS: {
      const newState = state.facets.v2State.valueSeq().reduce((acc, facetState) => {
        const schema = facetState.schemaWithData.schema;
        return acc.set(schema.key, getProcessor(schema.type).processLoadFacetsStarted(facetState));
      }, OrderedMap(state.facets.v2State));
      return {
        ...state,
        facets: {
          ...state.facets,
          v2State: newState,
        },
      };
    }

    case CANCEL_EDITING_RESOURCE_FACETS: {
      const newState = state.facets.v2State.valueSeq().reduce((acc, facetState) => {
        const schema = facetState.schemaWithData.schema;
        return acc.set(schema.key, getProcessor(schema.type).processCancelEditingFacets(facetState));
      }, OrderedMap(state.facets.v2State));
      return {
        ...state,
        facets: {
          ...state.facets,
          v2State: newState,
        },
      };
    }

    case TOGGLE_FACET_BUBBLE_LOCK: {
      const {facet} = action.payload;
      const {facetKey} = facet;
      let newState = OrderedMap(state.facets.v2State);
      const oldFacetState = newState.get(facetKey);
      if (oldFacetState) {
        newState = newState.set(facetKey, getProcessor(oldFacetState.schemaWithData.schema.type).processFacetLocked(oldFacetState, facet));
      }

      return {
        ...state,
        facets: {
          ...state.facets,
          v2State: newState,
        },
      };
    }

    case RESET_FACET_BUBBLES: {
      const {resetAll} = action.payload;
      const newState = state.facets.v2State.valueSeq().reduce((acc, facetState) => {
        const schema = facetState.schemaWithData.schema;
        return acc.set(schema.key, getProcessor(schema.type).processBubblesReset(facetState, resetAll));
      }, OrderedMap(state.facets.v2State));

      return {
        ...state,
        facets: {
          ...state.facets,
          v2State: newState,
        },
      };
    }

    case REMOVE_FACET_BUBBLE: {
      const {facet} = action.payload;
      const {facetKey} = facet;
      let newState = OrderedMap(state.facets.v2State);
      const oldFacetState = newState.get(facetKey);
      if (oldFacetState) {
        newState = newState.set(facetKey, getProcessor(oldFacetState.schemaWithData.schema.type).processBubbleRemoved(oldFacetState, facet.id));
      }

      return {
        ...state,
        facets: {
          ...state.facets,
          v2State: newState,
        },
      };
    }

    default: {
      return newResourceFacetsReducer(state, action as Action);
    }
  }
};

export const mergeSearchObject = (existingSearchObject: SearchObject, newSearchObject: SearchObject) => {
  const result = Object.assign(copyObject(existingSearchObject), newSearchObject);

  if (!newSearchObject) {
    return result;
  }

  if (newSearchObject.searchParams.type.searchText) {
    result.searchParams.type.metadataBoolQuery = null;
    result.searchParams.type.resourceIds = null;
  } else if (newSearchObject.searchParams.type.metadataBoolQuery) {
    // BE expects that we don't have this field in this case so we need to remove it
    delete result.searchParams.type.searchText;
    result.searchParams.type.resourceIds = null;
  } else if (newSearchObject.searchParams.type.resourceIds) {
    delete result.searchParams.type.searchText;
    result.searchParams.type.metadataBoolQuery = null;
  }
  return result;
};

export const mapToSearchFacetObject = (resourceFacets: ResourceFacets): SearchFacetsObject => {
  return resourceFacets ? Object.keys(resourceFacets).reduce((obj, k) => {
    const facets = (resourceFacets as any)[k].data;
    if (facets) {
      (obj as any)[k] = facets;
    }
    return obj;
  }, {}) : null;
};

export const combineBooleanFacets = (dataResponse: Partial<Facet>, selectedFacet: ResourceFacet, applied: boolean, facetKey: ResourceFacetBlock) => {
  if (dataResponse) {
    if (selectedFacet) {
      return {
        ...dataResponse,
        isResetLocked:applied && selectedFacet.isResetLocked,
        selected: selectedFacet.selected,
        applied,
        facetKey,
      };
    }
    return {...dataResponse, selected: applied, applied, facetKey};
  }
  return selectedFacet ? {
        ...selectedFacet,
          selected: applied,
          applied,
          isResetLocked: applied && selectedFacet.isResetLocked,
          count: 0,
        } : {...defaultResourceFacet, selected: applied, applied, facetKey};
};
