import { LocalizationService } from '../services/localization.service';

export const dateFormatFactory = (localizationService: LocalizationService) => {
  const finalDateFormat = (localizationService.dateFormat).toUpperCase();

  return {
    parse: {
      dateInput: finalDateFormat,
    },
    display: {
      dateInput: finalDateFormat,
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: finalDateFormat,
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };
};
