import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OverDriveAvailability } from 'core/over-drive/models/over-drive-availability';
import { OverDriveService } from 'core/over-drive/services/over-drive.service';
import * as Immutable from 'immutable';
import { forkJoin, of,} from 'rxjs';
import {
  bufferTime,
  catchError,
  filter,
  finalize,
  map,
  mergeMap,
  pluck,
  switchMap,
  tap,
} from 'rxjs/operators';
import { VendorType} from '../models/entity';
import { GatesService } from 'search/services/gates.service';
import { notifyNewEntitiesOnPage } from '../../list/actions/list.actions';
import {
  checkEntityVendorAvailability,
  entityVendorAvailabilityCheckComplete,
  gatesLoadResourceCount,
  gatesLoadResourceCountComplete,
  loadVendorAvailability,
  setEntities
} from '../actions/entity.actions';
import { EcontentAvailability, EcontentService } from 'core/econtent/services/econtent.service';
import { Store } from '@ngrx/store';

@Injectable()
export class EntityEffects {
  public notifyAboutNewBookmarkItems = createEffect(() => this.actions$.pipe(
    ofType(setEntities),
    map(({entities}) => notifyNewEntitiesOnPage({entities})),
  ));

  public rollupTabSelected = createEffect(() => this.actions$.pipe(
    ofType(gatesLoadResourceCount),
    filter(({recordIds}) => !!recordIds?.length),
    pluck('recordIds'),
    bufferTime(200),
    filter((resourceIds) => !!resourceIds.length),
    switchMap((resourceIds: string[][]) => {
      const deduplicatedResourceIds = Immutable.fromJS(resourceIds).flatten().toSet().toArray() as string[];
      return this.gatesService.getResourceCountForRecordIds(deduplicatedResourceIds).pipe(
        map((result) => gatesLoadResourceCountComplete(result)),
      );
    }),
  ));

  public checkSearchResultsRollupAvailability$ = createEffect(() => this.actions$.pipe(
    ofType(checkEntityVendorAvailability),
    filter(({content}) => !!content.reserveId),
    tap(({vendor, content}) => this.store.dispatch(loadVendorAvailability({vendor, resourceId: content.resourceId, isLoading: true}))),
    mergeMap(({vendor, content}) => {
      return this.makeCheckAvailabilityCallRollUp(vendor, content.reserveId)
        .pipe(
          map((data) => {
            return entityVendorAvailabilityCheckComplete({
              resourceId: content.resourceId,
              info: data,
              vendor: vendor as VendorType,
            });
          }),
          finalize(() => this.store.dispatch(loadVendorAvailability({vendor, resourceId: content.resourceId, isLoading: false})))
      );
    }),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly gatesService: GatesService,
    private readonly overDriveService: OverDriveService,
    private readonly econtentService: EcontentService,
    private readonly store: Store
  ) {
  }

  private makeCheckAvailabilityCallRollUp(vendor: VendorType | string, reserveIds: string[] = []) {
    let obs = of([]);
    switch (vendor) {
      case VendorType.OVERDRIVE: {
        const overDriveResults = [];
        const chunk = 25;
        for (let i = 0, j = reserveIds.length; i < j; i += chunk) {
          const overDriveResult = this.overDriveService.checkAvailability(reserveIds.slice(i, i + chunk))
          .pipe(catchError(() => of([])));
          overDriveResults.push(overDriveResult);
        }
        return forkJoin(overDriveResults).pipe(map((results: OverDriveAvailability[][]) => results.flatMap((r: OverDriveAvailability[]) => r)));
      }
      case VendorType.AXIS360:
      case VendorType.BORROW_BOX:
      case VendorType.CLOUD_LIBRARY: {
        const vendorResults = [];
        const vendorResult = this.econtentService.checkAvailability(vendor, reserveIds)
        .pipe(catchError(() => of([])));
        vendorResults.push(vendorResult);
        return forkJoin(vendorResults).pipe(map((results: EcontentAvailability[][]) => results.flatMap((r: EcontentAvailability[]) => r)));
    }
      default : {
        return obs;
      }
    }
  }
}
