import { Component, EventEmitter, Input, Output } from '@angular/core';

export enum BookshelfCardActionStateTypeEnum {
  none,
  loading,
  success,
  error,
}

export type BookshelfCardActionState = {
  type: BookshelfCardActionStateTypeEnum.none | BookshelfCardActionStateTypeEnum.loading;
} | {
  type: BookshelfCardActionStateTypeEnum.success | BookshelfCardActionStateTypeEnum.error;
  messageKey: string;
}

@Component({
  selector: 'app-bookshelf-card-action-state',
  templateUrl: './bookshelf-card-action-state.component.html',
  styleUrls: ['./bookshelf-card-action-state.component.scss'],
})
export class BookshelfCardActionStateComponent {
  @Input() state: BookshelfCardActionState;
  @Input() clickOutsideMessageEnabled = false;
  @Output() public readonly clickOutsideMessage = new EventEmitter<undefined>();

  public readonly bookshelfCardActionStateTypeEnum = BookshelfCardActionStateTypeEnum;
}
