import { Action, createAction, props } from '@ngrx/store';
import { GetListItemsError, ListItemEntity, ListSort, LoadListItemsPayload } from '../../list/models/list';
import { ListPagination, ListSortByString } from '../../list/models/list.dto';
import { GetSavedSearchError, GetSavedSearchResponse } from '../../saved-search/models/saved-search';
import {
  CoversAndItemsCount,
  CustomShowcase,
  CustomShowcaseCreatedFrom,
  CustomShowcaseCreationForm,
  CustomShowcaseForm,
  CustomShowcasePublishDate,
  CustomShowcaseReference,
  CustomShowcaseSingle,
  CustomShowcaseUpdatedFields,
} from '../models/custom-showcase';
import {
  CustomShowcaseDto,
  CustomShowcasePagination,
  CustomShowcasePaginationParams,
  EmbeddableShowcaseConfigDto,
  PublishedShowcase
} from '../models/custom-showcase.dto';
import { ShowcaseSearchData } from '../reducers/custom-showcase.reducer';

export const create = createAction(
  '[CustomShowcase] Create custom showcase',
  props<{ form: CustomShowcaseCreationForm, isSaveAndPublish?: boolean, currentPublishStatus?: boolean }>(),
);

export const createSuccess = createAction(
  '[CustomShowcase] Custom showcase has been created',
  props<CustomShowcaseReference>(),
);

export const createFailure = createAction(
  '[CustomShowcase] Failed to create custom showcase',
  props<{ errorCode: number }>(),
);

export const resetCreateState = createAction(
  '[CustomShowcase] Reset custom showcase create state',
);

export const remove = createAction(
  '[CustomShowcase] Remove custom showcase',
  props<CustomShowcaseReference & { createdFrom: CustomShowcaseCreatedFrom, actionOnSuccess?: Action, actionOnFailure?: Action }>(),
);

export const removeSuccess = createAction(
  '[CustomShowcase] Custom showcase has been removed',
  props<CustomShowcaseReference>(),
);

export const removeFailure = createAction(
  '[CustomShowcase] Failed to remove custom showcase',
  props<{ id: string, errorCode: number }>(),
);

export const resetRemoveState = createAction(
  '[CustomShowcase] Reset custom showcase remove state',
);

export const update = createAction(
  '[CustomShowcase] Update custom showcase',
  props<{ updatedFields: CustomShowcaseUpdatedFields, id: string, isSaveAndPublish?: boolean, currentPublishStatus?: boolean}>(),
);

export const publish = createAction(
  '[CustomShowcase] Publish/Unpublish custom showcase',
  props<{ updatedFields: CustomShowcaseUpdatedFields, id: string }>(),
);

export const publishFailure = createAction(
  '[CustomShowcase] Failed to publish custom showcase',
  props<{ errorCode: number, id: string }>(),
);

export const updateSuccess = createAction(
  '[CustomShowcase] Custom showcase has been updated',
  props<{ id: string }>(),
);

export const updateFailure = createAction(
  '[CustomShowcase] Failed to update custom showcase',
  props<{ errorCode: number, id: string }>(),
);

export const resetUpdateState = createAction(
  '[CustomShowcase] Reset custom showcase update state',
);

export const applyUpdate = createAction(
  '[CustomShowcase] Apply custom showcase update',
  props<{ id: string, updatedFields?: CustomShowcaseUpdatedFields,
    publishDateUpdate?: CustomShowcasePublishDate, coversAndItemsCount?: CoversAndItemsCount }>(),
);

export const applyFormDataUpdate = createAction(
  '[CustomShowcase] Apply custom showcase form data update',
  props<{ id: string, updatedFields?: CustomShowcaseUpdatedFields, publishDateUpdate?: CustomShowcasePublishDate }>(),
);

export const loadMyShowcases = createAction(
  '[CustomShowcase] Load my custom showcases',
);

export const loadAllShowcases = createAction(
  '[CustomShowcase] Load all custom showcases',
  props<{ paginationParams: CustomShowcasePaginationParams }>(),
);

export const preloadAllShowcasesCount = createAction(
  '[CustomShowcase] Preload all custom showcases count',
);

export const loadAllFirstPage = createAction(
  '[CustomShowcase] Load first page of all custom showcases',
);

export const loadAllMore = createAction(
  '[CustomShowcase] Load next page of all custom showcases',
);

export const loadSuccess = createAction(
  '[CustomShowcase] Load custom showcases success',
);

export const loadFailure = createAction(
  '[CustomShowcase] Load custom showcases failure',
  props<{ errorCode: number }>(),
);

export const resetLoadState = createAction(
  '[CustomShowcase] Reset load custom showcases state',
);

export const notifyLoaded = createAction(
  '[CustomShowcase] Notify custom showcases loaded',
  props<{ customShowcaseDtos?: CustomShowcaseDto[] }>(),
);

export const applyMyShowcasesLoaded = createAction(
  '[CustomShowcase] Apply loaded my custom showcases on load complete',
  props<{ customShowcases: CustomShowcase[] }>(),
);

export const applySearchMyShowcasesLoaded = createAction(
  '[CustomShowcase] Apply loaded search my custom showcases on load complete',
  props<{ customShowcases: CustomShowcase[] }>(),
);

export const addAllShowcasesLoaded = createAction(
  '[CustomShowcase] Add all custom showcases on load complete',
  props<{ customShowcases: CustomShowcase[], pagination: CustomShowcasePagination, skipTotalCount?: boolean }>(),
);

export const updateAllTotalCount = createAction(
  '[CustomShowcase] Update all total count',
  props<{ totalCount: number }>(),
);

export const clearSearchMyShowcases = createAction(
  '[CustomShowcase] Clear search my custom showcases',
);

export const clearAllShowcases = createAction(
  '[CustomShowcase] Clear all custom showcases',
);

export const openShowcase = createAction(
  '[CustomShowcase] Open showcase',
  props<{ id: string }>(),
);

export const showcaseClosed = createAction(
  '[CustomShowcase] Showcase closed',
  props<{ showcase: CustomShowcaseSingle }>(),
);

export const clearOpenedState = createAction(
  '[CustomShowcase] Clear opened state',
);

export const loadShowcaseSuccess = createAction(
  '[CustomShowcase] Load showcase success',
  props<{ showcase: CustomShowcaseSingle, listSortToRestoreOnClose?: ListSort }>(),
);

export const loadShowcaseFailure = createAction(
  '[CustomShowcase] Load showcase failure',
  props<{ errorCode: number }>(),
);

// <editor-fold desc="Curated showcase actions">
export const loadCuratedShowcaseItems = createAction(
  '[CustomShowcase] Load curated showcase items',
  props<LoadListItemsPayload>(),
);

export const loadCuratedShowcaseItemsSuccess = createAction(
  '[CustomShowcase] Load curated showcase items success',
  props<{ pagination: ListPagination }>(),
);

export const loadCuratedShowcaseItemsFailure = createAction(
  '[CustomShowcase] Load curated showcase items failure',
  props<{ error: GetListItemsError }>(),
);

export const loadMoreOpenedCuratedShowcaseItems = createAction(
  '[CustomShowcase] Load more curated opened showcase items',
);

export const loadMoreCuratedShowcaseItems = createAction(
  '[CustomShowcase] Load more curated showcase items',
  props<{ listId: string }>(),
);

export const addLoadedCuratedShowcaseItems = createAction(
  '[CustomShowcase] Add loaded curated showcase items',
  props<{ entities: ListItemEntity[] }>(),
);

export const toggleOpenedCuratedShowcaseItemSelection = createAction(
  '[CustomShowcase] Toggle opened curated showcase item selection',
  props<{ itemId: string }>(),
);

export const setAllOpenedCuratedShowcaseItemsSelection = createAction(
  '[CustomShowcase] Set all opened curated showcase items selection',
  props<{ selected: boolean }>(),
);

export const clearCuratedShowcaseItems = createAction(
  '[CustomShowcase] Clear curated showcase items',
);

export const notifyCuratedShowcasesItemsUpdated = createAction(
  '[CustomShowcase] Notify curated showcases items updated',
  props<{
    showcaseId: string,
    listId: string,
    loadedByList: {
      entities: ListItemEntity[],
      totalResults: number,
      sortByString: ListSortByString,
    },
  }>(),
);
// </editor-fold>

export const showForm = createAction(
  '[CustomShowcase] Show create custom showcase form',
  props<{ showcase: CustomShowcaseForm }>(),
);

export const hideForm = createAction(
  '[CustomShowcase] Hide custom showcase form',
);

export const focusList = createAction(
  '[CustomShowcase] Focus list',
);

export const focusListComplete = createAction(
  '[CustomShowcase] Focus list complete',
);

export const switcherStateSet = createAction(
  '[CustomShowcase] Switcher state set',
  props<{ isAll: boolean }>(),
);

export const setSearchText = createAction(
  '[CustomShowcase] Set search text',
  props<{ searchText: string }>(),
);

export const loadSavedSearch = createAction(
  '[CustomShowcase] Get Saved Search',
  props<{ id: string }>(),
);

export const loadSavedSearchSuccess = createAction(
  '[CustomShowcase] Get Saved Search Success',
  props<ShowcaseSearchData>(),
);

export const loadSavedSearchFailure = createAction(
  '[CustomShowcase] Get Saved Search Failure',
  props<{ error: any }>(),
);

export const openEmailModal = createAction(
  '[CustomShowcase] Open email modal',
  props<{ sharedShowcase: CustomShowcase }>(),
);

export const openGenerateShowcaseModal = createAction(
  '[CustomShowcase] Open generate showcase modal',
  props<{ publishedShowcase: CustomShowcase }>(),
);

export const getShowcaseUrl = createAction(
  '[CustomShowcase] Get showcase url',
  props<{ sharedShowcase: CustomShowcase }>(),
);

export const openShowcaseEmailModal = createAction(
  '[CustomShowcase] Open showcase email modal',
  props<{ sharedShowcase: CustomShowcase }>(),
);

export const getSavedSearch = createAction(
  '[CustomShowcase] Get showcase saved search',
  props<{ id: string }>(),
);

export const getSavedSearchComplete = createAction(
  '[CustomShowcase] Get showcase saved search complete',
  props<{ response: GetSavedSearchResponse, id: string }>(),
);

export const getSavedSearchFailure = createAction(
  '[CustomShowcase] Get showcase saved search failure',
  props<{ id: string, error: GetSavedSearchError }>(),
);

export const getSavedListComplete = createAction(
  '[CustomShowcase] Get showcase saved list complete',
  props<{ id: string }>(),
);

export const copyUrl = createAction(
  '[CustomShowcase] Copy url',
  props<{ id: string }>(),
);

export const writeUrl = createAction(
  '[CustomShowcase] Write URL',
  props<{ id: string, url: string }>(),
);

export const setLastCopied = createAction(
  '[CustomShowcase] Set last copied',
  props<{ id: string }>(),
);
