<div class="logout-page d-flex justify-content-center align-items-center p-12">
  <div class="logout-page-body">
    <h1 class="logout-page-title" data-automation-id="logout-page-title" translate>logoutPageTitle</h1>
    <div class="mt-10">
      <div *ngIf="loggingOut || redirecting" aria-live="polite" role="status" class="mt-2" data-automation-id="logout-page-loading">
        <span class="spinner-border text-primary" data-automation-id="logout-page-loading-spinner">
          <span class="sr-only" translate>Loading...</span>
        </span>
        <span class="m-2" data-automation-id="logout-page-loading-message">
          {{(loggingOut ? 'loggingYouOut' : 'redirecting') | translate}}
        </span>
      </div>
      <div *ngIf="!authorized">
        <div aria-live="polite" role="status" class="p-2 m-4"
             data-automation-id="logout-page-logged-out-message" translate>
          youAreLoggedOut
        </div>
        <a [routerLink]="['/']"
           class="ins-buttons button-primary flint"
           data-automation-id="logout-page-home-link"
           translate>logoutPageTakeMeHome</a>
      </div>
    </div>
  </div>
</div>
