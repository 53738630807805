import { Injectable, Inject } from '@angular/core';

import { LocalizationConfiguration, LocalizationDateFormat } from 'app/models/localization';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { FormatWidth, getLocaleDateFormat } from '@angular/common';
import { LOCALE_ID_WRAPPER } from '../app.module';

@Injectable({ providedIn: 'root' })
export class LocalizationService {
  public currentConfiguration =
    this.configLoader.localizationConfiguration ||
    {} as LocalizationConfiguration;

  private _currentShortDateFormat: string;

  constructor(
    private configLoader: ConfigurationLoader,
    @Inject(LOCALE_ID_WRAPPER) public locale: string,
  ) {}

  public get dateFormat() {
    if (this._currentShortDateFormat) {
      return this._currentShortDateFormat;
    }

    this._currentShortDateFormat = getLocaleDateFormat(
      this.locale,
      FormatWidth.Short,
    ).replace(/[a-z]+/gi, (match) =>
      match[0] === 'y' ? match[0].repeat(4) : match,
    );

    if (this.currentConfiguration.dateFormat) {
      const datePartsOrder: { [key: string]: { [key: string]: number } } = {
        [LocalizationDateFormat.DayFirst]: {
          d: 0,
          m: 1,
          y: 2,
        },
        [LocalizationDateFormat.MonthFirst]: {
          m: 0,
          d: 1,
          y: 2,
        },
      };
      const dateSeparator = this._currentShortDateFormat.match(/[^A-Za-z]/)[0];

      this._currentShortDateFormat = this._currentShortDateFormat
        .split(dateSeparator)
        .sort(
          (first: string, second: string) =>
            datePartsOrder[this.currentConfiguration.dateFormat][
              first[0].toLowerCase()
            ] -
            datePartsOrder[this.currentConfiguration.dateFormat][
              second[0].toLowerCase()
            ],
        )
        .join(dateSeparator);
    }

    return this._currentShortDateFormat;
  }
}
