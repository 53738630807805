import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  focusAvailableCardOrEmptyResultMessage,
  resetSavedSearchUpdatedState,
  updateSavedSearch
} from '../../actions/saved-search.actions';
import { SavedSearch } from '../../models/saved-search';
import { getUpdateState, SavedSearchState } from '../../reducers/saved-search.reducer';

@Component({
  selector: 'app-edit-search-name-modal',
  templateUrl: './edit-search-name-modal.component.html',
  styleUrls: ['./edit-search-name-modal.component.scss'],
})
export class EditSearchNameModalComponent implements OnInit, OnDestroy {
  @Input() public savedSearch?: SavedSearch;
  public editSearchNameForm: UntypedFormGroup;
  public isUpdating: boolean;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly store: Store<SavedSearchState>,
    private readonly formBuilder: UntypedFormBuilder,
  ) {
    this.store.dispatch(resetSavedSearchUpdatedState());
    this.editSearchNameForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
    });
  }

  public ngOnInit(): void {
    this.name.setValue(this.savedSearch.name);
    this.subscriptions.add(
      this.store.select(getUpdateState).subscribe((updateState) => {
        if (updateState?.error) {
          switch (updateState.error.status) {
            case 409: {
              this.name.setErrors({ duplicate: 'Save search name duplicate' });
              break;
            }
            case 404: {
              this.activeModal.close();
              this.store.dispatch(focusAvailableCardOrEmptyResultMessage());
              break;
            }
            default: {
              this.editSearchNameForm.setErrors({
                unknown: { message: 'General server error', originalMessage: updateState.error.message },
              });
            }
          }
        }

        if (updateState?.updated) {
          this.activeModal.close();
        }

        this.isUpdating = updateState?.loading || false;
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onClose(): void {
    this.activeModal.dismiss();
  }

  public onSubmit(): void {
    this.name.markAsTouched();
    if (!this.editSearchNameForm.valid || this.isUpdating) {
      return;
    }

    this.store.dispatch(updateSavedSearch({
      request: {
        id: this.savedSearch.id,
        name: this.name.value,
      },
    }));
  }

  public get name(): AbstractControl {
    return this.editSearchNameForm.get('name');
  }
}
