import { createAction, props } from '@ngrx/store';
import { LocationInfo } from 'shared/models/library-info';
import { LocationWithName } from '../../models/locations';

export const loadVisibleLocations = createAction(
  '[LibraryInfo] Load visible locations',
  props<{ configurationId: string }>(),
);

export const loadVisibleLocationsSuccess = createAction(
  '[LibraryInfo] Load visible locations success',
  props<{ visibleLocations: LocationWithName[] }>(),
);

export const loadVisibleLocationsFailure = createAction(
  '[LibraryInfo] Load visible locations failure',
  props<{ errorCode: number }>(),
);

export const loadLocationInfo = createAction(
  '[LibraryInfo] Load location info',
  props<{ configurationId: string, locationId: string }>(),
);

export const loadLocationInfoSuccess = createAction(
  '[LibraryInfo] Load location info success',
  props<{ locationInfo: LocationInfo }>(),
);

export const loadLocationInfoFailure = createAction(
  '[LibraryInfo] Load location info failure',
  props<{ errorCode: number }>(),
);

export const setDefaultLibraryCode = createAction(
  '[LibraryInfo] Set library code',
  props<{ defaultLibraryCode: string }>(),
);
