<ng-container [ngSwitch]="viewState">
  <app-create-list *ngSwitchCase="viewStateEnum.CREATE_NEW_LIST"
                   (onCancel)="closeCreateList()"
                   (onCreate)="closeCreateAndSelectList($event)">
  </app-create-list>

  <app-confirm-delete-items *ngSwitchCase="viewStateEnum.CONFIRM_DELETE_ITEMS"
                            [itemsCount]="availableListItems.length"
                            (onCancel)="closeDeleteConfirmation()"
                            (onConfirm)="onConfirmDelete()">
  </app-confirm-delete-items>

  <div *ngSwitchCase="viewStateEnum.SELECT_LIST" class="select-list-container">
    <ng-container *ngIf="selectListState === selectListStateEnum.INIT || selectListState === selectListStateEnum.DONE">
      <div role="listbox"
           attr.aria-label="{{ 'selectList' | translate }}"
           [attr.aria-multiselectable]="multiselect ? 'true' : 'false'"
           data-automation-id="select-list-select">
        <perfect-scrollbar
          removeTabindexOnYScroll
          class="select-list-container-scrollbar"
          data-automation-id="select-list-container-scrollbar">
          <button *ngFor="let listPrepared of listsPrepared, let i = index; trackBy: trackByListId"
                  class="w-100 border-0 px-4 d-flex align-items-center justify-content-between listbox-list-button"
                  role="option"
                  data-automation-id="select-list-select-option-button"
                  [attr.aria-selected]="listPrepared.selected"
                  (click)="toggleList(listPrepared.list.id, i)">
            <div class="d-flex align-items-center">
              <ins-bs-checkbox *ngIf="multiselect"
                               [checked]="listPrepared.selected"
                               (click)="onCheckboxClick($event)"
                               [containerClass]="['mr-2']"></ins-bs-checkbox>
              <div *ngIf="doesSomeListHasShowcase" class="listbox-list-button-icon d-flex align-items-center mr-3">
                <fa-icon *ngIf="listPrepared.list.showcaseRef"
                         [icon]="showcaseIcon"
                         size="xs"
                         role="img"
                         aria-hidden="true"
                         [attr.aria-label]="'listSelectListShowcase' | translate"
                         [classes]="['listbox-list-button-icon-showcase', 'h-100', 'w-100', 'rounded-circle']"></fa-icon>
              </div>
              <div class="listbox-list-button-name">{{ listPrepared.list.name }}</div>
            </div>
            <span class="listbox-list-button-items-count pl-4">{{ listPrepared.list.itemsCount }}</span>
          </button>
        </perfect-scrollbar>
      </div>

      <button *ngIf="canCreateNew"
              class="d-flex align-items-center w-100 h-auto px-4 py-3 border-0 new-list-button"
              (click)="openCreateList()"
              [attr.aria-label]="'listCreateNewList' | translate"
              data-automation-id="select-list-create-new-button">
        <div *ngIf="doesSomeListHasShowcase" class="listbox-list-button-icon mr-2"></div>
        <fa-icon [icon]="plusCircleIcon" [classes]="['new-list-button-icon mr-1']"></fa-icon>
        <span aria-hidden="true" translate>listNewList</span>
      </button>

      <div *ngIf="multiselect" class="d-flex p-4 pt-2">
        <button (click)="confirmAndApplyMultiselectChanges()"
                [class.button-disabled]="!availableListItems.length"
                [attr.aria-disabled]="!availableListItems.length"
                class="ins-buttons button-primary flint px-6"
                data-automation-id="select-list-done-button"
                translate>
          listSelectListDone
        </button>
        <button (click)="cancelMultiselectChanges()"
                class="ins-buttons button-secondary flint ml-3 text-uppercase"
                data-automation-id="select-list-cancel"
                translate>
          listSelectListCancelButton
        </button>
      </div>
    </ng-container>

    <app-select-list-unavailable-items
      [unavailableItems]="unavailableListItems">
    </app-select-list-unavailable-items>

    <div aria-atomic="true" aria-live="assertive" role="alert">
      <div *ngIf="selectListState === selectListStateEnum.ERROR"
           class="error-message py-2 px-3 text-center"
           data-automation-id="select-list-error">
        <span translate>generalServerError</span>
        <button (click)="resetSelectListState()"
                data-automation-id="select-list-error-ok-button"
                class="ins-buttons button-secondary flint m-2">
          {{ 'selectListErrorOk' | translate }}
        </button>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-center" role="status" aria-live="polite">
      <div class="py-2" *ngIf="selectListState === selectListStateEnum.LOADING">
        <div class="spinner-border text-primary" data-automation-id="select-list-spinner">
          <span class="sr-only" translate>Loading...</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
