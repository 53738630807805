import { Component, Input, OnInit, } from '@angular/core';
import { select, Store, } from '@ngrx/store';
import { IInnReachConfiguration, InnReachType, ISearchData, } from 'app/models/customization';
import { MappedSearchObject } from 'search/models/search-object';
import { SearchType } from 'search/models/search-type';
import { getSearchObject, State, } from 'search/reducers/root.reducer';
import { ConfigurationLoader } from 'shared/configuration-loader';

@Component({
  selector: 'app-inn-reach-search',
  templateUrl: './inn-reach-search.component.html',
  styleUrls: ['./inn-reach-search.component.scss'],
})
export class InnReachSearchComponent implements OnInit {
  @Input() public searchData: ISearchData;
  @Input() public isFullCard: boolean;
  public innReachConfig: IInnReachConfiguration;
  public queryParams: string;
  public linkUrl: string;

  constructor(
    private readonly configLoader: ConfigurationLoader,
    private readonly store: Store<State>,
  ) { }

  public ngOnInit() {
    this.innReachConfig = this.configLoader.innReachConfig;
    this.setInnReachQueryParams();
  }

  private setInnReachQueryParams(): void {
    if (this.searchData) {
      const author = this.trimTrailingPunctuation(this.searchData.author || '');
      this.queryParams = `t:(${this.trimTrailingPunctuation(this.searchData.title)})`;
      this.queryParams += author ?
        this.innReachConfig.type === InnReachType.WebPac ?
          `and a:(${author})` : `a:(${author})` : '';
      this.setLinkUrl();
    } else {
      this.store.pipe(select(getSearchObject))
        .subscribe((res: MappedSearchObject) => {
          if (!res) return;
          switch (res.searchType) {
            case SearchType.AGENT:
              this.queryParams = `a:(${res.searchText})`;
              break;
            case SearchType.TITLE:
              this.queryParams = `t:(${res.searchText})`;
              break;
            default:
              this.queryParams = res.searchText;
              break;
          }
          this.setLinkUrl();
        });
    }
  }

  private setLinkUrl(): void {
    const url = this?.innReachConfig?.url?.replace(/\/$/, '');
    this.linkUrl = this.innReachConfig.type === InnReachType.WebPac ?
      `${url}/search/X?SEARCH=${this.queryParams}` :
      `${url}/iii/encore/search/${encodeURIComponent(`C__S${this.queryParams}__Orightresult__U`)}`;
  }

  private trimTrailingPunctuation(text: string): string {
    return text.replace(/[^a-zA-Z0-9]+$/g, '');
  }
}
