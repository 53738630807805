export interface AutoSuggest {
  term: string;
  id: string;
  type?: string;
  roles?: AutosuggestAgentRoles[];
}

export interface FastLinks {
  concept: AutoSuggest;
}

export interface OptionValuePayload {
  suggest: AutoSuggest;
  isFastLink: boolean;
  type: string;
}

export enum AutosuggestAgentRoles {
  SUBJECT = 'subject',
  OTHER = 'other',
}
