<div class="p-4">
  <div class="modal-header">
    <h2 ngbAutofocus class="modal-title" data-automation-id="custom-showcase-failed-title" translate>requestFailedTitle</h2>
    <button data-automation-id="close-dialog-btn" type="button" class="close-btn p-0"
      [attr.aria-label]="'closeDialog' | translate" (click)="activeModal.dismiss()">
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="status-section" data-automation-id="custom-showcase-failed-status-message">
      <fa-icon class="text-danger mr-1" [icon]="faExclamationCircle" size="4x"></fa-icon>
      <span class="status-message">
        {{'customShowcaseErrorMessage' | translate }}
      </span>
    </div>
  </div>
</div>

