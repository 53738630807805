<div class="item-volume-list-modal-container p-4">
  <div data-automation-id="item-volume-list-header">
    <app-modal-header
      title="{{'selectItemVolumeTitle' | translate}}"
      titleId="item-volume-list-title"
      closeId="close-item-volume-list-dialog-btn"
      (close)="onClose()">
    </app-modal-header>
  </div>
  <div class="modal-body">
    <div class="d-flex flex-grow-1 flex-row align-items-center justify-content-between w-100">
      <div class="item-volumes-search-container d-flex flex-row align-items-center justify-content-start"
           data-automation-id="item-volumes-search-bar"
           (click)="$event.stopPropagation();">
        <div class="d-flex flex-row align-items-center">
          <fa-icon [icon]="searchIcon" class="position-absolute mx-1"></fa-icon>
          <input class="w-100 px-5 py-1 m-0"
                 #itemVolumesSearchInput
                 [attr.aria-label]="'searchVolumesLabel' | translate">
        </div>
      </div>
      <app-item-volume-list-sort class="sorted-by"
                                 (onChange)="sort($event)"
                                 [sortingOptions]="sortingOptions"
                                 [value]="sortValue">
      </app-item-volume-list-sort>
    </div>
    <perfect-scrollbar [config]="{ suppressScrollX: false }">
      <table class="table table-bordered table-hover" data-automation-id="item-volume-list-table">
        <thead class="thead-light">
        <tr>
          <th class="volume-table-data w-5"></th>
          <th class="volume-table-data w-30 text-capitalize" *ngIf="hasLocations" translate>location</th>
          <th class="volume-table-data w-30 text-capitalize" translate>callNumber</th>
          <th class="volume-table-data w-15" translate>Availability</th>
          <th class="volume-table-data w-20"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let itemVolume of queriedItemsAsVolumes; trackBy: trackById; index as i">
          <td class="volume-table-data w-5"></td>
          <td *ngIf="hasLocations" class="volume-table-data w-35 align-middle"
              data-automation-id="item-volume-list-location-row" translate>
            <ng-container *ngIf="itemVolume.location">
              {{'location.' + dictionariesService.getItemByName(physicalLocations, itemVolume.location)}}
            </ng-container>
          </td>
          <td class="volume-table-data w-35 align-middle"
            data-automation-id="item-volume-list-call-number-row">{{itemVolume.callNumber}}</td>
          <td class="volume-table-data w-10 align-middle text-capitalize"
            data-automation-id="item-volume-list-status-row">{{itemVolume?.status?.toLowerCase()}}</td>
          <td class="volume-table-data w-15">
            <button translate class="ins-buttons button-secondary flint my-1 mr-2"
                    [attr.data-automation-id]="'item-volume-place-hold-btn-' + i"
                    (click)="placeHold(itemVolume)">placeHold
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </perfect-scrollbar>
  </div>
</div>
