import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { first, map, Observable } from 'rxjs';
import { WindowRefService } from '../../services/window-ref.service';

@Injectable({
  providedIn: 'root'
})
export class UnauthorizedFlowService {
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly windowRef: WindowRefService,
  ) { }

  public saveParamsToUrl(queryParams: Params): void {
    const tree = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      queryParams,
    }).toString();
    this.location.go(tree);
  }

  public getParamsFromUrl(): Params {
    return this.router.parseUrl(this.router.url).queryParams;
  }

  public clearUrlFromIncompleteRequestParams(queryParams: Params): void {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  public saveStateToLocalStorage(localStorageKey: string, items: any): void {
    this.windowRef.localStorage.setItem(localStorageKey, JSON.stringify(items));
  }

  public getStateFromLocalStorage(localStorageKey: string): any {
    return JSON.parse(this.windowRef.localStorage.getItem(localStorageKey));
  }

  public clearLocalStorageFromIncompleteRequestState(localStorageKey: string): void {
    this.windowRef.localStorage.removeItem(localStorageKey);
  }

  public getFirstNavigationAfterLoginParams(): Observable<Params> {
    return this.router.events.pipe(
      first((event) => event instanceof NavigationEnd),
      map(() => this.getParamsFromUrl()),
    );
  }
}
