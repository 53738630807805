import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule, NgbModalModule, NgbNavModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TippyModule } from '@ngneat/helipopper';
import { SharedModule } from 'common/shared.module';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule, } from 'ngx-perfect-scrollbar';
import { NotificationPreferenceService } from 'user/components/account/profile/notification-preference.service';
// eslint-disable-next-line max-len
import { ProfileFormFieldMobilePhoneMultipleComponent } from 'user/components/account/profile/profile-form-field-mobile-phone-multiple/profile-form-field-mobile-phone-multiple.component';
// eslint-disable-next-line max-len
import { ProfileFormFieldInputSelectExtendedComponent } from 'user/components/account/profile/profile-form-field-input-select-extended/profile-form-field-input-select-extended.component';
import { AccountCardCoverComponent } from 'user/components/bookshelf/components/account-card-cover/account-card-cover.component';
import { AccountComponent } from 'user/components/account/account.component';
// eslint-disable-next-line max-len
import { CheckoutCardActionButtonComponent } from 'user/components/account/checkouts/checkout-card-action-button/checkout-card-action-button.component';
import { CheckoutCardComponent } from 'user/components/account/checkouts/checkout-card/checkout-card.component';
import { CheckoutsComponent } from 'user/components/account/checkouts/checkouts.component';
import { FineCardComponent } from 'user/components/account/fines/fine-card/fine-card.component';
import { FinesComponent } from 'user/components/account/fines/fines.component';
import { HoldCardActionButtonComponent } from 'user/components/account/holds/hold-card-action-button/hold-card-action-button.component';
import { HoldCardComponent } from 'user/components/account/holds/hold-card/hold-card.component';
import { HoldsComponent } from 'user/components/account/holds/holds.component';
import { AddAnotherButtonComponent } from 'user/components/account/profile/add-another-button/add-another-button.component';
import { ContactYourLibraryLinkComponent } from 'user/components/account/profile/contact-your-library-link/contact-your-library-link.component';
import { ProfileExpirationMessageComponent } from 'user/components/account/profile/profile-expiration-message/profile-expiration-message.component';
import { ProfileFormAddressesComponent } from 'user/components/account/profile/profile-form-addresses/profile-form-addresses.component';
// eslint-disable-next-line max-len
import { ProfileFormFieldInputAddressComponent } from 'user/components/account/profile/profile-form-field-input-address/profile-form-field-input-address.component';
// eslint-disable-next-line max-len
import { ProfileFormFieldInputMultipleComponent } from 'user/components/account/profile/profile-form-field-input-multiple/profile-form-field-input-multiple.component';
// eslint-disable-next-line max-len
import { ProfileFormFieldInputSelectComponent } from 'user/components/account/profile/profile-form-field-input-select/profile-form-field-input-select.component';
import { ProfileFormFieldInputComponent } from 'user/components/account/profile/profile-form-field-input/profile-form-field-input.component';
import { ProfileFormFieldLabelComponent } from 'user/components/account/profile/profile-form-field-label/profile-form-field-label.component';
import { ProfileFormFieldComponent } from 'user/components/account/profile/profile-form-field/profile-form-field.component';
import { ProfileFormComponent } from 'user/components/account/profile/profile-form/profile-form.component';
import { ProfileComponent } from 'user/components/account/profile/profile.component';
import { RemoveButtonComponent } from 'user/components/account/profile/remove-button/remove-button.component';
import { SortButtonComponent } from 'user/components/bookshelf/components/sort-button/sort-button.component';
import { InputDisplayDirective } from 'user/components/bookshelf/directives/input-display.directive';
import { ValueDisplayDirective } from 'user/components/bookshelf/directives/value-display.directive';
import { CustomShowcaseModule } from '../../../custom-showcase/custom-showcase.module';
import { ListModule } from '../../../list/list.module';
import { SavedSearchModule } from '../../../saved-search/saved-search.module';
// eslint-disable-next-line max-len
import { CheckoutCardActionButtonContainerComponent } from '../account/checkouts/checkout-card-action-button-container/checkout-card-action-button-container.component';
import { BookshelfComponent } from './bookshelf.component';
import { ReadingHistoryModule } from 'app/reading-history/reading-history.module';
import { BulkRenewButtonComponent } from 'user/components/account/checkouts/bulk-renew/bulk-renew-button/bulk-renew-button.component';
import {
  BulkRenewProgressComponent
} from 'user/components/account/checkouts/bulk-renew/bulk-renew-progress/bulk-renew-progress.component';
import {
  BulkRenewDaysChoiceModalComponent
} from 'user/components/account/checkouts/bulk-renew/bulk-renew-days-choice-modal/bulk-renew-days-choice-modal.component';
import {
  BulkRenewProcessModalComponent
} from 'user/components/account/checkouts/bulk-renew/bulk-renew-process-modal/bulk-renew-process-modal.component';
import {
  BulkRenewResultsComponent
} from 'user/components/account/checkouts/bulk-renew/bulk-renew-results/bulk-renew-results.component';
import { PermissionsModule } from 'app/permissions/permissions.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbNavModule,
    TippyModule,
    PerfectScrollbarModule,
    SavedSearchModule,
    FeatureToggleModule,
    RouterModule,
    ListModule,
    NgbPopoverModule,
    NgbDropdownModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    CustomShowcaseModule,
    ReadingHistoryModule,
    NgbModalModule,
    PermissionsModule,
  ],
  declarations: [
    BookshelfComponent,
    AccountComponent,
    CheckoutsComponent,
    HoldsComponent,
    FinesComponent,
    ProfileComponent,
    FineCardComponent,
    CheckoutCardComponent,
    CheckoutCardActionButtonComponent,
    CheckoutCardActionButtonContainerComponent,
    HoldCardComponent,
    HoldCardActionButtonComponent,
    SortButtonComponent,
    ProfileFormComponent,
    ProfileFormAddressesComponent,
    ProfileFormFieldComponent,
    ProfileFormFieldLabelComponent,
    ProfileFormFieldInputComponent,
    ProfileFormFieldInputAddressComponent,
    ProfileFormFieldInputMultipleComponent,
    ProfileFormFieldInputSelectComponent,
    ProfileExpirationMessageComponent,
    InputDisplayDirective,
    ValueDisplayDirective,
    AddAnotherButtonComponent,
    RemoveButtonComponent,
    ContactYourLibraryLinkComponent,
    AccountCardCoverComponent,
    ProfileFormFieldInputSelectExtendedComponent,
    ProfileFormFieldMobilePhoneMultipleComponent,
    BulkRenewButtonComponent,
    BulkRenewProcessModalComponent,
    BulkRenewProgressComponent,
    BulkRenewDaysChoiceModalComponent,
    BulkRenewResultsComponent
  ],
  exports: [BookshelfComponent],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    NotificationPreferenceService
  ],
})
export class BookshelfModule {
}
