<div class="hold-card card" data-automation-id="hold-card">
  <div class="container-fluid pl-3 pr-6 py-0 py-sm-2">
    <div class="row">
      <div class="col-auto d-none d-sm-block">
        <app-account-card-cover [resource]="card.receipt.resource" (onNavigation)="onNavigation()">
        </app-account-card-cover>
      </div>
      <div class="col pr-1">
        <div class="row" *ngIf="canGetIt || isReadyForPickUp">
          <div class="col-12 pl-0 pr-0 pr-sm-3 pb-2">
            <div class="hold-card-status-banner px-0 py-2 px-3" data-automation-id="hold-card-status-bar">
              <span *ngIf="canGetIt" translate>holdReadyToBorrow</span>
              <span *ngIf="isReadyForPickUp" translate>holdReadyForPickup</span>
            </div>
          </div>
        </div>
        <div class="row py-2 py-sm-0">
          <div class="col-auto d-block d-sm-none">
            <app-account-card-cover [resource]="card.receipt.resource" (onNavigation)="onNavigation()">
            </app-account-card-cover>
          </div>
          <div class="col pl-0 mb-1">
            <h2 class="hold-card-item-title">
              <a *ngIf="frcLink; else noFrcLink" (click)="onNavigation()"
                 (keydown.enter)="onNavigation()"
                 class="activeLink link-content overflow-hidden"
                 data-automation-id="hold-card-title"
                 [queryParams]="{id: card.receipt.resource.id, entityType: card.receipt.resource.type}"
                 [routerLink]="['/search', 'card']">
                <app-cut-text-with-certain-height
                  [id]="ariaTitleId"
                  [text]="card.receipt.resource.title"
                  [height]="50"
                  [withoutMoreLess]="true"
                  class="hold-card-title"
                  minLengthToCut="60">
                </app-cut-text-with-certain-height>
              </a>
            </h2>
            <div *ngIf="card.receipt.resource" class="hold-card-title-designator" data-automation-id="hold-card-title-designator">
              {{card.receipt.resource.titleDesignator}}
            </div>
            <div *ngIf="card.receipt.resource" class="hold-card-material-type" data-automation-id="hold-card-material-type">
              {{card.receipt.resource.materialType}}
              <span *ngIf="isEcontentVendor()"> {{"fromVendor" | translate: {vendor: formattedVendor[card.receipt.vendor]} }}</span>
            </div>
          </div>
          <ng-template #noFrcLink>
            <app-cut-text-with-certain-height *ngIf="hasResourceTitle; else noResourceTitle"
                                              [id]="ariaTitleId"
                                              [text]="card.receipt.resource.title"
                                              [height]="50"
                                              [withoutMoreLess]="true"
                                              class="hold-card-title hold-card-title--no-link"
                                              data-automation-id="hold-card-title"
                                              minLengthToCut="60">
            </app-cut-text-with-certain-height>
            <ng-template #noResourceTitle>
              <div class="col pl-0">
              <span [id]="ariaTitleId"
                    class="hold-card-title hold-card-title--no-link"
                    data-automation-id="hold-card-title">
                {{'holdResourceIsNotAvailable' | translate: {itemId: card.receipt.id} }}
              </span>
              </div>
            </ng-template>
          </ng-template>
        </div>
        <div class="row">
          <div class="col-5 col-sm-6 col-lg-2 pl-sm-0">
            <div class="hold-card-info" data-automation-id="hold-card-info-place-in-line"
                 [class.success]="canGetIt || isReadyForPickUp">
              <div class="hold-card-info-title" translate>holdPlaceInLine</div>
              <div class="hold-card-info-value-bold" data-automation-id="hold-card-info-place-in-line-status">
                <span>{{card.receipt.priority}}</span>
                <span> - </span>
                <ng-container *ngIf="!card.receipt.frozen; else statusFrozen">
                  <span *ngIf="canGetIt" translate>holdReadyToBorrow</span>
                  <span *ngIf="isReadyForPickUp" translate>holdReadyForPickup</span>
                  <span *ngIf="card.receipt.status === holdStatus.InTransit" translate>holdStatusInTransit</span>
                  <span *ngIf="card.receipt.status === holdStatus.Pending" translate>holdStatusPending</span>
                  <span *ngIf="card.receipt.status === holdStatus.ItemSentByMail" translate>holdStatusSent</span>
                  <span *ngIf="card.receipt.status === holdStatus.Waiting" translate>holdStatusWaiting</span>
                </ng-container>
                <ng-template #statusFrozen>
                  <span translate>holdFrozen</span>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-2 d-none d-sm-block">
            <ng-container *ngTemplateOutlet="pickupLocation"></ng-container>
          </div>
          <div class="col-7 col-sm-6 col-lg-2 pl-sm-0">
            <div class="hold-card-info" *ngIf="isReadyForPickUp" [class.success]="isReadyForPickUp"
                 data-automation-id="hold-card-info-pickup-date">
              <div class="hold-card-info-title" translate>holdPickupDate</div>
              <div class="hold-card-info-value">{{card.receipt.expirationDate | date : 'longDate'}}</div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-2">
            <div class="hold-card-info" *ngIf="card.receipt.neededBy && !isReadyForPickUp && card.receipt.status !== holdStatus.ItemSentByMail" [class.warning]="true"
                 data-automation-id="hold-card-info-ends-on">
              <div class="hold-card-info-title" translate>holdEndsOn</div>
              <div class="hold-card-info-value">{{card.receipt.neededBy | date : 'longDate'}}</div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-2 d-block d-sm-none">
            <ng-container *ngTemplateOutlet="pickupLocation"></ng-container>
          </div>
          <div class="col-12 col-lg-4 d-flex justify-content-center justify-content-sm-end">
            <div class="hold-card-info action-button" *ngIf="hasActions">
              <app-hold-card-action-button [action]="card.action"
                                           [disabled]="card.action?.status === actionStatus.Pending"
                                           (triggerAction)="handleActionTrigger($event)"
                                           [ariaDescribedBy]="ariaTitleId">
                <ng-container *ngIf="canGetIt">
                  <span *cardAction="receiptActionType.GetIt"
                        translate
                        data-automation-id="hold-card-get-it-action">
                      holdGetIt
                  </span>
                </ng-container>
                <ng-container *ngIf="canCancelHold()">
                  <span *cardAction="receiptActionType.CancelHold"
                          translate
                          data-automation-id="hold-card-cancel-action">
                    cancelHold
                  </span>
                </ng-container>
                  <ng-container *ngIf="card.receipt.allowedActions?.includes(allowedActions.FREEZE)">
                    <span *cardAction="receiptActionType.FreezeHold"
                          translate
                          data-automation-id="hold-card-freeze-action">
                      freeze
                    </span>
                  </ng-container>
                  <ng-container *ngIf="card.receipt.allowedActions?.includes(allowedActions.UNFREEZE)">
                    <span *cardAction="receiptActionType.UnfreezeHold"
                          translate
                          data-automation-id="hold-card-unfreeze-action">
                      unfreeze
                    </span>
                  </ng-container>
              </app-hold-card-action-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #pickupLocation>
    <div class="hold-card-info" *ngIf="!card.receipt.borrowByMail" data-automation-id="hold-card-info-pickup-location">
      <button class="hold-card-info-title-clickable"
              [attr.aria-label]="'changePickupLocation' | translate"
              (click)="changePickupLocation()"
              data-automation-id="hold-card-info-change-pickup-location-button">
        <span translate>holdPickupLocation</span>
        <fa-icon
          [icon]="editIcon"
          [classes]="['ml-1', 'edit-icon']">
        </fa-icon>
      </button>
      <div class="hold-card-info-value" data-automation-id="hold-card-info-pickup-location-value">
        {{card.receipt.location | translate}}
      </div>
    </div>
  </ng-template>
</div>
