import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { faTimes, faSearch } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-bookshelf-search-bar',
  templateUrl: './bookshelf-search-bar.component.html',
  styleUrls: ['./bookshelf-search-bar.component.scss']
})
export class BookshelfSearchBarComponent {
  @Input() public placeholder: string;
  @Output() public onSearch = new EventEmitter<string>();
  public readonly searchForm: UntypedFormGroup;
  public readonly timesIcon = faTimes;
  public readonly searchIcon = faSearch;

  constructor(
    private readonly fb: UntypedFormBuilder,
  ) {
    this.searchForm = this.fb.group({
      searchText: [''],
    });
  }

  public search(): void {
    this.onSearch.emit(this.searchText.value);
  }

  public clearSearch(): void {
    this.searchText.reset('');
    this.search();
  }

  public get searchText(): AbstractControl | null {
    return this.searchForm.get('searchText');
  }
}
