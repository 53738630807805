import {
  Pipe,
  PipeTransform,
} from '@angular/core';

@Pipe({
  name: 'sortUsingCustomComparator',
})
export class SortUsingCustomComparatorPipe<T> implements PipeTransform {

  public transform(
    array: T[],
    customComparator: (first: T, second: T, sortBy?: string) => number, sortBy?: string,
  ): T[] {
    return array.slice().sort((a, b) => customComparator(a, b, sortBy));
  }
}
