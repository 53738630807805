import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, } from '@angular/core';
import { DropdownValue } from 'common/models/custom-dropdown';
import { ISortingValue } from 'user/models/item-volume';

@Component({
  selector: 'app-item-volume-list-sort',
  templateUrl: './item-volume-list-sort.component.html',
  styleUrls: ['./item-volume-list-sort.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemVolumeListSortComponent {
  @Input() public sortingOptions: ISortingValue[];
  @Input() public value: ISortingValue;
  @Output() public onChange: EventEmitter<DropdownValue<ISortingValue>> = new EventEmitter<{ selected: ISortingValue; label: string; }>();
  public expanded = false;

  public onDropdownChange(value: { selected: string; label: string; }) {
    this.value = this.sortingOptions.find((item) => item.key === value.selected);
    this.onChange.emit({selected: this.value, label: value.label});
  }
}
