import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import {
  loadMoreReadingHistoryItems,
  loadReadingHistoryItems,
  updateReadingHistoryOptIn,
} from '../../actions/reading-history.actions';
import {
  ReadingHistoryPagination,
  ReadingHistorySort,
  ReadingHistorySortByString,
  ReadingHistorySortField,
  ReadingHistorySortOrder,
} from '../../models/reading-history';
import {
  AllReadingHistoryItems,
  getReadingHistoryStatusState,
  getReadingHistoryItems,
  isLastPageLoaded,
  getReadingHistoryPagination,
  getReadingHistorySortedBy,
} from '../../reducers/reading-history.reducer';

@Component({
  selector: 'app-reading-history-view',
  templateUrl: './reading-history-view.component.html',
  styleUrls: ['./reading-history-view.component.scss'],
})
export class ReadingHistoryViewComponent implements OnInit, OnDestroy {
  public readingHistory: AllReadingHistoryItems = null;
  public allReadingHistoryItemsLoaded = false;
  public readingHistoryEnabled: boolean;
  public conflictServerError = 409;
  public pagination: ReadingHistoryPagination;
  public searchText: string;
  public sortBy: ReadingHistorySort;

  public readonly sortTranslateKeys = {
    checkoutDate: {
      asc: 'sortByCheckoutDateAscending',
      desc: 'sortByCheckoutDateDescending',
    },
    title: {
      asc: 'bookmarksSortByTitleAscending',
      desc: 'bookmarksSortByTitleDescending',
    },
    author: {
      asc: 'sortByAuthorAscending',
      desc:'sortByAuthorDescending',
    },
  };

  private readonly subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly cdr: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.store.select(getReadingHistoryStatusState)
      .pipe(distinctUntilChanged())
      .subscribe(status => {
        this.readingHistoryEnabled = status;
        if (this.readingHistoryEnabled) {
          this.store.dispatch(loadReadingHistoryItems({}));
        }
        this.cdr.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getReadingHistoryItems)
      .subscribe(readingHistory => {
        this.readingHistory = readingHistory;
        this.cdr.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getReadingHistoryPagination)
      .pipe(filter((pagination) => !!pagination))
      .subscribe(readingHistoryPagination => {
        this.pagination = readingHistoryPagination;
        this.allReadingHistoryItemsLoaded = isLastPageLoaded(readingHistoryPagination);
        this.cdr.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getReadingHistorySortedBy)
      .pipe(
        distinctUntilChanged(),
        filter((sort) => !!sort),
        map((sort) => this.transformSort(sort)),
      )
      .subscribe(sortBy => {
        this.sortBy = sortBy;
        this.cdr.markForCheck();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public loadMoreItems(): void {
    this.store.dispatch(loadMoreReadingHistoryItems());
  }

  public optInToRH(): void {
    this.store.dispatch(updateReadingHistoryOptIn());
  }

  public search(searchText: string): void {
    this.searchText = searchText;
    this.store.dispatch(loadReadingHistoryItems({searchText}));
  }

  public setSort(field: ReadingHistorySortField, order: ReadingHistorySortOrder, sortDropdown: NgbDropdown) {
    const newSort = this.joinSort(field, order);
    const oldSort = this.joinSort(this.sortBy.field, this.sortBy.order);

    if (newSort !== oldSort) {
      this.store.dispatch(loadReadingHistoryItems({sortBy: newSort, searchText: this.searchText}));
    }

    sortDropdown.close();
  }

  private transformSort(sortBy: ReadingHistorySortByString): ReadingHistorySort {
    if (!sortBy) {
      return null;
    }

    const [field, order] = sortBy.split('.');
    if ((field !== 'checkoutDate' && field !== 'title' && field !== 'author') || (order !== 'asc' && order !== 'desc')) {
      return null;
    }

    return {field, order};
  }

  private joinSort(field: ReadingHistorySortField, order: ReadingHistorySortOrder): ReadingHistorySortByString {
    return `${field}.${order}`;
  }
}
