import { Directive, Input, TemplateRef, } from '@angular/core';

@Directive({
  selector: '[cardAction]',
})
export class CardActionDirective<T = any> {
  @Input() public cardAction: T;
  @Input() public cardActionPayload: any;

  constructor(
    public template: TemplateRef<any>,
  ) {
  }
}
