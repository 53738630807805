import { ChangeDetectionStrategy, Component, OnInit, } from '@angular/core';
import { BrowserTabTitleService } from 'core/services/browser-tab-title.service';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { CustomerIntegrationType } from '../../../customer-integration/customer-integration';

@Component({
  selector: 'bc-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundPageComponent implements OnInit {
  public isNlbCustomer: boolean;

  constructor(
    private readonly titleService: BrowserTabTitleService,
    private readonly configLoader: ConfigurationLoader,
  ) {}

  public ngOnInit() {
    this.titleService.setTabTitle('404');
    this.isNlbCustomer = this.configLoader.customerIntegrationType === CustomerIntegrationType.NLB;
  }
}
