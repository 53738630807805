<ng-container *customerFeatureToggle="CustomerFeature.HoldExpirationDate">
  <div [formGroup]="holdNeededDateForm" class="hold-needed-date"
       *ngIf="ilsType === IlsType.sierra || (ilsType === IlsType.polaris && holdActivationDateSetting)">

    <!-- Expiration Date Checkbox -->
    <ins-bs-checkbox class="hold-needed-date__checkbox"
                     [checked]="holdNeededDateForm.value.hasExpirationDate"
                     [containerClass]="['my-3', 'mr-3']"
                     (change)="onChange($event.checked)"
                     [disabled]="disabled"
                     [aria-label]="(ilsType === IlsType.polaris ? 'dontNeedThisItemBefore' : 'dontNeedThisItemAfter') | translate: {itemsCount}"
                     data-automation-id="dont-need-checkbox">
    <span class="dont-need-text">
      {{ (ilsType === IlsType.polaris ? 'dontNeedThisItemBefore' : 'dontNeedThisItemAfter') | translate: {itemsCount} }}
    </span>
    </ins-bs-checkbox>

    <div class="hold-needed-date__input" *ngIf="holdNeededDateForm.value.hasExpirationDate">

      <!-- Polaris: Needed From Date -->
      <div *ngIf="ilsType === IlsType.polaris">
        <label for="needed-from-date"
               data-automation-id="needed-from-date-label"
               class="hold-needed-date__label">
          {{ 'neededFromLabel' | translate }}
        </label>
        <div class="form-group form-inline">
          <div class="input-group">
            <div class="input-group-prepend">
              <button type="button" class="btn btn-outline-flint" (click)="pickerNeededFrom.open()" attr.aria-label="{{ 'calendarNeededFrom' | translate }}"
                      data-automation-id="toggle-datepicker-from">
                <fa-icon [icon]="calendarIcon"></fa-icon>
              </button>
            </div>
            <input class="form-control" id="needed-from-date" [matDatepicker]="pickerNeededFrom" [min]="today" formControlName="neededFrom"
                   [class.is-invalid]="holdNeededDateForm.get('neededFrom')?.invalid" data-automation-id="needed-from-date" attr.aria-label="{{ 'neededFrom' | translate }}">
            <mat-datepicker #pickerNeededFrom color="accent"></mat-datepicker>
          </div>
        </div>
        <div class="hold-needed-date__input__date-format">{{ dateFormat }}</div>
      </div>

      <!-- Sierra: Needed By Date -->
      <div *ngIf="ilsType === IlsType.sierra">
        <label for="needed-by-date"
               data-automation-id="needed-by-date-label"
               class="hold-needed-date__label">
          {{ 'neededByLabel' | translate }}
        </label>
        <div class="form-group form-inline">
          <div class="input-group">
            <div class="input-group-prepend">
              <button type="button" class="btn btn-outline-flint"
                      (click)="pickerNeededBy.open()"
                      attr.aria-label="{{ 'calendarNeededBy' | translate }}"
                      data-automation-id="toggle-datepicker-by"
                      [disabled]="disabled">
                <fa-icon [icon]="calendarIcon"></fa-icon>
              </button>
            </div>
            <input formControlName="neededBy" class="form-control" id="needed-by-date"
                   [matDatepicker]="pickerNeededBy" [min]="today"
                   [class.is-invalid]="holdNeededDateForm.get('neededBy')?.invalid"
                   data-automation-id="needed-by-date"
                   attr.aria-label="{{ 'neededBy' | translate }}">
            <mat-datepicker #pickerNeededBy color="accent"></mat-datepicker>
          </div>
        </div>
        <div class="hold-needed-date__input__date-format">{{ dateFormat }}</div>
      </div>
    </div>
  </div>
</ng-container>
