export const defaultImg = './assets/resource.svg';

export const defaultImgMap = {
  Agent: './assets/person.svg',
  Concept: './assets/concept.svg',
};

export const defaultMappedAgentImg = './assets/contributor.svg';

export const agentImgMap = [
  {
    image: './assets/author.svg',
    codes: ['aft', 'ann', 'aqt', 'aud', 'aui', 'blw', 'scr', 'wac', 'wam', 'wat', 'wpr', 'wst', 'aus', 'aut', 'cmp', 'dub', 'wal', 'win'],
  },
  {
    image: './assets/editor.svg',
    codes: ['edc', 'edm', 'pfr', 'edt', 'flm'],
  },
  {
    image: './assets/translator.svg',
    codes: ['trl'],
  },
  {
    image: './assets/illustrator.svg',
    codes: ['anm', 'art', 'ard', 'bkd', 'bpd', 'clr', 'dsr', 'etr', 'ilu', 'scl', 'tyd', 'bdd', 'bjd', 'cov', 'ill'],
  },
];
