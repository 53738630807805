import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { endGuestSession, stillActive } from 'user/actions/user-profile.actions';
import { UserState } from 'user/reducers/user.reducer';

import { KeycloakService } from '../../../keycloak/services/keycloak.service';

@Component({
  selector: 'app-session-expired-dialog',
  templateUrl: './session-expired-dialog.component.html',
  styleUrls: ['./session-expired-dialog.component.scss'],
})
export class SessionExpiredDialogComponent implements OnInit, OnDestroy {
  @Input() public isAuthorized: boolean;
  public secondsLeft = 60;
  public maxFrames: number;
  public framesLeft: number;

  private framesPerSecond = 8;
  private intervalId: any;

  constructor(
    private keycloakService: KeycloakService,
    private userStore: Store<UserState>,
    private activeModal: NgbActiveModal,
    private readonly router: Router,
  ) {
    this.maxFrames = this.secondsLeft * this.framesPerSecond;
    this.framesLeft = this.maxFrames;
  }

  public ngOnInit() {
    this.runLeftTimer();
  }

  public ngOnDestroy(): void {
    this.clearLeftTimer();
  }

  public handleAction(logout: boolean) {
    this.activeModal.dismiss();
    this.clearLeftTimer();
    if (logout) {
      if (this.isAuthorized) {
        this.keycloakService.logout();
      } else {
        this.userStore.dispatch(endGuestSession());
        this.router.navigate(['/']);
      }
    } else {
      this.userStore.dispatch(stillActive());
      if (this.isAuthorized) {
        this.keycloakService.updateToken(true);
      }
    }
  }

  private runLeftTimer() {
    let intervalTick = 0;

    this.intervalId = setInterval(() => {
      intervalTick = (intervalTick + 1) % this.framesPerSecond;
      this.framesLeft--;

      if (intervalTick === 0) {
        this.secondsLeft--;
        if (!this.secondsLeft) {
          this.handleAction(true);
        }
      }
    }, 1000 / this.framesPerSecond);
  }

  private clearLeftTimer(): void {
    clearInterval(this.intervalId);
  }
}
