<div class="delete-list-modal-container p-4" data-automation-id="delete-list-modal-container">
  <div class="modal-header">
    <h2 class="modal-title title text-capitalize"
        data-automation-id="delete-list-modal-title"
        id="delete-list-modal-title"
        translate>
      listDeleteConfirmation
    </h2>
    <button (click)="onClose()"
            [attr.aria-label]="'closeDialog' | translate"
            class="close-btn p-0 m-n2"
            data-automation-id="close-delete-list-dialog-btn"
            type="button">
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
  </div>
  <div class="modal-body">
    <p data-automation-id="delete-list-caution" id="delete-list-caution">
      <ng-container *ngIf="canDeleteList; else canNotDelete">
        {{ 'listDeleteCaution' | translate }}
      </ng-container>
      <ng-template #canNotDelete>
        {{ 'listDeleteErrorCannotDeleteRelatedShowcase' | translate }}
      </ng-template>
    </p>

    <div *ngIf="list.showcaseRef && canDeleteList"
         class="delete-list-showcase-caution py-2 mt-5 mb-1"
         data-automation-id="delete-list-showcase-caution">
      <fa-icon [icon]="exclamationCircle"></fa-icon>
      {{ 'listDeleteCautionShowcaseWillBeDeleted' | translate }}
    </div>

    <div aria-live="assertive" role="alert">
      <p *ngIf="displayError"
           class="error"
           data-automation-id="delete-list-error">
        <ng-container [ngSwitch]="displayError">
          <ng-container *ngSwitchCase="errorEnum.showcase">
            {{'listDeleteErrorShowcase' | translate}}
          </ng-container>
          <ng-container *ngSwitchCase="errorEnum.showcaseRemovedButNotList">
            {{'listDeleteErrorButShowcaseRemoved' | translate}}
          </ng-container>
          <ng-container *ngSwitchCase="errorEnum.list">
            {{'listDeleteError' | translate}}
          </ng-container>
        </ng-container>
      </p>
    </div>
    <div aria-live="polite" role="status">
      <div *ngIf="pendingDelete"
           class="spinner-border text-primary"
           data-automation-id="delete-list-spinner">
        <span class="sr-only" translate>Loading...</span>
      </div>
    </div>
  </div>

  <div *ngIf="canDeleteList" class="modal-footer justify-content-start">
    <button (click)="onConfirm()"
            class="ins-buttons button-primary flint"
            data-automation-id="delete-list-modal-confirm-btn"
            type="button">
      <ng-container *ngIf="list.showcaseRef; else deleteOnlyList">
        {{'listDeleteWithShowcaseConfirmationButton' | translate}}
      </ng-container>
      <ng-template #deleteOnlyList>
        {{'listDeleteConfirmationButton' | translate}}
      </ng-template>
    </button>
    <button (click)="onClose()"
            class="ins-buttons button-secondary flint ml-3 text-uppercase"
            data-automation-id="delete-list-modal-cancel-btn"
            translate>
      listDeleteKeepButton
    </button>
  </div>
</div>
