import { Component, OnInit } from '@angular/core';
import { FeatureToggleService } from '../../../services/feature-toggle.service';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-custom-showcase-form-metadata-schedule-showcase',
  templateUrl: './custom-showcase-form-metadata-schedule-showcase.component.html',
  styleUrls: ['./custom-showcase-form-metadata-schedule-showcase.component.scss']
})
export class CustomShowcaseFormMetadataScheduleShowcaseComponent implements OnInit {
  public isShowCaseScheduleFlagEnabled = false;
  public customShowcaseForm: UntypedFormGroup;
  public today: Date = new Date();

  constructor(public readonly controlContainer: ControlContainer,
    private readonly featureToggleService: FeatureToggleService) {
  }

  public ngOnInit(): void {
    this.isShowCaseScheduleFlagEnabled = this.featureToggleService.getToggles()['DIS-31554_2024-08-30_schedule_showcase'];
    this.customShowcaseForm = this.controlContainer.control as UntypedFormGroup;
  }

  get aboutShowcase(): UntypedFormGroup {
    return this.controlContainer.control as UntypedFormGroup;
  }

  onDateChange(event: any, controlName: string): void {
    const selectedDate = event.value;
    if (selectedDate) {
      const time = '12:00:00';
      const dateTime = new Date(`${selectedDate}T${time}`);
      this.aboutShowcase.get(controlName).setValue(dateTime.toISOString());
    }
  }

  onTimeChange(event: any, controlName: string): void {
    const timeValue = event.target.value;
    const currentControlValue = this.aboutShowcase.get(controlName)?.value;

    if (currentControlValue) {
      const currentDate = new Date(currentControlValue);
      const [hours, minutes] = this.parseTime(timeValue);
      currentDate.setHours(hours, minutes);

      const localISOString = this.formatDate(currentDate);

      this.aboutShowcase.get(controlName)?.setValue(localISOString);
    }
  }

  private parseTime(timeValue: string): [number, number] {
    return timeValue.split(':').map(Number) as [number, number];
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');
    const second = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
  }
}
