import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild, } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CheckoutCardActionType } from 'user/models/checkouts';
import { HoldCardActionType } from 'user/models/holds';

@Component({
  selector: 'app-confirmation-modal-content',
  templateUrl: './confirmation-modal-content.component.html',
  styleUrls: ['./confirmation-modal-content.component.scss'],
})
export class ConfirmationModalContentComponent implements AfterViewInit {
  @Input() public context: any;
  @ViewChild('confirmationContainer', {static: true}) public confirmationContainer: ElementRef;
  @ViewChild('confirmationMessage', {static: true}) public confirmationMessage: ElementRef;
  public readonly holdActionType = HoldCardActionType;
  public readonly checkoutActionType = CheckoutCardActionType;

  public constructor(
    private cdRef: ChangeDetectorRef,
    public activeModal: NgbActiveModal,
  ) {
  }

  public get confirmationAnnounceMessage() {
    return [
      this.confirmationMessage.nativeElement.getElementsByTagName('span')[0].dataset[`${this.context.action}ActionMessage`],
      this.confirmationContainer.nativeElement.dataset['closeConfirmationDialogMessage'],
    ].join('. ');
  }

  public ngAfterViewInit() {
    this.cdRef.detectChanges();
     }
}
