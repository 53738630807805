import { Component, Input, OnInit } from '@angular/core';
import { LibraryHasConfiguration } from 'shared/models/library-has-configuration';
import { configLibraryHas } from 'shared/utils/config-library-has';

@Component({
  selector: 'app-drawer-library-has',
  templateUrl: './drawer-library-has.component.html',
  styleUrls: ['./drawer-library-has.component.scss'],
})
export class DrawerLibraryHasComponent implements OnInit {
  @Input() public displayStrings: string[];
  @Input() public supplementStrings: string[];
  @Input() public indexStrings: string[];

  public expanded: boolean;
  public showConfig: LibraryHasConfiguration;

  public ngOnInit(): void {
    this.showConfig = configLibraryHas(this.expanded, this.displayStrings, this.indexStrings, this.supplementStrings);
  }

  public toggleExpanded() {
    this.expanded = !this.expanded;
    this.showConfig = configLibraryHas(this.expanded, this.displayStrings, this.indexStrings, this.supplementStrings);
  }
}
