import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NlbItemLocation, NlbItemLocationRequest} from '../models/nlb-item-locator';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NlbItemLocatorService {
  private static readonly nlbItemLocatorUrl = 'api/search-result/nlb/item-locator';

  constructor(
    private readonly http: HttpClient,
  ) { }

  public loadItemLocation(params: NlbItemLocationRequest): Observable<NlbItemLocation> {
    return this.http.get<NlbItemLocation>(NlbItemLocatorService.nlbItemLocatorUrl, {params});
  }
}
