import { Injectable } from '@angular/core';
import { WhitelistDomains } from '../whitelist';

@Injectable({
  providedIn: 'root'
})
export class WhitelistService {

  public getSanitizedUrl(url: string): string | null {
    return this.isAllowedUrl(url) ? url : null;
  }

  public isAllowedUrl(url: string): boolean {
    try {
      const {host} = new URL(url);
      return WhitelistDomains.some((allowedDomain) => allowedDomain === host || host.endsWith(`.${allowedDomain}`));
    } catch (e) {
      return false;
    }
  }
}
