import { Injectable, } from '@angular/core';
import { FacetsSchemaService } from 'search/facets/services/facets-schema.service';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { NlbScriptService } from '../customer-integration/nlb/nlb-qr-code/services/nlb-script.service';
import { CustomerIntegrationService } from '../customer-integration/services/customer-integration.service';
import { ElementsV2Service } from '../elements-v2/service/elements-v2.service';
import { DiscoverV2BffService } from '../elements-v2/service/discover-v2-bff.service';
import { CustomDomainLoader } from './custom-domain-loader';
import { DictionariesService } from './dictionaries.service';
import { FeatureToggleService } from './feature-toggle.service';
import { I18NService } from './i18-n.service';
import { LicenseService } from './license.service';
import { loadPendo } from './pendo-snippet';
import { PendoService } from './pendo.service';

@Injectable()
export class StartupService {

  constructor(private readonly dictionariesService: DictionariesService,
              private readonly i18nService: I18NService,
              private readonly facetsSchemaService: FacetsSchemaService,
              private readonly configLoader: ConfigurationLoader,
              private readonly licenseService: LicenseService,
              private readonly featureToggleService: FeatureToggleService,
              private readonly pendoService: PendoService,
              private readonly elementsV2Service: ElementsV2Service,
              private readonly customDomainLoader: CustomDomainLoader,
              private readonly discoverV2BffService: DiscoverV2BffService,
              private readonly nlbScriptsService: NlbScriptService,
              private readonly customerIntegrationService: CustomerIntegrationService,
  ) {
  }

  public async load(): Promise<any[]> {
    await this.customDomainLoader.loadDomain();
    return Promise.all([
      this.dictionariesService.loadAllDictionaries(),
      this.facetsSchemaService.loadFacetSchemas(),
      this.licenseService.loadToggles()
        .then(({licenses, customerFeatures}) => {
          this.featureToggleService.initToggles(licenses, customerFeatures);
          this.discoverV2BffService.getPageData();
          this.elementsV2Service.loadScripts();
          if (!this.licenseService.errorStatus) {
            loadPendo();
            this.pendoService.startGuestSession();
          }
        })
        .catch(() => this.featureToggleService.initToggles([], [])),
      this.configLoader.loadConfiguration()
        .then(() => {
          this.nlbScriptsService.loadNlbScripts();
          this.customerIntegrationService.getCustomerIntegrationLinks(this.configLoader.customerIntegrationType);
        }),
      this.i18nService.init(),
    ]);
  }
}
