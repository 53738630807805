import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'common/shared.module';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { NlbQrCodeComponent } from './components/nlb-qr-code/nlb-qr-code.component';

@NgModule({
  declarations: [
    NlbQrCodeComponent,
  ],
  exports: [
    NlbQrCodeComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FeatureToggleModule,
  ],
  providers: [],
})
export class NlbQrCodeModule {
}
