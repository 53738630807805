<div class="bubble-container" data-automation-id="search-facets-bubble">
  <div class="bubble">
    <button *ngIf="!isReadonly" class="bubble-button" (click)="onToggleBubbleLock()"
      data-automation-id="search-facets-bubble-lock-btn" [attr.aria-label]="'Lock ' + facetLabel + ' facet'">
      <ng-container *ngIf="facet?.isResetLocked; else unlockedIcon">
        <span class="icon-lock" data-automation-id="search-facets-bubble-lock-icon"></span>
      </ng-container>
      <ng-template #unlockedIcon>
        <span class="icon-lock-open" data-automation-id="search-facets-bubble-unlock-icon"></span>
      </ng-template>
    </button>
    <span class="bubble-label px-2" data-automation-id="search-facets-bubble-label" [ngSwitch]="facet.id">
      <ng-container *ngSwitchCase="'at_library'">{{ 'at_library' | translate }}</ng-container>
      <ng-container *ngSwitchCase="'online'">{{ 'online' | translate }}</ng-container>
      <ng-container *ngSwitchDefault>{{facetLabel}}</ng-container>
    </span>
    <button *ngIf="!isReadonly" class="bubble-button ml-1" (click)="onRemoveFacetBubble()"
      data-automation-id="search-facets-bubble-remove-btn" [attr.aria-label]="'Remove ' + facetLabel + ' facet'">
      <span class="icon-close"></span>
    </button>
  </div>
</div>
