import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReadingHistoryDto, ReadingHistoryPaginationParams, ReadingHistorySortByString, ReadingHistoryStatus } from '../models/reading-history';

@Injectable()
export class ReadingHistoryService {
  private static readonly readingHistoryEndpoint = 'api/search-result/patrons/me/checkouts/history';
  private static readonly readingHistoryItemsEndpoint = 'api/search-result/bookshelf/reading-history';

  constructor(private readonly http: HttpClient) {
  }

  public getStatus(): Observable<ReadingHistoryStatus> {
    return this.http.get<ReadingHistoryStatus>(`${ReadingHistoryService.readingHistoryEndpoint}/activation-status`);
  }

  public updateReadingHistoryOption(readingHistoryActivation: ReadingHistoryStatus): Observable<ReadingHistoryStatus> {
    return this.http.post<ReadingHistoryStatus>(`${ReadingHistoryService.readingHistoryEndpoint}/activation-status`, readingHistoryActivation);
  }

  public readingHistorySynchronization() {
    return this.http.post(`${ReadingHistoryService.readingHistoryEndpoint}/sync`, {});
  }

  public getReadingHistoryItems(sortBy: ReadingHistorySortByString, paginationParams: ReadingHistoryPaginationParams,
                                searchText?: string): Observable<ReadingHistoryDto> {
    const params: Record<string, string> = {
      sortBy,
      pageNum: paginationParams.pageNum.toString(),
      pageSize: paginationParams.pageSize.toString(),
    };
    if (searchText) {
      params.searchText = searchText;
    }
    return this.http.get<ReadingHistoryDto>(ReadingHistoryService.readingHistoryItemsEndpoint, {params});
  }
}
