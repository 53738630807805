import { Directive, TemplateRef, } from '@angular/core';

@Directive({
  selector: '[cardStatus]',
})
export class CardStatusDirective {
  constructor(
    public template: TemplateRef<any>,
  ) {
  }
}
