import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, first, merge, Observable, of, switchMap, tap, withLatestFrom, zip } from 'rxjs';
import { UnauthorizedFlowService } from 'shared/service/unauthorized-flow.service';
import { bulkHoldSelectedItems, bulkHoldStartUnauthorizedFlow, bulkHoldUnauthorizedFlowFailure } from 'user/actions/bulk-hold.actions';
import { loadCheckoutsSuccess, loadHoldsSuccess, toggleOpenedUserPanel } from 'user/actions/user-profile.actions';
import { bulkSelectAllItems, tryToBulkHoldSelectedItems } from '../../bulk-select/actions/bulk-select.actions';
import { setEntities } from '../../entity/actions/entity.actions';
import { isAuthorizedInKeycloak } from '../../keycloak/reducers/keycloak.reducer';
import { LOCAL_STORAGE } from '../../models/local-storage';

@Injectable()
export class BulkHoldUnauthorizedFlowEffects {

  public tryToBulkHoldSelectedItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(tryToBulkHoldSelectedItems),
      withLatestFrom(this.store.select(isAuthorizedInKeycloak)),
      switchMap(([{bulkFeatureKey, items}, isPatronAuthorized]) => {
        return isPatronAuthorized
          ? of(bulkHoldSelectedItems({bulkFeatureKey, items}))
          : of(bulkHoldStartUnauthorizedFlow({bulkFeatureKey, items}));
      }),
    );
  });

  public bulkHoldStartUnauthorizedFlow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(bulkHoldStartUnauthorizedFlow),
      switchMap(({bulkFeatureKey, items}) => {
        this.unauthorizedFlowService.saveParamsToUrl({bulkHold: bulkFeatureKey});
        this.unauthorizedFlowService.saveStateToLocalStorage(LOCAL_STORAGE.BULK_HOLD, items);
        return of(toggleOpenedUserPanel({isOpened: true}));
      }),
    );
  });

  public hasBulkHoldParamsAfterLogin(): Observable<Params> {
    return this.unauthorizedFlowService.getFirstNavigationAfterLoginParams()
    .pipe(filter(({bulkHold}) => !!bulkHold));
  };

  public returnAuthenticatedAndResumeBulkHold$ = createEffect(() =>
    zip(
      this.hasBulkHoldParamsAfterLogin(),
      this.actions$.pipe(ofType(setEntities), first()),
      this.actions$.pipe(ofType(loadHoldsSuccess), first()),
    ).pipe(
      switchMap(([{bulkHold: bulkFeatureKey}]) => {
        const items = this.unauthorizedFlowService.getStateFromLocalStorage(LOCAL_STORAGE.BULK_HOLD);
        return [
          bulkSelectAllItems({bulkFeatureKey, items}),
          bulkHoldSelectedItems({bulkFeatureKey, items}),
        ];
      }),
      catchError(() => of(bulkHoldUnauthorizedFlowFailure())),
    ));

  public clearIncompleteBulkHoldRequest$ = createEffect(() => merge(
    this.actions$.pipe(ofType(bulkHoldSelectedItems)),
    this.actions$.pipe(ofType(bulkHoldUnauthorizedFlowFailure)),
  ).pipe(
    filter(() => this.unauthorizedFlowService.getParamsFromUrl().bulkHold),
    tap(() => {
      this.unauthorizedFlowService.clearUrlFromIncompleteRequestParams({bulkHold: null});
      this.unauthorizedFlowService.clearLocalStorageFromIncompleteRequestState(LOCAL_STORAGE.BULK_HOLD);
    }),
  ), {dispatch: false});

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly unauthorizedFlowService: UnauthorizedFlowService,
  ) {
  }
}
