<div *ngIf="showConfig.showIssuesCount > 0" class="mb-4">
  <div class="rollup-tabs-content-card-col-mw-fixed mb-2" data-automation-id="library-has-label">
    {{'libraryHas' | translate}}
  </div>
  <div class="w-100 d-lg-none" data-automation-id="library-has-spacer"></div>
  <div data-automation-id="library-has">
    <p *ngFor="let issue of displayStrings; let i = index;" class="mb-0">
      <ng-container class="mb-1" *ngIf="i < showConfig.showIssuesCount">
        {{issue}}
      </ng-container>
    </p>
  </div>
</div>
<div *ngIf="showConfig.showIndexesCount > 0" class="mb-4">
  <div class="rollup-tabs-content-card-col-mw-fixed mb-2" data-automation-id="drawer-index-label">
    {{'indexes' | translate}}
  </div>
  <div class="w-100 d-lg-none" data-automation-id="drawer-index-spacer"></div>
  <div data-automation-id="drawer-index-strings">
    <p *ngFor="let index of indexStrings; let i = index;" class="mb-0">
      <ng-container class="mb-1" *ngIf="i < showConfig.showIndexesCount">
        {{index}}
      </ng-container>
    </p>
  </div>
</div>
<div *ngIf="showConfig.showSupplementsCount > 0" class="mb-4">
  <div class="rollup-tabs-content-card-col-mw-fixed mb-2" data-automation-id="drawer-supplement-label">
    {{'supplements' | translate}}
  </div>
  <div class="w-100 d-lg-none" data-automation-id="drawer-supplement-spacer"></div>
  <div data-automation-id="drawer-supplement-strings">
    <p *ngFor="let index of supplementStrings; let i = index;" class="mb-0">
      <ng-container class="mb-1" *ngIf="i < showConfig.showSupplementsCount">
        {{index}}
      </ng-container>
    </p>
  </div>
</div>
<div>
  <div class="rollup-tabs-content-card-col-mw-fixed mb-2"></div>
  <div class="w-100 d-lg-none"></div>
  <div data-automation-id="show-more">
    <p *ngIf="!expanded && showConfig.showMore" class="mt-1">
      <a class="holdings-details-link holdings-details-link-md"
         (click)="$event.preventDefault(); toggleExpanded()"
         data-automation-id="drawer-more-holdings-details"
         tabindex="0"
         href="">{{'moreHoldingsDetails' | translate}}
        <img src="../../../../assets/icon-dropdown-down.png" height="16" width="16" aria-hidden="true"/>
      </a>
    </p>
    <p *ngIf="expanded && showConfig.showMore" class="mt-1">
      <a class="holdings-details-link"
         (click)="$event.preventDefault(); toggleExpanded()"
         data-automation-id="drawer-fewer-holdings-details"
         tabindex="1"
         href="">{{'fewerHoldingsDetails' | translate}}
        <img src="../../../../assets/icon-dropdown-up.png" height="16" width="16" aria-hidden="true"/>
      </a>
    </p>
  </div>
</div>
