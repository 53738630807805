<div class="custom-showcase-status d-inline-flex px-0 px-sm-2">
  <ng-container *ngIf="published; else draft">
    <div class="custom-showcase-status-published d-inline-flex" data-automation-id="custom-showcase-publish-status-published">
      <span class="fas fa-check check-icon mr-2 p-1" aria-hidden="true"></span>
      <div class="d-flex flex-column">
        <div>{{ 'customShowcasePublished' | translate }}</div>
        <div class="custom-showcase-status-publish-date text-nowrap">
          {{ publishDate | date : 'mediumDate' }}
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #draft>
    <div class="custom-showcase-status-draft" data-automation-id="custom-showcase-publish-status-draft">
      {{ 'customShowcaseDraft' | translate }}
    </div>
  </ng-template>
</div>
