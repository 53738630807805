import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { WhitelistService } from '../../../customer-integration/services/whitelist.service';
import { isAuthorizedInKeycloak, isAuthorizingInKeycloak, isStaffAuthorizedInKeycloak } from '../../../keycloak/reducers/keycloak.reducer';
import { KeycloakService } from '../../../keycloak/services/keycloak.service';
import { State } from '../../../reducers';
import { WindowRefService } from '../../../services/window-ref.service';

@Component({
  selector: 'app-logout-page',
  templateUrl: './logout-page.component.html',
  styleUrls: ['./logout-page.component.scss']
})
export class LogoutPageComponent implements OnInit, OnDestroy {
  public authorized: boolean;
  public loggingOut = false;
  public redirecting = false;
  private redirectUri: string;
  private readonly redirectUriKey = 'redirect_uri';
  private readonly subscriptions = new Subscription();

  constructor(
    private readonly store: Store<State>,
    private readonly route: ActivatedRoute,
    private readonly keycloakService: KeycloakService,
    private readonly windowRefService: WindowRefService,
    private readonly whitelistService: WhitelistService,
  ) {
  }

  public ngOnInit(): void {
    this.subscriptions.add(this.route.queryParamMap
    .subscribe((params) => {
      this.redirectUri = params.get(this.redirectUriKey);
    }));

    this.subscriptions.add(combineLatest([
      this.store.select(isAuthorizingInKeycloak),
      this.store.select(isAuthorizedInKeycloak),
      this.store.select(isStaffAuthorizedInKeycloak),
    ])
    .pipe(filter(([isAuthorizing]) => !isAuthorizing))
    .subscribe(([_, isPatronAuthorized, isStaffAuthorized]) => {
      this.authorized = isPatronAuthorized || isStaffAuthorized;
      const sanitizedUri = this.whitelistService.getSanitizedUrl(this.redirectUri);
      if (this.authorized) {
        this.logoutAndRedirect(sanitizedUri);
      } else {
        this.redirect(sanitizedUri);
      }
    }));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private logoutAndRedirect(redirectUri?: string): void {
    this.loggingOut = true;
    this.keycloakService.logout(redirectUri);
  }

  private redirect(redirectUri?: string): void {
    if (redirectUri) {
      this.redirecting = true;
      this.windowRefService.nativeWindow().location.href = redirectUri;
    }
  }
}
