import { Injectable } from '@angular/core';
import { CustomDomainLoader } from '../../services/custom-domain-loader';

@Injectable()
export class ShowcaseGenerationService {

  private readonly showcaseScriptTemplate = `
    <div class="iframe-wrapper" style="display: flex; min-width: 300px; justify-content: center;">
      <iframe id="iframeId" style="border: none; display: block; height: 450px; width: 100%"
        title="{title}"
        srcdoc="&#60;vega-showcase-widget
        showcase-id=&#34;{showcaseId}&#34;
        vega-host=&#34;{siteCode}&#34;
        display-amount=&#34;{displayAmount}&#34;
        max-amount=&#34;{totalAmount}&#34;
        description=&#34;&#34;&#62;
        &#60;/vega-showcase-widget&#62;
        &#60;script type=&#34;text/javascript&#34; defer src=&#34;{pathToScript}&#34;&#62;&#60;/script&#62;">
      </iframe>
    </div>`;

  constructor(
    private readonly customDomainLoader: CustomDomainLoader,
  ) {
  }

  generateShowcaseScript(
    showcaseId: string,
    displayAmount: string,
    totalAmount: string,
    pathToScript: string,
    title: string
    ): string {
    const siteCode = this.customDomainLoader.domain;
    const mergedValues: { [key: string]: string } = {
      showcaseId,
      displayAmount,
      totalAmount,
      siteCode,
      pathToScript,
      title
    };

    return this.showcaseScriptTemplate.replace(/{([^{}]+)}/g, (_, key) => {
      return mergedValues[key];
    });
  }

}
