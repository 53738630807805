import { HostBinding, Component, Input } from '@angular/core';
import { PassThroughItemI } from '../../../../models/customization';

@Component({
  selector: 'app-custom-pass-through-item',
  templateUrl: './custom-pass-through-item.component.html',
  styleUrls: ['./custom-pass-through-item.component.scss']
})
export class CustomPassThroughItemComponent {
  @Input() public item: PassThroughItemI;
  @Input() public fixedWidth: boolean;
  @Input() public vertical: boolean = false;

  @HostBinding('class.fixed-width') get isFixWidth() {
    return this.fixedWidth;
  }

  @HostBinding('class.vertical') get isHorizontal() {
    return this.vertical;
  }
}
