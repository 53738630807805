import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EntityTypes } from '../../../entity/models/entity';
import {
  CitationExportContentComponent
} from '../../../citation/components/citation-modal-window/citation-modal-window.component';
import { EditionData } from '../../../citation/models/editions';
import { EmailPopupComponent } from '../../../share-it/components/email-popup/email-popup.component';
import { ShareItResource } from '../../../share-it/models/share-it';
import { faQuoteRight } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-entity-options-button',
  templateUrl: './entity-options-button.component.html',
  styleUrls: ['./entity-options-button.component.scss'],
})
export class EntityOptionsButtonComponent implements OnInit {
  @Input() public entityType: EntityTypes;
  @Input() public resourceData: ShareItResource;
  @Input() public editionsData: EditionData[];
  @Input() public ariaDescribedBy: string;
  @Input() public smallButton = false;
  @Input() public disabled: boolean;
  @ViewChild('optionsMenuToggleButton') public optionsMenuToggleButton: ElementRef;

  public isCitationVisible = false;
  public quoteIcon = faQuoteRight;

  constructor(
    private modal: NgbModal
  ) {
  }

  public ngOnInit(): void {
    this.isCitationVisible = this.editionsData && [EntityTypes.FORMAT_GROUP, EntityTypes.INSTANCE].includes(this.entityType);
  }

  public onEmailAction() {
    const modalRef = this.modal.open(EmailPopupComponent, {
      windowClass: 'inspire-custom-modal',
      ariaDescribedBy: this.ariaDescribedBy,
    });

    modalRef.componentInstance.resourceData = this.resourceData;
    modalRef.result.finally(() => {
      this.optionsMenuToggleButton?.nativeElement.focus();
    });
  }

  public onCitationAction() {
    const modalRef = this.modal.open(CitationExportContentComponent, {
      windowClass: 'inspire-custom-modal',
      ariaDescribedBy: this.ariaDescribedBy,
    });

    modalRef.componentInstance.editionsData = this.editionsData;
    modalRef.result.finally(() => {
      this.optionsMenuToggleButton?.nativeElement.focus();
    });
  }
}
