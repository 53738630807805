<div class="modal-header" data-automation-id="available-links-modal-window">
  <h2 class="modal-title m-0" translate tabindex="-1" cdkFocusInitial>
    availableLinks
  </h2>
  <button type="button"
          class="close-btn p-0"
          (click)="onClose()"
          aria-label="Close dialog"
          data-automation-id="close-get-it-dialog-btn">
    <span aria-hidden="true" class="icon icon-close"></span>
  </button>
</div>
<div class="modal-body">
  <perfect-scrollbar style="max-height: 200px" removeTabindexOnYScroll>
    <div role="list">
      <div
        class="get-it-container"
        data-automation-id="get-it-container"
        role="listitem"
        *ngFor="let locator of context.urls;">
        <a
          class="get-it-link"
          data-automation-id="get-it-link"
          target="_blank"
          href="{{ locator }}"
        >
          {{ locator }}
        </a>
      </div>
    </div>
  </perfect-scrollbar>
</div>
<div class="modal-footer justify-content-start">
  <button
    (click)="onClose()"
    class="ins-buttons button-secondary flint ml-3"
    data-automation-id="cancel-modal-btn"
    translate>
    cancel
  </button>
</div>
