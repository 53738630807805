import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { NlbHoldEffects } from './effects/nlb-hold.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([
      NlbHoldEffects,
    ]),
  ],
})
export class NlbHoldModule {
}
