import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[domChange]'
})
export class DomChangeDirective implements OnDestroy {
  @Output() public domChange = new EventEmitter<MutationRecord>();
  public changes: MutationObserver;

  constructor(private readonly elementRef: ElementRef) {
    const element = this.elementRef.nativeElement;
    const config = {attributes: true, childList: true, subtree: true};

    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
        mutations.forEach((mutation: MutationRecord) => this.domChange.emit(mutation));
      }
    );

    this.changes.observe(element, config);
  }

  public ngOnDestroy(): void {
    this.changes.disconnect();
  }
}
