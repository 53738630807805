<ng-container [formGroup]="controlContainer.control">
  <input [attr.aria-invalid]="control.invalid && control.touched"
         [class.is-invalid]="control.invalid && control.touched"
         [formControl]="control"
         [id]="formControlLabel"
         [autocomplete]="autocompleteValue"
         class="form-control profile-card-info-value-input mb-0"
         data-automation-id="profile-card-info-value-input"
         [attr.aria-describedby]="errorId"
         #inputFieldElement
         type="text"/>
  <div aria-atomic="true"
       aria-live="assertive"
       role="alert"
       [id]="errorId"
       data-automation-id="profile-card-info-value-input-error">
    <div *ngIf="control.invalid && control.touched"
         class="invalid-feedback d-block"
         data-automation-id="profile-card-info-value-input-error-message">
      <ng-container *ngIf="control.errors.notBlank">
        {{ 'profileFieldErrorRequired' | translate: {label: formControlLabel ?? ''} }}
      </ng-container>
      <ng-container *ngIf="control.errors.email">
        {{ 'profileFieldErrorEmail' | translate }}
      </ng-container>
      <ng-container *ngIf="control.errors.phoneNumber">
        {{ 'profileFieldErrorPhoneNumber' | translate: {label: formControlLabel ?? ''} }}
      </ng-container>
      <ng-container *ngIf="control.errors.maxlength">
        {{ 'profileFieldErrorMaxLength' | translate: {label: formControlLabel ?? '', maxCharactersNumber: control.errors.maxlength.requiredLength} }}
      </ng-container>
    </div>
  </div>
</ng-container>
