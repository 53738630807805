import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as Immutable from 'immutable';
import {
  addToList,
  clear,
  removeFromList,
  removeSelectedFromListComplete,
  setAllItemsSelection,
  toggleItemSelection
} from '../actions/for-later-list.actions';
import { List, ListItem, ListItemEntity, ListType } from '../models/list';
import {
  clearItems,
  ListRecord,
  ListRecordFactory, ListState,
  makeListItem,
  removeFromItems,
  removeSelectedItems,
  setSelectedAllItems,
  toggleItemSelected,
  updateListItemIfFound
} from './list.reducer';

export type ForLaterListState = ListRecord;

export const initialState: ForLaterListState = ListRecordFactory({
  id: 'for_later',
  name: 'For Later',
  items: Immutable.List(),
  type: ListType.forLater,
});

export const backupStateToJsonString = (state: ForLaterListState): string => {
  return JSON.stringify({items: state.get('items').toJS()});
};

export const restoreStateFromJsonString = (json: string): ForLaterListState => {
  const parsedJson = JSON.parse(json);
  if (parsedJson) {
    const {items: jsItems} = parsedJson;
    const items = Immutable.List(jsItems as ListItem[]);
    return initialState.set('items', items);
  }

  return null;
};

export const mergeStates = (original: ListRecord, patch: ListRecord) => {
  return original.merge(patch);
};

const forLaterListReducer = createReducer(
  initialState,
  on(addToList, (state: ListRecord, {entity}): ForLaterListState => (
    state.update('items', (items) => items.push(makeListItem(entity)))
  )),
  on(removeFromList, (state: ListRecord, {entity}) => (
    state.update('items', (items) => removeFromItems(items, entity))
  )),
  on(removeSelectedFromListComplete, (state) => (
    state.update('items', removeSelectedItems)
  )),
  on(toggleItemSelection, (state, {itemId}) => (
    updateListItemIfFound(state, itemId, toggleItemSelected)
  )),
  on(setAllItemsSelection, (state, {selected}) => (
    state.update('items', (items) => setSelectedAllItems(items, selected))
  )),
  on(clear, (state) => (
    state.update('items', (items) => clearItems(items))
  )),
);

export function reducer(state: ForLaterListState | undefined, action: Action) {
  return forLaterListReducer(state, action);
}

export const forLaterListFeatureKey = 'forLaterList';

export const getForLaterListState = createFeatureSelector<ForLaterListState>(forLaterListFeatureKey);

export const getForLaterList = createSelector(getForLaterListState, (listState: ForLaterListState): List => {
  return listState ? listState.toJS() as List : null;
});

export const getForLaterListItems = createSelector(getForLaterListState, (listState: ForLaterListState): ListItem[] => {
  return listState ? listState.get('items').toJS() as ListItem[] : null;
});

export const getSelectedListItemEntities = createSelector(
  getForLaterListState,
  (list: ForLaterListState): ListItemEntity[] | null => {
    return (list)
      ? list.get('items').filter((item) => item.selected).map((item) => item.entity).toJS() as ListItemEntity[]
      : null;
  },
);

export const getForLaterListItemSearchResults = createSelector(getForLaterListItems, (items: ListItem[]): ListItemEntity[] => (
  items ? items.map((item) => item.entity) : []
));
