import { HostBinding, Component, Input, OnInit } from '@angular/core';
import { agentImgMap, defaultImgMap, defaultMappedAgentImg } from 'core/utils/thumbnails-mapper';
import { EntityTypes } from '../../../entity/models/entity';
import { Cover } from '../../../models/cover';
import { I18NService } from '../../../services/i18-n.service';
import {BookCover} from 'search/models/search-results';

type CoverSize = 'small' | 'medium' | 'large';

@Component({
  selector: 'app-cover',
  templateUrl: './cover.component.html',
  styleUrls: ['./cover.component.scss']
})
export class CoverComponent implements OnInit {
  @Input() public config: Cover | BookCover;
  @Input() public size: CoverSize = 'small';
  @Input() public author: string;
  @Input() public title: string;
  @Input() public materialType: string;
  public url: string;

  constructor(
    private readonly i18nService: I18NService,
  ) {}

  public ngOnInit() {
    this.url = this.config?.coverUrl?.[this.size] || this.getDefaultImageUrl();
  }

  @HostBinding('class.app-cover-container')

  public handleImageError(): void {
    this.url = this.getDefaultImageUrl();
  }

  public handleImageLoad(event: Event): void {
    const {naturalHeight, naturalWidth} = event.currentTarget as HTMLImageElement;

    if (naturalHeight === 1 || naturalWidth === 1) {
      this.url = this.getDefaultImageUrl();
    }
  }

  private getDefaultImageUrl(): string {
    if (this.config?.type === EntityTypes.AGENT) {
      return this.getAgentDefaultImg(this.config);
    }
    return (defaultImgMap as any)[this.config?.type] || '';
  }

  private getAgentDefaultImg(cover: Cover): string {
    const availableSources: string[] = [];
    if (cover.agentRoles && cover.agentRoles.length) {
      agentImgMap.forEach((imgType) => {
        cover.agentRoles.forEach((role) => {
          if (imgType.codes.includes(role)) {
            availableSources.push(imgType.image);
          }
        });
      });
    }
    if (cover.agentRoles && cover.agentRoles.length && !availableSources.length) {
      const roleDescription = cover.agentRoles.find((roleCode) => !!this.i18nService.getContributorRole(roleCode, true));
      return roleDescription ? defaultMappedAgentImg : defaultImgMap.Agent;
    }
    return availableSources[0] || defaultImgMap.Agent;
  }
}
