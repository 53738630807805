import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
// eslint-disable-next-line max-len
import { ProfileFormFieldInputMultipleComponent } from 'user/components/account/profile/profile-form-field-input-multiple/profile-form-field-input-multiple.component';
import { SelectValue } from 'user/components/account/profile/profile-form-field-input-select/profile-form-field-input-select.component';
import { Carrier } from 'user/models/user';

@Component({
  selector: 'app-profile-form-field-mobile-phone-multiple',
  templateUrl: './profile-form-field-mobile-phone-multiple.component.html',
  styleUrls: ['profile-form-field-mobile-phone-multiple.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ProfileFormFieldInputMultipleComponent,
    multi: true,
  }],
})
export class ProfileFormFieldMobilePhoneMultipleComponent extends ProfileFormFieldInputMultipleComponent implements OnInit {
  @Input() carriers: Carrier[] = [];

  public carrierValues: SelectValue[];

  constructor(public readonly controlContainer: ControlContainer) {
    super(controlContainer);
  }

  ngOnInit() {
    this.carrierValues = this.carriers.map((carrier) => ({
      id: carrier.carrierId + '',
      value: carrier.carrierName,
    }));
  }

  getControl(index: number, controlName: string): UntypedFormControl {
    return (this.control.controls[index] as UntypedFormGroup).controls[controlName] as UntypedFormControl;
  }

  onChange(index: number) {
    const controls = (this.control.controls[index] as UntypedFormGroup).controls;
    controls['phone']?.markAsTouched();
    controls['carrierId']?.markAsTouched();
  }

  public setFocusOnInputField() {
    setTimeout(() => {
      this.inputList.last.setFocusOnInputField();
    });
  }
}
