import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { KeycloakEffect } from './effects/keycloak.effect';
import * as fromKeycloak from './reducers/keycloak.reducer';
import { AuthInterceptor } from './services/auth.interceptor';
import { AuthorizedGuard } from './services/authorized.guard';
import { KeycloakService } from './services/keycloak.service';

export function keycloakServiceFactory(keycloakService: KeycloakService) {
  return () => keycloakService.init();
}

@NgModule( {
  imports: [
    StoreModule.forFeature('keycloak', fromKeycloak.reducer),
    EffectsModule.forFeature([KeycloakEffect]),
  ],
  providers: [
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: keycloakServiceFactory,
      deps: [KeycloakService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    AuthorizedGuard,
  ],

})
export class KeyCloakModule {
}
