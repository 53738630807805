import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { UserPermission } from 'user/models/user';
import { doesUserHavePermission } from 'user/reducers/user.reducer';
import { deleteList, focusAvailableListCard, resetListDeleteState } from '../../actions/list.actions';
import { List } from '../../models/list';
import { getDeleteState, ListState } from '../../reducers/list.reducer';

enum DeleteListError {
  showcase = 1,
  list,
  showcaseRemovedButNotList,
}

@Component({
  selector: 'app-delete-list-modal',
  templateUrl: './delete-list-modal.component.html',
  styleUrls: ['./delete-list-modal.component.scss'],
})
export class DeleteListModalComponent implements OnInit, OnDestroy {
  @Input() public list: List;

  public pendingDelete: boolean;
  public displayError: DeleteListError | null = null;
  public canDeleteList: boolean;
  public readonly errorEnum = DeleteListError
  public readonly exclamationCircle = faExclamationCircle;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly store: Store<ListState>,
  ) {
    this.store.dispatch(resetListDeleteState());
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.store.select(getDeleteState).subscribe((deleteState) => {
        if (deleteState.deleted || deleteState.error?.list?.status === 404) {
          this.activeModal.close();
          this.store.dispatch(resetListDeleteState());
          this.store.dispatch(focusAvailableListCard());
          return;
        }

        if (deleteState.error) {
          this.displayError = (deleteState.error.showcase)
            ? DeleteListError.showcase
            : (
              (deleteState.error.showcase === false)
                ? DeleteListError.showcaseRemovedButNotList
                : DeleteListError.list
            );
        }

        this.pendingDelete = deleteState.loading;
      }),
    );

    this.subscriptions.add(
      this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_PERSONAL_DELETE))
      .subscribe((canDeletePersonal) => {
        this.canDeleteList = !this.list.showcaseRef || canDeletePersonal;
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onClose(): void {
    this.activeModal.dismiss();
  }

  public onConfirm() {
    this.displayError = null;
    if (this.pendingDelete) {
      return;
    }

    this.store.dispatch(deleteList({
      id: this.list.id,
    }));
  }
}
