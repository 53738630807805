import { Component, Input, OnInit } from '@angular/core';
import { OverDriveFormat } from 'core/over-drive/models/over-drive-formats';
import { WindowRefService } from 'app/services/window-ref.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-over-drive-get-it-successful-dialog',
  templateUrl: './over-drive-get-it-successful-dialog.component.html',
  styleUrls: ['./over-drive-get-it-successful-dialog.component.scss'],
})
export class OverDriveGetItSuccessfulDialogComponent implements OnInit {
  public formats = OverDriveFormat;
  public downloadLink: string;
  public faCheckCircle = faCheckCircle;
  @Input() public data: any;

  constructor(
    public activeModal: NgbActiveModal,
    private windowRefService: WindowRefService,
  ) { }

  public ngOnInit(): void {
    this.downloadLink = this.data?.downloadLink?.links?.contentLink?.href;
  }

  public navigateToDownloadLink() {
    this.windowRefService.nativeWindow().open(this.downloadLink, '_blank');
    this.activeModal.close();
  }

}
