import { Component, Input, OnInit } from '@angular/core';
import { DictionaryItem, DictionaryTypes } from 'app/models/dictionaries';
import { DictionariesService } from 'app/services/dictionaries.service';
import { AvailabilityStatus, MaterialTab } from '../../models/entity';
import { isEmptyMessage } from 'core/utils/empty-message';

@Component({
  selector: 'app-physical-item-availability',
  templateUrl: './physical-item-availability.component.html',
  styleUrls: ['./physical-item-availability.component.scss'],
})
export class PhysicalItemAvailabilityComponent implements OnInit {
  @Input() public materialTab: MaterialTab;
  @Input() public availabilityStatus: AvailabilityStatus;

  public readonly availabilityStatusEnum = AvailabilityStatus;
  public formatByCode: DictionaryItem;
  public showOpacMessage: boolean;

  constructor(
    private readonly dictionariesService: DictionariesService,
  ) {}

  ngOnInit() {
    this.formatByCode = this.dictionariesService.getDictionaryItemByCode(
      DictionaryTypes.MATERIAL_TYPES, this.materialTab?.materialTypes?.[0]);
    this.showOpacMessage = !isEmptyMessage(this.materialTab?.opacMessage);
  }
}
