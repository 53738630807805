import { createAction, props } from '@ngrx/store';
import { PatronUser } from 'user/models/user';

export const loadPatronUser = createAction('[User] Load Patron User');

export const loadPatronUserSuccess = createAction(
  '[User] Load Patron User Success',
  props<{ patronUser: PatronUser }>(),
);

export const loadPatronUserFailure = createAction(
  '[User] Load Patron User Failure',
  props<{ error: any }>(),
);
