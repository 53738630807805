import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import {
  loadPatronUser,
  loadPatronUserFailure,
  loadPatronUserSuccess,
} from 'user/actions/patron-user.actions';
import { PatronUserService } from 'user/services/patron-user.service';

@Injectable()
export class PatronUserEffects {

  loadPatronUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadPatronUser),
        exhaustMap(() =>
          this.patronUserService.getMe()
            .pipe(
              map(patronUser => loadPatronUserSuccess({ patronUser })),
              catchError((error) => of(loadPatronUserFailure( error ))),
            )),
      ),
  );

  loadPatronUserFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadPatronUserFailure),
        map(p => p.error),
        exhaustMap((error) => {
          if (error.redirectLink) {
            window.location.href = error.redirectLink;
          }
          return from(this.router.navigateByUrl('/'));
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private patronUserService: PatronUserService,
  ) { }

}
