export interface LocalizationConfiguration {
  localizationConfigurationId: string;
  locale?: string;
  customerId: string;
  currencyDetails?: Currency;
  before?: boolean;
  dateFormat?: LocalizationDateFormat;
}

export interface Currency {
  code: string;
  country: string;
  name: string;
  displaySymbol: string;
  paypalCode?: string;
}

export enum LocalizationDateFormat {
  MonthFirst = 'mm/dd/yyyy',
  DayFirst = 'dd/mm/yyyy',
}

export const mappedShortDateFormats = {
  [LocalizationDateFormat.MonthFirst]: 'M/d/yyyy',
  [LocalizationDateFormat.DayFirst]: 'd/M/yyyy',
};
