import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { faConveyorBeltAlt } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngrx/store';
import { moveBookmarksViaSelectList, resetSelectListState } from 'app/list/actions/list.actions';
import { ListItemEntity, ListWithItemsCount } from 'app/list/models/list';
import { asapScheduler, Observable, scheduled, Subscription } from 'rxjs';
import { delay, filter, mergeAll, tap } from 'rxjs/operators';
import { SelectListTrigger } from '../../../list/models/select-list';
import { getSelectListState, SelectListState } from '../../../list/reducers/list.reducer';

interface ListExtended {
  list: ListWithItemsCount;
  selected: boolean;
}

enum SelectListViewState {
  SELECT_LIST,
  CREATE_NEW_LIST,
  CONFIRM_DELETE_ITEMS,
}

@Component({
  selector: 'app-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss'],
})
export class SelectListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public listItemEntities: ListItemEntity[];
  @Input() public lists: ListWithItemsCount[];
  @Input() public listIdsSelected: string[];
  @Input() public trigger?: SelectListTrigger;
  @Input() public multiselect = false;
  @Input() public canCreateNew = false;
  @Input() public confirmDelete = false;
  @Output() public onDone = new EventEmitter<void>();
  @Output() public onCancel = new EventEmitter<void>();

  public availableListItems: ListItemEntity[] = [];
  public unavailableListItems: ListItemEntity[] = [];
  public viewState: SelectListViewState = SelectListViewState.SELECT_LIST;
  public listsPrepared: ListExtended[];
  public doesSomeListHasShowcase: boolean;
  public selectListState: SelectListState;
  public selectListStateEnum = SelectListState;

  public readonly viewStateEnum = SelectListViewState;
  public readonly plusCircleIcon = faPlusCircle;
  public readonly showcaseIcon = faConveyorBeltAlt;
  private originalListIdsSelected: string[];
  private multiselectChanges: {
    addToLists: Set<string>,
    removeFromLists: Set<string>,
  };
  private readonly subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly cdr: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {
    this.store.dispatch(resetSelectListState());
    this.subscription.add(
      this.getSelectListStateWithLoadingDelayed().subscribe((selectListState) => {
        this.selectListState = selectListState;
        if (this.selectListState === SelectListState.DONE) {
          this.store.dispatch(resetSelectListState());
          this.onDone.emit();
          this.closeCreateList();
        }
        this.cdr.detectChanges();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.lists || changes.listIdsSelected) {
      this.setInitialSelection();
    }
    if (changes.listItemEntities) {
      this.defineItemsAvailability();
    }
  }

  public toggleList(listId: string, listIndex?: number): void {
    if (!this.multiselect) {
      const fromListId = this.listIdsSelected[0];
      if (fromListId !== listId) {
        this.store.dispatch(moveBookmarksViaSelectList({
          entities: this.availableListItems,
          fromListIds: (fromListId) ? [fromListId] : [],
          toListIds: [listId],
          trigger: this.trigger,
        }));
      } else {
        this.onDone.emit();
        this.closeCreateList();
      }
    } else {
      this.multiselectList(listId, listIndex);
    }
  }

  public confirmAndApplyMultiselectChanges(): void {
    if (!this.availableListItems.length) {
      return;
    }
    if (this.multiselectChanges.addToLists.size || this.multiselectChanges.removeFromLists.size) {
      const isDeletingItems = !this.multiselectChanges.addToLists.size && this.multiselectChanges.removeFromLists.size;
      if (this.confirmDelete && isDeletingItems) {
        this.openDeleteConfirmation();
        return;
      }
      this.applyMultiselectChanges();
    }
  }

  public applyMultiselectChanges(): void {
    this.store.dispatch(moveBookmarksViaSelectList({
      entities: this.availableListItems,
      fromListIds: Array.from(this.multiselectChanges.removeFromLists),
      toListIds: Array.from(this.multiselectChanges.addToLists),
    }));
  }

  public cancelMultiselectChanges(): void {
    this.setInitialSelection();
    this.onCancel.emit();
  }

  public openCreateList(): void {
    this.viewState = SelectListViewState.CREATE_NEW_LIST;
  }

  public closeCreateList(): void {
    this.viewState = SelectListViewState.SELECT_LIST;
  }

  public openDeleteConfirmation(): void {
    this.viewState = SelectListViewState.CONFIRM_DELETE_ITEMS;
  }

  public closeDeleteConfirmation(): void {
    this.viewState = SelectListViewState.SELECT_LIST;
  }

  public onConfirmDelete(): void {
    this.applyMultiselectChanges();
    this.closeDeleteConfirmation();
  }

  public trackByListId(index: number, listExtended: ListExtended): string {
    return listExtended.list.id;
  }

  public closeCreateAndSelectList(listId: string): void {
    // timeout to get updated lists
    setTimeout(() => {
      this.toggleList(listId);
      this.cdr.detectChanges(); // bug on android
    });
    if (this.multiselect) {
      this.closeCreateList();
    }
  }

  public resetSelectListState(): void {
    this.store.dispatch(resetSelectListState());
  }

  public onCheckboxClick(event: Event) {
    event.preventDefault(); // prevent checkbox from being checked, as it's only for visual purposes
  }

  private setInitialSelection() {
    this.listsPrepared = this.lists.map((list) => ({list, selected: this.listIdsSelected?.includes(list.id)}));
    this.doesSomeListHasShowcase = this.listsPrepared.some(({list}) => list.showcaseRef);
    this.originalListIdsSelected = this.listIdsSelected;
    this.multiselectChanges = {addToLists: new Set(), removeFromLists: new Set()};
  }

  private defineItemsAvailability() {
    const partitions = this.listItemEntities.reduce((acc, item) => {
      item.isUnresolved ? acc.unavailable.push(item) : acc.available.push(item);
      return acc;
    }, {available: [], unavailable: []});

    this.availableListItems = partitions.available;
    this.unavailableListItems = partitions.unavailable;
  }

  private multiselectList(listId: string, listIndex?: number): void {
    if (isNaN(listIndex)) {
      listIndex = this.listsPrepared.findIndex((list) => list.list.id === listId);
    }
    const nextSelected = !this.listsPrepared[listIndex].selected;
    const existed = this.originalListIdsSelected.includes(listId);
    if (existed) {
      if (nextSelected) {
        this.multiselectChanges.removeFromLists.delete(listId);
      } else {
        this.multiselectChanges.removeFromLists.add(listId);
      }
    } else {
      if (nextSelected) {
        this.multiselectChanges.addToLists.add(listId);
      } else {
        this.multiselectChanges.addToLists.delete(listId);
      }
    }
    this.listsPrepared[listIndex].selected = nextSelected;
  }

  private getSelectListStateWithLoadingDelayed(): Observable<SelectListState> {
    const selectListState$ = this.store.select(getSelectListState);
    let setLoading = false;
    return scheduled(
      [
        selectListState$.pipe(
          filter((selectListState) => selectListState === SelectListState.LOADING),
          tap(() => {
            setLoading = true;
          }),
          delay(200),
          filter(() => setLoading),
        ),
        selectListState$.pipe(
          filter((selectListState) => selectListState !== SelectListState.LOADING),
          tap(() => {
            setLoading = false;
          }),
        ),
      ],
      asapScheduler,
    ).pipe(mergeAll());
  }
}
