import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import { faCartShoppingFast, faConveyorBeltAlt } from '@fortawesome/pro-solid-svg-icons';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { SitePermissions } from 'app/permissions/models/permissions';
import { FeatureToggleService } from 'app/services/feature-toggle.service';
import { Subscription } from 'rxjs';
import { openShowcasesTab } from 'user/actions/user-profile.actions';
import { UserPermission } from 'user/models/user';
import { doesUserHavePermission } from 'user/reducers/user.reducer';
import {
  bulkSelectAllItems,
  resetBulkSelection,
  toggleSelectItem,
  tryToBulkHoldSelectedItems
} from '../../../bulk-select/actions/bulk-select.actions';
import { BulkFeatureKey } from '../../../bulk-select/enums/bulk-feature-key';
import { hideForm, openShowcase, showForm } from '../../../custom-showcase/actions/custom-showcase.actions';
import { CustomShowcaseCreatedFromType } from '../../../custom-showcase/models/custom-showcase';
import { CustomerFeature } from '../../../customer-integration/customer-integration';
import { isStaffAuthorizedInKeycloak } from '../../../keycloak/reducers/keycloak.reducer';
import { changeListSort, listClosed, loadMoreListItems, loginFromBookmarksTab } from '../../actions/list.actions';
import { ListItem, ListItemEntity, ListSortField, ListSortOrder, ListType, ListWithItemsCount } from '../../models/list';
import { isLastPageLoaded } from '../../reducers/list.reducer';
import { ListStoreAdapterService } from '../../services/list-store-adapter.service';

enum StepDisplayedEnum {
  lists,
  singleList,
  both,
}

@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({left: '-100%'})),
      state('false', style({left: '0%'})),
      transition('true <=> false', animate('0.5s ease-out')),
    ]),
  ],
})
export class BookmarksComponent implements OnInit, OnDestroy {
  public stepDisplayed: StepDisplayedEnum = StepDisplayedEnum.lists;
  public listOpenCloseState = false;
  public lists: ListWithItemsCount[];
  public openedList: ListWithItemsCount = null;
  public authorized: boolean;
  public isStaffAuthorized: boolean;
  public itemsEntitySelected: ListItemEntity[];
  public itemsSelectedCount: number;
  public isAllSelected: boolean;
  public allListItemsLoaded = true;
  public showcaseIcon = faConveyorBeltAlt;
  public placeHoldsIcon = faCartShoppingFast;
  public isAuthPatronFlagEnabled: boolean;
  public sitePermissions = SitePermissions;
  public readonly hasViewShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_VIEW));
  public readonly hasCreateShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_CREATE));
  public readonly listType = ListType;
  public readonly arrowIconLeft = faAngleLeft;
  public readonly bulkFeatureKey = BulkFeatureKey.BOOKMARKS;
  public readonly CustomerFeature = CustomerFeature;

  public readonly sortTranslateKeys = {
    date: {
      asc: {full: 'bookmarksSortByOldestFirst', short: 'bookmarksSortByOldest'},
      desc: {full: 'bookmarksSortByNewestFirst', short: 'bookmarksSortByNewest'},
    },
    title: {
      asc: {full: 'bookmarksSortByTitleAscending', short: 'bookmarksSortByTitleAZ'},
      desc: {full: 'bookmarksSortByTitleDescending', short: 'bookmarksSortByTitleZA'},
    },
  };
  public readonly stepDisplayedEnum = StepDisplayedEnum;
  private readonly subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly listStoreAdapterService: ListStoreAdapterService,
    private readonly cdr: ChangeDetectorRef,
    private readonly featureToggleService: FeatureToggleService,
  ) {
  }

  public ngOnInit(): void {
    this.isAuthPatronFlagEnabled = this.featureToggleService.getToggles()['DIS-30793_2024-04-27_auth_patron'];
    this.subscription.add(
      this.listStoreAdapterService.getListsWithOpened().subscribe(([lists, openedList, authorized]) => {
        this.lists = lists;
        this.openedList = openedList;
        if (openedList) {
          this.itemsEntitySelected = openedList.items.filter((item) => item.selected).map((item) => item.entity);
          this.itemsSelectedCount = this.itemsEntitySelected.length;
          this.isAllSelected = !!(this.itemsSelectedCount && this.itemsSelectedCount === this.openedList.items.length);
          this.allListItemsLoaded = (this.authorized) ? isLastPageLoaded(this.openedList) : true;
        } else {
          this.itemsSelectedCount = 0;
          this.isAllSelected = false;
          this.allListItemsLoaded = false;
        }

        this.listOpenCloseState = !!openedList;
        this.authorized = authorized;
        this.cdr.markForCheck();
      }),
    );

    this.subscription.add(this.store.select(isStaffAuthorizedInKeycloak)
    .subscribe((isStaffAuthorized) => {
      this.isStaffAuthorized = isStaffAuthorized;
    }));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onOpenCloseAnimation(event: AnimationEvent): void {
    if (event.phaseName === 'start') {
      this.stepDisplayed = StepDisplayedEnum.both;
    } else if (event.toState) {
      this.stepDisplayed = StepDisplayedEnum.singleList;
    } else {
      this.stepDisplayed = StepDisplayedEnum.lists;
      this.store.dispatch(listClosed());
    }
  }

  public closeListAndDeselectItems(): void {
    this.setAllItemsSelection(this.openedList.id, false);
    this.listOpenCloseState = false;
  }

  public setAllItemsSelection(listId: string, selected: boolean): void {
    this.listStoreAdapterService.setAllItemsSelection(listId, selected);
    this.updateBulkSelectOnSelectAll(selected);
  }

  public onToggleItem(listId: string, listItem: ListItem): void {
    this.listStoreAdapterService.toggleItemSelection(listId, listItem.id);
    this.updateBulkSelectOnToggle(listItem.entity);
  }

  public createShowcase(): void {
    const createdFrom = {id: this.openedList.id, name: this.openedList.name, type: CustomShowcaseCreatedFromType.list};
    this.store.dispatch(showForm({showcase: {createdFrom}}));
  }

  public viewShowcase(): void {
    this.store.dispatch(hideForm());
    this.store.dispatch(openShowcasesTab());
    this.store.dispatch(openShowcase({id: this.openedList.showcaseRef}));
  }

  public removeSelectedFromList(): void {
    this.listStoreAdapterService.removeSelectedFromList(this.openedList.id);
  }

  public bulkPlaceHolds(event: Event) {
    event.stopPropagation();
    if (this.itemsEntitySelected.length) {
      const items = this.itemsEntitySelected.map((item) => item.sourceEntity);
      this.store.dispatch(tryToBulkHoldSelectedItems({bulkFeatureKey: this.bulkFeatureKey, items}));
    }
  }

  public logIn(event: Event): void {
    event.stopPropagation();
    this.store.dispatch(loginFromBookmarksTab());
  }

  public setOpenedListSort(field: ListSortField, order: ListSortOrder, sortDropdown: NgbDropdown): void {
    if (field !== this.openedList.sort.field || order !== this.openedList.sort.order) {
      this.store.dispatch(changeListSort({id: this.openedList.id, sort: {field, order}}));
    } else {
      sortDropdown.close();
    }
  }

  public loadMoreItems(): void {
    this.store.dispatch(loadMoreListItems({id: this.openedList.id}));
  }

  private updateBulkSelectOnToggle(listItemEntity: ListItemEntity) {
    const item = listItemEntity.sourceEntity;
    this.store.dispatch(toggleSelectItem({bulkFeatureKey: this.bulkFeatureKey, item}));
  }

  private updateBulkSelectOnSelectAll(selected: boolean) {
    if (selected) {
      this.store.dispatch(resetBulkSelection({bulkFeatureKey: this.bulkFeatureKey}));
    } else {
      const items = this.itemsEntitySelected.map((item) => item.sourceEntity);
      this.store.dispatch(bulkSelectAllItems({bulkFeatureKey: this.bulkFeatureKey, items}));
    }
  }
}
