import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { getPatronOrStaffUserId } from 'user/reducers/user.reducer';
import {
  clearSearchMyShowcases,
  loadAllFirstPage,
  loadAllMore,
  loadMyShowcases,
  preloadAllShowcasesCount,
  setSearchText,
  showcaseClosed,
  switcherStateSet,
} from '../../actions/custom-showcase.actions';
import {
  CustomShowcase,
  CustomShowcaseCreatedFromType,
  CustomShowcaseSingle,
  isOpenedCuratedShowcase,
  isOpenedDynamicShowcase,
  OpenedCuratedShowcaseWithDetails,
  OpenedDynamicShowcaseWithDetails,
} from '../../models/custom-showcase';
import {
  CustomShowcaseTotalCounts,
  getAllCustomShowcases,
  getCustomShowcaseTotalCounts,
  getIsShowcaseOpened,
  getMyCustomShowcasesConsideringSearch,
  isLastPageLoaded,
} from '../../reducers/custom-showcase.reducer';
import { CustomShowcaseStoreAdapterService } from '../../services/custom-showcase-store-adapter.service';

enum StepDisplayedEnum {
  showcases,
  singleShowcase,
  both,
}

@Component({
  selector: 'app-custom-showcases',
  templateUrl: './custom-showcases.component.html',
  styleUrls: ['./custom-showcases.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({left: '-100%'})),
      state('false', style({left: '0%'})),
      transition('true <=> false', animate('0.5s ease-out')),
    ]),
  ],
})
export class CustomShowcasesComponent implements OnInit, OnDestroy {
  public userId: string;

  // all showcases data
  public stepDisplayed: StepDisplayedEnum = StepDisplayedEnum.showcases;
  public showcaseOpenCloseState = false;
  public customShowcases$: Observable<CustomShowcase[]>;
  public isAllView: boolean;
  public allShowcasesLoaded: boolean;
  public totalCounts: CustomShowcaseTotalCounts;
  public myResultsCount: number;
  public allResultsCount: number;
  public searchText: string;

  // opened showcase data
  public openedShowcase: CustomShowcaseSingle;
  public openedShowcaseType: CustomShowcaseCreatedFromType | null;
  public openedCuratedShowcaseWithDetails: OpenedCuratedShowcaseWithDetails;
  public openedDynamicShowcaseWithDetails: OpenedDynamicShowcaseWithDetails;
  public readonly showcaseType = CustomShowcaseCreatedFromType;
  public readonly stepDisplayedEnum = StepDisplayedEnum;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly customShowcaseStoreAdapterService: CustomShowcaseStoreAdapterService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.setMyShowcases();
    this.store.dispatch(preloadAllShowcasesCount());
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.store.select(getIsShowcaseOpened).subscribe((isShowcaseOpened) => {
        this.showcaseOpenCloseState = isShowcaseOpened;
        this.cdr.markForCheck();
      }),
    );

    this.subscriptions.add(
      this.store.select(getCustomShowcaseTotalCounts).subscribe((totalCounts) => {
        this.totalCounts = totalCounts;
        this.cdr.markForCheck();
      }),
    );

    this.subscriptions.add(
      this.customShowcaseStoreAdapterService.getOpenedShowcaseWithDetails()
      .subscribe((data) => {
        if (data) {
          this.openedShowcaseType = data.type;
          this.openedShowcase = data.showcase;

          if (isOpenedCuratedShowcase(data)) {
            this.openedCuratedShowcaseWithDetails = data;
            this.openedDynamicShowcaseWithDetails = null;
          } else if (isOpenedDynamicShowcase(data)) {
            this.openedDynamicShowcaseWithDetails = data;
            this.openedCuratedShowcaseWithDetails = null;
          }
        } else {
          this.openedShowcase = null;
          this.openedShowcaseType = null;
          this.openedCuratedShowcaseWithDetails = null;
          this.openedDynamicShowcaseWithDetails = null;
        }
        this.cdr.markForCheck();
      }),
    );

    this.subscriptions.add(
      this.store.select(getPatronOrStaffUserId).subscribe((userId) => {
        this.userId = userId;
        this.cdr.markForCheck();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.store.dispatch(setSearchText({searchText: ''}));
    this.store.dispatch(clearSearchMyShowcases());
    this.subscriptions.unsubscribe();
  }

  public onOpenCloseAnimation(event: AnimationEvent): void {
    if (event.phaseName === 'start') {
      this.stepDisplayed = StepDisplayedEnum.both;
    } else if (event.toState) {
      this.stepDisplayed = StepDisplayedEnum.singleShowcase;
    } else if (event.fromState !== 'void') {
      this.stepDisplayed = StepDisplayedEnum.showcases;
      this.store.dispatch(showcaseClosed({showcase: this.openedShowcase}));
    }
  }

  public selectMyShowcases() {
    if (this.isAllView) {
      this.setMyShowcases();
    }
  }

  public selectAllShowcases() {
    if (!this.isAllView) {
      this.isAllView = true;
      this.store.dispatch(switcherStateSet({isAll: true}));
      this.customShowcases$ = this.store.pipe(select(getAllCustomShowcases))
      .pipe(
        tap((allCustomShowcases) => {
          this.allShowcasesLoaded = !allCustomShowcases.pagination || isLastPageLoaded(allCustomShowcases.pagination);
          this.allResultsCount = allCustomShowcases.pagination?.totalResults;
        }),
        map((allCustomShowcases) => allCustomShowcases.list),
      );
      this.store.dispatch(loadAllFirstPage());
    }
  }

  public searchShowcases(searchText: string) {
    this.searchText = searchText;
    this.store.dispatch(setSearchText({searchText}));
    if (!searchText) {
      this.store.dispatch(clearSearchMyShowcases());
    }
    this.isAllView
      ? this.store.dispatch(loadAllFirstPage())
      : this.store.dispatch(loadMyShowcases());
  }

  public loadMoreItems() {
    this.store.dispatch(loadAllMore());
  }

  public closeShowcase(): void {
    this.showcaseOpenCloseState = false;
  }

  private setMyShowcases() {
    this.isAllView = false;
    this.store.dispatch(switcherStateSet({isAll: false}));
    this.customShowcases$ = this.store.pipe(select(getMyCustomShowcasesConsideringSearch))
    .pipe(
      tap((myCustomShowcases) => {
        this.myResultsCount = myCustomShowcases.length;
      }),
    );
    this.store.dispatch(loadMyShowcases());
  }
}
