import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ScriptLoaderService } from 'common/services/script-loader.service';

@Component({
  selector: 'app-custom-section',
  templateUrl: './custom-section.component.html',
  styleUrls: ['./custom-section.component.scss'],
})
export class CustomSectionComponent implements OnInit {
  @Input() public code: string;
  public sanitizedCode: string;

  constructor(
    private readonly scriptLoaderService: ScriptLoaderService,
    public readonly cdRef: ChangeDetectorRef,
  ) {
  }

  public async ngOnInit() {
    // angular will not process script tags from injected html,
    // so get script content from it and load separately
    const parsedCode = this.scriptLoaderService.sanitizeHtmlAndParseScripts(this.code);
    this.sanitizedCode = parsedCode.sanitizedHtml;

    for (const scriptContent of parsedCode.scripts) {
      await this.scriptLoaderService.loadScript(scriptContent);
    }
  }

  // If custom script takes long to execute, main content in root-page will not be shown properly.
  // It depends on rendered header height and Angular is not aware of the DOM changes made by custom script.
  // Observe DOM mutations to mark for check up to root-page component
  public onDomChange() {
    this.cdRef.markForCheck();
  }
}
