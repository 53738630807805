import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { openBookshelfAccountTab } from 'user/actions/user-profile.actions';
import { AccountTabs } from 'user/models/bookshelf';
import { WindowRefService } from '../../../../services/window-ref.service';

@Component({
  selector: 'app-checked-and-held-items-banner',
  templateUrl: './checked-and-held-items-banner.component.html',
  styleUrls: ['./checked-and-held-items-banner.component.scss'],
})
export class CheckedAndHeldItemsBannerComponent {
  @Input() public heldCount: number;
  @Input() public checkedOutCount: number;
  @Input() public customHoldsLink?: string;
  @Input() public customCheckoutsLink?: string;

  constructor(
    private readonly store: Store,
    private readonly windowRef: WindowRefService,
  ) { }

  public goToHolds(): void {
    if (this.customHoldsLink) {
      this.windowRef.nativeWindow().open(this.customHoldsLink, '_blank');
    } else {
      this.store.dispatch(openBookshelfAccountTab({accountTab: AccountTabs.HOLDS}));
    }
  }

  public goToCheckouts(): void {
    if (this.customCheckoutsLink) {
      this.windowRef.nativeWindow().open(this.customCheckoutsLink, '_blank');
    } else {
      this.store.dispatch(openBookshelfAccountTab({accountTab: AccountTabs.CHECKOUTS}));
    }
  }
}
