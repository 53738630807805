import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Volume } from 'user/models/get-it';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-volume-list',
  templateUrl: './volume-list.component.html',
  styleUrls: ['./volume-list.component.scss'],
})
export class VolumeListComponent implements OnInit, AfterViewInit {

  @Input() public volumes: Volume[];

  @ViewChild('volumesSearchInput') public volumesSearchInput: ElementRef;
  public readonly searchIcon = faSearch;
  public queriedVolumes: Volume[];
  private FILTER_FIELDS = ['volume'];

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  public ngOnInit(): void {
    this.queriedVolumes = this.volumes;
  }
  ngAfterViewInit(): void {
    if (this.volumesSearchInput?.nativeElement) {
      fromEvent(this.volumesSearchInput.nativeElement,'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => {
            this.onVolumesSearch(this.volumesSearchInput.nativeElement.value);
          })
        )
        .subscribe();
    }
  }

  public onClose() {
    this.activeModal.dismiss();
  }

  public trackById(index: number, volume: Volume) {
    return volume.id;
  }

  public placeHold(volume: Volume) {
    this.activeModal.close(volume);
  }

  private filterUsingQuery(rows: Volume[], query: string) {
    if (!query) {
      return rows;
    }
    return rows.reduce((target: Volume[], item) => {
      for (const filter of this.FILTER_FIELDS) {
        if ((item as any)[filter]?.toLowerCase()?.trim()?.includes(query?.toLowerCase()?.trim())) {
          target.push(item);
          break;
        }
      }
      return target;
    }, []);
  }

  public onVolumesSearch(query: string) {
    this.queriedVolumes = this.filterUsingQuery(this.volumes, query);
  }
}
