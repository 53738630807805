<svg class="default-cover-container" viewBox="0 0 80 120" aria-hidden="true">
  <foreignObject x="0" y="0" width="80" height="120">
    <body xmlns="http://www.w3.org/1999/xhtml" style="background: transparent">
    <div
      [style.background-color]="color.background"
      [style.color]="color.text"
      data-automation-id="default-cover-default-cover"
      class="default-cover">
      <div class="default-cover_header">
        <span class="line-clamp line-clamp--two" data-automation-id="default-cover-author">
          {{author}}
        </span>
      </div>
      <div class="default-cover_body">
        <span class="line-clamp line-clamp--five" data-automation-id="default-cover-title">
          {{title}}
        </span>
      </div>
      <div class="default-cover_footer">
        <span class="line-clamp line-clamp--two" data-automation-id="default-cover-material-type">
          {{materialType}}
        </span>
      </div>
    </div>
    </body>
  </foreignObject>
</svg>
