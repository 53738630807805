import { compose, createFeatureSelector, createSelector, } from '@ngrx/store';
import { FacetActions } from 'search/actions/facet.actions';
import { SearchActions } from 'search/actions/search.actions';
import { FacetState, FacetURLFilters, } from 'search/facets/models/facet-state';
import { FacetType } from 'search/facets/models/facet-type';
import { CatalogDateFacet, DateFacet, Facet } from 'search/facets/models/resource-facet';
import { initialResourceFacetsState, resourceFacetsReducer, ResourceFacetsState } from 'search/facets/reducers/resourse-facets.reducer';
import {
  ElementTriggeredSearch,
  initialSearchState,
  searchReducer,
  SearchResultsMetadataState,
  SearchState,
} from 'search/reducers/search.reducer';
import { EntityTypes } from '../../entity/models/entity';
import { defaultSearchObjectConfig, MappedSearchObject, SearchObject, } from '../models/search-object';
import { SearchRequestBody, } from '../models/search-results';

export type State =
  RootState
  & SearchState
  & ResourceFacetsState;

export interface RootState {
  loading: boolean;
  searchObject: SearchObject;
  error: { status: number; message: string } | null;
  searchResultsMetadata: SearchResultsMetadataState;
}

export const initialRootState: RootState = {
  loading: false,
  error: null,
  searchObject: {
    ...defaultSearchObjectConfig,
  },
  searchResultsMetadata: { totalResults: 0, totalPages: 0 },
};

export const initialState: State = {
  ...initialRootState,
  ...initialSearchState,
  ...initialResourceFacetsState,
};

export function reducer(state = initialState, action: SearchActions | FacetActions)
  : State { return compose(searchReducer(action), resourceFacetsReducer(action))(state); }

const searchState = createFeatureSelector<State>('search');

const getPureSearchResultsMetadata = createSelector(searchState, (state) => state && state.searchResultsMetadata);

export const getSearchResultsMetadata = createSelector(getPureSearchResultsMetadata, (searchResultsMetadata) => {
  return searchResultsMetadata;
});

export const getPureSearchObject = createSelector(searchState, (searchState) => searchState && searchState.searchObject);

export const getSearchObject = createSelector(getPureSearchObject, (searchObject): MappedSearchObject => {
  if (searchObject) {
    // We stick to the old model of search object here in order not to make
    // a lot of changes in the whole application

    return {
      searchText: searchObject.searchParams.type.searchText,
      searchType: searchObject.searchType,
      metadataBoolQuery: searchObject.searchParams.type.metadataBoolQuery,
      resourceIds: searchObject.searchParams.type.resourceIds,
      pageSize: searchObject.pagination.pageSize,
      pageNum: searchObject.pagination.pageNum,
      resourceType: searchObject.resourceType,
      sorting: searchObject.sortingParams.sorting,
      sortOrder: searchObject.sortingParams.sortOrder,
      filters: searchObject.filters,
    };
  } else {
    return null;
  }
});

export const getSearchRequestBody = createSelector(getSearchObject, (searchObject): SearchRequestBody => {
  if (searchObject) {
    let searchRequestBody: SearchRequestBody = {} as any;

    if (searchObject.metadataBoolQuery) {
      searchRequestBody.metadataBoolQuery = searchObject.metadataBoolQuery;
    } else if (searchObject.searchText) {
      searchRequestBody.searchText = searchObject.searchText;
    } else if (searchObject.resourceIds) {
      searchRequestBody.resourceIds = searchObject.resourceIds;
    }

    if (searchObject.sorting != null) {
      searchRequestBody.sorting = searchObject.sorting;
    }

    if (searchObject.expand != null) {
      searchRequestBody.expand = searchObject.expand;
    }

    if (searchObject.sortOrder != null) {
      searchRequestBody.sortOrder = searchObject.sortOrder;
    }

    if (searchObject.searchType != null) {
      searchRequestBody.searchType = searchObject.searchType;
    }

    if (searchObject.filters) {
      const facets = { ...searchObject.filters.facets };
      searchRequestBody = Object.keys(facets)
      .reduce((acc, key) => {
        if ((facets as any)[key] && ((facets as any)[key].length || (facets as any)[key].toString())) {
          (acc as any)[key] = (facets as any)[key];
        }
        return acc;
      }, searchRequestBody);
    }

    searchRequestBody.pageNum = searchObject.pageNum;
    searchRequestBody.pageSize = searchObject.pageSize;
    searchRequestBody.resourceType = EntityTypes.FORMAT_GROUP;

    return searchRequestBody;
  } else {
    return null;
  }
});

export const getLastSearchResultsUrl = createSelector(searchState, (searchState) => {
  return searchState && searchState.lastSearchResultsUrl ?
    searchState.lastSearchResultsUrl :
    null;
});

export const getV2Facets = createSelector(searchState, (searchState): FacetState[] => {
  return searchState && searchState.facets && searchState.facets.v2State
    && (searchState.facets.v2State.toList().toJS() as FacetState[]).filter((facetState: FacetState) => facetState.schemaWithData.schema.isShown);
});

export const getAppliedFacetsArray = createSelector(getV2Facets, (facets = []): (DateFacet | Partial<Facet>)[] => {
  return facets.reduce((acc, facet) => {
    switch (facet.schemaWithData.schema.type) {
      case FacetType.Iterable: {
        return acc.concat(facet.applied);
      }
      default: {
        return facet.applied ? acc.concat([facet.applied]) : acc;
      }
    }
  }, []);
});

export const getFacetsLoading = createSelector(getV2Facets, (facets = []): boolean => {
  return facets.some((facet) => facet.isLoading);
});

export const getSearchResultsMetadataForScroll = createSelector(searchState, (searchState) => {
  return searchState?.searchResultsMetadata;
});

export const getSelectedLoading = createSelector(searchState, (searchState): boolean => {
  return !!searchState.loading;
});

export const getSelectedError = createSelector(searchState, (searchState) => {
  return searchState.error;
});

export const getLockedFacetsAsQueryParams = createSelector(
  getV2Facets,
  (facets = []): FacetURLFilters => {
    return facets.reduce((acc, facet) => {
      const mapField = facet.schemaWithData.schema.mapField;
      switch (facet.schemaWithData.schema.type) {
        case FacetType.Iterable: {
          const appliedIterable = facet.applied as Facet[];
          const lockedIterable = appliedIterable.reduce((locked, f: Facet) => f.isResetLocked ? locked.concat(f.id) : locked, []);
          if (lockedIterable.length) {
            acc[mapField + 'Ids'] = lockedIterable.join(',');
          }
          return acc;
        }
        case FacetType.Date: {
          const appliedDate = facet.applied as DateFacet;
          if (appliedDate && appliedDate.isResetLocked) {
            if (appliedDate.dateFrom) {
              acc['dateFrom'] = appliedDate.dateFrom;
            }
            if (appliedDate.dateTo) {
              acc['dateTo'] = appliedDate.dateTo;
            }
          }
          return acc;
        }
        case FacetType.CatalogDate: {
          const appliedCatalogDate = facet.applied as CatalogDateFacet;
          if (appliedCatalogDate && appliedCatalogDate.isResetLocked) {
            if (appliedCatalogDate.catalogDate) {
              acc['catalogDate'] = appliedCatalogDate.catalogDate;
            }
          }
          return acc;
        }
        case FacetType.Single: {
          const appliedSingle = facet.applied as Facet;
          if (appliedSingle && appliedSingle.isResetLocked) {
            acc[mapField] = true;
          }
          return acc;
        }
      }
    }, {} as any);
  });

export const getFilterPanelState = createSelector(searchState, (searchState) => {
  return searchState.filterPanelState;
});

export const getDoFocusRefineResultsButton = createSelector(searchState, (searchState): boolean => {
  return searchState.doFocusRefineResultsButton;
});

export const getDoFocusResultsNumberButton = createSelector(searchState, (searchState): boolean => {
  return searchState.doFocusResultsNumberButton;
});

export const getDoFocusSortedByButton = createSelector(searchState, (searchState): boolean => {
  return searchState.doFocusSortedByButton;
});

export const getElementTriggeredSearch = createSelector(searchState, (searchState): ElementTriggeredSearch => {
  return searchState.elementTriggeredSearch;
});

export const getCurrentSearchInput = createSelector(searchState, (searchState): string => {
  return searchState.currentSearchInput;
});

export const getAdvancedMode = createSelector(searchState, (searchState) => {
  return searchState?.advancedMode;
});
