<div class="p-4">
  <div class="modal-header">
    <h2 ngbAutofocus class="modal-title" data-automation-id="bulk-renew-days-choice-modal-title">
      {{title | translate}}
    </h2>
  </div>
  <div class="modal-body">
    <h3 class="modal-title" data-automation-id="bulk-renew-days-choice-modal-subtitle">
      {{'renewItemsDueInDays' | translate}}
    </h3>
    <input
      [attr.min]="minDays"
      [attr.max]="maxDays"
      [formControl]="daysInput"
      [class.is-invalid]="daysInput.invalid && daysInput.touched"
      type="number"
      data-automation-id="due-in-days-input">
    <small
      *ngIf="daysInput.errors?.max && daysInput.touched"
      id="renewal-max-days-error"
      class="form-text text-muted is-invalid"
      data-automation-id="renewal-max-days-error">
      {{'renewalMaxDaysError' | translate : {maxDays} }}
    </small>
    <small
      *ngIf="daysInput.errors?.min && daysInput.touched"
      id="renewal-min-days-error"
      class="form-text text-muted is-invalid"
      data-automation-id="renewal-min-days-error">
      {{'renewalMinDaysError' | translate}}
    </small>
    <div class="modal-footer">
      <button
        class="ins-buttons button-secondary flint"
        data-automation-id="cancel-btn"
        (click)="dismissModal()">
        {{'cancel' | translate}}
      </button>
      <button
        [disabled]="daysInput.invalid"
        type="submit"
        class="ins-buttons button-primary flint ml-3"
        data-automation-id="renew-btn"
        (click)="onClose()">
        {{'renew' | translate}}
      </button>
    </div>
  </div>
</div>


