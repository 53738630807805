<div class="citation p4 pb-5 p">
  <div class="modal-header header-container pl-6 pl-sm-9 pr-4 pt-4 pb-0">
    <h2 class="modal-title header mt-5 mb-0 pb-0" data-automation-id="citation-title" translate>exportCitation</h2>
    <button type="button" class="close" [attr.aria-label]="'closeDialog' | translate" (click)="onClose()" data-automation-id="citation-close-btn">
      <span aria-hidden="true" class="icon-close"></span>
    </button>
  </div>
  <div class="modal-body body px-6 px-sm-9 py-0 mt-5">
    <div class="form-group mb-0">
      <label class="edition-label" for="edition-select" data-automation-id="citation-edition-label" translate>editionTitle</label>
      <select class="custom-select select p-4 mb-0" id="edition-select" name="select" [formControl]="editionSelect"
        data-automation-id="citation-edition-select">
        <option *ngFor="let edition of editions; trackBy: trackById" [value]="edition.id"
          data-automation-id="citation-edition-option">
          {{ edition.editionInfo }}
        </option>
      </select>
    </div>
  </div>
  <div class="modal-footer footer justify-content-between flex-wrap justify-content-sm-start px-6 px-sm-9 py-0 mt-6">
    <button type="button" class="btn btn-outline-dark action-button px-4 mb-2 mr-4 mr-sm-4 ml-0"
      data-automation-id="citation-RefWorks-button" (click)="onRefworks()">Refworks</button>
    <button type="button" class="btn btn-outline-dark action-button px-4 mb-2 mr-4 mr-sm-4 ml-0"
      data-automation-id="citation-Easybib-button" (click)="onEasybib()">Easybib</button>
    <button type="button" class="btn btn-outline-dark action-button px-4 mb-2 mr-4 mr-sm-4 ml-0"
      data-automation-id="citation-Zotero-button" [attr.aria-expanded]="!isZoteroCollapsed"
      [attr.aria-label]="(isZoteroCollapsed ? 'zoteroShowExportingInfo' : 'zoteroHideExportingInfo') | translate"
      (click)="onZotero()">Zotero</button>
    <button [disabled]="!MENDELEY_URL" type="button"
      class="btn btn-outline-dark action-button px-4 mb-2 mr-4 mr-sm-4 ml-0"
      data-automation-id="citation-Mendeley-button" (click)="onMendeley()">Mendeley</button>
    <button type="button" class="btn btn-outline-dark action-button px-4 mb-2 mr-4 mr-sm-4 ml-0"
      data-automation-id="citation-RIS-button" (click)="onRISFile()">{{ 'risFile' | translate }}</button>
  </div>
  <div class="px-6 px-sm-9 py-0 mt-6" *ngIf="!isZoteroCollapsed" data-automation-id="zotero-container" aria-live="assertive">
    <h3 class="zotero-collapse-title" data-automation-id="zotero-title">{{ 'zoteroCollapseTitle' | translate }}:</h3>
    <div class="card">
      <div class="card-body py-6 px-5">
        <ol class="mb-0">
          <li><button class="zotero-ris-file-button" data-automation-id="zotero-ris-file-link"
              (click)="onZoteroRISFile()">{{ 'risFileDownload' | translate }}</button></li>
          <li><a href="https://www.zotero.org/download" class="zotero-plugin-link" target="_blank" data-automation-id="zotero-plugin-link"
              (click)="onZoteroPlugin()">{{ 'zoteroPluginDownloadLink' | translate }}
            </a>&nbsp;<span data-automation-id="zotero-plugin-download-text">{{ 'zoteroPluginDownloadText' | translate }}</span>
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>
