<button class="library-info-button"
        #libraryInfoButton
        *ngIf="libraryInfoConfigEnabled && defaultLibraryCode"
        data-automation-id="library-info-btn"
        (click)="toggleLibraryInfo($event)"
        cdkMonitorElementFocus
        attr.aria-label="{{ 'libraryInfo' | translate }}"
        tippy="{{ 'libraryInfo' | translate }}">
  <span class="icon icon-library-info"></span>
</button>
