<div class="nlb-item-locator p-1">
  <ng-container *ngIf="loading; else loaded">
    <div class="spinner-border text-primary"
         data-automation-id="item-locator-spinner">
      <span class="sr-only" translate>Loading...</span>
    </div>
  </ng-container>
  <ng-template #loaded>
    <ng-container *ngIf="error; else success">
      <span translate data-automation-id="item-locator-map-error">itemHasNoLocationMap</span>
    </ng-container>
    <ng-template #success>
      <a class="map-link" href="{{itemLocation?.mapUrl}}" target="_blank">
        <span data-automation-id="item-locator-map-description">{{locationDescription}}</span>
        <span data-automation-id="item-locator-map-icon" class="fas fa-map small-icon map-icon"></span>
      </a>
    </ng-template>
  </ng-template>
</div>
