export interface CitationResponseRIS {
  fileName: string;
  body: Blob;
}

export interface MendeleyAuthUrlResponse {
  url: string;
}

export interface AuthTokenData {
  refresh_token: string;
  access_token: string;
  expiration_date: string;
  token_type: string;
}

export interface MendeleyData {
  title: string;
  type: string;
  id: string;
}

export enum MendeleyAuthStorage {
  ACCESS_TOKEN = 'mendeley_access_token',
  REFRESH_TOKEN = 'mendeley_refresh_token',
  EXPIRATION_DATE = 'mendeley_expiration_date',
  CODE = 'mendeley_code',
}

export interface AuthStorage {
  ACCESS_TOKEN: string;
  REFRESH_TOKEN: string;
  EXPIRATION_DATE: string;
  CODE: string;
}
