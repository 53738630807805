<div class="author-bio" [class.d-none]="!isDisplayed" [class.invisible]="!isLoaded">
  <div class="unbound_author"></div>
</div>

<div aria-live="polite" class="d-flex w-100 justify-content-start" role="status">
  <div *ngIf="!isLoaded"
       class="spinner-border text-primary p-2 mb-2"
       data-automation-id="unbound-author-spinner">
    <span class="sr-only" translate>Loading...</span>
  </div>
</div>
