import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faSearchPlus } from '@fortawesome/pro-regular-svg-icons';
import { faConveyorBeltAlt } from '@fortawesome/pro-solid-svg-icons';
import { select, Store } from '@ngrx/store';
import { openList } from 'app/list/actions/list.actions';
import { runSavedSearch } from 'app/saved-search/actions/saved-search.actions';
import { combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { dateFormatFactory } from 'shared/date-format-factory';
import { areEqualDeeply } from 'shared/utils/are-equal-deeply';
import { openBookmarksTab, openShowcasesTab } from 'user/actions/user-profile.actions';
import { MaxHeightPerfectScrollbar } from 'user/models/bookshelf';
import { UserPermission } from 'user/models/user';
import { doesUserHavePermission, getStaffUserName, getUserNickname } from 'user/reducers/user.reducer';
import {
  clearOpenedState,
  create,
  hideForm,
  openShowcase,
  resetCreateState,
  resetUpdateState,
  update
} from '../../actions/custom-showcase.actions';
import { CustomShowcaseCreatedFromType, CustomShowcaseForm, isDynamicShowcase } from '../../models/custom-showcase';
import { customShowcaseMetadataDefaults, customShowcaseMetadataSavedSearchDefaults } from '../../models/custom-showcase.defaults';
import {
  CustomShowcaseState,
  getCreateState,
  getFormData,
  getIsShowcaseOpened,
  getUpdateState,
} from '../../reducers/custom-showcase.reducer';
import { FeatureToggleService } from 'app/services/feature-toggle.service';
import { ShowcasePermissions } from 'app/permissions/models/permissions';
import { LocalizationService } from 'app/services/localization.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';

@Component({
  selector: 'app-custom-showcase-form',
  templateUrl: './custom-showcase-form.component.html',
  styleUrls: ['./custom-showcase-form.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useFactory: dateFormatFactory, deps: [LocalizationService]},
  ],
})
export class CustomShowcaseFormComponent implements OnInit, OnDestroy {
  @ViewChild('nameField') nameField: ElementRef;
  public customShowcaseForm: UntypedFormGroup;
  public isSaving: boolean;
  public hasError: boolean;
  public isEditMode = false;
  public showcase: CustomShowcaseForm;
  public showcasePermissions = ShowcasePermissions;
  public isAuthPatronFlagEnabled: boolean;
  public readonly createdFromType = CustomShowcaseCreatedFromType;
  public readonly nickname$ = this.store.pipe(select(getUserNickname));
  public readonly staffName$ = this.store.pipe(select(getStaffUserName));
  public readonly hasPublishShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_PUBLISH));
  public readonly maxHeightPerfectScrollbar = MaxHeightPerfectScrollbar.BASE;
  public readonly showcaseIcon = faConveyorBeltAlt;
  public readonly searchIcon = faSearchPlus;
  private readonly subscription = new Subscription();
  private isSaveAndPublish = false;
  private isShowcaseOpened = false;
  private isUpdated = false;
  public currentPublishStatus: boolean;
  public today = new Date();
  public isShowCaseScheduleFlagEnabled: boolean = false;
  @Inject(MAT_DATE_FORMATS) private dateFormats: any

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store<CustomShowcaseState>,
    private readonly cdr: ChangeDetectorRef,
    private readonly featureToggleService: FeatureToggleService,
  ) {
    this.store.dispatch(resetCreateState());
    this.store.dispatch(resetUpdateState());
    this.customShowcaseForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      metadata: this.formBuilder.group({
        sortOrder: [customShowcaseMetadataDefaults.sortOrder],
        displayOn: this.formBuilder.group(customShowcaseMetadataDefaults.displayOn),
        displayCreator: [customShowcaseMetadataDefaults.displayCreator],
        aboutShowcase: this.formBuilder.group({
          startDate: ['', [Validators.maxLength(50)]],
          endDate: ['', [Validators.maxLength(50)]],
          deleteExpired: [false],
          type: this.formBuilder.group(customShowcaseMetadataDefaults.aboutShowcase.type),
          age: this.formBuilder.group(customShowcaseMetadataDefaults.aboutShowcase.age),
          format: this.formBuilder.group(customShowcaseMetadataDefaults.aboutShowcase.format),
        }),
      }),
    });
  }

  public ngOnInit(): void {
    this.isAuthPatronFlagEnabled = this.featureToggleService.getToggles()['DIS-30793_2024-04-27_auth_patron'];
    this.isShowCaseScheduleFlagEnabled = this.featureToggleService.getToggles()['DIS-31554_2024-08-30_schedule_showcase'];
    this.subscription.add(
      this.store.select(getFormData)
      .pipe(distinctUntilChanged(areEqualDeeply))
      .subscribe((formData) => {
        if (formData.showcase) {
          this.isEditMode = !!formData.showcase.id;
          this.showcase = formData.showcase;


          if (this.isEditMode) {
            this.name.setValue(formData.showcase.name);
            if (isDynamicShowcase(this.showcase)) {
              this.metadata.removeControl('sortOrder');
            }
            this.metadata.setValue(formData.showcase.metadata);
          } else {
            this.name.setValue(formData.showcase.createdFrom.name);
            if (isDynamicShowcase(this.showcase)) {
              this.metadata.setValue(customShowcaseMetadataSavedSearchDefaults);
            }
          }
        }
        this.cdr.markForCheck();
      }),
    );

    this.subscription.add(
      combineLatest([
        this.store.select(getCreateState),
        this.store.select(getUpdateState),
      ])
      .pipe(distinctUntilChanged(areEqualDeeply))
      .subscribe(([createState, updateState]) => {
        this.isSaving = createState.loading || updateState.loading;
        this.hasError = !!(createState.errorCode || updateState.errorCode);

        if (createState.id) {
          this.close();
          this.store.dispatch(clearOpenedState());
          this.store.dispatch(openShowcasesTab());
        }
        if (updateState.updated) {
          this.isUpdated = true;
        }
        if (updateState.updated && !this.isSaveAndPublish) {
          this.close();
          if (this.isShowcaseOpened) {
            this.store.dispatch(openShowcase({id: this.showcase.id}));
          }
        }
        this.cdr.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getIsShowcaseOpened)
      .subscribe((isShowcaseOpened) => {
        this.isShowcaseOpened = isShowcaseOpened;
      }),
    );
    this.onFocusSetup();
  }


  private onFocusSetup() {
    setTimeout(() => {
      this.nameField?.nativeElement.focus();
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public save(isSaveAndPublish = false) {
    this.isSaveAndPublish = isSaveAndPublish;
    this.currentPublishStatus = this.showcase.published;
    this.name.markAsTouched();
    if (!this.customShowcaseForm.valid || this.isSaving) {
      return;
    }
    const formValue = {...this.customShowcaseForm.getRawValue()};

    this.isEditMode
      ? this.update(formValue, isSaveAndPublish, this.currentPublishStatus)
      : this.create(formValue, isSaveAndPublish, this.currentPublishStatus);
  }


  public cancel() {
    this.close();
    if (this.isUpdated && this.isShowcaseOpened) {
      this.store.dispatch(openShowcase({id: this.showcase.id}));
    }
  }

  public get name(): AbstractControl | null {
    return this.customShowcaseForm.get('name');
  }

  public get metadata(): UntypedFormGroup | null {
    return this.customShowcaseForm.get('metadata') as UntypedFormGroup;
  }

  public get aboutShowcase(): UntypedFormGroup | null {
    return this.metadata.get('aboutShowcase') as UntypedFormGroup;
  }

  public onShowcaseTypeClick(): void {
    isDynamicShowcase(this.showcase) ? this.runSavedSearch() : this.openList();
  }

  private runSavedSearch(): void {
    this.store.dispatch(runSavedSearch({savedSearchId: this.showcase.createdFrom.id}));
  }

  private openList(): void {
    this.store.dispatch(openBookmarksTab());
    this.store.dispatch(openList({listId: this.showcase.createdFrom.id}));
  }

  private create(formValue: any, isSaveAndPublish: boolean = false, currentPublishStatus: boolean = false) {
    formValue.createdFrom = this.showcase.createdFrom;
    this.store.dispatch(create({form: formValue, isSaveAndPublish, currentPublishStatus}));
  }

  private update(formValue: any, isSaveAndPublish: boolean = false, currentPublishStatus: boolean) {
    this.store.dispatch(update({updatedFields: formValue, id: this.showcase.id, isSaveAndPublish, currentPublishStatus}));
  }

  private close() {
    this.store.dispatch(resetCreateState());
    this.store.dispatch(resetUpdateState());
    this.store.dispatch(hideForm());
  }
}
