import {
  Component,
  Input,
  ElementRef,
  HostBinding,
  Output,
  EventEmitter,
  HostListener,
  AfterContentInit,
  AfterViewInit
} from '@angular/core';
import { Highlightable } from '@angular/cdk/a11y';
import { I18NService } from 'app/services/i18-n.service';

@Component({
  selector: 'app-custom-dropdown-option',
  templateUrl: './custom-dropdown-option.component.html',
  styleUrls: ['./custom-dropdown-option.component.scss'],
})
export class CustomDropdownOptionComponent
  implements AfterContentInit, AfterViewInit, Highlightable {
  @Input() public value: string;
  @Input() public image: string;
  @Output() public select: EventEmitter<any> = new EventEmitter();

  @Input()
  @HostBinding('class.selected')
  @HostBinding('attr.aria-selected')
  public selected = false;

  @HostBinding('class.active')
  public active: boolean;

  @HostBinding('attr.role')
  public role = 'option';

  public label: string;

  constructor(private elementRef: ElementRef, private i18nService: I18NService) {}

  public ngAfterContentInit(): void {
    this.initOptionLabel();
  }

  public ngAfterViewInit(): void {
    // ngAfterViewInit is more suitable lifecycle hook as we have not only static text, but interpolation or other elements inside of option.
    // Unfortunately, I faced a problem with change detection and detectChanges() doesn't work.
    // The label won't be updated until you hover it with a cursor.
    // The only available solution for now is the folowwing:

    if (!this.label) {
      this.initOptionLabel();
    }

    // If you have something better to propose, please welcome :)
  }

  public setActiveStyles(): void {
    this.active = true;
  }

  public setInactiveStyles(): void {
    this.active = false;
  }

  @HostListener('click')
  public selectOption(): void {
    this.selected = true;
    this.select.emit({
      value: this.value,
      label: this.label,
      image: this.image,
    });
  }

  private initOptionLabel() {
    const label = this.elementRef.nativeElement.innerText.trim();
    this.label = this.i18nService.getTranslation(label) || label;
  }
}
