import { Injectable } from '@angular/core';
import { StaffUser, User } from 'user/models/user';
import { AuthorizedAccount, AuthorizedVisitor, CustomProperties, GuestAccount, GuestVisitor, PendoObject } from '../models/pendo';
import { CustomDomainLoader } from './custom-domain-loader';
import { WindowRefService } from './window-ref.service';

export const APP_NAME = 'Discover';

@Injectable()
export class PendoService {
  private initialized = false;
  private readonly domain: string;

  constructor(private readonly windowRefService: WindowRefService,
              private readonly customDomainLoader: CustomDomainLoader) {
    this.domain = this.customDomainLoader.domain;
  }

  public startGuestSession(): void {
    const visitor: GuestVisitor = {id: ''};
    const account = this.guestAccount;
    this.initialize(visitor, account);
  }

  public startPatronSession(user: User): void {
    const visitor = {
      id: `${user.id.toString()}_${this.siteCode}`,
      role: user.roles?.toString()
    } as AuthorizedVisitor;
    const account = {
      ...this.guestAccount,
      branch: user.homeLibrary,
      branch_code: user.homeLibraryCode,
    };
    this.initialize(visitor, account);
  }

  public startStaffSession(staffUser: StaffUser): void {
    const visitor = {
      id: `${staffUser.info.id.toString()}_${this.siteCode}`,
      role: staffUser.roles?.toString(),
    } as AuthorizedVisitor;
    const account = {
      ...this.guestAccount,
    };
    this.initialize(visitor, account);
  }

  public trackEvent(eventName: string, props?: CustomProperties): void {
    this.pendo.track(eventName, props);
  }

  // There is no "clear session" method in Pendo library,
  // so it is necessary to clear session manually
  // by removing localstorage keys used by pendo.
  // This way next time pendo.initialize() is called,
  // new visitor.id will be generated instead of using previous one.
  public clearSession(): void {
    // clear all pendo data stored in localStorage
    Object.keys(this.windowRefService.localStorage)
    .filter((key) => key.startsWith('_pendo'))
    .forEach((key) => {
      this.windowRefService.localStorage.removeItem(key);
    });
  }

  private initialize(visitor: GuestVisitor | AuthorizedVisitor, account: GuestAccount | AuthorizedAccount): void {
    if (this.initialized) {
      this.pendo.identify({visitor, account});
    } else {
      this.pendo.initialize({visitor, account});
      this.initialized = true;
    }
  }

  private get guestAccount(): GuestAccount {
    return {
      id: this.hostWithHyphens,
      sitecode: this.siteCode,
      application_name: APP_NAME,
    };
  }

  private get siteCode(): string {
    return this.domain.split('.')[0];
  }

  private get hostWithHyphens(): string {
    return this.domain.replace(/[.]/g, '-');
  }

  private get pendo(): PendoObject {
    return this.windowRefService.nativeWindow().pendo;
  }
}
