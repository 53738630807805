import { Component, Input } from '@angular/core';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WindowRefService } from 'app/services/window-ref.service';
import { RichFeatureAction } from 'core/over-drive/components/econtent-rich-feature-successful-dialog/models';

@Component({
  selector: 'app-econtent-get-it-successful-dialog',
  templateUrl: './econtent-rich-feature-successful-dialog.component.html',
  styleUrls: ['./econtent-rich-feature-successful-dialog.component.scss'],
})
export class EcontentRichFeatureSuccessfulDialogComponent {
  @Input() public url: string;
  @Input() public action: RichFeatureAction;
  public faCheckCircle= faCheckCircle;
  RichFeatureAction = RichFeatureAction;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly windowRefService: WindowRefService,
  ) {
  }

  public navigateToLink() {
    this.windowRefService.nativeWindow().open(this.url, '_blank');
    this.activeModal.close();
  }
}
