<div class="view-toggle">
  <button (click)="onToggleView(viewType.LIST)"
          [class.active]="view === viewType.LIST"
          [attr.aria-pressed]="view === viewType.LIST"
          data-automation-id="view-toggle-list-button"
          class="mr-1">
    <fa-icon [icon]="listIcon"></fa-icon>
    <span class="text ml-1">{{'viewToggleList' | translate}}</span>
  </button>
  <button (click)="onToggleView(viewType.GRID)"
          [class.active]="view === viewType.GRID"
          [attr.aria-pressed]="view === viewType.GRID"
          data-automation-id="view-toggle-grid-button">
    <fa-icon [icon]="gridIcon"></fa-icon>
    <span class="text ml-1">{{'viewToggleGrid' | translate}}</span>
  </button>
</div>
