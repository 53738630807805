<div class="row mb-3">
  <ng-container
    *ngIf="materialTab.itemLibrary; else availabilityFromLocationsTmpl">
    <section class="col-12 col-sm-auto" data-automation-id="material-type-info">
      <div class="d-flex flex-nowrap align-items-center">
        <app-availability-icon class="lh-1" [status]="availabilityStatusEnum.AVAILABLE"></app-availability-icon>

        <span class="label-available" data-automation-id="item-availability-message">
          {{'onShelfAt' | translate}} {{'location.' + materialTab.itemLibraryCode | translate}}
        </span>
      </div>
    </section>
    <div class="col call-number d-flex align-items-center" data-automation-id="item-call-number-and-location">
      <div>
        <ng-container *ngIf="materialTab.callNumber"><strong>{{materialTab.callNumber}}</strong></ng-container>
        <ng-container *ngIf="materialTab.callNumber && (materialTab.itemLocation || materialTab.itemShelfLocation || materialTab.itemCollectionLocation || showOpacMessage)"> | </ng-container>
        <ng-container *ngIf="materialTab.itemLocation">{{'location.' + materialTab.itemLocationCode | translate}}</ng-container>
        <ng-container *ngIf="materialTab.itemLocation && (materialTab.itemShelfLocation || materialTab.itemCollectionLocation)"> | </ng-container>
        <ng-container *ngIf="materialTab.itemShelfLocation">{{'shelfLocation' | translate}} {{materialTab.itemShelfLocation}}</ng-container>
        <ng-container *ngIf="materialTab.itemShelfLocation && materialTab.itemCollectionLocation"> | </ng-container>
        <ng-container *ngIf="materialTab.itemCollectionLocation">{{'collection' | translate}} {{materialTab.itemCollectionLocation}}</ng-container>
        <ng-container *ngIf="materialTab.callNumber && (materialTab.itemLocation || materialTab.itemShelfLocation || materialTab.itemCollectionLocation) && showOpacMessage"> | </ng-container>
        <ng-container *ngIf="showOpacMessage">{{materialTab.opacMessage}}</ng-container>
        <div *ngIf="materialTab.publicNote">
          <strong class="text-capitalize">{{'note' | translate}}: </strong><span>{{materialTab.publicNote}}</span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #availabilityFromLocationsTmpl>
    <div class="col">
      <div class="d-flex flex-nowrap align-items-center"
           data-automation-id="material-type-info"
           *ngIf="availabilityStatus === availabilityStatusEnum.AVAILABLE; else noCopiesAvailableTmpl">
        <app-availability-icon class="lh-1" [status]="availabilityStatusEnum.AVAILABLE"></app-availability-icon>
        <span class="label-available" data-automation-id="item-availability-message">
          {{'onShelfAtOneOrMoreLocations' | translate}}
        </span>
      </div>
    </div>
    <ng-template #noCopiesAvailableTmpl>
      <span *ngIf="!formatByCode?.alwaysAvailable; else alwaysAvailable" class="label-unavailable" data-automation-id="item-availability-message">
        {{'noCopiesAvailable' | translate}}
      </span>
      <ng-template #alwaysAvailable>
        <div class= "d-flex align-items-center">
          <app-availability-icon [status]="availabilityStatusEnum.AVAILABLE"></app-availability-icon>
          <app-material-tab-type-label
            [status]="availabilityStatusEnum.AVAILABLE"
            [label]="materialTab.translationKey ? (materialTab.translationKey | translate) : materialTab.name">
          </app-material-tab-type-label>
          <app-rollup-tabs-content-status
                  class="tabs-content-status"
                  data-automation-id="content-status"
                  [materialTab]="materialTab"
                  [availabilityStatus]="availabilityStatusEnum.AVAILABLE">
          </app-rollup-tabs-content-status>
        </div>
      </ng-template>
    </ng-template>
  </ng-template>
</div>
