<div class="edit-search-name-modal-container p-4">
  <div class="modal-header">
    <h2 class="modal-title title text-capitalize"
        data-automation-id="edit-search-name-modal-title"
        id="edit-search-name-modal-title"
        translate>
      savedSearchEditSearchNameModalTitle
    </h2>
    <button (click)="onClose()"
            [attr.aria-label]="'closeDialog' | translate"
            class="close-btn p-0 m-n2"
            data-automation-id="edit-search-name-modal-close-btn"
            type="button">
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
  </div>

  <div class="modal-body">
    <form data-automation-id="edit-search-name-modal-form"
          [formGroup]="editSearchNameForm"
          (ngSubmit)="onSubmit()">
      <div class="form-group">
        <input type="text"
               formControlName="name"
               class="form-control edit-search-name-form-name"
               [class.is-invalid]="name.invalid && name.touched"
               placeholder="{{ 'savedSearchEditSearchNamePlaceholder' | translate }}"
               aria-labelledby="edit-search-name-modal-title"
               [attr.aria-invalid]="name.invalid && name.touched"
               aria-required="true"
               data-automation-id="edit-search-name-modal-form-name"/>
        <div aria-atomic="true" aria-live="assertive" role="alert">
          <p *ngIf="editSearchNameForm.invalid && editSearchNameForm.touched"
             class="invalid-feedback d-block"
             data-automation-id="edit-search-name-modal-form-error">
            <ng-container *ngIf="editSearchNameForm.errors?.unknown">
              {{ 'generalServerError' | translate }}
            </ng-container>

            <ng-container *ngIf="name.errors">
              <ng-container *ngIf="name.errors.required">
                {{ 'savedSearchEditSearchNameRequiredError' | translate }}
              </ng-container>
              <ng-container *ngIf="name.errors.maxlength">
                {{ 'savedSearchEditSearchNameMaxLengthError' | translate: {maxCharactersNumber: name.errors.maxlength.requiredLength} }}
              </ng-container>
              <ng-container *ngIf="name.errors.duplicate">
                {{ 'savedSearchEditSearchNameDuplicateError' | translate }}
              </ng-container>
            </ng-container>
          </p>
        </div>
      </div>
    </form>
    <div aria-live="polite" role="status">
      <div *ngIf="isUpdating"
           class="spinner-border text-primary"
           data-automation-id="edit-search-name-modal-spinner">
        <span class="sr-only" translate>Loading...</span>
      </div>
    </div>
  </div>

  <div class="modal-footer justify-content-start">
    <button (click)="onSubmit()"
            class="ins-buttons button-primary flint"
            data-automation-id="edit-search-name-modal-confirm-btn"
            translate
            type="button">
      savedSearchEditSearchNameButton
    </button>
    <button (click)="onClose()"
            class="ins-buttons button-secondary flint ml-3 text-uppercase"
            data-automation-id="edit-search-name-modal-cancel-btn"
            translate>
      savedSearchEditSearchCancelButton
    </button>
  </div>
</div>
