import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PublishedShowcase } from 'app/custom-showcase/models/custom-showcase.dto';
import { CopyToClipboardService } from '../../../services/copy-to-clipboard.service';
import { ShowcaseGenerationService } from '../../services/showcase-generation.service';

@Component({
  selector: 'app-showcase-generation-popup',
  templateUrl: './showcase-generation-popup.component.html',
  styleUrls: ['./showcase-generation-popup.component.scss'],
})
export class ShowcaseGenerationPopupComponent implements OnInit {
  @Input() preselectedShowcase: PublishedShowcase;
  @Input() publishedShowcases: PublishedShowcase[];
  @Input() pathToScript: string;

  public readonly maxBooksNum = 20;
  public readonly defaultTotalAmount = 12;
  public readonly defaultDisplayAmount = 2;
  public readonly booksDisplayAmount = [{booksNum: 2}, {booksNum: 3}, {booksNum: 4}, {booksNum: 5}];

  public showcaseWidgetScript = '';
  public showcaseGenForm: UntypedFormGroup;
  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly activeModal: NgbActiveModal,
    private readonly showcaseGenService: ShowcaseGenerationService,
    private readonly copyService: CopyToClipboardService,
  ) {
  }

  ngOnInit(): void {
    this.showcaseGenForm = this.fb.group({
      showcaseId: this.preselectedShowcase.id,
      displayAmount: this.defaultDisplayAmount,
      totalAmount: this.defaultTotalAmount,
    }, {validators: [this.validateBooksAmount()], updateOn: 'change'});
  }

  public trackById(index: number, item: { id: string, name: string }) {
    return item.id;
  }

  public onClose(): void {
    this.activeModal.dismiss();
  }

  public copyScript(): void {
    this.copyService.copyToClipboard(this.showcaseWidgetScript);
  }

  public generateScript(): void {
    if (this.showcaseGenForm.invalid) {
      return;
    }

    const {showcaseId, displayAmount, totalAmount} = this.showcaseGenForm.value;

    this.showcaseWidgetScript = this.showcaseGenService.generateShowcaseScript(
      showcaseId, displayAmount, totalAmount, this.pathToScript, this.preselectedShowcase.name
      );
  }

  public validateBooksAmount(): ValidatorFn {
    return (form: UntypedFormGroup) => {
      const displayAmount = form.get('displayAmount').value;
      const totalAmount = form.get('totalAmount').value;

      return displayAmount <= totalAmount ? null : {
        booksAmount: {
          valid: false,
        },
      };
    };
  }

}
