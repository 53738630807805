<div class="d-inline-block" ngbDropdown
     placement="bottom-right bottom left-bottom left auto">
  <button class="sort-button px-2"
          ngbDropdownToggle
          data-automation-id="sort-button-toggle-dropdown"
          aria-haspopup="menu"
          type="button">
    {{'sortedBy' | translate}} {{sortFieldsMap[currentSortField].translationKey | translate}}
  </button>
  <div role="menu" ngbDropdownMenu data-automation-id="sort-dropdown">
    <button *ngFor="let sortField of sortFields"
            (click)="setSort(sortField)"
            class="d-block py-2 px-5 w-100 h-auto sort-dropdown-option"
            data-automation-id="sort-dropdown-option"
            ngbDropdownItem
            role="menuitem">
      {{sortField.translationKey | translate}}
    </button>
  </div>
</div>
