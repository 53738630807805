import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'pluck'})
export class PluckPipe implements PipeTransform {
  transform(value: any[], keysString: string): unknown {
    const keys = keysString.split('.');

    return value.map((item) => {
      return keys.slice(1).reduce((property, key) => {
        return property[key];
      }, item[keys[0]]);
    });
  }
}
