<div class="d-inline-block"
     #removeDropdown="ngbDropdown"
     ngbDropdown
     placement="bottom-right bottom left-bottom left auto"
     (openChange)="removeDropdownOpenChange($event)"
     [autoClose]="'outside'">
  <button ngbDropdownAnchor
          (click)="$event.stopPropagation(); toggleRemoveConfirmation(removeDropdown)"
          [attr.aria-expanded]="removeDropdownOpened"
          [attr.aria-disabled]="!itemsSelectedCount"
          [class]="buttonClass"
          [class.button-disabled]="!itemsSelectedCount"
          data-automation-id="bookshelf-action-button-delete"
          aria-haspopup="dialog"
          class="hide-dropdown-arrow"
          type="button">
    <span class="fas fa-trash icon" aria-hidden="true"></span>
    <span translate class="text">bookshelfRemoveFromList</span>
  </button>
  <div role="dialog" ngbDropdownMenu data-automation-id="bookshelf-remove-from-list-dropdown"
       [attr.aria-label]="'bookshelfRemoveFromListConfirmation' | translate">
    <app-confirm-delete-items [itemsCount]="itemsSelectedCount"
                              (onCancel)="removeDropdown.close()"
                              (onConfirm)="removeSelectedFromList(removeDropdown)">
    </app-confirm-delete-items>
  </div>
</div>
