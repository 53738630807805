import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { PreviewService } from '../../services/preview.service';

@Component({
  selector: 'app-preview-links',
  templateUrl: './preview-links.component.html',
  styleUrls: ['./preview-links.component.scss'],
})
export class PreviewLinksComponent implements OnInit, OnDestroy {
  @Input() public identifiers?: Record<string, string>;

  public gBookPreviewLink?: string;
  public subscriptions = new Subscription();

  constructor(
    private readonly previewService: PreviewService,
    private readonly configurationLoader: ConfigurationLoader,
    private readonly cdRef: ChangeDetectorRef,
  ) { }

  public ngOnInit() {
    this.setGooglePreviewLink();
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private setGooglePreviewLink() {
    if (this.configurationLoader.connectionsConfiguration?.includes('googlebooks')) {
      const gBookBibKeys = this.getGoogleBookBibKeys();
      if (gBookBibKeys?.length) {
        gBookBibKeys.forEach((gBookBibKey) => {
          this.subscriptions.add(
            this.previewService.getGoogleBookConfig(gBookBibKey).subscribe((previewResult) => {
              if (previewResult.succeeded && previewResult.result?.embeddable) {
                this.gBookPreviewLink = previewResult.result.preview_url;
                this.cdRef.markForCheck();
              }
            }),
          );
        });
      }
    }
  }

  private getGoogleBookBibKeys(): string[] | undefined {
    return this.identifiers && Object.keys(this.identifiers)
    .filter((key) => ['lccn', 'isbn'].includes(key.toLowerCase()))
    .sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)
    .map((key) => `${key.toUpperCase()}:${this.identifiers[key].trim()}`);
  }
}
