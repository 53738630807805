import { Routes } from '@angular/router';
import { ForbiddenPageComponent } from 'core/containers/forbidden-page/forbidden-page.component';
import { LogoutPageComponent } from 'core/containers/logout-page/logout-page.component';
import { MaintenanceModePageComponent } from 'core/containers/maintenance-mode-page/maintenance-mode-page.component';
import { NotFoundPageComponent } from 'core/containers/not-found-page/not-found-page.component';
import { RootPageComponent } from 'core/containers/root-page/root-page.component';
import { SiteCodeGuard } from 'core/services/site-code-guard.service';
import { HomePageComponent } from './pages/home-page/home-page.component';

export const routes: Routes = [
  {
    path: '', canActivate: [SiteCodeGuard], component: RootPageComponent,
    children: [
      { path: '', component: HomePageComponent, data: { page: 'home' } },
      { path: 'search', loadChildren: () => import('./search/search.module').then((m) => m.SearchModule), data: { page: 'search' } },
      {
        path: 'library-list',
        loadChildren: () => import('./library-list/library-list.module').then((m) => m.LibraryListModule),
      },
    ],
  },
  { path: 'logout', component: LogoutPageComponent },
  { path: '403', component: ForbiddenPageComponent },
  { path: '503', component: MaintenanceModePageComponent },
  { path: '**', component: NotFoundPageComponent },
];
