<nav class="paginator" aria-label="Search results pagination">
  <button
    *ngIf="isLeftDotsVisible"
    class="ins-buttons button-primary prev-page"
    aria-label="Go to previous page"
    (click)="goToPage(currentPage - 1)"
    data-automation-id="prev-page-button">
    <fa-icon class="arrow-icon" [icon]="arrowIconLeft"></fa-icon>
    <span translate>previous</span>
  </button>

  <ul class="pages">

    <li class="page first"
        [ngClass]="{ 'hasdots': isLeftDotsVisible }">
      <a
        class="ins-buttons button-primary page-number"
        [ngClass]="{ 'current': currentPage === 0 }"
        [attr.tabindex]="currentPage === 0 ? null : 0"
        aria-label="Go to page 1"
        [attr.aria-current]="currentPage === 0 ? 'page' : null"
        (click)="goToPage(0)"
        (keydown.space)="goToPage(0)"
        (keydown.enter)="goToPage(0)"
        data-automation-id="go-to-page-link">1</a>
    </li>

    <li class="page" *ngFor="let number of numbers">
      <a cdkMonitorElementFocus
         class="ins-buttons button-primary page-number"
         [ngClass]="{'current': currentPage === number}"
         [attr.tabindex]="currentPage === number ? null : 0"
         [attr.aria-label]="'Go to page ' + (number + 1)"
         [attr.aria-current]="currentPage === number ? 'page' : null"
         (click)="goToPage(number)"
         (keydown.space)="goToPage(number)"
         (keydown.enter)="goToPage(number)"
         data-automation-id="go-to-page-link">{{number + 1}}</a>
    </li>

    <li class="page last" *ngIf="!isOnlyPage"
        [ngClass]="{ 'hasdots': isRightDotsVisible }">
      <a
        class="ins-buttons button-primary page-number"
        [ngClass]="{ 'current': currentPage === (totalPagesCount - 1) }"
        [attr.tabindex]="currentPage === (totalPagesCount - 1) ? null : 0"
        [attr.data-total]="totalPagesCount"
        [attr.data-current]="currentPage"
        [attr.aria-label]="'Go to page ' + totalPagesCount"
        [attr.aria-current]="currentPage === (totalPagesCount - 1) ? 'page' : null"
        (click)="goToPage(totalPagesCount - 1)"
        (keydown.space)="goToPage(totalPagesCount - 1)"
        (keydown.enter)="goToPage(totalPagesCount - 1)"
        data-automation-id="go-to-page-link">{{totalPagesCount}}</a>
    </li>
  </ul>

  <button
    *ngIf="isRightDotsVisible"
    class="ins-buttons button-primary next-page"
    aria-label="Go to next page"
    (click)="goToPage(currentPage + 1)"
    data-automation-id="next-page-button">
    <span translate>next</span>
    <fa-icon  class="arrow-icon" [icon]="arrowIconRight"></fa-icon>
  </button>
</nav>
