import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Axis360Availability, BorrowboxAvailability, CloudLibraryAvailability } from '../models/econtent-availability';

export type EcontentAvailability = Axis360Availability[] | CloudLibraryAvailability[] | BorrowboxAvailability[];
@Injectable({
  providedIn: 'root',
})
export class EcontentService {
  private readonly baseURL = 'api/search-result';

  constructor(
    private http: HttpClient,
  ) {}

  public checkAvailability(vendor:string, resourceIds: string[]) {
    return this.http.post<EcontentAvailability>(
      `${this.baseURL}/overdrive/${vendor}/availability`,
      resourceIds,
    );
  }
}
