<div class="row px-8" *ngIf="hasPreviousUrl && !isShowcaseFullPage" >
  <div class="col">
    <button class="back"
            (click)="goToPreviousPage()"
            data-automation-id="back-button">
      <span class="icon-back icon"></span>
      <span translate class="text">back</span>
    </button>
  </div>
</div>


