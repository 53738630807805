import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  OnInit,
} from '@angular/core';
import {
  ComponentPortal,
  PortalInjector,
} from '@angular/cdk/portal';
import {
  trigger,
  transition,
  style,
  animate,
  query,
} from '@angular/animations';
import { timer } from 'rxjs';

import {
  NOTIFICATION_PARAMS,
  NotificationsService,
} from './services/notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('appearDisappearAnim', [
      transition('* => *', [
        query(':enter', [
          style({transform: 'translateX(120px)', opacity: 0}),
          animate('200ms ease-out', style({transform: 'translateX(0)', opacity: 1})),
        ], {optional: true}),
      ]),
    ]),
  ],
})
export class NotificationsComponent implements OnInit {
  public notifications: any[] = [];

  constructor(
    private service: NotificationsService,
    private cdRef: ChangeDetectorRef,
    private injector: Injector,
  ) { }

  public ngOnInit() {
    this.service.showNotification$.subscribe(({component, params}) => {
      const tokens = new WeakMap([[NOTIFICATION_PARAMS, params]]);
      const injector = new PortalInjector(this.injector, tokens);
      const portal = new ComponentPortal(component, null, injector);
      this.notifications.push(portal);
      this.cdRef.markForCheck();

      timer(5000)
        .subscribe(() => {
          this.notifications = this.notifications.filter((notification) => notification !== portal);
          this.cdRef.markForCheck();
        });
    });
  }
}
