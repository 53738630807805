<div class="email-entity-modal-container p-4" data-automation-id="email-entity-modal-container">
  <div class="modal-header">
    <h2 class="modal-title title text-capitalize"
        data-automation-id="email-entity-modal-title"
        id="email-entity-modal-title"
        translate>
      shareItEmailEntityModalTitle
    </h2>
    <button (click)="onClose()"
            [attr.aria-label]="'closeDialog' | translate"
            class="close-btn p-0 m-n2"
            data-automation-id="email-entity-modal-close-btn"
            type="button">
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
  </div>

  <div class="modal-body">
    <form data-automation-id="email-entity-modal-form"
          [formGroup]="emailEntityForm"
          (ngSubmit)="onSubmit()"
          (keydown)="submitIfEnter($event)">
      <div class="form-group">
        <label for="email-entity-form-to"
               class="email-entity-form-to-label"
               data-automation-id="email-entity-form-to-label"
               translate>
          shareItEmailEntityToLabel
        </label>
        <input type="text"
               formControlName="to"
               id="email-entity-form-to"
               class="form-control email-entity-form-to"
               [class.is-invalid]="to.invalid && to.touched"
               placeholder="{{ 'emailSample' | translate }}"
               [attr.aria-invalid]="to.invalid && to.touched"
               aria-required="true"
               data-automation-id="email-entity-modal-form-to"/>
        <div aria-atomic="true" aria-live="assertive" role="alert">
          <p *ngIf="to.invalid && to.touched"
             class="invalid-feedback d-block"
             data-automation-id="email-entity-modal-form-to-error">
            <ng-container *ngIf="to.errors">
              <ng-container *ngIf="to.errors.required">
                {{ 'emailRequired' | translate }}
              </ng-container>
              <ng-container *ngIf="to.errors.email || to.errors.pattern">
                {{ 'emailValidation' | translate }}
              </ng-container>
            </ng-container>
          </p>
        </div>
      </div>
      <div class="form-group">
        <label for="email-entity-form-subject"
               class="email-entity-form-subject-label"
               data-automation-id="email-entity-form-subject-label"
               translate>
          shareItEmailEntitySubjectLabel
        </label>
        <input type="text"
               formControlName="subject"
               id="email-entity-form-subject"
               class="form-control email-entity-form-subject"
               [class.is-invalid]="subject.invalid && subject.touched"
               placeholder="{{ 'shareItEmailEntitySubjectPlaceholder' | translate }}"
               [attr.aria-invalid]="subject.invalid && subject.touched"
               aria-required="true"
               data-automation-id="email-entity-modal-form-subject"/>
        <div aria-atomic="true" aria-live="assertive" role="alert">
          <p *ngIf="subject.invalid && subject.touched"
             class="invalid-feedback d-block"
             data-automation-id="email-entity-modal-form-subject-error">
            <ng-container *ngIf="subject.errors?.required">
              {{ 'shareItEmailEntitySubjectRequiredError' | translate }}
            </ng-container>
          </p>
        </div>
      </div>

      <p *ngIf="emailEntityForm.invalid && emailEntityForm.touched"
         class="invalid-feedback d-block"
         data-automation-id="email-entity-modal-form-error">
        <ng-container *ngIf="emailEntityForm.errors">
          <ng-container *ngIf="emailEntityForm.errors.notFound">
            {{ 'shareItEmailEntitySavedSearchNotFound' | translate }}
          </ng-container>
          <ng-container *ngIf="emailEntityForm.errors.unknown">
            {{ 'generalServerError' | translate }}
          </ng-container>
        </ng-container>
      </p>
    </form>
    <div aria-live="polite" role="status">
      <div *ngIf="isSending"
           class="spinner-border text-primary"
           data-automation-id="email-entity-modal-spinner">
        <span class="sr-only" translate>Loading...</span>
      </div>
    </div>
  </div>

  <div class="modal-footer justify-content-start">
    <button (click)="onSubmit()"
            class="ins-buttons button-primary flint"
            data-automation-id="email-entity-modal-confirm-btn"
            translate
            type="button">
      shareItEmailEntitySendButton
    </button>
    <button (click)="onClose()"
            class="ins-buttons button-secondary flint ml-3 text-uppercase"
            data-automation-id="email-entity-modal-cancel-btn"
            translate>
      shareItEmailEntityCancelButton
    </button>
  </div>
</div>
