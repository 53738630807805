import { Component, Inject, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DATEPICKER_VALIDATORS } from '@angular/material/datepicker';
import { faCalendarWeek } from '@fortawesome/pro-regular-svg-icons';
import { dateFormatFactory } from 'shared/date-format-factory';
import { IlsType } from 'shared/models/ilsType';
import { HoldNeededDateForm } from 'user/models/get-it';
import { CustomerFeature } from '../../../customer-integration/customer-integration';
import { LocalizationService } from '../../../services/localization.service';

@Component({
  selector: 'app-hold-needed-date-form',
  templateUrl: './hold-needed-date-form.component.html',
  providers: [{
    provide: MAT_DATE_FORMATS,
    useFactory: dateFormatFactory,
    deps: [LocalizationService]
  }],
  styleUrls: ['./hold-needed-date-form.component.scss']
})
export class HoldNeededDateFormComponent implements OnInit {
  @Input() public ilsType: IlsType;
  @Input() public holdActivationDateSetting: boolean;
  @Input() public holdExpirationDefault: number;
  @Input() public itemsCount = 1;
  @Input() public disabled = false;
  public readonly CustomerFeature = CustomerFeature;
  public readonly IlsType = IlsType;
  public readonly calendarIcon = faCalendarWeek;
  public dateFormat = this.dateFormats.display.dateInput;
  public today = new Date();

  constructor(
    @Inject(MAT_DATE_FORMATS) private dateFormats: any,
    private readonly controlContainer: ControlContainer,
  ) {
  }

  public ngOnInit() {
    if (this.ilsType === IlsType.polaris && this.holdActivationDateSetting) {
      this.holdNeededDateForm.addControl('neededFrom', this.initializeDateFormControl());
    }

    if (this.ilsType === IlsType.sierra) {
      this.holdNeededDateForm.addControl('neededBy', this.initializeDateFormControl(this.holdExpirationDefault));
    }
  }

  get holdNeededDateForm(): FormGroup<HoldNeededDateForm> {
    return this.controlContainer.control as FormGroup<HoldNeededDateForm>;
  }

  public onChange(checked: boolean) {
    this.holdNeededDateForm.patchValue({
      hasExpirationDate: checked,
    });
  }

  private initializeDateFormControl(holdExpirationDefaultValue = 0): FormControl<Date> {
    return new FormControl(new Date(
      this.today.getFullYear(),
      this.today.getMonth(),
      this.today.getDate() + holdExpirationDefaultValue,
    ), MAT_DATEPICKER_VALIDATORS);
  }
}
