import { Vendor } from 'user/models/vendor';
import { VendorType } from './entity';

export const FormattedVendor: {[key in Vendor]: string} = {
  [Vendor.CLOUD_LIBRARY]: 'Cloud Library',
  [Vendor.BORROW_BOX]: 'Borrow Box',
  [Vendor.AXIS360]: 'Axis 360',
  [Vendor.OVERDRIVE]: 'OverDrive',
  [Vendor.HOOPLA]: 'Hoopla',
  [Vendor.GATES]: 'Gates',
  [Vendor.OAI_PMH]: 'OAI-PMH'
};


export const FormattedResource: {[key in VendorType]: string} = {
  [Vendor.CLOUD_LIBRARY]: Vendor.CLOUD_LIBRARY,
  [Vendor.BORROW_BOX]: Vendor.BORROW_BOX,
  [Vendor.AXIS360]: Vendor.AXIS360,
  [Vendor.OVERDRIVE]: Vendor.OVERDRIVE,
  [Vendor.HOOPLA]: Vendor.HOOPLA,
  [Vendor.OAI_PMH]: 'digital resources'
};
